// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import LineItemModelController from "controllers/ModelControllers/Quotes/LineItemModelController"

export default class ListLineController extends _viewController {
    constructor(props) {
        super(props)
        this.line_item  = new LineItemModelController({params: {parent: this, 
                                                                        controller: this,
                                                                        is_view: true, _param: "line_items"}})
    }
    get item_data() {
        return this.view.item_data
    }
    get _configs() {
        return this.view._configs
    }
    is_string(obj) {
        if (typeof obj === 'string' || obj instanceof String) {
            return true
        }
        return false
    }
    is_number(obj) {
        if (typeof obj === 'number' || obj instanceof Number) {
            return true
        }
        return false
    }
    is_obj(obj) {
        if (typeof obj === 'object' || obj instanceof Object) {
            return true
        }
        return false
    }
    unwrap_obj({obj, key, use_root}) {
        if (!this.is_obj(obj)) { return obj }
        if (obj === undefined || obj === null) { return null }
        let k = Object.keys(obj)?.[0]
        if (key !== undefined) {
            if (key in obj) {
                k = key
            } else {
                return null
            }
        }
        if (this.is_string(obj[k]) || this.is_number(obj[k]) || null) {
            if (use_root) { return obj }
            return obj[k]
        } else if (this.is_obj(obj[k])) {
                return this.unwrap_obj({obj: obj[k], key: key})
        }
        return null
    }
    lc(data) {
        if (data !== null && data !== undefined) {
            return data.toString().toLowerCase()
        }
        return data
    }
}
