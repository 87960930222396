// GENERAL REACT MODULES

//MODULE SPECIFIC
import _viewController from "controllers/ViewController/_viewController.jsx"
import PersonModelController from "controllers/ModelControllers/PersonModelController.jsx"
import update_contact from "assets/graphql/Contacts/Contacts.update.graphql.json"


export default class NewPersonModalController extends _viewController {
    constructor(props) {
        super(props)
        this.person = new PersonModelController({params: {parent: this,
                                                        controller: this.controller,
                                                        index: 0,
                                                        is_view: true,
                                                        _param: "people"}})
    }
    action_new_person() {
        if (this.modal_controller.person._sum > 0) {
            if(this.modal_controller.person.validateContact()) {
                this.modal_controller.new_person()
            }
        }
    }
    new_person() {
        let data = JSON.parse(JSON.stringify(this.person?.data))
        this.controller.people.push(data)
        this.format_request()
        this.controller.process_people()
        this.clear_person()
        this.view.state.parent.toggle_modal({toggle_state: false})
        this.controller.view.forceUpdate()
    }
    format_request() {
        let data = JSON.parse(JSON.stringify(this.controller.contact.summary))
        let person = JSON.parse(JSON.stringify(this.person.summary))
        data.contact.people = [person]
        data = this.toUnquotedJSON(data)
        let body = JSON.parse(JSON.stringify(update_contact))
        body.query = body.query.replace("input:", "input:"+data)
        this.api.contacts.ask({caller: this, params: {body: body}})
    }
    clear_person() {
        this.person.email = ""
        this.person.phone = ""
        this.person.last_name = ""
        this.person.first_name = ""
        // was working fine to reset the fields
        // then I tried to change something on the view it stopped working.
        // reverted the changes and did not work so I added the react key prop fix.
        this.view.setState(prevState => ({key: !prevState.key}))
        this.view.forceUpdate()
    }
    remove_person(index) {
        if (index > -1) {
            this.people.splice(index, 1)
        }
        this?.view?.forceUpdate()
    }
}