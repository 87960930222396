// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Container } from 'react-bootstrap'

// PAGE SPECIFIC MODULES
import "./TripsPanel.css"
import TripBuilderPartial from "./partials/TripBuilderPartial/TripBuilder.partial.view"
import TripOptionsPartial from "./partials/TripOptionsPartial/TripOptions.partial.view"

// ALL OTHER MODULES
import MultiPanel from "components/MultiPanels/MultiPanel"
import Controller from "./TripsPanel.controller.jsx"
import DispatchedTrip from '../DispatchedTrip/DispatchedTrip.view.jsx'
import { DragDropContext } from 'react-beautiful-dnd'
import CotaUserBar from "components/CotaUserBar/CotaUserBar.partial.jsx"

export default class TripsPanel extends MultiPanel {
    constructor(props) {
        super(props)
        this.custom_canvas = true
        this.controller = new Controller({ params: { view: this, controller: this.state.controller } })
        this.state.add_css = "border_add dashed_border"
        this.state.screen  = 0
        this.handleProcessEvent = this.handleProcessEvent.bind(this)
        this.onDragEnd = this.controller.onDragEnd.bind(this)
        this.state.tab_selection  = "loads" // options: "loads", "drivers", "trucks", "trailers"
        this.state.loads          = []
        this.state.drivers        = []
        this.state.trucks         = []
        this.state.trailers       = []
        this.state.trailer_types  = []
        this.state.linked = {
            drivers: false,
            trucks: false,
            trailers: false
        }
        this.state.data.trip = {
            sel_loads: [],
            sel_drivers: [],
            sel_trucks: [],
            sel_trailers: []
        }
    }
    renderInnerFrame() {
        // console.log(this)
        return (
            <Container id={this.name} className="generic_100 g-0">
                <Row className="max_height">
                    <Col id={this.name} className="flex_wrapper_rows">
                        <CotaUserBar params={{ parent: this, controller: this.controller, title: "trips", return: true }} />
                        <Row style={{ display: "flex", flexWrap: "nowrap" }} className="page">
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Col>
                                    <TripOptionsPartial params={{parent: this, controller: this.controller}}/>
                                </Col>
                                <Col sm={1} className="middle_col"/>
                                <Col >
                                    <div style={{ position: 'sticky', top:'84px'}}>
                                        <TripBuilderPartial params={{parent: this, controller: this.controller}}/>
                                    </div>
                                </Col>
                            </DragDropContext>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}
