// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import StatusPanel from "../StatusPanel/Status.partial.jsx";
import ColumnController from "components/Column/Column.controller.jsx"

export default class ColumnOneController extends ColumnController {
    constructor(props) {
        super(props)
        this.state.data = {
            shipper: {},
            consignee: {},
            stop: undefined
        }
        this.state.rows = this.generate_rows()
    }
    generate_rows() {
        let rows = Array(1).fill(undefined)
        // manages state of StatusPanel component. Intermediary between the view (components) and the model (data) of the application
        rows[0] = {obj: StatusPanel, params: {params: {parent: this, controller: this.view.controller, editable: true, index: 0}}}
        // rows[1] = {obj: StatusPanel, params: {params: {parent: this, controller: this.view.controller, editable: true, index: 0}}}
        // rows[2] = {obj: StatusPanel, params: {params: {parent: this, controller: this.view.controller, editable: true, index: 0}}}
        return rows
    }
}