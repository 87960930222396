// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import unlink_driver from "assets/graphql/Assets/Driver.unlink.graphql.json"

export default class UnassignTruckModalController extends _viewController {
    constructor(props) {
        super(props)
    }
    detach_truck() {
        let data = {id: this.view.controller.asset._id, user_id: this.view.controller.driver._id}
        data = this.toUnquotedJSON(data)
        let body = JSON.parse(JSON.stringify(unlink_driver))
        body.query = body.query.replace("input:", "input:"+data)
        this.api.assets.ask({caller: this, params: {body: body}, callback: {f: this.process_truck}})
    }
    process_truck({caller, params, results}) {
        if(results?.errors !== undefined) {
            console.log("Error detaching truck", results.errors)
            return
        }
        // console.log("Truck detached") 
        caller.view.toggle_modal({toggle_state: false})
        caller.view.controller.setState({key: "data", param: "asset", value: {}})
    }
}