// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "./_ModelController";
import CompanyModelController from "./CompanyModelController"
import RoleModelController from "./RoleModelController";

export default class DriverModelController extends _ModelController {
    constructor(props) {
        super(props)
        this._state         = undefined
        this.company        = new CompanyModelController ({params: {parent: this,
                                                           controller: this.controller,
                                                           is_view: true}})
    }
    get id () {
        return this.data?.id
    }
    load({data, key}) {
        this._state = {}
        this._state.data = data
        this._state.key = key
    }
    get all() {
        return this.data
    }
    get drivers_license() {
        if(this.data?.drivers_license !== undefined && this.data?.drivers_license !== "") {
            return this.data?.drivers_license
        }
    }
    get drivers_license_state() {
        if (this.data.drivers_license_state !== undefined && this.data?.drivers_license_state !== "") {
            return this.data?.drivers_license_state
        }
    }
    get first_name () {
        if (this.data?.first_name !== undefined && this.data?.first_name !== null) {
            return this.data.first_name
        }
        if (this.data?.given_name !== undefined && this.data?.given_name !== null) {
            return this.data?.given_name
        }
        return ""
    }
    get last_name () {
        if (this.data?.last_name !== undefined && this.data?.last_name !== null) {
            return this.data.last_name
        }
        if (this.data?.family_name !== undefined && this.data?.family_name !== null) {
            return this.data?.family_name
        }
        return ""
    }
    get safety_info () {
        if (this.data?.safety_info !== undefined && this.data?.safety_info !== "") {
            return this.data.safety_info
        }
    }
    get email () {
        if (this.data?.email !== undefined && this.data?.email !== "") {
            return this.data.email
        }
    }
    get phone_numbers() {
        return this.data?.phone_numbers
    }
    get phone_one() {
        if(this.phone_numbers?.length > 0) {
            return this.build_phone_number(this.phone_numbers[0])
        }
        // return "N/A"
    }
    get phone_two() {
        if(this.phone_numbers?.length > 1) {
            return this.build_phone_number(this.phone_numbers[1])
        }
    }
    build_phone_number(obj) {
        return obj.area_code+"-"+obj.phone_number
    }
    get phone () {
        if (this.data?.phone !== undefined && this.data?.phone !== "") {
            return this.data.phone
        }
    }
    get role () {
        if (this.role?.name !== undefined && this.role?.name !== "") {
            return this.role.name
        }
    }
    get role_data () {
        if (this.role?.data !== undefined && this.role?.name !== "") {
            return this.role.data
        }
    }
    get asset() {
        return this.data?.asset
    }
    get trips() {
        return this.data?.trips
    }
    get type() {
        return this.data?.driver_type
    }
    get status() {
        return this.data?.driver_status?.name
    }
}