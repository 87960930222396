//REACT SPECFIC 
import React from "react";
import { Button, Row, Col, Card} from "react-bootstrap";

//MODULE SPECIFIC
import Controller from "./TripLogs.controller.jsx"
import _basePartial from "components/General/_basePartial.jsx";
import Modal from "./AddLogModal/AddLogModal.view.jsx"
import GrafanaFrame from "components/GrafanaFrame/GrafanaFrame.view.jsx";
import grafana_config from "../../../../Map/grafana_test.json"
import TripLogItem from "./TripLogItem/TripLogItem.view.jsx";
import "./TripLogs.css";

// TEST DATA
import test_logs from "./TripLogs.json"

export default class TripLogs extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller     = new Controller({params: {view: this, controller: this?.controller}})
        this._modal                 = Modal
        this.state.show_map         = false
        this.state.btn_left         = "btn_logs_active" // Preserve
        this.state.btn_right        = "" // Preserve
        this.state.panel_name       = "maps"
        this.state.channel_name     = "grafana_row"
        this.state.panel_configs    = grafana_config["pages"]["maps"]
    }
    // NEED to figure out why there is a grafana frame in the map view?
    render_map() {
        return (
            <Row className="grafana g-0">
                <GrafanaFrame params={{parent: this, panel_configs: this.state.panel_configs, panel_name: this.state.panel_name}}/>
            </Row>
        )
    }
    render_trip_logs() {
        if (this.controller?.logs?.length === 0) { return this.render_no_logs() }
        return (
            <Row className="g-0">
                <Col>
                    {this.controller?.logs?.map((log, index) => (
                        <TripLogItem key={"trip_log_item_"+index} params={{parent: this, controller: this.controller, data: {event: log}, index: index}} />
                    ))}
                </Col>
            </Row>
        )
    }
    render_no_logs() {
        return (
            <Row className="g-0">
                <Col className="center">
                    No logs have been created yet
                </Col>
            </Row>
        )
    }
    render_log() {
        return (<>
            <Row className="g-0 ps-4 py-4">
                <Col>
                    <Row className="pad_top_half g-0">
                        <Col className="trip_logs">
                            Trip Logs
                        </Col>
                    </Row>
                </Col>
                <Col />
                <Col>
                    <Row className="g-0">
                        <Col className="d-flex justify-content-center">
                            <Button className="btn_add_log" onClick={() => this.toggle_modal({toggle_state: true})}>
                                Add Log
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="info g-0">
                <Col>
                    <Row className="ps-4 g-0">
                        <Col>
                            Event ID
                        </Col>
                        <Col sm={4}>
                            Type
                        </Col>
                        <Col>
                            Date
                        </Col>
                        <Col>
                            Receipt
                        </Col>
                        <Col>
                            Details
                        </Col>
                        <Col className="action_col">
                            Action
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr />
                        </Col>
                    </Row>
                </Col>
            </Row>
            {this.render_trip_logs()}
            <Row className="g-0">
                <Col>
                    <hr />
                </Col>
            </Row>
        </>)
    }
    // Moved buttons to their own method to make it easier to manage when we want to implement the map tab again.
    render_tab_btns() {
        return (
            <Row classname="g-0">
                <Col type="button" className={"btn btn_logs btn_logs_left "+this.state.btn_left}
                    onClick={() => this.partial_controller.handleLogs()}>
                    Trip Logs
                </Col>
                <Col type="button" className={"btn btn_logs btn_logs_right "+this.state.btn_right}
                    onClick={() => this.partial_controller.handleMap()}>
                    Trip Map
                </Col>
            </Row>
        )
    }
    // DONT NEED AFTER implementing map again.
    render_log_btn() {
        return (
            <Row>
                <Col className="btn btn_logs btn_logs_active btn_logs_single">
                    Trip Logs
                </Col>
            </Row>
        )
    }
    render_tab_views() {
        if(this.state.show_map) {
            return this.render_map()
        }
        return this.render_log()
    }
    renderView() {
        return (
            <Row id={this.name} className="add_shadow cota_panel_border">
                <Col>
                    {/* {this.render_tab_btns()} */} {/* Preserve */}
                    {this.render_log_btn()}
                </Col>
                <Card id="TripLogs" className="card_logs">
                    {this.render_tab_views()}
                </Card>
            </Row>
        )
    }
}