// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
// https://gist.github.com/Qti3e/6341245314bf3513abb080677cd1c93b
import mime_types from "./mime_types.json"

export default class DownloadPartialController extends _viewController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    get file() {
        return this.view?.props?.params?.file
    }
    get is_private() {
        return this.view.state.is_private ?? true
    }
    get is_tab() {
        return this.view.state?.new_tab ?? false
    }
    download_file() {
        if (this.file?.url !== undefined) {
            this.api.upload.file_download({caller: this, url: this.file?.url, is_private: this.is_private, callback: {f: this.process_downloaded_file, p: {"tab": this.is_tab}}})
        }
    }
    process_downloaded_file({caller, results, params}) {
        // console.log("--- process_downloaded_file ---", results)
        // console.log("params:::", params)
        console.log("--- process downloaded file ---", caller)
        if(results?.errors !== undefined) {
            console.log("Error downloading file", results.errors)
            return
        }
        if(params?.tab) {
            return caller.open_new_tab({resource_type: caller.file.resource_type, base64_string: results})
        }
        const byteArray = caller.create_byte_array(results)
        let resource_type = caller.resolve_resource_type({resource_type: caller.file?.resource_type, byteArray: byteArray})
        console.log("resource_type", resource_type)
        const a = document.createElement("a")
        a.href = "data:"+resource_type+";base64,"+results
        console.log("File downloaded")
        a.download = caller.file.name
        a.click()
        document.body.appendChild(a)
    }
    open_new_tab({resource_type, base64_string}) {
        // resource_type = this.resolve_resource_type({resource_type: resource_type})
        // const data = resource_type+";base64"
        // const byteCharacters = atob(base64_string);
        // const byteArrays = []
        // for (let i = 0; i < byteCharacters.length; i++) {
        //     byteArrays.push(byteCharacters.charCodeAt(i))
        // }
        // const byteArray = new Uint8Array(byteArrays)
        // const file = new Blob([byteArray], { type: data })
        const file = this.generate_file({resource_type: resource_type, base64_string: base64_string})
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL)
    }
    resolve_resource_type({resource_type, byteArray}) {
        if(resource_type === "application/json") {
            if(this.file?.name.includes("pdf")) {
                return "application/pdf"
            }
            if(this.file?.name.includes("xlsx")) {
                return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            }
            if(byteArray !== undefined) {
                return this.get_mime_type(byteArray)
            }
        }
        if((resource_type === undefined || resource_type === null) && byteArray !== undefined) {
            return this.get_mime_type(byteArray)
        }
        return resource_type
    }
    create_byte_array(base64_string) {
        const byteCharacters = atob(base64_string);
        const byteArrays = []
        for (let i = 0; i < byteCharacters.length; i++) {
            byteArrays.push(byteCharacters.charCodeAt(i))
        }
        return new Uint8Array(byteArrays)
    }
    get_signature(byteArray) {
        const len = 4
        let signature = ""
        if (byteArray.length >= len) {
            let signatureArr = new Array(len)
            for (let i = 0; i < len; i++) {
                // signatureArr[i] = (new Uint8Array(byteArray))[i].toString(16)
                signatureArr[i] = (new Uint8Array(byteArray))[i].toString(16).padStart(2, "0")
                signature = signatureArr.join('').toUpperCase()
            }
        }
        return signature
    }
    get_mime_type(byteArray) {
        let file_signature = this.get_signature(byteArray)
        let string_mime_types = JSON.stringify(mime_types)
        let index = string_mime_types.indexOf(file_signature)
        let end_index = string_mime_types.indexOf(",", index)
        let substr = string_mime_types.substring(index, end_index)
        let mime_type = substr.substring(substr.indexOf(":")+1).replaceAll('"', "")
        if(mime_type === undefined) {
            console.log("Error getting mime type")
            return
        }
        return mime_type
    }
    generate_file({resource_type, base64_string}) {
        const byteArray = this.create_byte_array(base64_string)
        resource_type = this.resolve_resource_type({resource_type: resource_type, byteArray: byteArray})
        const data = resource_type+";base64"
        const file = new Blob([byteArray], { type: data })
        return file
    }
}