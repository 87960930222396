// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import StopModelController from "controllers/ModelControllers/StopModelController.jsx"
import CompanyModelController from "controllers/ModelControllers/CompanyModelController.jsx"
import ShipmentModelController from "controllers/ModelControllers/Shipping/ShipmentModelController.jsx"
import EquipmentModelController from "controllers/ModelControllers/Shipping/EquipmentModelController.jsx"
import CommodityModelController from "controllers/ModelControllers/Shipping/CommodityModelController.jsx"
import LineItemModelController from "controllers/ModelControllers/Quotes/LineItemModelController"
import InfoModelController from "controllers/ModelControllers/Shipping/InformationModelController.jsx"
import InvoiceModelController from "controllers/ModelControllers/InvoiceModelController.jsx"
import UploadPartialController from "components/FileUpload/Upload.partial.controller.jsx"
import CarrierModelController from "controllers/ModelControllers/Carrier/CarrierModelController.jsx"

// API REQUESTS
import existing_quoteload from "assets/graphql/Loads/load.query.graphql.json"
import invoice_request from "assets/graphql/Loads/loads_queries/load.invoice.query.graphql.json"
import invoice_generation from "assets/graphql/Invoice/invoice_generation.json"
import email_request from "assets/graphql/Email/Email.graphql.json"
import employee_contact_email_request from "assets/graphql/Contacts/EmployeeContacts.graphql.json"
import documents_query from "assets/graphql/Loads/load.documents.graphql.json"

export default class BookingPanelController extends _viewController {
    constructor(props) {
        super(props)
        this.uploader = new UploadPartialController()
        this.load = new ShipmentModelController({params: {parent: this,
                                                        controller: this,
                                                        is_view: true } })
        this.commodity = new CommodityModelController({ params: {parent: this,
                                                                controller: this,
                                                                is_view: true } })
        this.pickup = new StopModelController({ params: { parent: this,
                                                            controller: this,
                                                            is_view: true,
                                                            _param: "pickup" } })
        this.consignee = new StopModelController({ params: { parent: this,
                                                            controller: this,
                                                            is_view: true,
                                                            _param: "consignee" } })
        this.payor = new CompanyModelController({ params: { parent: this,
                                                            controller: this,
                                                            is_view: true,
                                                            _param: "payor"} })
        this.carrier        = new CarrierModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true}})
        this.equipment = new EquipmentModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true}})
        this.line_item = new LineItemModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true,
                                                                //  _param: "line_items"
                                                            }})
        this.information = new InfoModelController({params: { parent: this,
                                                                controller: this,
                                                                is_view: true} })
        this.invoice = new InvoiceModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true } })
    }
    get line_items() {
        return this.view.state.data?.line_items
    }
    get invoice_pdf() {
        return this.view.state.invoice_pdf
    }
    get documents() {
        return this.view.state.documents
    }
    get emails() {
        return Array.from(new Set(this.view.state.emails.map(JSON.stringify))).map(JSON.parse)
    }
    // remove_duplicates(arr) {
    //     let new_arr = Array.from(new Set(arr.map(JSON.stringify))).map(JSON.parse)
    //     // let obj = arr.map(JSON.stringify)
    //     // let set = new Set(obj)
    //     // let new_arr = Array.from(set).map(JSON.parse)
    //     console.log(new_arr)
    //     return new_arr
    // }
    load_cache() {
        let key = this.view.state.screen
        let data = (this.panel_controller?.getCache(key))?.data
        if (data === undefined) {
            key = 3
            data = (this.panel_controller?.getCache(key))?.data
        }
        if (data !== undefined) {
            this.setState({ key: "data", value: data })
            if (key === 3) {
                this.view?.state?.panel_controller?.loadCache({ id: data?.id }, 0)
            }
        }
    }
    load_harness() {
        this.load_existing_quote(this.view.state?.data)
    }
    resolveCache() {
        if (this.view.state?.cache !== undefined) {
            this.view.state.context.user = this.getCopy(this.view?.state?.cache)
            this.setState({ key: "data", param: "user", value: this.getCopy(this.view?.state?.cache) })
            this.view.forceUpdate()
        }
    }
    async load_existing_quote(data) {
        this.setState({ _is_mutating: true })
        if (data !== undefined) {
            let body = JSON.parse(JSON.stringify(existing_quoteload))
            let query_param = (data?.id !== undefined) ? 'load(id: "' + data?.id + '")' : 'load(load_number: "' + data?.load_number + '")'
            body.query = body.query.replace("load", query_param)
            this.api.quotes.ask({ caller: this, params: { body: body }, callback: { f: this.process_loaded_quote} })
        }
    }
    get_contact_emails({ id }) {
        let body = JSON.parse(JSON.stringify(employee_contact_email_request))
        body.query = body.query.replace("employee_contacts", 'employee_contacts(company_id: "' + id + '")')
        this.api.contacts.ask({ caller: this, params: { body: body }, callback: { f: this.process_contact_emails } })
    }
    process_contact_emails({caller, params, results}) {
        // grabs the email of the payor to add to the options to select from, regardless of errors
        let email = caller?.payor?.email
        if(results?.errors !== undefined) {
            // console.log(results?.errors)
            caller.view.state.emails.push({label: email, value: email})
            return
        }
        if(results?.data?.employee_contacts?.length === 0) {
            caller.view.state.emails.push({label: email, value: email})
            return
        }
        results?.data?.employee_contacts?.push({email: email})
        results?.data?.employee_contacts?.map(el => {
            if (el?.email !== undefined && el?.email !== null) {
                return caller.view.state.emails.push({ label: el?.email, value: el?.email })
            }
        })
    }
    process_loaded_quote({ caller, params, results }) {
        let quoteload = results?.data?.load
        if (quoteload?.stops !== undefined) {
            caller.view.state.data.stops = quoteload?.stops
        }
        if (quoteload?.carrier !== undefined) {
            caller.setState({ key: "data", param: "saved.times", value: true })
            if (quoteload?.carrier?.id !== undefined) {
                caller.view.state.data.saved.dispatched = true
            }
        }
        if (quoteload?.loads?.length > 0) {
            caller.payor.index = 0
            caller.view.state.data.company = quoteload?.loads.map(a => a.payor)
        }
        let quote = quoteload?.quote
        if (quoteload?._type === "quote") {
            quote = JSON.parse(JSON.stringify(quoteload))
            delete quoteload.id
        }
        // console.log(quoteload)
        if (quoteload !== undefined) {
            if (quoteload.commodities === undefined) {
                caller.commodity?._sync({ data: quote?.raw_quote?.request?.items, remapper: "quote" })
            } else {
                caller.commodity?._sync({ data: quoteload.commodities })
            }
            if (quoteload?.rate_items !== undefined) {
                let linehaul = quoteload?.linehaul
                if ((linehaul !== undefined && linehaul !== null) && linehaul > 0) {
                    quoteload.rate_items = quoteload.rate_items.filter(el => el?.rate_type !== "flat_rate")
                    quoteload.rate_items?.push({ id: "linehaul", rate_type: "flat_rate", number: 1, price: linehaul, rate: linehaul })
                }
                caller.view.state.data.line_items = quoteload?.rate_items
            }
            caller.setState({ key: "data", param: "equipment", value: quoteload?.equipment })
            caller.load?._sync({ data: quoteload, remapper: "quote" })
            caller.quote?._sync({ data: quote })
            if(quoteload?.carrier?.id !== undefined && quoteload?.carrier?.id !== null) {
                caller?.carrier?._sync({data: quoteload?.carrier})
            } 
            if (quote?.raw_quote !== undefined) {
                let raw_request = JSON.parse(JSON.stringify(quote?.raw_quote))
                let date = undefined
                if (quote?.pickup_date !== null && quote?.pickup_date !== undefined) {
                    let convert_date = new Date(parseInt(quote?.pickup_date, 10))
                    if (!isNaN(convert_date)) {
                        date = convert_date
                    }
                }
                caller.quote?._sync({ data: quote })
            }
            caller.load_payor_or_dispatcher({ load: quoteload })
            if (quoteload?.pickup !== undefined) {
                caller.get_stop({ id: quoteload?.pickup?.id, name: "pickup" })
            }
            if (quoteload?.dropoff !== undefined) {
                caller.get_stop({ id: quoteload?.dropoff?.id, name: "consignee" })
            }
            if (quoteload?.stops !== undefined) {
                if (Array.isArray(quoteload.stops)) {
                    quoteload.stops.map((stop, index) => {
                        caller.get_stop({ id: stop.id, name: "stops", index: index })
                    })
                }
            }
            if (Array.isArray(quoteload?.documents) && quoteload?.documents?.length > 0) {
                caller.handle_documents({ documents: quoteload?.documents })
            }
            // used to run the get_invoice function which requires the load id.
            if (quoteload?.invoice !== undefined) {
                caller.get_invoice({ load: quoteload })
            }
        }
        caller.view.setState({ _is_loading: false })
    }
    handle_documents({ documents }) {
        // accepted documents: pod, bol
        documents?.map((doc, index) => {
            let doc_type = doc?.document_type?.name
            if (doc_type === "POD" || doc_type === "BOL") {
                this.view.state.documents.push(doc)
            }
        })
    }
    load_payor_or_dispatcher({ load }) {
        if (load?.dispatcher?.id !== undefined && load?.dispatcher?.id !== null) {
            return this.get_company({ id: load.dispatcher?.id, name: "payor" })
        }
        if (load?.payor !== undefined) {
            return this.get_company({ id: load.payor?.id, name: "payor" })
        }
        console.log("Error loading payor/dispatcher info")
    }
    get_stop({ id, name }) {
        this.api?.stops?.gid({ caller: this, value: id, callback: { f: this.process_loaded_stop, p: { name: name } } })
    }
    get_contact({ id, name }) {
        this.api?.contacts?.gid({ caller: this, value: id, callback: { f: this.process_loaded_contact, p: { name: name } } })
    }
    get_company({ id, name }) {
        this.api?.companies?.get({ caller: this, id: id, callback: { f: this.process_loaded_company, p: { name: name } } })
    }
    process_loaded_stop({ caller, params, results }) {
        caller?.[params?.name]?._sync({ data: results?.data?.stop })
    }
    process_loaded_contact({ caller, params, results }) {
        let contact = {
            contact: results?.data?.contact,
            company: results?.data?.contact?.company,
            insurance: results?.data?.contact?.insurance
        }
        caller.setState({ key: "data", param: params?.name, value: contact })
        // guard update function in an if statement to run only 
        // if variable in state called update is true.
        // prevents update function from being called when page is first loaded.
        if (caller.view.state.update) {
            caller.update()
        }
    }
    process_loaded_company({ caller, params, results }) {
        caller?.[params?.name]?._sync({ data: results.data.company })
        caller.get_contact_emails({ id: results.data.company?.id })
    }
    // This is probably breaking so many conventions
    // trying to avoid "callback chaining" / useless callback functions
    get_attachments() {
        if (this?.view?.state?.selected_docs?.length > 0) {
            let results = this.view.state.selected_docs.map((doc, index) => {
                let name = doc?.url?.slice(doc?.url?.indexOf(".com/") + 5)
                return { name: name }
            })
            // let results = await Promise.all(this.view.state.selected_docs.map(async (doc, index) => {
            //     let file = await this.api.upload.file_download({ caller: this, url: doc?.url, is_private: true })
            //     let name = doc?.url?.slice(doc?.url?.indexOf(".com/") + 5)
            //     return { file: file, name: name }
            // }))
            return results
        }
        return []
    }
    send_email() {
        let name = this.invoice_pdf.url.slice(this.invoice_pdf.url.indexOf(".com/") + 5)
        let email = this?.payor?.email
        let attachments = this.get_attachments()
        attachments.unshift({ name: name })
        let data = {
            to: email,
            type: "invoice",
            code: "invoice",
            attachments: attachments
        }
        data = this.toUnquotedJSON(data)
        let body = JSON.parse(JSON.stringify(email_request))
        body.query = body.query.replace("input:", "input:" + data)
        this.view.toggle_modal({ toggle_state: false })
        this.send_invoice({ body: body, email: email })

        // this.api.upload.file_download({ caller: this, url: this.invoice_pdf?.url, is_private: true, callback: { f: this.process_sent_email } })
    }
    // // AND This is probably breaking so many conventions
    // async process_sent_email({ caller, params, results }) {
    //     // console.log("--- process_sent_email ---", caller)
    //     let name = caller.invoice_pdf.url.slice(caller.invoice_pdf.url.indexOf(".com/") + 5)
    //     let email = caller?.payor?.email
    //     // let email = caller?.load?.payor?.email
    //     // let email = "david.lindberg@c0ta.com"
    //     let attachments = await caller.get_attachments()
    //     attachments.unshift({ name: name })
    //     // attachments.unshift({ name: name, file: results })
    //     let data = {
    //         to: email,
    //         type: "invoice",
    //         code: "invoice",
    //         attachments: attachments
    //     }
    //     data = caller.toUnquotedJSON(data)
    //     let body = JSON.parse(JSON.stringify(email_request))
    //     body.query = body.query.replace("input:", "input:" + data)
    //     caller.view.toggle_modal({ toggle_state: false })
    //     caller.send_invoice({ body: body, email: email })
    // }
    send_invoice({ body, email }) {
        let sb_config = { success: { show: true, msg: `Invoice successfully sent to ${email}` } }
        this.api.documents.ask({ caller: this, params: { body: body }, callback: { f: this.process_email, p: { sb: sb_config } } })
    }
    process_email({ caller, params, results }) {
        if (results?.errors !== undefined) {
            console.log("Error sending email", results.errors)
            return
        }
        // caller.view.toggle_modal({toggle_state: false})
        console.log("Email Sent")
    }
    // get_invoice({ load }) {
    //     console.log("LOAD",this)
    //     if ((load?.invoice?.id === undefined || load?.invoice?.id === null)) {
    //         let datas = [{ id: load.id, type: "loads", endpoint: "load" }]
    //         datas = this.toUnquotedJSON(datas)
    //         let body = JSON.parse(JSON.stringify(invoice_generation))
    //         body.query = body.query.replace("input:", 'input: {request_type: "loads", datas: ' + datas + ',' + 'cota_file: "invoice", file_type: "pdf"}')
    //         this.api.documents.ask({ caller: this, params: { body: body }, callback: { f: this.refresh_invoice } })
    //         return
    //     }
    //     this.load_existing_invoice({ data: load })}
    get_invoice({ load }) {
   
        if ((load?.invoice?.id === undefined || load?.invoice?.id === null)) {
            let datas = [{ id: load.id, type: "loads", endpoint: "load" }]
            datas = this.toUnquotedJSON(datas)
            let body = JSON.parse(JSON.stringify(invoice_generation))
            // body.query = body.query.replace("input:", 'input: {email: "'+this.load?.payor?.email+'", request_type: "loads", datas: '+datas+','+'cota_file: "invoice", file_type: "pdf"}')
            body.query = body.query.replace("input:", 'input: {request_type: "loads", datas: ' + datas + ',' + 'cota_file: "invoice", file_type: "pdf"}')
            this.api.documents.ask({ caller: this, params: { body: body }, callback: { f: this.refresh_invoice } })
            return
        }
        this.load_existing_invoice({ data: load })
    }
    refresh_invoice({ caller, params, results }) {
        if (results?.errors !== undefined) {
            console.log("Error refreshing invoice", results.errors)
            return
        }
        console.log("Refreshed invoice")
        let id = caller.load?._id
        let body = JSON.parse(JSON.stringify(invoice_request))
        body.query = body.query.replace("load", 'load(id: "' + id + '")')
        caller.api.loads.ask({ caller: caller, params: { body: body }, callback: { f: caller?.process_refresh_invoice } })
    }
    process_refresh_invoice({ caller, params, results }) {
        if (results?.errors !== undefined) {
            console.log("Error processing refreshed invoice", results.errors)
        }
        console.log("Refreshed invoice")
        caller.view.setState({ invoice_pdf: results?.data?.load?.invoice })
        // caller.view.state.invoice_pdf = results.data?.create_document
        caller.view.forceUpdate()
    }
    load_existing_invoice({ data }) {
        //  || this.view.state.data.updated
        let body = JSON.parse(JSON.stringify(invoice_request))
        body.query = body.query.replace("load", 'load(id: "' + data?.id + '")')
        this.api.invoice_pdf.ask({ caller: this, params: { body: body }, callback: { f: this.process_invoice } })
    }
    process_invoice({ caller, params, results }) {
        if (results?.errors !== undefined) {
            console.log("Invoice creation error:", results.errors)
            return
        }
        // console.log("RESULTS",results)
        let load = results?.data?.load
         let url = results?.data?.load?.invoice?.url
        // let url = "https://cota-user-documents.s3.us-west-1.amazonaws.com/70b44a9f-5e45-4c15-8e87-7119dc5bec62"
        if (url !== undefined && url !== null) {
            if (caller.view.state.data.updated) { 
                caller.view.state.data.updated = false
                let datas = [{ id: load?.id, type: "loads", endpoint: "load", url: url, invoice: load?.invoice?.id }]
                datas = caller.toUnquotedJSON(datas)
                let body = JSON.parse(JSON.stringify(invoice_generation))
                body.query = body.query.replace("create_document","update_document") 
                body.query = body.query.replace("input:", 'input: {request_type: "loads", datas: ' + datas + ',' + 'cota_file: "invoice", file_type: "pdf"}')
                console.log("BODY QUERY",body.query)
                caller.api.documents.ask({ caller: caller, params: { body: body }, callback: { f: caller.refresh_invoice } })
                return

            }
            caller.view.state.invoice_pdf = results.data.load.invoice
            console.log("Invoice processed")
            caller.view.forceUpdate()
            return
        }
        console.log("Invoice not processed")
    }
}