import React from "react";
import { Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx"
import Controller from "./EmailModal.controller.jsx"
import "./EmailModal.css"
import DocumentRow from "./DocumentRow/DocumentRow.partial.jsx"

export default class EmailModal extends _baseModal {
    constructor(props) {
        super(props)
        this.modal_controller   = new Controller({params: {view: this, controller: this?.controller}})
        this.state.data = {
            email: ""
        }
        this.state.form = {
            "email": {
                "title": "payor",
                "name": "email",
                "type": "select",
                "subtype": "name",
                "placeholder": "Select Email"
            }
        }
        this.state.modal_params = { 
            positioned: "middle_of_panel",   
            title: "Select Documents",                
            title_size: "",                     
            closeable: true,                    
            has_overlay: true,                 
            close_on_outside_click: true,       
            relative_position: "",
            relative_alignment: "",
            x_adjustment: "0px",                
            y_adjustment: "0px" 
        }
    }
    render_email_select() {
        // by waiting for all them emails to load, we ensure that there will be an email for the form object to grab
        if(this.controller.emails?.length > 0) {
            return (
                <div style={{position: "absolute"}}>
                    {this.formObject({data: this.state.form.email, owner: this.state.parent, options: this.controller.emails, controller: this.controller})}
                </div>
            )
        }
    }
    render_headers() {
        if(this.controller.documents?.length === 0) { return <></> }
        return (
            <Row className="mt-2 header_row">
                <Col sm={6}>
                    Name
                </Col>
                <Col sm={2}>
                    Type
                </Col>
                <Col sm={4} />
            </Row>
        )
    }
    render_documents() {
        if(this.controller.documents?.length === 0) { 
            return (<>
                <Row>
                    <Col>
                        No additional documents to select.
                    </Col>
                </Row>
            </>) 
        }
        return this.controller.documents.map((doc, index) => {
            return <DocumentRow key={index} params={{parent: this, controller: this?.controller, document: doc, index: index}}/>
        })
    }
    renderView() {
        return (
            <Row id={this.name}>
                <Col>
                    <Row>
                        <Col>
                            An invoice and any selected documents will be sent via email.
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col className="d-flex align-items-center">
                            <div className="d-flex align-items-center">Select payor's email:</div> <div className="d-flex align-items-center">{this.render_email_select()}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr />
                        </Col>
                    </Row>
                    {this.render_headers()}
                    {this.render_documents()}
                    <Row className="mt-4">
                        <Col>
                            <hr />
                        </Col>
                    </Row>
                    <Row className="btn_row">
                        <Col />
                        <Col />
                        <Col>
                            <Button className={"primary_button_coloring "+this._theme} onClick={() => this?.controller?.send_email()} >
                                Send
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}
