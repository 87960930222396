// ALL OTHER MODULES
import _baseApiObject from "components/General/_baseApiObject";
import drivers_request from "assets/graphql/Companies/Drivers.graphql.json"
import block_driver_request from "assets/graphql/Employees/BlockDriver.graphql.json"
import delete_driver_mutation from "assets/graphql/Companies/Drivers.delete.graphql.json"
import test_data from "./test.json"
import schema from "./schema.json"

export default class Drivers extends _baseApiObject {
    constructor(props) {
        super(props)
        this.schema     = schema
        this.test_data  = test_data
        this.requests   = {
            list: drivers_request,
        }
    }
    async search({caller, value, callback, params}) {
        //params.data.name
        let results = value
        if (value?.length >= 2) {
            let body = JSON.parse(JSON.stringify(drivers_request))
            let request_str = 'name: "'+value+'%"'
            body.query = body.query.replace("drivers()", 'drivers('+request_str+')')
            params = (params === undefined) ? {} : params
            params.body = body
            results = await this.ask({caller: caller, callback: callback, params: params, nocache: true})
        }
        // if (callback !== undefined) {
        //     callback({caller: caller, params: params, results: results})
        // }
        return results
    }
    async blockDriverToggle({caller, value, callback, params}) {
        let body = undefined
            body = JSON.parse(JSON.stringify(block_driver_request))
            body.query = body.query.replace("input: ", `input: { driver_id: "${value.driver_id}" }`)
            params = (params === undefined) ? {} : params
            params.body = body
            let results = await this.ask({caller: caller, callback: callback, params: params, nocache: true})
        return results
    }
    async deleteDriver({caller, value, callback, params}) {
        let body = JSON.parse(JSON.stringify(delete_driver_mutation))
        body.query = body.query.replace("input: ", `input: { id: "${value.driver_id}" }`)
        this.ask({caller: caller, params: {body: body}, callback: callback, nocache: true})
    }
}
