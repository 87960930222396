//REACT SPECFIC 
import React from "react";
import { Row, Col } from "react-bootstrap";
import { FaEllipsisV } from "react-icons/fa";
import { CiChat1 } from "react-icons/ci";
import { MdImage } from "react-icons/md";

//MODULE SPECIFIC
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./TripLogItem.controller.jsx"
import Modal from "./TripLogModal/TripLogItem.modal.jsx"
import "./TripLogItem.partial.css"

export default class TripLogItem extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({ params: { view: this, controller: this?.controller } })
        this._modal = Modal
    }
    get log() {
        return this.partial_controller.event
    }
    // Not to be confused with human resources.
    render_hr() {
        if (this.state.index === 0) { return <></> }
        return (
            <Row className="g-0">
                <Col>
                    <hr />
                </Col>
            </Row>
        )
    }
    render_value() {
        // really dumb fix for ensuring $0.00 is not displayed where it should not be
        let check = ["CHECK_CALL", "FUEL_RECEIPT", "TOLL_RECEIPT"]
        if (this.log?.value !== undefined && this.log?.value !== null) {
            if (!check.includes(this.log?.details?.type)) { return }
            if(this.log?.value > 0) {
                return this.partial_controller.format_currency(this.log?.value)
            }
        }
    }
    renderView() {
        // console.log(this)
        return (<>
            {this.render_hr()}
            <Row id={this.name} key={"event_" + this.state.index} className="ps-4 g-0">
                <Col className="details">
                    {/* {this.log?.event_number} */}
                    {this.state.index + 1}
                </Col>
                <Col sm={4}>
                    <Row className="g-0">
                        <Col className="details no_wrap">
                            {this.log?.details?.title}
                        </Col>
                    </Row>
                    <Row className="g-0">
                        <Col className="details_two">
                            {this.log?.full_address}
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row className="g-0">
                        <Col className="details">
                            {this.log?.date}
                        </Col>
                    </Row>
                    <Row className="g-0">
                        <Col className="details_two">
                            {this.log?.time}
                        </Col>
                    </Row>
                </Col>
                {console.log("LOG :", this?.log)}


                <Col>
                    {this.render_value()}
                    {/* {this.partial_controller.format_currency(this.log?.value)} */}
                </Col>

                <Col>
                    <Row sm={4} >
                        <Col>{this.log?.note ? <CiChat1 size={24} ></CiChat1> : ""}</Col>
                        <Col>{this.log?.documents?.length > 0 ? <MdImage size={24} ></MdImage> : ""}</Col>
                    </Row>
                </Col>


                {/* <Col>
                    {this.log?.inference}
                </Col> */}
                <Col>
                    <Row className="g-0 view_row">
                        <Col onClick={() => this.toggle_modal({ toggle_state: true })} className="view_btn pointer text-center" sm={4}>
                            View
                        </Col>
                        <Col sm={4}>
                            <FaEllipsisV className="pointer action" size={18} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>)
    }
}