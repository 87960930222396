// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "./_ModelController.jsx";
import CommodityModelController from "./Shipping/CommodityModelController.jsx";
// LEE TAKE A LOOK  - Lindhagen
import { Row, Col, Card, Container } from "react-bootstrap";

export default class LoadModelController extends _ModelController {
    constructor(props) {
        super(props)
        this._state     = undefined
        this.commodity  = new CommodityModelController ({params: {parent: this,
                                                            controller: this.controller,
                                                            is_view: true}})
    }
    get id () {
        return this.data?.id
    }
    load({data, key}) {
        this._state = {}
        this._state.data = data
        this._state.key = key
    }
    get all () {
        return this.data
    }
    get rate () {
        return this.data?.rate
    }
    get commodity_description() {
        return this?.data?.commodities?.[0]?.description
    }

    date = () => {
        if (this.data?.pickup?.date !== undefined && this.data?.pickup?.date !== null) {
        let date = this.data?.pickup?.date
        let dateObj = new Date(date)

        let day = dateObj.getDate()
        let month = dateObj.getMonth() + 1

        return (
            <Row>
                <Col>
                    {month}/{day}
                </Col>
            </Row>
        )
        } else {
            if (this.data?.dropoff?.date !== undefined && this.data?.dropoff?.date !== null) {
                let date = this.data?.dropoff?.date
                let dateObj = new Date(date)

                let day = dateObj.getDate()
                let month = dateObj.getMonth() + 1

                return (
                    <Row>
                        <Col>
                            {month}/{day}
                        </Col>
                    </Row>
                    )
            }

        }
    }
    commodities () {
        let commodities = this.data?.commodities
        let descriptions = commodities?.map(commodity => commodity?.description)
        return descriptions?.join(' ')
    }
    get total_width() {
        if (!isNaN(this.data?.total_width)) {
            return (Math.round(this.data?.total_width * 100) / 100).toFixed(2)
        }
        return this.data?.total_width
    }
    get total_weight() {
        if (!isNaN(this.data?.total_weight)) {
            return (Math.round(this.data?.total_weight * 100) / 100).toFixed(2)
        }
        return this.data?.total_weight
    }
    get total_length() {
        if (!isNaN(this.data?.total_length)) {
            return (Math.round(this.data?.total_length * 100) / 100).toFixed(2)
        }
        return this.data?.total_length
    }

    get total_height() {
        if (!isNaN(this.data?.total_height)) {
            return (Math.round(this.data?.total_height * 100) / 100).toFixed(2)
        }
        return this.data?.total_height
    }
    get total_amount() {
        if (!isNaN(this.data?.total_amount)) {
            return (Math.round(this.data?.total_amount * 100) / 100).toFixed(2)
        }
        return this.data?.total_amount
    }
    get total_rate() {
        if (!isNaN(this.data?.total_rate)) {
            return (Math.round(this.data?.total_rate * 100) / 100).toFixed(2)
        }
        return this.data?.total_rate
    }
    get cota_id() {
        return this.data?.cota_id
    }
    set cota_id (value) {
        return this.data?.cota_id
    }
    set update_day(value){
        return this.update_date
    }
    get update_day(){
        return this.data.update_day
    }
    // LEE SEE IF YOU ARE USING THIS OTHERWISE GET RID OF IT OR 
    // IMPLEMENT IN PAGE WHERE NEEDED - Lindhagen
    date = () => {
        if (this.data?.pickup?.date !== undefined && this.data?.pickup?.date !== null) {
        let date = this.data?.pickup?.date
        let dateObj = new Date(date)

        let day = dateObj.getDate()
        let month = dateObj.getMonth() + 1

        return (
            <Row>
                <Col>
                    {month}/{day}
                </Col>
            </Row>
        )
        } else {
            if (this.data?.dropoff?.date !== undefined && this.data?.dropoff?.date !== null) {
                let date = this.data?.dropoff?.date
                let dateObj = new Date(date)

                let day = dateObj.getDate()
                let month = dateObj.getMonth() + 1

                return (
                    <Row>
                        <Col>
                            {month}/{day}
                        </Col>
                    </Row>
                    )
            }

        }
    }
}