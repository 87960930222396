// GENERAL REACT MODULES
import React from "react"
import { Row, Col } from "react-bootstrap"

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./Overlay.partial.controller.jsx"
import "./Overlay.partial.css"

// PARTIAL IMPORTS
import CompanyClaimPartial from "./CompanyClaim/CompanyClaim.partial.jsx"
import CompanyCreationPartial from "./CompanyCreation/CompanyCreation.partial.jsx"

export default class Overlay extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this, controller: this?.controller}})
        this.state.claim = true
    }
    render_partial() {
        if(this.state.claim) {
            return <CompanyClaimPartial params={{parent: this, controller: this?.controller}}/>
        }
        return <CompanyCreationPartial params={{parent: this, controller: this?.controller}}/>
    }
    render_box() {
        return (
            <div className="box pointers border">
                {this.render_partial()}
            </div>
        )
    }
    renderView() {
        // console.log(this)
        return (
            <div id={this.name}>
                <div className="dimensions relative base_flex pointers">
                    {this.render_box()}
                </div>
            </div>
        )
    }
}