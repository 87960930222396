//GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button } from "react-bootstrap";

//PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx";
import "./StatusDropdown.modal.css"

export default class StatusDropdownModal extends _baseModal {
    constructor(props) {
        super(props)
        this.modal_controller   = this.state.parent?.partial_controller
        this.state.modal_params = {
            positioned: "relative_to_parent",
            title_size: "extra_small",
            relative_position: "below",
            relative_alignment: "middle",
            x_adjustment: "62.5px",
            y_adjustment: "-120px",
            has_overlay: false,
            close_on_outside_click: true,
        }
    }
    get parent() {
        return this.state.parent
    }
    select_status(index) {
        this.modal_controller.trip.status = this.controller.statuses?.[index]?.value
        this.parent.state.current_status = this.controller.statuses?.[index]?.label
        this.controller.view.forceUpdate()
        this.state.parent?.toggle_modal({})
    }
    renderView() {
        // console.log(this)
        return (
            <>
                {
                    this.controller.statuses.map((status, index) => {
                        return (
                            <Row style={{width: "400px"}} className="row_height pointer super_rainbow_row" key={"statusOptions_"+index}>
                                <Col onClick={this.select_status?.bind(this, index)}>
                                    {status.label}
                                </Col>
                            </Row>
                        )
                    })
                }
            </>
        )
    }
}