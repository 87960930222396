import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";

import _baseModal from "components/General/_baseModal.jsx";
import ModalController from "./NewContact.modal.controller"
import config_data from "./NewContact.modal.json"
import "./NewContact.modal.css"


export default class NewContactModal extends _baseModal {
    constructor(props) {
        super(props);
        this.modal_controller   = new ModalController ({params: {view: this}})
        this.state.form         = config_data.form_objects
        this.state.dims         = {width: 636, height: 980}
    }
    renderView() {
        const styles = {maxWidth:"36rem"};
        const newStyles = {...styles, ...this._inner_modal_location}
        return (
            <>
          <Row className={"g-0 inner_modal_window cota_panel_border add_shadow modal_style no_wrap"} ref={(divElement) => {this.divElement = divElement;}} style={newStyles} id={this.name}>
            <Col>
              <Row>
                <Col>
                  <Row>
                    <Col className="cota_sub_title no_wrap">
                      New Contact Person
                    </Col>
                  </Row>
                  <Row className="info">
                    <Col>
                      FIRST NAME*
                    </Col>
                  </Row>
                  <Row >
                    <Col>
                      {this.formObject({data: this.state.form.first_name})}
                    </Col>
                  </Row>
                  <Row className="info">
                    <Col>
                      LAST NAME*
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {this.formObject({data: this.state.form.last_name})}
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row className="info pad_top" >
                    <Col>
                      EMAIL*
                    </Col>
                  </Row>
                  <Row >
                    <Col>
                      {this.formObject({data: this.state.form.email})}
                    </Col>
                  </Row>
                  <Row className="info">
                    <Col>
                      PHONE*
                    </Col>
                  </Row>
                  <Row >
                    <Col>
                      {this.formObject({data: this.state.form.phone})}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="pad_top_hr">
                <Col>
                    <hr className="hr"/>
                </Col>
              </Row>
              <Row className="pad_no_wrap">
                <Col></Col>
                <Col>
                  <Row>
                    <Col>
                      <Button className={"clear_button " + this._theme} onClick={this.toggle_modal?.bind(this.state.parent, {toggle_state: false})}>
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <Button className={"primary_button_coloring " + this._theme}>
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          </>
        )
    }
}