import { Button, Col, Row, Card, Container } from "react-bootstrap";
import _basePartial from "components/General/_basePartial.jsx";
import "./ContactInfoPanel.css";

export default class ContactInfoPanel extends _basePartial {
    constructor(props) {
        super(props);
        // Get clarification for this line of code?
        this.state.status = this.controller.getState({
            key: "data",
            param: "saved",
        });
    }
    get load() {
        return this.state?.parent?.controller?.load
    }
    render() {
        return (
            <>
                {/* id={this.name} allows dev to set component specific css styling. i.e. #ContactInfoPanel .example { } */}
                <Container id={this.name}>
                    <Col>
                        <Row className="co2_row"> 
                            <Col>
                                <Row>
                                    <Col className="results_co2_panel">
                                        {this.load?.co2}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="co2_col">
                                        Pounds of CO2
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="text_primary">
                            <Col>
                                <Row>
                                    <Col className="name"> 
                                        Sarah Drury 
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <br/>
                                        <Row>
                                            <Col>
                                                <a href={`mailto:freight@cotasystems.com?subject=${this.controller.get_subject()}`} className="email" > 
                                                    freight@cotasystems.com
                                                </a>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <a className="phone_number" href="tel:651-369-7001"> 
                                                    651.369.7001
                                                </a>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="book_it">
                                    <Col>
                                        <Button className={this._theme} onClick={this.controller.request_info.bind(this)} >
                                            Request Info
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Container>
            </>
        );
    }
}
