// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import schema from "./schema.json"
import stop from "assets/graphql/Stops/stop.graphql.json"

// ALL OTHER MODULES
import _baseApiObject from "components/General/_baseApiObject";

export default class Contacts extends _baseApiObject {
    constructor(props) {
        super(props)
        this.schema     = schema
    }
    async gid({caller, value, callback, params}) {
        let results = value
        if (value?.length >= 2) {
            let body = JSON.parse(JSON.stringify(stop))
            body.query = body.query.replace("stop", 'stop(id: "'+value+'")')
            params = (params === undefined) ? {} : params
            params.body = body
            results = await this.ask({caller: caller, callback: callback, params: params, nocache: true})
        }
        return results
    }
}
