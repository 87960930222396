// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "controllers/ModelControllers/_ModelController.jsx";
import CompanyModelController from "../CompanyModelController.jsx"
import BillingDetailsModelController from "../BillingDetailsModelController.jsx"
import InsuranceModelController from "../InsuranceModelController.jsx"

export default class CarrierModelController extends _ModelController {
    constructor(props) {
        super(props)
        this.company = new CompanyModelController({params: {parent: this, _param: ((this._bind_child) ? this._data_param+".company": undefined),
                                                            controller: this.controller,
                                                            is_view: true}})
        this.billing_details = new BillingDetailsModelController({params: {parent: this, _param: ((this._bind_child) ? this._data_param+".company": undefined),
                                                            controller: this.controller,
                                                            is_view: true}})
        this.insurance = new InsuranceModelController({params: {parent: this, _param: ((this._bind_child) ? this._data_param+".company": undefined),
                                                            controller: this.controller,
                                                            is_view: true}})
    }
    get name() {
        let selected = this.controller.getState({key: "search", param: "company.selected_value"})
        if (selected?.name !== undefined) {
            return selected?.name
        }
        let searched = this.controller.getState({key: "search", param: "company.search_value"})
        if (searched !== undefined && Object.keys(searched).length > 0) {
            return searched
        }
        if (this.data?.notnull("name") && this.data?.name !== "") {
            return this.data?.name
        }
        return this.company?.name
    }
    // May need to remove this if it causes issues with the search functionality?
    set name(value) {
        this.company.name = value
    }
    get people() {
        return this.data?.people
    }
    get documents() {
        return this.data?.documents
    }
    get document_name() {
	    return this.data?.document_name
    }
    set document_name(value) {
	    this.data.document_name = value
    }
    get factoring_company() {
        return this.data?.factoring_company
    }
    set factoring_company(value) {
        this.setState({param: "factoring_company", value: value})
    }
    get same_billing_address() {
        return this.data?.same_billing_address
    }
    set same_billing_address(value) {
        this.setState({param: "same_billing_address", value: value})
    }
    get tenninenine() {
        return this.data?.tenninenine
    }
    set tenninenine(value) {
        this.setState({param: "tenninenine", value: value})
    }
    get liftgate() {
        return this.data?.liftgate
    }
    set liftgate(value) {
        this.setState({param: "liftgate", value: value})
    }
    get air_ride() {
        return this.data?.air_ride
    }
    set air_ride(value) {
        this.setState({param: "air_ride", value: value})
    }
    get address() {
        return (this.data?.address?.address !== undefined && this.data?.address?.address !== null) ? this.data?.address?.address : this.company?.address
    }
    set address(value) {
        if (this.company?._id === undefined) {
            this.company.address = value
        } else {
            this.setState({param: "address.address", value: value})
        }
    }
    get address_2() {
        if (this.company?._id !== undefined && this.data?.address?.notnull("address_2")) {
            return this.data?.address?.address_2
        }
        return this.company?.address_2
    }
    set address_2(value) {
        if (this.company?._id === undefined) {
            this.company.address_2 = value
        } else {
            this.setState({param: "address.address_2", value: value})
        }
    }
    get country() {
        if (this.company?._id !== undefined && this.data?.address?.location?.notnull("country")) {
            return this.data.address.location.country
        }
        return this.company?.country
    }
    set country(value) {
        if (this.company?._id === undefined) {
            this.company.country = value
        } else {
            this.setState({param: "address.location.country", value: value})
        }
    }
    get city() {
        if (this.company?._id !== undefined && this.data?.address?.location?.notnull("city")) {
            return this.data.address.location.city
        }
        return this.company?.city
    }
    set city(value) {
        if (this.company?._id === undefined) {
            this.company.city = value
        } else {
            this.setState({param: "address.location.city", value: value})
        }
    }
//    get state() {
//        return (this.data?.address?.location?.notnull("state")) ? this.data.address.location.state: this.company?.state
//    }
    get state() {
        if (this.company?._id !== undefined && this.data?.address?.location?.notnull("state")) {
            return this.data.address.location.state
        }
        return this.company?.state
    }
    set state(value) {
        if (this.company?._id === undefined) {
            this.company.state = value
        } else {
            this.setState({param: "address.location.state", value: value})
        }
    }
    get zip() {
        return (this.data?.address?.location?.notnull("zip")) ? String(this.data.address.location.zip): this.company?.zip
    }
    set zip(value) {
        if (this.company?._id === undefined) {
            this.company.zip = value
        } else {
            this.setState({param: "address.location.zip", value: value})
        }
    }
    get raw_location() {
        return this.data?.address?.location
    }
    get location() {
        return (this.raw_location !== undefined) ? this.raw_location : this.company.raw_location
    }
    get formatted_location() {
        let location = [this.city, this.state, this.zip]
        location = (location.filter((el) => this._notnull(el) && el !== ""))?.join(", ")
        // if(this._notnull(this.country)) { location += " "+this.country }
        return location
    }
    get street_address() {
        let address = (this._notnull(this.address)) ? this.address : ""
        let addrress_2 = (this._notnull(this.address_2)) ? this.address_2 : ""
        if(addrress_2 !== "") { address += " "+addrress_2 }
        return address
    }
    get full_address() {
        let address = (this.street_address !== "") ? this.street_address : ""
        if(this.formatted_location !== "") { address += ", "+this.formatted_location }
        return address
    }
    get email() {
        if (this.data?.email !== undefined && this.data?.email !== null) {
            return this.data?.email
        }
        return this.company?.email
    }
    set email(value) {
        if (this.company?._id === undefined) {
            this.company.email = value
        } else {
            this.setState({param: "email", value: value})
        }
    }
    get phone() {
        if (this.company?._id !== undefined && this.data?.notnull("phone")) {
            return this.data?.phone
        }
        return this.company?.phone
    }
    set phone(value) {
        if (this.company?._id === undefined) {
            this.company.phone = value
        } else {
	        this.data.phone = value
        }
    }
    get fax() {
        if (this.company?._id !== undefined && this.data?.notnull("fax")) {
            return this.data?.fax
        }
        return this.company?.fax
    }
    set fax(value) {
        if (this.company?._id === undefined) {
            this.company.fax = value
        } else {
            this.data.fax = value
        }
    }
    get number_of_trucks() {
        return this.data?.number_of_trucks
    }
    set number_of_trucks(value) {
        this.setState({param: "number_of_trucks", value: value})
    }
    get roles() {
        return this.data?.roles
    }
    get payor() {
        return this.roles?.payor
    }
    set payor(value) {
        this.setState({param: "roles.payor", value: value})
    }
    get shipper() {
        return this.roles?.shipper
    }
    set shipper(value) {
        this.setState({param: "roles.shipper", value: value})
    }
    get consignee() {
        return this.roles?.consignee
    }
    set consignee(value) {
        this.setState({param: "roles.consignee", value: value})
    }
    build_role(role) {
        return {code: role}
    }
    generate_roles() {
        let roles = []
        if (this.payor) { roles.push(this.build_role(6)) }
        if (this.shipper) { roles.push(this.build_role(8)) }
        if (this.consignee) { roles.push(this.build_role(7)) }
        return roles
    }
    number_reduction(value) {
        if (value !== undefined && value !== null) {
            return value.replace(/[^0-9]/g, '');
        }
        return value
    }
    get_area_code(phone_number) {
        return Number((this.number_reduction(phone_number.toString())?.slice(0,3)))
    }
    get_number(phone_number) {
        return (this.number_reduction(phone_number))?.slice(3,phone_number.length)
    }
    build_phone_number(phone_number) {
        if (this.get_number(phone_number) !== undefined) {
            return {
                country_code: 1,
                area_code: this.get_area_code(phone_number),
                phone_number: this.get_number(phone_number),
                extension: 0
            }
        }
        return undefined
    }
    resolve_address() {
        let address = this.company.address
        let selected_address = this.controller.getState({key: "search", param: "address.selected_value"})
        if (selected_address?.address !== undefined && typeof selected_address?.address === "string") {
            return selected_address?.address
        }
        let search_address = this.controller.getState({key: "search", param: "address.search_value"})
        if (search_address !== undefined) {
            return search_address
        }
        return address
    }
    resolve_zip() {
        let _zip = this.controller.getState({key: "search", param: "zip.selected_value"})
        if (_zip !== undefined && typeof _zip === "string") {
            return _zip.zip
        }
        _zip = this.controller.getState({key: "search", param: "zip.search_value"})
        return _zip
    }
    resolve_country() {
        if (this.country !== undefined && this.country !== null && this.country !== "") {
            return this.country
        }
        return "US"
    }

    get summary() {
        let _summary = {
            company: this.company.summary,
            carrier: JSON.parse(JSON.stringify(this.data))
        }
        delete _summary.carrier.image
        _summary.carrier.name = this.name
        if(!this._notnull(_summary.carrier?.name) || _summary.carrier?.name === "") {
            _summary.carrier.name = _summary.company.name
        }
        _summary.carrier.email = {email: this.email}
        _summary.carrier.phone = this.build_phone_number(this.phone)
        _summary.carrier.fax = this.build_phone_number(this.fax)
        _summary.carrier.roles = this.generate_roles()
        _summary.carrier.factoring_company_id = this.factoring_company
        delete _summary.carrier.factoring_company

        let selected = this.controller.getState({key: "search", param: "company.selected_value.id"})
        if (selected !== undefined && selected !== null) {
            if (Object.keys(selected).length > 0) {
                _summary.company.id = selected
            }
        }
        if (this.company?._id !== undefined) {
            _summary.carrier.address = {
                address: this.resolve_address(),
                address_2: this.address_2,
                location: { 
                    city: this.city,
                    state: this.state,
                    zip: this.resolve_zip(),
                    country: this.resolve_country()
                }
            }
        }
        if (Object.keys(this.insurance.summary).length > 0) {
            _summary.carrier.insurance = this.insurance.summary
        }
        if (Object.keys(this.billing_details.summary).length > 0) {
            _summary.carrier.billing_details = this.billing_details.summary
        }
        delete _summary.company?.image
        delete _summary.carrier?.documents
        delete _summary.carrier?.company
        console.log(this)
        console.log(_summary.carrier)
        _summary.carrier.scrub()
        return _summary
    }
}
