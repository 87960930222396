
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class RoleObjectController extends _viewController {
    constructor(props) {
        super(props)
    }
    handleAppt() {
        this.view.setState((prevState) => ({
            apptColor: prevState.apptColor === "#043B64" ? "#F0F1F7" : "#043B64",
            iconColorAppt: prevState.iconColorAppt === "black" ? "white" : "black" 
        }))
    }
    handleDelivery() {
        this.view.setState((prevState) => ({
            deliveryColor: prevState.deliveryColor === "#043B64" ? "#F0F1F7" : "#043B64",
            iconColorDelivery: prevState.iconColorDelivery === "black" ? "white" : "black"
        }))
    }
    get type() {
        return (this.view.state.role === "shipper") ? "pickup" : this.view.state.role
    }
    check_disabled() {
        // if(JSON.stringify(this.controller[this.type+"_contact"]?.data) !== '{}') {
        //     return false
        // }
        if(this.controller[this.type]?.contact?.id !== undefined) {
            return false
        }
        return true
    }
}

