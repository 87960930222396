// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Container, Button, Card } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx";
import Controller from "./EditDriverModal.controller.jsx";
import { MultiSelectDropdown } from "components/Dropdowns/Dropdowns";
import form_configs from "../../../../Assets/EditTrailer/EditTrailerPanel.json"

import "./EditDriverModal.css"

export default class EditDriverModal extends _baseModal {
    constructor(props) {
        super(props);
        this.modal_controller   = new Controller({ params: { view: this } })
        this.state.dims         = {width: 636, height: 980}
        this.state.form_configs = form_configs
        this.state.form         = form_configs.form_objects
        this.state.modal_params = {
            positioned: "middle_of_panel",
            title: `Edit Driver Type`,
            y_adjustment: "0px",
            x_adjustment: "0px",
            has_overlay: true,
            close_on_outside_click: true,
            closeable: true,
            draggable: false
        }
    }

    renderView() {
        const styles = { maxWidth: "35vw" };
        const newStyles = { ...styles, ...this._inner_modal_location }
        return (
            <div className="min_width">
                    <Row className="pad_top_1_5">
                        <Col className="type">
                            driver Type*
                        </Col>
                    </Row>
                    <Row className="pad_top_1 dropdown_row"> 
                        <Col className="absolute">
                            <MultiSelectDropdown params={{ parent: this,
                                                           name: "optionSelectedDriver",
                                                           title: "selection",
                                                           controller: this.controller,
                                                           options: form_configs.driver_type,
                                                           handleChange: this.controller?.handleChange,
                                                           optionSelected: this.state.selection}}
                                />
                        </Col>
                    </Row>
                    <Row className="pad_top_1">
                        <Col className="button_min_w">
                            <Button className={"center_button clear_button "+this._theme} onClick={this.toggle_modal?.bind(this.state.parent, {toggle_state: false})} >
                                Cancel
                            </Button>
                        </Col>
                        <Col className="button_min_w">
                            <Button className={"center_button primary_button_coloring "+this._theme} onClick={this.toggle_modal?.bind(this.state.parent, {toggle_state: false})} >
                                Save
                            </Button>
                        </Col>
                    </Row>
            </div>
        );
    }
}

