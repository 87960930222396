import _viewController from "controllers/ViewController/_viewController.jsx"
import self_request from "assets/graphql/Users/self.graphql.json"

export default class LoadsController extends _viewController {
    constructor (props) {
        super(props)
        this.routing = {
            default: {
                path: 0,
                screen: 1
            },
            alt: "load_number"
        }
        this.overrides = ["dispatched", "available", "delivered"]
        this.requests  = [{ request: self_request,
                            callback: {f: this.load_user, p: {name: "self"}}, endpoint: "users"},
                            {callback: {f: this.process_status}, endpoint: "status", 
                                    replace: {o: "status", r: 'status(types:"load")'}}]
    }
    generate_query_params(add_params) {
        return "id, load_id: local_id, payor, carrier, pickup, pickup_contact, pickup_date, dropoff,  po_number, dropoff_contact, dropoff_date, distance, total_amount: payment_rate, status"
    }
    load_user({caller, params, results}) {
        if(results?.errors !== undefined) { 
            console.log("Error processing self request", results.errors)
        }
        if(results?.errors === undefined && results?.data?.self !== undefined) {
            let length = results.data.self.companies.length - 1
            caller.view.setState({
                company_id: results.data.self.companies[length]?.id,
                // carrier_id: results.data.self.companies[0].carrier_id
                carrier_id: results.data.self.companies[length]?.carrier_id
            })
        }
    }
    process_status({caller, params, results}) {
        results?.data?.status?.map((obj) => {
            // if(obj?.value === "DISPATCHED") { return }
            if(obj?.value === "DISPATCHED_CARRIER" || obj?.value === "BOOKED" || obj.value === "BILLED_FREIGHT" || obj?.value === "POSTED") { return }
            caller.view.state.status_options.push({value: obj?.value, label: obj?.label.Capitalize()})
        })
    }
    get internal_user() {
        return this.check_role("INTERNAL_COTA_USER")
    }
    get sidebar() {
        return this.view?.state?.context?.sidebar
    }
    get menus() {
        return this.sidebar.context?.menus
    }
    processEventReaction(decoded) {
        console.log("DECODEED",decoded.message.data)
        if(decoded.message.data.status === "Processing"){
            this.view.state.processing = true
            this.view.forceUpdate()
            return
        }
        this.setState({key: "data", value: decoded.message.data})
        this.view.handleScreen({path_num: 1, screen_num: 1, data: decoded.message.data})
    }
    get _data() {
        return this.getState({key: "data"});
    }
    handleFindLoad() {
        this.sidebar?.controller?._handleClick(this.menus?.[1]?.[0], this.view.state.context)
    }
    handleCloseScreen() {
        this.resetScreen()
    }
    handleChange(){
        // console.log("change")
    }
    
}
