// GENERAL REACT MODULES
import { Row, Col, Button } from 'react-bootstrap'
import { FaImage } from "react-icons/fa"

//MODULE SPECIFIC
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./CompanyInfo.partial.controller.jsx"
import "./CompanyInfo.css"
import config_data from "./CompanyInfo.json"
import { countriesOptions } from "assets/countryCodes.js"
import UploadPartial from "components/FileUpload/Upload.partial.jsx";

export default class CompanyInfoPartial extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this, controller: this?.controller}})
        this.state.form = config_data.form_objects
    }
    get states() {
        return this.controller.states
    }
    get form() {
        if(this.controller.product === "carrierpro") {
            return this.state.form.carrier
        }
        return this.state.form.company
    }
    followDidMount() {
        this.forceUpdate()
    }
    render_company_search() {
        if(this.controller.product === "carrierpro") { return <></> }
        if(this.partial_controller.internal_user) {
            return this.formObject({data: this.form.company, controller: this.partial_controller, owner: this})
        }
        return <></>
    }
    render_labeled_input({label, data, options}) {
        return (
            <Row className="mb_label_input">
                <Col>
                    <Row className="label_row">
                        <Col className="label">
                            {label}
                        </Col>
                    </Row>
                    <Row>
                        {/* Adding this key allows for rerender of the inputs, removing sticky text */}
                        <Col key={this.state.key_num+label+this.partial_controller.company._id}>
                            {this.formObject({data: data, controller: this.partial_controller, owner: this, options: options, disabled: !this.partial_controller.editable})}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
    render_bank_info() {
        if(!this.partial_controller.editable) { return <></> }
        if(this.controller.product === "carrierpro") {
            return (<>
                {this.render_labeled_input({label: "Bank", data: this.form.bank})}
                {this.render_labeled_input({label: "Bank Routing #", data: this.form.routing_number})}
                {this.render_labeled_input({label: "Bank Account #", data: this.form.account_number})}
            </>)
        }
    }
    render_insurance_info() {
        if(!this.partial_controller.editable) { return <></> }
        if(this.controller.product === "carrierpro") {
            return (<>
                <Row>
                    <Col>
                        <hr />
                    </Col>
                </Row>
                <Row className="padding_row mb-3 mt-1">
                    <Col className="title_2">
                        Insurance Information
                    </Col>
                </Row>
                <Row className="padding_row">
                    <Col>
                        {this.render_labeled_input({label: "Producer", data: this.form.insurance.producer})}
                        {this.render_labeled_input({label: "Broker Name", data: this.form.insurance.broker_name})}
                        {this.render_labeled_input({label: "Email", data: this.form.insurance.email})}
                        {this.render_labeled_input({label: "Phone", data: this.form.insurance.phone})}
                    </Col>
                    <Col sm={1}/>
                    <Col>
                        {this.render_labeled_input({label: "General Liability", data: this.form.insurance.general_liability})}
                        {this.render_labeled_input({label: "Cargo Liability", data: this.form.insurance.cargo_liability})}
                        {this.render_labeled_input({label: "Workmen's Compensation", data: this.form.insurance.workmens_comp})}
                        {this.render_labeled_input({label: "Expiration Date", data: this.form.insurance.expiration})}
                    </Col>
                </Row>
            </>)
        }
    }
    render_tax_id() {
        if (!this.partial_controller.editable) return <></>
        return this.render_labeled_input({label: "Tax ID", data: this.form.tax_id})
    }
    render_edit_buttons() {
        if (!this.partial_controller.editable) return <></>
        return (<>
            <Row>
                <Col>
                    <hr />
                </Col>
            </Row>
            <Row className="padding_row g-0 mb-3">
                <Col>
                    <Button onClick={() => this.partial_controller.reset()} className="clear_button min_button_width">
                        Discard Changes
                    </Button>
                </Col>
                <Col sm={1} className="save_col">
                    <Button onClick={() => this.partial_controller.save()} className={"primary_button_coloring min_button_width "+this._theme}>
                        Save
                    </Button>
                </Col>
            </Row>
        </>)
    }
    renderView() {
        // console.log(this)
        this.partial_controller.get_company_data()
        return (
            <div id={this.name}>
                <Row className="generic_100 panel_object g-0 add_shadow">
                    <Col>
                        <Row className="mt-4 mb-4 padding_row">
                            <Col className="title">
                                Company Information
                            </Col>
                            <Col>
                                {this.render_company_search()}
                            </Col>
                        </Row>
                        <Row className="padding_row">
                            <Col sm={2}>
                                {console.log("ADMIN IMAGE",this.partial_controller.editable)}
                                {/* <div className="icon_row icon_col">
                                    <FaImage className="icon" size={25} />
                                </div> */}
                                                                <UploadPartial params={{
                                    parent: this.view, 
                                    controller: this.partial_controller, 
                                    endpoint: "company", 
                                    single_link: true, 
                                    css_rules: "icon_row icon_col", 
                                    image_css:"SettingsUserImage", 
                                    id:this?.state?.data?.company?.id, 
                                    existing_image: {url :this?.state?.data?.company?.image},
                                    new_image: this.state?.current_image,
                                    view: this,
                                    save: this.state.save,
                                    uploaded: this.state.uploaded,
                                    admin: true,
                                    disabled : !this.partial_controller.editable ?? true
                                    }} />
                            </Col>
                            <Col>
                                {this.render_labeled_input({label: "Company Name", data: this.form.name})}
                                {this.render_labeled_input({label: "Motor Carrier #", data: this.form.mc_number})}
                                {this.render_labeled_input({label: "DOT #", data: this.form.dot_number})}
                            </Col>
                            <Col xs="auto"/>
                            <Col className="d-flex flex-column-reverse">
                                {this.render_labeled_input({label: "Email Address", data: this.form.email})}
                                {this.render_labeled_input({label: "Phone", data: this.form.phone})}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <hr />
                            </Col>
                        </Row>
                        <Row className="padding_row mb-3 mt-1">
                            <Col className="title_2">
                                Billing Details
                            </Col>
                        </Row>
                        <Row className="padding_row">
                            <Col>
                                {this.render_labeled_input({label: "Address", data: this.form.address})}
                                {this.render_labeled_input({label: "Address Line 2", data: this.form.address_2})}
                                {this.render_labeled_input({label: "City", data: this.form.city})}
                                <Row>
                                    <Col>
                                        {this.render_labeled_input({label: "State/Province", data: this.form.state, options: this.states})}
                                    </Col>
                                    <Col>
                                        {this.render_labeled_input({label: "Zip Code", data: this.form.zip})}
                                    </Col>
                                </Row>
                                {this.render_labeled_input({label: "Country", data: this.form.country, options: countriesOptions})}
                            </Col>
                            <Col sm={1}/>
                            <Col>
                                {this.render_tax_id()}
                                {/* {this.render_labeled_input({label: "Fax", data: this.form.fax})}
                                {this.render_labeled_input({label: "Website", data: this.form.website})} */}
                                {this.render_bank_info()}
                            </Col>
                        </Row>
                        {this.render_insurance_info()}
                        {this.render_edit_buttons()}
                    </Col>
                </Row>
            </div>
        )
    }
}