// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import StopModelController from "controllers/ModelControllers/StopModelController.jsx"

export default class ChangeContactModalController extends _viewController {
    constructor(props) {
        super(props)
    }
   reset_contact(){
    this.view.parent.partial_controller.reset_contact(true)
    // console.log("NEW THIS",this)
    this.view.parent.toggle_modal({toggle_state: false})
   }
  async change_contact(id){
    let type = undefined
    let replace = undefined
//    await this.view.parent.partial_controller.reset_contact()
    console.log("NEW THIS",this,this?.controller?.load?.summary,this?.view?.parent?.props?.params?.type,id)
   await this.view.parent.toggle_modal({toggle_state: false})
    if(this?.controller?.load?.summary){
        if(this?.view?.parent?.props?.params?.type){
         type = this.view.parent.props.params.type.toUpperCase()
        }
        console.log("CHECKING TYPE",type)
        if(type){
 replace = {date:"",contact_id:`${id}`,stop_type:`${type}`}
        }else{
            replace = id
            replace.is_payor = true
        }
        if(this.view.parent?.name === "PayorCard") {
            this.view.parent.state.new_contact = true
        }
    console.log("RUNNING THING",this.controller)
  await  this.controller.save_load(replace)
   this.controller.view.forceUpdate()
    }
   }
}
