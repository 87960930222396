// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Container } from "react-bootstrap";

// MODULE SPECIFIC IMPORTS 
import _basePanelView from "components/General/_basePanelView.jsx"
import Controller from "./Help.controller.jsx"
import "./Help.css"
import Modal from "./HelpModal/Help.modal.jsx"
import CotaUserBar from "components/CotaUserBar/CotaUserBar.partial.jsx"

export default class Help extends _basePanelView {
    constructor(props) {
        super(props)
        this.controller = new Controller({params: {view: this}})
        this._modal = Modal
        this.state._toggled_modal = true
    }
    // NEED to make something similar to this page: https://support.google.com/youtube/?hl=en-GB#topic=9257498
    // for the look of the view
    renderMain() {
        return (
            <Container id={this.name} className="generic_100 g-0">
                <Row className="max_height">
                    <Col className="flex_wrapper_rows">
                        <CotaUserBar params={{parent: this, controller: this.controller, title: "Help"}}/>
                        <Row className="generic_100 panel_object g-0 add_shadow">
                            <Col>
                                <Row>
                                    <Col>
                                        {/* Hello World */}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}