// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import test_data from "./test.json"
import schema from "./schema.json"

// ALL OTHER MODULES
import _baseApiObject from "../../../../components/General/_baseApiObject";
import requirements from "assets/graphql/Requirements/requirements.graphql.json"
import special_requirements from "assets/graphql/Requirements/special_requirements.graphql.json"
import delivery_requirements from "assets/graphql/Requirements/delivery_requirements.graphql.json"
import accessorials from "assets/graphql/Requirements/accessorials.graphql.json"

export default class Requirements extends _baseApiObject {
    constructor(props) {
        super(props)
        this.schema     = schema
        this.test_data  = test_data
        this.requests   = {
            general: requirements,
            special: special_requirements,
            delivery: delivery_requirements,
            accessorials: accessorials
        }
    }
}
