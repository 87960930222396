import _viewController from "controllers/ViewController/_viewController.jsx"
import AssetModelController from "controllers/ModelControllers/AssetModelController.jsx"
import asset_request from "assets/graphql/Assets/Assets.graphql.json"

export default class ChangeDriverPartialController extends _viewController{
    constructor(props) {
        super(props)
        this.data       = {config_data: {},status: "map_idle"}
        this.asset      = new AssetModelController ({params: {parent: this,
                                                        controller: this,
                                                        is_view: true}})
    }
    load_harness() {
        this.attached_driver_info()
    }
    attached_driver_info() {
        let id = this.view.controller.asset.id
        let body = JSON.parse(JSON.stringify(asset_request))
        body.query = body.query.replace("assets", 'asset(id: "'+id+'")')
        this.view.controller.api.assets.ask({caller: this, params: {body: body}, callback: {fin:true,f: this.process_driver}})
    }
    process_driver({caller, params, results}) {
        if (results.data?.asset !== undefined) {
            caller.view.state.data.asset = caller.getCopy(results.data?.asset)
            caller.view.forceUpdate()
        }
    }
}