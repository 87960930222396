//GENERAL REACT MODULES
import React from "react"
import {Button } from "react-bootstrap"

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import CancelLoadModal from "./CancelLoad.modal"

export default class CancelLoadButton extends _basePartial {
    constructor(props) {
        super(props)
        this.modal_controller   = this.controller
        this._modal = CancelLoadModal
    }

    get status() {
        return this.controller.load?.status?.toLowerCase()
    }

    render_make_available() {
        return (
            <Button className="primary_button_coloring w-100 cota" onClick={this.toggle_modal.bind(this)}>Make Available</Button>
        )
    }

    render_cancel() {
        return (
            <Button variant="danger" className="w-100 cota" onClick={this.toggle_modal.bind(this)}>Cancel Load</Button>
        )
    }

    renderView() {
        switch (this.status) {
            case "canceled":
                return this.render_make_available()
            default:
                return this.render_cancel()
        }
    }
}