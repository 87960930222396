// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";
import { BsImage } from "react-icons/bs";
import { RiCheckboxCircleFill } from "react-icons/ri";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import "./LoadboardPanel.css"

export default class LoadboardPanel extends _basePartial {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    render_check() {
        if (this.state?.data?.valid) {
            return (
                <RiCheckboxCircleFill className="check_mark" size={30} />
            )
        }
        return (
            <></>
        )
    }
    render_date({date}) {
        return "Last updated " +date.time+" "+date.desc+" ago"
    }
    renderView() {
        return (
            <>
                <Row className="row_padding" id={this.name}>
                    <Col className="no_left cota_panel_border click_cursor" onClick={this.controller?.get_details.bind(this, {data: this.state?.data})}>
                        <Row className="g-0">
                            <Col className="g-0 relative">
                                <img className="image" src={this.state?.data?.image} alt={"Cota Freight Carriers "+this.state?.data?.name}/>
                                <BsImage size={70} className="image_icon" />
                            </Col>
                            <Col className="min_padding">
                                { this.render_check() }
                                <Row>
                                    <Col className="center_text name_row">
                                        {this.state?.data?.name}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="center_only cap_sentance">
                                        {this.state?.data?.default}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="center_text date_row">
                                        {this.render_date({date: this.controller.diff_date({then: this.state?.data?.date})})}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}