// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import test_data from "./test.json"
import schema from "./schema.json"

// ALL OTHER MODULES
import _baseApiObject from "components/General/_baseApiObject";

export default class Users extends _baseApiObject {
    constructor(props) {
        super(props)
        this.schema     = schema
        this.test_data  = test_data
        this.snackbar_config = {
            success: { msg: "User information saved successfully.", show: false },
            error: { msg: "Error getting user information.", show: true }
        }
    }
    load_sub_objects(data) {
        if (data !== undefined) {
            data.companies = {}
            data?.company_ids?.map((id, index) => {
                data.companies[id] = this.state.parent.companies.get({id: id})
                return undefined
            })
            data.roles = {}
            data?.role_ids?.map((id, index) => {
                data.roles[id] = this.state.parent.roles.get({id: id})
                return undefined
            })
            return data
        }
        return data
    }
}

