//REACT SPECFIC 
import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FaTrailer, FaUser } from "react-icons/fa";
import { BsTruck } from "react-icons/bs";

//MODULE SPECIFIC 
import Controller from "./TripLoadCard.controller.jsx"
import _basePartial from "components/General/_basePartial.jsx";
import "./TripLoadCard.css";

export default class TripLoadCard extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller     = new Controller({params: {view: this, controller: this?.controller}})
    }
    render_edit() {
        if(this.controller?.trip?.status?.toLowerCase() === "completed") { return <></> }
        if(this.controller?.trip?.status !== undefined) {
            return (
                <Button onClick={() => this.partial_controller.edit_trip()} size="sm" variant="secondary" className='btn_secondary'>
                    Edit Trip
                </Button>
            )
        }
    }
    renderView() {
        // console.log(this)
        return (
            <Row id={this.name}>
                <Col className="add_shadow cota_panel_border">
                    <Row className="g-0 pt-4 pb-3">
                        <Col className="title ps-3">
                            Trip {this.controller?.trip?.trip_id} {(this.controller?.trip?.cota_id !== undefined &&
                                                                                                    this.controller?.trip?.cota_id !== null && 
                                                                                                    this.controller?.trip?.cota_id !== "") ? this.controller?.trip?.cota_id : ""}
                        </Col>
                        <Col sm="auto" className="d-flex align-items-center">
                            <Row className="g-0">
                                <Col className="status">
                                    {this.controller.trip.status}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="g-0 text-center">
                        <Col>
                            <Row className="g-0">
                                <Col/>
                                <Col sm={5} className="center icon_col">
                                    <FaUser size={50} className="icon" />
                                </Col>
                                <Col/>
                            </Row>
                            <Row className="pad_top_half g-0">
                                <Col className="info">
                                    {/* {this.controller.driver.full_name} */}
                                    {this.partial_controller.driver_name}
                                </Col>
                            </Row>
                            <Row className="pad_top_half g-0">
                                <Col className="details">
                                    {this.controller.driver.email}
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row className="g-0">
                                <Col/>
                                <Col sm={5} className="center icon_col">
                                    <BsTruck size={50} className="icon" />
                                </Col>
                                <Col/>
                            </Row>
                            <Row className="pad_top_half g-0">
                                <Col className="info">
                                    {this.controller.truck.asset_number ?? "N/A"}
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row className="g-0">
                                <Col/>
                                <Col sm={5} className="center icon_col">
                                    <FaTrailer size={50} className="icon" />
                                </Col>
                                <Col/>
                            </Row>
                            <Row className="pad_top_half g-0">
                                <Col className="info">
                                    {this.controller.trailer.asset_number ?? "N/A"}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="pb-4">
                        <Col className="d-flex justify-content-end">
                            {this.render_edit()}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}