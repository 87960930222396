//GENERAL REACT MODULES
import React from "react"
import { Row, Col, Button } from 'react-bootstrap'
import { HiUser } from "react-icons/hi"

//PAGE SPECIFIC MODULES
import Controller from "./DriverDetails.partial.controller.jsx"
import _basePartial from "components/General/_basePartial.jsx";
import Modal from "../EditDriverModal/EditDriverModal.view.jsx"
import "./DriverDetails.css"
import form_configs from "./DriverDetails.json"
import BlockDriverPartial from "../BlockDriver/BlockDriver.partial.jsx"

export default class DriverDetails extends _basePartial {
    constructor(props) {
        super(props)
        this._modal = Modal
        this.partial_controller = new Controller({ params: { view: this, controller: this?.controller } })
        this.state.form = form_configs.form_objects
        this.state.form_configs = form_configs.form_objects
        this.state.edit_mode = false
    }
    get driver_id() {
        return this.controller.driver.id?.substring(0, 5)
    }
    renderView() {
        return (
            <Row id={this.name} className="g-0 cota_panel_border add_shadow">
                <Col>
                    <Row className="padding g-0">
                        <Col sm={1}>
                            <Row className="icon_row">
                                <Col className="icon_col">
                                    <HiUser size={50} className="icon" />
                                </Col>
                            </Row>
                        </Col>
                        <Col className='empty_spacing' sm={3}></Col>
                        <Col>
                            <Row>
                                <Col className="name">
                                    {this.controller?.driver?.first_name} {this.controller?.driver?.last_name}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="label">
                                    {this?.controller?.driver?.trips?.length} Trips
                                </Col>
                            </Row>
                        </Col>
                        <Col></Col>
                        <Col className="status_spacing" sm={2}>
                            {/* <Row className="status_background">
                                <Col className="status">
                                    available
                                </Col>
                            </Row> */}
                            <Row>
                                <Col className="status_badge_col">
                                    <Button size="sm" className={"status_button " + this.controller.driver?.status?.toLowerCase()}>
                                        {this.controller.driver?.status ?? "Pending"}
                                    </Button>
                                </Col>
                                <Col className="id">
                                    ID: # {this.driver_id}
                                </Col>

                                    {/* <Row className="pt-3"> */}
                                        {/* NEED to fix this edit driver modal */}
                                        {/* <Col>
                                            <Button onClick={() => this.toggle_modal({toggle_state: true})} className="edit_driver text-center">
                                                Edit driver type
                                            </Button>
                                        </Col> */}
                                    {/* </Row> */}
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            < hr />
                        </Col>
                    </Row>
                    <Row className="g-0 padding">
                        <Col>
                            <Row>
                                <Col className="gi">
                                    general info
                                </Col>
                            </Row>
                            <Row>
                                <Col className="info MuiInputLabel-shrink">
                                    Address
                                </Col>
                            </Row>
                            <Row className="pad_top_half">
                                <Col className="details">
                                    {this.controller.company.full_address}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="info MuiInputLabel-shrink">
                                    Email
                                </Col>
                            </Row>
                            <Row className="pad_top_half">
                                <Col className="details">
                                    {this.controller.driver.email}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="info MuiInputLabel-shrink">
                                    Phone
                                </Col>
                            </Row>
                            <Row className="pad_top_half">
                                <Col className="details">
                                    {this.controller.company?.phone}
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col className="info MuiInputLabel-shrink">
                                    2nd Phone
                                </Col>
                            </Row>
                            <Row className="pad_top_half">
                                <Col className="details">
                                    {this.state.form_configs?.general_info?.second_phone}
                                </Col>
                            </Row> */}
                        </Col>
                        <Col></Col>
                        <Col>
                            <Row>
                                <Col className="gi">
                                    license details
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="auto">
                                    <Row>
                                        <Col className="info MuiInputLabel-shrink">
                                            License Number
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="details">
                                            {this.controller.driver.drivers_license}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row >
                                        <Col className="info MuiInputLabel-shrink">
                                            License State
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="details">
                                            {this.controller.driver.drivers_license_state}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="info MuiInputLabel-shrink">
                                    License Category
                                </Col>
                            </Row>
                            <Row>
                                <Col className="details">
                                    {this.controller.driver.drivers_license_category}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    < hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="auto">
                                    <Row>
                                        <Col className="info MuiInputLabel-shrink">
                                            Driver Type
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="details">
                                            {this.controller.driver.type}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col className="info MuiInputLabel-shrink">
                                            Safety Date
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="details">
                                            {this.controller.driver.safety_info}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={1}></Col>
                    </Row>
                    <Row>
                        <Col>
                            < hr />
                        </Col>
                    </Row>
                    {/* <Row className="pad_bot">
                        <Col>
                            <Row>
                                <Col>
                                    <BlockDriverPartial params={{parent: this, controller: this.controller}} />
                                </Col>
                            </Row>
                        </Col>
                    </Row> */}
                </Col>
            </Row>
        )
    }
}