// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button } from "react-bootstrap";
// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx";
import ModalController from "./EditLoad.modal.controller.jsx";
import form_configs from "./EditLoad.json"
import { IoMdImage } from "react-icons/io";
import "./EditLoad.modal.css"

export default class EditLoadModal extends _baseModal {
    constructor(props) {
        super(props)
        this.modal_controller   = new ModalController ({params: {view: this, controller: this.controller}})
        this.state.form         = form_configs.form_objects
        this.state.base         = {x:50, y:75} 
        this.state.dims = {width: 636, height: 980}
        this.state.draggable    = false
        this.state.modal_params = {
            positioned: "middle_of_panel",   
            title: "Edit Load Details",                
            title_size: "",                     
            closeable: true, 
            draggable: true,                   
            has_overlay: false,                 
            close_on_outside_click: true,       
            x_adjustment: "0px",                
            y_adjustment: "0px",
        }
    }
    renderView() {
        this.modal_controller.retrieve_data()        
        return (
            <div className="relative">
                <Row className="row_padding">
                    <Col>
                        <Row className="row_one">
                            <Col>
                                <Row style={{paddingTop:"10px"}}>
                                    <Col className="titles_font">
                                        COMMODITY *
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.formObject({ data: this.state.form?.name, owner: this, controller: this.modal_controller})}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="auto"></Col>
                        </Row>
                        <Row className="row_wrapper">
                            <Col>
                                <Row className="titles_font max_row text_row">
                                    <Col>
                                        WEIGHT, LB
                                    </Col>
                                    <Col>
                                        VALUE, $
                                    </Col>
                                </Row>
                                <Row className="max_row input_row">
                                    <Col>
                                        {this.formObject({ data: this.state.form?.weight, owner: this, controller: this.modal_controller})}
                                    </Col>
                                    <Col>
                                        {this.formObject({ data: this.state.form?.value, owner: this, controller: this.modal_controller })}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="row_wrapper">
                            <Col>
                                <Row className="titles_font max_row text_row flex_wrap">
                                    <Col className="no_wrap">
                                        LENGTH, FT  
                                    </Col>
                                    <Col className="no_wrap">
                                        WIDTH, FT 
                                    </Col>
                                    <Col className="no_wrap">
                                        HEIGHT, FT 
                                    </Col>
                                </Row>
                                <Row className="max_row input_row">
                                    <Col>
                                        {this.formObject({ data: this.state.form?.length, owner: this, controller: this.modal_controller})}
                                    </Col>
                                    <Col>
                                        {this.formObject({ data: this.state.form?.width, owner: this, controller: this.modal_controller})}
                                    </Col>
                                    <Col>
                                        {this.formObject({ data: this.state.form?.height, owner: this, controller: this.modal_controller})}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <hr className="hr"/>
                <Row className="row_padding">
                    <Col>           
                        <Row className="row_wrapper pad_top_1rem">
                            <Col>
                                <Row className="generic_form_label max_row text_row">
                                    <Col style={{whiteSpace:"nowrap"}}>
                                        TRAILER TYPE NEEDED
                                    </Col>
                                </Row>
                                <Row className="r_pad input_row">
                                    <Col>
                                        {this.formObject({ data: this.state.form?.trailer_type, options: this.controller.trailer_types, owner: this, controller: this.modal_controller})}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row className="titles_font max_row text_row">
                                    <Col style={{whiteSpace:"nowrap"}}>
                                        SPECIAL EQUIPMENT
                                    </Col>
                                </Row>
                                <Row className="input_row">
                                    <Col>
                                        {this.formObject({ data: this.state.form?.special_equipment, options: this.controller.requirements, owner: this, controller: this.modal_controller})}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="row_wrapper">
                            <Col className="r_pad checkbox_col">
                                <Row>
                                    <Col className="titles_font flex_nowrap">
                                        {this.formObject({data: this.state.form?.hazmat, owner: this, controller: this.modal_controller})}
                                        Hazmat
                                    </Col>
                                </Row>
                                <Row 
                                // className="oversized_row"
                                >
                                    <Col className=" titles_font flex_nowrap">
                                        {this.formObject({data: this.state.form?.oversized, owner: this, controller: this.modal_controller})}
                                        Oversized
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row className="titles_font max_row text_row">
                                    <Col className="no_wrap">
                                        CONTAINER #
                                    </Col>
                                </Row>
                                <Row className="input_row">
                                    <Col>
                                        {this.formObject({ data: this.state.form?.container_number, owner: this, controller: this.modal_controller})}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="row_wrapper">
                        <Col>
                                <Row className="titles_font max_row text_row">
                                    <Col>
                                        # OF CASES
                                    </Col>
                                </Row>
                                <Row className="input_row">
                                    <Col>
                                        {this.formObject({ data: this.state.form?.number_of_cases, owner: this, controller: this.modal_controller})}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row className="titles_font max_row text_row" >
                                    <Col className="no_wrap">
                                        TEMPERATURE RANGE, F
                                    </Col>
                                </Row>
                                <Row className="input_row">
                                    <Col>
                                        {this.formObject({ data: this.state.form?.temperature_min, owner: this, controller: this.modal_controller})}
                                    </Col>
                                    <Col sm={1} className="pt_five">
                                        -
                                    </Col>
                                    <Col>
                                        {this.formObject({ data: this.state.form?.temperature_max, owner: this, controller: this.modal_controller})}
                                    </Col>
                                </Row>
                            </Col>  
                        </Row>
                        <Row className="row_wrapper">
                            <Col>
                                <Row className="titles_font max_row text_row">
                                    <Col>
                                        # OF PALLETS
                                    </Col>
                                </Row>
                                <Row className="input_row">
                                    <Col>
                                        {this.formObject({ data: this.state.form?.number_of_pallets, owner: this, controller: this.modal_controller})}
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="r_pad flex_wrap checkbox_col">
                                <Row>
                                    <Col className="titles_font flex_nowrap">
                                        {this.formObject({data: this.state.form?.pallets_exchange, owner: this, controller: this.modal_controller})}
                                        Pallets Exchange
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <hr className="hr"/>
                <Row>
                    <Col>
                    </Col>
                    <Col sm={3} className="button_min_w g-0">
                        <Button className={"center_button clear_button "+this._theme} onClick={() => this.modal_controller.cancel()} >
                            Cancel
                        </Button>
                    </Col>
                    <Col sm={3} className="button_min_w g-0">
                        <Button className={"center_button primary_button_coloring "+this._theme} onClick={() => this.modal_controller.save_action()} >
                            Save
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

