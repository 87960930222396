// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import StopModelController from "controllers/ModelControllers/StopModelController.jsx"
import ShipmentModelController from "controllers/ModelControllers/Shipping/ShipmentModelController.jsx"
import EquipmentModelController from "controllers/ModelControllers/Shipping/EquipmentModelController.jsx"
import CommodityModelController from "controllers/ModelControllers/Shipping/CommodityModelController.jsx"
import AssetModelController from "controllers/ModelControllers/AssetModelController.jsx"
import PersonModelController from "controllers/ModelControllers/PersonModelController.jsx"

export default class TripOptionController extends _viewController {
    constructor(props) {
        super(props)
        this.load           = new ShipmentModelController({params: {parent: this,
                                                                    controller: this,
                                                                    is_view: true}})
        this.commodity      = new CommodityModelController ({params: {parent: this,
                                                                        controller: this,
                                                                        is_view: true}})
        this.equipment      = new EquipmentModelController({params: {parent: this,
                                                                        controller: this,
                                                                        is_view: true}})
        this.pickup         = new StopModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true,
                                                                _param: "pickup"}})
        this.consignee      = new StopModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true,
                                                                _param: "consignee"}})
        this.truck          = new AssetModelController({params: {parent: this,
                                                                    controller: this,
                                                                    is_view: true,
                                                                    _param: "truck"}})
        this.trailer        = new AssetModelController({params: {parent: this,
                                                                    controller: this,
                                                                    is_view: true,
                                                                    _param: "trailer"}})                                                        
        this.driver         = new PersonModelController({params: {parent: this,
                                                                    controller: this,
                                                                    is_view: true}})
    }
    get total_rate() {
        let rate = this.load?.payment_rate ?? 0
        return rate
    }
    get labeled_dims() {
        let dims = this.calculate_commodity_totals()
        dims.height = this.reduce_size({value: dims.height, metric: false, label: true})
        dims.width  = this.reduce_size({value: dims.width, metric: false, label: true})
        dims.length = this.reduce_size({value: dims.length, metric: false, label: true})
        return dims
    }
    get trailer_type() {
        // REFACTOR to use model controller - Load or Asset?
        if(this.view.state?.data?.equipment?.trailer_types === undefined) { return "N/A" }
        if(this.view.state?.data?.equipment?.trailer_types?.length === 0) { return "N/A" }
        let id = this.view.state?.data?.equipment?.trailer_types?.[0]
        let tt = this.controller.trailer_types?.find(({ value }) => value === id)
        tt = (tt === undefined) ? "N/A" : tt.label
        return tt
    }
    calculate_commodity_totals() {
        let totals = { height: 0, length: 0, width: 0, weight: 0, value: 0 }
        this.view.state?.data?.shipment?.commodities?.map((com, index) => {
            totals.height += com?.height ?? 0
            totals.length += com?.length ?? 0
            totals.width += com?.width ?? 0
            totals.weight += com?.weight ?? 0
            totals.value += com?.value ?? 0
        })
        return totals
    }
    process_load_data() {
        // let load = this.view?.state?.data?.shipment
        let load = this.view?.props?.params?.data?.shipment
        if(load === undefined) { return }
        load = JSON.parse(JSON.stringify(load))
        // console.log(" --- load*", this.view.state.index, load)
        this.view.state.data = {
            shipment: load,
            pickup: load?.pickup,
            consignee: load?.dropoff,
            equipment: load?.equipment,
            commodity: load?.commodities?.[0]
        }
    }
    linked_key(option) {
        switch (option) {
            case "person":
                return "drivers"
            case "truck":
                return "trucks"
            case "trailer": 
                return "trailers"       
        }
    }
    remove_linked(data, type) {
        let obj = JSON.parse(JSON.stringify(data))
        if(type !== undefined) {
            this.controller.view.state.linked[type] = false
        }
        if(obj?.driver?.id !== undefined && obj?.driver?.id !== null) {
            let driver = obj?.driver
            this.controller.view.state.linked.drivers = false
            this.remove_linked(driver)
        }
        if(obj?.linked_asset?.id !== undefined && obj?.linked_asset?.id !== null) {
            let linked_asset = obj?.linked_asset
            let asset_type = linked_asset?.asset_type?.toLowerCase() +"s"
            this.controller.view.state.linked[asset_type] = false
            this.remove_linked(linked_asset)
        }
        if(obj?.asset?.id !== undefined && obj?.asset?.id !== null) {
            let asset = obj?.asset
            let asset_type = asset?.asset_type?.toLowerCase() +"s"
            this.controller.view.state.linked[asset_type] = false
            this.remove_linked(asset)
        }
    }
    remove_assigned() {
        // truck and trailer combo does have a driver but all keys are undefined (so it doesnt have a driver). So why does it put it into drivers?
        let data = this.view.state.data?.[this.view.data_key]
        if(this.view.data_key !== "shipment") {
            this.remove_linked(data, this.linked_key(this.view.data_key))
        }
        // MAKE SURE THIS WORKS FOR RESET IN TRIP BUIlDER PARTIAL
        // for when editing a trip occurs and an option is removed:
        if(this.controller.view?.state?.trip_id !== undefined) {
            // ensure that it does not already exist in the options so duplicates are not made.
            if(!JSON.stringify(this.controller.view.state[this.view.state.option]).includes(data?.id)) {
                if(this.view.data_key !== "shipment") {
                    let obj = this.format_option_data(data)
                    if(this.notnull(obj?.driver?.id)) {
                        this.controller.view.state.drivers.push(obj.driver)
                    }
                    if(this.notnull(obj?.truck?.id)) {
                        this.controller.view.state.trucks.push(obj.truck)
                    }
                    if(this.notnull(obj?.trailer?.id)) {
                        this.controller.view.state.trailers.push(obj.trailer)
                    }
                } else {
                    this.controller.view.state[this.view.state.option].push(data)
                }
            }
        }
        this.controller.trip[this.view.state.option]?.splice(this.view.state.index, 1)
        this.view.forceUpdate()
        this.controller.view.forceUpdate()
    }
    copy_obj(obj) {
        return JSON.parse(JSON.stringify(obj))
    }
    format_truck(data) {
        if(!this.notnull(data)) { return undefined }
        let obj = this.copy_obj(data)
        if(obj?.asset_type === "TRUCK") { return obj }
        if(this.notnull(obj?.linked_asset?.id)) {
            let trailer = this.copy_obj(obj)
            delete trailer.linked_asset
            obj = this.copy_obj(obj.linked_asset)
            obj.linked_asset = trailer
            return obj
        } else if(this.notnull(obj?.asset?.id)) {
            let driver = this.copy_obj(obj)
            delete driver.asset
            obj = this.copy_obj(obj.asset)
            obj.driver = driver
            return obj
        }
    }
    format_trailer(data) {
        if(!this.notnull(data)) { return undefined }
        let obj = this.copy_obj(data)
        if(obj?.asset_type === "TRAILER") { return obj }
        if(this.notnull(obj?.linked_asset?.id)) {
            let truck = this.copy_obj(obj)
            delete truck.linked_asset
            obj = this.copy_obj(obj.linked_asset)
            obj.linked_asset = truck
            return obj
        } else if(this.notnull(obj?.asset?.linked_asset?.id)) {
            let driver = this.copy_obj(obj)
            obj = this.copy_obj(obj.asset?.linked_asset)
            if(this.notnull(driver?.asset?.id)) {
                let truck = this.copy_obj(driver.asset)
                delete driver.asset
                truck.driver = driver
                obj.linked_asset = truck
                return obj
            }
            return obj
        }
    }
    format_driver(data) {
        if(!this.notnull(data)) { return undefined }
        let obj = this.copy_obj(data)
        if(this.notnull(obj?.driver?.id)) {
            let truck = this.copy_obj(obj)
            delete truck.driver
            obj = this.copy_obj(obj.driver)
            obj.asset = truck
            return obj
        } else if(this.notnull(obj?.linked_asset?.driver?.id)) {
            let trailer = this.copy_obj(obj)
            obj = this.copy_obj(obj?.linked_asset?.driver)
            if(this.notnull(trailer?.linked_asset?.id)) {
                let truck = this.copy_obj(trailer.linked_asset)
                delete trailer.linked_asset
                truck.linked_asset = trailer
                obj.asset = truck 
                return obj
            }
            return obj
        } else if(this.notnull(obj.asset_type)) {
            // ensures obj is not an asset and is indeed a driver.
            return undefined
        }
        return obj
    }
    format_option_data(data) {
        let obj = {}
        obj.driver = this.format_driver(data)
        obj.truck = this.format_truck(data)
        obj.trailer = this.format_trailer(data)
        return obj
    }
    set_size(size) {
        if(this.view.state.size === undefined) {
            this.view.setState({size: size})
        }
    }
    notnull(obj) {
        if (obj !== undefined && obj !== null) {
            return true
        }
        return false
    }
    determine_size() {
        let data = this.view.state.data?.[this.view.state.data_key]
        let asset = (this.notnull(data?.asset?.id)) ? data?.asset : undefined
        let driver = (this.notnull(this.view.state.data?.person?.id)) ? this.view.state.data?.person : data?.driver
        let linked_asset = (!this.notnull(data?.asset?.id)) ? data.linked_asset : asset.linked_asset
        if(this.notnull(linked_asset?.driver?.id)) { driver = linked_asset.driver }
        let count = 0
        let size = "full"
        if (this.view.state.data_key !==  "shipment") {
            size = "large"
            if(this.view.state.data_key !== "person") {
                if (this.notnull(driver?.id)) {
                    count = count + 1
                }
            }
            if (this.notnull(data?.id)) {
                count = count + 1
            }
            if (this.notnull(asset?.id)) {
                count = count + 1
            }
            if (this.notnull(linked_asset?.id)) {
                count = count + 1
            }
            if (count === 2) {
                size = "medium"
            }
            if (count === 1) {
                size = "small"
            }
            if (this.view?.state?.formatting) {
                size = size + " abs "
                if (this.notnull(driver?.id)) {
                    if (count !== 3) {
                        size = size + " minibuffer "
                    } else {
                        size = size + " thinbuffer "
                    }
                } else {
                    if (data?.asset_type === "TRAILER" && !this.notnull(linked_asset?.id)) {
                        size = size + " extrabuffer "
                    } else {
                        size = size + " buffer "
                    }
                }
            }
        }
        return size
    }
}
