// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"

// MODEL CONTROLLERS
import ShipmentModelController from "controllers/ModelControllers/Shipping/ShipmentModelController.jsx"
import StopModelController from "controllers/ModelControllers/StopModelController.jsx"

export default class LoadDetailsPartialController extends _viewController {
    constructor(props) {
        super(props)
        this.load           = new ShipmentModelController({params: {parent: this,
                                                                    controller: this, 
                                                                    is_view: true}})
        this.pickup         = new StopModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true,
                                                                _param: "pickup"}})
        this.dropoff        = new StopModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true,
                                                                _param: "dropoff"}})
    }
} 