// GENERAL REACT MODULES
import { Col } from 'react-bootstrap'
import ListLineController from "components/CotaListPanel/ListLineController.jsx"

// OBJECT SPECIFIC MODULES
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { BiReset } from "react-icons/bi"

export default class ListHeaderController extends ListLineController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    add_item() {
        this.view.controller.add_line_item()
    }
    get item_data() {
        return this.view.item_data
    }
    get _configs() {
        return this.view._configs
    }
    get_column_color({configs, item, index}) {
        if (configs?.sort !== true) {
            if (configs?.pk?.indexOf(item) > -1) { return "pk_color" }
        }
        if (configs?.sort) {
            if (this.view.state?.sort === index) {
                return "sortable pk_color"
            }
            return "sortable font_color"
        }
        return "font_color"
    }
    select_sort(index) {
        let direction = (this.state.sort === index) ? !this.state.direction : true
        this.setState({sort: index, direction: direction})
        this.controller.setState({key: "direction", value: direction})
        this.controller.view.props.params.filters.sortby = Object.keys(this.item_data)?.[index]
        this.controller.view.props.params.filters.dir = direction
        this.controller.setState({key: "sort_by", value: Object.keys(this.item_data)?.[index]})
        this.controller?.view?.forceUpdate()
    }
    add_sort_btn({configs, index}) {
        if (configs?.sort) {
            if (this.view.state?.sort === index) {
                if (this.view.state?.direction) {
                    return <RiArrowDownSFill />
                } else {
                    return <RiArrowUpSFill />
                }
            }
        }
    }
    build_label({configs, label, index}) {
        let rename  = (configs?.headers?.rename?.filter(type => this.lc(type.n) === this.lc(label)))?.[0]
        if (rename !== undefined) {
            return rename?.v
        }
        return label
    }
    map_columns() {
        if (this.item_data !== undefined) {
            return Object.keys(this.item_data).map((item, index) => {
                // style={{"minWidth": widths?.w+"px", "maxWidth": (widths?.mw !== undefined) ? widths?.mw+"px" : undefined }}
                let widths  = (this._configs?.columns?.widths?.filter(type => this.lc(type.n) === this.lc(item)))?.[0]
                if (this._configs?.hide?.indexOf(item) > -1) { return }
                if (this.is_string(this.item_data?.[item]) || this.is_number(this.item_data?.[item]) || null || this.is_obj(this.item_data?.[item])) {
                    return (
                        <Col className={"center_text font_style "+this.get_column_color({configs: this._configs, item: item, index: index})} key={"header_"+index}
                            onClick={this.select_sort.bind(this.view, index)} style={{"minWidth": widths?.w+"px", "maxWidth": (widths?.mw !== undefined) ? widths?.mw+"px" : undefined }}>
                            {this.build_label({configs: this._configs, label: item, index: index}).replace(/_/g," ")} {this.add_sort_btn({configs: this._configs, index: index})}
                        </Col>
                    )
                }
                return (<></>)
            })
        }
    }
    add_button() {
        if (this._configs?.button !== undefined) {
            return (
                <Col className="cota_table_button">
                </Col>
            )
        }
        return
    }
    self_filter() {
        if (this.view.state?.line_filter) {
            return (
                <Col className="filter_btn">
                    {this.self_fitler_btn()}
                </Col>
            )
        }
    }
    self_fitler_btn() {
        if (this.view?.controller?.view?.state?.self_filters.length > 0) {
            return <BiReset className="pointer" onClick={this.reset_filter.bind(this)}/>
        }
    }
    reset_filter() {
        this.view.controller.view.state.self_filters = []
        this.view.controller.view.forceUpdate()
        this.view.forceUpdate()
    }
    add_actions() {
        if (this.view.state?.actions !== undefined) {
            return (
                <Col className="action_btn" key={"add_actions_"+this.view.state?.index}>
  
                </Col>
            )
        }
    }
}
