// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import LineItemModelController from "controllers/ModelControllers/Quotes/LineItemModelController.jsx"

import remove_item from "assets/graphql/Rate_items/rate_items.remove.mutation.graphql.json"

export default class LineItemController extends _viewController {
    constructor(props) {
        super(props)
        this.line_item = new LineItemModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true}})
    }
    delete_item() {
        if(this.partial_controller.line_item?._id !== undefined) {
            this.partial_controller.delete_rate_item()
            this.controller.view.state.data.shipment.rate_items.splice(this.state?.line_index, 1)
        }
        if (this.state?.line_index !== undefined) {
            this.controller.view.state.line_items.splice(this.state?.line_index, 1)
            this.controller.view.forceUpdate()
            this.state?.parent.forceUpdate()
        }
    }
    delete_rate_item() {
        let data = {
            load_id: this.controller.load._id,
            id: this.line_item?._id
        }
        data    = this.toUnquotedJSON(data)
        let body = JSON.parse(JSON.stringify(remove_item)) 
        body.query  = body.query.replace("input", 'input:'+data)
        // console.log(body.query)
        this.api.quotes.ask({caller: this, params: {body: body}, callback: {f: this.process_delete}})
    }
    process_delete({caller, params, results}) {
        if(results?.errors !== undefined) {
            console.log("Error deleting rate item", results?.errors) 
            return
        }
        caller.view.controller.view.state.data.line_items.splice(caller.view.state?.line_index, 1)
        // console.log("Rate item deleted")
    }
    view_field({data}) {
        return this.get_object_param({key: data?.title, param: data?.name})
    }
    resolve_item_name({data}) {
        let option = (this.view.rateOptions.filter((item) => { return item.value === data}))?.[0]
        return option?.label
    }
}