import _viewController from "controllers/ViewController/_viewController.jsx"
import PaymentDetailsModelController from "controllers/ModelControllers/Loads/PaymentDetailsModelController.jsx"

export default class PaymentDetailController extends _viewController {
    constructor (props) {
        super(props)
        this.payment_details = new PaymentDetailsModelController({params: {parent: this,
                                                                                controller: this,
                                                                                is_view: true}})
    }
    get total_rate() {
        // GET THE LINEHAUL AS A FLAT RATE // Handled in ViewLoadPanelController
        // let rate = this.controller?.load?.linehaul ?? 0
        // if(rate === 0) { rate = this.controller?.load?.calculated_rate }
        // if(this.controller?.line_items?.length > 0) {
        //     rate = this.controller?.load?.rate
        // }
        let rate = this.controller?.load?.payment_rate ?? 0
        rate = this.format_rate(rate)
        return rate
    }
    format_rate(rate) {
        return Number.parseFloat(rate).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    check_basic_items() {
        let items = this.controller?.view?.state?.line_items
        if(items?.length < 3 && !this.view?.state?._toggled_modal) {
            this.controller.view.state.line_items = this.controller?.add_basic_items({items: items})
        }
    }
}