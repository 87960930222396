// OBJECT SPECIFIC MODULES
import test_data from "./test.json"
import schema from "./schema.json"

// ALL OTHER MODULES
import _baseApiObject from "../../../../components/General/_baseApiObject";
import events_request from "assets/graphql/Events/events.query.graphql.json"
import min_request from "assets/graphql/Events/events.min.graphql.json"

export default class Events extends _baseApiObject {
    constructor(props) {
        super(props)
        this.schema     = schema
        this.test_data  = test_data
        this.requests   = {
            general: events_request,
            min: min_request
        }
    }
}
