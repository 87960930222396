// GENERAL REACT MODULES
import React from "react";
import { Row, Col } from 'react-bootstrap';

// PAGE SPECIFIC MODULES
import _baseViewComponent from "components/General/_baseViewComponent.jsx"

export default class DetailsIcon extends _baseViewComponent {
    constructor(props) {
        super(props)
        this.controller = props.params?.controller
        this.data = this.controller.getState({key: "details"})
    }
    render() {
        return(
            <Row className="g-0 icon_row" id={this.name}>
                <Col className="g-0 image_wrapper">
                    <img className="image" src={this.data?.image} alt="Cota Details"/>
                </Col>
            </Row>
        )
    }
}