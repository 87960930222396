// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import invoice_mutation    from "assets/graphql/Invoice/invoice_mutation.json"
export default class BillingDetailsController extends _viewController {
    constructor(props) {
        super(props)
  
    }





}
