// GENERAL REACT MODULES
import React from "react";
import { FiSettings } from "react-icons/fi";
import { FaUserCircle } from "react-icons/fa";
import { ProSidebar, SidebarHeader } from 'react-pro-sidebar';
import { Container, Row, Col } from 'react-bootstrap'
import { GiAbstract050 } from "react-icons/gi";
import { RiAdminFill, RiLogoutCircleRLine } from "react-icons/ri";
import { BsLightbulb, BsQuestionCircle, BsFillPeopleFill } from "react-icons/bs";
// import FeedBearWidget from "components/Integrations/FeedBearWidget/FeedBearWidget.view.jsx";
// import HelpPartial from "pages/Help/HelpPartial/Help.partial.jsx";
import { GiHamburgerMenu } from "react-icons/gi";
import WideIconImage from "assets/logo.png"

// PAGE SPECIFIC MODULES
import Controller from "./Sidebar.controller.jsx";
import "./Sidebar.scss"

// ALL OTHER MODULES
import _baseViewComponent from "/opt/app/src/components/General/_baseViewComponent.jsx"
import ModuleContext from "module/controllers/ModuleContext.jsx";
class Sidebar extends _baseViewComponent {
    constructor(props) {
        super(props)
        this.state.expanded = false
        this.state.loads_expanded = false
        this.controller     = new Controller({params: {view: this}})
        this.state.display  = (this.state.display === undefined) ? "block" : this.state.display 
    }
 followDidMount() {
      this.load_self(this.state.context)
        if (this.context.baseMenus === undefined) {
            if (!process.env.REACT_APP_NOLOGIN || process.env.REACT_APP_DEVMODE) {
                this.context.baseMenus = [{name: "Settings", icon: <FiSettings className="menuListIcon"/>, internal: true, borders: ["Top"], func: this.controller.expand_settings},
                                            {name: "User Details", icon: <FaUserCircle className="menuListIcon"/>, internal: true, bound: "expanded", link: "/settings"},
                                            {name: "Admin", icon: <RiAdminFill className="menuListIcon"/>, internal: true, bound: "expanded", link: "/admin", restrict: "settings"},
                                            {name: "Manage Users", icon: <BsFillPeopleFill className="menuListIcon"/>, internal: true, bound: "expanded", link: "/manage-users", restrict: "settings"},
                                            {name: "Logout", icon: <RiLogoutCircleRLine className="menuListIcon"/>, internal: false, bound: "expanded", request: "POST", restrict: "settings",
                                                link: "http://auth."+process.env.REACT_APP_DOMAIN+"/auth/realms/cotaall/protocol/openid-connect/logout?redirect_uri=http://freight."+process.env.REACT_APP_DOMAIN},
                                            {name: "How to Use", icon: <BsQuestionCircle className="menuListIcon"/>, internal: true, link: "/help"},
                                            // {name: "How to Use", icon: <HelpPartial params={{parent: this, controller: this.controller}}/>, internal: true},
                                            // {name: "Request Features", icon: <FeedBearWidget />, internal: true, link: "", integration: "feedbear"}
                                            {name: "Request Features", icon: <BsLightbulb className="menuListIcon"/>, internal: true, link: "/feedback"}]
            }
            this.context.menus.push(this.context.baseMenus)
        }
        this.setState({name: "Sidebar",
                        selection: null,
                        menus: this?.context?.getMenu()})
    }
    map_menu_items(menus) {
        return menus?.map((menu, index) => {
            return (
                <>
                {
                    menu?.map((menu_item, index) => {
                        return this.controller.generate_row_item({roles: this.state?.authClient?.realmAccess?.roles, menu_item: menu_item, index: index})
                    })
                }
                {
                    this.map_menu_items(menu?.subitems)
                }
                </>
            )
        })
    }
   load_self(context) {
        this.state.loading = true
        context.get_self({caller: this.controller})
    }
    get collapsed() {
        /*
        if (!this.state.context?.is_mobile() && this.controller?.context?.self?.settings?.menu_open !== undefined ) {
               this.state.menuCollapse = !this.controller.context.self?.settings?.menu_open 
            return !this.controller?.context?.self?.settings?.menu_open
        }
        */
        return false
    }
    get sidebar_style() {
        if (this.state.context?.is_mobile()) {
            return "full_screen"
        }
        return ""
    }
    get viewable() {
        if (this.state.context?.is_mobile()) {
            return this.state.display
        }
        return ""
    }
    renderView() {
        if (this.state.context?.is_mobile()) {
            if (this.state.display === "none") {
                return (
                    <Row id="top_row">
                        <Col>
                            <img src={WideIconImage} className="center_item" alt="Cota Systems"/>
                        </Col>
                        <Col sm={1} className="menu">
                            <GiHamburgerMenu size={20} onClick={this.controller.toggle_menu.bind(this.controller)}/>
                        </Col>
                    </Row>
                )
            }
        }
        this.state.context.sidebar = this
        return(
            <div id="sidebarWrapper" className={"sidebar_color "+this?._theme} style={{"display": this.viewable}}>
                <ProSidebar collapsed={this.collapsed} className={this.sidebar_style}>
                    <Row className="g-0" onClick={this.controller.menuIconClick}>
                        <Col>
                        <SidebarHeader>
                            {
                                this.collapsed ? (
                                    <GiAbstract050 className="sidebarHeaderIcon"/>) : (
                                    <div className="sidebarHeaderLabel sidebar_header_label_color">
                                        {this.controller.get_title()}
                                    </div>)
                            }
                        </SidebarHeader>
                        </Col>
                    </Row>
                    <Row className="g-0">
                        {
                            this.map_menu_items(this.state?.menus)
                        }
                    </Row>
                    <Row className="g-0 empty_block" onClick={this.controller.menuIconClick}>
                        <Col>
                            &nbsp;
                        </Col>
                    </Row>
                    <Container className="sidebar_icon_row">
                        <Row>
                            <Col>
                                <Row>
                                    <Col className="icon_contents">
                                        {this.controller.get_icon()}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </ProSidebar>
            </div>
        )
    }
}
Sidebar.contextType = ModuleContext
export default Sidebar;