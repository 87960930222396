// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import self_request from "assets/graphql/Users/self.graphql.json"
import _viewController from "controllers/ViewController/_viewController.jsx"
import User from "controllers/ModelControllers/UserModelController"

export default class ContactsController extends _viewController {
    constructor (props) {
        super(props)
        this.user           = new User({params: {parent: this,
                                                    controller: this,
                                                    is_view: true}})
        this.requests       = [{request: self_request, 
                                callback: {f: this.process_user, p: {name: "self"}}, endpoint: "users"},
                                {callback: {f: this.state_results, p: {name: "areas", var: ["areas", "states"]}}, 
                                endpoint: "areas", replace: {o: "params:", r:'params: {country: "US"}'}},
                                {callback: {f: this.state_results, p: {name: "areas", var: ["areas", "states"]}}, 
                                    endpoint: "areas", replace: {o: "params:", r:'params: {is_country: true}'}}]
    }
    load_harness() {
        // console.log(this)
    }
    process_user({caller, params, results}) {
        if(results?.errors === undefined && results?.data?.self !== undefined) {
            caller.view.state.data.user = caller.getCopy(results.data.self)
            caller.view.state.cache = caller.getCopy(caller.view.state.data.user)
        }
    }
    state_results({caller, params, results}) {
        let states = []
        let countries = []
        if (results?.errors === undefined && results?.data?.areas !== undefined) {
            results.data.areas.map((area, index) => {
                if(area.state !== null) {
                    states.push({ value: area.state, label: area.state })
                } else {
                    countries.push({ value: area.country, label: area.country })
                }
            })
            if(states.length > 0) {
                caller.view.state.states = states
            }
            if(countries.length > 0) {
                caller.view.state.countries = countries
            }
        }
    }
    resolveCache() {
        if (this.view.state?.cache !== undefined) {
            this.view.state.context.user    = this.getCopy(this.view?.state?.cache)
            this.setState({key: "data", param: "user", value: this.getCopy(this.view?.state?.cache)})
            this.view.forceUpdate()
        }
    }
    processEventReaction(decoded) {
        // delete this.view.state?.contacts_search?.search_contacts
        // this.resetRoleFilter()
        this.setState({key: "data", value: decoded.message.data})
        this.view.handleScreen({path_num: 0, screen_num: 0, data: decoded.message.data})
        // console.log(decoded)
    }
    follow_on_selection({event, obj, data}) {
        this.view.empty_results()
        this.setState({key: "data", value: obj})
        this.view.handleScreen({path_num: 0, screen_num: 0, data: obj})
    }
    newContactButton() {
        delete this.view.state?.contacts_search?.search_contacts
        this.resetRoleFilter()
        this.view.handleScreen({path_num: 0, screen_num: 1})
    }
    handleRoleClick(event) {
        let text = event.target.textContent.toLowerCase()
        let roles = this.controller.updateRoleFilter(text)
        this.setState(prevState => ({
            [text]: prevState?.[text] === "" ? text : "",
            role_filter: roles
        }))
    }
    updateRoleFilter(text) {
        let roles = JSON.parse(JSON.stringify(this.view.state.role_filter))
        let index = roles.indexOf(text)
        if(index > -1) {
            roles.splice(index, 1)
        } else {
            roles.push(text)
        }
        return roles
        // this.view.setState({role_filter: roles})
    }
    resetRoleFilter() {
        this.view.setState({
            role_filter: ["payor", "shipper", "consignee"],
            payor: "payor",
            shipper: "shipper",
            consignee: "consignee"
        })
    }
    get user_filter() {
        if (this.view?.state?.alpha !== undefined) {
            return this.view.state.alpha
        }
        return ""
    }
}