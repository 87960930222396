// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx";
import Controller from "./AddLogModal.controller.jsx"
import "./AddLogModal.css"
import form_configs from "../TripLogs.json"
import UploadPartial from "components/FileUpload/Upload.partial.jsx"

export default class AddLogModal extends _baseModal {
    constructor(props) {
        super(props)
        this.modal_controller   = new Controller ({params: {view: this, controller: this?.controller}})
        this.state.dims         = {width: 636, height: 980}
        this.state.form         = form_configs.form_objects
        this.state.modal_params = {
            positioned: "middle_of_panel",   
            title: "New Trip Log",                
            title_size: "",
            draggable: false,                     
            closeable: true,                    
            has_overlay: true,                 
            close_on_outside_click: true,       
            x_adjustment: "0px",                
            y_adjustment: "0px",
        }
    }
    get states() {
        return this.controller.states
    }
    // WANT to make a cool drag and drop, using this little render as a prototype, will return to this.
    render_file_drop() {
        return (
            <Row>
                <Col>
                </Col>
            </Row>
        )
    }
    renderView() {
        // console.log(this)
        return (<>
            <Row key={"add_log_"+this.state.parent.state.reset} className="pad_top">
                <Col>
                    <Row>
                        <Col className="type">
                            type
                        </Col>
                    </Row>
                    <Row className="pad_top_25">
                        <Col>
                            {this.formObject({data: this.state.form.type, controller: this.modal_controller, 
                                    options: form_configs.log_types, owner: this})}
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col className="type">
                            value
                        </Col>
                    </Row>
                    <Row className="pad_top_25">
                        <Col>
                            {this.formObject({data: this.state.form.value, controller: this.modal_controller, owner: this})}  
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="pad_top">
                <Col className="type">
                    location
                </Col>
            </Row>
            <Row className="pad_top_25">
                <Col>
                    {this.formObject({data: this.state.form.address, controller: this.modal_controller, owner: this})}            
                </Col>
            </Row>
            <Row className="pad_top">
                <Col>
                    <Row>
                        <Col className="type">
                            city
                        </Col>
                    </Row>
                    <Row className="pad_top_25">
                        <Col>
                            {this.formObject({data: this.state.form.city, controller: this.modal_controller, owner: this})}            
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col className="type">
                            state
                        </Col>
                    </Row>
                    <Row className="pad_top_25">
                        <Col>
                            {this.formObject({data: this.state.form.state, options: this.states, controller: this.modal_controller, owner: this})}            
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col className="type">
                            zip
                        </Col>
                    </Row>
                    <Row className="pad_top_25">
                        <Col>
                            {this.formObject({data: this.state.form.zip, controller: this.modal_controller, owner: this})}            
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* <Row className="pad_top">
                <Col>
                    <Row>
                        <Col className="type">
                            date*
                        </Col>
                    </Row>
                    <Row className="pad_top_25">
                        <Col>
                            {this.formObject({data: this.state.form.date, controller: this.modal_controller, owner: this})}  
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col className="type">
                            time*
                        </Col>
                    </Row>
                    <Row className="pad_top_25">
                        <Col>
                            {this.formObject({data: this.state.form.time, controller: this.modal_controller, owner: this})}  
                        </Col>
                    </Row>
                </Col>
            </Row> */}
            <Row className="pad_top">
                <Col className="type">
                    notes
                </Col>
            </Row>
            <Row className="pad_top_25">
                <Col>
                    {this.formObject({data: this.state.form.note, controller: this.modal_controller, owner: this})}            
                </Col>
            </Row>
            <Row className="mt-4">
                <UploadPartial params={{parent: this, controller: this.modal_controller, endpoint: "event", parent_override: this.state.parent}} />
            </Row>
            {/* NEED to get documents working on here, maybe make this another type of document upload view / add to partial? */}
            {/* <Row className="pad_top">
                <Col className="files">
                    files
                </Col>
            </Row> */}
            {/* <Row className="file_types">
                <Col>
                    Choose .png, .jpg, .jpeg, or .pdf files that are not bigger than 15 MB.
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card onClick={this.handleClick} className="ta_center">
                        <Row className="g-0 ta_center">
                            <Row>
                                <Col className="add_files">
                                    +
                                </Col>
                            </Row>
                            <Row>
                                <Col className="add_files no_wrap">
                                    add files
                                </Col>
                            </Row>
                        </Row>
                    </Card>
                </Col>
            </Row> */}
            <hr/>
            <Row>
                <Col></Col>
                <Col>
                    <Button className={"center_button clear_button "+this._theme} onClick={() => this.modal_controller.cancel()} >
                        Cancel
                    </Button>
                </Col>
                <Col>
                    <Button className={"center_button btn_add"} onClick={() => this.modal_controller.submit_action()} >
                        Add
                    </Button>
                </Col>
            </Row>
        </>)
    }
}