//GENERAL REACT MODULES
import React from "react"
import { Row, Col, Button } from "react-bootstrap"

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"

export default class Note extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller     = props.params.controller
    }
    renderView() {
        return (
            <Row className="g-0">
                <Col>
                    <Row className="g-0">
                        <Col>
                            {this.state.note.note_text}
                        </Col>
                    </Row>    
                </Col>
                <Col>
                     <Row className="g-0">
                        <Col>
                            <strong>
                                {this.state.note?.timestamp ?? "Time"}
                            </strong>
                        </Col>
                     </Row>
                     <Row className="g-0">
                        <Col>
                            <Button className="clear_button" onClick={() => this.partial_controller.remove_note(this.state.index)}>
                                Delete
                            </Button>
                        </Col>
                     </Row>
                </Col>
            </Row>
        )
    }
}