// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx"

export default class BlockDriverModal extends _baseModal {
    constructor(props) {
        super(props)
        this.state.modal_params = {
            positioned: "middle_of_panel",
            title: "",                         
            title_size: "",                     
            closeable: true,                    
            has_overlay: true,                  
            close_on_outside_click: true,      
            x_adjustment: "0px",                
            y_adjustment: "-100px",                
            draggable: false,                              
        }
        this._message = ""
        this._action = null
        this._ok_button_only = false
    }

    get status() {
        return this.controller?.driver?.status?.toLowerCase()
    }

    get message() {
        return this._message
    }

    set message(message){
        this._message = message
    }

    get action() {
        return this._action
    }

    set action(action){
        this._action = action
    }

    unblock_driver(){
        // this.parent.controller.load.status = "Available"
        this.parent.toggle_modal({toggle_state: false})
        // this.parent.toggle_modal.bind(this.parent)
        this.controller.blockDriverToggle()
    }

    block_driver(){
        // this.parent.controller.load.status = "Canceled"
        this.parent.toggle_modal({toggle_state: false})
        // this.parent.toggle_modal.bind(this.parent)
        this.parent?.controller.blockDriverToggle()
    }

    determine_modal() {
        console.log('status is', this.status)
        switch (this.status) {
            case "blocked":
                this._ok_button_only = false;
                this.action = this.unblock_driver
                this.message = <div className="text-center">Would you like to unblock this driver? <br/>This will allow them to be dispatched on to trips and view company data.</div>
                break;
            case "available":
                this._ok_button_only = false;
                this.action = this.block_driver
                this.message = <div className="text-center">Would you like to block this driver? <br/>This will prevent them from being dispatched on to trips or viewing company data.</div>
                break;
            default:
                this._ok_button_only = true;
                this.message = <div className="text-center">This driver is currently dispatched on a trip.<br/>Please remove them from their current trip prior to blocking.</div>

        }
    }

    renderButtons(){
        if(this._ok_button_only){
            return (
                <Row className="pt-4">
                    <Col className="flex_centered">
                        <Button className="primary_button_coloring cota" 
                                onClick={this.parent.toggle_modal.bind(this.parent)}
                        >
                            OK
                        </Button>
                    </Col> 
                </Row>
            ) 
        }
        return (
            <Row className="pt-4">
                <Col>
                    <Button className="clear_button cota"  onClick={this.parent.toggle_modal.bind(this.parent)}>
                        No
                    </Button>
                </Col>
                <Col>
                    <Button className="primary_button_coloring cota" 
                            onClick={this.action.bind(this)}
                    >
                        Yes
                    </Button>
                </Col>
            </Row>
        )
    }

    renderView() {
        this.determine_modal()
        console.log(this)
        return (
            <Row className="flex_centered p-3">
               <Col>
                    <Row>
                        <Col className="flex_centered description">
                            {this.message}
                        </Col>
                    </Row>
                    {this.renderButtons()}
               </Col>
            </Row>
        )
    }
}
