//GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button } from "react-bootstrap";

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx";
import Modal from "./AddStopPeople.modal.jsx"
import "./AddStopPeople.css"

export default class AddStopPeoplePartial extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = this.state?.partial_controller
        this._modal = Modal
    }
    get type() {
        return this.partial_controller.view.type
    }
    render_rep_name() {
        let rep = this.partial_controller[this.type]?.rep
        return (rep !== undefined) ? rep.first_name + " " + rep.last_name : "Contact Person"
    }
    renderView() {
        return (
            <Row id={this.name}>
                <Col>
                    <Button className="w-100 dropdown_btn dropdown-toggle" onClick={() => this.toggle_modal({toggle_state: true})}>
                        {/* Contact Person */}
                        {this.render_rep_name()}
                    </Button>
                </Col>
            </Row>
        )
    }
}