// GENERAL REACT MODULES

//MODULE SPECIFIC
import _viewController from "controllers/ViewController/_viewController.jsx"
import PersonModelController from "controllers/ModelControllers/PersonModelController.jsx"
import update_contact from "assets/graphql/Contacts/Contacts.update.graphql.json"


export default class EditPersonModalController extends _viewController {
    constructor(props) {
        super(props)
        this.person = new PersonModelController({params: {parent: this,
                                                        controller: this.controller,
                                                        index: this.view.state.index ?? 1,
                                                        is_view: true}})
        // this.person = this.view.state.parent.state.person
        // this.person = this.controller.personModels[this.view.state.index]
    }
    set_model_controller() {
        this.person = new PersonModelController({params: {parent: this,
                                                    controller: this.controller,
                                                    index: this.view.state.index ?? 1,
                                                    is_view: true}})
    }
    action_new_person() {
        if (this.modal_controller.person._sum > 0) {
            if(this.modal_controller.person.validateContact()) {
                // this.modal_controller.new_person()
            }
        }
        this.state.parent.toggle_modal({toggle_state: false})
    }
    cancel() {
        this.view.state.parent.toggle_modal({toggle_state: false})
        let backup = this.view.state.person_backup
        this.person.first_name = backup.first_name
        this.person.last_name = backup.last_name
        this.person.phone = backup.phone
        this.person.email = backup.email
        this.controller.view.forceUpdate()
    }
}