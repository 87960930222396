// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "./_ModelController";
import CompanyModelController from "./CompanyModelController";

export default class UserModelController extends _ModelController {
    constructor(props) {
        super(props)
        this.company = new CompanyModelController({ params: { parent: this, controller: this.controller } })
        let get_active_role = this.get_active_role.bind(this)
        let get_first_name = this.get_first_name.bind(this)
        let get_last_name = this.get_last_name.bind(this)
        this.role = {
            get active() {
                return get_active_role()
            },
            get a() {
                return get_active_role()
            },
            get first() {
                return get_active_role((get_active_role())?.[0])
            }
        }
        this.name = {
            get full() {
                return get_first_name() + " " + get_last_name()
            },
            get first() {
                return get_first_name()
            },
            get last() {
                return get_last_name()
            }
        }
    }
    get_company_ids(index) {
        if (index === undefined) {
            return this.data.company_ids
        }
        return this.companies?.[index]
    }
    get_role_ids(index) {
        if (index === undefined) {
            return this.data.role_ids
        }
        return this.roles?.[index]
    }
    get_active_role() {
        if (this.data?.roles !== undefined) {
            return this.data?.roles[Object.keys(this.data?.roles).filter((key) => this.data?.roles[key].status === "ACTIVE" || this.data?.roles[key].status === "ASSIGNED")]
        }
        return undefined
    }
    get_first_name() {
        if (this._notnull(this.data?.given_name)) {
            return this.data.given_name
        }
        if (this._notnull(this.data?.first_name)) {
            return this.data.first_name
        }
    }
    get family_name() {
        if (this._notnull(this.data?.family_name)) {
            return this.data.family_name
        }
        if (this._notnull(this.data?.last_name)) {
            return this.data.last_name
        }
        return ""
    }
    set family_name(value) {
        this.data.family_name = value
    }
    get_last_name() {
        if (this._notnull(this.data?.family_name)) {
            return this.data.family_name
        }
        if (this._notnull(this.data?.last_name)) {
            return this.data.last_name
        }
    }
    get given_name() {
        if (this._notnull(this.data?.given_name)) {
            return this.data.given_name
        }
        if (this._notnull(this.data?.first_name)) {
            return this.data.first_name
        }
        return ""
    }
    set given_name(value) {
        this.data.given_name = value
    }
    get preferences() {
        return this.data?.preferences
    }
    get phone_number() {
        if (this._notnull(this.data?.phone_number)) {
            return this.data.phone_number
        }
        if (this._notnull(this.data?.phone)) {
            return this.data.phone
        }
        return ""
    }
    set phone_number(value) {
        this.data.phone_number = value
    }
    get email() {
        return this.data?.email
    }
    set email(value) {
        this.data.email = value
    }
    get raw_roles() {
        if(this._notnull(this.data?.roles) && !this._notnull(this.data?.raw_roles)) {
            this.data.raw_roles = this.data?.roles
        }
        return this.data?.raw_roles
    }
    get roles() {
        if(this.data?.roles?.length) {
            return this.format_roles(this.data.roles)
        }
        return this.data?.roles
    }
    set roles(value) {
        this.data.roles = value
    }
    get role_status() {
        if(this.raw_roles?.length) {
            let count = 0
            for(const role of this.raw_roles) {
                if(role?.status === "PENDING") {
                    count++
                }
            }
            if(count === this.raw_roles.length) {
                return "Pending"
            }
        }
        if (this._notnull(this.raw_roles?.[0]?.status)) {
            return this.raw_roles?.[0]?.status
        }
        return "Pending"
    }
    set role_status(value) {
        this.roles[0].status = value
    }
    get assigned_role() {
        let _role = this.roles?.[0]
        if(this._notnull(_role) && typeof _role === "object") {
            return this.roles?.[0]?.label
        }
        return _role
    }
    set assigned_role(value) {
        this.roles[0] = this.format_role(value)
    }
    get type() {
        return this.data?.type
    }
    get companies() {
        return this.data?.companies
    }
    get socials() {
        return this.data?.socials
    }
    get facebook() {
        return this.socials?.facebook
    }
    set facebook(value) {
        this.data.socials.facebook = value
    }
    get menu_open() {
        return this.data?.settings?.menu_open
    }
    set menu_open(value) {
        this.data.settings.menu_open = value
        // this.setState({key: "menuCollapse", param: undefined, value: this.data?.settings?.menu_open})
        // this.data.settings.menu_open = value
    }

    get row_length() {
        return this.data?.settings?.row_length
    }
    set row_length(value) {
        this.data.settings.row_length = value
        // this.setState({key: "menuCollapse", param: undefined, value: this.data?.settings?.row_length})
        // this.data.settings.menu_open = value
    }

    get instagram() {
        return this.socials?.instagram
    }
    set instagram(value) {
        this.data.socials.instagram = value
    }
    get linkedin() {
        return this.socials?.linkedin
    }
    set linkedin(value) {
        this.data.socials.linkedin = value
    }
    get twitter() {
        return this.socials?.twitter
    }
    set twitter(value) {
        this.data.socials.twitter = value
    }
    get settings() {
        return this.data?.settings
    }
    get measurement_system() {
        return this.settings?.measurement_system
    }
    set measurement_system(value) {
        return this.data.settings.measurement_system = value
    }
    get data() {
        return (this._state?.data !== undefined) ? this._state?.data : this.controller.getState({ key: this._data_key, param: "user" })
    }
    get home_phone() {
        return this.get_phone("home")
    }
    set home_phone(value) {
        this.set_phone({type: "home", value: value})
    }
    get mobile_phone() {
        return this.get_phone("mobile")
    }
    set mobile_phone(value) {
        this.set_phone({type: "mobile", value: value})
    }
    get work_phone() {
        return this.get_phone("work")
    }
    set work_phone(value) {
        this.set_phone({type: "work", value: value})
    }
    get drivers_license() {
        return this.data?.drivers_license
    }
    set drivers_license(value) {
        this.data.drivers_license = value
    }
    get drivers_license_state() {
        return this.data?.drivers_license_state
    }
    set drivers_license_state(value) {
        this.data.drivers_license_state = value
    }
    get license_type() {
        return this.data?.license_type
    }
    set license_type(value) {
        this.data.license_type = value
    }
    get safety_date() {
        return this.data?.safety_date
    }
    set safety_date(value) {
        this.data.safety_date = value
    }
    get_phone(type) {
        let phone = this.data?.phone_numbers?.find((el) => el?.label === type)
        if(this._notnull(phone)) {
            phone = this.format_phone_number(phone)
        } else if(this?._notnull(this.data?.[`${type}_phone`])) {
            phone = this.data?.[`${type}_phone`]
        }
        return phone
    }
    set_phone({type, value}) {
        let phone = this.data?.phone_numbers?.find((el) => el?.label === type)
        let area_code = value
        if(value?.length >= 3) { area_code = value?.slice(0, 3) }
        let phone_number = value?.slice(3)
        if(this?._notnull(phone)) {
            phone.area_code = area_code
            phone.phone_number = phone_number
        } else {
            // let obj = {area_code: area_code, phone_number: phone_number, label: "mobile"}
            this.data[`${type}_phone`] = value
        }
    }
    format_roles(roles) {
        if(Array.isArray(roles)) {
            let arr = []
            for(const role of roles) {
                if(typeof role === "object") {
                    arr.push(role?.label)
                } else {
                    arr.push(role)
                }
            }
            return arr
        }
    }
    format_role(role) {
        let obj = { }
        if(!this._notnull(role)) { return }
        switch (role?.toUpperCase()) {
            case "ADMINISTRATOR":
                obj.code = 1; break
            case "DRIVER":
                obj.code = 5; break
            case "OWNER":
                obj.code = 4; break
            case "MANAGER":
                obj.code = 2; break
            case "VIEWER":
                obj.code = 10; break
            default:
                obj.code = 10; break
        }
        return obj
    }
    generate_roles() {
        let _roles = []
        if (this._notnull(this.roles)) {
            for(const role of this.roles) {
                if(typeof role === "object") {
                    _roles.push({ code: role?.code, status: role?.status })
                } else {
                    _roles.push(this.format_role(role))
                }
            }
        }
        return _roles
    }
    number_reduction(value) {
        if (value !== undefined && value !== null) {
            return value.replace(/[^0-9]/g, '');
        }
        return value
    }
    get_area_code(phone_number) {
        return Number((this.number_reduction(phone_number)?.slice(0, 3)))
    }
    get_number(phone_number) {
        return (this.number_reduction(phone_number))?.slice(3, phone_number.length)
    }
    build_phone_number(phone_number) {
        if (this.get_number(phone_number) !== undefined) {
            return {
                id: phone_number?.id,
                country_code: 1,
                area_code: this.get_area_code(phone_number),
                phone_number: this.get_number(phone_number),
                extension: 0
            }.scrub()
        }
        return undefined
    }
    format_phone_number(phone_number) {
        let number = ""
        if(this._notnull(phone_number?.area_code)) {
            number += phone_number?.area_code
        }
        if(this._notnull(phone_number?.phone_number)) {
            number += phone_number?.phone_number
        }
        if(!number?.length) {
            return undefined
        }
        return number.replaceAll("-", "")
    }
    get_phone_numbers() {
        let _phone_numbers = this.data?.phone_numbers ?? []
        let new_numbers = [{type: "mobile", data: this.data?.mobile_phone}, {type: "home", data: this.data?.home_phone}, 
                            {type: "work", data: this.data?.work_phone}]?.filter((el) => this?._notnull(el?.data))
        for (const phone of new_numbers) {
            let _phone = this.build_phone_number(phone.data)
            _phone.label = phone.type
            let index = _phone_numbers?.findIndex((el) => el?.label === phone?.type)
            if(this?._notnull(_phone?.area_code)) {
                _phone.area_code = Number(_phone.area_code)
            }
            if(index > -1) {
                _phone_numbers[index] = _phone
            } else {
                _phone_numbers.push(_phone)
            }
        }
        return _phone_numbers
    }
    get summary() {
        let _summary = {}
        _summary.id = this._id
        _summary.family_name = this.family_name
        _summary.given_name = this.given_name
        _summary.name = this.name.full
        _summary.phone_numbers = this.get_phone_numbers()
        if(!_summary.phone_numbers?.length) {
            delete _summary.phone_numbers
        } else {
            _summary.phone_numbers.map((phone) => phone.area_code = Number(phone.area_code))
        }
        if (this._notnull(this.email)) {
            _summary.email = { email: this.email }
        }
        if (this._notnull(this.settings)) {
            _summary.settings = this.settings
            _summary.settings.language = this.settings?.language?.code
        }
        if (this._notnull(this.socials)) {
            _summary.socials = this.socials
        }
        if(this._notnull(_summary?.raw_roles)) {
            delete _summary.raw_roles
        }
        if(this._notnull(this.drivers_license)) {
            _summary.drivers_license = this.drivers_license
        }
        if(this._notnull(this.drivers_license_state)) {
            _summary.drivers_license_state = this.drivers_license_state
        }
        if(this._notnull(this.license_type)) {
            _summary.license_type = this.license_type
        }
        if(this._notnull(this.safety_date)) {
            _summary.safety_date = this.safety_date
        }
        _summary.roles = this.generate_roles()
        _summary.scrub()
        return _summary
    }
}
