// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import ColumnController from "components/Column/Column.controller.jsx"
import DetailsIcon from "../Partials/DetailsIcon.partial"
import DetailsMenu from "../Partials/DetailsMenu.partial"

export default class ColumnOneController extends ColumnController {
    constructor(props) {
        super(props)
        this.state.data = {
            shipper: {},
            consignee: {},
            stop: undefined
        }
        this.state.rows = this.generate_rows()
    }
    generate_rows() {
        let rows = Array(2).fill(undefined)
        rows[0] = {obj: DetailsIcon, params: {params: {parent: this, 
                            controller: this.view.controller, 
                            editable: false, 
                            index: 0, 
                            data: this.configs?.[0]
                        }}}
        rows[1] = {obj: DetailsMenu, params: {params: {parent: this, 
                            controller: this.view.controller, 
                            editable: false, 
                            index: 1, 
                            data: this.configs?.[1]
                        }}}
        return rows
    }
}