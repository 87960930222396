// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Container, Button, Form, } from "react-bootstrap";
import { FaPaperclip } from "react-icons/fa";



// MODULE SPECIFIC IMPORTS 
import configs from "./feedback.json"
import _basePanelView from "components/General/_basePanelView.jsx"
import Controller from "./Feedback.controller.jsx"
import UploadPartial from "components/FileUpload/Upload.partial.jsx";
import "./Feedback.css"
import Modal from "./FeedBackModal.jsx"

// import CotaUserBar from "components/CotaUserBar/CotaUserBar.partial.jsx"
// import FeedBearWidget from "components/Integrations/FeedBearWidget/FeedBearWidget.view.jsx";

export default class Feedback extends _basePanelView {
    constructor(props) {
        super(props)
        this._modal = Modal
        this.state.form         = configs.form_objects
        this.controller = new Controller({ params: { view: this } })
    }
    renderMain() {
        console.log("VIEW THIS",this)
        // if(this.state.submitted === true)
        // {this.toggle_modal({toggle_state: true})}
        return (
            <Container id={this.name} className="generic_100 g-0">
                {/* {this.state.submitted === true ?  this.toggle_modal({toggle_state: true}) : ""} */}
                <Col  >
                    <Row className="card_padding generic_100 cota_panel_object add_shadow row" >
                        <Col>
                            <Row className="bold" >
                                CarrierPro | Share Ideas or Report Bugs
                            </Row>
                            <Row className="light" >
                                Request features that will help you dispatch trips and improve your business with CarrierPro!
                            </Row>
                        </Col>
                    </Row>
                    <Row className="card_spaceing" ></Row>
                    <Row className=" padd generic_100 cota_panel_object add_shadow row" >
                        <Col >
                            <Row className="bold_noPadd" >
                                New Improvement Ideas Or Bugs
                            </Row>
                            <Row>
                                We value your feedback and our goal is to develop the best TMS to grow your business. You can add new feature ideas, vote on your favorites, or report any bugs you experience.
                            </Row>
                            {/* <Row className="bold_noPadd sm_pad " >
                                Title
                            </Row>
                            <Row>
                                <Form.Control type="text"
                                    // value={this.controller.view.state?.current_file?.name} 
                                    placeholder={"Something short"} />
                            </Row> */}
                            <Row className="bold_noPadd sm_pad " >
                                Description
                            </Row>
                            <Row>
                            {this.controller.view.formObject({data: this.state.form.description,  owner: this, controller: this.controller})}                            </Row>
                            <Row className="pad_vertical">
                                <Col sm={3} className="upload upload_size" >
                                <UploadPartial params={{parent: this.view, controller: this.controller, endpoint: "feedback", title_row: false}} />
                                </Col>

                            </Row>
                            {/* <Row className="bold_noPadd sm_pad " >
                                Name*
                            </Row>
                            <Row>
                                <Form.Control type="text"
                                    // value={this.controller.view.state?.current_file?.name} 
                                    placeholder={"Your name"} />
                            </Row> */}
                            {/* <Row className="bold_noPadd sm_pad " >
                                Email*
                            </Row> */}
                            {/* <Row>
                                <Form.Control type="text"
                                    // value={this.controller.view.state?.current_file?.name} 
                                    placeholder={"Your email address"} />
                            </Row> */}
                            <Row className="upload_size pad_vertical">
                      
                            
                            {this.controller.view.formObject({data: this.state.form.is_bug,  owner: this, controller: this.controller})}     
                         

                            </Row>

                            <Row>
                                <Button className={"primary_button_coloring "+this._theme} onClick={() => this.controller.save()} >
                                    Add Idea or Bug
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Container>

            // <iframe 
            //     id="feedbear_iframe"
            //     src={this.controller.project_url}
            //     width="100%" 
            //     // height="300" 
            //     height="100%"
            //     title="feedbear_iframe"
            // />
            // <Container id={this.name} className="generic_100 g-0">
            //     <Row className="max_height">
            //         <Col className="flex_wrapper_rows">
            //             {/* <CotaUserBar params={{parent: this, controller: this.controller, title: "Request Features"}}/> */}
            //             <Row className="max_height">
            //                 <Col>
            //                     <iframe 
            //                         id="feedbear_iframe"
            //                         src="https://cota-systems.feedbear.com/boards/carrierpro?embed=true" 
            //                         width="100%" 
            //                         // height="300" 
            //                         height="100%"
            //                     />
            //                 </Col>
            //             </Row>
            //             {/* <FeedBearWidget params={{parent: this, controller: this.controller}}/> */}
            //         </Col>
            //     </Row>
            // </Container>
        )
    }
}