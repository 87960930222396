// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import TripModelController from "controllers/ModelControllers/Trips/TripModelController.jsx"

export default class StatusButtonsController extends _viewController {
    constructor(props) {
        super(props)
        this.trip = new TripModelController({params: {parent: this,
                                                        controller: this,
                                                        is_view: true}})
    }
    save_action() {
        this.controller.trip.status = this.view.state.data.trip.status
        this.controller.update_trip_status(this.trip.status.toUpperCase())
        this.close()
    }
    close() {
        this.view.toggle_modal({ toggle_state: false })
    }
}