// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx"
import "./DeleteLoadButton.css"

export default class DeleteLoadModal extends _baseModal {
    constructor(props) {
        super(props)
        this.state.modal_params = {
            positioned: "middle_of_panel",   
            title: "",                         
            title_size: "",                     
            closeable: true,                    
            has_overlay: true,                  
            close_on_outside_click: true,      
            x_adjustment: "0px",                
            y_adjustment: "-100px",                
            draggable: false,                              
        }
        this._action = null
        this._ok_button_only = false
    }
    get status() {
        return this.controller.load?.status?.toLowerCase()
    }
    renderButtons() {
        if(this._ok_button_only){
            return (
                <Row className="pt-4">
                    <Col className="flex_centered">
                        <Button className="primary_button_coloring cota" onClick={this.parent.toggle_modal.bind(this.parent)} >
                            OK
                        </Button>
                    </Col> 
                </Row>
            ) 
        }
        return (
            <Row className="pt-4">
                <Col>
                    <Button className="clear_button cota"  onClick={this.parent.toggle_modal.bind(this.parent)}>
                        No
                    </Button>
                </Col>
                <Col>
                    <Button className="primary_button_coloring cota" onClick={() => this?.controller?.delete_load()}>
                        Yes
                    </Button>
                </Col>
            </Row>
        )
    }
    renderView() {
        return (
            <Row id={this.name} className="flex_centered p-3">
               <Col>
                    <Row>
                        <Col className="flex_centered description" style={{"flex-direction": "column"}}>
                            <Row>
                                A deleted load cannot be recovered.
                            </Row>
                            <Row>
                                Attached commodity and any stops, documents, and notes will all be deleted.
                            </Row>
                            <Row>
                                {"Are you sure you want to delete this load? (This action cannot be undone.)"}
                            </Row>
                        </Col>
                    </Row>
                    {this.renderButtons()}
               </Col>
            </Row>
        )
    }
}
