// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "../_ModelController";

export default class NoteModelController extends _ModelController {
    constructor(props) {
        super(props)
        this.index = (props.params?.index === undefined) ? 0 : props.params.index
        if (this.index !== undefined) {
            this._param = "notes"
        }
    }
    get note_text() {
        return (this.data?.note_text === null || this.data?.note_text === "") ? "" : this.data?.note_text
    }
    set note_text(data) {
        this.data.note_text = data
    }
    get viewable() {
        if (this.data?.viewable === null) {
            return false
        }
        return this.data?.viewable
    }
    set viewable(data) {
        this.data.viewable = data
    }
    get summary() {
        let data = JSON.parse(JSON.stringify(this.data))
        if (data?.viewable === undefined || data?.viewable === null || data?.viewable === "") {
            delete data.viewable
        }
	    return data
    }
}
