// GENERAL REACT MODULES
import { Row, Col } from 'react-bootstrap'
import { FaUser } from "react-icons/fa";

//MODULE SPECIFIC
import _basePartial from "components/General/_basePartial.jsx"
import "../ContactPanel.css"
import "../Partials/RoleButtonRow/RoleButtonRow.css"
import config from "../ContactPanel.json"
import UploadPartial from "components/FileUpload/Upload.partial.jsx"


export default class ContactDetails extends _basePartial {
    constructor(props) {
        super(props)
        // this.parent                 = props.params.parent
        this.state.form = config.form_objects
    }
    render() {
     //   console.log("ALL INFO",this)
        return (
            <>
                <Row className='padding' id={this.name}>
                    <Col xs="auto">
                        {/* <Row className='icon_row'>
                            <Col className='icon_col'>
                                <FaUser size={50} className="icon" />
                            </Col>
                        </Row> */}
                        <UploadPartial params={{
                                    parent: this.view, 
                                    controller: this.controller, 
                                    endpoint: "contact", 
                                    single_link: true, 
                                    css_rules: "image", 
                                    disabled : true,
                                  //  image_css: { display: block, height: 10rem, width: 10rem border-radius: 3rem}, 
                                    id:this?.controller?.contact?.id, 
                                    existing_image: this?.controller?.contact?.image ,
                                    new_image: this.state?.current_image ?? this?.controller?.view?.state?.current_image,
                                    view: this,
                                    save: this.state.save,
                                    uploaded: this.state.uploaded,
                                    cache: this?.controller?.view?.state?.current_image ? true : false
                                    }} />
                    </Col>
                    <Col sm={5}>
                        <Row className='pad_top'>
                            <Col className='name'>
                                {this.controller?.contact?.name}
                            </Col>
                        </Row>
                        <Row>
                            <Col className='address'>
                                {this.controller?.contact?.full_address}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={5}>
                        {this.controller.view_roles()}
                        <Row className='pad_top'>
                            <Col className='id'>
                                {/* ID: 1633 */}
                            </Col>
                        </Row>
                    </Col>
                    <Row>
                        {this.controller.save_button_row()}
                    </Row>
                </Row>
                <Row className='pad_top'>
                    <Col>
                        < hr/>
                    </Col>
                </Row>
                <Row className='padding' id="GeneralInfo">
                    <Col sm={5}>
                        <Row>
                            <Col className='gi'>
                                General Info
                            </Col>
                        </Row>
                        <Row className='pad_top_20'>
                            <Col className='info MuiInputLabel-shrink'>
                                Address
                            </Col>
                        </Row>
                        <Row>
                            {this.controller.view_field({data: this.state.form.full_address})}
                        </Row>
                        <Row className='pad_top_8'>
                            <Col className='info MuiInputLabel-shrink'>
                                Email
                            </Col>
                        </Row>
                        <Row>
                            {this.controller.view_field({data: this.state.form.email})}
                        </Row>
                        <Row className='pad_top_8'>
                            <Col className='info MuiInputLabel-shrink'>
                                Fax
                            </Col>
                        </Row>
                        <Row>
                            {this.controller.view_field({data: this.state.form.fax})}
                        </Row>
                        <Row className='pad_top_8'>
                            <Col className='info MuiInputLabel-shrink'>
                                Phone
                            </Col>
                        </Row>
                        <Row>
                            {this.controller.view_field({data: this.state.form.phone})}
                        </Row>
                        <Row className='pad_top_8'>
                            <Col className='info MuiInputLabel-shrink'>
                                Website
                            </Col>
                        </Row>
                        <Row>
                            {this.controller.view_field({data: this.state.form.website})}
                        </Row>
                    </Col>
                    <Col sm={2}></Col>
                    <Col>
                        <Row>
                            <Col className='gi'>
                                social contacts
                            </Col>
                        </Row>
                        <Row className='pad_top_20'>
                            <Col className='info MuiInputLabel-shrink'>
                                Facebook
                            </Col>
                        </Row>
                        <Row>
                            {this.controller.view_field({data: this.state.form.facebook})}
                        </Row>
                        <Row className='pad_top_8'>
                            <Col className='info MuiInputLabel-shrink'>
                                Twitter
                            </Col>
                        </Row>
                        <Row>
                            {this.controller.view_field({data: this.state.form.twitter})}
                        </Row>
                    </Col>
                    <Col></Col>
                </Row>
            </>
        )
    }
}
