//GENERAL REACT MODULES
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { IoMdImage } from "react-icons/io";

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx";
import Controller from "./Commodity_Partial.controller.jsx"
import "./Commodity_Partial.css"

export default class CommodityPartial extends _basePartial {
    constructor(props){
        super(props)
        this.partial_controller         = new Controller ({params: {view: this}})
        this.state.hazmatColor          = "#F0F1F7"
        this.state.oversizedColor       = "#F0F1F7"
        this.state.palletsColor         = "#F0F1F7"
        this.state.iconColor            = "black"
    } 
    renderView() {
      return (
        <Row id={this.name}>
            <Col>
                <Col>
                    <Row>
                        <Col className="header">
                            new load
                        </Col>
                    </Row>
                    <Row className="pad_top_half">
                        {/* <Col xs="auto">
                            <div className="image_block">
                                {this.props.selectedImage ? (
                                    <img src={this.props.selectedImage} className="selected_image" alt = "Selected Image"/>
                                ) : (
                                <IoMdImage className="immd_class" size={20} />
                                )}
                            </div> 
                        </Col> */}
                    <Col>
                        <Row className="pad_top_1">
                            <Col className="generic_form_label">
                                commodity description*
                            </Col>
                        </Row>
                        <Row className="pad_top_half">
                            <Col>
                                {this.formObject({ data: this.state.form.name })}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Col>
      </Row>
      )
    }
}