// GENERAL REACT MODULES
import React from "react";

// ALL OTHER MODULES
import _baseForm from "components/General/_baseForm.jsx"

export default class _basePartial extends _baseForm {
    constructor(props) {
        super(props)
        this._harness           = false
        this._isMounted         = false
        this.state._completed   = false
        this.state.seperated    = false
        this._modal             = undefined
        this._draggable_modal	= true
        this._toggled_modal     = false
        this.panel_controller   = this.state?.panel_controller
        this.panel_controller   = (this.state?.parent?.state?.panel_controller !== undefined) ? this.state?.parent?.state?.panel_controller : (this.state?.parent?.panel_controller !== undefined) ? this.state?.parent?.panel_controller : this.panel_controller 
        let get_frame_width     = this.get_frame_width.bind(this)
        let get_frame_height    = this.get_frame_height.bind(this)
        this.couple_controller(props?.params?.index)
        this.frame = {
            get width() {
                return get_frame_width()
            },
            get height() {
                return get_frame_height()
            }
        }
    }
    get main_context() {
        if (this.parent?.state?.context !== undefined) {
            return this.parent.state.context
        }
        return this.parent?.main_context
    }
    get is_mobile() {
        return this.main_context?.is_mobile()
    }
    get parent() {
        return this.state.parent
    }
    get modal_parent() {
        if(this?.state?.parent_override !== undefined) {
            return this.state.parent_override
        }
        return this
    }
    couple_controller(index) {
        if (this.state?.parent?.panels !== undefined && index !== undefined) {
            this.state.parent.panels[index] = this
        }
    }
    get_frame_width() {
        return this._selfElement?.offsetWidth
    }
    get_frame_height() {
        return this._selfElement?.offsetHeight
    }
    get _partial_data() {
        if (this.frame.height === undefined) {
            this.forceUpdate()
            // this.controller?.view?.forceUpdate()
        }
        return {name: this.name, height: this.frame.height, status: this._completed, seperated: this.state.seperated}
    }
    get _completed() {
        return this.state._completed 
    }
    renderView() {}
    render_internal() {
        return(
            <span ref={ (_selfElement) => { this._selfElement = _selfElement } }>
                {this.renderView()}
                {this.render_modal({panel_params: {parent: this, modal: this.state.modal, modal_pos: this.state.modal_pos, centered: this._center_modal}} )}
            </span>
        )
    }
    render() {
        // Use renderView if you need to attach a Modal
        return(
            <span ref={ (_selfElement) => { this._selfElement = _selfElement } }>
                {this.renderView()}
                {this.render_modal({panel_params: {parent: this, modal: this.state.modal, modal_pos: this.state.modal_pos, centered: this._center_modal}} )}
            </span>
        )
    }
    display_modal() {
        return (this.state._toggled_modal) ? "visible" : "hide"
        // return (this.state._toggled_modal) ? "generic_block_ul" : "none_display"
    }
    // Where the modal actually gets rendered?
    render_modal() {
        if (this._modal !== undefined) {
            return (
                <span className={this.display_modal()}>
                    {
                        React.createElement(this._modal, {params: {parent: this, panel_controller: this.panel_controller, controller: this.controller, panel_params: undefined, draggable: this._draggable_modal}}, undefined)
                    }
                </span>
            )
        }
    }
    toggle_modal({toggle_state}) {
        if (toggle_state === undefined) {
            this.setState({_toggled_modal: !this.state._toggled_modal})
        } else {
            this.setState({_toggled_modal: toggle_state})
        }
        this.forceUpdate()
    }
}

