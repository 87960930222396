// GENERAL REACT MODULES
import React from "react";
import { Row, Col } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx";
import Controller from "./Document.modal.controller.jsx"
import DownloadPartial from "components/FileDownload/Download.partial";
import DeleteFile from "components/DeleteFile/DeleteFile.button.jsx"
import "./Document.modal.css"

export default class DocumentModal extends _baseModal {
    constructor(props) {
        super(props)
        this.modal_controller = new Controller({params: {view: this, controller: this?.controller}})
        this.state.modal_params   = {
            positioned: "middle_of_panel",   
            title: "Document Information",                
            has_overlay: true,                 
            close_on_outside_click: true,  
            closeable: true,                         
            x_adjustment: "0px",                
            y_adjustment: "0px",
        }
    }
    get document() {
        return this.controller.view.state?.selected_doc
    }
    close() {
        this.state.parent.toggle_modal({toggle_state: false})
    }
    renderView() {
        return (
            <div id={this.name}>
                <Row>
                    <Col>
                        <Row>
                            <Col className="title">
                                Filename
                            </Col>
                        </Row>
                        <Row>
                            <Col className="doc_name">
                                {this.modal_controller.truncateString(this.document?.name, 32)}
                            </Col>
                        </Row>
                        <Row className="title">
                            <Col>
                                Extension
                            </Col>
                            <Col>
                                Type
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.document?.resource_ext}
                            </Col>
                            <Col>
                                {this?.document?.["document_type.label"]}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DeleteFile params={{button: true, file: this.document, controller: this.controller, parent: this, callback: "close"}} />
                            </Col>
                            <Col>
                                <DownloadPartial params={{button: true, file: this.document, controller: this.controller, parent: this}} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}