// MODULE SPECIFIC IMPORTS
import _viewController from "controllers/ViewController/_viewController";

// REQUESTS
import self_request from "assets/graphql/Users/self.graphql.json"
import employee_request from "assets/graphql/Employees/Employees.roles.graphql.json"
import company_request from "assets/graphql/Companies/Company.graphql.json"
import CompanyModelController from "controllers/ModelControllers/CompanyModelController.jsx"

export default class ManageUsersController extends _viewController {
    constructor(props) {
        super(props)
        this.requests = [{request: self_request, callback: {f: this.load_user, p: {name: "self"}}, endpoint: "users"},
                         {callback: {f: this.process_roles}, endpoint: "roles"}]
        this.company = new CompanyModelController({params: {parent: this,
                                                            is_view: true,
                                                            controller: this}})
    }
    get company_id() {
        return this.view.state.company_id
    }
    get roles() {
        return this.view.state.roles
    }
    get raw_roles() {
        return this.view.state.raw_roles
    }
    get internal_user() {
        return this.check_role("INTERNAL_COTA_USER")
    }
    get admin_user() {
        return this.check_admin()
    }
    get owner() {
        return this.check_role("OWNER")
    }
    get editable() {
        if(this.internal_user || this.admin_user || this.owner) {
            return true
        }
        return false
    }
    load_user({caller, params, results}) {
        if(results?.errors !== undefined) { 
            console.log("Error processing self request", results.errors)
        }
        if(results?.errors === undefined && results?.data?.self !== undefined) {
            let length = results.data.self.companies.length - 1
            caller.view.state.company_id = results.data.self.companies[length].id
            caller.load_company(results.data.self.companies[length].id)
        }
    }
    load_company(id) {
        let body = JSON.parse(JSON.stringify(company_request))
        body.query  = body.query.replace("company", 'company(id: "'+id+'")')
        this.api.companies.ask({caller: this, params: {body: body}, callback: {f: this.process_company}})
    }
    process_company({caller, params, results}) {
        if(results?.data?.company !== undefined) {
            caller.view.state.data.company = results?.data?.company
            caller.view.setState({company_id: results?.data?.company?.id})
            // caller.view.forceUpdate()
        }
    }
    process_roles({caller, params, results}) {
        if(results?.errors !== undefined) {
            console.log("Error processing roles", results.errors)
            return
        }
        results?.data?.roles?.forEach((role) => {
            if(role?.name === "DRIVER" || role?.name === "OWNER" || role?.name === "ADMIN" || role?.name === "VIEWER") {
                caller.view.state.roles.push({value: role.label, label: role.label})
                caller.view.state.raw_roles.push(role)
            }
            return
        })
    }
    processEventReaction(decoded) {
        this.view.state.edit_user = true
        let body = JSON.parse(JSON.stringify(employee_request))
        body.query = body.query.replace("employees", 'employees(id:"'+decoded?.message?.data?.id+'", company_id:"'+this.company_id+'")')
        this.api.users.ask({caller: this, params: {body: body}, callback: {f: this.process_decoded_user}})
    }
    process_decoded_user({caller, params, results}) {
        if(results?.errors !== undefined) {
            console.log(results.errors)
            return 
        }
        caller.view.state.selected = results?.data?.employees?.[0]
        caller.view.toggle_modal({toggle_state: true})
    }
    new_user() {
        this.view.state.edit_user = false
        this.view.toggle_modal({toggle_state: true})
    }
    follow_on_selection({event, obj, data}) {
	    // console.log("--- FOLLOW ON SELECTION ---")
        // console.log(obj)
        delete this.view.state.search.company
        delete this.view.state.data.companies
        this.view.setState({company_id: obj?.id})
    }
}