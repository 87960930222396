// GENERAL REACT MODULES
import _basePartial from "components/General/_basePartial.jsx"
import { Row, Col } from 'react-bootstrap'

// ALL OTHER MODULES
import "./DualColumn.css"
import Controller from "./DualColumn.controller.jsx"

export default class DualColumn extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this}})
        this.state.has_icon     = (this.state.has_icon === undefined) ? false : this.state.has_icon
    }
    get items() {
        return this.controller.getState({key: "line_items"})
    }
    get item_data() {
        return this.props?.params?.item_data
        // return this.items?.[this.state?.index]
    }
    get line_item() {
        return this.partial_controller.line_item
    }
    get line_height() {
        return 40
    }
    get _configs() {
        return this.controller.getState({key: "configs"})
    }
    render() {
        return (
            <Col class id={this.name} style={{"minWidth": this.state.width}}>
                <Row className="g-0">
                    {this.partial_controller.render_icon()}
                    {this.partial_controller.render_fields()}
                </Row>
            </Col>
        )
    }

}
