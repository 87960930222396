//GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button, Container, Card } from "react-bootstrap";
import { FiTruck } from "react-icons/fi"

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx";
import Controller from "./AssetStatus.partial.controller.jsx"
import AssignAsset from "../AssignAsset/AssignAsset.partial.jsx";

import "./AssetStatus.css"

export default class AssetStatus extends _basePartial {
    constructor(props) {
        super(props);
        this.partial_controller     = new Controller ({params: { view: this}})
        this.state.showAssignAsset  = false;
        this.handleAssignAsset      = this.handleAssignAsset.bind(this)
    }
    handleAssignAsset() {
        this.setState({showAssignAsset: true})
    }
    get asset() {
        return this.controller.asset
    }
    renderView() {
        return (
            <div>
                {this.state.showAssignAsset ? <AssignAsset params={{parent: this, controller: this.controller}}/> : 
                <Row id={this.name}>
                    <div className="cota_panel_border g-0 add_shadow">
                        <Row className="pad_3">
                            <Col className="icon_col">
                                <Row className="icon_row">
                                    <Col className="icon_col">
                                        <FiTruck size={40} className="icon"/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="pad_1">
                            <Col className="icon_col">
                                <Row>
                                    <Col className="info">
                                        {this.asset.base_type} is unhooked
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="pad_1">
                            <Col className="icon_col">
                                <Row>
                                    <Col className="details">
                                        Search for an idle {this.asset.inverse_type} and hook up it to this {this.asset.base_type}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="padding">
                            <Col className="icon_col">
                                <Row>
                                    <Col>
                                        <Button className="btn_hookup" onClick={this.handleAssignAsset}>
                                            Hook up to a {this.asset.inverse_type}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Row>
                }
            </div>
        )
    }
}
