// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import test_data from "./test.json"
import schema from "./schema.json"

// ALL OTHER MODULES
import _baseApiObject from "../../../../components/General/_baseApiObject";
import class_request from "assets/graphql/Class/class.estimate.graphql.json"

export default class Class extends _baseApiObject {
    constructor(props) {
        super(props)
        this.schema     = schema
        this.test_data  = test_data
        this.requests   = {
            general: class_request
        }
    }
    async estimate({caller, value, callback, params}) {
        if (value.density !== undefined) {
            value.density = parseFloat(value.density)
        }
        if (value.volume !== undefined) {
            value.volume = parseFloat(value.volume)
        }
        if (value.weight !== undefined) {
            value.weight = parseInt(value.weight)
        }
        if (value !== undefined) {
            if (!isNaN(value.density) || (!isNaN(value.volume) && !isNaN(value.weight))) {
                let body = JSON.parse(JSON.stringify(class_request))
                Object.keys(value).forEach((key, index) => {
                    body = this.add_item({body: body, name: key, value: value?.[key]})
                })
                params = (params === undefined) ? {} : params
                params.body = body
                await this.ask({caller: caller, callback: callback, params: params, nocache: true})
            }
        }
        // if (typeof callback.f === "function") {
        //     callback.f({caller: caller, params: callback?.p, results: false})
        // }
        return
    }
    add_item({body, name, value}) {
        body.query = body.query.replace(name+":", name+': '+(isNaN(value) ? null : value)+'')
        return body
    }
}

