// GENERAL REACT MODULES
import { Form, Row, Col, Card, Button, Container } from 'react-bootstrap'
import { FaTrailer } from "react-icons/fa";
import { BsMap } from "react-icons/bs"
import { BsTruck } from "react-icons/bs";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./ViewAssetDetails.partial.controller.jsx"
import configs from "./ViewAssetDetails.json"

import "./ViewAssetDetails.css"
import Assets from '../../../Assets.view';
import Map from '../../../../Map/map.view.jsx';

export default class ViewAssetDetails extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller     = new Controller ({params: { view: this, controller: this.controller}})
        this.state.form             = configs.form_objects
        this.state.from_configs     = configs
        this.state.panel_trees      = [{ key: 0, name: "assets", base: 0}]
        this.state.panels           = [{ key: 0, obj: Assets, params: { panel_params: { is_closeable: true, close_title: "Back Home", close_func: "resetScreen" }}}]
        this.state.edit_mode        = false
        this.state.states           = []
        this.state.trailer_types    = []
        this.state.length           = []
    }
    get asset() {
        return this.controller.asset
    }
    componentDidMount() {
        this._isMounted = true
        this.partial_controller.load_data()
    }
    render_boxtruck() {
        if(this.asset?.is_boxtruck && !this.state?.edit_mode) {
            return (
                <Col>
                    Box Truck
                </Col>
            )
        } else if (this.state.edit_mode && this.asset?.asset_type?.toLowerCase() !== "trailer") {
            return (
                <Col>
                    {this.formObject({data: this.state.form.is_boxtruck, owner: this})}
                </Col>
            )
        }
    }
    renderView() {
        // console.log(this)
        return (
            <>
                <div id={this.name}>
                    <Row  className="">
                        <Col className="">
                            <Row className="padding">
                                <Col xs="auto">
                                    <Row className='icon_row'>
                                        {this.partial_controller?.render_icon()}
                                    </Row>
                                </Col>
                                <Col xs="auto"></Col>
                                <Col>
                                    <Row className="pad_top">
                                        <Col className="trailer no_wrap">
                                            {this.partial_controller?.render_asset_number()}
                                        </Col>
                                    </Row>
                                    <Row className="pad_top">
                                        {this.render_boxtruck()}
                                    </Row>
                                    {this.partial_controller?.render_trailer_dropdown()}
                                </Col>
                                <Col></Col>
                                <Col className='mx-10'>
                                    <Row className="pad_top idle_row">
                                        <Col className={this.controller?.asset?.status?.toLowerCase()}>
                                        {/* <Col className={this.controller.asset.status}> */}
                                            {this.controller.asset.status}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {this.partial_controller?.support_air_ride()}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={1}></Col>
                                <Row>
                                    <Col className="edit_trailer no_wrap flex_end">
                                        {this.partial_controller?.button_row()}
                                    </Col>
                                    <Col xs="auto"></Col>
                                </Row>
                            </Row>
                            <Row className='pad_top'>
                                <Col>
                                    <hr/>
                                </Col>
                            </Row>
                            <Row className='padding'>
                                <Col sm={4}>
                                    <Row>
                                        <Col className='gi'>
                                            general info
                                        </Col>
                                    </Row>
                                    {/* <Row className='pad_top_20'>
                                        <Col className='info'>
                                            Type
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="type">
                                            {this.controller?.editable_field({data: this.state.form.asset_type, owner: this})}
                                        </Col>
                                    </Row> */}
                                    <Row className='pad_top_8'>
                                        <Col className='info MuiInputLabel-shrink'>
                                            Make
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="type">
                                        {this.partial_controller?.editable_field({data: this.state.form.make, owner: this,})}
                                        </Col>
                                    </Row>
                                    <Row className='pad_top_8'>
                                        <Col className='info MuiInputLabel-shrink'>
                                            Model
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="type">
                                        {this.partial_controller?.editable_field({data: this.state.form.model, owner: this})}
                                        </Col>
                                    </Row>
                                    <Row className='pad_top_8'>
                                        <Col className='info MuiInputLabel-shrink'>
                                            Year
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="type">
                                        {this.partial_controller?.editable_field({data: this.state.form.year, owner: this})}
                                        </Col>
                                    </Row>
                                    <Row className='pad_top_8'>
                                        <Col className='info MuiInputLabel-shrink'>
                                            Weight
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="type">
                                        {this.partial_controller?.editable_field({data: this.state.form.weight, owner: this})}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {this.partial_controller.render_truck_color()}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {this.partial_controller.render_length()}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {this.partial_controller.render_weight_capacity()}
                                        </Col>
                                    </Row>
                                    <Row className='pad_top_8'>
                                        <Col className='info MuiInputLabel-shrink'>
                                            License Plate #
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="type">
                                        {this.partial_controller?.editable_field({data: this.state.form.license_plate, owner: this})}
                                        </Col>
                                    </Row>
                                    <Row className='pad_top_8'>
                                        <Col className='info MuiInputLabel-shrink'>
                                            VIN
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='type'>
                                        {this.partial_controller?.editable_field({data: this.state.form.vin, owner: this})}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={2}></Col>
                                <Col sm={6}>
                                    <Row>
                                        <Col className='gi'>
                                            Current mileage and location
                                        </Col>
                                    </Row>
                                    <Row className='pad_1'>
                                        <Col >
                                            <Row>
                                                <Col className='info MuiInputLabel-shrink no_wrap'>
                                                    Current mileage, miles
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='type'>
                                                    {this.partial_controller?.editable_field({data: this.state.form.current_miles, owner: this})}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col className='info MuiInputLabel-shrink no_wrap'>
                                                    Current location
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='type'>
                                                    {this.partial_controller?.editable_field({data: this.state.form.last_location, owner: this})}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs="auto"></Col>
                                    </Row>
                                    <Row className='pad_top_8'>
                                        <Col>
                                            < hr/>
                                        </Col>
                                    </Row>
                                    <Row className='pad_2'>
                                        <Col className='gi'>
                                            starting mileage and location
                                        </Col>
                                    </Row>
                                    <Row className='pad_top_8'>
                                        <Col>
                                            <Row>
                                                <Col className='info MuiInputLabel-shrink'>
                                                    Starting mileage, miles
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='type'>
                                                {this.partial_controller?.editable_field({data: this.state.form.starting_miles, owner: this})}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col className='info MuiInputLabel-shrink no_wrap'>
                                                    Starting ZIP code
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='type'>
                                                {this.partial_controller?.editable_field({data: this.state.form.starting_zip, owner: this})}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs="auto"></Col>
                                    </Row>
                                    <Row className='pad_top_8'>
                                        <Col>
                                            < hr/>
                                        </Col>
                                    </Row>
                                    <Row className='pad_2'>
                                        <Col className='gi'>
                                            registration details
                                        </Col>
                                    </Row>
                                    <Row className='pad_top_8'>
                                        <Col>
                                            <Row>
                                                <Col className='info MuiInputLabel-shrink'>
                                                    Registration #
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='type'>
                                                {this.partial_controller?.editable_field({data: this.state.form.registration_number, owner: this})}
                                                </Col>
                                            </Row>
                                            <Row className='pad_top_8'>
                                                <Col className='info MuiInputLabel-shrink'>
                                                    Registration Date
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='type'>
                                                {this.partial_controller?.editable_field({data: this.state.form.registration_date, owner: this})}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col className='info MuiInputLabel-shrink'>
                                                    Registration State
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='type'>
                                                    {this.partial_controller?.editable_field({data: this.state.form.registration_state, options: this.state?.states, owner: this})}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs="auto"></Col>
                                    </Row>
                                </Col>
                                <Row>&nbsp;</Row>
                                <Row>
                                    {this.partial_controller?.save_button_row()}
                                </Row>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}
