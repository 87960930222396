// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button, Card } from "react-bootstrap";
import { FaUser } from "react-icons/fa";

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx";
import Controller from "./AssignDriver.partial.controller.jsx"
import "./AssignDriverPartial.css"
import config_data from "./AssignDriverPartial.json"
import ChangeDriverPartial from "../../ChangeDriver/ChangeDriver.partial.view.jsx";

export default class AssignDriverPartial extends _basePartial {
    constructor(props) {
        super(props);
        this.partial_controller             = new Controller ({params: {view: this, controller: this.controller}})
        this.state.form                     = config_data.form_objects
        this.state.redirectToChangeDriver   = false  
    }
    change_driver() {
        return (
            <ChangeDriverPartial params= {{parent: this.view, controller: this.controller}} />
        )
    }
    assign_driver_button() {
        if(this.partial_controller.driver_id !== undefined && this.partial_controller.driver_id !== null) {
            return (
                    <>
                        <Col>
                            {/* <Button className="btn_assign" onClick={this.partial_controller.attach_driver.bind(this)} > */}
                            <Button className="btn_assign" onClick={() => this.partial_controller.attach_driver()} >
                                Assign Driver
                            </Button>
                        </Col>
                    </>
            )
        } else {
            return (
                <>
                    <Col>
                        <Button className="btn_assign_inactive">
                            Assign Driver
                        </Button>
                    </Col>
                </>
            )
        }
    }
    renderView() {
        return (
            <Row id={this.name}>
                <Card className="g-0 add_shadow">
                    <Row className="pad_2">
                        <Col className="align_items">
                            <Row className='icon_row'>
                                <Col className='align_items'>
                                    <FaUser size={40} className="icon" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="pad_1 align_items">
                        <Col>
                            <Row>
                                <Col className="info">
                                    Driver is unassigned
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="align_items">
                            <Row>
                                <Col className="instructions">
                                    Search for an available driver and assign them to this truck
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="pad_top">
                        <Col className="align_items drop_down">
                            <Row>
                                <Col className="dropdown_col">
                                    {this.formObject({data: this.state.form.driver_filter, options: this.controller?.drivers, owner: this, enlarge_subtitle: true, controller: this.partial_controller})}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="pad_1">
                        <Col className="align_items">
                            <Row>
                                {this.assign_driver_button()}
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </Row>
        )
    }
}