// GENERAL REACT MODULES
import { Row, Col, Button, Form, Container } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal";
import "./AutoLoadModal.css";
import config_data from "./AutoLoadModal.json"
import UploadPartial from "components/FileUpload/Upload.partial.jsx";
import modal_controller from "./AutoLoadModal.controller.jsx"

export default class AutoLoadModal extends _baseModal {
    constructor(props) {
        super(props)
        this.modal_controller = new modal_controller
        this.state.form = config_data.form_objects
        // this.modal_controller = this.state.parent?.partial_controller
        // this.controller.setState({ key: "private_file", value: true })
        // this.state.docTypes = test_data.docTypes
        // this.state.got_doc_types = false
        // this.state.docTypes = []
        this.state.modal_params = {
            positioned: "middle_of_panel",
            ignore_sidebar: true,
            title: "Create a Load From a Document",
            title_size: "",
            closeable: false,
            has_overlay: true,
            close_on_outside_click: false,
            draggable: false,
        }
    }

    renderView() {
        // this.get_doc_types()
        console.log(this)
        return (
            <Container id={this.name}>
                <Row  className="g-0 col_padding remove_top_padding">
                    <Col className="g-0 col_padding remove_padding">

                        <Row className="g-0 description">
                            <center>
                                {/* {console.log("CHECKING FORM OBJ :",this.controller?.view)} */}
                                {/* {console.log("CHECKING FORM PARENT :",this)} */}
                                Upload a BOL or Rate Conformation and we'll take care of the rest.
                            </center>
                        </Row>
                        <Row className="g-0 file_types">

                            {/* {console.log("CHECKING FORM OBJ :",this.controller?.view)} */}
                            {/* {console.log("CHECKING FORM PARENT :",this)} */}
                            <center>
                                {'(supported file types - pdf)'}
                            </center>
                        </Row>
                        <Row className="file_types">
                            <UploadPartial params={{ parent: this, controller: this.controller, endpoint: "load", upload_override: true }} />
                        </Row>
                        <Row className="pt-2" style={{ minWidth: "400px" }}>
                            {this?.controller?.view?.state?.file_endpoint !== "feedback" ?
                                <><Col sm={4}>
                                    Document Type:
                                </Col><Col>
                                        <div className="absolute">
                                            <div className="" style={{ minWidth: "226px" }}>
                                                {this.formObject({ data: this.state.form.doc_type, owner: this.parent.controller ,options: config_data.doc_types })}
                                            </div>
                                        </div>
                                    </Col></> : ""
                            }
                        </Row>
                        <Row className="pt-3">
                            <Col>
                                <hr />
                            </Col>
                        </Row>
                        <Row className="pe-3">
                            <Col />
                            <Col>
                                <Button size="sm" variant="secondary" className='btn_secondary'
                                    onClick={() => this.parent.toggle_modal({toggle_state: false})}>
                                    Cancel
                                </Button>
                            </Col>
                            <Col className={"edit_contact button_col "}>
                                <Button size="sm" onClick={() => this.modal_controller.changeModal(this)} className={"primary_button_coloring " + this._theme}>
                                    Submit
                                </Button>
                            </Col>
                            {/* <Col sm={1}/> */}
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}
