//GENERAL REACT MODULES
import React from "react"
import { Button, Row, Col } from "react-bootstrap"

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
// import OutOfServiceModal from "./OutOfService.modal.jsx"

export default class OutOfServiceButton extends _basePartial {
    constructor(props) {
        super(props)
        // this.modal_controller   = this.controller
        // this._modal = OutOfServiceModal
    }
    get status() {
        return this.controller.asset?.status?.toLowerCase()
    }
    render_make_available() {
        return (
            <Button className="primary_button_coloring w-100 cota" onClick={this.controller.change_service.bind(this.controller)}>Make Available</Button>
        )
    }

    render_mark_out_of_service() {
        return (
            <Button variant="danger" className="w-100 cota" onClick={this.controller.change_service.bind(this.controller)}>Mark as Out of Service</Button>
        )
    }
    renderView() {
        switch (this.status) {
            case "available":
                return this.render_mark_out_of_service()
            default:
                return this.render_make_available()
        }
    }
}