// GENERAL REACT MODULES
import { Row, Col, Button } from 'react-bootstrap'
import { IoIosCloseCircle } from "react-icons/io";
import ListLineController from "components/CotaListPanel/ListLineController.jsx"

// OBJECT SPECIFIC MODULES
import LineItemModelController from "controllers/ModelControllers/Quotes/LineItemModelController"
import TriColumn from "./TriColumn/TriColumn.partial.jsx"
import DualColumn from "./DualColumn/DualColumn.partial.jsx"
import BadgeColumn from "./BadgeColumn/BadgeColumn.partial.jsx"
import MiniBadgeColumn from "./MiniBadgeColumn/MiniBadgeColumn.partial.jsx"
import { BsThreeDotsVertical } from "react-icons/bs";

export default class LineItemController extends ListLineController {
    constructor(props) {
        super(props)
        this.state      = {
            event: undefined
        }
        this.line_item  = new LineItemModelController({params: {parent: this, 
                                                                        controller: this,
                                                                        is_view: true, _param: "line_items"}})
    }
    get item_data() {
        return this.view?.props?.params?.item
    }
    get _configs() {
        return this.view._configs
    }
    decorate_text({item}) {
        let decoration = ""
        // left_align
        let aligns = (this._configs?.body?.aligns?.filter(align => align.n.includes(item)))?.[0]
        if (aligns?.t !== undefined) {
            decoration = decoration+" "+aligns?.t+"_align"
        }
        if (this._configs?.body?.bold?.indexOf(item) > -1) { decoration = decoration + " bold_text " }
        let color = (this._configs?.body?.colors?.filter(color => color.n.includes(item)))?.[0]
        if (color !== undefined) { decoration = decoration+" "+color.c+"_text " } 

        let type = (this._configs?.body?.types?.filter(type => type.n.includes(item)))?.[0]
        if (type?.t === "currency") { decoration = decoration+" green_text bold_text " }
        return decoration
    }
    map_columns() {
        if (this.item_data !== undefined) {
            return Object.keys(this.item_data).map((item, index) => {
                if (this._configs?.hide?.indexOf(item) > -1) { return }
                return this.build_column({item: item, index: index, item_data: this.item_data})
            })
        }
    }
    build_column({item, index, item_data}) {
        let tris    = (this._configs?.columns?.tri?.filter(type => this.lc(type.n?.[0]) === this.lc(item)))?.[0]
        let duals   = (this._configs?.columns?.dual?.filter(type => this.lc(type.n?.[0]) === this.lc(item)))?.[0]
        let badges  = (this._configs?.columns?.badges?.filter(type => this.lc(type.n) === this.lc(item)))?.[0]
        let minis   = (this._configs?.columns?.minis?.filter(type => this.lc(type.n) === this.lc(item)))?.[0]
        let widths  = (this._configs?.columns?.widths?.filter(type => this.lc(type.n) === this.lc(item)))?.[0]
        if (tris !== undefined) {
            return (
                <TriColumn params={{parent: this.view, controller: this.view.controller, config: tris,
                                    data: tris?.n, index: this.view.state?.index, item: item, item_data: item_data}} key={"line_col_"+index+"_tri"}/>
            )
        }
        if (duals !== undefined) {
            return (
                <DualColumn params={{parent: this.view, controller: this.view.controller, data: duals?.n, config: duals,
                                        index: this.view.state?.index, item: item, item_data: item_data, type: duals?.t}} key={"line_col_"+index+"_dual"}/>
            )
        }
        if (badges !== undefined) {
            return (
                <BadgeColumn params={{parent: this.view, controller: this.view.controller, design: badges,
                                         data: this.item_data?.[item], item: item, item_data: item_data}} key={"line_col_"+index+"_badge"}/>
            )
        }
        if (minis !== undefined) {
            return (
                <MiniBadgeColumn params={{parent: this.view, controller: this.view.controller, design: minis,
                                             data: this.item_data?.[item], item: item, item_data: item_data}} key={"line_col_"+index+"_mini"}/>
            )
        }
        return (
            <Col className={"line_col "+this.decorate_text({item: item})} key={"line_col_"+index} 
                    style={{"minHeight": this.view.line_height+"px", "lineHeight": this.view.line_height+"px", 
                            "minWidth": widths?.w+"px", "maxWidth": (widths?.mw !== undefined) ? widths?.mw+"px" : undefined }}>
                {this.determine_type({value: this.unwrap_obj({obj: this.item_data?.[item]}), item: item, item_data: item_data})}
            </Col>
        )
    }
    determine_type({value, item}) {
        let type = (this._configs?.body?.types?.filter(type => type.n.includes(item)))?.[0]
        switch(type?.t) {
            case "currency":
                return (value !== null) ? "$"+value : ""
            default:
                return value
        }
    }
    add_button() {
        if (this.view?._configs?.button !== undefined) {
            return (
                <Col className="cota_table_cell button_column_width" key={"add_btn_"+this.view.state?.index}>
                    <Button className="cota_table_button" onClick={this.handle_button.bind(this)}>{this.view?._configs?.button?.label}</Button>
                </Col>
            )
        }
        return
    }
    self_filter() {
        if (this.view.state?.line_filter) {
            return (
                <Col className="filter_btn" key={"filter_btn_"+this.view.state?.index}>
                    <IoIosCloseCircle onClick={this.filter_results.bind(this)} className="pointer"/>
                </Col>
            )
        }
    }

    filter_results(e) {
        e.stopPropagation();
        if (!this.view.controller.view.state.self_filters.includes(this.item_data?.id)) {
            this.view.controller.view.state.self_filters.push(this.item_data?.id)
            this.view.controller.view.forceUpdate()
        }
    }
    add_actions() {
        if (this.view.state?.actions !== undefined) {
            return (
                <Col className="action_btn" key={"add_actions_"+this.view.state?.index}>
                    <Row className='g-0'>
                        <Col className='action_link action_link_wrapper'>
                            {this.view.state?.actions}
                        </Col>
                        <Col className="action_dots">
                            <BsThreeDotsVertical />
                        </Col>
                    </Row>
                </Col>
            )
        }
    }
    get click_length() {
        return this.state.stop - this.state.start
    }
    handle_row(e) {
        let clickable = true
        if (this.state.is_draggable) {
            if (this.partial_controller.click_length > 200) {
                clickable = false
            }
        }
        e.stopPropagation();
        if (this.state.is_clickable && clickable) {
            let decoded = {channel: "grafana_row", message: { data: this.item_data } }
            this.partial_controller?.sendEventReaction(decoded)
        }
    }
    handle_button(e) {
        e.stopPropagation();
        let decoded = {channel: "grafana_button", message: { data: this.item_data } }
        this.sendEventReaction(decoded)
    }
    handle_link(e) {
        e.stopPropagation();
        let decoded = {channel: "grafana_link", message: { data: this.item_data } }
        this.sendEventReaction(decoded)
    }
    handle_drop(e) {
        e.stopPropagation();
        let decoded = {channel: "grafana_drag", message: { data: this.item_data } }
        this.sendEventReaction(decoded)
    }
    sendEventReaction(decoded) {
        decoded.message.data = this.item_data
        this.view?.parent?.controller?.processEventReaction(decoded)
    }
    external_draggable() {
        if (this.view.state.external_drag && this.view.state.is_moving) {
            return "abs"
        }
        return ""
    }
    touchable() {
        if (this.view.state.is_clickable) {
            return "touchable"
        }
        if (this.view.state.is_draggable) {
            return "touchable"
        }
    }
    moving_class() {
        if (this.view.state.is_moving) {
            return "moving_row"
        }
        return ""
    }
    moving_width() {
        if (this.view.state.external_drag && this.view.state.is_moving) {
            // console.log("IT WAS TRUE")
            return this.view.state.width+"px"
        }
        return undefined
    }
    eventHandler(e, data) {
        switch(data) {
            case "start":
                if (e.nativeEvent !== undefined) {
                    this.view.setState({offset: this.view._selfElement.offsetTop - this.view.controller?.view?._scroll_window?.scrollTop})
                }
                this.state.event = e
                this.state.start = Math.floor(Date.now())
                this.view.setState({is_moving: true})
                break
            case "stop":
                this.view.setState({offset: undefined})
                this.state.stop = Math.floor(Date.now())
                if (this.state.event !== undefined) {
                    this.handle_drop(this.state.event)
                }
                this.view.setState({is_moving: false})
                this.state.event = undefined
                break
            default:
                break
        }
    }
    hover_line(hover) {
        this.setState({hovered: hover})
    }
}
