// GENERAL REACT MODULES
import { Row, Col, Button } from 'react-bootstrap'

// OBJECT SPECIFIC MODULES
import contact_request from "assets/graphql/Contacts/Contact.graphql.json"
import company_request from "assets/graphql/Companies/Company.graphql.json"
import _viewController from "controllers/ViewController/_viewController.jsx"
import ContactModelController from "controllers/ModelControllers/ContactModelController.jsx"
import PersonModelController from "controllers/ModelControllers/PersonModelController.jsx"
import ContactDetails from './ContactDetails/ContactDetailsPanel.jsx'
import EditContactPanel from './EditContact/EditContactPanel.jsx'
import ContactPersonPanel from './ContactPerson/ContactPersonPanel.jsx'
import contact_mutation from "assets/graphql/Contacts/Contacts.update.graphql.json"
import update_request from "assets/graphql/Contacts/Contacts.update.graphql.json"
 
export default class ViewContactController extends _viewController {
    constructor(props) {
        super(props)
        this.contact        = new ContactModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true}})
        this.people         = []
        this.personModels   = []
        this.requests       = this.enable_requests()
    }
    get company() {
        return this.contact.company
    }
    get notes() {
        return this.view?.state?.data?.notes
    }
    _delete({name, index}) {
        this.view.state.data[name] = this.view?.state?.data?.[name].filter((value, i) => i !== index)
        this.view.forceUpdate()
    }
    load_harness() {
        if(!this.view.state.edit_mode) {
            this.load_contact()
            // console.log(this)
        }
    }
    enable_requests() {
        if(!this.view.state.edit_mode) {
            return [{callback: {f: this.state_results, p: {name: "areas", var: ["areas", "states"]}}, 
                        endpoint: "areas", replace: {o: "params:", r:'params: {country: "US"}'}},
                    {callback: { f: this.state_results, p: {name: "areas", var: ["areas", "states"]}}, 
                        endpoint: "areas", replace: {o: "params:", r:'params: {is_country: true}'}}]
        }
        return []
    }
    load_contact() {
        let id = this.view?.state?.panel_params?.data?.id
        // console.log("--- Contact ID ---", id)
        let body = JSON.parse(JSON.stringify(contact_request))
        body.query = body.query.replace("contact(id: )", 'contact(id: "'+id+'")')
        this.api.contacts.ask({caller: this, params: {body: body}, callback: {f: this.process_contact}})
    }
    // process_contact({caller, params, results}) {
    //     if(results?.data?.contact !== undefined) {
    //         if(results.data?.contact?.roles !== undefined) {
    //             results.data.contact.roles = caller.process_roles(results.data?.contact?.roles)
    //         }
    //         if(results?.data?.contact?.notes !== undefined) {
    //             caller.view.state.data.notes = results.data.contact.notes
    //         }
    //         caller.view.state.data.contact = caller.getCopy(results?.data?.contact)
    //         caller.view.state.cache = caller.getCopy(caller.view.state.data.contact)
    //         caller.people = results?.data?.contact?.people
    //         caller.people?.unshift({email: "", first_name: "", last_name: "", phone: ""})
    //         caller.process_people()
    //         caller.load_company(results.data.contact.company.id)
    //     }
    // }
    process_contact({caller, params, results}) {
        if (results?.data?.contact?.notnull("id")) {
            caller.view.state.form_disabled = true
            if (results?.data?.contact?.notnull("people")) {
                caller.people       = [...results?.data?.contact?.people, ...caller.people]
            }
            caller.employees   = results?.data?.contact?.company?.employees
            if (results?.data.contact !== null && results?.data.contact !== undefined) {
                results.data.contact.roles   = caller.process_roles(results?.data?.contact?.roles)
            }
            if (results?.data?.contact?.address === null) {
                results.data.contact.address = {}
            }
            if (results?.data?.contact?.address?.location === null) {
                results.data.contact.address.location = {}
            }
            caller.view.state.data.company = results.data?.contact?.company
            caller.view.state.data.contact = results.data?.contact
            let name    = ((caller._notnull(caller.view.state.data?.contact?.name)) ? caller.view.state.data?.contact?.name : "")
            let company = ((caller._notnull(caller.view.state.data?.company?.id)) ? caller.view.state.data?.company : null)
            if (results?.data?.contact?.name !== undefined && results?.data?.contact?.name !== null) {
                company = {name: results.data.contact.name}
            }
            caller.setState({key: ["search",
                                    "search"],
                            param: ["company.search_value",
                                    "company.selected_value"],
                            value: [name,
                                    company]})
            let address = caller.contact?.address
            let location = caller.contact?.raw_location
            let zip = caller.contact?.zip
            caller.setState({key: ["search",
                                            "search",
                                            "search"],
                                        param: ["address.search_value",
                                                "zip.selected_value",
                                                "zip.search_value"],
                                        value: [address,
                                            location,
                                            zip]})
        }
    }
    async follow_on_selection({event, obj, data}) {
        if (data?.name === "zip") { 
            this.contact.zip        = obj.zip
            this.contact.city       = obj.city
            this.contact.state      = obj.state
            this.contact.country    = obj.country
        }
        if (data?.name === "address") {
            this.contact.address    = obj?.address
            this.contact.address_2  = obj?.address_2
            this.contact.zip        = obj?.location?.zip
            this.contact.city       = obj?.location?.city
            this.contact.state      = obj?.location?.state
            this.contact.country    = obj?.location?.country
            this.setState({key: "search",
                            param: "zip.selected_value",
                            value: {zip: obj?.location?.zip} })
        }
        if (obj?.id !== undefined && data?.name === "company") {
            let body    = JSON.parse(JSON.stringify(company_request))
            body.query  = body.query.replace("company", 'company(id: "'+obj?.id+'")')
            this.api.companies.ask({caller: this, params: {body: body}, nocache: true, callback: {f: this.process_company}})
        }
    }
    process_roles(roles) {
        let formatted_roles = { 
            payor: undefined,
            shipper: undefined,
            consignee: undefined,
        }
        roles?.map((role, index) => {
            if (role?.name !== undefined) {
                formatted_roles[role.name.toLowerCase()] = true
            } else {
                delete formatted_roles[role.name.toLowerCase()]
            }
        })
        if(formatted_roles.payor === true) {
            this.view.state.credit_fields = true
        }
        return formatted_roles
    }
    process_people() {
        this.personModels = []
        this.people?.map((person, index) => {
            person = new PersonModelController({params: {parent: this,
                                                        controller: this,
                                                        view: this.view,
                                                        is_view: true,
                                                        index: index,
                                                        _state: {data: person}}})
            this.personModels.push(person)
        })
    }
    load_company(id) {
        let body = JSON.parse(JSON.stringify(company_request))
        body.query  = body.query.replace("company", 'company(id: "'+id+'")')
        this.api.companies.ask({caller: this, params: {body: body}, callback: {f: this.process_company}})
    }
    process_company({caller, params, results}) {
        if(results?.data?.company !== undefined) {
            caller.view.state.data.company = results?.data?.company
            caller.view.forceUpdate()
        }
    }
    state_results({caller, params, results}) {
        let states = []
        let countries = []
        if (results?.errors === undefined && results?.data?.areas !== undefined) {
            results.data.areas.map((area, index) => {
                if(area.state !== null) {
                    states.push({ value: area.state, label: area.state })
                } else {
                    countries.push({ value: area.country, label: area.country })
                }
            })
            if(states.length > 0) {
                caller.view.state.states = states
            }
            if(countries.length > 0) {
                caller.view.state.countries = countries
            }
        }
    }
    edit() {
        this.setState({key: "edit_mode", value: true})
    }
    async format_request() {
        if (this?.state?.current_image) {
            this.state.parent.state.image = this?.state?.current_image
        }
        this.setState({save: true})
        this.setState({_is_mutating: true})
        let body = undefined
        if (this.state?.data?.contact?.id === undefined) {
            body    = JSON.parse(JSON.stringify(contact_mutation))
        } else {
            body    = JSON.parse(JSON.stringify(update_request))
        }
        let summary = this.contact.summary
        delete summary?.contact?.image
        let data = this.toUnquotedJSON(summary)
        this.contact.address    = summary?.contact?.address?.address
        this.contact.address_2  = summary?.contact?.address?.address_2
        this.contact.city       = summary?.contact?.address?.location?.city
        this.contact.state      = summary?.contact?.address?.location?.state
        this.contact.zip        = summary?.contact?.address?.location?.zip
        this.contact.country    = summary?.contact?.address?.location?.country
        body.query = body.query.replace("input:", 'input: '+data)
        let sb_config = {success: {show: false}}

        this.api.contacts.create({caller: this, params: {body: body}, callback: {f: this.process_update}})
    }
    process_update({caller, params, results}) {
       // console.log("CALLER",caller)
        if(results?.errors !== undefined) {
            console.log("Error updating contact", results.errors)
            return
        }
        caller.view.state.data.contact = caller.getCopy(caller.view.state?.data?.contact)
        // caller.view.state.data.contact.company = caller.getCopy(caller.view.state?.data?.contact?.company)
        caller.view.state.cache = caller.getCopy(caller.view.state?.data?.contact)
        caller.resolveCache()
	    caller.setState({key: "edit_mode", value: false})
    }
    close({save: save}) {
        // this.setState({key: "edit_mode", value: false})
        if (save) {
            this.save()
        } else {
            this.cancel()
        }
    }
    cancel() {
        this.setState({key: "edit_mode", value: false})
        this.controller?.resolveCache()
    }
    async save() {
        // need to find where to stop pulling data from db.
        this.view.state.save = true
        this.format_request()
//        this.view.state.data.contact = this.getCopy(this.view.state?.data?.contact)
//        this.view.state.cache = this.getCopy(this.view.state?.data?.contact)
//        this.controller?.resolveCache()
    }
    resolveCache() {
        if (this.view.state?.cache !== undefined) {
            this.view.state.data.contact    = this.getCopy(this.view?.state?.cache)
            this.setState({key: "data", param: "contact", value: this.getCopy(this.view?.state?.cache)})
            this.view.forceUpdate()
        }
    }
    handleCloseScreen() {
        // Needs a refactor
        this.view.state.panel_params.controller.view.resetScreen()
    }
    handleRoleClick(event) {
        let text = event.target.textContent.toLowerCase()
        this.setState(prevState => ({
            [text]: prevState?.[text] === "" ? text : ""
        }))
        this.controller.toggle_role(text)
        this.controller.toggle_credit(text)
    }
    toggle_role(text) {
        this.contact.roles[text] = !this.contact?.roles?.[text]
        this.view.forceUpdate()
    }
    toggle_credit(text) {
        if(text !== "payor") {
            return
        }
        this.view.setState(prevState => ({credit_fields: !prevState.credit_fields}))
    }
    save_button_row() {
        return (
            <>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col className='edit_contact no_wrap'
                    xs='auto'
                    onClick={this.edit.bind(this)}
                    tabIndex={this.view.tab("cancel")}>
                        Edit Contact
                </Col>
                <Col xs="auto"></Col>
            </>
        )
    }
    edit_button_row() {
        return (
            <>
                <Col></Col>
                <Col></Col>
                <Col className='no_wrap'
                    xs='auto'
                    onClick={this.close.bind(this, {save: false})}
                    tabIndex={this.view.tab("cancel")}>
                        <Button size="sm" variant="secondary" className='btn_secondary'>
                            Cancel
                        </Button>
                </Col> 
                <Col className='edit_contact no_wrap ' xs='auto'>
                    <Button size="sm" onClick={this.close.bind(this, {save: true})} 
                        tabIndex={this.view.tab("save")} className={this._theme}>
                        Save
                    </Button>
                </Col>
                <Col xs="auto"></Col> 
            </>
        )
    }
    editable_field({data, label, options}) {
      
            return (
                <Col>
                    <Row>
                        <Col className='info MuiInputLabel-shrink'>
                            {label}
                        </Col>
                    </Row>
                    <Row className="g-0">
                        <Col>
                            {this.view?.formObject({data: data, owner: this, options: options})}
                        </Col>
                    </Row>
                </Col>
            )
}
    view_field({data}) {

        if(this?.[data.title]?.[data.name] !== undefined) {
            if(this?.[data.title]?.[data.name] === "object") {
                return
            }
        }
        return (
            <>
                <Row>
                    <Col className="lg_title_row">
                        {this?.[data.title]?.[data.name] !== undefined && this?.[data.title]?.[data.name] !== null  ? this?.[data.title]?.[data.name] : " - " }
                    </Col>
                </Row>
            </>
        )
    }
    view_roles() {
        if(typeof this.contact?.roles !== "object") {
            return
        }
        if(Object.keys(this.contact?.roles)?.length === 0) {
            return <Row></Row>
        }
        return (
            <Row className='d-flex flex-row justify-content-end'>
                {Object.keys(this.contact?.roles).map((role, index) => {
                    let role_class = (this.contact?.[role]) ? role : ""
                    return (
                        <Col key={index} sm={3} className={"role "+role_class}>
                            {role}
                        </Col>
                    ) 
                })}
            </Row>
        )
    }
    contactPersonsDisplay() {
        if(this.personModels.length > 1) {
            return <ContactPersonPanel params={{parent: this.view, controller: this}} />
        }
        return (
            <Button className={"primary_button_coloring " +this._theme} 
                    style={{width:"100%"}} 
                    onClick={() => this.view.toggle_modal({toggle_state: true})}>
                        Add Contact Person
            </Button>
        )
    }
    switchContactPanel() {
        if(this.view.state.edit_mode) {
            return <EditContactPanel params={{parent: this.view, controller: this}}/>
        }
        return <ContactDetails params={{parent: this.view, controller: this}} />
    }
    // Needs a refactor
    credit_form(view) {
        if(this.view.state.credit_fields) {
            return (<>
                <Row>
                    {this.editable_field({data: view.state.form.credit_limit, label: "Credit Limit"})}
                </Row>
                <Row className='pad_top_8'>
                    {this.editable_field({data: view.state.form.credit_terms, label: "Credit Terms"})}
                </Row>
                <Row className='mt-5 align-items-middle'>
                    <Col>
                        <hr />
                    </Col>
                </Row>
            </>)
        }
        return <></>
    }
}  
