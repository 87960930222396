//REACT SPECIFIC
import React from "react";
import { Row, Col, Container, Button, Card } from "react-bootstrap";

//MODULE SPECIFIC
import _baseModal from "components/General/_baseModal.jsx";
import Controller from "./UnhookAssetModal.controller.jsx"
import "./UnhookAssetModal.css"

export default class UnhookAssetModal extends _baseModal {
    constructor(props) {
        super(props) 
        this.modal_controller   = new Controller ({params: {view: this, controller: this?.controller}})
        this.state.modal_params = {
            positioned: "middle_of_panel",
            has_overlay: true,
            title: "Unhook "  + this.controller.asset.inverse_type,
            close_on_outside_click: true,

        }
    }
    renderView() {
        return (
            <>
                <div id={this.name}>
                    <Col className="pad">
                        <Row>
                            <Col className="font">
                                Are you sure want to unhook this  {this.controller.asset.inverse_type}?
                            </Col>
                        </Row>
                        <Row>
                            &nbsp;
                        </Row>
                        <Row>
                            <Col>
                                <Button className={"clear_button "+this._theme} onClick={this.toggle_modal?.bind(this.state.parent, {toggle_state: false})} >
                                    Cancel
                                </Button>
                            </Col>
                            <Col>
                                <Button className={"primary_button_coloring "+this._theme} onClick={() => this.modal_controller.unattach_asset()}>
                                    Unhook
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </div>
            </>
        )
    }
}