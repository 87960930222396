// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import Documents from "./Partials/DocumentsPartial/Documents.partial.jsx"
import Loadboard from "./Partials/AdminColumns/AdminColumns.partial"
import Accounting from "./Partials/AccountingPartial/Accounting.partial"
import Details from "./Partials/AdminColumns/Details/Details.partial"
// import company_request from "assets/graphql/Companies/Company.documents.graphql.json"
import company_request from "assets/graphql/Companies/Company.carrier.graphql.json"
import self_request from "assets/graphql/Users/self.graphql.json"
import User from "controllers/ModelControllers/UserModelController"

// PARTIAL IMPORTS
import CompanyInfoPartial from "./Partials/CompanyInfo/CompanyInfo.partial.jsx"

export default class AdminController extends _viewController {
    constructor(props) {
        super(props)
        this.user               = new User({params: {parent: this, 
                                                controller: this,
                                                is_view: true}})
        this.company    = undefined
        this.companies  = []
        this.requests   = [{ request: self_request,
                                callback: {f: this.load_user, p: {name: "self"}}, endpoint: "users"},
                            {callback: {f: this.state_results, p: {name: "areas", var: ["areas", "states"]}}, 
                            endpoint: "areas", replace: {o: "params:", r:'params: {country: "US"}'}}]
    }
    get documents() {
        return this.view.state.documents
    }
    get states() {
        return this.view.state.states
    }
    get company_id() {
        return this.view.state?.data?.company?.id
    }
    get product() {
        let product = window?.location?.href?.split('//')?.[1]?.split('.')?.[0]
        if(product.includes("localhost:3002")) {
            return "carrierpro"
        }
        return product
    }
    load_user({caller, params, results}) {
        if(results?.errors !== undefined) { console.log(results?.errors) }
        if(results?.errors === undefined && results?.data?.self !== undefined) {
            caller.view.state.data.user = caller.getCopy(results.data.self)
            caller.view.state.cache = caller.getCopy(caller.view.state.data.user)
            caller.load_company(results.data?.self?.companies?.[0]?.id)
            // if(caller._notnull(results?.data?.self?.companies[0]?.carrier_id)) {
            //     console.log("FOUND A CARRIER ID IN SELF")
            //     console.log("CARRIER ID:", results?.data?.self?.companies[0]?.carrier_id)
            // }
        }
    }
    state_results({caller, params, results}) {
        if (results?.errors === undefined && results?.data?.areas !== undefined) {
            results.data.areas.forEach((area, index) => {
                caller.view.state.states.push({ value: area?.state, label: area?.state })
                return
            })
        }
    }
    load_company(id) {
        let body = JSON.parse(JSON.stringify(company_request))
        body.query  = body.query.replace("company", 'company(id: "'+id+'")')
        this.api.companies.ask({caller: this, params: {body: body}, callback: {f: this.process_company}})
    }
    process_company({caller, params, results}) {
        if(results?.errors !== undefined) {
            console.log("Error fetching company::", results?.errors)
            return
        }
        if(results?.data?.company !== undefined) {
            caller.view.state.data.company = results?.data?.company
            if (caller._notnull(results?.data?.company?.documents)) {
                caller.view.state.documents = results?.data?.company?.documents
            }
            caller.view.forceUpdate()
        }
    }
    load_page(page_num) {
        // maybe make another page for insurance?
        switch (page_num) {
            case "documents":
                return <Documents params={{parent: this.view, 
                                            controller: this}}/>
            case "accounting":
                return <Accounting params={{parent: this.view, 
                                            controller: this}}/>
            case "loadboards":
                return <Loadboard params={{parent: this.view, 
                                            controller: this}}/>
            case "details":
                return <Details params={{parent: this.view, 
                                          controller: this}}/>
            default:
                return <CompanyInfoPartial params={{parent: this.view, controller: this}}/>
        }
    }
    follow_on_dropdown({key, param, value}) {
        this.setState({key: "selection", value: value})
    }
    change_page(e) {
        this.setState({key: "selection", value: e.target.title})
    }
    resolveCache() {
        if (this.view.state?.cache !== undefined) {
            this.view.state.context.user    = this.getCopy(this.view?.state?.cache)
            this.setState({key: "data", param: "user", value: this.getCopy(this.view?.state?.cache)})
            this.view.forceUpdate()
        }
    }
    display_internal() {
        if (this.check_admin() === true) {
            return " "
        } else {
            return " hide"
        }
    }
    processEventReaction(decoded) {
        this.view.state.selected_doc = decoded?.message?.data
        this.view.toggle_modal({toggle_state: true})
    }
    get_details({date}) {

    }
}
