// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Modal from "./DocUpload.modal.jsx";
import "components/NewLoadPartials/ShipperObject/Shipper.css"
import "./DocUpload.css"

export default class DocUpload extends _basePartial {
    constructor(props) {
        super(props)
        this._modal = Modal
        this._center_modal = true
    }
    renderView() {
        return (
            <Row id={this.name}>
                <Col>
                    <Button className={this._theme+" "} onClick={() => this.toggle_modal({toggle_state: true})}>
                        Upload New Document
                    </Button>
                </Col>
            </Row>
        )
    }
}
