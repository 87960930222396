// GENERAL REACT MODULES

// ALL OTHER MODULES
import LoadModelController from "./LoadModelController.jsx";
import _ModelController from "./_ModelController.jsx";
import CompanyModelController from "./CompanyModelController.jsx";

export default class AssetModelController extends _ModelController {
    constructor(props) {
        super(props)
        this._state = undefined
        this.loads = new LoadModelController ({params: {parent: this,
                                                controller: this,
                                                is_view: true}})
        this.company = new CompanyModelController({params: {parent: this, 
                                                    controller: this.controller,
                                                    is_view: true}})

    }
    get all () {
        return this.data
    }
    get id() {
        return this.data?.id
    }
    get notes() {
	    return this.data?.notes
    }
    set notes(value) {
	    this.data.notes = value
    }
    get asset_number() {
        if(this._notnull(this.data?.truck_number)) {
            return this.data?.truck_number
        }
        if(this._notnull(this?.data?.trailer_number)) {
            return this.data?.trailer_number
        }
        return this.data?.asset_number
    }
    set asset_number(value) {
        this.data.asset_number = value
    }
    get asset_type() {
        return this.data?.asset_type
    }
    set asset_type(value) {
        this.data.asset_type = value.toUpperCase()
    }
    get is_boxtruck() {
        return this?.data?.is_boxtruck ?? false
    }
    set is_boxtruck(value) {
        this.data.is_boxtruck = value
    }
    get current_miles() {
        return this.data?.current_miles
    }
    set current_miles(value) {
        this.data.current_miles = value
    }
    get last_location() {
        return this.data?.last_location
    }
    set last_location(value) {
        this.data.last_location = value
    }
    get length() {
        return this.data?.length
    }
    set length(value) {
        return this.setState({param: "length", value: value})
    }
    get vin_number() {
        return this.data?.vin_number
    }
    set vin_number (value) {
        this.data.vin_number = value
    }
    get year() {
        return this.data?.year
    }
    set year (value) {
        this.data.year = value
    }
    get license_plate() {
        return this.data?.license_plate
    }
    set license_plate (value) {
        this.data.license_plate = value
    }
    get weight() {
        return this.data?.weight
    }
    set weight (value) {
        this.data.weight = value
    }
    get make() {
        return this.data?.make
    }
    set make (value) {
        this.data.make = value
    }
    get model() {
        return this.data?.model
    } 
    set model (value) {
        this.data.model = value
    }
    get color() {
        return this.data?.color
    }
    set color(value) {
        return this.data.color = value
    }
    get registration_date() {
        return this.data?.registration_date
    }
    set registration_date(value) {
        this.data.registration_date = value
    }
    get registration_number() {
        return this.data?.registration_number
    }
    set registration_number (value) {
        this.data.registration_number = value
    }
    get registration_state() {
        return this.data?.registration_state
    }
    set registration_state (value) {
        this.data.registration_state = value
    }
    get starting_miles() {
        return this.data?.starting_miles
    }
    set starting_miles (value) {
        this.data.starting_miles = value
    }
    get starting_zip() {
        return this.data?.starting_zip
    }
    set starting_zip (value) {
        this.data.starting_zip = value
    }
    get weight_capacity() {
        return this.data?.weight_capacity
    }
    set weight_capacity (value) {
        this.data.weight_capacity = value
    }
    get current_location() {
        return this.data?.current_location
    }
    set current_location (value) {
        this.data.current_location = value
    }
    get trailer_number() {
        return this.data?.trailer_number
    }
    set trailer_number (value) {
        this.data.trailer_number = value
    }
    get truck_number() {
        return this.data?.truck_number
    }
    set truck_number (value) {
        this.data.truck_number = value
    }
    get trailer_type() {
        return this.data?.trailer_type?.name
    }
    set trailer_type(value) {
        // this.setState({param: "trailer_type.name", value: value})
        this.data.trailer_type.name = value
    }
    get support_air_ride() {
        return this.data?.support_air_ride
    }
    set support_air_ride(value) {
        this.setState({param: "support_air_ride", value: value})
    }
    get trailer_truck() {
        return this.data?.trailer_truck
    }
    get base_type() {
        if (this.asset_type === "TRUCK") {
            return "Truck"
        } else {
            return "Trailer"
        } 
    }
    get inverse_type() {
        if (this.asset_type !== "TRUCK") {
            return "Truck"
        } else {
            return "Trailer"
        } 
    }
    get trailer_truck() {
        return this.data?.trailer_truck
    }
    set trailer_truck(value) {
        this.setState({param: "trailer_truck", value: value})
    }
    get status_toggle() {
        return this.data?.status_toggle
    }
    set status_toggle(value) {
        this.setState({param: "status_toggle", value: value})
    }
    get status () {
        if (this.data?.status !== undefined) {
            return this.data?.status
        }
        return "out of service"
    }
    set status(value) {
        this.data.status = value
    }
    get company_id() {
        return this.data?.company_id
    }
    set company_id(value) {
        this.data.company_id = value
    }
    get city() {
        return this.data?.address?.location?.city
    }
    set city (value) {
        // this.setState({param: "address.location.city", value: value})
        this.data.city = value
    }
    get state() {
        return this.data?.address?.location?.state
    }
    set state(value) {
        // this.setState({param: "address.location.state", value: value})
        this.data.state.id = value
    }
    get zip() {
        return (this.data?.address?.location?.notnull("zip")) ? String(this.data.address.location.zip): this.company?.zip
    }
    set zip (value) {
        this.setState({param: "address.location.zip", value: value})
    }
    resolve_address() {
        let address = this.controller.getState({key: "search", param: "address.selected_value"})
        if (address?.address !== undefined) {
            return address?.address
        }
        address = this.controller.getState({key: "search", param: "address.search_value"})
        return address
    }
    resolve_zip() {
        let _zip = this.controller.getState({key: "search", param: "zip.selected_value"})
        if (_zip !== undefined) {
            return _zip.zip
        }
        _zip = this.controller.getState({key: "search", param: "zip.search_value"})
        return _zip
    }
    get summary() {

        let _summary = {}
        _summary = JSON.parse(JSON.stringify(this.data))
        _summary.asset_number = this.asset_number
        if (this.address !== undefined && this.address !== null) {
            _summary.address = {
                address: this.resolve_address(),
                address_2: this.address_2,
                location: { 
                    city: this.city,
                    state: this.state,
                    zip: this.resolve_zip()
                }
            }
            delete this.data.city
            delete this.data.state
        }
	    _summary.status = _summary.status?.replace(/-/g, '_')
        if ( _summary.trailer_type !== undefined && _summary.trailer_type !== null) {
            _summary.trailer_type = {
                name: this.data.trailer_type?.name ?? this.data.trailer_type
            }
        }
        if (_summary.length !== undefined) {
            _summary.length = Number(_summary.length)
        }
        if ( _summary.current_miles !== undefined) {
                _summary.current_miles = Number(_summary.current_miles)
        }
        if (_summary.starting_miles) {
                _summary.starting_miles = Number(_summary.starting_miles)
        }
        if (_summary.weight) {
                _summary.weight = Number(_summary.weight)
        }
        if(_summary.weight_capacity) {
            _summary.weight_capacity = Number(_summary.weight_capacity)
        }
        if(_summary.year) {
            _summary.year = Number(_summary.year)
        }
        if(this._notnull(_summary?.driver)) {
            delete _summary.driver.asset
            delete _summary.driver.name
            delete _summary.driver.user
        }
        if(this._notnull(_summary?.linked_asset?.id)) {
            delete _summary.linked_asset
        }
        delete _summary.status_toggle
        delete _summary.city
        delete _summary.state
        _summary.scrub()
        return _summary
    }
}