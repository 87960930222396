// GENERAL REACT MODULES
import React from "react";
import { Row } from 'react-bootstrap';

// PAGE SPECIFIC MODULES
import Column from "components/Column/Column.view.jsx"
import Seperator from "components/Seperator/Seperator.view.jsx"
import ColumnOneController from "./ColumnOne.controller";
import _basePartial from "components/General/_basePartial.jsx"

export default class AdminColumnsPanel extends _basePartial {
    constructor(props) {
        super(props)
        this.controller = props.params?.controller
        this.state.files = []
        let get_column_controller = this.get_column_controller.bind(this)
        this.column = {
            controllers: {
                get one() {
                    return get_column_controller({name: "column_one_controller", class_name: ColumnOneController})
                }
            }
        }
    }
    get_column_controller({name, class_name}) {
        if (this.state?.[name] === undefined && class_name !== undefined) {
            this.state[name] = new class_name({params: {view: this}})
        }
        return this.state?.[name]
    }
    renderView() {
        return(
            <span className="admin_middle_wrapper" id={this.name}>
                <Row className="g-0 admin_max_height">
                    <Column params={{parent: this, controller: this.controller, view_controller: this.column.controllers.one }}/>
                    <Seperator params={{parent: this, controller: this.controller, view_controller: this.column.controllers.two, solid: true}}/>
                    <Column params={{parent: this, controller: this.controller, view_controller: undefined }}/>
                </Row>
            </span>
        )
    }
}