// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button } from "react-bootstrap";
// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx";
import ModalController from "./EditPayor.modal.controller";
import PayorObject from "components/NewLoadPartials/PayorPartial/Payor.partial.jsx"
import ReformPayorObject from "../ReformPayorObject/ReformPayorObject.partial";
import "./EditPayor.modal.css";

export default class EditPayorModal extends _baseModal {
  constructor(props) {
    super(props)
    this.modal_controller = new ModalController({ params: { view: this, controller: this.controller } })
    this.state.dims = { width: 636, height: 980 }
    this.state.modal_params = {
      positioned: "middle_of_panel",
      title: "Edit Payor",
      title_size: "",
      y_adjustment: "-100px",
      x_adjustment: "0px",
      has_overlay: false,
      close_on_outside_click: true,
      closeable: true,
      draggable: true,
      overflow: "hidden"
    }
  }
  get reform_mode() {
    if(this.controller?.load?.status === "Pending") {
      return true
    }
    return false
  }
  render_payor_object() {
    if(this.reform_mode) {
      return <ReformPayorObject params={{parent: this, controller: this.modal_controller, borderless: true, half_width: false,
                    closeable: true, sandwich: true}}/>
    }
    return <PayorObject params={{parent: this,
                                controller: this.modal_controller,
                                borderless: true, half_width: true,
                                closeable: true, sandwich: true
                              }} />
  }
  renderView() {
    console.log(this)
    return (
      <Row className="modal_dims">
        <Col>
          <Row className="titles row_padding input_row_height">
            <Col>
              {this.render_payor_object()}
            </Col>
          </Row>
          <hr />
          <Row className="flex-nowrap">
            <Col>
              <Button className={"clear_button " + this._theme} onClick={() => this.modal_controller.cancel()}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button className={"primary_button_coloring " + this._theme} onClick={() => this.modal_controller.save_action()}>
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}
