	// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "../_ModelController";

export default class EquipmentModelController extends _ModelController {
    constructor(props) {
        super(props)
        this._state = undefined
    }
    get requirements() {
        return this.data?.requirements
    }
    set requirements(value) {
        this.data.requirements = value
    }
    get trailer_types() {
        return this.data?.trailer_types
    }
    set trailer_types(value) {
        this.data.trailer_types = value
    }
    get summary() {
        // let _summary = {
        //     trailer_types: this.data?.trailer_types?.map((el , index) => { return el.value }),
        //     requirements: this.data?.requirements?.map((el , index) => { return el.value })
        // }
        let _summary = {
            trailer_types: this.data?.trailer_types,
            requirements: this.data?.requirements
        }
        return _summary
    }
}
