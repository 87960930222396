// GENERAL REACT MODULES
import React from "react";

// ALL OTHER MODULES
import "styles/generic.css"
import _baseViewComponent from "./_baseViewComponent.jsx"
import { PanelController } from "controllers/PanelController/PanelController.jsx";

var attached = false;

export default class _basePanelView extends _baseViewComponent {
    constructor(props) {
        super(props)
        this.state.screen       = 0
        this.state.path         = -1
        this._toggled_modal     = false
        this.handleProcessEvent = this.handleProcessEvent.bind(this)
    }
    followDidMount() {
        this.mount_listener()
    }
    mount_listener() {
        if (!attached) {
            window.addEventListener('message', this.handleProcessEvent);
            attached = true
        }
    }
    followUnDidMount() {
        window.removeEventListener('message', this.doSomething)
        if (Array.isArray(this.state?.channel_name)) {
            this.state?.channel_name.map((msg, index) => {
                window.removeEventListener('msg', this.doSomething)
            })
        }
        attached = false
    }
    handleScreen({path_num, screen_num, data}) {
        let path = (path_num === undefined) ? 0 : path_num
        let screen = (screen_num === undefined) ? 0 : screen_num
        this.setState({path: path, screen: screen, panel_data: data})
    }
    handleProcessEvent = event => {
        if (event.data !== undefined) {
            const data = event.data;
            try {
                const decoded = JSON.parse(data);
                if (Array.isArray(this.state?.channel_name)) {
                        if (this.state?.channel_name.indexOf(decoded?.channel) > -1) {
                                this.controller?.processEventReaction(decoded)
                        }
                }
                if (decoded?.channel === this.state?.channel_name) {
                    this.controller?.processEventReaction(decoded)
                }
            } catch (e) { }
        }
    }

    resetScreen() {
        this.setState({path: -1})
        this.state.path = -1
        this.forceUpdate()
    }
    backScreen() {
//	    this.setState({path: this.state.path-1})
    }
    checkRoute() {
        if (this.controller.paths[1] !== undefined) {
            if (this.route_keys?.[this.controller.paths[1]] !== undefined) {
                return this.route_keys[this.controller.paths[1]]
            }
        }
        return this.state.screen
    }
    check_render() {
        // Quick Links here?
        if (!this.controller?.direct_load()) {
            return this.renderMain()
        }
    }
    renderView() {
	    this.state.context.page = this
        switch (this.state.path) {        
            case -1:
                return(
                    <>
                        {this.check_render()}
                    </>
                )
            default:
                return (
                    <>
                        <PanelController params={{parent: this,
                                                    panel_name: this.state.panel_trees[this.state.screen]?.name,
                                                    tree: this.state.panel_trees[this.state.path],
                                                    path: this.state.path,
                                                    panel_index: this.state.screen,
                                                    panels: this.state.panels}}/>
                    </>
                )
        }
    }
}
