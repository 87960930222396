// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import { Form, Row, Col, Card, Button, Container } from 'react-bootstrap'


export default class DriverDetailsPartialController extends _viewController {
    constructor(props) {
        super(props)
    }
    saveAction(page_key) {
        this.state?.parent?.selectPanel({panel_name: page_key, cache_data: {}})
    }
    get first_name() {
        return this.controller?.driver?.first_name
    }
    editable_field({data, label, options}) {
        if(this.user?.company._sum > 0 ) {
            this.company = this.user?.company
        }
        if (this.view.state.edit_mode) {
            let name = data['name']
            data ['placeholder'] = (this.user?.company?.[name] !== undefined) ? this.user?.company?.[name] : data['name']
            return (
                <Col>
                    <Row className = "g-0">
                        <Col>
                            {label}
                        </Col>
                    </Row>
                    <Row className="g-0">
                        <Col>
                            {this.view?.formObject({data: data, owner: this, options: options})}
                        </Col>
                    </Row>
                </Col>
            )
        }
        return (
            <Col>
                <Row>
                    <Col>
                        {this.get_object_param({key: data?.title, param: data?.name})}
                    </Col>
                </Row>
            </Col>
        )
    }
}