// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";
import { Textfit } from 'react-textfit';

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./ReformPayorObject.controller.jsx"
import "./ReformPayorObject.css"
import config_data from "./ReformPayorObject.json"
import { AiFillCloseCircle } from "react-icons/ai";
import { BsFillCaretLeftFill, BsFillCaretRightFill } from "react-icons/bs";


export default class ReformPayorObject extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this, controller: this.controller}})
        this.state.form         = config_data.form_objects
        this.state.border       = (this.state?.borderless) ? "" : "cota_panel_object_unsized"
        this.state.style        = (this.state?.borderless) ? {margin: 0, padding: 0, border: "none"} : {}
        this.state.half_width   = (this.state?.half_width) ? {width: "50%"} : {}
    }
    get company() {
        return this.partial_controller.company
    }
    get contact() {
        return this.partial_controller.contact
    }
    determine_edit_contact(param) {
        console.log("RUNNING  determine_edit_contact",this)
        // this.state.parent.state.parent.toggle_modal({ toggle_state: false })
        this.state.parent.state.parent.changePayor(param)
        console.log("NEW MODAL",this.state.parent.state.parent)
        // this.state.parent.state.parent.toggle_modal({ toggle_state: true })
        // this.state.parent.state.parent.forceUpdate()
        const editContact = this.controller?.editContact.bind(this)
        return editContact()
    }
    render_edit_btn() {
        if(this.reform_mode) {
          return (
            <Col>
              <Button className={"primary_button_coloring " + this._theme} onClick={() => this.modal_controller.save_action()}>
                Save
              </Button>
            </Col>
          )
        }
      }
    center() {
        if (this.state.center) {
            return "center_payor"
        }
        return ""
    }
    render_payor_view() {
        return (
            <Row className="g-0 min_col" id={this.name}>
                <Col className="g-0">
                    <Row className={this.border + " cota_payor_box g-0 "+this.center()} style={this.state.style}>
                        {(this.state.sandwich) ? this.add_company() : this.a_wrap()}
                    </Row>
                </Col>
                {this.add_nav()}
            </Row>
        )
    }
    closeable() {
        if (this.state.closeable) {
            return (
                <AiFillCloseCircle size={20} className={"searchable_close "} onClick={this.partial_controller.close.bind(this)}/>
            )
        }
    }
    a_wrap() {
        if (this.company?.url !== undefined) {
            return (
                <a href={this.company?.url} target="_blank">
                    {this.add_company()}
                </a>
            )
        }
        return this.add_company()
    }
    add_image() {
        return <img src={this.company?.image} className="cota_payor_image"/>
    }
    display_image() {
        if(this.state.sandwich) {
            return (
                <Col className="g-0 cota_payor_image_col">
                    {this.a_sandwich()}
                </Col> 
            )
        }
        return (
            <Col className="g-0 cota_payor_image_col">
                <img src={this.company?.image} className="cota_payor_image"/>
            </Col>
        )
    }
    a_sandwich() {
        if (this.company?.url !== undefined) {
            return (
                <a href={this.company?.url} target="_blank">
                    {this.add_image()}
                </a>
            )
        }
        return this.add_image()
    }
    add_company() {
        return (
            <Col>
                {this.closeable()}
                <Row className="padding">
                    {/* <Col className="g-0 cota_payor_image_col">
                        <img src={this.company?.image} className="cota_payor_image"/>
                    </Col> */}
                    {this.display_image()}
                    <Col sm={5} className="g-0 company_name_label_col">
                        <Textfit mode="single" className="payor_label">
                            {(this.company?.name !== undefined) ? this.company.name : ""}
                        </Textfit>
                    </Col>
                </Row>
            </Col>
        )
    }
    add_nav() {
        if (isNaN(this.controller?.company?.index)) {
            return this.add_label()
        }
        return (
            <Col sm={1} className="cota_payor_title_box strong_font g-0">
                <Row>
                    <Col sm={3} className="g-0">
                        <BsFillCaretLeftFill size={30} className="nav_arrow pointer" onClick={this.controller?.company.nav.bind(this.controller?.company, false)}/>
                    </Col>
                    <Col className="g-0">
                         {this.state.label}
                    </Col>
                    <Col sm={3} className="g-0">
                        <BsFillCaretRightFill size={30} className="nav_arrow pointer" onClick={this.controller?.company.nav.bind(this.controller?.company, true)}/>
                    </Col>
                </Row>
            </Col>
        )
    }
    add_label() {
        if (this.state.label !== undefined) {
            return (
                <Col sm={1} className="cota_payor_title_box strong_font g-0">
                    {this.state.label}
                </Col>
            )
        }
    }
    render_payor_form() {
        return (
            <Row className="g-0 min_col" id={this.name}>
                <Col className="g-0">
                    <Row>
                        <Col className='large_text'>
                            {this.state.title}
                        </Col>
                    </Row>
                    <Row style={this.state.half_width}>
                        <Col sm={8} >
                            {this.formObject({data: this.state.form.contact, 
                                                        options: undefined,
                                                        controller: this.partial_controller,
                                                        owner: this.partial_controller})}
                        </Col>
                        {/* <Col sm={3} className="create_contact_col">
                            <Button className={"toggle_button_style " + this.controller.getTheme()}
                                onClick={() => this.determine_edit_contact("new")}>
                                Create Contact
                            </Button>
                        </Col> */}
                        <Col sm={3} className="create_contact_col">
                            <Button className={"toggle_button_style " + this.controller.getTheme()}
                                onClick={() => this.determine_edit_contact()}>
                                Edit Contact
                            </Button>
                        </Col>
                    </Row>
                </Col>
        
            </Row>
        )
    }
    renderView() {
        console.log(this)
        this.state.data.company = this.controller?.company?.data
        if (this.state.data?.company?.id !== undefined && this.state.data?.company?.id !== null) {
            return this.render_payor_view()
        }
        return this.render_payor_form()
    }
}
