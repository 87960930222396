//GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button } from "react-bootstrap";

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx";
import Modal from "./InvoiceModal/InvoiceModal.modal.jsx"
import Controller from "./InvoicePanel.partial.controller.jsx"
import "./InvoicePanel.css";
import UploadPartial from "components/FileUpload/Upload.partial.jsx"
import DownloadPartial from "components/FileDownload/Download.partial.jsx"

export default class InvoicePanel extends _basePartial {
    constructor(props) {
        super(props)
        this._modal = Modal
        this.partial_controller = new Controller({params: {view: this, controller: this.controller}})
    }
    get invoice_pdf() {
        return this.controller.view.state.invoice_pdf
    }
    toggleModalFunction(){
        this.toggle_modal({ toggle_state: true });
    };
    render_download() {
        if(this.invoice_pdf !== undefined) {
            return <DownloadPartial params={{parent: this, controller: this.controller, is_private: true, file: this.invoice_pdf, button: true, title: "Download Invoice", css: "w-100"}}/>
            // return <DownloadPartial params={{parent: this, controller: this.controller, is_private: true, file: this.invoice_pdf}}/>
        }
        return <></>
    }
    // REVISIT WHEN WE HAVE FACTORING INTEGRATIONS LIVE.
    renderView() {
        // DOES THIS NEED TO ONLY APPEAR IF THE PAYOR OR DISPATCHER IS COTA??
        // Will make this return just a download button for now. 
        return (
            <Row id={this.name}>
                <Col className="g-0">
                    {this.render_download()}
                </Col>
            </Row>
        )
        // return (
        //     <Row id={this.name}>
        //         <Col className="panel add_shadow">
        //             <Row className="top_row">
        //                 <Col>
        //                     Get Paid Faster with Invoice Factoring
        //                 </Col>
        //             </Row>
        //             <Row className="bottom_row">
        //                 <Col>
        //                     <Row className="btn_row">
        //                         <Col sm={2}>
        //                             {this.render_download()}
        //                         </Col>
        //                         <Col style={{textWrap: "nowrap"}}>
        //                             {(this.invoice_pdf !== undefined) ? "Download Invoice" : ""}
        //                         </Col>
        //                     </Row>
        //                     <Row>
        //                         <Col>
        //                             <Button className="green_btn" onClick={this.toggleModalFunction.bind(this)}>
        //                                 Get Paid Today
        //                             </Button>
        //                         </Col>
        //                     </Row>
        //                     <Row>
        //                         <Col className="bottom_text">
        //                             All invoices must be received by Noon PST
        //                         </Col>
        //                     </Row>
        //                 </Col>
        //             </Row>
        //         </Col>
        //     </Row>
        // )
    }
}
