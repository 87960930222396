// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx"
import "./Documents.css"
import UploadPartial from "components/FileUpload/Upload.partial.jsx";
import Controller from "./Documents.modal.controller"
import DownloadPartial from "components/FileDownload/Download.partial";
import DeleteFile from "components/DeleteFile/DeleteFile.button.jsx"
import documents from "./Documents.json"

export default class DocumentsModal extends _baseModal {
    constructor(props) {
        super(props)
        this.modal_controller   = new Controller({params: {view: this, controller: this.controller }})
        this.state.modal_params = {
            positioned: "middle_of_panel",
            title: "Documents",
            title_size: "",
            closeable: true,
            has_overlay: true,
            close_on_outside_click: true,
            x_adjustment: "0px",
            y_adjustment: "-100px",
            draggable: false,
        }
        this.state.doc_types = documents.doc_types
    }
    render_delete(doc) {
        if(doc?.created_on === "freightpro" || doc?.name === "RATE_CONFIRMATION") {
            return <></>
        } 
        return <DeleteFile params={{button: false, file: doc, controller: this.controller, parent: this}} />
    }
    renderView() {
        return (
            <>
                <Row className="pad_bottom">
                    <UploadPartial params={{parent: this.view, controller: this.controller, endpoint: "load", doc_type_override: this.state.doc_types}} />
                </Row>
                <Row className="autoscroll_list">
                    <Col>
                        {
                            this.controller?.load?.documents?.map((doc, index) => {
                                    const docName = doc?.name
                                    const docTypeName = doc?.document_type?.name
                                return (
                                    <Row className={"doc_row pointer "+this.modal_controller.get_row_color(index)}
                                                onClick={this.modal_controller.download_file?.bind(this, {item: doc, index: index})}
                                                onMouseEnter={this.modal_controller.hover_line.bind(this, index)} 
                                                onMouseLeave={this.modal_controller.hover_line.bind(this, index)}>
                                        <Col className="center_text"> 
                                            {docName && this.modal_controller.truncateString(docName, 24)}
                                        </Col>
                                        <Col className="center_text">
                                            {docTypeName && this.modal_controller.truncateString(docTypeName, 24)}
                                        </Col>
                                        <Col sm={1}>
                                            <DownloadPartial params={{button: false, file: doc, controller: this.controller, parent: this}} />
                                        </Col>
                                        <Col sm={1}>
                                            {this.render_delete(doc)}
                                            {/* <DeleteFile params={{button: false, file: doc, controller: this.controller, parent: this}} /> */}
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </Col>
                </Row>
            </>
        )
    }
}
