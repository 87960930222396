// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx";
import ModalController from "./DriverModal.controller";
import config_data from "./DriverModal.json"
import "./DriverModal.css"

export default class DriverModal extends _baseModal {
    constructor(props) {
        super(props);
        this.state.draggable = false
        this.state.modal_params   = {
            positioned: "middle_of_panel",   
            title: "New Driver",                
            title_size: "",                     
            closeable: true,                    
            has_overlay: true,                 
            close_on_outside_click: true,       
            relative_position: "",
            relative_alignment: "",
            x_adjustment: "0px",                
            y_adjustment: "0px",
        }
        // this.modal_controller   = new ModalController ({params: {view: this}})
        this.uncontrolled_modal_controller     = new ModalController ({params: {view: this}})
        this.state.form           = config_data.form_objects
        this.state.config_data    = config_data
        this.state.dims           = {width: 650, height: 980}
	    this.state.data		= {}
        // this.state.data = {first_name: null, last_name: null, company: null, email: null}
    }
    follow_toggle_modal() {
	    // console.log("MOUNTED")
    }
    renderView() {
        // console.log("--- VIEWING ---")
        // console.log(this)
        // console.log(this.state.data)
        return (
            <Row ref={ (divElement) => { this.divElement = divElement } } style={this._inner_modal_location}>
                <Col>
                    <Row>
                    <Col>
                        <Row className="info MuiInputLabel-shrink">
                            <Col>
                                FIRST NAME*
                            </Col>
                        </Row>
                        <Row >
                            <Col>
                                {this.formObject({data: this.state.form.first_name, owner: this, controller: this.uncontrolled_modal_controller})}
                            </Col>
                        </Row>
                        <Row className="info MuiInputLabel-shrink">
                            <Col>
                                LAST NAME*
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.formObject({data: this.state.form.last_name, owner: this, controller: this.uncontrolled_modal_controller})}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row className="info MuiInputLabel-shrink" >
                            <Col>
                                EMAIL*
                            </Col>
                        </Row>
                        <Row >
                            <Col>
                                {this.formObject({data: this.state.form.email, owner: this, controller: this.uncontrolled_modal_controller})}
                            </Col>
                        </Row>
                        <Row className="info MuiInputLabel-shrink">
                            <Col>
                                DRIVER TYPE*
                            </Col>
                        </Row>
                        <Row>
                            <Col className="dropdown_style">
                                {this.formObject({data: this.state.form.driver_type, options: config_data.company_options, owner: this, controller: this.uncontrolled_modal_controller})}
                            </Col>
                        </Row>
                    </Col>
                    </Row>
                    <hr className="hr"/>
                    <Row className="no_wrap">
                        <Col>
                            <Row className="info font_size" >
                                <Col className="generic_center">
                                    An invite will be sent to the user's email address
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="pad_no_wrap">
                        <Col></Col>
                        <Col>
                            <Row>
                                <Col>
                                    <Button className={"clear_button " + this._theme} onClick={this.toggle_modal?.bind(this.state.parent, {toggle_state: false})}>
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    <Button className={"primary_button_coloring " + this._theme} onClick={this.uncontrolled_modal_controller?.send_invite.bind(this)}>
                                        Invite
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}


