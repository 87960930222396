// GENERAL REACT MODULES
import React from "react";
import { Row, Col } from 'react-bootstrap'

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Modal from "./CotaUserMenu.modal.jsx"
import "./CotaUserBar.css"

export default class CotaUserMenu extends _basePartial {
    constructor (props) {
        super(props)
        this._modal = Modal;
    }
    get user() {
        return this.parent.state?.data?.user
    }
    get initials() {
        if(this.user?.given_name === undefined || this.user?.family_name === undefined) {
            return ""
        }
        return this.user?.given_name?.charAt(0) + this.user?.family_name?.charAt(0)
    }
    renderView() {
        return (
            <Row id={this.name} className="user_menu_link" onClick={()=>this.toggle_modal({toggle_state: true})}>
                <Col xs="auto" className="user_fullname" >
                    {this.user?.name}
                </Col>
                {!this?.controller?.controller?.context?.self?.image?.[0] ?
                <Col>
                    <div className={"image_icon square rounded-circle avatar_background margin_auto "+this._theme} >
                        <span className="initials">{this.initials}</span>
                    </div>
                </Col>
                :
                <Col>
                <div >
                <span >
                <img  className={"image_icon square rounded-circle avatar_background margin_auto "+this._theme} src={this?.controller?.controller?.context?.self?.image?.[0]?.url} alt="Cota User" />
                </span>
                </div>
                </Col>
    }
            </Row>
        )
    }
}