import {Row, Col, Card, Button } from "react-bootstrap";

import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./StopPickupCard.controller.jsx"
import config_data from "./StopPickupCard.json"
import { FaUser} from "react-icons/fa";
import "./StopPickupCard.css"

export default class StopPickupCardPanel extends _basePartial {
    constructor (props) {
        super(props)
        this.controller = new Controller({params: {view: this}})
        this.state.config_data = config_data
        // this.state.config_data = config_data
    }

    render() {
        return (
            <Row id={this.name}>
                <Card>
                    <Row className="end g-0">
                        <Row className="shipper">
                            Shipper
                        </Row>
                    </Row>
                    <Row className="wrap">
                        <Col className="pad_left">
                            <Row >
                                <Col className="title">
                                    {this.state?.details.id}
                                </Col>
                            </Row>
                            <Row>
                                &nbsp;
                            </Row>
                            <Row>
                                <Col className="address">
                                    Address
                                </Col>
                                <Row>
                                    <Col className="location">
                                        {this.state?.details?.address}
                                    </Col>
                                </Row>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                &nbsp;
                            </Row>
                            <Row>
                                &nbsp;
                            </Row>
                            <Row >
                                <Col>
                                    <Row className="address">
                                        Contact Details
                                    </Row>
                                </Col>
                                <Row>
                                    <Col>
                                        <Row className="location">
                                            {this.state?.details?.contact_phone}
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row className="location">
                                            {this.state?.details?.contact_email}
                                        </Row>
                                    </Col>
                                </Row>
                            </Row>   
                        </Col>
                        <Col>
                            <Row>
                                <Col className="end">
                                    <div className="user">
                                        <FaUser size={30}/>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    &nbsp;
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    &nbsp;
                                </Col>
                            </Row>
                            <Row>
                                <Col>

                                </Col>
                                <Col sm={2}>
                                    <Button  onClick={() => this.state.parent.setState({show: false})} sm={2} className="clear_button">
                                        Delete
                                    </Button>
                                </Col>
                                <Row>
                                    &nbsp;
                                </Row>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </Row>
        )
    }
}