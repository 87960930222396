// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx"
import "./ChangeContactModal.css"
// import UploadPartial from "components/FileUpload/Upload.partial.jsx";
import Controller from "./ChangeContactModal.controller"
// import DownloadPartial from "components/FileDownload/Download.partial";
// import DeleteFile from "components/DeleteFile/DeleteFile.button.jsx"
// import documents from "./AutoLoadModal.json"

export default class ChangeContactModal extends _baseModal {
    constructor(props) {
        super(props)
        this.modal_controller   = new Controller({params: {view: this, controller: this.controller }})
        this.state.modal_params = {
            positioned: "middle_of_panel",
            title: "",
            title_size: "",
            closeable: false,
            has_overlay: true,
            close_on_outside_click: true,
            x_adjustment: "0px",
            y_adjustment: "-100px",
            draggable: false,
        }
        // this.state.doc_types = documents.doc_types
    }
    // render_delete(doc) {
    //     if(doc?.created_on === "freightpro" || doc?.name === "RATE_CONFIRMATION") {
    //         return <></>
    //     } 
    //     return <DeleteFile params={{button: false, file: doc, controller: this.controller, parent: this}} />
    // }
    renderView() {
        console.log("BUG CHECK",this)
if(this?.parent?.props?.params?.type){
    this.state.similar = this.parent?.state?.[this?.parent?.props?.params?.type]?.similar?.data?.contacts
}else{
    this.state.similar =  this.parent?.state?.similar_payor?.data?.contacts
}
        return (
            <Col>
            {this?.state?.similar?.map((similar) => 
                <><Row className="contactCard  add_shadow ">
                    <Col className="cardPad">
                        <Row className="centerButton">
                            {similar.name}
                        </Row>
                        <Row className="centerButton">
                           {similar.flat_address}
                        </Row>
                        {/* <Row className="centerButton">
                           {similar.address.address_2}
                        </Row> */}
                    </Col>
                    <Col className="centerButton">
                        <Button onClick={() => this.modal_controller.change_contact( this?.parent?.props?.params?.type ? similar.id : similar)} className={"primary_button_coloring " + this._theme}>
                            Use this Contact
                        </Button>
                    </Col>
                </Row><Row></Row></>
                )}
                <Row className="cardPad newContact" >
                    <center>
                        {/* this.state.parent.toggle_modal({ toggle_state: true }) */}
                        <div onClick={() => this.modal_controller.reset_contact(true)} >
                        Create A New One
                        </div>
                    </center>
                </Row>
                <Row>
                    {/* {console.log("CHECKING THIS",this)} */}
                    <center>
                        <div onClick={() => this.parent.reset_modal()} >
                        Cancel
                        </div>
                    </center>
                </Row>

            </Col>
        )
    }
}
