// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import test_data from "./test.json"
import schema from "./schema.json"

// ALL OTHER MODULES
import _baseApiObject from "components/General/_baseApiObject";
import delete_request from "assets/graphql/Rate_items/rate_item.delete.graphql.json"


export default class Rate_item extends _baseApiObject {
    constructor(props) {
        super(props)
        this.schema     = schema
        this.test_data  = test_data
        this.requests   = {
            delete: delete_request,
        }
    }
    async delete({caller, value, callback, params}) {
        let body    = JSON.parse(JSON.stringify(delete_request))
        body.query  = body.query.replace("input:{id:", 'input:{id:"'+value+'"')
        params      = (params === undefined) ? {} : params
        params.body = body
        let results = await this.ask({caller: caller, callback: callback, params: params, nocache: true})
        return results
    }
}