
import React from "react";

import { Container, Row, Col, ToggleButton, ToggleButtonGroup, Card, Button } from 'react-bootstrap'

import { BiMapAlt } from "react-icons/bi";
import _basePanelView from "components/General/_basePanelView.jsx"
import "./map.css"
import NewLoadPanel from "../Loads/LoadsPanel/NewLoad/NewLoadPanel";
import TripsPanel from "../Trips/TripsPanel/TripsPanel.view.jsx";
import GrafanaFrame from "components/GrafanaFrame/GrafanaFrame.view.jsx";
import grafana_config from "./grafana_test.json"


export default class Map extends _basePanelView {
    constructor(props) {
        super(props)
        this.state.panel_name = "maps"
        this.state.channel_name = "grafana_row"
        this.state.panel_configs = grafana_config["pages"]["maps"]
        this.state.clicked_trip = false
        this.state.clicked_load = false
        this.state.clicked_truck = false
        this.state.clicked_trailer = false
        this.state.show = false
        this.state.panel_trees = [{
            key: 0,
            name: "new_load",
            base: 1,
        },
        {
            key: 0,
            name: "new_trip",
            base: 0,
        }]
        this.state.panels = [{ key: 0, obj: NewLoadPanel, params: { panel_params: { is_closeable: true, close_title: "Back Home", close_func: "resetScreen" } } },
        { key: 1, obj: TripsPanel, params: { panel_params: { is_closeable: true, close_title: "Back Home", close_func: "resetScreen" } } },
        ]
        this.handleProcessEvent = this.handleProcessEvent.bind(this)
    };
    renderMain() {
        return (
            <Row id={this.name}>
                                       <Row className="end">
                        <Row className="fit">
                            <Col className="info">
                                <p>David Brown</p>
                            </Col>
                            <Col className="spacing">
                                <Container className="square rounded-circle avatar_background" >
                                    <p className="initals">DB</p>
                                </Container>
                            </Col>
                        </Row>
                    </Row>
                <Row className="btn_row">
                    <Row className="width">
                        <Col onClick={() => this.setState({ show: true })}>
                            <Row className="">
                                <div>
                                    <BiMapAlt size={30} />
                                </div>
                            </Row>
                        </Col>
                        <Row className="small">
                            <Row className="reduce">
                                <Button className={`${this.state.clicked_trip === true ? " clicked" : " trip"}`}
                                    onClick={() => this.setState({ clicked_trip: !this.state.clicked_trip })} >
                                    Trip
                                </Button>
                            </Row>
                            <Row className="reduce">
                                <Button className={`${this.state.clicked_load === true ? "clicked" : "load"}`}
                                    onClick={() => this.setState({ clicked_load: !this.state.clicked_load })} >
                                    Load
                                </Button>
                            </Row>
                            <Row className="reduce">
                                <Button className={`${this.state.clicked_truck === true ? "clicked" : "truck"}`}
                                    onClick={() => this.setState({ clicked_truck: !this.state.clicked_truck })}>
                                    Truck
                                </Button>
                            </Row>
                            <Row className="reduce">
                                <Button className={`${this.state.clicked_trailer === true ? " clicked" : "trailer"}`}
                                    onClick={() => this.setState({ clicked_trailer: !this.state.clicked_trailer, })}>
                                    Trailer
                                </Button>
                            </Row>
                        </Row>
                    </Row>

                    <Row className="end reduce">
                        <Row className="reduce padding">
                            <Button onClick={this.handleScreen.bind(this, { path_num: 0 })}>
                                New Trip
                            </Button>
                        </Row>
                        <Row className="reduce">
                            <Button onClick={this.handleScreen.bind(this, { path_num: 1 })}>
                                New Load
                            </Button>
                        </Row>
                    </Row>
                </Row>
                <Row>
                    <Row style={{height:"750px", width:"1500px"}}>
                        <GrafanaFrame params={{parent: this, panel_configs: this.state.panel_configs, panel_name: this.state.panel_name}}
                            />
                        </Row>
                </Row>
            </Row>
        )
    }

}

