// GENERAL REACT MODULES
import { Row, Col, Button} from "react-bootstrap";

import _viewController from "controllers/ViewController/_viewController.jsx"
// import AssetModelController from "controllers/ModelControllers/AssetModelController.jsx"
// import asset_request from "assets/graphql/Assets/Assets.graphql.json"
import update_request from "assets/graphql/Assets/Assets.update.graphql.json"
import { BsTruck } from "react-icons/bs";
import {FaTrailer} from "react-icons/fa"

export default class ViewAssetDetailsController extends _viewController {
    constructor(props) {
        super(props)
        this.requests   = [{callback: {f: this.process_trailer_types, p: {name: "trailer_types", var: "trailer_types"}},
                                                                endpoint: "trailers"},
                            {callback: {f: this.state_results, p: {name: "areas", var: ["areas", "states"]}}, 
                                endpoint: "areas", replace: {o: "params:", r:'params: {country: "US"}'}}]
    }
    state_results({caller, params, results}) {
        if (results?.errors === undefined && results?.data?.areas !== undefined) {
            results.data.areas.map((area, index) => {
                caller.view.state.states.push({ value: area.state, label: area.state })
            })
        }
    }
    process_trailer_types({caller, params, results}) {
        if(results.data?.trailer_types === undefined) { return }
        if(results.data?.trailer_types.length <= 0) { return }
        results.data.trailer_types.map((asset, index) => {
            asset.label = asset.name
            asset.value = asset.name
            caller.view.state.trailer_types.push(asset)
        })
    }
    editable_field({data,label,options}) {
        if (this.view.state.edit_mode) {
            return (
                <Col>
                    <Row className="g-0">
                        <Col>
                            {label}
                        </Col>
                    </Row>
                    <Row className="g-0">
                        <Col>
                            {this.view?.formObject({data: data, owner: this, options: options})}
                        </Col>
                    </Row>
                </Col>
            )
        }
        return (
            <Col>
                <Row>
                    <Col>
                        {this.controller.get_object_param({key: data?.title, param: data?.name})}
                    </Col>
                </Row>
            </Col>
        )
    }
    render_icon() {
        if (this.controller?.asset?.truck_number !== undefined && this.controller?.asset?.truck_number !== null) {
           return (
            <Col className='icon_col'> 
                <BsTruck size={50} className="icon" />
            </Col> 
           )
        } else {
           return (
            <Col className='icon_col'>
                <FaTrailer size={40} className="icon"/>
            </Col>
            )
        }
    }
    render_asset_number() {
        if (this.controller?.asset?.trailer_number !== undefined && this.controller?.asset?.trailer_number !== null) {
            return (
                <>
                    <Row className="pad_top">
                        <Col className="trailer no_wrap">
                            {this.editable_field({data: this.view?.state?.form?.trailer_number, owner: this})}
                        </Col>
                    </Row>
                </>
            )
        } else {
            return (
                <>
                    <Row className="pad_top">
                        <Col className="trailer no_wrap">
                            {this.editable_field({data: this.view?.state?.form?.truck_number, owner: this})}
                        </Col>
                    </Row>
                </>
            )
        }
    }
    render_trailer_dropdown() {
        if (this.controller?.asset?.trailer_number !== undefined && this.controller?.asset?.trailer_number) {
            return (
                <Row className="pad_top">
                    <Col className="info">
                        {this.editable_field({data: this.view?.state?.form?.trailer_type, 
                                                                options: this.view?.state?.trailer_types, 
                                                                owner: this})}
                    </Col>
                </Row>
            )          
        }
    }
    support_air_ride () {
        if (this.controller?.asset?.trailer_number !== undefined && this.controller?.asset?.trailer_number !== null) {
            return (
                <Row style={{paddingTop:"1rem"}}>
                    <Col sm={1} className="no_wrap">
                        {this.editable_field({data: this.view?.state?.form?.support_air_ride, owner: this})} 
                    </Col>
                    <Col>
                    </Col>
                </Row>
            )
        } else {
            return <></>
        }
    }
    edit() {
        this.setState({key: "edit_mode", value: true})
    }
    edit_button() {
        if (this.controller?.asset?.truck_number !== undefined && this.controller?.asset?.truck_number !== null) {
        return (
            <Row>
                <Col onClick={this.edit.bind(this)} tabIndex={this.view.tab("cancel")}>
                    Edit Truck
                </Col>
            </Row>
            )
        } else {
            return (
                <Row>
                    <Col onClick={this.edit.bind(this)} tabIndex={this.view.tab("cancel")}>
                        Edit Trailer
                    </Col>
                </Row>
                )
        }
    }
    button_row() {
        if (this.view.state.edit_mode)  {
            return ""
        }
        return this.edit_button()
    }
    render_truck_color() {
        if (this.controller?.asset?.truck_number !== undefined && this.controller?.asset?.truck_number !== null) {
            return (
                <>
                    <Row className='pad_top_8'>
                        <Col className='info'>
                            Color
                        </Col>
                    </Row>
                    <Row>
                        <Col className="type">
                        {this.editable_field({data: this.view?.state?.form?.color, owner: this})}
                        </Col>
                    </Row>
                </>
            )
        } else {
            return <></>
        }
    }
    render_length() {
        if ((this.controller?.asset?.trailer_number !== undefined && this.controller?.asset?.trailer_number !== null)
            || (this.controller?.asset?.is_boxtruck)) {
            return (
                <>
                    <Row className='pad_top_8'>
                        <Col className='info'>
                            Length, FT
                        </Col>
                    </Row>
                    <Row>
                        <Col className="type">
                            {this.editable_field({data: this.view?.state?.form?.length, 
                                                    options: this.form_configs?.length_options,
                                                    owner: this})}
                        </Col>
                    </Row>
                </>
            )
        } else {
            return <></>
        }
    }
    render_weight_capacity() {
        if (this.controller?.asset?.trailer_number !== undefined && this.controller?.asset?.trailer_number !== null) {
            return (
                <>
                <Row className='pad_top_8'>
                    <Col className='info'>
                        Weight Capacity
                    </Col>
                </Row>
                <Row>
                    <Col className="type">
                        {this.editable_field({data: this.view?.state?.form?.weight_capacity, owner: this})}
                    </Col>
                </Row>
            </>
            )
        } else {
            return <></>
        }
    }
    async saveAction(page_key) {
        let body = undefined
        body = JSON.parse(JSON.stringify(update_request))
        let summary = this.controller?.asset?.summary
        let data = this.toUnquotedJSON(summary)
        body.query = body.query.replace("input:", 'input: '+data)
        let asset_id = await this.api.assets.create({caller: this, params: {body: body}, callback:{f: this.process_save, p: {page_key: page_key}}})       
    }
    async process_save({caller, params, results}) {
        // let id = (results?.data?.setAsset?.id !== undefined ? results?.data?.setAsset?.id : results?.data?.updateAsset?.id)
        // if (id !== undefined && id !== null) {
        //     if(caller?.controller?.uploader !== undefined) {
        //         await caller.controller.uploaded?.process_queue(id)
        //     }
        //     caller.panel_controller.selectPanel({panel_name: params?.page_key, cache_data: {id: id, type: caller.controller?.asset_type}})
        // }
    }
    save_button_row() {
        if (this.view.state.edit_mode) {
            return (
                <>
                <Row>
                    <Col>
                        <hr/>
                    </Col>
                </Row>
                <Col>&nbsp;</Col>
                    <Col sm={3} className="button_col">
                        <Button className={"completeButton primary_button_coloring "+this._theme}
                            onClick={this.close.bind(this, {save: false})} 
                            variant="secondary" 
                            size="sm" 
                            tabIndex={this.view.tab("cancel")}>
                                Cancel
                        </Button>
                    </Col>
                    <Col sm={3} className="button_col">
                        <Button className={"completeButton primary_button_coloring "+this._theme}
                            // onClick={this.saveAction.bind(this, {save: true})}
                            onClick={() => {
                                this.saveAction({ save: true });
                                this.close({ save: false });
                              }}
                              
                            variant="primary" 
                            size="sm">
                                Save
                        </Button>
                    </Col>
                    <Col xs="auto"></Col>
                    <Row>
                        &nbsp;
                    </Row>
                </>
            )
        }
        return ""
    }
    close({save: save}) {
        this.setState({key: "edit_mode", value: false})
        if (save) {
            this.save()
        } else {
            this.cancel()
        }
    }
    cancel() {
        this.resolveCache()
    }
    save() {
        this.setState({key: "edit_mode", value: false})
        this.state.context.user = this.controller.getCopy(this.state.user)
        this.controller?.resolveCache()
    }
    resolveCache() {
        if (this.view.state?.cache !== undefined) {
            this.view.state.context.user    = this.getCopy(this.view?.state?.cache)
            this.setState({key: "data", param: "user", value: this.getCopy(this.view?.state?.cache)})
            this.view.forceUpdate()
        }
    }
}