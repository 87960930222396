// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class HomeController extends _viewController {
    constructor(props) {
        super(props)
    }
    open_helper() {
        this.setState({open: !this.state.open})
    }
}
