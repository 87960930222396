//GENERAL REACT MODULES
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
// RETAIN Import for checkbox test
import { BsArrowsMove, BsFillLightningFill, BsCheck2 } from "react-icons/bs";

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx";
import Controller from "../Commodity/Commodity_Partial.controller.jsx"
import { MultiSelectDropdown } from "components/Dropdowns/Dropdowns";
import { TrailerOptions } from "../../../../TestOptions/TrailerOptions.js"
import { EquipmentOptions } from "../../../../TestOptions/EquipmentOptions.js";
import "./CommodityDetails.css"

export default class CommodityDetailsPartial extends _basePartial {
    constructor(props){
        super(props)
        this.partial_controller         = new Controller ({params: {view: this}})
        this.state.hazmatColor          = "#F0F1F7"
        this.state.oversizedColor       = "#F0F1F7"
        this.state.palletsColor         = "#F0F1F7"
        this.state.iconColor            = "black"
    }
    renderView() {
        console.log('commodityDetails', this)
        return (
            <Row id={this.name}>
                <Col>
                        <Row className="padding no_wrap">
                            <Col>
                                <Row>
                                    <Col className="generic_form_label">
                                        weight, lb
                                    </Col>
                                </Row>
                                <Row className="pad_top_half">
                                    <Col>
                                        {this.formObject({ data: this.state.form.commodity.weight })}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col className="generic_form_label">
                                        value
                                    </Col>
                                </Row>
                                <Row className="pad_top_half">
                                    <Col>
                                        {this.formObject({ data: this.state.form.commodity.value })} {/* DNE */}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="padding no_wrap generic_form_label">
                            <Col>
                                <Row>
                                    <Col>
                                        length, ft
                                    </Col>
                                </Row>
                                <Row className="pad_top_half">
                                    <Col>
                                        {this.formObject({ data: this.state.form.commodity.length_in_feet})}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        width, ft
                                    </Col>
                                </Row>
                                <Row className="pad_top_half">
                                    <Col>
                                        {this.formObject({ data: this.state.form.commodity.width_in_feet })}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        height, ft
                                    </Col>
                                </Row>
                                <Row className="pad_top_half">
                                    <Col>
                                        {this.formObject({ data: this.state.form.commodity.height_in_feet })}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="padding no_wrap generic_form_label">
                            <Col>
                                <Row>
                                    <Col>
                                        trailer type needed
                                    </Col>
                                </Row>
                                <Row className="pad_top_half">
                                    <Col>
                                        {this.formObject({ data: this.state.form.equipment.trailer_types, options: this.controller.trailer_types, controller: this.controller })}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        special equipment
                                    </Col>
                                </Row>
                                <Row className="pad_top_half">
                                    <Col>
                                        {this.formObject({ data: this.state.form.equipment.requirements, options: this.controller.requirements, controller: this.controller })}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="padding no_wrap" >
                            <Col className="no_wrap pad_top_1">
                                {this.formObject({data: this.state.form.commodity.hazmat, controller: this.controller})}
                                {/* <Col xs="auto"onClick={() => this.partial_controller.handleHazmat()} className="icons" style={{ backgroundColor: this.state.hazmatColor }}>
                                    <BsFillLightningFill className = "icon_size" />
                                </Col>
                                <Col className="labels" xs="auto">
                                    HazMat
                                </Col> */}
                            </Col>
                            <Col className="no_wrap pad_top_1">
                                {this.formObject({data: this.state.form.commodity.oversized, controller: this.controller})}
                                {/* <Col xs="auto"onClick={() => this.partial_controller.handleOversized()} className="icons" style={{ backgroundColor: this.state.oversizedColor }}>
                                    <BsArrowsMove className = "icon_size"/>
                                </Col>
                                <Col className="labels">
                                    Oversized
                                </Col> */}
                            </Col>
                            <Col>
                                <Row>
                                    <Col className="generic_form_label">
                                        container #
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.formObject({ data: this.state.form.commodity.container_number, controller: this.controller })}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="padding generic_form_label no_wrap">
                            <Col sm={3}>
                                <Row>
                                    <Col>
                                        TEMPERATURE RANGE, F
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.formObject({ data: this.state.form.commodity.temperature_min, controller: this.controller })}
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="width_3">
                                <Row>
                                <Col className="pad_top_1_6">
                                    -
                                </Col>
                                </Row>
                            </Col>
                            <Col sm={3} className="pad_1_1">
                                <Row>
                                    <Col>
                                        {this.formObject({ data: this.state.form.commodity.temperature_max, controller: this.controller })}
                                    </Col>
                                </Row>
                            </Col>
                            {/* <Col>
                                <Row>
                                    <Col>
                                        piece count
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.formObject({ data: this.state.form.commodity.piece_count })}
                                    </Col>
                                </Row>
                            </Col> */}
                            <Col>
                                <Row>
                                    <Col>
                                        # of cases
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.formObject({ data: this.state.form.commodity.cases, controller: this.controller })}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="padding">
                            <Col className="generic_form_label">
                                <Row>
                                    <Col>
                                        # of pallets
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.formObject({ data: this.state.form.commodity.pallets, controller: this.controller })} 
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={1}></Col>
                            <Col>
                                {this.formObject({data: this.state.form.commodity.pallets_exchange, controller: this.controller})}
                                {/* <Row className="pad_top_1_6">
                                    <Col xs="auto" onClick={() => this.partial_controller.handlePallets()}>
                                        <BsCheck2 className="icons" style={{ backgroundColor: this.state.palletsColor, fill: this.state.iconColor }} onMouseDown={this.handleMouseDown} />
                                    </Col>
                                    <Col  className="labels">
                                        Pallets Exchange   
                                    </Col>
                                </Row> */}
                            {/* DNE */}
                            </Col>
                        </Row>
                        <Row>
                            &nbsp;
                        </Row>                
                </Col>
            </Row>)
    }
}