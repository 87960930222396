//REACT SPECIFC 
import React from "react";
import {Row, Col, Button, Container, Card} from "react-bootstrap";

//MODULE SPECIFIC
import Controller from "./Save_Cancel.controller.jsx"
import _basePartial from "components/General/_basePartial.jsx";
import "./Save_Cancel.css"

export default class SaveCancel extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller ({params: {view: this}})
        this.state.more_info    = this.parent.state.more_info
    }
    renderView() {
        this.state.more_info    = this.parent.state.more_info
        return (
            <>
                <Row id={this.name} className="pt-4">
                    <Col>
                        <Row>
                            <Col>
                                <Row  className="hr_row g-0">
                                    <hr />
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button className={this._theme} onClick={() => this.controller.handleMoreInfo()}>
                                    {(this.state.more_info) ? "Close Info" : "Edit More"}
                                </Button>
                            </Col>
                            <Col xs="auto">
                                <Row>
                                    <Col>
                                        <Button onClick={() => this.controller.handleCloseScreen()} className={"clear_button " + this._theme}>
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col>
                                    {/* {console.log("SAVECANCEL CONTROLLER: ", this.controller)} */}
                                        <Button onClick={() => this.controller.save_load()} className={"primary_button_coloring " + this._theme}>
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                </>
        )
    }
}