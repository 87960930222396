// GENERAL REACT MODULES
import { Row, Col } from 'react-bootstrap';

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import GrafanaFrame from "components/GrafanaFrame/GrafanaFrame.view.jsx";
import grafana_config from "assets/grafana_config.json"
import Controller from "./Document.partial.controller.jsx"
import Modal from "./DocumentModal/Document.modal.jsx"
import config_data from "./Documents.json"
import "./Documents.partial.css"
import DocUpload from "./DocUploadPartial/DocUpload.partial.jsx";

export default class Documents extends _basePartial {
    constructor(props) {
        super(props)
        this._modal                 = Modal
        this.partial_controller     = new Controller({params: {view: this, controller: this?.controller}})
        this.state.panel_name       = "documents"
        this.state.channel_name     = "grafana_row"
        this.state.panel_configs    = grafana_config["pages"]["documents"]
        this.state.form             = config_data.form_objects
    }
    renderGrafanaPanel() {
        if(this.controller?.company_id === undefined) {return <></>}
        return (
            <GrafanaFrame params={{ parent: this.state.parent, panel_configs: this.state.panel_configs, 
                                    panel_name: this.state.panel_name,
                                    filters: {
                                        company_id: this.controller?.company_id,
                                        ext: this.state?.page?.ext,
                                        name: this.state?.page?.name
                                    }
                                }}
            />
        )
    }
    renderView() {
        // console.log(this)
        return(
            <div className="generic_100" id={this.name}>
                <Row className="generic_100 panel_object g-0 add_shadow">
                    <Col>
                        <Row className="top_row">
                            <Col />
                            <Col sm={4}>
                                {this.formObject({data: this.state.form.name, owner: this, controller: this.partial_controller})}
                            </Col>
                            <Col sm={2}>
                                <DocUpload params={{parent: this, controller: this?.controller}}/>
                            </Col>
                        </Row>
                        <Row className="grafana grafana_dims">
                            <Col>
                                {this.renderGrafanaPanel()}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}
