// GENERAL REACT MODULES
import { Container, Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import Controller from "./ViewAssetsPanel.controller.jsx"
import _basePanelView from "components/General/_basePanelView.jsx";
import MultiPanel from "components/MultiPanels/MultiPanel";
import AssetStatus from "./Partials/AssetStatus/AssetStatus.partial.view.jsx";
import NotesPanel from "../../Loads/ViewLoad/ViewLoadPanel/Partials/Notes/NotesPanel.partial.jsx";
import CotaUserBar from "components/CotaUserBar/CotaUserBar.partial.jsx"
import ChangeDriverPartial from "./Partials/ChangeDriver/ChangeDriver.partial.view.jsx";
import AssignDriverPartial from "./Partials/AssignDriver/AssignDriver/AssignDriver.partial.view.jsx";
import AssetAttached from "./Partials/AssetAttached/AssetAttached.partial.view.jsx";
import DeleteAssetButton from "./Partials/DeleteAssetButton/DeleteAssetButton.partial.jsx";
import OutOfServiceButton from "./Partials/OutOfServiceButton/OutOfServiceButton.partial.jsx";

export default class ViewAssetsPanel extends MultiPanel {
    constructor (props) {
        super(props)
        this.custom_canvas          = true
        this.controller             = new Controller({params: {view: this}})
        this.state.out_of_service   = false 
        this.state.selection        = "view_assets"
        this.state.data = {
            notes: [],
            drivers: []
        }
    }
    render_driver() {
        if(this.state.data.asset.truck_number !== undefined && this.state.data.asset.truck_number !== null) {
            let driver = this.state.data.asset?.driver
            if(driver?.first_name !== undefined && driver?.last_name !== null) {
                return (
                    <Col>
                        <ChangeDriverPartial params={{parent: this, controller: this.controller}} />
                    </Col>
                )
            }
            return (
                <Col>
                    <AssignDriverPartial params={{parent: this, controller: this.controller}} />
                </Col>
            )
        }
        return <></> 
    }
    render_linked_asset() {
        if(this.state.data.asset?.linked_asset?.id !== undefined && this.state.data.asset?.linked_asset?.id !== null) {
            return (
                <Col>
                    <AssetAttached params={{parent: this, controller: this.controller}} />
                </Col>
            )
        }
        return (
            <Col>
                <AssetStatus params={{parent: this, controller: this.controller}} />
            </Col>
        )
    }
    render_delete_button() {
        if(this.controller.editable) {
            return <DeleteAssetButton params={{parent: this, controller: this.controller}}/>
        }
    }
    render_out_of_service_button(){
        if(this.controller.editable) {
            return <OutOfServiceButton params={{parent: this, controller: this.controller}}/>
        }
    }
    renderInnerFrame() {

        // **** LEAVE THIS NEXT LINE ALONE, things break without it for some reason (race condition? async issues?) *** 
        let temp_stat = this.controller.asset.status
        // *** 

        return (
            <Container className= "generic_100 g-0" id={this.name}>
                <Row className="max_height">
                    <Col className="flex_wrapper_rows">
                        <CotaUserBar params={{parent: this, controller: this.controller, title: "assets", return: true}} />
                        <Row className="generic_100 margin_auto">
                            <Col>
                                <Row>
                                    <Col>
                                        <div className="g-0 cota_panel_border add_shadow width">
                                            {
                                                this.controller?.load_page(this.state.selection)
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="auto"> </Col>
                            <Col sm={4}>
                                <Row>
                                    {this.render_driver()}
                                </Row>
                                <Row>
                                    &nbsp;
                                </Row>
                                <Row>
                                    {this.render_linked_asset()}
                                </Row>
                                <Row>
                                    &nbsp;
                                </Row>
                                <Row>
                                    <Col className="g-0">
                                        {this.render_out_of_service_button()}
                                    </Col>
                                </Row>
                                <Row>
                                    &nbsp;
                                </Row>
                                <Row>
                                    <Col className="g-0">
                                        {this.render_delete_button()}
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col>
                                        <NotesPanel params={{parent: this, controller: this.controller}} />
                                    </Col>
                                </Row> */}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}