// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Container } from 'react-bootstrap'

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import _basePanelView from "components/General/_basePanelView.jsx"
// import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./RoleButtonRow.controller.jsx"
import "./RoleButtonRow.css"

export default class RoleButtonRow extends _basePartial{
    constructor(props) {
        super(props)
        // this.contact            = this.controller?.contact
        // this.roles              = this.controller?.contact?.roles
        // this.state.payor        = (this.roles?.payor)     ? "payor" : ""
        // this.state.shipper      = (this.roles?.shipper)   ? "shipper" : ""
        // this.state.consignee    = (this.roles?.consignee) ? "consignee" : ""
    }
    get contact() {
        return this.controller?.contact
    }
    get payor() {
        return (this.contact?.payor) ? "payor" : ""
    }
    get shipper() {
        return (this.contact?.shipper) ? "shipper" : ""
    }
    get consignee() {
        return (this.contact?.consignee) ? "consignee" : ""
    }
    renderView() {
	    // console.log(this)
        return (
            <Container id={this.name} className="m-0 p-0">
                <Row className="pad_top_8 pb-1">
                    <Col className="info">
                        ROLE
                    </Col>
                </Row>
                <Row className="g-2">
                    <Col className={"btn mx-2 "+this.payor} 
                        type="button" 
                        onClick={this.controller.handleRoleClick.bind(this)}>
                            payor</Col>
                    <Col className={"btn mx-2 "+this.shipper} 
                        type="button" 
                        onClick={this.controller.handleRoleClick.bind(this)}>
                            shipper</Col>
                    <Col className={"btn mx-2 "+this.consignee} 
                        type="button" 
                        onClick={this.controller.handleRoleClick.bind(this)}>
                            consignee</Col>
                </Row>
            </Container>
        )
    }
}
