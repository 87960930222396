// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import CommodityModelController from "controllers/ModelControllers/Shipping/CommodityModelController.jsx"
import InfoModelController from "controllers/ModelControllers/Shipping/InformationModelController.jsx"
import ShipmentModelController from "controllers/ModelControllers/Shipping/ShipmentModelController.jsx"
import EquipmentModelController from "controllers/ModelControllers/Shipping/EquipmentModelController.jsx"

export default class DriverModalController extends _viewController {
    constructor(props) {
        super(props)
        this.commodity          = new CommodityModelController ({params: {parent: this,
                                                                          controller: this,
                                                                          is_view: true}})
        this.information        = new InfoModelController({params: {parent: this,
                                                                    controller: this,
                                                                    is_view: true}})
        this.shipment           = new ShipmentModelController({params: {parent: this,
                                                                        controller: this,
                                                                        is_view: true}})
        this.equipment          = new EquipmentModelController({params: {parent: this,
                                                                        controller: this,
                                                                        is_view: true}})
    }
    get previous_commodity() {
        return this.controller?.commodities?.[0]
    }
    get load() {
        return this.controller.view.state.data.shipment
    }
    get equip() {
        return this.controller.view.state.data.equipment
    }
    convertInchesToRoundedFeet(valueInInches){
        let valueInFeet = (valueInInches/12)
        // always round UP to nearest 10s place decimal 10.123 => 10.2
        let roundedValue = Math.ceil(valueInFeet * 10) / 10;
        return roundedValue.toString()
    }
    handleCloseScreen() {
        this.resetScreen()
    } 
    get_commodity_data() {
        if(JSON.stringify(this.view.state.data.commodity) === '{}' && typeof this.controller?.commodities?.[0] === "object") {
            let commodity = JSON.parse(JSON.stringify(this.controller?.commodities?.[0]))
            commodity.length_in_feet = this.convertInchesToRoundedFeet(commodity?.length)
            commodity.width_in_feet = this.convertInchesToRoundedFeet(commodity?.width)
            commodity.height_in_feet = this.convertInchesToRoundedFeet(commodity?.height)
            this.view.state.data.commodity = commodity
        }
        // Needs to set the state again for reform to work.
        let id = this.controller?.commodities?.[0]?.id
        if((id !== null && id !== undefined)) {
            if((this.view.state.data.commodity?.id === null || this.view.state.data.commodity?.id === undefined)) {
                let commodity = JSON.parse(JSON.stringify(this.controller?.commodities?.[0]))
                commodity.length_in_feet = this.convertInchesToRoundedFeet(commodity?.length)
                commodity.width_in_feet = this.convertInchesToRoundedFeet(commodity?.width)
                commodity.height_in_feet = this.convertInchesToRoundedFeet(commodity?.height)
                this.view.state.data.commodity = commodity
            }
        }
    }
    retrieve_data() {
        this.get_commodity_data()
        if(this.controller.info?.container_number !== undefined) {
            let info = JSON.stringify(this.view.state.data.information)
            if(info === '{}' || info === undefined) {
                this.view.state.data.information = JSON.parse(JSON.stringify(this.controller.info))
            }
        }
        if(this.view.state.data.shipment?.id === undefined) {
            this.view.state.data.shipment = JSON.parse(JSON.stringify(this.load))
            this.view.state.data.equipment = JSON.parse(JSON.stringify(this.equip))
        }
    }
    save_action() {
      let equipment_data = JSON.parse(JSON.stringify(this.equipment.summary))
      let shipment_data = JSON.parse(JSON.stringify(this.shipment.data))
      shipment_data = {...this.load, ...shipment_data}
      let commodity_data = JSON.parse(JSON.stringify(this.commodity.summary))
      let info_data = JSON.parse(JSON.stringify(this.information.summary))
      this.controller.setState({key: "data", param: "shipment", value: shipment_data})
      this.controller.setState({key: "data", param: "equipment", value: equipment_data})
      this.controller.setState({key: "data", param: "commodity", value: [commodity_data]})
      this.controller.setState({key: "data", param: "information", value: info_data})
      // reset data just incase it decides to be sticky
      delete this.view.state.data.equipment
      delete this.view.state.data.commodity
      delete this.view.state.data.information
      delete this.view.state.data.shipment
      this.controller.update()
      this.close()
    }
    cancel() {
      delete this.view.state.data.equipment
      delete this.view.state.data.shipment
      delete this.view.state.data.commodity
      delete this.view.state.data.information
      this.close()
    }
    close() {
      this.view.state.parent.toggle_modal({toggle_state: false})
    }
}