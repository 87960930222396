// GENERAL REACT MODULES
import _basePartial from "components/General/_basePartial.jsx"
import { Row, Col } from 'react-bootstrap'

// ALL OTHER MODULES
import "./FilterLine.css"
import Controller from "./FilterLine.controller.jsx"

export default class FilterLine extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this, controller: this.controller}})
        this.state.line_filter  = (this.state.line_filter === undefined) ? false : this.state.line_filter
    }
    get items() {
        return this.controller.getState({key: "line_items"})
    }
    get item_data() {
        // return this.props?.params?.item
        return this.items?.[this.state?.index]
    }
    get line_item() {
        return this.partial_controller.line_item
    }
    get line_height() {
        return 40
    }
    get _configs() {
        return this.controller.getState({key: "configs"})
    }
    render() {
        return (
            <Row id={this.name} style={{height: this.line_height, "lineHeight": this.line_height+"px"}} className={"btm_border g-0"}>
                <Col>
                    <Row className="g-0">
                        {this.partial_controller?.map_columns()}
                        {this.partial_controller?.add_button()}
                        {this.partial_controller?.add_actions()}
                        {this.partial_controller?.self_filter()}
                    </Row>
                </Col>
            </Row>
        )
    }
}
