import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";

import _baseModal from "components/General/_baseModal.jsx";
import ModalController from "./NewContactLoad.modal.controller"
import form_configs from "./NewContactLoad.json"
import "./NewContactLoad.modal.css"
import { countriesOptions } from "assets/countryCodes.js"

export default class NewContactModal extends _baseModal {
    constructor(props) {
        super(props)
        this.state.modal_params = {
            positioned: "middle_of_panel",
            title: "New Contact",
            title_size: "",
            closeable: true,
            has_overlay: true,
            close_on_outside_click: true,
            relative_position: "",
            relative_alignment: "",
            x_adjustment: "0px",
            y_adjustment: "0px",
        }
        this.modal_controller = new ModalController({ params: { view: this, controler: this.controller } })
        this.state.dims = { width: 1300, height: 1800 }
        this.state.form = form_configs.form_objects
        this.state.shipper = (this.role === "shipper") ? this.role : ""
        this.state.consignee = (this.role === "consignee") ? this.role : ""
        this.state.payor = (this.role === "payor") ? this.role : ""
        this.state.reset = false
        this.modal_controller.toggle_role(this.role)
    }
    get parent() {
        return this.state.parent
    }
    get role() {
        if(this.parent.state.role){
        return this.parent.state.role.toLowerCase()
        }else{return "payor"}
    }
    get type() {
        return (this.role === "shipper") ? "pickup" : this.role
    }
    get param() {
        return this.type + "." + "contact"
    }
    renderView() {
        return (
            <>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <Row className="pad_top">
                                            <Col className="generic_form_label">
                                                contact company name
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.formObject({ data: this.state.form?.company, owner: this, controller: this.modal_controller })}
                                            </Col>
                                        </Row>
                                        <Row  className="pad_top">
                                            <Col className="generic_form_label">
                                                Address
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.formObject({ data: this.state.form?.address, owner: this, controller: this.modal_controller })}
                                            </Col>
                                        </Row>
                                        <Row  className="pad_top">
                                            <Col className="generic_form_label">
                                                city *
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.formObject({ data: this.state.form?.city, owner: this, controller: this.modal_controller })}
                                            </Col>
                                        </Row>
                                        <Row  className="pad_top">
                                            <Col>
                                                <Row>
                                                    <Col className="generic_form_label">
                                                        state/province*
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="relative">
                                                        <div className="absolute">
                                                            {this.formObject({ data: this.state.form?.state, options: this.controller.states, owner: this, controller: this.modal_controller })}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row >
                                                    <Col className="generic_form_label">
                                                        zip code *
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {this.formObject({ data: this.state.form?.zip, owner: this, controller: this.modal_controller })}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row  className="pad_top">
                                            <Col className="generic_form_label">
                                                country
                                            </Col>
                                        </Row>
                                        <Row className="input_height">
                                            <Col className="relative">
                                                <div className="absolute">
                                                    {this.formObject({ data: this.state.form?.country, options: countriesOptions, owner: this, controller: this.modal_controller })}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <Row  className="pad_top">
                                            <Col className="generic_form_label">
                                                role
                                            </Col>
                                        </Row>
                                        <Row className="badge_group">
                                            <Col className="badge_col">
                                                    <div className={`role ${this.state.shipper}`} onClick={this.modal_controller.handleRoleClick.bind(this)}>
                                                        SHIPPER
                                                    </div>
                                            </Col>
                                            <Col className="badge_col">
                                                    <div className={`role ${this.state.consignee}`} onClick={this.modal_controller.handleRoleClick.bind(this)}>
                                                        CONSIGNEE
                                                    </div>
                                            </Col>
                                            <Col className="badge_col">
                                                    <div className={`role ${this.state.payor}`} onClick={this.modal_controller.handleRoleClick.bind(this)}>
                                                        PAYOR
                                                    </div>
                                            </Col>
                                        </Row>
                                        <Row  className="pad_top">
                                            <Col className="generic_form_label">
                                                Email Address
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.formObject({ data: this.state.form?.email, owner: this })}
                                            </Col>
                                        </Row>
                                        <Row  className="pad_top">
                                            <Col className="generic_form_label">
                                                Phone
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.formObject({ data: this.state.form?.phone, owner: this })}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <hr />
                            </Col>
                        </Row>
                        <Row className="no_wrap">
                            <Col>
                                <Button className={"clear_button " + this._theme} onClick={() => this.modal_controller.cancel_action()}>
                                    Cancel
                                </Button>
                            </Col>
                            <Col></Col>
                            <Col></Col>
                            <Col>
                                <Button onClick={() => this.modal_controller.save_action()} className={"primary_button_coloring " + this._theme} >
                                    Create
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            &nbsp;
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}
