// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import test_data from "./test.json"
import schema from "./schema.json"

// ALL OTHER MODULES
import _baseApiObject from "../../../../components/General/_baseApiObject";

export default class Feedback_info extends _baseApiObject {
    constructor(props) {
        super(props)
        // console.log("ENDPOINT RAN")
        this.schema     = schema
        this.test_data  = test_data
    }
}
