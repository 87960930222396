// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import UserModelController from "controllers/ModelControllers/UserModelController.jsx"

export default class UserPanelController extends _viewController {
    constructor(props) {
        super(props)
        this.user = new UserModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true}})
    }
}