// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import test_data from "./test.json"
import schema from "./schema.json"
import graph_request from "./Contacts.search.graphql.json"
import id_request from "assets/graphql/Contacts/Contacts.search.graphql.json"
import delete_request from "assets/graphql/Contacts/Contacts.delete.graphql.json"
import contact_list from "assets/graphql/Contacts/contacts.chart.graphql.json"
import fcontact_chart from "assets/graphql/Contacts/fcontacts.chart.graphql.json"
import fcontacts_request from "assets/graphql/Contacts/fcontacts.panel.graphql.json"
import fcontacts_min_request from "assets/graphql/Contacts/fcontacts.min.graphql.json"

// ALL OTHER MODULES
import _baseApiObject from "components/General/_baseApiObject";

export default class Contacts extends _baseApiObject {
    constructor(props) {
        super(props)
        this.schema     = schema
        this.test_data  = test_data
        this.requests   = {
            list: contact_list,
            fchart: fcontact_chart,
            flist: fcontacts_request,
            min_list: fcontacts_min_request
        }
    }
    async search({caller, value, callback, params}) {
        console.log("---- Contacts.jsx Search ----",caller,value)
        //params.data.name
        let results = value
        if (value?.length >= 2) {
            let body = JSON.parse(JSON.stringify(graph_request))
            let roles = params?.data?.roles
            let request_str = 'name: "'+value+'%"'+((roles !== undefined) ? ", roles: ["+roles.map((role, index) => {return `"${role}"`})+"]" : "")
            body.query = body.query.replace("contacts", 'contacts('+request_str+')')
            params = (params === undefined) ? {} : params
            params.body = body
            // if(value?.includes("FACTS") || value?.includes("Erie")) {
            //     console.log("body::", params, callback)
            // }
            results = await this.ask({caller: caller, callback: callback, params: params, nocache: true})
        }
        // if (callback !== undefined) {
        //     callback({caller: caller, params: params, results: results})
        // }
        return results
    }
    async delete({caller, value, callback, params}) {
        let results = value
        let body = JSON.parse(JSON.stringify(delete_request))
        body.query = body.query.replace("deleteContact", 'deleteContact(id: "'+value+'")')
        params = (params === undefined) ? {} : params
        params.body = body
        results = await this.ask({caller: caller, callback: callback, params: params, nocache: true})
        return results
    }
    async gid({caller, value, callback, params}) {
        let results = value
        if (value?.length >= 2) {
            let body = JSON.parse(JSON.stringify(id_request))
            body.query = body.query.replace("contacts", 'contact(id: "'+value+'")')
            params = (params === undefined) ? {} : params
            params.body = body
            results = await this.ask({caller: caller, callback: callback, params: params, nocache: true})
        }
        return results
    }
}
