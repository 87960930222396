//GENERAL REACT MODULES
import React from "react"
import { Row, Col, Button } from "react-bootstrap"

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./StatusDropdown.partial.controller.jsx"
import Modal from "./StatusDropdownModal/StatusDropdown.modal.jsx"
import "./StatusDropdown.partial.css"

export default class StatusDropdownPartial extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = this.state.partial_controller
        // this.partial_controller = new Controller({params: { view: this, controller: this.controller }})
        this._modal = Modal
        this.state.status = this.controller?.load?.status
        this.state.current_status = this.controller?.load?.status
    }
    renderView() {
        // console.log(this)
        return (
            <Row id={this.name}>
                <Col className="d-flex justify-content-evenly">
                    <Button className="btn_dropdown dropdown-toggle" onClick={() => this.toggle_modal({toggle_state: true})}>
                        {this.state.current_status}
                    </Button>
                </Col>
            </Row>
        )
    }
}