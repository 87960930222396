// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx";
import load_request from "assets/graphql/Loads/Loads.graphql.json"
// import LoadModelController from "controllers/ModelControllers/LoadModelController.jsx"
import LoadModelController from "controllers/ModelControllers/Shipping/ShipmentModelController.jsx"
import request_info from "assets/graphql/Request_info/request_info.graphql.json"

export default class LoadboardController extends _viewController {
    constructor(props) {
        super(props)
        this.load       = new LoadModelController ({params: { parent: this,
                                                    controller: this,
                                                    is_view: true}})
        this.requests   = [{callback: {f: this.state_results, p: {name: "areas", var: "states"}}, 
                                endpoint: "areas", replace: {o: "params:", r:'params: {country: "US"}'}},
                            {callback: {p: {name: "special_requirements", var: "special_requirements"}},
                                endpoint: "requirements", req_name: "special"},
                            {callback: {p: {name: "trailer_types", var: "trailer_types"}}, 
                                endpoint: "trailers"}]
    }
    get states() {
        return this.view.state.states
    }
    get equipment() {

    }
    generate_query_params(add_params) {
        if (add_params !== undefined) {
            let qparams = "id,pickup,rate,co2,asset_type,length,total_weight, rate_mile, dropoff, distance, total_amount "+add_params.toString()
            return qparams
        }
        return "id,pickup,rate,co2,asset_type,length,total_weight, rate_mile, dropoff, distance, total_amount"
    }
    state_results({caller, params, results}) {
        if (results?.errors === undefined && results?.data?.areas !== undefined) {
            results.data?.areas?.map((area, index) => {
                caller.view.state.states.push({ value: area.state, label: area.state })
            })
        }
    }
    processEventReaction(decoded) {
        this.view.state.selected = decoded.message.data
        this.setState({key: "cri", value: undefined})
        this.view.toggle_modal({toggle_state: true})
    }
    async get_load({id}) {
        let body = JSON.parse(JSON.stringify(load_request))
        body.query = body.query.replace("load", 'load(id: "'+id+'")')
        let results = await this.api.post({id: id, body: body})
        this.setState({key: "details", value: results.data.load})
    }

//  get_trailer_type(all_trailer_types, trailer_types){
//     console.log("HERE",all_trailer_types)
// // for (let i = 0; i <= all_trailer_types.length, i++;){
// //     console.log("CHECK",all_trailer_types[i].id)
// //     console.log(trailer_types)
// //     if (all_trailer_types[i].id === trailer_types){
// //         return all_trailer_types[i].name
// //     }
// let result = all_trailer_types.filter((trailer) => trailer.id === trailer_types)

// if(result[0] !== undefined){
//     console.log("RESULT",result)
// return result
// }
// }


// filter_trailer_type(all_trailer_types, trailer_types){
//     // console.log("CHECKING TRAILER TYPES",trailer_types)
// trailer_types?.map((trailer_type,index) => {
//     return this.get_trailer_type(all_trailer_types,trailer_type)
// })
// }

filter_trailers(arr1, arr2) {
    let result = [];

    arr1?.forEach(id => {
        let matchingItem = arr2?.find(item => item.id === id);
        if (matchingItem) {
            result.push(matchingItem.name);
        }
    });

    return result;
}

    filter_array(array1, array2) {
        let filtered = []
        if (array1 !== undefined && array2 !== undefined) {

                filtered = array1.filter(el => {
            
                    return array2.indexOf(el.value) !== -1;
                });
        }
     
        return filtered;
    };
    // processEventReaction(decoded) {
    //     console.log("event reaction")
    //     this.setState({key: "data", value: decoded.message.data})
    //     this.view.toggle_modal({toggle_state: true})
    //     this.get_load_info({id: decoded.message.data.id})
    // }
    // async get_load_info() {
    //     let id = this._data.id
    //     let body = JSON.parse(JSON.stringify(load_request))
    //     body.query = body.query.replace("load", 'load(id: "'+id+'")')
    //     this.api.loads.ask({caller: this, params: {body:body}, callback: {f: this.process_load}})
    // }
    // process_load({caller, params, results}) {
    //     let quoteload = results?.data?.load
    //     if (quoteload !== undefined) {
    //         caller.load?._sync({data: quoteload, remapper:"quote"})
    //         if (quoteload?.pickup !== undefined) {
    //             caller.get_stop({id: quoteload?.pickup?.id, name: "pickup"})
    //         }
    //         if (quoteload?.dropoff !== undefined) {
    //             caller.get_stop({id: quoteload?.dropoff?.id, name: "consignee"})
    //         }
    //         // caller.view.state.data.shipment = caller.getCopy(results.data?.load)
    //         caller.view.forceUpdate()
    //     }
    // }
    // get_stop({id, name, index}) {
    //     this.api?.stops?.gid({caller: this, value: id, callback: {f: this.process_loaded_stop, p: {name: name, index: index}}})
    // }
    // process_loaded_stop({caller, params, results}) {
    //     if (params.index === undefined) {
    //         caller?.[params?.name]?._sync({data: results?.data?.stop})
    //     } else {
    //         let stops = caller.view.state.data[params?.name]
    //         stops[params?.index] = results?.data?.stop
    //         caller.setState({key: "data", param: params?.name, value: stops})
    //     }
    // }
    handleCloseScreen() {
        this.resetScreen()
    }
    get _data() {
        return this.getState({key: "data"});
    }
    get _details() {
        return this.getState({key: "details"});
    }
    get _stops() {
        return this._details?.stops
    }

    resolveCache() {
        if (this.view.state?.cache !== undefined) {
            this.view.state.context.user    = this.getCopy(this.view?.state?.cache)
            this.setState({key: "data", param: "user", value: this.getCopy(this.view?.state?.cache)})
            this.view.forceUpdate()
        }
    }
    get_subject() {    
        return (
            `${this.view.state.data.pickup}  to ${this.view.state.data.dropoff}`
        )
    }
    request_info() {
        // console.log(this)
        let body = JSON.parse(JSON.stringify(request_info))
        let _summary = {
            cota_id: this.parent?.controller?.load?.cota_id,
            load_id: this.parent?.controller?.load?._id
        }
        let data = this.controller.toUnquotedJSON(_summary)
        body.query = body.query.replace("input:", 'input: '+data+'')
        this.api.request_info.ask({caller: this.controller, params: {body: body}, callback: {f: this.controller.process_request}})
    }
    process_request({caller, params, results}) {
        if (results?.data?.request_info !== undefined && results?.data?.request_info !== null && typeof results?.data?.request_info === "string" && results?.data?.request_info.length > 0) {
            caller.setState({key: "cri", value: results?.data?.request_info})
        }
    }
}
    // processEventReaction (decoded) {
    //     this.setState({key: "data", value: decoded?.message?.data})
    //     this.get_load({id: decoded?.message?.data?.id})
    //     window.location.href = (`mailto:freight@cotasystem.com?subject=${this.get_subject()}`)
    // }
    //  async get_load({id}) {
    //     let body = JSON.parse(JSON.stringify(load_request))
    //     body.query = body.query.replace("load", 'load(id: "'+id+'")')
    //     let results = await this.api.post({id: id, body: body})
    //     this.setState({key: "details", value: results.data.load})
    // }