// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import PaymentDetailsModelController from "controllers/ModelControllers/Loads/PaymentDetailsModelController.jsx"

export default class EditPaymentModalController extends _viewController {
    constructor(props) {
        super(props)
        this.payment_details = new PaymentDetailsModelController({params: {parent: this,
                                                                    controller: this,
                                                                    is_view: true}})
    }
    get line_items() {
        return this.controller.view?.state?.line_items
    }
    get info() {
        let _info = {
            bol_num: this.controller.view.state.data?.information?.bol_number,
            po_num: this.controller.view.state.data?.information?.po_number
        }
        return _info
    }
    retrieve_data() {
        if(this.info.bol_num !== undefined || this.info.po_num !== undefined) {
            if(this.view.state.data?.po_num === undefined && this.view.state.data?.bol_num === undefined) {
                let data = JSON.parse(JSON.stringify(this.info))
                this.view.state.data = data
            }
        }
        this.view.state.data.line_items = this.line_items
    }
    add_item() {
        this.controller.view.state.line_items.push({rate_type: ""})
        this.controller.view.forceUpdate()
        this.forceUpdate()
    }
    async save_action() {
        let line_items = this.controller.getState({key: "data", param: "line_items"}) 
        if(line_items?.length <= 1 && this.controller.view.state.data.shipment?.total_amount === undefined) { 
            alert("Please add a rate item."); return 
        }
        if(line_items?.length > 1) {
            line_items = { rate_items: line_items }
        }
        let summary = JSON.parse(JSON.stringify(this.modal_controller.payment_details.summary))
        delete summary.items
        let shipment = this.controller.getState({key: "data", param: "shipment"})
        shipment = {...shipment, ...line_items}
        shipment = {...shipment, ...summary}
        // console.log("shipment summary*", shipment)
        this.controller.setState({key: "data", param: "information", value: summary})
        this.controller.setState({key: "data", param: "shipment", value: shipment})
        this.controller.update("rate_items")
        this.modal_controller.close()
    }
    close() {
        this.view.state.parent.toggle_modal({toggle_state: false})
    }
}