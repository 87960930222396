import {Row, Col, Card, Button } from "react-bootstrap";

import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./DeliveryStopCard.controller.jsx"
import delivery_data from "./DeliveryStop.json"
import {FaUser} from "react-icons/fa";
import "./DeliveryStopCard.css"

export default class DeliveryStopCardPanel extends _basePartial {
    constructor(props) {
        super(props)
        this.controller = new Controller({params: {view: this}})
        this.state.delivery_data  = delivery_data
    }

    render() {
        return (
            <Row id={this.name}>
                <Card>
                    <Row className="end g-0">
                        <Row className="shipper">
                            Shipper
                        </Row>
                    </Row>
                    <Row className="wrap">
                        <Row style={{paddingLeft:"40px"}}>
                            <Col>
                                <Row className="number">
                                    {this.state?.details.id}
                                </Row>
                                <Row>
                                    &nbsp;
                                </Row>
                                <Row>
                                    <Col>
                                        <Row className="address">
                                            Address
                                        </Row>
                                    </Col>
                                <Row>
                                    <Col>
                                        <Row className="location">
                                            {this.state?.details?.address}
                                        </Row>
                                    </Col>
                                </Row>
                                </Row>
                            </Col>
                            <Col >
                                <Row>
                                    &nbsp;
                                </Row>
                                <Row>
                                    &nbsp;
                                </Row>
                                <Row >
                                    <Col>
                                        <Row className="address">
                                            Contact Details
                                        </Row>
                                    </Col>
                                    <Row>
                                        <Col>
                                            <Row className="location">
                                                {this.state?.details?.contact_phone}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Row className="location">
                                                {this.state?.details?.contact_email}
                                            </Row>
                                        </Col>
                                    </Row>
                                </Row>   
                            </Col>
                            <Col>
                                <Row>
                                    <Col className="end">
                                        <div className="user">
                                            <FaUser size={30}/>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                    <Col>
                        &nbsp;
                    </Col>
                </Row>
                <Row>
                    <Col>
                        &nbsp;
                    </Col>
                </Row>
                <Row>
                    <Col>
                    </Col>
                    <Col sm={2}>
                        <Button  onClick={() => this.state.parent.setState({show: false})} sm={2} className="clear_button" style={{minWidth:"180px", whiteSpace:"nowrap", cursor:"pointer"}}>
                            Delete
                        </Button>
                    </Col>
                    <Row>
                        &nbsp;
                    </Row>
                </Row>
                            </Col>
                        </Row>
                    </Row>
                </Card>
            </Row>
        )
    }
}