// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import company_drivers from "assets/graphql/Companies/Company.drivers.graphql.json"
import employees_request from "assets/graphql/Employees/Employees.graphql.json"
import trip_mutation from "assets/graphql/Trips/trips.mutation.graphql.json"
import test_data from "./Trips_Test_data.json"

// MODEL CONTROLLERS
import TripModelController from "controllers/ModelControllers/Trips/TripModelController.jsx"

export default class TripsPanelController extends _viewController {
    constructor(props) {
        super(props)
        this.trip = new TripModelController({params: {parent: this,
                                                      controller: this,
                                                      is_view: true}})
        // NEED to refactor this.colors
        this.colors = {
            background1: "button_active", background2: "button_default",
            background3: "button_default", background4: "button_default",
            textColor1: "text_active", textColor2: "text_default",
            textColor3: "text_default", textColor4: "text_default",
        }
        // Will NEED to filter by a status as well. update: status for drivers, and assets
        this.requests = [{callback: {f: this.process_loads}, endpoint: "loads",
                            replace: {o:"load", r:'loads(company: "'+this.controller.context?.self?.companies[0]?.id+'", statuses: ["AVAILABLE"])'}},
                            // replace: {o:"load", r:'loads(carrier: "'+this.controller.context?.self?.companies[0]?.carrier_id+'", statuses: ["AVAILABLE"])'}},
                        {request: employees_request, callback: {f: this.process_company_drivers}, endpoint: "companies", 
                            replace: {o:"employees()", r:'employees(company_id: "'+this.controller.context?.self?.companies[0]?.id+'", role: "DRIVER", statuses: ["AVAILABLE"])'}},
                        {callback: {f: this.process_assets}, endpoint: "assets", 
                            replace: {o:"assets", r:'assets(company_id: "'+this.controller.context?.self?.companies[0]?.id+'", statuses: ["AVAILABLE"])'}},
                        {callback: {p: {name: "trailer_types", var: "trailer_types"}}, endpoint: "trailers"}]
    }
    option_requests({company_id}) {
        this.request_data({req:{callback: {f: this.process_loads}, endpoint: "loads", replace: {o:"load", r:'loads(company: "'+company_id+'", statuses: ["AVAILABLE"])'}}})
        // this.request_data({req:{request: company_drivers, callback: {f: this.process_company_drivers}, endpoint: "companies", replace: {o:"company", r:'company(id: "'+company_id+'")'}}})
        this.request_data({req:{request: employees_request, callback: {f: this.process_company_drivers}, endpoint: "companies", replace: {o:"employees()", r:'employees(company_id: "'+company_id+'", role: "DRIVER", statuses: ["AVAILABLE"])'}}})
        this.request_data({req:{callback: {f: this.process_assets}, endpoint: "assets", replace: {o:"assets", r:'assets(company_id: "'+company_id+'", statuses: ["AVAILABLE"]))'}}})
    }
    get internal_user() {
        return this.check_role("INTERNAL_COTA_USER")
    }
    get company_id() {
        return this.controller.context?.self?.companies[0]?.id
    }
    filter_array(array) {
        return this.view.state[array].filter(el => (this.trip[array].length > 0) ? this.trip[array].some(({id}) => el.id !== id) : el)
    }
    filter_loads(array) {
        if(this.trip[array].length > 0) {
            return this.view.state[array].filter(el => !this.trip.loads.includes(el))
        }
        return this.view.state[array]
    }
    get loads() {
        return this.filter_loads("loads")
    }
    get drivers() {
        // NEED TO JUST REQUEST AVAILABLE DRIVERS
        return this.filter_array("drivers")
    }
    get trucks() {
        return this.filter_array("trucks")
    }
    get trailers() {
        return this.filter_array("trailers")
    }
    get trailer_types() {
        return this.view.state.trailer_types
    }
    load_cache() {
        if(this.view.state?.data?.prepopulation_delete) {
            delete this.view.state.data.populate_data
            delete this.view.state.trip_id
            delete this.view.state.data.prepopulation_delete
        }
        let key = this.view.state.screen
        let data = (this.panel_controller?.getCache(key))?.data
        if(data === undefined) { 
            data = (this.panel_controller?.getCache(3))?.data
        } 
        if(data !== undefined) {
            delete this.view.state.data.populate_data
            this.setState({key: "data", param: "populate_data", value: data})
        }
        this.prepopulate_selections()
    }
    follow_on_search({results}) {
        if(results?.data?.loads?.length === 0 || this.view.state.search?.loads?.search_value === "") {
            // this.view.state.loads = []
            this.request_data({req: this.requests?.[0]})
        } else if (results?.data?.loads?.length > 0) {
            this.view.setState({loads: results?.data?.loads})
        }
        if(results?.data?.assets?.length === 0 || this.view.state.search?.assets?.search_value === "") {
            this.view.state.trucks = []
            this.view.state.trailers = []
            this.request_data({req: this.requests?.[2]})
        } else if (results?.data?.assets?.length > 0) {
            this.view.setState({[this.view.state.tab_selection]: results?.data?.assets})
        }
        if(results?.data?.employees?.length === 0 || this.view.state.search?.employees?.search_value === "") {
            this.view.state.drivers = []
            this.request_data({req: this.requests?.[1]})
        } else if (results?.data?.employees?.length > 0) {
            this.view.setState({drivers: results?.data?.employees})
        }
    }
    notnull(value) {
        if(value !== null && value !== undefined) {
            return true
        }
        return false
    }
    dispatch_owner_operator(summary) {
        if((this.check_admin() || this.check_role("OWNER")) && this.check_role("DRIVER")) {
            if(!this.notnull(this.view.state?.trip_id) && !this.notnull(summary?.driver_id)) {
                return true
            }
        }
        return false
    }
    dispatch_trip() {
        // Load ids and truck id must be defined before this method can be called.
        // ADD DISPATHED AS TRIP STATUS
        let summary = JSON.parse(JSON.stringify(this.trip.summary))
        summary.status = "DEADHEADING"
        if(this.view?.state?.resolved && this.view?.state?.trip_id !== undefined) {
            summary.id = this.view.state.trip_id
            if(!this.notnull(summary?.driver_id)) { summary.driver_id = null }
            if(!this.notnull(summary?.trailer_id)) { summary.trailer_id = null }
            if(this.notnull(this.view.state?.data?.status) && this.view?.state?.data?.status !== "Available") {
                summary.status = this.view?.state?.data?.status?.toUpperCase()?.replaceAll(" ", "_")
            }
            delete this.view.state.data.trip_id
            delete this.view.state.data.populate_data
        }
        if(this.dispatch_owner_operator(summary)) {
            summary.driver_id = this.context?.self?.id
        }
        let data = this.toUnquotedJSON(summary)
        let body = JSON.parse(JSON.stringify(trip_mutation))
        body.query  = body.query.replace("input", 'input: '+data)
        let sb_config = {success: {show: false}}
        this.api.trips.ask({caller: this, params: {body: body}, callback: {f: this.process_dispatch_trip, p: {sb: sb_config}}})
    }
    process_dispatch_trip({caller, params, results}) {
        if(results?.errors !== undefined) {
            return
        }
        let id = results?.data?.setTrip?.id
        caller.view.panel_controller.selectPanel({panel_name: "view_trip", cache_data: {id: id}, data: id})
    }
    assign_linked_asset({item}) {
        switch (item?.asset_type) {
            case "TRUCK":
                this.view.state.trucks.push(item)
                break;
            case "TRAILER":
                this.view.state.trailers.push(item)
                break;
            default:
                this.view.state.drivers.push(item)
                break;
        }
        this.handle_linked_assets({item: item})
    }
    handle_linked_assets({item}) {
        switch (item) {
            case item?.asset?.id !== null && item?.asset?.id !== undefined:
                this.assign_linked_asset({item: item.asset})
                break;
            case item?.linked_asset?.id !== null && item?.linked_asset?.id !== undefined:
                this.assign_linked_asset({item: item.linked_asset})
                break;
            case item?.driver?.id !== null && item?.driver?.id !== undefined:
                this.assign_linked_asset({item: item.driver})
                break;
        }
    }
    process_loads({caller, params, results}) {
        if(results?.errors !== undefined) {
            console.log("process_loads error:", results.errors)
        }
        results?.data?.loads.map(el => {
            caller.view.state.loads.push(el)
        })
        // caller.view.state.loads = results?.data?.loads
        caller.view.state.loaded_loads = true
        caller.view.forceUpdate()
    }
    process_company_drivers({caller, params, results}) {
        if(results?.errors !== undefined) {
            console.log("process_company_drivers error:", results.errors)
        }
        // let drivers = results?.data?.company?.drivers
        let drivers = results?.data?.employees

        // add check for asset and if exists send asset data to respective array?
        // also check if asset has linked_asset
        if(drivers !== undefined) {
            // drivers?.map((driver, index) => { caller.assign_linked_asset({item: driver}) })
            caller.view.state.drivers = drivers
            caller.view.forceUpdate()
        }
    }
    process_assets({caller, params, results}) {
        if(results?.errors !== undefined) {
            console.log("process_assets error:", results.errors)
        }
        // console.log("---- Assets caller ----", caller)
        caller.view.state.trucks = []
        caller.view.state.trailers = []
        results?.data?.assets?.map((asset, index) => {
            // caller.assign_linked_asset({item: asset})
            if(asset?.asset_type === "TRUCK") {
                caller.view.state.trucks.push(asset)
            }
            if(asset?.asset_type === "TRAILER") {
                caller.view.state.trailers.push(asset)
            }  
        })
        caller.view.forceUpdate()
    }
    data_key() {
        switch (this.view.state.tab_selection) {
            case "loads":
                return "shipment"
            case "drivers":
                return "person"
            case "trucks":
                return "truck"
            case "trailers": 
                return "trailer"       
            default:
                return "shipment"
        }
    }
    prepopulate_selections() {
        if(this.view.state?.resolved) { return }
        let data = this.view?.state?.data?.populate_data
        if(data?.id === undefined) { return }
        let index = this.view.state?.loads?.findIndex(load => load?.id === data?.id)
        if(data?.cota_id?.includes("CT")) {
            this.prepopulate_existing_trip()
        } else {
            this.prepopulate_load(index)
        }
    }
    prepopulate_load(index) {
        if(!this.view.state?.loaded_loads) { return }
        let item = this.view.state?.loads?.[index]
        if(!this.notnull(item)) { item = this.view.state.data?.populate_data }
        // Incase load is not found at index, we need to delete the trip key otherwise we get the error: TypeError: Converting circular structure to JSON
        item = JSON.parse(JSON.stringify(item))
        delete item.trip
        this.prepopulate_data({key: "loads", data: item})
    }
    prepopulate_existing_trip() {
        let trip_keys = ["loads", "truck", "trailer", "driver"]
        let data = this.view?.state?.data?.populate_data
        if(data?.id === undefined) { return }
        this.view.state.trip_id = data?.id
        let check = Object.keys(data).toString().replaceAll(",", "")
        trip_keys.map(el => {
            if(check?.includes(el)) { 
                if(data[el] !== undefined && data[el] !== null) {
                    this.resolve_trip_keys({data: data[el], key: el})
                }
            }
            return el
        })
    }
    resolve_trip_keys({key, data}) {
        if(this.is_obj(data)) {
            if(Array.isArray(data)) {
                data?.map((el) => {
                    return this.prepopulate_data({key: key, data: el})
                })
            } else {
                this.prepopulate_data({key: key, data: data})
            }
        }
        this.view.state.resolved = true
        this.view.forceUpdate()
    }
    prepopulate_data({key, data}) {
        if(key.slice(-1) !== "s") { key = key + "s" }
        if(key === "loads") {
            let load = this.view.state?.[key]?.[this.view.state?.[key].findIndex(el => el?.id === data?.id)]
            if(this.notnull(load)) { data = load }
        } 
        if(key === "drivers") {
            if(!JSON.stringify(this.view.state.data.trip).includes(data?.id)) { 
                data = {id: data?.id, first_name: data?.given_name, last_name: data?.family_name}
                this.view.state.linked.drivers = true
            }
        }
        if(data?.id !== null && data?.id !== undefined && !this.view.state.data.trip?.["sel_"+key]?.some((el) => el?.id === data?.id)) {
            if(!JSON.stringify(this.view.state.data.trip).includes(data?.id)) {
                // This push to the array is the most important function. It hides the prepopulated data from the options to select from.
                this.view.state.data.trip?.["sel_"+key]?.push(data)
                this.view.state.linked[key] = true
            }
        }
    }
    is_obj(obj) {
        if (typeof obj === 'object' || obj instanceof Object) {
            return true
        }
        return false
    }
    check_linked(data, type) {
        let obj = JSON.parse(JSON.stringify(data))
        if(obj?.driver?.id !== undefined && obj?.driver?.id !== null) {
            if(this.view.state.linked.drivers) {
                return false
            }
            let driver = obj?.driver
            this.check_linked(driver)
        }
        if(obj?.linked_asset?.id !== undefined && obj?.linked_asset?.id !== null) {
            let linked_asset = obj?.linked_asset
            let asset_type = linked_asset?.asset_type?.toLowerCase() +"s"
            if(this.view.state.linked[asset_type]) {
                return false
            }
            this.check_linked(linked_asset)
        }
        if(obj?.asset?.id !== undefined && obj?.asset?.id !== null) {
            let asset = obj?.asset
            let asset_type = asset?.asset_type?.toLowerCase() +"s"
            if(this.view.state.linked[asset_type]) {
                return false
            }
            this.check_linked(asset)
        }
        return true
    }
    assign_linked(data, type) {
        let obj = JSON.parse(JSON.stringify(data))
        if(type !== undefined) {
            this.view.state.linked[type] = true
        }
        if(obj?.driver?.id !== undefined && obj?.driver?.id !== null) {
            let driver = obj?.driver
            this.view.state.linked.drivers = true
            this.assign_linked(driver)
        }
        if(obj?.linked_asset?.id !== undefined && obj?.linked_asset?.id !== null) {
            let linked_asset = obj?.linked_asset
            let asset_type = linked_asset?.asset_type?.toLowerCase() +"s"
            this.view.state.linked[asset_type] = true
            this.assign_linked(linked_asset)
        }
        if(obj?.asset?.id !== undefined && obj?.asset?.id !== null) {
            let asset = obj?.asset
            let asset_type = asset?.asset_type?.toLowerCase() +"s"
            this.view.state.linked[asset_type] = true
            this.assign_linked(asset)
        }
    }
    // May need to used this function to retain the index number of a draggable / trip option
    onDragEnd(result) {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        // console.log("on drag end source", source, "destination", destination)
        // console.log(result)
        // could separate each "if type ..." into individual functions.
        // if type load
        if (destination.droppableId === "assigned_loads" && source.droppableId === "loads") {
            let index = this.state.loads?.findIndex(load => load?.id === result.draggableId)
            if(index === undefined) { return }
            let data = this.state.loads?.[index]
            // console.log("load index", index, "data", data)
            // if load id exists
            if (data?.id !== undefined) {
                // push load to selected loads.
                this.state.data.trip.sel_loads.push(data)
            }
            this.forceUpdate()
            return
        }
        if (destination.droppableId === "assigned_details" && source.droppableId !== "loads") {
            // console.log(result.draggableId)
            let type    = source.droppableId
            let index   = this.state?.[type]?.findIndex(item => item?.id === result.draggableId)
            // console.log(type)
            // console.log(this.state)
            if(index === undefined) { return }
            let data = this.state?.[type]?.[index]
            if(data?.id !== undefined && data?.id !== null) {
                if (this.state?.data?.trip?.["sel_"+type]?.length === 0 && !this.state.linked[type]) {
                    if(this.controller.check_linked(data, type)) {
                        this.state.data.trip["sel_"+type].push(data)
                        this.controller.assign_linked(data, type)
                    }
                }
            }
            this.forceUpdate()
            return
        }
    }
    handleColorChange(id) {
        if (id === 1) {
            this.colors.background1 = "button_active"
            this.colors.textColor1 = "text_active"
            this.colors.background2 = "button_default"
            this.colors.textColor2 = "text_default"
            this.colors.background3 = "button_default"
            this.colors.textColor3 = "text_default"
            this.colors.background4 = "button_default"
            this.colors.textColor4 = "text_default"

        } else if (id === 2) {
            this.colors.background2 = "button_active"
            this.colors.textColor2 = "text_active"
            this.colors.background1 = "button_default"
            this.colors.textColor1 = "text_default"
            this.colors.background3 = "button_default"
            this.colors.textColor3 = "text_default"
            this.colors.background4 = "button_default"
            this.colors.textColor4 = "text_default"
        } else if (id === 3) {
            this.colors.background3 = "button_active"
            this.colors.textColor3 = "text_active"
            this.colors.background2 = "button_default"
            this.colors.textColor2 = "text_default"
            this.colors.background1 = "button_default"
            this.colors.textColor1 = "text_default"
            this.colors.background4 = "button_default"
            this.colors.textColor4 = "text_default"
        }
        else if (id === 4) {
            this.colors.background4 = "button_active"
            this.colors.textColor4 = "text_active"
            this.colors.background2 = "button_default"
            this.colors.textColor2 = "text_default"
            this.colors.background3 = "button_default"
            this.colors.textColor3 = "text_default"
            this.colors.background1 = "button_default"
            this.colors.textColor1 = "text_default"
        }
    }
}
