// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import CompanyModelController from "controllers/ModelControllers/CompanyModelController.jsx"
import ContactModelController from "controllers/ModelControllers/ContactModelController.jsx"

export default class EditPayorModalController extends _viewController {
    constructor(props) {
        super(props)
        this.company   = new CompanyModelController({params: {parent: this,
                                                        controller: this,
                                                        is_view: true}})
        this.contact   = new ContactModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true}})
    }
    handleCloseScreen() {
        this.resetScreen()
    } 
    editContact() {
        // console.log("NAVIGATING",this.controller.getState({key: "data"})?.[this.state?.type],this)
        this.panel_controller?.selectPanel({ panel_num: 1, cache_data: { type: this.state?.type, data: this.controller.getState({ key: "data" })?.[this.state?.type] } })
    }
    save_action() {
        let data = undefined
        if(this.view.reform_mode) {
            data = JSON.parse(JSON.stringify(this.contact.summary))
            if(typeof data?.email === "object") {
                data.email = data.email.email
            }
            this.view.parent.state.new_contact = true
            this.controller.setState({key: "data", param: "contact", value: data})
        } else {
            data = JSON.parse(JSON.stringify(this.company.summary))
            if(typeof data?.email === "object") {
                data.email = data.email.email
            }
            this.controller.setState({key: "data", param: "company", value: data})
        }
        delete this.view.state.data.contact
        delete this.view.state.data.company
        // IMPORTANT!!!
        // needs to call an update function from this.controller
        // console.log(this?.controller?.load, this)
        if(this.view.reform_mode){
            this.controller.update(data)
        } else {
            this.controller.update("payor")
        }
        this.close()
    }
    cancel() {
        delete this.view.state.data.company
        this.close()
    }
    close() {
        this.view.state.parent.toggle_modal({toggle_state: false})
        // this.view.state.parent.reset_modal()
    }
}