//GENERAL REACT MODULES
import { Row, Col, Button, Container } from "react-bootstrap"

// ALL OTHER MODULES
import NoteModelController from "controllers/ModelControllers/Shipping/NoteModelController.jsx"
import _viewController from "controllers/ViewController/_viewController.jsx"
// import update_contact from "assets/graphql/Contacts/Contacts.update.graphql.json"
import delete_note from "assets/graphql/Notes/Note.delete.graphql.json"

export default class ContactNotesController extends _viewController {
    constructor(props) {
        super(props)
        this._configs = {
            local: "note",
            array: "notes"
        }
        this.note           = new NoteModelController({params: {parent: this,
                                                        controller: this,
                                                        is_view: true,
                                                        index: undefined}})
    }

    clear_object() {
        console.log("clearobject", this)
        if (this.view?.state?.data?.note !== undefined) {
            // let _note = {note_text: undefined}
            // this.note._sync({data: _note})
            this.view.state.data.note = {}
            this.view.setState(prevState => ({
                index: prevState.index +1
            }))
        }
    }
    add_object() {
        let data_copy = this?.[this?._configs?.local].data.copy()
        data_copy.viewable = true
        if (this.controller?.load?.notes === undefined) {
            this.controller.load.notes = []
        }
        this.controller?.load?.notes.push(data_copy)
        console.log(this.controller.view,data_copy,this.controller?.load?.notes)
        // this.controller?.[this.modal_controller?._configs?.array]?.push(data_copy)
        this.clear_object()
        this.controller.view?.forceUpdate()
    }
    add_note() {
        console.log(this)
        this.add_object()
        if(this.note._sum <= 0) { return }
        if(this.note.note_text.trim() === "") { return }
        let data = JSON.parse(JSON.stringify(this.note?.data))
        // this.controller?.view?.state?.data?.shipment?.notes?.push(data)
        this.controller.view.state.data.shipment.notes = [data]
        // works to reset the note model controller's values. 
        this.setState({key: "data", param: "notes", value: [{note_text:"", viewable:false}]})
        // this set state forces the textarea to clear.
        this.view.setState(prevState => ({
            index: prevState.index + 1
        }))
        this.controller.update("notes")
    }
    remove_note(index) {
        let note = this.controller.notes[index]
        if(note?.id !== undefined && note?.id !== null) { 
            let body = JSON.parse(JSON.stringify(delete_note))
            body.query = body.query.replace("id:", 'id: "'+note.id+'"')
            let sb_config = {success: {show: false}}
            this.api.notes.ask({caller: this, params: {body: body}, callback: {p: {sb: sb_config}}})
        }
        // this.view.controller._delete({name: this?._configs?.array, index: index})
        // this.view.controller.state.data.shipment.notes.splice(index, 1)
        this.controller.update("notes")
    }
}