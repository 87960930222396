// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class DocumentRowController extends _viewController {
    constructor(props) {
        super(props)
    }
    add_doc() {
        let doc = JSON.parse(JSON.stringify(this?.view?.document))
        this.controller?.view?.state?.selected_docs?.push(doc)
        this.view.setState({added: true})
    }
    remove_doc() {
        let index = this?.controller?.view?.state?.selected_docs?.findIndex(({id}) => id === this?.view?.document?.id)
        this?.controller?.view?.state?.selected_docs?.splice(index, 1)
        this.view.setState({added: false})
    }
    handle_doc() {
        if(this.view.state.added) {
            return this.remove_doc()
        }
        return this.add_doc()
    }
}