//GENERAL REACT MODULES
import React from "react";
import { Row, Col } from "react-bootstrap";

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx";
import Controller from "./BillingDetails.partial.controller"
import "./BillingDetails.css";
import InvoiceRateItem from "../InvoiveRateItemsPartial";

export default class BillingDetails extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({ params: { view: this, controller: this.controller } })
    }
    not_null(value) {
        if(value !== undefined && value !== null) {
            return true
        }
        return false
    }
    componentDidMount() {
        this._isMounted = true
    }
    render_line_items() {
        return this.controller?.line_items?.map((rate_item, index) =>
            <InvoiceRateItem params={{ parent: this, controller: this.controller, data: rate_item, index: index }} />
        )
    }
    render_total_rate() {
        if(this.not_null(this.controller.load?.payment_rate)) {
            return <div className="rate">Total: ${this.controller.load?.payment_rate}</div>
        }
        return <div className="error info ">[ Missing Rate ]</div>
    }
    render_carrier() {
        return (
            <Row>
                <Col>
                    <Row>
                        <Col>
                            {this.controller?.carrier?.name}
                        </Col>
                        <Col>
                            {this.controller?.carrier?.email}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    {this.controller?.carrier?.street_address}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.controller?.carrier?.formatted_location}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
    render_payor() {
        return (
            <Row>
                <Col>
                    <Row>
                        <Col sm={1}>
                            To:
                        </Col>
                        <Col>
                            {this.controller?.payor?.name}
                        </Col>
                        <Col>
                            {this.controller?.payor?.email}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={1}/>
                        <Col>
                            <Row>
                                <Col>
                                    {this.controller?.payor?.street_address}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.controller?.payor?.location}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
    render_stop_contact(type) {
        return (
            <Row>
                <Col>
                    <Row>
                        <Col>
                            {this.controller?.[type]?.contact?.name}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    {this.controller?.[type]?.contact?.street_address}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.controller?.[type]?.contact?.location}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    } 
    renderView() {
        return (
            <Row id={this.name}>
                <Col className="cota_panel_border add_shadow">
                    <Row className="billing_panel bottom_border">
                        <Col>
                            <Row>
                                <Col className="title">
                                    Invoice
                                </Col>
                            </Row>
                            {this.render_carrier()}
                        </Col>
                    </Row>
                    <Row className="billing_panel bottom_border">
                        <Col>
                            {this.render_payor()}
                            <Row>
                                <Col>
                                    &nbsp;
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.render_stop_contact("pickup")}
                                </Col>
                                <Col>
                                    {this.render_stop_contact("consignee")}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="billing_panel bottom_border">
                        {this.render_line_items()}
                    </Row>
                    <Row className="billing_panel">
                        <Col />
                        <Col sm={3}>
                            {this.render_total_rate()}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}
