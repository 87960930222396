// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import DriverModelController from "controllers/ModelControllers/DriverModelController.jsx"
import CompanyModelController from "controllers/ModelControllers/CompanyModelController.jsx"
import AssetModelController from "controllers/ModelControllers/AssetModelController.jsx"
import driver_request from "assets/graphql/Users/user.drivers.graphql.json"
import company_request from "assets/graphql/Companies/Company.graphql.json"

export default class ViewDriverController extends _viewController {
    constructor(props) {
        super(props)
        this.driver     = new DriverModelController ({params: {parent: this,
                                                        controller: this,
                                                        is_view: true}})
        this.company    = new CompanyModelController ({params: {parent: this,
                                                        controller: this,
                                                        is_view: true}})
        this.asset     = new AssetModelController({params: {parent: this,
                                                        controller: this,
                                                        is_view: true}})
    }
    load_cache() {
        let key = this.view.state.screen
        let data = (this.panel_controller?.getCache(key))?.data
        if (data === undefined) {
            data = (this.panel_controller?.getCache(2))?.data
        }
        if (data !== undefined) {
            this.setState({key: "panel_params", param: "data", value: data})
        }
    }
    get notes() {
        return this.view?.state?.data?.driver?.notes
    }
    get trips() {
        return this.view.state?.data?.driver?.trips
    }
    get internal_user() {
        return this.check_role("INTERNAL_COTA_USER")
    }
    get admin_user() {
        return this.check_admin()
    }
    get owner() {
        return this.check_role("OWNER")
    }
    get editable() {
        if(this.internal_user || this.admin_user || this.owner) {
            return true
        }
        return false
    }
    _delete({name, param, index}) {
        if(typeof param === "string" && param?.length > 0) {
            this.view.state.data[param][name] = this.view?.state?.data?.[param]?.[name].filter((value, i) => i !== index)
        } else {
            this.view.state.data[name] = this.view?.state?.data?.[name].filter((value, i) => i !== index)
        }
        this.view.forceUpdate()
    }
    processEventReaction(decoded) {
        this.setState({key: "data", value: decoded.message.data})
        this.view.handleScreen(this, {path_num: 0, screen_num: 0, data:decoded.message.data})
        // console.log("DATATA",data)
    }
    get _data() {
        return this.getState({key: "data"});
    }
    saveAction(page_key) {
        this.state?.parent?.selectPanel({panel_name: page_key, cache_data: {}})
    }
    handleCloseScreen() {
        // Needs a refactor
        this.view.state.panel_controller.props.params.parent.resetScreen()
        // this.view.state.panel_params.close_func()
    }
    load_harness() {
        this.load_driver()
    }
    load_driver() {
        let id = this.view.state.panel_params.data.id
        let body    = JSON.parse(JSON.stringify(driver_request))
        if (id !== undefined) {
            body.query  = body.query.replace("user", 'user_or_person(id: "'+id+'")')
            this.api.users.ask({caller: this, params:{body: body}, callback: {f: this.process_user}})
        }
    }
    load_company(id) {
        let body = JSON.parse(JSON.stringify(company_request))
        body.query = body.query.replace("company", 'company(id: "'+id+'")')
        this.api.companies.ask({caller: this, params: {body: body}, callback: {f: this.process_company, fin: true}})
    }
    process_user({caller, params, results}) {
        if(results?.data?.user_or_person !== undefined) {
            caller.view.state.data.driver = results?.data?.user_or_person
            if(results?.data?.user_or_person?.asset?.id !== null && results?.data?.user_or_person?.asset?.id !== undefined) {
                caller.view.state.data.asset = results?.data?.user_or_person?.asset
            }
            if (results?.data?.user_or_person?.companies?.[0]?.id !== undefined && results?.data?.user_or_person?.companies?.[0]?.id !== null) {
                caller.load_company(results.data.user_or_person.companies[0].id)
            } else {
                caller.view.forceUpdate()
            }
        }
    }
    process_company({caller, params, results}) {
        if(results.data?.company !== undefined) {
            caller.view.state.data.company = caller.getCopy(results.data?.company)
            caller.view.state.cache = caller.getCopy(caller.view.state.data)
            caller.view.forceUpdate()
        }
    }
    resolveCache() {
        if (this.view.state?.cache !== undefined) {
            this.view.state.context.user    = this.getCopy(this.view?.state?.cache)
            this.setState({key: "data", param: "user", value: this.getCopy(this.view?.state?.cache)})
            this.view.forceUpdate()
        }
    }
    view_asset({id}) {
        this.view.panel_controller.selectPanel({panel_name: "view_asset", cache_data: {id: id}})
    }
    async blockDriverToggle(){
        const driver_id = this.driver.id;
        await this.api.drivers.blockDriverToggle({caller: this, value: {driver_id: driver_id}, callback: {f: this.load_driver.bind(this)}})
    }
    async deleteDriver(){
        const driver_id = this.driver.id;
        await this.api.drivers.deleteDriver({caller: this, value: {driver_id: driver_id}, callback: {f: ()=> this.handleCloseScreen()}})
    }
}
