// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import CompanyModelController from "controllers/ModelControllers/CompanyModelController.jsx"
import CarrierModelController from "controllers/ModelControllers/Carrier/CarrierModelController.jsx"
import BillingDetailsModelController from "controllers/ModelControllers/BillingDetailsModelController.jsx"
import InsuranceModelController from "controllers/ModelControllers/InsuranceModelController.jsx"
import company_update from "assets/graphql/Companies/Company.update.mutation.graphql.json"
import carrier_update from "assets/graphql/Carriers/Carrier.company.update.graphql.json"
import company_request from "assets/graphql/Companies/Company.graphql.json"

export default class CompanyInfoPartialController extends _viewController {
    constructor(props) {
        super(props)
        this.company = new CompanyModelController({params: {parent: this,
                                                            is_view: true,
                                                            controller: this}})
        this.billing_details = new BillingDetailsModelController({params: {parent: this, 
                                                                    controller: this,
                                                                    is_view: true}})
        this.carrier = new CarrierModelController({params: {parent: this,
                                                            is_view: true,
                                                            controller: this}})
        this.insurance = new InsuranceModelController({params: {parent: this,
                                                                is_view: true,
                                                                controller: this}})
    }
    get internal_user() {
        return this.check_role("INTERNAL_COTA_USER")
    }
    get admin_user() {
        return this.check_admin()
    }
    get owner() {
        return this.check_role("OWNER")
    }
    get editable() {
        if(this.internal_user || this.admin_user || this.owner) {
            return true
        }
        return false
    }
    get_company_data() {
        if(JSON.stringify(this.view.state?.data?.company) === "{}") {
            let company = this?.controller?.view?.state?.data?.company
            if(company === undefined) { return }
            company = JSON.parse(JSON.stringify(company))
            if(this.controller.product === "carrierpro") {
                this.set_carrier_data(company)
            } else {
                this.set_search(company)
                this.company._sync({data: company})
            } 
        }
    }
    set_carrier_data(company) {
        let carrier = {} 
        if(!this._notnull(company)) { return }
        if(this._notnull(company?.carrier?.id)) {
            carrier = JSON.parse(JSON.stringify(company.carrier))
            carrier.company = company
        } else {
            carrier = {company: company}
        }
        delete company.carrier
        this.view.state.data.billing_details = this.process_billing_details(carrier?.billing_details)
        this.set_search(carrier)
        this.carrier._sync({data: carrier})
    }
    process_billing_details(bd) {
        let billing_details = {
            bank: (bd?.bank !== undefined) ? bd?.bank : null,
            routing_number: (bd?.routing_number !== undefined) ? bd?.routing_number : null,
            account_number: (bd?.account_number !== undefined) ? bd?.account_number : null
        }
        return billing_details
    }
    compare_obj(obj1, obj2) {
        if(obj1 instanceof Object && obj2 instanceof Object) {
            if(JSON.stringify(obj1) === JSON.stringify(obj2)) {
                return true
            }
        }
        return false
    }
    update_carrier_company(data) {
        let obj = JSON.parse(JSON.stringify(data))
        let carrier = obj?.carrier
        let company = obj?.company
        let carrier_keys = Object.keys(carrier)
        Object.keys(company).map((key, i) => {
            if(carrier_keys.includes(key)) {
                if(!this.compare_obj(company[key], carrier[key]) && key !== "id") {
                    obj.company[key] = carrier[key]
                }
            }
            return key
        })
        return obj
    }
    save() {
        if(this.controller.product === "carrierpro") {
            return this.save_carrier()
        }
        return this.save_company()
    }
    save_carrier() {
        this.view.state.save = true
        let summary = JSON.parse(JSON.stringify(this.carrier?.summary))
        let data = this.update_carrier_company(summary)
        delete summary?.image
        // console.log("save data---", data)
        data = this.toUnquotedJSON(data)
        let body = JSON.parse(JSON.stringify(carrier_update))
        body.query = body.query.replace("input:", "input: "+data)
        this.api.carriers.ask({caller: this, params: {body: body}, callback: {f: this.process_save}})
    }
    save_company() {
        this.view.state.save = true 
        let summary = JSON.parse(JSON.stringify(this.company?.summary))
        delete summary?.image
        delete summary.carrier
        let data = this.toUnquotedJSON(summary)
        let body = JSON.parse(JSON.stringify(company_update))
        body.query = body.query.replace("input:", "input: "+data)
        this.api.companies.ask({caller: this, params: {body: body}, callback: {f: this.process_save}})
    }
    process_save({results, caller, params}) {
        // console.log("-- process_save --")
        // console.log(results)
        if(results?.errors !== undefined) {
            // console.log(results.errors)
            return
        }
        if(caller.controller.product === "carrierpro") {
            let result = results?.data?.updateCarrier
            let carrier = JSON.parse(JSON.stringify(result))
            let company = JSON.parse(JSON.stringify(carrier.company))
            delete carrier.company
            company.carrier = carrier
            // console.log("new company data ---", JSON.parse(JSON.stringify(company)))
            caller.controller.view.state.data.company = JSON.parse(JSON.stringify(company))
            caller.set_carrier_data(company)
        } else {
            let company = results?.data?.updateCompany
            caller.controller.view.state.data.company = JSON.parse(JSON.stringify(company))
            caller.company._sync({data: company})
        }
    }
    reset() {
        if(this.controller.product === "carrierpro") {
            return this.reset_carrier()
        }
        return this.reset_company()
    }
    reset_company() {
        let company = JSON.parse(JSON.stringify(this.controller.view.state.data.company))
        this.set_search(company)
        this.company._sync({data: company})
    }
    reset_carrier() {
        let company = JSON.parse(JSON.stringify(this.controller.view.state.data.company))
        // console.log("RESET CARRIER")
        // console.log("COMPANY DATA", company)
        this.set_carrier_data(company)
    }
    set_search(obj) {
        this.view.state.search = {
            address: {selected_value: obj?.address},
            zip: {search_value: obj?.address?.location?.zip}
        }
    }
    follow_on_selection({event, obj, data}) {
        let target = this.company
        if(this.controller.product === "carrierpro") {
            target = this.carrier
        }
        if (data?.name === "zip") { 
            target.zip        = obj.zip
            target.city       = obj.city
            target.state      = obj.state
            target.country    = obj.country
        }
        if (data?.name === "address") {
            target.address    = obj?.address
            target.address_2  = obj?.address_2
            target.zip        = obj?.location?.zip
            target.city       = obj?.location?.city
            target.state      = obj?.location?.state
            target.country    = obj?.location?.country
            this.setState({key: "search",
                            param: "zip.selected_value",
                            value: {zip: obj?.location?.zip} })
        }
        if (obj?.id !== undefined && data?.name === "company") {
            let body    = JSON.parse(JSON.stringify(company_request))
            body.query  = body.query.replace("company", 'company(id: "'+obj?.id+'")')
            this.api.companies.ask({caller: this, params: {body: body}, nocache: true, callback: {f: this.process_company}})
        }
    }
    process_company({caller, params, results}) {
        delete caller.view.state.search
        delete caller.view.state.data.companies
        let company = results?.data?.company
        if(caller.controller.product === "carrierpro") {
            caller.controller.view.state.data.carrier = company
            caller.carrier._sync({data: company})
        } else {
            caller.company._sync({data: company})
        }
        caller.set_search(company)
        caller.controller.view.forceUpdate()
    }
}