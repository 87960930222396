// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import test_data from "./test.json"
import schema from "./schema.json"

// ALL OTHER MODULES
import _baseApiObject from "components/General/_baseApiObject"
import commodity_request from "assets/graphql/Commodities/Commodities.graphql.json"

export default class Commodities extends _baseApiObject {
    constructor(props) {
        super(props) 
        this.schema     = schema
        this.test_data  = test_data
        this.requests   = {
            general: commodity_request
        }
    }
}
