import React from "react";
import { Row, Col } from "react-bootstrap";

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./LineItem.controller.jsx"
import "./LineItem.css";
import config_data from "./LineItem.json"
import { TiDeleteOutline } from "react-icons/ti";
import { rateOptions } from "./Options.js";

export default class LineItemRow extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({ params: { view: this, controller: this.controller } })
        this.state.form = config_data.form_objects
        this.rateOptions = rateOptions
        this.state.updated = false
        this.state.disabled = false
    }
    get line_index() {
        return this.state.line_index
    }
    // deprecated
    disable_rate() {
        if(this.state.disabled || (this.partial_controller.line_item.price > 0 || this.partial_controller.line_item.number > 0)) {
            return true
        }
        return false
    }
    check_rate() {
        if(this.partial_controller.line_item?._id === "linehaul") { return }
        if((this.partial_controller.line_item.price === 0 || this.partial_controller.line_item.number === 0)) {
            this.partial_controller.line_item.rate = 0
        }
    }
    check_items() {
        let item = this.controller.view?.state?.line_items?.[this.line_index]
        if(item === undefined || this.controller?.view?.state?._is_loading) { return }
        // console.log("--- item at index", this.line_index, item)
        if(!this.state.updated) { this.state.data = JSON.parse(JSON.stringify(item)); this.state.updated = true }
        if(item?.id?.includes("linehaul")) { this.state.disabled = true }
    }
    render_delete() {
        if(this.state.disabled) { return <></> }
        return (
            <TiDeleteOutline className="delete_btn pointer flex_end" size={30} onClick={this.partial_controller.delete_item.bind(this)} />
        )
    }
    renderView() {
        // console.log(this)
        let item = this.state?.parent?.modal_controller?.line_items?.[this.line_index]
        if(item !== undefined) {
            this.state.parent.modal_controller.line_items[this.line_index] = this.partial_controller.line_item.summary
        }
        this.check_items()
        this.check_rate()
        return (
            <Row id={this.name}>
                <Col id={this.name} xs={4} >
                    <div style={{position: "absolute", width: "175px"}}>
                        {this.formObject({data: this.state.form.type, owner: this, options: rateOptions, controller: this.partial_controller, disabled: this.state.disabled})}
                    </div>
                </Col>
                <Col xs ={2}>
                    {this.formObject({data: this.state.form.number, owner: this, controller: this.partial_controller, disabled: this.state.disabled})}
                </Col>
                <Col xs ={2} >
                    {this.formObject({data: this.state.form.price, owner: this, controller: this.partial_controller, disabled: this.state.disabled})}
                </Col>
                <Col xs ={2}>
                    {this.formObject({data: this.state.form.rate, owner: this, controller: this.partial_controller, disabled: true})}
                </Col>
                <Col xs={2}>
                    {this.render_delete()}
                </Col>
            </Row>
        )
    }
}
