// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "./_ModelController";

export default class BillingDetailsModelController extends _ModelController {
    constructor(props) {
        super(props)
        this._param = "billing_details"
    }
    get bank() {
        return this.data?.bank
    }
    set bank(value) {
        console.log("SETTING BANK")
        console.log(this.controller)
        console.log(value)
        console.log(this)
        this.controller.setState({key: "data", param: "billing_details.bank", value: value})
    }
    get routing_number() {
        return this.data?.routing_number
    }
    set routing_number(value) {
        this.controller.setState({key: "data", param: "billing_details.routing_number", value: value})
    }
    get account_number() {
        return this.data?.account_number
    }
    set account_number(value) {
        this.controller.setState({key: "data", param: "billing_details.account_number", value: value})
    }
    get all() {
        return this.data
    }
    get summary() {
        let data = {}
        if (this?._id !== undefined) {
            data.id = this._id
        }
        if (this.bank !== undefined) {
            data.bank = this.bank
        }
        if (this.routing_number !== undefined) {
            data.routing_number = this.routing_number
        }
        if (this.account_number !== undefined) {
            data.account_number = this.account_number
        }
        return data
    }
}

