// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import CommodityModelController from "controllers/ModelControllers/Shipping/CommodityModelController.jsx"
import StopModelController from "controllers/ModelControllers/StopModelController.jsx"
import CompanyModelController from "controllers/ModelControllers/CompanyModelController.jsx"
import ShipmentModelController from "controllers/ModelControllers/Shipping/ShipmentModelController.jsx"
import EquipmentModelController from "controllers/ModelControllers/Shipping/EquipmentModelController.jsx"
import InfoModelController from "controllers/ModelControllers/Shipping/InformationModelController.jsx"
import edit_load from "assets/graphql/Loads/loads.mutation.graphql.json"
import distance_request from "assets/graphql/Distance/distance.graphql.json"

export default class NewLoadController extends _viewController {
    constructor(props) {
        super(props)
        this.state = {}
        this.state.stops_length         = 0
        this.state.old_stops_length     = 0    
        // console.log("STATE CONTRTOLER", this)
        this.load       = new ShipmentModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true}})
        this.commodity  = new CommodityModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true}})
        this.pickup     = new StopModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true,
                                                            _param: "pickup"}})
        this.consignee  = new StopModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true,
                                                            _param: "consignee"}})
        this.company    = new CompanyModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true}})
        this.equipment      = new EquipmentModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true}})
        this.information = new InfoModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true}})
        this.requests   = [{callback: {f: this.state_results, p: {name: "areas", var: ["areas", "states"]}}, 
                                    endpoint: "areas", replace: {o: "params:", r:'params: {country: "US"}'}},
                                {callback: {p: {name: "trailer_types", var: "trailer_types"}}, endpoint: "trailers"},
                                {callback: {p: {name: "special_requirements", var: "special_requirements"}},
                                    endpoint: "requirements", req_name: "special"}]


                                                     
    }
    state_results({caller, params, results}) {
        if (results?.errors === undefined && results?.data?.areas !== undefined) {
            results.data.areas.map((area, index) => {
                caller.view.state.states.push({ value: area.state, label: area.state })
            })
        }
    }
    get company_id() {
        return this.view.state.controller.view.state.company_id
    }
    get carrier_id() {
        return this.view.state.controller.view.state.carrier_id
    }
    get states() {
        return this.view.state.states
    }
    get trailer_types() {
        return this.view.state.trailer_types
    }
    get requirements() {
        return this.view.state.special_requirements
    }
    saveAction(page_key) {
        this.state?.parent?.selectPanel({panel_name: page_key, cache_data: {}})
    }
    handleMoreInfo() {
        this.view.setState(prevState => ({more_info: !prevState.more_info}))
    }
    handleCloseScreen() {
        // Needs a refactor
        this.view.state.panel_params.controller.view.resetScreen()
    }
    follow_on_selection({event, obj, data}) {
	    this.get_contact({id: obj?.id, name: data?.name})
    }
    get_contact({id, name}) {
        this.api?.contacts?.gid({caller: this, value: id, callback: {f: this.process_loaded_contact, p: {name: name}}})
    }
    process_loaded_contact({caller, params, results}) {
        let contact = {
            contact: results?.data?.contact,
            company: results?.data?.contact?.company,
            insurance: results?.data?.contact?.insurance
        }
        caller.setState({key: "data", param: params?.name, value: contact})
    }
    // LOAD CREATION:
   async save_load() {
    console.log("SAVE_LOAD",this)
        if(this.commodity.data.name === undefined) { alert("Please enter a Commodity Name"); return }
        // console.log("THIS SAVE LOAD",this)
        await this.get_distance()
        this.view.state.data.commodities.push(this.commodity.summary)
        let summary = JSON.parse(JSON.stringify(this.load.summary))
        // console.log("DATA SAVE LOAD",this.state)
        summary.carrier_id = this.carrier_id
        summary.company_id = this.company_id
        if(this.state.distance){
            summary.distance = this.state.distance
        }
        // console.log("save new load summary snls*", summary)
        let data = this.toUnquotedJSON(summary)
       
        let body    = JSON.parse(JSON.stringify(edit_load))
        body.query  = body.query.replace("load", 'load(input: '+data+')')
        // console.log(body)
        this.api.loads.create({caller: this, params: {body: body}, callback: {f: this.process_load}})
        // this.handleCloseScreen()
    }
    async get_distance() {
        let fetch = false
        // if we add or subtract a stop set fetch to true to recaculate the distance
        if (this.calc_stop_length() !== this.state.old_stops_length) { fetch = true }
        // if we dont have a distance fetch one
        if (this.load?.data?.distance === null || this.load?.data?.distance === undefined) { fetch = true }
// begin the calculating process
        if (fetch) {
            // store the stops in state to check against to see of we need to recalculate distance later
            this.state.old_stops_length = this.calc_stop_length()
            // we calculate the distance based of the zips
            let zips = []
            zips = this.push_zip({stop: this.pickup, zips: zips})
            // check to see if we have an array to loop over 
            if (Array.isArray(this.load.stops)) {
                // grab every zip code to give to the api to calculate the distance
                this.load.stops.forEach((stop) => {
                    zips = this.push_zip({stop: stop, zips: zips})
                    return
                })
            }
            // if we dont have an array push the new zip into the array of zips
            zips = this.push_zip({stop: this.consignee, zips: zips})
                 // make sure we have at least two stops 
            if (zips.length > 1) {
                /*  distance req looks like this
                  "query": 
                  "query{ distance(zips:)
                { total_distance, total_time, complexity, ave_complexity } }" */
                let body    = JSON.parse(JSON.stringify(distance_request))
                body.query  = body.query.replace("(zips:)", '(zips: '+JSON.stringify(zips)+')')
                await this.api.distance.ask({caller: this, params: {body: body}, callback: {f: this.process_distance}})
            }
        }
    }
    calc_stop_length() {
        let len = 0
        if (this.pickup?.contact?.id !== undefined) {
            len = len + 1
        }
        if (this.consignee?.contact?.id !== undefined) {
            len = len + 1
        }
        if (Array.isArray(this.load?.stops)) {
            len = len + this.load?.stops.length
        }
        return len
    }
    push_zip({stop, zips}) {
        if (stop.contact?.zip !== undefined && stop.contact?.zip !== null) {
            if (stop?.contact?.zip !== "") {
                zips.push(stop?.contact?.zip)
            }
        } else {
            if (stop?.contact?.company?.zip !== undefined && stop?.contact?.company?.zip !== null && stop?.contact?.company?.zip !== "") { zips.push(stop?.contact?.company?.zip) }
        }
        return zips
    } 
    process_distance({caller, params, results}) {
        caller.state.distance = results?.data?.distance?.total_distance
        // console.log("DISTANCE RESULTS", results?.data?.distance?.total_distance, caller.load.distance)
        caller.view.forceUpdate()
    }
    process_load({caller, params, results}) {
        if(results?.errors !== undefined) {
            console.log("Load creation error:", results.errors)
            return
        } 
        caller.handleCloseScreen()
        // console.log("Load created")
    }
}

