// GENERAL REACT MODULES
import { Row, Col } from 'react-bootstrap'

// OBJECT SPECIFIC MODULES
// import LineItemModelController from "controllers/ModelControllers/Quotes/LineItemModelController"
import ListLineController from "components/CotaListPanel/ListLineController.jsx"
import { BsFillPersonFill, BsTruck } from "react-icons/bs";
import { FaTrailer } from "react-icons/fa";
import "./DualColumn.css"

export default class DualColumnController extends ListLineController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    get item_data() {
        return this.view.item_data
    }
    get _configs() {
        return this.view._configs
    }
    render_icon() {
        let icons = (this._configs?.columns?.has_icons?.filter(icon => this.lc(icon) === this.lc(this.view.state.item)))?.[0]
        if (this.view.state.has_icon || icons !== undefined) {
            return (
                <Col className="left_pad image_wrapper">
                    <div className={"image_bg center_icon " + this.hover_css("image_bg")}>
                        {this.render_icon_image()}
                    </div>
                </Col>
            )
        }
    }
    render_icon_image() {
        if (this.view.state.item === "asset_number" && this?.view?.state?.parent?.state?.data?.asset_type === "TRAILER") {
            this.view.state.type = "trailer"
        }
        switch (this.view.state.type) {
            case "person":
                return (<BsFillPersonFill size={24} className="icon_color" />)
            case "truck":
                return (<BsTruck size={24} className="icon_color" />)
            case "trailer":
                return (<FaTrailer size={24} className="icon_color" />)
            default:
                return
        }
    }
    hover_css(field) {
        if (this.view?.parent?.state?.hovered) {
            return field + "_hover"
        }
        return ""
    }
    get_col_color(row_num) {
        let color = this._configs?.columns?.[this?.view?.props?.params?.item]?.c?.[row_num]
        return (color === undefined) ? "#000" : color
    }
    render_fields() {
        return (
            <Col className='center_fields'>
                <Row className="row_text">
                    <Col className="line_one g-0 " style={{  color: this.get_col_color(0)}}>
                        {this.unwrap_obj({ obj: this.item_data?.[this.view.props?.params?.data?.[0]] })}
                    </Col>
                </Row>
                <Row className="row_text">
                    <Col className={"line_two g-0 " + this.hover_css("line_two")} style={{color: this.get_col_color(1)}}>
                        {this.unwrap_obj({ obj: this.item_data?.[this.view.props?.params?.data?.[1]] })}
                    </Col>
                </Row>
            </Col>
        )
    }
    // render_fields() {
    //     return (
    //         <Col   className='center_fields'>
    //             <Row>
    //                 <Col  className="line_one" style={{  color: this.get_col_color(0)}}>
    //                     {this.unwrap_obj({ obj: this.item_data?.[this.view.state.data?.[0]] })}
    //                 </Col>
    //             </Row>
    //             <Row>
    //                 <Col className={"line_two " + this.hover_css("line_two")} style={{color: this.get_col_color(1)}}>
    //                     {this.unwrap_obj({ obj: this.item_data?.[this.view.state.data?.[1]] })}
    //                 </Col>
    //             </Row>
    //         </Col>
    //     )
    // }
}
