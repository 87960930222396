// GENERAL REACT MODULES
import React from "react";
import { Row, Col } from 'react-bootstrap'
import { FaUser, FaTrailer, FaPlusCircle } from 'react-icons/fa'
import { BsTruck, } from 'react-icons/bs'
import { AiFillDollarCircle } from "react-icons/ai";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import "./TripOption.css"
import Controller from './TripOption.controller.jsx';

export default class TripOption extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this, controller: this?.controller}})
        this.state.size = undefined // must remain undefined in order for the size of the cards to render properly
    }
    get data_key() {
        return this.state?.data_key
    }
    notnull(obj) {
        if (obj !== undefined && obj !== null) {
            return true
        }
        return false
    }
    display_icon(option) {
        option = (option === undefined) ? this.state?.option : option
        switch (option) {
            case "drivers":
                return  <FaUser className="avatar_spacing" size={30} /> 
            case "trucks":
                return <BsTruck className="avatar_spacing" size={30} /> 
            case "trailers":
                return <FaTrailer className="avatar_spacing" size={30} />     
            default:
                return <></>
        }
    }
    render_icon(option) {
        return (
            <Row>
                <span className="avatar_background relative">
                    {this.display_icon(option)}
                </span>
            </Row>
        )
    }
    get_driver_full_name(driver) {
        let fullname = ""
        if(this.notnull(driver?.first_name)) { fullname = driver?.first_name }
        if(this.notnull(driver?.last_name)) { fullname = fullname + " " + driver?.last_name }
        return fullname.trim()
    }
    render_driver(person) {
        let driver = person ?? this.state.data?.person
        let truck = driver?.asset
        if(this.notnull(driver?.id)) {
            return (<>
                <Col>
                    {this.render_icon("drivers")}
                    <Row>
                        <Col className="asset_name">
                            {this.get_driver_full_name(driver)}
                        </Col>
                    </Row>
                </Col>
                {(this.notnull(truck?.id)) && this.render_truck(driver?.asset)}
            </>)
        }
    }
    render_truck(asset) {
        let truck = asset ?? this.state.data?.truck
        let trailer = truck?.linked_asset
        let driver = truck?.driver
        if(this.notnull(truck?.id)) {
            return (<>
                {(this.notnull(driver?.id)) && this.render_driver(driver)}
                <Col>
                    {this.render_icon("trucks")}
                    <Row>
                        <Col className="asset_name">
                            {truck?.asset_number}
                        </Col>
                    </Row>
                </Col>
                {(this.notnull(trailer?.id)) && this.render_trailer(trailer)}
            </>)
        }
    }
    render_trailer(asset) {
        let trailer = asset ?? this.state.data?.trailer
        let truck = trailer?.linked_asset
        if(this.notnull(trailer?.id)) {
            return (<>
                {(this.notnull(truck?.id)) && this.render_truck(truck)}
                <Col>
                    {this.render_icon("trailers")}
                    <Row>
                        <Col className="asset_name">
                            {trailer?.asset_number}
                        </Col>
                    </Row>
                </Col>
            </>)
        }
    }
    asset_card() {
        this.partial_controller.determine_size()
        return (<>
            <Row className={'g-0 trips_object'}>
                <Col className="space_rows">
                    <Row>
                        {this.render_remove_asset_btn()}
                    </Row>
                    <Row>
                        {this.render_driver()}
                        {this.render_truck()}
                        {this.render_trailer()}
                    </Row>
                </Col>
                {/* {this.render_remove_asset_btn()} */}
            </Row>
        </>)
    }
    loads_card() {
        this.partial_controller.process_load_data()
        return (
            <Row  className={'g-0 trips_object'}>
                <Col xs={3} className='icon_col_spacing'>
                    <Row>
                        <Col>
                            <Row >
                                <Col>
                                    <AiFillDollarCircle className="green" size={50} />
                                </Col>
                            </Row>
                            <Row className='dollar'>
                                <Col>
                                    {/* {'$' + (this.partial_controller.load.linehaul ?? 0)} */}
                                    {'$' + (this.partial_controller.total_rate ?? 0)}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="miles">
                                    {this.partial_controller.load.distance} Miles
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row><Col>&nbsp;</Col></Row>
                    <Row>
                        <Col>
                            <Row>
                                {/* NEED TO GET LOAD INFO INTO THESE FIELDS */}
                                <Col className='obj_title'>
                                    {this.partial_controller.load.local_id}
                                </Col>
                                <Col className='line_items'>
                                    {this.partial_controller.commodity.name}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="dollar" >
                                    Trailer: {this.partial_controller.trailer_type}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="dollar line_items" >
                                    length
                                </Col>
                                <Col className='line_items'>
                                    {this.state?.data?.length} {/* NEEDS to be the length of the required trailer */}
                                </Col>
                                <Col className="dollar line_items" >
                                    weight
                                </Col>
                                <Col className='line_items'>
                                    {this.state?.data?.weight} {/* NEEDS to be the weight of the load */}
                                </Col>
                            </Row>
                            <Row>
                                <Col className='line_items_mid'>
                                    {this.partial_controller.pickup?.contact?.city}, {this.partial_controller.pickup?.contact?.state}
                                </Col>
                                <Col className='line_items_mid'>
                                    {this.partial_controller.consignee?.contact?.city}, {this.partial_controller.consignee?.contact?.state}
                                </Col>
                            </Row>
                            <Row>
                                <Col className='line_items'>
                                    {this.partial_controller.pickup?.contact?.name}
                                </Col>
                                <Col className='line_items'>
                                    {this.partial_controller.consignee?.contact?.name}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                {this.render_remove_load_btn()}
            </Row>
        )
    }
    render_remove_asset_btn() {
        if(this.state?.remove === undefined) { return <></> }
        return (
            // <div className="remove_asset_btn">
            //     <FaPlusCircle onClick={() => this.partial_controller.remove_assigned()} className="remove_icon" size={20}/>
            // </div>
            <Col className="remove_asset_btn">
                <FaPlusCircle onClick={() => this.partial_controller.remove_assigned()} className="remove_icon" size={20}/>
            </Col>
        )
    }
    render_remove_load_btn() {
        if(this.state?.remove === undefined) { return <></> }
        return (
            <Col sm={1} className="remove_load_btn">
                <FaPlusCircle onClick={() => this.partial_controller.remove_assigned()} className="remove_icon" size={20}/>
            </Col>
        )
    }
    render_card_type() {
        if(this.state?.option === "loads") {
            return this.loads_card()
        }
        return this.asset_card()
    }
    render() {
        // console.log(this.state.index, this.state?.data_key, this)
        return (
            <span id={this.name}>
                <Col key={this.state.option+"_"+this.state.index} 
                        className={"g-0 trips_object_wrapper "+this.partial_controller.determine_size()} 
                        ef={(_selfElement) => { this._selfElement = _selfElement }}>
                    {this.render_card_type()}
                </Col>
            </span>)
    }
}
