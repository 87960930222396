import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";

import _baseModal from "components/General/_baseModal.jsx";
import ModalController from "./EditPerson.modal.controller.jsx"
import config_data from "./EditPerson.modal.json"
import "./EditPerson.modal.css"


export default class EditPersonModal extends _baseModal {
    constructor(props) {
        super(props)
        this.modal_controller    = new ModalController ({params: {view: this, controller: this.controller}})
        this.state.form          = JSON.parse(JSON.stringify(config_data.form_objects))
        this.state.dims          = {width: 636, height: 980}
        this.state.index         = this.state.parent.state.index
        this.state.data.person   = this.controller.personModels[this.state.index]?.data
        this.state.person_backup = JSON.parse(JSON.stringify(this.state.data.person))
    }
    update_attributes() {
        this.state.index         = this.state.parent.state.index
        this.state.data.person   = this.controller.personModels[this.state.index]?.data
        if(this.state.data.person.id !== this.state.person_backup.id) {
            this.state.person_backup = JSON.parse(JSON.stringify(this.state.data.person))
        }
        this.modal_controller.set_model_controller()
    }
    renderView() {
        this.update_attributes()

        const styles = {maxWidth:"36rem"}
        const newStyles = {...styles, ...this._inner_modal_location}
        return (
            <>
          <Row key={"edit_person_modal"+this.state.index} className={"g-0 inner_modal_window cota_panel_border add_shadow modal_style no_wrap"} ref={(divElement) => {this.divElement = divElement}} style={newStyles} id={this.name}>
            <Col>
              <Row>
                <Col>
                  <Row>
                    <Col className="cota_sub_title no_wrap">
                      Edit Contact Person
                    </Col>
                  </Row>
                  <Row className="info">
                    <Col>
                      FIRST NAME*
                    </Col>
                  </Row>
                  <Row >
                    <Col>
                      {this.controller.view?.formObject({data: this.state.form.first_name, owner: this, controller: this.modal_controller})}
                    </Col>
                  </Row>
                  <Row className="info">
                    <Col>
                      LAST NAME*
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {this.controller.view?.formObject({data: this.state.form.last_name, owner: this, controller: this.modal_controller})}
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row className="info pad_top" >
                    <Col>
                      EMAIL*
                    </Col>
                  </Row>
                  <Row >
                    <Col>
                      {this.controller.view?.formObject({data: this.state.form.email, owner: this, controller: this.modal_controller})}
                    </Col>
                  </Row>
                  <Row className="info">
                    <Col>
                      PHONE*
                    </Col>
                  </Row>
                  <Row >
                    <Col>
                      {this.controller.view?.formObject({data: this.state.form.phone, owner: this, controller: this.modal_controller})}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="pad_top_hr">
                <Col>
                    <hr className="hr"/>
                </Col>
              </Row>
              <Row className="pad_no_wrap">
                <Col></Col>
                <Col>
                  <Row>
                    <Col>
                      <Button className={"clear_button " + this._theme} onClick={() => this.modal_controller.cancel()}>
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <Button className={"primary_button_coloring " + this._theme} onClick={this.modal_controller.action_new_person.bind(this)}>
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          </>
        )
    }
}