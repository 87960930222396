// GENERAL REACT MODULES
import React from "react";
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { MdHelp } from "react-icons/md";

// PAGE SPECIFIC MODULES
import _baseComponent from "components/General/_baseComponent.jsx"
import Controller from "./HelperPanel.controller.jsx"
import "./HelperPanel.css"

export default class HelperPanel extends _baseComponent {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this}})
        this.state.open         = false
	    this.state.panel_data   = props?.params
    }
    get panel_size() {
        if (this.state?.panel_data?.size !== undefined) {
            if (!isNaN(this.state.panel_data.size)) {
                return this.state.panel_data.size
            }
        }
        return 3
    }
    get min_class() {
        if (this.state?.panel_data?.ignore_min) {
            return ""
        }
        return "helper_panel_btn_col"
    }
    render() {
        if (this.state.open) {
            return (
                <Col sm={this.panel_size} onClick={this.partial_controller.open_helper.bind(this)} id={this.name}>
                    <Row className="cota_tooltip">
                        <Col>
                            <Row className="pad_top">
                                <Col className="instructions">
                                    {this.state.panel_data?.title}
                                </Col>
                            </Row>
                            <Row className="pad_top">
                                <Col>
                                    {this.state.panel_data?.msg}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        </Col>
                    </Row>
                </Col>
            )
        } else {
            return (
                <Col sm={1} className={this.min_class} id={this.name} >
                    <MdHelp size={40} className="helper_btn" onClick={this.partial_controller.open_helper.bind(this)}/>
                </Col>
            )
        }
    }
}

