// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import PersonModelController from "controllers/ModelControllers/PersonModelController.jsx"

import invite_request from "assets/graphql/Email/Email.graphql.json"
import person_mutation from "assets/graphql/Persons/person.mutation.graphql.json"
import driver_invite from "assets/graphql/Invites/invite.driver.graphql.json"

export default class DriverModalController extends _viewController {
    constructor(props) {
        super(props)
        this.person = new PersonModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true,
                                                            index: null}})
    }
    async send_invite() {
	    console.log("--- SEND INVITE ---")
        // this.uncontrolled_modal_controller.create_person()
        let body = undefined
        if (invite_request !== undefined) {
            body = JSON.parse(JSON.stringify(driver_invite))
            let _summary = {
                person: this.uncontrolled_modal_controller?.person?.summary,
                company_id: this.controller.company_id
            }
            let data = this.controller.toUnquotedJSON(_summary)
            // if (_summary?.to !== undefined) {
                body.query = body.query.replace("input:", 'input: '+data)
                this.api.invite.create({caller: this, params: {body: body}})
		        this.state.data.person = {}
		        // this.uncontrolled_modal_controller.person.first_name = ""
		        this.forceUpdate()
		        // console.log(this.uncontrolled_modal_controller)
                this.state.parent.toggle_modal({toggle_state: false})
            // }
        }
    }
    create_person() {
	    // console.log("--- CREATE PERSON ---")
        let body = JSON.parse(JSON.stringify(person_mutation))
        let data = this.toUnquotedJSON(this.person.summary)
        body.query = body.query.replace("input:", 'input: '+data)
        // console.log(body)
        // console.log(this.person)
        this.person.first_name = undefined
        // this.api.persons.ask({caller: this, params: {body: body}, callback: {f: this.process_person}})
    }
    process_person({caller, params, results}) {
        if(results?.errors !== undefined) {
            console.log("Error creating person", results.errors)
            return
        }
        // console.log("Person created")
        // need to re-render the driver panel
        caller.view.state.parent.forceUpdate()
    }
}
