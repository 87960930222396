import _viewController from "controllers/ViewController/_viewController.jsx"

export default class LoadWidgetController extends _viewController{
    constructor(props) {
        super(props)
    }
    create_data_obj({data, params}) {
        let obj = {}
        obj[params?.name] = data
        if(typeof data === "object") { 
            Object.keys(data).map((key, index) => {
                if(typeof data[key] === "object" || Array.isArray(data[key])) {
                    obj[key] = data[key]
                }
            })
        }
        return obj
    }
}