import _viewController from "controllers/ViewController/_viewController.jsx"
import driver_link from "assets/graphql/Assets/Driver.link.graphql.json"

export default class AssignDriverPartialController extends _viewController{
    constructor(props) {
        super(props)
    }
    get driver_id () {
        return this.view?.state?.page?.driver_filter
    }
    get company_drivers() {
        return this.controller.view.state.data?.company?.drivers
    } 
    attach_driver() {
        let driver = this.company_drivers?.filter(driver => driver?.id === this.driver_id)?.[0]
        this.controller.view.state.data.asset.driver = driver
        let body = JSON.parse(JSON.stringify(driver_link))
        let _summary = {
            id: this.controller?.asset?.id,
            user_id: this?.driver_id
        }
        if(_summary.id !== undefined && _summary.user_id !== undefined) {
            let data = this.toUnquotedJSON(_summary)
            body.query = body.query.replace("input:", 'input: '+data)
            this.api.assets.create({caller: this, params: {body: body}, callback: {f: this.process_assignment}})
        }
    }
    process_assignment({caller, params, results}) {
        if(results?.errors !== undefined) {
            console.log("Error assigning driver", results.errors)
            return
        }
        // console.log("Driver assigned")
        caller.controller.view.forceUpdate()
    }
}