import _viewController from "controllers/ViewController/_viewController.jsx"
import asset_request from "assets/graphql/Assets/Assets.link.graphql.json"

export default class AssignTruckController extends _viewController{
    constructor(props) {
        super(props)
    }
    follow_handle_results({params, results}) {

    }
    attach_asset() {
        let _asset = this.state?.search?.assets?.selected_value
        this.controller.view.state.data.asset.linked_asset = _asset
        let body = JSON.parse(JSON.stringify(asset_request))
        let _summary = {
            id: this.controller?.asset?.id,
            asset_id: this.state?.search?.assets?.selected_value?.id
        }
        if (_summary.id !== undefined && _summary.asset_id !== undefined) {
            let data = this.controller.toUnquotedJSON(_summary)
            body.query = body.query.replace("input:", 'input: '+data)
            this.controller.api.assets.create({caller: this, params: {body: body}, callback: {f: this.controller.process_assignment}})
        }
        this.setState({redirectToAssetAttached: true})
    }
    process_assignment({caller, params, results}) {
        caller.controller.view.forceUpdate()
    }
    redirect_asset_status () {
        this.setState({showAssetStatus: true})
    }
}
