// GENERAL REACT MODULES
import React from "react";
import cloneDeep from 'lodash/cloneDeep'
import Draggable, { DraggableCore} from 'react-draggable';
import { Row, Col, Container } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";

// ALL OTHER MODULES
import _baseForm from "components/General/_baseForm.jsx"

// ~~~ HOW TO USE MODAL_PARAMS ~~~
// SET this.state.modal_params ON THE TOP LEVEL COMPONENT

// this.state.modal_params = {
//      positioned: "relative_to_parent",   ("relative_to_parent" or "middle_of_panel")
//      title: "",                          (renders title row)
//      title_size: "",                     (adjust title font size, "extra_large","large", "medium", "small", "extra_small")
//      closeable: true,                    (renders "X" for closing in corner)
//      has_overlay: false                  (render tinted overlay)
//      close_on_outside_click: true,       (only works if has_overlay = true, closes window by clicking outside of it on overlay)
//      x_adjustment: "0px",                (adjust position, use px, %, rem, etc. )
//      y_adjustment: "0px",                (adjust position, use px, %, rem, etc. )
//      draggable: true,                    (ONLY WORKS on "middle_of_panel" modals)
//      overflow: "hidden"                  (add scrolling to page, use CSS overflow properties like "hidden", "auto", or "scroll" (Defaults to "auto"))
//    }
// ~~~~~~~~~~~~~~~~~

export default class _baseModal extends _baseForm {
    constructor(props) {
        super(props)
        this.state.modal_pos    = props?.params?.panel_params?.modal_pos
        this.state.centered     = props?.params?.panel_params?.centered
        this.state.closeable    = (props?.params?.panel_params?.closeable) ? props?.params?.panel_params?.closeable : false
        this.nodeRef            = React.createRef();
        let get_wrapper_width   = this.get_wrapper_width.bind(this)
        let get_wrapper_height  = this.get_wrapper_height.bind(this)
        if (this.state.base === undefined) { this.state.base = {x: 0, y: 0} }
        this.modalWrapper       = {
            get width() {
                return get_wrapper_width()
            },
            get height() {
                return get_wrapper_height()
            }
        }
        if (this.state.draggable) {
            this.state.offset = {
                x: 0 + ((this.modal_pos?.x !== undefined) ? this.modal_pos.x : 0),
                y: 0 + ((this.modal_pos?.y !== undefined) ? this.modal_pos.y : 0)
            }
        }
        if (this.parent?.state !== undefined) {
            this.parent.state.active_modal = this
        }
    }
    reset_offset() {
        this.state.offset = {
            x: this.state.base.x,
            y: this.state.base.y
        }
        this.forceUpdate()
    }
    get sidebar_offset() {
        if (this.controller?.sidebar?.state?.menuCollapse) {
            return 90
        }
        return 253
    }
    init_offset() {
        this.state.base.x = this.state.base.x - this.sidebar_offset
    }
    set_offset({x, y}) {
        this.state.offset = {
            x: (x === undefined) ? 0 : x,
            y: (y === undefined) ? 0 : y
        }
        this.forceUpdate()
    }
    get_wrapper_height() {
        return this.parentElement?.clientHeight
    }
    get_wrapper_width() {
        return this.parentElement?.clientWidth
    }
    get height() {
        return this?.divElement?.clientHeight
    }
    get width() {
        if (this?.divElement?.clientWidth !== undefined || this?.divElement?.clientWidth < this.state?.dims?.width) {
            return this?.divElement?.clientWidth
        }
        return (this.state?.dims?.width === undefined) ? 0 : this.state?.dims?.width
    }
    get modal_pos() {
        return this.controller?.getState({key: "modal_pos"})
        // let modal_pos = this.controller?.getState({key: "modal_pos"})
        // if (modal_pos !== undefined) {
        //     this.state.modal_pos = JSON.parse(JSON.stringify(modal_pos))
        //     this.controller.setState({key: "modal_pos", value: undefined})
        // } else {
        //     return this.state?.modal_pos
        // }
    }
    follow_modal_toggle({toggle_state}) { }
    toggle_modal({toggle_state}) {
        if (this.state?.active_modal !== undefined) {
            this.state.active_modal.follow_modal_toggle({toggle_state: ((toggle_state === undefined) ? !this.state._toggled_modal : toggle_state)})
        }
        if (toggle_state === undefined) {
            this.setState({_toggled_modal: !this.state._toggled_modal})
        } else {
            this.setState({_toggled_modal: toggle_state})
        }

        this.forceUpdate()
    }
    get _inner_modal_location() {
        let top = (this.state.base?.y !== undefined) ? this.state.base?.y : 0
        let left = (this.state.base?.x !== undefined) ? this.state.base?.x : 0
        if (this.state.draggable || this.state.centered) {
            top = (this._top_raw !== 0) ? "calc(50% - "+this.top+")" : "calc(50% - "+(this.state?.dims?.height/2)+"px)"
            left = (this._left_raw !== 0) ? "calc(50% - "+this.left+")" : "calc(50% - "+(this.state?.dims?.width/2)+"px)"
        }
        let loc = {top: top, left: left}
        if (this.state.width !== undefined) {
            loc.width = this.state.width
        }
        return loc
    }

//    get _inner_modal_location() {
//        let top = (this._top_raw !== 0) ? "calc(50% - "+this.top+")" : "calc(50% - "+(this.state?.dims?.height/2)+"px)"
//        let left = (this._left_raw !== 0) ? "calc(50% - "+this.left+")" : "calc(50% - "+(this.state?.dims?.width/2)+"px)"
//        let loc = {top: top, left: left}
//        return loc
//    }
    get _top_raw() {
        if (this.height === undefined) {
            return (0 + (this?.state?.offset?.y))
        }
        return (this.height?.half() - this?.state?.offset?.y )
    }
    get _left_raw() {
        if (this.width === undefined) {
            return (0 + (this.state.offset?.x))
        }
        return (this.width?.half() - this.state.offset?.x)
    }
    get top() {
        if (this.height === undefined) {
            return (0 + (this.state.offset?.y)) + "px"
        }
        return (this.height?.half() - this.state.offset?.y ).px()
    }
    get left() {
        if (this.width === undefined) {
            return (0 + (this.state.offset?.x)) + "px"
        }
        return (this.width?.half() - this.state.offset?.x).px()
    }
    validStr(message) {
        if (typeof message === 'string' && message.length !== 0) {
            return message
        }
        if (message === undefined || message === null) {
                return ""
        }
        return message.toString()
    }
    shouldComponentUpdate() {
        this.reset_offset()
        return false
    }
    wrapped_render_view() {
        this.dims = this.divElement?.getBoundingClientRect()
        return this.renderView()
    }
    renderView() { }
    eventHandler(e, data) {
        let buffer = (this.state.buffer === undefined) ? 20 : this.state.buffer
        if (e.movementX < 0) {
            if ((this.modalWrapper.width + (this.state.offset.x*2)) > this.width) {
                let movement = this.state.offset.x + e.movementX
                if (movement*2 > ((this.modalWrapper.width - (buffer+(this.width))) *-1)) {
                    this.state.offset.x = movement
                }
            }
        } else {
            if (((this.state.offset.x*2) + this.width) < this.modalWrapper.width) {
                let movement = this.state.offset.x + e.movementX
                if (movement*2 < (this.modalWrapper.width - (buffer+(this.width)))) {
                    this.state.offset.x = movement
                }
            }
        }
        if (e.movementY < 0) {
            // if (Math.abs((this.state.offset.y*2) - this.height) < this.modalWrapper.height) {
                let movement = this.state.offset.y + e.movementY
                if (movement*2 > ((this.modalWrapper.height)-(buffer+(this.height))) *-1) {
                    this.state.offset.y = movement
                }
            // }
        } else {
            // if (((this.state.offset.y*2) + this.height) < this.modalWrapper.height) {
                let movement = this.state.offset.y + e.movementY
                if (movement*2 < ((this.modalWrapper.height)-(buffer+(this.height)))) {
                    this.state.offset.y = movement
                }
            // }
        }
        this.forceUpdate()
    }
    set_modal() {
        this.eventHandler({x: 0, y: 0}, "start")
    }
    render_closeable() {
        if (this.state.closeable) {
            return (
                <Row >
                    <Col>
                    
                    </Col>
                    <Col xs="auto" className='g-0 relative pointer'>
                        <AiFillCloseCircle size={20} className={"block_close"} onClick={this.toggle_modal.bind(this.state.parent)}/>
                    </Col>
                </Row>
            )
        }
    }
    
    followDidMount() {}
    componentDidMount() {
        this._isMounted     = true
        if (this.modal_controller !== undefined) {
            if (typeof this.modal_controller.load_data === "function") { 
                this.modal_controller.load_data()
            }
        }
        this.followDidMount()
    }
    
    // ~~~vvv~~~ START MODAL_PARAMS METHODS ~~~vvv~~~

    renderOverlay(has_overlay) {
        const close_on_outside_click = this.state.modal_params.close_on_outside_click ? true : false
        return(
            <div className={"fullscreen_overlay "+((has_overlay) ? "tinted" : "")}
                onClick={close_on_outside_click ? this.toggle_modal.bind(this.state.parent) : ()=>{}}>
            </div>
        )
    }

    renderTitleCloseRow(){
        const {title, title_size, closeable} = this.state.modal_params;
        
        const fontSizes = {
            extra_large: "50px",
            large: "35px",
            medium: "24px",
            small: "18px",
            extra_small: "12px"
        }

        return (
            <Row className="flex-nowrap modal_title_close_bar">
                <Col className="modal_heading" style={{fontSize: `${fontSizes[title_size]}`}}>
                    {title}
                </Col>
                {closeable && 
                (<Col xs="auto" className='pointer close_x'>
                    <AiFillCloseCircle size={20} onClick={this.toggle_modal.bind(this.state.parent)}/>
                </Col>)
                }
            </Row>
        )
    }

    renderMiddleOfPanel(){
        let {has_overlay, draggable} = this.state.modal_params;
        const positioningStyles = this.positioningStyles;
        let sidebar_offset = (this?.state?.modal_params?.ignore_sidebar) ? 0 : this.sidebar_offset

        draggable = draggable === true ? true : false;
        return(
            <div id="renderMiddleOfPanel" style={{ position: 'fixed',
                                            width: `calc(100% - ${sidebar_offset}px)`,
                                            height: "100%",
                                            left: `${sidebar_offset}px`,
                                            top: '0px',
                                            zIndex: 1010}}>
                <div className="flex_centered"  style={{ position: "absolute",
                                                            width: "100%",
                                                            height: "100%"}}>
                    {this.renderOverlay(has_overlay)}
                    <Draggable bounds = "parent"
                                disabled = {!draggable}>
                        <div style={{ ...positioningStyles, 
                                            cursor: draggable ? "move" : "pointer",
                                            zIndex: "11"}}>
                            <Container key={this.type + this.state.reset} 
                                        className={"cota_modal_border add_shadow"} 
                                        ref={(divElement) => { this.divElement = divElement; }} 
                                        id={this.name} 
                                        style={{maxHeight: "95vh",
                                                maxWidth: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                boxSizing: "border-box"}}>
                                {this.renderTitleCloseRow()}
                                <Row style={{
                                    overflow: this.state.modal_params.overflow ? this.state.modal_params.overflow : "auto"
                                    }}>
                                    <Col>
                                        {this.renderView()}
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Draggable>
                </div>
                    
                </div>
        )
    }

    get positioningStyles(){
        // let {positioned, relative_position, relative_alignment, x_adjustment, y_adjustment} = this.state.modal_params
        let { x_adjustment, y_adjustment} = this.state.modal_params
        
        let positioningStyles = {}
        let translatePercent = 0
        let alignmentPercent = 0
        x_adjustment = x_adjustment ? x_adjustment : "0px"
        y_adjustment = y_adjustment ? y_adjustment : "0px"

        // if(relative_alignment === "start"){
        //     translatePercent = 0
        //     alignmentPercent = 0
        // } else if(relative_alignment === "center"){
        //     translatePercent = 50
        //     alignmentPercent = 50
        // } else if(relative_alignment === "end"){
        //     translatePercent = -100
        //     alignmentPercent = 100
        // }


        // if(positioned === "relative_to_parent"){
        //     positioningStyles = (() => {
        //         if(relative_position === "above"){
        //             return ({
        //                 position: "relative",
        //                 bottom: `calc(100% + ${y_adjustment}`,
        //                 left: `calc(${alignmentPercent}% + ${x_adjustment})`,
        //                 transform: `translateX(${translatePercent}%`
        //             })
        //         }   
        //         else if(relative_position === "below"){
        //             return ({
        //                 position: "relative",
        //                 top: `calc(100% + ${y_adjustment}`,
        //                 left: `calc(${alignmentPercent}% + ${x_adjustment})`,
        //                 transform: `translateX(${translatePercent}%`
        //             })
        //         } else if(relative_position === "left"){
        //             return ({
        //                 position: "relative",
        //                 top: `calc(${alignmentPercent}% + ${y_adjustment})`,
        //                 right: `calc(100% + ${x_adjustment}`,
        //             })
        //         } else if(relative_position === "right"){
        //             return ({
        //                 position: "relative",
        //                 top: `calc(${alignmentPercent}% + ${y_adjustment})`,
        //                 left: `calc(100% + ${x_adjustment}`,
        //             })
        //         }
        //     })()
        // } else {
            positioningStyles = (() => {
                    return ({
                        position: "relative",
                        top: `${y_adjustment}`,
                        left: `${x_adjustment}`,
                    })
            })()
        // }

        return positioningStyles
    }

    renderRelativeToParent() {
        let {positioned, has_overlay, draggable} = this.state.modal_params;
        const parentWidth = this.parent_dims?.width;
        const parentHeight = this.parent_dims?.height;
        const parentPositionStyles = {
                position: 'relative',
                width: parentWidth,
                height: parentHeight,
                top: -parentHeight, 
                left: 0
        }
        return(
            <div style={{position: "absolute", zIndex: "11", height: "0px", width: "fit-content" }}>
                {this.renderOverlay(has_overlay)}
                <Container key={this.type + this.state.reset} 
                            className={"cota_modal_border add_shadow"} 
                            ref={(divElement) => { this.divElement = divElement; }} 
                            id={this.name} 
                            style={{maxHeight: "95vh",
                                    maxWidth: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    boxSizing: "border-box",
                                    ...this.positioningStyles,
                                    zIndex: 2}}>
                            {this.renderTitleCloseRow()}
                    <Row style={{ overflow: this.state.modal_params.overflow ? this.state.modal_params.overflow : "auto"}}>
                        <Col>
                            {this.renderView()}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
}

    renderFromSidebar() {
        
    }

    
// ~~~^^^~~~ END MODAL_PARAMS METHODS ~~~^^^~~~


    render() {
        if (this.parent?.original_state !== undefined) {
            this.parent.original_state = cloneDeep(this.state.data)
        } else {
            this.state.data = cloneDeep(this.state.data)
        }
        this.parent_dims = this.state.parent?.divElement?.getBoundingClientRect()
        if (this.state?.parent?.state?.refs !== undefined) {
            this.state.parent.state.refs._modal_ref = this.nodeRef
        }
        if(this.state?.modal_params?.positioned === "middle_of_panel") {

            return this.renderMiddleOfPanel();

        } else if(this.state?.modal_params?.positioned === "relative_to_parent"){

            return this.renderRelativeToParent()

        } else if(this.state.draggable) {
            // Currently not working. TS Later
            return (
                <Row id={this.name} className="generic_modal_wrapper g-0" ref={ (parentElement) => { this.parentElement = parentElement } }>
                    <DraggableCore nodeRef={ this.nodeRef }
                                    onStart={(e) => this.eventHandler(e, "start")}
                                    onDrag={(e) => this.eventHandler(e, "drag")} 
                                    onStop={(e) => this.eventHandler(e, "stop")}
                                    disabled={false}>
                                    {/* disabled={this.state.offset.x === 400 & this.state.offset.y === -260 ? true : false}> */}
                        <div ref={this.nodeRef}>
                            {
                                this.renderView()
                            }
                        </div>
                    </DraggableCore>
                </Row>
            ) 
        } else {
            return (
                <Row id={this.name} className="generic_modal_wrapper g-0" ref={ (parentElement) => { this.parentElement = parentElement } }>
                    {
                        this.renderView()
                    }
                </Row>
            )
        }
    }
}

Number.prototype.half = function () {
    var target = this;
    return target / 2
}
Number.prototype.px = function () {
    var target = this;
    return target + "px"
}
