// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx"
import "./AddStopPeople.css"

export default class AddStopPeopleModal extends _baseModal {
    constructor(props) {
        super(props)
        this.state.modal_params = {
            positioned: "relative_to_parent",
            relative_position: "below",
            relative_alignment: "start",
            x_adjustment: "0px",
            y_adjustment: "-40px",
            close_on_outside_click: true,
            closeable: false,
        }
        this.modal_controller = this.state.parent?.partial_controller
    }
    get people() {
        return this.modal_controller.view.state.people
    }
    renderView() {
        // console.log(this)
        return (
            <>
                <Row id={this.name} style={{width: "292px"}}>
                    <Col>
                        {
                            this.people.map((person, index) => {
                                return (
                                    <Row className="g-0 people_row pointer row_highlight" onClick={this.modal_controller.select_person.bind(this, index)} key={"person_"+index}>
                                        <Col>
                                            {person?.first_name} {person?.last_name}
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </Col>
                </Row>
            </>
        )
    }
}
