//GENERAL REACT MODULES
import React from "react";
import { Container, Row, Col, Button } from 'react-bootstrap'

//MODULE SPECIFIC
import _basePanelView from "components/General/_basePanelView.jsx"
import CotaUserBar from "components/CotaUserBar/CotaUserBar.partial.jsx"
import { MultiSelectDropdown } from "components/Dropdowns/Dropdowns.jsx"
import config_data from "./LoadOptions.json"
import Controller from "./Loads.controller.jsx"
import "./Loads.css"
import ViewLoadPanel from "./ViewLoad/ViewLoadPanel/ViewLoadPanel.view.jsx";
import NewLoadPanel from "./LoadsPanel/NewLoad/NewLoadPanel.jsx";
import GrafanaFrame from "components/GrafanaFrame/GrafanaFrame.view.jsx";
import BillingPage from "./ViewLoad/BillingPage/BillingPage.view.jsx"
import TripsPanel from "../Trips/TripsPanel/TripsPanel.view.jsx"
import DispatchedTrip from "../Trips/DispatchedTrip/DispatchedTrip.view.jsx";
// import grafana_config from "../Map/grafana_test.json";
import grafana_config from "../../controllers/GrafanaConfig/GrafanaConfig.js";
import CotaListPanel from "components/CotaListPanel/CotaListPanel.partial.jsx";
import { ImMagicWand } from "react-icons/im";
import Modal from "./Modals/AutoLoadModal/AutoLoadModal.jsx"
import processingModal from "./Modals/ProcessingModal/ProcessingModal.jsx"
import ConfomationModal from "./Modals/ConformationModal/ConformationModal.jsx";


export default class LoadsPanel extends _basePanelView {
    constructor (props) {
        super (props)
        this.state.refs = {
            _modal_ref: undefined,
            _frame_ref: React.createRef()
        }
        this.controller             = new Controller ({params: {view: this}})
        this.state.config_data      = config_data.load_options
        this.state.panel_config    = config_data.load_data
        this.state.form             = config_data.form_objects   
        this.state.loads            = []
        this.state.screen           = 0
        // this.state.panelId          = 2
        this.state.status_options   = []
        this.state.page = {
            status_filter: []
        }
        this.state.reset            = false
        this.state.panel_name       = "loads"
        this.state.channel_name     = "grafana_row"
        this.state.panel_configs    = grafana_config()["pages"]["loads"]
        this.state.panel_trees      = [{key: 0, name:"new_loads", base: 0},
                                       {key: 1, name: "view_loads", base: 1, 
                                        paths: [{"new_trip": 3, "view_trip": 4, "billing": 2}]},
                                       {key: 2, name: "billing", base: 2},
                                       {key: 3, name: "trips", base: 3},
                                       {key: 4, name: "view_trip", base:4}]
        // add view_trip panel to above and below
        this.state.panels           = [{key: 0, obj: NewLoadPanel, params: {panel_params: {is_closeable: false, close_title: "Back Home", close_func: "resetScreen", controller: this.controller}}},
                                       {key: 1, obj: ViewLoadPanel, params: {panel_params: {is_closeable: false, close_title: "Back Home", close_func: "resetScreen", controller: this.controller}}},
                                        {key: 2, obj: BillingPage, params: {panel_params: {is_closeable: false, close_title: "Back", close_func: "backPanel", controller: this.controller}}},
                                        {key: 3, obj: TripsPanel, params: {panel_params: {is_closeable: false, close_title: "Back", close_func: "backPanel", controller: this.controller}}},
                                        {key: 4, obj: DispatchedTrip, params: {panel_params: {is_closeable: false, close_title: "Back to Loads", close_func: undefined, controller: this.controller}}}]
        this.handleProcessEvent     = this.handleProcessEvent.bind(this)

        this._modal = Modal
        this._center_modal      = true
    }
    should_reset_statuses() {
        if(this.state.reset) {
            this.state.page.status_filter = []
            this.state.reset = false
        }
    }
    renderGrafanaPanel() {
        if(this.state?.carrier_id === undefined) {return <></>}
        let carrier_id =  (this.controller.internal_user) ? "" : this?.state?.carrier_id
        return (
            <CotaListPanel params={{parent: this, 
                panel_name: this.state.panel_name,
                configs: this.state.panel_config.panels,
                endpoint: "loads",
                req_name: "list",
                query: "floads",
                configs: this.state.panel_config.panels,
                search_fields: "Load id, Payor, Pickup, or Dropoff",
                query_params: this.controller.generate_query_params(),
                // args: this.state?.page?.status_filter[0] === undefined ? `carrier:"${this?.state?.carrier_id}"` : `carrier:"${this?.state?.carrier_id}",statuses:["${this.state?.page?.status_filter}"]` ,
                filters: {
                    statuses: this.state.page.status_filter,
                    // name: this.state?.page?.user_filter, 
                            // c: this.state?.page?.status_filter,
                            // ltl_truckload: this.controller.resolve_ltl_toggle(),
                            // nots: ((this.state?.page?.status_filter?.length > 0) ? "" : "status_id"),
                            carrier: carrier_id,
                            // time: this.controller.process_date(this.state?.page?.time_frame)
                        }}}/>
        )
    }
    toggleDeliveryModal = () => {
        if(this.state.processing){
            this._modal = processingModal
            console.log("WORKED")
        this.toggle_modal({ toggle_state: true })
        }
        this.state.processing = false
    };
    changeModal = (processing_id) => {
        if(this.state.change){
        this._modal = ConfomationModal
        this.state.processing_id = processing_id
        this.toggle_modal({ toggle_state: true })
        }
        this.state.change = false
    };
    resetModal(){
        console.log("RESETTING")
        this._modal = Modal
    }
    check_statuses() {
        this.push_status("dispatched")
        this.push_status("available")
        this.push_status("delivered")
    }
    push_status(name) {
        // console.log("STATUS NAME",name)
        if(this.state?.[name]) {
            name = name.toUpperCase()
            if(name === "DISPATCHED"){
                this.state.page.status_filter = [name,"AT_SHIPPER","AT_CONSIGNEE","AT_STOP","IN_TRANSIT"]
            }
            if(!this.state.page.status_filter.includes(name)) {
                this.state.page.status_filter = [name]
            }
            this.state.reset = true
        }
    }
    get_panel_size() {
            if (this.state?.data?.loads?.length === 0) {
                return "60% + 160px"
            }
            return "60%"
        
        return "100%"
    }
    renderMain() {
        this.should_reset_statuses()
        this.check_statuses()
        if(this.state.processing){
        this.toggleDeliveryModal()
        }
        return (
            <Container id={this.name} className="generic_100 g-0">
                <Row className="max_height text_color">
                    <Col className="flex_wrapper_rows">
                        <Row>
                            <Col className="flex_wrapper_rows">
                                <CotaUserBar params={{parent: this, controller: this.controller, title:"Loads"}} />
                            </Col>
                        </Row>
                        <Row className="generic_100 cota_panel_object g-0 add_shadow">
                            <Col className="generic_100">
                                <Row className="toolbar">
                                    <Col sm={1} className="panel_title">
                                        Loads
                                    </Col>
                                    <Col />
                                    <Col sm={3} className="dropdown">
                                        {this.formObject({data: this.state.form.status_filter, options: this.state.status_options, owner: this})}
                                    </Col>
                                    {/* <Col></Col> */}
                                    {/* <Col sm={2} className="search">                           
                                        {this.formObject({data: this.state.form, options: undefined, owner: this})} 
                                    </Col> */}
                                    <Col sm="auto">
                                        <Button className={this._theme} variant="primary" onClick={this.handleScreen.bind(this, {path_num: 0, screen_num: 0})}>
                                            New Load
                                        </Button>   
                                    </Col>
                                    <Col sm="auto">
                                        <Button className={this._theme} variant="primary" onClick={() => this.toggle_modal({toggle_state: true})} >
                                            <ImMagicWand className="mar_right"  ></ImMagicWand>
                                           Auto Load
                                        </Button>   
                                    </Col>
                                    <Col sm="auto" className="find_loads_btn">
                                        <Button onClick={() => this.controller.handleFindLoad()} className="btn_find">
                                            Find loads
                                        </Button>   
                                    </Col>
                                </Row>
                                <Row className="grafana g-0" style={{"height": "calc("+this.get_panel_size()+")"}} ref={ (grafanaElement) => { this.state.refs.grafanaElement = grafanaElement } }>
                                    <Col className="" >
                                        {this.renderGrafanaPanel()}
                                    </Col>
                                </Row>
                            </Col>                                               
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}