// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Container, Button, Card } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx";
import _basePanelView from "components/General/_basePanelView.jsx"
import ModalController from "./DeliveryStop.modal.controller.jsx";
import delivery_data from "./DeliveryStopCard/DeliveryStop.json"
import config_data from "../AddPickupStop/PickupStop.json"
import "./DeliveryStop.modal.css"
import DeliveryStopCardPanel from "./DeliveryStopCard/DeliveryStopCard.view.jsx";

export default class DeliveryStopModal extends _baseModal {
    constructor(props) {
        super(props);
        this.state.form = config_data.form_objects
        this.state.config_data = config_data
        this.state.delivery_data = delivery_data
        this.modal_controller = new ModalController ({params: {view: this}})
        this.state.clicked_one = false
        this.state.dims = {width: 636, height: 980}
    }

    renderDelivery = () => {
        const deliveryCards = this.state.delivery_data;
        this.state.parent.setState({show: true, _toggled_modal: false})
        const deliveryComponent = deliveryCards.map((deliveryCard, index) => (
            <DeliveryStopCardPanel
                params={{parent: this, details: deliveryCard}}
                key = {deliveryCard.id}
                deliveryCard={deliveryCard?.id}
                />
        ))
    }

    renderView() {
        const deliveryCards = this.state.delivery_data;
        const deliveryComponent = deliveryCards.map((deliveryCard, index) => (
            this.state.parent.state.component.push(
                <DeliveryStopCardPanel
                    params={{parent: this, details: deliveryCard}}
                    key={deliveryCard.id}
                    deliveryCard = {deliveryCard?.id}
                    />
            )
        ))
        const styles = {maxWidth:"45%"};
        const newStyles = { ...styles, ...this._inner_modal_location}
        return (
           
            <>
            <Col className={"g-0 inner_modal_window cota_panel_border add_shadow modal_style"} ref={(divElement) => {this.divElement = divElement}} style={newStyles} id={this.name}>
                <Col className="cota_sub_title marg_left text_color"> Add Delivery Stop</Col>
                &nbsp;
                <Row className="flex_wrap" style={{marginLeft:"5%"}}>
                    <Row className="">
                        <Row className={`${this.state.clicked === true ? "blue_color" : "text_color" }`}>CONSIGNEE*</Row>
                        <Row onClick={() => this.setState({ clicked: !this.state.clicked})} className="dropdown_col">
                        {/* {this.formObject({ data: this.state.form})} */}
                        {this.formObject({data: this.state.form.time_frame, options: undefined, owner: this})}
                        </Row>
                    </Row>
                </Row>
                &nbsp;
                <Row className="flex_wrap " style={{marginLeft:"5%"}}>
                    <Row >
                        <Row >
                        <Row style={{}}>CONSIGNEE CONTACT PERSON</Row>
                        </Row>
                        <Row className="dropdown_col">
                        {/* {this.formObject({ data: this.state.form})} */}
                        {this.formObject({data: this.state.form.time_frame, options: undefined, owner: this})}
                        </Row>
                    </Row>
                </Row>
                &nbsp;
                <Row className="flex_wrap " style={{marginLeft:"3%"}}>
                    <Row >
                        <Row style={{marginLeft:"1%"}}>NOTES</Row>
                        <textarea style={{height:"175px", width:"550px"}} />
                        {/* <Row className="dropdown_col" style={{height:"175px", width:"550px"}}>
                        {/* {this.formObject({ data: this.state.form})} */}
                        {/* {this.formObject({data: this.state.form.time_frame, options: undefined, owner: this})}
                        </Row>  */}
                    </Row>
                </Row>
                &nbsp;
                <Row style={{marginRight:"1vw"}}>
                    <Col>
                    </Col>
                    <Col sm={3} className="button_min_w g-0">
                        <Button className={"center_button clear_button "+this._theme} onClick={this.toggle_modal?.bind(this.state.parent, {toggle_state: false})} >
                            Cancel
                        </Button>
                    </Col>
                    <Col sm={3} className="button_min_w g-0" onClick={() => this.renderDelivery()}>
                        <Button className={"center_button primary_button_coloring "+this._theme} onClick={this.toggle_modal?.bind(this.state.parent, {toggle_state: false})} >
                            Save
                        </Button>
                    </Col>
                </Row>
            </Col>
            </>
        )
    }
}