// GENERAL REACT MODULES
import React from "react";

// ALL OTHER MODULES
import _baseComponent from "components/General/_baseComponent";

export class PanelController extends _baseComponent {
    constructor(props) {
        super(props)
        this.state.context  = {
            cache: { }
        }
    }
    get cache() {
        return this.state.context.cache
    }
    get_context() {
        return this.state.context
    }
    create_context({key, data}){ 
        this.setState({context: {[key]: data}})
    }
    merge_context(data) {
        // data.map((key, value) => {
        //     this.setState({context: {[key]: value}})
        // })
    }
    dump_context() {
        // this.setState({context: {}})
    }
    followDidMount() {
//        this.setState({screen_num: 0, 
//                        panel_index: this.state?.tree?.base})
    }
    getCurrentTree() {
	    let screen_num = (this.state?.screen_num !== undefined) ? this.state?.screen_num : 0
        return this.state?.tree?.paths?.[screen_num]
    }
    getTreeKey(panel_name) {
        return (this.getCurrentTree())?.[panel_name]
    }
    reverse_key(panel_type) {
        let looked_up = this.state?.panels?.filter(o => (new o.obj()).name === panel_type )[0]
        return looked_up.key
    }
    renderPanelView(panel_object) {
        if (panel_object !== undefined) {
            return (
                React.createElement(panel_object, {params: {parent: this, panel_controller: this, panel_params: this.assignPanelProps()}}, undefined)
            )
        }
        return (
            <div></div>
        )
    }
    assignPanelProps() {
        if (this.state.panels !== undefined) {
            if (this.state.panels[this.state.panel_index] !== undefined) {
                let panel_params = this.state.panels[this.state.panel_index]?.params?.panel_params
                if (panel_params !== undefined) {
                    if (this.state?.parent?.state?.panel_data !== undefined) {
                        panel_params.data = this.state?.parent?.state?.panel_data
                    }
                    // TROUBLESHOOT
                    // panel_params.parent == this
                    return panel_params
                }
            }
        }
        return {}
    }
    function_wrapper(passed_function) {
        var fn = this.state?.parent?.[passed_function]?.bind(this.state?.parent);
        if(typeof fn === 'function') {
            fn()
        }
	if (fn === undefined) {
	    fn = this?.[passed_function]?.bind(this)
	    this.do_func(fn)
	}
    }
    do_func(fn) {
	if(typeof fn === 'function') {
	    fn()
	}
    }
    iteratePanels(data) {
        this.setState({data: data})
        if (this.state.panel_index < this.state.panels.length-1) {
            this.setState({panel_index: this.state.panel_index + 1})
        }
        if (this.state.panel_index >= this.state.panels.length) {
            console.log("COMPLETED")
        }
    }
    select_panel_by_name(name) {
        this.selectPanel({panel_num: this.getTreeKey(name)})
    }
    selectPanel({screen_num, panel_num, panel_name, data, cache_data}) {
        if (cache_data?.current !== undefined) {
            this.state.current = cache_data.current
        }
        if (data?.key !== undefined) {
            this.state.data[data?.key] = data.value
        }
        if (panel_name !== undefined && panel_num === undefined) {
            panel_num = this.getTreeKey(panel_name)
        }
        if (screen_num !== undefined) {
            this.state.screen_num = screen_num
        }
        if (panel_num !== undefined) {
            let previous_index = this.state.panel_index
            this.state.panel_index = panel_num
            this.state.from = previous_index
            this.loadCache({cache_data: cache_data})
            this.setState({from: previous_index})
        }
    } 
    loadCache({cache_data, index}) {
        let data = undefined
        if (cache_data !== undefined) {
            data = JSON.parse(JSON.stringify(cache_data))
        }
        let panel_index = (index === undefined) ? this.state.panel_index : index
        if (cache_data !== undefined) {
            if (this.state?.context?.cache !== undefined) {
                if (this.state?.context?.cache?.[panel_index] === undefined) {
                    this.state.context.cache[panel_index] = {}
                }
            }
            this.state.context.cache[panel_index].data = data
        }
    }
    getCache(index, verify) {
        if (index !== undefined) {
            if (this.state?.context?.cache?.[index] !== undefined) {
                let cache = JSON.parse(JSON.stringify(this.state.context.cache[index]))
                if (verify !== undefined) {
                    if (cache?.data?.[verify?.k] !== verify?.v) { return }
                }
                this.state.context.cache[index] = undefined
                delete this.state.context.cache[index]
                return cache
            }
        }
    }
    backPanel() {
        if (this.state?.from !== undefined) {
            this.setState({panel_index: this.state?.from})
             return
        } 
        let parent  = this.state?.parent
        let name    = parent?.name
        let menus   = parent.state?.context?.sidebar?.state?.menus
        let merged_menus = [...menus[0], ...menus[1]]
        let menu_item = merged_menus.filter(menu_item => { return menu_item.name === name })?.[0]
        if (menu_item !== undefined) {
            window.history.pushState(null, null,  menu_item?.link);
        }
        this.state.parent.state.context.path = undefined
        console.log(this.state.parent)
        this.state.parent.resetScreen()
    }

    get_panel_by_key(key) {
        return this.state.panels.find((element) => {
            return element.key === key;
        })
    }
    reset_main_screen() {
        this.state.parent.setState({screen: 0})
    }
    get _panel_index() {
        return (this.state?.panel_index === undefined) ? this.state?.tree?.base : this.state.panel_index
    }
    render() {
        let panel = this.get_panel_by_key(this._panel_index)
        if (panel !== undefined) {
            return(
                <>
                {
//			this.renderPanelView(panel?.obj)
                    this.renderPanelView(this.state.panels[this._panel_index]?.obj)
                }
                
                </>
            )
        } 
        return (
            <>
            </>
        )
    }
}
