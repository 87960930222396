// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
// import LineItemModelController from "controllers/ModelControllers/Quotes/LineItemModelController"
import ListLineController from "components/CotaListPanel/ListLineController.jsx"
import { BsTruck, BsDashCircleDotted } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import { CiWallet } from "react-icons/ci";

export default class MiniBadgeColumnController extends ListLineController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    get item_data() {
        return this.view.item_data
    }
    get _configs() {
        return this.view._configs
    }
    configure_data(data) {
        data = this.unwrap_obj({obj: data})
        if(typeof data === "string" && data?.includes(",")) {
            data = data.replaceAll(" ", "").split(",").filter((el) => el !== "Importer" && el !== null)
        }
        return data
    }
    configure_design_values() {
        let design = this.view.props?.params?.design
        if(design?.b !== null && design?.b !== undefined && Array.isArray(design?.b)) {
            return design?.b
        }
        return design
    }
    get_badge_color(index) {
        if (this.view.state.color !== undefined) {
            return this.view.state.color+"_badge"
        }
        let data = this.configure_data(this.view.props?.params?.data)
        if(index !== undefined) { data = data?.[index] }
        let design = this.configure_design_values()
        if(data !== null && data !== undefined) {
            if(Array.isArray(design)) {
                design = design.find(el => el?.v === this.lc(data))
            }
            return this.determine_badge_color({data: data, design: design})
        }
        return ""
    }
    determine_badge_color({data, design}) {
        if (design?.v !== null) {
            data = data.toString()
            if (data.toLowerCase() === design?.v?.toLowerCase()) {
                return design?.c+"_badge"
            }
        }
        return ""
    }
    get_icon(index) {
        let data = this.configure_data(this.view.props?.params?.data)
        if(index !== undefined) { data = data?.[index] }
        let design = this.configure_design_values()
        if (data !== null && data !== undefined) {
            if(Array.isArray(design)) {
                design = design.find(el => el?.v === this.lc(data))
            } 
            return this.determine_icon({data: data, design: design})
        }
        return <BsDashCircleDotted size={24} className="badge_icon"/>
    }
    determine_icon({data, design}) {
        if (design?.v !== null) {
            data = data.toString()
            if (data.toLowerCase() === design?.v?.toLowerCase()) {
                switch(design?.t) {
                    case "asset":
                        return <BsTruck size={24} className="badge_icon"/>
                    case "wallet":
                        return <CiWallet size={24} className="badge_icon"/>
                    case "loc":
                        return <MdLocationOn size={24} className="badge_icon"/>
                    default:
                        return <BsDashCircleDotted size={24} className="badge_icon"/>
                }
            }
        }
    }
}
