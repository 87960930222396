// Disgustin
import React from "react"
import { Row, Col, Button } from "react-bootstrap"

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./CompanyCreation.partial.controller.jsx"
import { countriesOptions } from "assets/countryCodes.js"
import "./CompanyCreation.partial.css"
// import config_data from "../Overlay.json"
import config_data from "./CompanyCreation.json"

export default class CompanyCreationPartial extends _basePartial {
    constructor(props) {
        super(props)
        this.controller = new Controller({params: {view: this, controller: this?.state?.controller}})
        this.state.form = config_data.form_objects
        this.state.states	= []
        this.state.data     = {
            carrier: {},
            company: {
                email: "" // fixes the email issue so updating the model controller does not break FP
            }
        }
    }
    renderView() {
        // console.log(this.controller.carrier)
        return (
        <Row id={this.name}>
            <Col>
                <Row className="generic_panel_object generic_100 g-0 main_row" id={this.name}>
                        <Col>
                            {/* <Row className="max_width_row">
                                <Col sm={1} className="logo_col">
                                    <Row>
                                        <div className="new_contact_logo" />
                                    </Row>
                                </Col>
                                <Col />
                            </Row>
                            <Row className="mb-2">
                                <Col>
                                    <div className="new_contact_logo_button">
                                        Click to upload logo
                                    </div>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col>
                                    <Row className="company_title_question company_details_row_padding">
                                        <Col>
                                            Company Name
                                        </Col>
                                    </Row>
                                    <Row className="company_details_row_padding">
                                        <Col>
                                            {this.formObject({data: this.state.form.company, options: undefined, owner: this, controller: this.controller})}
                                        </Col>
                                    </Row>
                                    <Row className="company_details_row_padding">
                                        <Col>
                                            Billing Address 1
                                        </Col>
                                    </Row>
                                    <Row className="company_details_row_padding">
                                        <Col>
                                            {this.formObject({data: this.state.form.address, options: undefined, owner: this, controller: this.controller})}
                                        </Col>
                                    </Row>
                                    <Row className="company_details_row_padding">
                                        <Col>
                                            Billing Address 2
                                        </Col>
                                    </Row>
                                    <Row className="company_details_row_padding">
                                        <Col>
                                            {this.formObject({data: this.state.form.address_2, options: undefined, owner: this, controller: this.controller})}
                                        </Col>
                                    </Row>
                                    <Row className="company_details_row_padding">
                                        <Col>
                                            Billing City
                                        </Col>
                                    </Row>
                                    <Row className="company_details_row_padding">
                                        <Col>
                                            {this.formObject({data: this.state.form.city, owner: this, controller: this.controller})}
                                        </Col>
                                    </Row>
                                    <Row className="company_details_row_padding">
                                        <Col>
                                            Phone Number
                                        </Col>
                                    </Row>
                                    <Row className="company_details_row_padding">
                                        <Col>
                                            {this.formObject({data: this.state.form.phone, owner: this, controller: this.controller})}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Row>
                                            <Col>
                                                <Row className="company_details_row_padding">
                                                    <Col>
                                                        State
                                                    </Col>
                                                </Row>
                                                <Row className="company_details_row_padding">
                                                    <Col>
                                                        {this.formObject({data: this.state.form.state, options: this.state.controller.states, owner: this, controller: this.controller})}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className="company_details_row_padding">
                                                    <Col>
                                                        Zip
                                                    </Col>
                                                </Row>
                                                <Row className="company_details_row_padding">
                                                    <Col>
                                                        {this.formObject({data: this.state.form.zip, owner: this, controller: this.controller})}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className="company_details_row_padding">
                                                    <Col>
                                                        Country
                                                    </Col>
                                                </Row>
                                                <Row className="company_details_row_padding">
                                                    <Col>
                                                        {this.formObject({data: this.state.form.country, owner: this, options: countriesOptions, controller: this.controller})}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Row className="company_details_row_padding">
                                                    <Col>
                                                        Email Address
                                                    </Col>
                                                </Row>
                                                <Row className="company_details_row_padding">
                                                    <Col>
                                                        {this.formObject({data: this.state.form.email, owner: this, controller: this.controller})}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className="company_details_row_padding">
                                                    <Col>
                                                        Fax Number
                                                    </Col>
                                                </Row>
                                                <Row className="company_details_row_padding">
                                                    <Col>
                                                        {this.formObject({data: this.state.form.fax, owner: this, controller: this.controller})}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="cd_bottom_row">
                                <hr />
                            </Row>
                            <Row>
                                <Col>&nbsp;</Col>
                                <Col className="generic_center">
                                    <Button className={"generic_center save_button "+this._theme} onClick={this.controller?.cancel_action.bind(this)}>
                                        Cancel
                                    </Button>
                                </Col>
                                <Col className="generic_center">
                                    <Button className={"generic_center save_button "+this._theme} onClick={this.controller?.submit_action.bind(this)}>
                                        Save
                                    </Button>
                                </Col>
                                <Col>&nbsp;</Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>  
        )
    }
}

