import React from "react";
import { Container, Row, Col, Button } from 'react-bootstrap'

import _basePanelView from "components/General/_basePanelView.jsx"
import CotaUserBar from "components/CotaUserBar/CotaUserBar.partial.jsx"
import GrafanaFrame from "components/GrafanaFrame/GrafanaFrame.view.jsx";
import grafana_config from "../../controllers/GrafanaConfig/GrafanaConfig.js";
import Controller from "./Trips.controller.jsx"
import TripsPanel from "./TripsPanel/TripsPanel.view.jsx";
import config_data from "./Trips.json"
import "./Trips.css"
import DispatchedTrip from "./DispatchedTrip/DispatchedTrip.view.jsx";
import ViewLoadPanel from "../Loads/ViewLoad/ViewLoadPanel/ViewLoadPanel.view.jsx";
import BillingPage from "../Loads/ViewLoad/BillingPage/BillingPage.view.jsx";
import CotaListPanel from "components/CotaListPanel/CotaListPanel.partial.jsx"

export default class Trips extends _basePanelView {
    constructor(props) {
        super(props)
        this.controller = new Controller({ params: { view: this } })
        this.state.panel_name = "trips"
        this.state.channel_name = "grafana_row"
        this.state.sceen = 0
        this.state.panel_configs = grafana_config()["pages"]["trips"]
        this.state.form = config_data.form_objects
        this.state.panel_trees = [{ key: 0, name: "new_trip", base: 0,
                                    paths: [{ "view_trip": 1, "new_trip": 0 }]},
                                  { key: 1, name: "trip_flow", base: 1,
                                    paths: [{ "view_load": 2, "view_trip": 1, "billing": 3, "new_trip": 0 }]}]
        // this.state.panel_trees      = [{key: 0, name: "new_trip", base: 0},
        //                                {key: 1, name: "view_trip", base: 1}]
        this.state.panels = [{ key: 0, obj: TripsPanel, params: { panel_params: { is_closeable: false, close_title: "Back Home", close_func: "resetScreen", controller: this.controller } } },
                             { key: 1, obj: DispatchedTrip, params: { panel_params: { is_closeable: false, close_title: "Back Home", close_func: "resetScreen", controller: this.controller } } },
                             { key: 2, obj: ViewLoadPanel, params: { panel_params: { is_closeable: false, close_title: "Back to Trip", close_func: "backPanel", controller: this.controller } } },
                             { key: 3, obj: BillingPage, params: { panel_params: { is_closeable: false, close_title: "Back to Load", close_func: "backPanel", controller: this.controller } } }]
        this.state.status_options = []
        this.state.page = {
            status_filter: []
        }
        this.handleProcessEvent = this.handleProcessEvent.bind(this)
    }
    renderMain() {
        // console.log(this)
        return (
            <Container id={this.name} className="generic_100 g-0">
                <Row className="max_height text_color">
                    <Col className="flex_wrapper_rows">
                        <Row>
                            <Col className="flex_wrapper_rows">
                                <CotaUserBar params={{ parent: this, controller: this.controller, title: "Trips" }} />
                            </Col>
                        </Row>
                        <Row className='generic_100 cota_panel_object g-0 add_shadow'>
                            <Col className="generic_100">
                                <Row className="toolbar">
                                    <Col sm={1} className="panel_title">
                                        Trips
                                    </Col>
                                    <Col />
                                    <Col sm={3}>
                                        {this.formObject({ data: this.state.form.status_filter, options: this.state.status_options, owner: this })}
                                    </Col>
                                    {/* <Col sm={3}></Col>
                                    <Col sm={3}>
                                        {this.formObject({ data: this.state.form.search_trips, owner: this })}
                                    </Col> */}
                                    <Col sm={2}>
                                        <Button className={this._theme} onClick={() => this.controller.new_trip()}>
                                            New Trip
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className="grafana g-0">
                                    <Col>
                                        {/* <GrafanaFrame params={{ parent: this,
                                                                panel_configs: this.state.panel_configs,
                                                                panel_name: this.state.panel_name,
                                                                filters: {
                                                                    statuses: JSON.stringify(this.state.page.status_filter)
                                                                }}} 
                                        />*/}
                                        <CotaListPanel params={{
                                            parent: this,
                                            panel_name: this.state.panel_name,
                                            configs: config_data?.panels,
                                            endpoint: "trips",
                                            // req_name: "general",
                                            search_fields: "Cota id",
                                            query: "ftrips",
                                            filters: {
                                                 statuses: this.state.page.status_filter }
                                        }} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}