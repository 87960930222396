//GENERAL REACT MODULES
import React from "react"
import { Row, Col } from "react-bootstrap"

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./TripRow.partial.controller.jsx"
import "./TripRow.css"

export default class TripRow extends _basePartial {
    constructor(props){
        super(props)
        this.partail_controller = new Controller({params: {view: this, controller: this?.controller}})
    }
    render_hr() {
        if(this.state?.index === 0) { return <></> }
        return (
            <Row className="g-0">
                <Col>
                    <hr />
                </Col>
            </Row>
        )
    }
    renderView() {
        this.partail_controller.inject_data({data: this?.state?.data?.trip})
        this.state.data.event = this?.state?.data?.logs?.[0]
        // console.log(this)
        return (<>
            {this.render_hr()}
            <Row id={this.name} className="g-0">
                <Col>
                    <Row className="g-0">
                        <Col sm={2} className="ms-3">
                            {this.partail_controller.trip?.trip_number ?? ""}
                        </Col>
                        <Col>
                            <Row className="g-0">
                                <Col className="truck no_wrap">
                                    {this.partail_controller.truck?.number ?? ""}
                                </Col>
                            </Row>
                            <Row className="g-0">
                                <Col className="trailer no_wrap">
                                    {this.partail_controller.trailer?.number ?? ""}
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={3}>
                            <Row className="g-0">
                                <Col className="no_wrap">
                                    {this.partail_controller.event?.inference ?? ""}
                                </Col>
                            </Row>
                            <Row className="g-0">
                                <Col className="address">
                                    {this.partail_controller.event?.full_address}
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={1} className="status_col">
                            <Row>
                                <Col sm={1} className="status status_col">
                                    {this.partail_controller.trip?.status ?? ""}
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row className="g-0 view_row">
                                {/* NEED to update this on click function to route to dispatched trips */}
                                <Col onClick = {() => {this.partail_controller.view_trip()}} className="view_btn pointer text-center" sm={4}>
                                    View
                                </Col>
                                <Col />
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>)
    }
}