// MODULE SPECIFIC IMPORTS
import _viewController from "controllers/ViewController/_viewController.jsx";
import CarrierModelController from "controllers/ModelControllers/Carrier/CarrierModelController.jsx"
import mutation_request from "assets/graphql/Carriers/Carriers.mutation.graphql.json"

export default class CompanyCreationController extends _viewController {
    constructor(props) {
        super(props)
        this.carrier = new CarrierModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: this,
                                                            view: this.view}})
    }
    async follow_on_selection({event, obj, data}) {
        if (data?.name === "zip") { 
            this.carrier.zip        = obj.zip
            this.carrier.city       = obj.city
            this.carrier.state      = obj.state
            this.carrier.country    = obj.country
        }
        if (data?.name === "address") {
            this.carrier.address    = obj?.address
            this.carrier.address_2  = obj?.address_2
            this.carrier.zip        = obj?.location?.zip
            this.carrier.city       = obj?.location?.city
            this.carrier.state      = obj?.location?.state
            this.carrier.country    = obj?.location?.country
            this.setState({key: "search",
                            param: "zip.selected_value",
                            value: {zip: obj?.location?.zip} })
        }
    }
    map_carrier_data() {
        // WHY do I need this function, seriously should already assign data properly but it is not.
        let data = JSON.parse(JSON.stringify(this.view.state.data.carrier))
        Object.keys(data).map((key, index) => this.carrier[key] = data[key] )
    }
    async submit_action() {
        // console.log(this)
        // let _summary = JSON.parse(JSON.stringify(this.carrier.summary))
        this.controller.map_carrier_data()
        let summary = JSON.parse(JSON.stringify(this.controller.carrier.summary))
        delete summary?.carrier?.address_2
        if(summary?.carrier?.address === undefined) {
            summary.carrier.address = summary.company?.address
        }
        // console.log("SUBMIT::", summary)
        let body = JSON.parse(JSON.stringify(mutation_request))
        let data = this.controller.toUnquotedJSON(summary)
        body.query = body.query.replace("input:", 'input: '+data)
        let sb_config = {success: {show: false}}
	    await this.api.carriers.create({caller: this, params: {body: body}, callback: {f: this.controller?.process_creation, p: {sb: sb_config}}})
        // if(this.company?._sum <= 0) { return }
        // this.api.companies.create({caller: this.controller, value: this.controller.toUnquotedJSON(this.controller.company.summary.scrub()), callback: {f: this.controller.process_creation} })
    }
    process_creation({caller, params, results}) {
        if(results?.errors !== undefined) {
            return
        }
        // let id = results?.data?.setCarrier?.id
        let data = results?.data?.setCarrier
        // console.log("NAME:",data?.name)
        if(data?.name !== undefined){
            data.name = data.name
            caller.controller.view.state.parent.partial_controller.set_company({data: data})
            caller.controller.view.state.parent.setState({claim: true, created: true}) 
        }else{
            data.name = caller.carrier.name
            caller.view.state.parent.partial_controller.set_company({data: data})
            caller.view.state.parent.setState({claim: true, created: true}) 
        }
    }
    cancel_action() {
        this.state.parent.setState({claim: true})
    }
}