export const rowOptions = [
    { value: undefined, label: "Default" },
    { value: "8", label: "8" },
    { value: "16", label: "16" },
    { value: "20", label: "20" },
    { value: "24", label: "24" },
    { value: "40", label: "40" }
];
export const refreshOptions = [
    { value: -1, label: "None" },
    { value: 5, label: "5s" },
    { value: 10, label: "10s" },
    { value: 30, label: "30s" },
    { value: 60, label: "1m" },
    { value: 120, label: "2m" },
    { value: 300, label: "5m" }
];