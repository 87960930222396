// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Container, Button, Card } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx";
import _basePanelView from "components/General/_basePanelView.jsx"
import ModalController from "./PickupStop.modal.controller.jsx";
import StopPickupCardPanel from "../StopPickupCard/StopPickupCard.view.jsx";
import { MultiSelectDropdown } from "components/Dropdowns/Dropdowns";
import { SelectDropdown } from "components/Dropdowns/Dropdowns"
import config_data from "./PickupStop.json"
import pickup_data from '../StopPickupCard/StopPickupCard.json'
import "./PickupStop.modal.css"

export default class PickupStopModal extends _baseModal {
    constructor(props) {
        super(props);
        this.state.form = config_data.form_objects
        this.state.config_data = config_data
        this.state.pickup_data = pickup_data
        this.modal_controller = new ModalController ({params: {view: this}})
        this.state.dims = {width: 636, height: 980}
        
    }

    renderCard = () => {
        const cards = this.state.pickup_data;
        this.state.parent.setState({show: true, _toggled_modal: false})
        const component = cards.map((card, index) => (
          <StopPickupCardPanel
            params={{ parent: this, details: card }}
            key={card.id}
            card={card?.id}
          />
        ))
      }


    renderView() {
        const cards = this.state.pickup_data;
        const component = cards.map((card, index) => (
            this.state.parent.state.component.push(  <StopPickupCardPanel params={{ parent: this, details: card }} key={card.id} card={card?.id} />)
        ));
        const styles = {maxWidth:"45%"};
        const newStyles = { ...styles, ...this._inner_modal_location}
        return (
            <>
            <Col className={"g-0 inner_modal_window cota_panel_border add_shadow modal_style"} ref={(divElement) => {this.divElement = divElement}} style={newStyles} id={this.name}>
                <Col className="cota_sub_title marg_left header_color"> Add Pickup Stop</Col>
                &nbsp;
                <Row className="flex_wrap marg_left">
                    <Row>
                        <Row className="text_color">
                            SHIPPER*
                        </Row>
                        <Row className="input">
                            {this.formObject({data: this.state.form.time_frame, options: undefined, owner: this})}
                        </Row>
                    </Row>
                </Row>
                &nbsp;
                <Row className="flex_wrap marg_left">
                    <Row >
                        <Row className="text_color">
                            SHIPPER CONTACT PERSON
                        </Row>
                        <Row className="input">
                            {this.formObject({data: this.state.form.time_frame, options: undefined, owner: this})}
                        </Row>
                    </Row>
                </Row>
                &nbsp;
                <Row className="flex_wrap marg_left_three">
                    <Row >
                        <Row className="text_color marg_left_one">NOTES</Row>
                        <textarea className="text_area" placeholder="Add specific notes regarding this trip log" />
                    </Row>
                </Row>
                &nbsp;
                <Row style={{marginRight:"1vw"}}>
                    <Col>
                    </Col>
                    <Col sm={3} className="button_min_w g-0">
                        <Button className={"center_button clear_button "+this._theme} onClick={this.toggle_modal?.bind(this.state.parent, {toggle_state: false})} >
                            Cancel
                        </Button>
                    </Col>
                    <Col sm={3} className="button_min_w g-0" onClick={() => this.renderCard()}>
                        <Button className={"center_button primary_button_coloring "+this._theme} onClick={this.toggle_modal?.bind(this.state.parent, {toggle_state: false})} >
                            Save
                        </Button>
                    </Col>
                </Row>
                {/* <Row style={{display:"flex", flexWrap:"nowrap", marginLeft:"45%", width:"60%"}}>
            <Row style={{width:"fit-content"}}>
            <Button onClick={this.toggle_modal?.bind(this.state.parent, {toggle_state: false})} style={{backgroundColor:"white", color:"gray", border:"none"}}>Cancel</Button>
            </Row>
            <Row style={{width:"fit-content"}}>
            <Button onClick={() => this.renderCard()}>Save</Button>
            </Row>
            </Row> */}
            </Col>
            </>
        )
    }
}