export const partnersOptions = [{ value: 'insurance', label: 'Insurance' },
    { value: 'custom_brokers', label: 'Custom Brokers' },
    { value: 'shipping_lanes', label: 'Shipping Lanes' },
    { value: 'importers', label: 'Importers' },
    { value: 'cfs', label: 'CFS' },
    { value: 'wreckers', label: 'Wreckers' },
    { value: 'spaces', label: 'Spaces' }
]

export const integrationsOptions = [{ value: 'accounting', label: 'Accounting' },
    { value: 'loadboards', label: 'Loadboards' }
]