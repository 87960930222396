
// GENERAL REACT MODULES
import { Row, Col } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./AlphaBar.controller.jsx"
import "./AlphaBar.css"

export default class AlphaBar extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this}})
        const alpha     = Array.from(Array(26)).map((e, i) => i + 65);
        this.alphabet   = alpha.map((x) => String.fromCharCode(x));
        this.state.selected = -1
        this.state.hover    = -1
    }
    renderView() {
        return (
            <Row id={this.name} className="g-0">
                <Col className="bar">
                    <Row className="g-0 pointer">
                        {this.alphabet.map((alpha, index) => {
                            return(
                                <Col key={alpha} className={"g-0 center_text "+((index < this.alphabet.length-1) ? "border_right" : "right_ltr")+
                                                    " "+((this.state.hover === index) ? "ltr_hover" : "")+
                                                    " "+((this.state.selected === index) ? "ltr_selected" : "")+
                                                    " "+((index === 0) ? "left_ltr" : "")} 
                                    onClick={this.partial_controller.select_letter.bind(this, index)} onMouseEnter={this.partial_controller.hover_letter.bind(this, index)} onMouseLeave={this.partial_controller.unhover_letter.bind(this, index)}>
                                    {alpha}
                                </Col>
                            )
                        })}
                    </Row>
                </Col>
            </Row>
        )
    }
}
