// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import TripModelController from "controllers/ModelControllers/Trips/TripModelController.jsx"
import EventModelController from "controllers/ModelControllers/Trips/EventModelController.jsx"
import AssetModelController from "controllers/ModelControllers/AssetModelController.jsx"

export default class TripRowController extends _viewController {
    constructor(props) {
        super(props)
        this.trip           = new TripModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true}})
        this.event            = new EventModelController({params: {parent: this,
                                                                    controller: this,
                                                                    is_view: true}})
        this.truck          = new AssetModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true,
                                                                _param: "truck"}})
        this.trailer        = new AssetModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true,
                                                                _param: "trailer"}})
    }
    inject_data({data, params}) {
        if(typeof data === "object") { 
            return Object.keys(data).map((key, index) => {
                if(typeof data[key] === "object" || Array.isArray(data[key])) {
                    this.view.state.data[key] = data[key]
                }
            })
        }
    }
    view_trip() {
        this.view.panel_controller.selectPanel({panel_name: "view_trip", cache_data: {id: this.trip._id}})
    }
}