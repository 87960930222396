// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx";
import CompanyModelController from "controllers/ModelControllers/CompanyModelController";
import update_request from "assets/graphql/Assets/Assets.mutation.graphql.json"

export default class CompanyClaimController extends _viewController {
    constructor(props) {
        super(props)
        this.company = new CompanyModelController({params: {parent: this, 
                                                            controller: this,
                                                            is_view: true}})
    }
    get context() {
        return this.controller.context
    }
    submit_action() {
        let id = this.state.data.company.id
        let value = {}
        if (id !== undefined) {
            value.company_id = id
            this.api.companies.join({caller: this.partial_controller, 
                                        value: this.partial_controller.toUnquotedJSON(value), 
                                        callback: {f: this.partial_controller.process_creation} })
        }
    }
    async confirm() {
        this.controller.view.state.data.user = await this.partial_controller.context.get_self({caller: this.partial_controller, update: true})
        this.controller.view.forceUpdate()
    }
    async process_creation({caller, params, results}) {
        caller.controller.view.state.data.user = await caller.context.get_self({caller: caller, update: true})
        // console.log(caller.company._id )
        //  caller.create_truck(caller.company._id )
        // console.log("force Update")
        caller.controller.view.forceUpdate()
    }
    // async create_truck(id) {
    //     let body = JSON.parse(JSON.stringify(update_request))
    //     let summary = {
    //         truck_number: "1",
    //         asset_type: "TRUCK",
    //         company_id: `${id}`,
    //         asset_number: "1" ,
    //         is_boxtruck: true
    //     }
    //     let data = this.toUnquotedJSON(summary)
    //     body.query = body.query.replace("input:", 'input: '+data)
    //     this.api.assets.create({caller: this, params: {body: body}, callback:{f: this.process_save,}})       
    // }
    async process_save({caller, params, results}) {
        if(results?.errors !== undefined) {
            console.log("Error updating asset", results.errors)
        }
        // console.log("ASSET RESULTS",results)
        // console.log("Asset Updated")
        // let id = (results?.data?.setAsset?.id !== undefined ? results?.data?.setAsset?.id : results?.data?.updateAsset?.id)
        // if (id !== undefined && id !== null) {
        //     if(caller?.controller?.uploader !== undefined) {
        //         await caller.controller.uploaded?.process_queue(id)
        //     }
        //     caller.panel_controller.selectPanel({panel_name: params?.page_key, cache_data: {id: id, type: caller.controller?.asset_type}})
        // }
    }
    process_user_load({caller, params, results}) {
        // CANNOT use this callback since we need to actually await get_self()
        // console.log("--- process user load ---")
        // console.log("force Update")
        // caller.controller.view.forceUpdate()
    }
    cancel_action() {
        delete this.parent.state.created
        this.state.parent.setState({claim: false})
    }
}