export const Measurements = [ 
    {label: "Imperial (lbs/ft)", value: "imperial"},
    {label: "Metric (g/m)", value: "metric"}
];
export const Menu = [ 
    {label: "Open", value: true},
    {label: "Closed", value: false}
];

export const Row_Length = [
    {label:"5", value:"5"},
    {label:"10", value:"10"},
    {label:"15", value:"15"},
    {label:"20", value:"20"},
    {label:"25", value:"25"},
    {label:"50", value:"50"},
]