// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx";
import Controller from "./User.modal.controller.jsx"
import config_data from "./User.modal.json"
import "./User.modal.css"

export default class UserModal extends _baseModal {
    constructor(props) {
        super(props)
        this.modal_controller = new Controller({params: {view: this, controller: this?.controller}})
        this.state.modal_params   = {
            positioned: "middle_of_panel",   
            title: "User Information",                
            has_overlay: true,                 
            close_on_outside_click: true,       
            x_adjustment: "0px",                
            y_adjustment: "0px",
        }
        this.state.form = config_data.form_objects
        this.state.data = {
            user: {}
        }
        this.state.roles = config_data.roles
    }
    get user() {
        return this.modal_controller.user
    }
    get roles() {
        return this.clean_roles(this.controller.roles)
        // return this.controller.roles
    }
    clean_roles(roles) {
        // delete roles.styled
        for(let role of roles) {
            delete role.internal
        }
        return roles
    }
    render_labeled_input({label, data, options}) {
        return (
            <Row className="mb_label_input">
                <Col>
                    <Row className="label_row">
                        <Col className="label">
                            {label}
                        </Col>
                    </Row>
                    <Row>
                        {/* Adding this key allows for rerender of the inputs, removing sticky text */}
                        <Col key={this.state.key_num+label+this.modal_controller.user._id}>
                            {this.formObject({data: data, controller: this.modal_controller, owner: this, options: options, disabled: !this.modal_controller.editable})}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
    render_bottom_btns() {
        if(!this.modal_controller.editable) {
            return (
                <Row>
                    <Col className="flex_end">
                        <Button className={"primary_button_coloring " + this._theme} onClick={this.toggle_modal?.bind(this.state.parent, {toggle_state: false})}>
                            Close
                        </Button>
                    </Col>
                </Row>
            )
        }
        if(this.modal_controller.user?.type === "person") {
            return (
               <Row>
                    <Col className="info_sm">
                        An invite will be sent to the user's email address
                    </Col>
                    <Col>
                        <Row className="flex_no_wrap">
                            <Col sm={6}>
                                <Button className={"clear_button " + this._theme} onClick={this.toggle_modal?.bind(this.state.parent, {toggle_state: false})}>
                                    Cancel
                                </Button>
                            </Col>
                            <Col>
                                <Button className={"primary_button_coloring " + this._theme} onClick={() => this.modal_controller.send_invite()}>
                                    Resend Invite
                                </Button>
                            </Col>
                        </Row>
                    </Col>
               </Row> 
            )
        }
        return (
            <Row>
                <Col className="info_sm">
                    An invite will be sent to the user's email address
                </Col>
                <Col>
                    <Row className="flex_no_wrap">
                        <Col sm={6}>
                            <Button className={"clear_button " + this._theme} onClick={this.toggle_modal?.bind(this.state.parent, {toggle_state: false})}>
                                Cancel
                            </Button>
                        </Col>
                        <Col>
                            <Button className={"primary_button_coloring " + this._theme} onClick={() => this.modal_controller.save()}>
                                {this.render_save_btn_text()}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
    render_save_btn_text() {
        if(this.modal_controller.user.role_status === "Pending" && this.modal_controller.user?._id !== undefined) {
            return "Approve"
        }
        return "Save"
    }
    render_role_dropdown() {
        if(this.roles.length) {
            return (
                <Row className="dropdown_row">
                    <Col>
                        <div className="absolute dropdown_div">
                            {this.formObject({data: this.state.form.roles, owner: this, controller: this.modal_controller, options: this.roles, disabled: !this.modal_controller.editable})}
                        </div>
                    </Col>
                </Row>
            )
        }
    }
    renderView() {
        // console.log(this)
        // console.log(this.modal_controller.user)
        this.modal_controller.get_user_data()
        return (
            <div id={this.name}>
                <Row className="mt-2">
                    <Col key={this.name+this.modal_controller.edit_mode}>
                        <Row>
                            <Col>
                                {this.render_labeled_input({label: "First Name", owner: this, data: this.state.form.given_name})}
                            </Col>
                            <Col>
                                {this.render_labeled_input({label: "Last Name", owner: this, data: this.state.form.family_name})}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                {this.render_labeled_input({label: "Email", owner: this, data: this.state.form.email})}
                            </Col>
                            <Col>
                                {this.render_labeled_input({label: "Phone", owner: this, data: this.state.form.phone})}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mt-4 label title_2">
                                Roles
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <hr />
                            </Col>
                        </Row>
                        {this.render_role_dropdown()}
                        <Row>
                            <Col>
                                <hr />
                            </Col>
                        </Row>   
                        {this.render_bottom_btns()}
                    </Col>
                </Row>
            </div>
        )
    }
}