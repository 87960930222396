// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import StopModelController from "controllers/ModelControllers/StopModelController.jsx"

export default class ShipperPartialController extends _viewController {
    constructor(props) {
        super(props)
        this.details = undefined
        // this.details = new StopModelController({params: {parent: this, 
        //                                            controller: this.view?.controller, _nested: undefined,
        //                                            is_view: true, _param: this.view?.state?.type}})
    }
    load_details() {
        this.details = new StopModelController({params: {parent: this,
                                                    controller: this.view?.controller,
                                                    _nested: undefined,
                                                    index: this.view.props.params.index,
                                                    is_view: true, _param: this.view?.state?.type
                                                }})
        // this.find_contacts(this.details)
    }
    get contact() {
        return this.details?.contact
    }
    get company() {
        return this.details?.contact?.company
    }
    get index() {
        return this.view?.index
    }
    async follow_on_selection({ event, obj, data }) {
        // console.log("SELECTION")
    }
    load_cache() {
        let cache_0 = this.panel_controller?.getCache("0")
        if (cache_0 !== undefined) {
            this.api.contacts.gid({ caller: this, value: cache_0?.data?.id, callback: { f: this.process_loaded_contact, p: { name: this.view?.state?.type } } })
        }
    }
    process_loaded_contact({ caller, params, results }) {
        caller.controller.process_loaded_contact({ caller: caller.controller, params: params, results: results })
    }
    selectRender() {
        let data = this.controller.getState({ key: "data" })?.[this.state?.type + "_details"]
        let contact = this.controller.getState({ key: "data" })?.[this.state?.type]
        this.details._load({ data: data, sub_objects: { contact: contact } })
        // let data = this.controller.getState({key: "data"})?.[this.state?.type]
        if (contact === undefined || Object.keys(contact).length === 0) {
            return this.renderInput()
        }
        return this.renderDetails(this.details)
    }
    process_date({ date, word }) {
        if (date !== undefined) {
            var splitStr = ("" + date + "").split(word);
            return splitStr[0]
        }
        return ""
    }
    editContact() {
        // console.log("NAVIGATING",this.controller.getState({key: "data"})?.[this.state?.type],this.state?.type,this)
        this.panel_controller?.selectPanel({ panel_num: 1, cache_data: { type: this.state?.type, data: this.controller.getState({ key: "data" })?.[this.state?.type] } })
    }
    reset_contact(reasssign) {
        this.view.setState({ new_contact: true })
        // console.log("RESET_CONTACT", this)
        if (this.props?.params?.index === undefined) {
            this.view.controller.setState({ key: "search", param: this.view.state?.type, value: {} })
            this.view.controller.setState({ key: "data", param: this.view.state?.type, value: {} })
        } else {
            let objs = this.view.controller.getState({ key: "data", param: this.view.state?.type })
            objs.splice(this.view.props?.params?.index, 1)
            this.view.controller.setState({ key: "data", param: this.view.state?.type, value: objs })
            this.view.controller.view.forceUpdate()
            this.view.state.parent?.column?.forceUpdate()
        }
        if (reasssign) {
            this.view.state.parent.toggle_modal({ toggle_state: true })
        }
    }
    reset_rep() {
        this.controller[this.state.type].rep = undefined
        this.forceUpdate()

    }
    should_render() {
        let show = false
        if (this?.view?.state?.controller?.load?.status === "Pending" &&
            this?.view?.state?.new_contact !== true &&
            this?.view?.controller?.load?._id &&
            this.view.state.hide !== true
        ) {
            if (this?.view?.state?.[this?.view?.state?.type]?.similar_number) {
                if (this?.view?.state?.[this?.view?.state?.type]?.similar_number > 1) {
                    // console.log("SHOULD BE HERE", this?.view?.state?.[this?.view?.state?.type]?.similar_number)
                    show = true
                }
            } else {
                if (this?.view?.state?.[this?.view?.state?.type]?.[0]?.similar_number) {
                    if (this?.view?.state?.[this?.view?.state?.type]?.[0]?.similar_number > 1) {
                        show = true
                    } 
                }
            }
        }
        return show
    }
    find_contacts(type) {
        // console.log("---- find_contacts ----", type)
        // FOR SOME REASON THIS PARTIAL IS NOT MOUNTED WHEN MAKING REQUESTS 
        // ON STOPS IN THE VIEW LOAD PANEL
        if (type === null || type === undefined) {
            this.view.state.hide = true
            this.view.forceUpdate()
            return
        }
        this.view._isMounted = true
        if (this.view.state.ran.length) {
            return
        } else {
            // console.log("________SENDING-------", type, this, this?._isMounted)
            this.api.contacts.search({ caller: this, value: `${type}`, callback: { f: this.process_contacts } })
        }
        this.view.state.ran.push(type)
    }

    process_contacts({ caller, params, results }) {
        caller.view.state.loading = false
        if (caller.view.state.type !== undefined && results !== undefined) {
            caller.view.state[caller.view.state.type].similar = results
            caller.view.state[caller.view.state.type].similar_number = results?.data?.contacts?.length
            caller.view.forceUpdate()
        }
    }
    select_person(index) {
        this.details.rep = index
        // this.controller[this.state.type].rep = index
        this.toggle_modal({ toggle_state: undefined })
        // Super crappy solution to updating rep on CarrierPro View Load Panel
        if (this?.controller?.view?.name === "ViewLoadPanel") {
            this.controller?.update()
        }
    }
}
