// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button, Container, Card } from "react-bootstrap"
import { FaDollarSign, FaBiohazard, FaExchangeAlt } from "react-icons/fa";
import { CiSquareAlert } from "react-icons/ci";


// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./Load_Details.partial.controller"
import Modal from "module/pages/Loads/ViewLoad/EditLoadModal/EditLoad.modal.jsx"
import CommodityPartial from "./CommodityPartial/Commodity.partial.jsx";
import "./Load_Details.css"

export default class LoadDetails extends _basePartial {
    constructor(props) {
        super(props)
        this._modal = Modal
        this.partial_controller = new Controller({ params: { view: this, controller: this.controller } })
    }
    render_commodities() {
                // making return just one commodity leaving functionaly for more later.
        return (<>
            <Row>
                <Col className="light_font">
                    Commodity
                </Col>
                <Col className="light_font">
                    {this.controller?.commodities?.filter((c) => c.type == null).length == 0 && "Type"}
                </Col>
                <Col className="light_font">
                    {this.controller?.commodities?.filter((c) => c.quantity == null).length == 0 && "Qty"}
                </Col>

                {/* <Col className="light_font">
                    Count
                </Col> */}
                {/* <Col className="light_font">
                    Length
                </Col>
                <Col className="light_font">
                    Weight
                </Col> */}
                <Col sm={6} />
            </Row>
            <Row>
                <Col>
                    {this.controller?.commodities?.map((commodity, index) => {
                        return <CommodityPartial params={{ parent: this, controller: this?.controller, data: commodity, index: index }} />
                    })}
                </Col>
            </Row>
        </>)
    }
    render_special_requirements() {
        if (this.controller.special_requirements?.length) {
            return (
            <>
                <Row>
                    <Col className="light_font">
                        Special Needs
                    </Col>
                </Row>
                {this.controller.special_requirements?.map((requirement, index) => {
                    return (
                        <Row style={{ backgroundColor: "blue" }} key={"requirement_" + index}>
                            <Col>
                                {requirement?.name}
                            </Col>
                        </Row>
                    )
                })}
            </>)
        }
    }
    render_dimensions() {
        return (<>
            <Row>
                <Col sm={2} />
                <Col>
                    <Row>
                        <Col sm={2}>
                            {this.controller.load.total_length != null &&
                                <>
                                    <Row>
                                        <Col className="light_font">
                                            Total Length
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="units">
                                            {Math.ceil(this.controller.load.total_length / 12)} ft
                                        </Col>
                                    </Row>
                                </>}
                        </Col>
                        <Col>
                            {this.controller.load.total_weight != null &&
                                <>
                                    <Row>
                                        <Col className="light_font">
                                            Total Weight
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="units">
                                            {/* {this.controller?.commodities_dims.weight} lbs */}
                                            {this.controller?.load.total_weight} lbs
                                        </Col>
                                    </Row>
                                </>}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>)
    }
    render_fp_load(){
        return (
            <Row id={this.name}>
                <Col className="card_window cota_panel_object_unsized cota_panel_border">
                    <Row>
                        <Col>
                            <Row>
                                <Col sm={2} className="title">
                                    <Row>
                                        <Col className="d-flex justify-content-start">
                                            Load {this.controller?.load?.local_id}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="cota_id_text d-flex justify-content-start">
                                            {(this.controller?.load?.cota_id !== undefined &&
                                            this.controller?.load?.cota_id !== null &&
                                            this.controller?.load?.cota_id !== "") ? this.controller?.load?.cota_id : ""}
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                <Col />
                                <Col className="status_badge_col">
                                    <Button size="sm" className={"status_button "+this.controller.load?.status}>
                                        {this.controller.load?.status}
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="mt-1">
                                <Col className="std_font">
                                    <Row className="top_row">
                                        <Col sm={2}>
                                            <div className="dollar_badge">
                                                <FaDollarSign size={70} className="dollar_sign"/>
                                            </div>
                                        </Col>
                                        <Col>
                                            {this.render_commodities()}
                                        </Col>
                                    </Row>
                                    {this.render_dimensions()}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
    render_equipment(type) {
        if(this.controller?.[`${type}_objects`]?.filter((c) => c.label !== null && c.label !== undefined)?.length) {
            return (
                <Col>
                    {this.controller?.[`${type}_objects`]?.map((obj)=>{
                        return <div>{obj.label}</div>
                    })}
                </Col>
            )
        }
    }
    get_commodity_data() {
        this.state.data.commodity = this.controller?.commodities?.[0]
    }
    render_cp_load() {
        this.get_commodity_data()
        return (
            <Row id={this.name}>
                <Col className="card_window cota_panel_object_unsized cota_panel_border">
                    <Row>
                        <Col>
                            <Row>
                                <Col sm={2} className="title">
                                    <Row>
                                        <Col className="d-flex justify-content-start">
                                            Load {this.controller?.load?.local_id}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="cota_id_text d-flex justify-content-start">
                                            {(this.controller?.load?.cota_id !== undefined &&
                                            this.controller?.load?.cota_id !== null &&
                                            this.controller?.load?.cota_id !== "") ? this.controller?.load?.cota_id : ""}
                                        </Col>
                                    </Row>
                                    
                                </Col>
                                <Col />
                                <Col className="status_badge_col">
                                    <Button size="sm" className={"status_button " + this.controller.load?.status}>
                                        {this.controller.load?.status}
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="mt-1">
                                <Col className="std_font">
                                    <Row className="top_row">
                                        <Col sm={2}>
                                            <div className="dollar_badge">
                                                <FaDollarSign size={70} className="dollar_sign" />
                                            </div>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col className="light_font">
                                                    Commodity Description
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    {this.partial_controller.commodity.name}
                                                </Col>
                                            </Row>
                                            <Row className="weight">
                                                <Col>
                                                    <Row>
                                                        <Col className="light_font">
                                                            Weight
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            {this.partial_controller.commodity.weight ? `${this.partial_controller.commodity.weight} lbs` : "-"}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className="value">
                                                    <Row>
                                                        <Col className="light_font">
                                                            Value
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                        {this.partial_controller.commodity.value ? `$${this.partial_controller.commodity.value}` : "-"}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={2}>
                                                    <Row>
                                                        <Col className="light_font">
                                                            Length
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            {this.partial_controller.commodity.length_in_feet ? `${this.partial_controller.commodity.length_in_feet} ft` : "-"}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={2} className="width">
                                                    <Row>
                                                        <Col className="light_font" xs={2}>
                                                            Width
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            {this.partial_controller.commodity.width_in_feet ? `${this.partial_controller.commodity.width_in_feet} ft` : "-"}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={2}>
                                                    <Row>
                                                        <Col className="light_font">
                                                            Height
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            {this.partial_controller.commodity.height_in_feet ? `${this.partial_controller.commodity.height_in_feet} ft` : "-"}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={6}></Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Row>
                                                        <Col className="light_font">
                                                            Trailer Type(s)
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        {this.render_equipment("trailer_type")}
                                                    </Row>
                                                </Col>
                                                <Col>
                                                    <Row>
                                                        <Col className="light_font">
                                                            Specialized Equipment
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        {this.render_equipment("special_requirements")}
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="pad_top_1rem">
                                                {this.controller?.information?.container_number &&
                                                <Col>
                                                    <Row>
                                                        <Col className="light_font">
                                                            Container Number
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            {this.controller?.information?.container_number ? `${this.controller?.information?.container_number}`  : "-"}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                }
                                                {this.controller?.load?.is_hazardous && 
                                                <Col className="flex_centered">
                                                    <FaBiohazard/> Hazmat
                                                </Col>}
                                                {this.controller?.load?.is_oversize &&
                                                <Col className="flex_centered">
                                                    <CiSquareAlert/> Oversized
                                                </Col>
                                                } 
                                            </Row>
                                            {/* <Row>
                                                {(this.controller.load.temperature_from || this.controller.load.temperature_to) &&
                                                <Col>
                                                    <Row>
                                                        <Col className="light_font">
                                                            Temperature Range, F
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Row>
                                                                <Col xs="auto">
                                                                    {this?.controller?.load?.temperature_from
                                                                    ? `${this?.controller?.load?.temperature_from}°`
                                                                    : ""}
                                                                </Col>
                                                                <Col xs={1}> - </Col>
                                                                <Col xs="auto">
                                                                {this?.controller?.load?.temperature_to
                                                                    ? `${this?.controller?.load?.temperature_to}°`
                                                                    : ""}
                                                                </Col>
                                                                <Col></Col>
                                                            </Row>
                                                        </Col>
                                                        <Col>
                                                            
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                }
                                                <Col>
                                                    <Row>
                                                        <Col className="light_font">
                                                            Number of Cases
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            {this.controller?.load?.cases
                                                                    ? `${this.controller?.load?.cases}`
                                                                    : "-"}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row> */}
                                            <Row>
                                                <Col>
                                                    <Row>
                                                        <Col className="light_font">
                                                            Number of Pallets
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            {this.controller?.load?.pallet_number ? `${this?.controller?.load?.pallet_number}` : "-"}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                {this.controller?.load?.pallet_exchange && 
                                                <Col className="flex_centered">
                                                    <FaExchangeAlt /> Pallet Exchange
                                                </Col>}
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        </Col>
                        <Col sm={2}>
                            <Button onClick={() => this.toggle_modal({toggle_state: true, controller: this.partial_controller})} className="clear_button wide_button">
                                Edit Load Details
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
    renderView() {
        if(this.controller.load.created_on == "freightpro") { 
            return this.render_fp_load()   
        } 
        return this.render_cp_load()
    }
}