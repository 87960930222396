// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import UserModelController from "controllers/ModelControllers/UserModelController.jsx"
import PersonModelController from "controllers/ModelControllers/PersonModelController.jsx"

import user_update from "assets/graphql/Users/user.update.mutation.graphql.json"
import invite_request from "assets/graphql/Email/Email.graphql.json"
import invite from "assets/graphql/Invites/invite.driver.graphql.json"

export default class UserModalController extends _viewController {
    constructor(props) {
        super(props)
        this.user = new UserModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true}})
    }
    get editable() {
        return this.controller.editable
    }
    get edit_mode() {
        return this.controller.view.state.edit_user
    }
    user_check() {
        let user = JSON.parse(JSON.stringify(this.view.state?.data?.user))
        let parent_user = this?.controller?.view?.state?.selected
        if(parent_user === undefined) return false
        if(JSON.stringify(user) === "{}") return true 
        else if(parent_user?.id !== user?.id) return true 
        return false
    }
    get_user_data() {
        if(this.user_check() && this.edit_mode) {
            let user = this?.controller?.view?.state?.selected
            if(user === undefined) { return }
            user = JSON.parse(JSON.stringify(user))
            this.view.state.data.user = user
        }
        this.refresh_user()
    }
    refresh_user() {
        let state = this?.controller?.view?.state
        if(JSON.stringify(this.user.data) === "{}") return
        if(!state?._toggled_modal) {
            this.delete_data({obj: this.view.state.data.user})
        }
    }
    delete_data({obj}) {
        if(typeof obj === "object") {
            Object.keys(obj).map((key) => { return delete obj[key] })
        }
    }
    save() {
        if(this.edit_mode) {
            return this.update_user()        
        }
        return this.send_invite()
    }
    process_save({caller, params, results}) {
        if(results?.errors !== undefined) {
            console.log(results?.errors)
            return 
        }
        caller.view.state.parent.toggle_modal({toggle_state: false})
    }
    update_user() {
        let summary = JSON.parse(JSON.stringify(this.user.summary))
        let data = this.toUnquotedJSON(summary)
        let body = JSON.parse(JSON.stringify(user_update))
        body.query = body.query.replace("input:", "input: "+data)
        let sb_config = {error: {msg: "Error saving user information."}}
        this.api.users.ask({caller: this, params: {body: body}, callback: {f: this.process_save, p: {sb: sb_config}}})
    }
    send_invite() {
        if(!this._notnull(this.user.email)) return
        console.log("--- SEND INVITE ---")
        let _user = JSON.parse(JSON.stringify(this.user.summary))
        let role = {code: this.user.format_role(this.user.assigned_role)?.code}
        delete _user.roles
        _user.email = _user.email.email
        _user.first_name = _user.given_name
        _user.last_name = _user.family_name
        let person = new PersonModelController({params: {parent: this, controller: this, is_view: true, _state: {data: _user}}})
        let body = undefined
        if (invite_request !== undefined) {
            body = JSON.parse(JSON.stringify(invite))
            let _summary = {
                person: person?.summary,
                company_id: this.controller.company_id,
                role: role
            }
            let data = this.toUnquotedJSON(_summary)
            body.query = body.query.replace("input:", 'input: '+data)
            this.api.invite.create({caller: this, params: {body: body}, callback: {f: this.process_invite}})
        }
    }
    process_invite({caller, params, results}) {
        if(results?.errors !== undefined) {
            console.log(results.errors)
            return
        }
        caller.view.forceUpdate()
        caller.view.state.parent.toggle_modal({toggle_state: false})
    }
}