// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class ColumnController extends _viewController {
    constructor(props) {
        super(props)
        this.panels     = []
        this.column     = undefined
        this.seperator  = undefined
        this.state = {
            data: {},
            rows: [],
            seperator_status: []
        }
    }
    get _rows() {
        return this.state?.rows
    }
    set _rows(value) {
	this.state.rows = value
    }
    get _filtered_rows() {
        return this.state?.rows.filter(e => e)
    }
    get _data() {
        return this.state?.data
    }
    generate_rows() {

    }
    check_rows() {
        this.panels.map((panel, index) => {
            this.state.seperator_status[index] = panel._partial_data
            return
        })
    }
    row_states() {
        this.check_rows()
        return this.state.seperator_status
    }
    check_row_status() {
        return undefined
    }
}
