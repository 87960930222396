// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";
import { BiCheck } from "react-icons/bi"
 
// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"


export default class InvoiceModalController extends _viewController {
    constructor(props) {
        super(props)
    }
    render_invoices() {
        let invoice_list = this.view.controller.view.state.invoice_list
        let invoices = invoice_list.map((invoice, index) => (
            <Row key={index}>
                <Col>
                   <BiCheck size={30} /> Invoice ID: {invoice.label}
                </Col>
            </Row>
        ))
        return invoices
    }
}