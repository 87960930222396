import _viewController from "controllers/ViewController/_viewController"
import ContactModelController from "controllers/ModelControllers/ContactModelController.jsx"
import company_request from "assets/graphql/Companies/Company.graphql.json"
import mutation_request from "assets/graphql/Contacts/Contacts.mutation.graphql.json"
import update_request from "assets/graphql/Contacts/Contacts.update.graphql.json"

export default class EditContactModalController extends _viewController{
    constructor(props) {
        super(props)
        this.contact = new ContactModelController({params: {parent: this,          
                                                            controller: this,
                                                            is_view: true}})
    }
    handleCloseScreen() {
        this.resetScreen()
    }
    handleRoleClick(event) {
        let text = event.target.textContent.toLowerCase()
        this.setState(prevState => ({
            [text]: prevState?.[text] === "" ? text : ""
        }))
        this.modal_controller.toggle_role(text)
    }
    toggle_role(text) {
        this.contact[text] = !this.contact?.[text]
        this.view.forceUpdate()
    }
    follow_on_selection({event, obj, data}) {
        if (data?.name === "zip") { 
            this.contact.zip        = obj.zip
            this.contact.city       = obj.city
            this.contact.state      = obj.state
            this.contact.country    = obj.country
        }
        if (data?.name === "address") {
            this.contact.address    = obj?.address
            this.contact.address_2  = obj?.address_2
            this.contact.zip        = obj?.location?.zip
            this.contact.city       = obj?.location?.city
            this.contact.state      = obj?.location?.state
            this.contact.country    = obj?.location?.country
            this.setState({key: "search",
                            param: "zip.selected_value",
                            value: {zip: obj?.location?.zip} })
        }
        if (obj?.id !== undefined) {
            if(data?.name === "company") {
                let body    = JSON.parse(JSON.stringify(company_request))
                body.query  = body.query.replace("company", 'company(id: "'+obj?.id+'")')
                this.api.companies.ask({caller: this, params: {body: body}, nocache: true, callback: {f: this.process_company}})
            }
            if(data?.name === "contact") {
                this.get_contact({id: obj?.id, name: data?.name})
            }
        }
    }
    process_company({caller, params, results}) {
        caller.setState({key: ["search",
                                "search",
                                "data"],
                            param: ["address.selected_value",
                                    "zip.selected_value",
                                    "company"],
                            value: [{address: results?.data?.company?.address?.address},
                                    {zip: results?.data?.company?.address?.location?.zip},
                                    results?.data?.company]})
    }
    get_contact({id, name}) {
        this.api?.contacts?.gid({caller: this, value: id, callback: {f: this.process_loaded_contact, p: {name: name}}})
    }
    process_loaded_contact({caller, params, results}) {
        let address = results?.data?.contact?.address
        if(address === null || address === undefined) {
            address = results?.data?.contact?.company?.address
        }
        caller.setState({key: ["search", "search", "search", "data"],
                            param: ["address.selected_value",
                                    "zip.selected_value",
                                    "company.selected_value",
                                    "contact"],
                            value: [{address: address?.address},
                                    {zip: address?.location?.zip},
                                    results?.data?.contact?.company,
                                    results?.data?.contact]})
    }
    async save_action() {
        let body = undefined
        let summary = JSON.parse(JSON.stringify(this.contact.summary))
        let reform_mode = false
        if(summary?.contact?.id === "reform") {
            summary.contact.address = this.handle_reform_address(summary.contact?.address)
            reform_mode = true
        }
        if(summary?.company?.id === "reform") {
            summary.company.address = this.handle_reform_address(summary.company?.address)
        }
        if(this?.view?.parent?.name === "PayorCard") {
            let company = JSON.parse(JSON.stringify(summary.company))
            let contact = JSON.parse(JSON.stringify(summary.contact))
            contact.company = summary.company
            this.handle_inject_payor({contact: contact, company: company})
            return
        }
        if(reform_mode) {
            let company = JSON.parse(JSON.stringify(summary.company))
            let contact = JSON.parse(JSON.stringify(summary.contact))
            this.handle_inject_reform({contact: contact, company: company})
            return
        }
        // either create or update the contact.
        if (summary?.contact?.id === undefined || summary?.contact?.id === null) {
            body    = JSON.parse(JSON.stringify(mutation_request))
        } else {
            body    = JSON.parse(JSON.stringify(update_request))
        }
        // Need to figure out how to resolve these in the API.
        delete summary.company?.image
        delete summary.company?.website
        let data = this.toUnquotedJSON(summary)
        body.query = body.query.replace("input:", 'input: '+ data)
        await this.api.contacts.create({caller: this, params: {body: body}, callback: {f: this.inject_data}})
    }
    inject_data({caller, params, results}) {
        let obj = ""
        // set contact to the results of either the update or create mutation
        let contact = (results?.data?.setContact?.id !== undefined) ? results?.data?.setContact : results?.data?.updateContact
        if (contact?.id !== undefined && contact?.id !== null) {
            // set company to the company data associated with the contact
            let company = contact?.company
            // check if the caller's controller has an array of stops, else do something else.
            if(caller?.view?.controller?.view?.state?.data?.stops?.length > 0){
                // filter out the stops where the contact_id exists.
                let filtered = caller.view.controller.view.state.data.stops.filter((stop) => stop?.contact?.id !== undefined);
                // set the caller's stops equal to the filtered stops.
                caller.view.controller.view.state.data.stops = filtered
                // create an object for a stop using the contact and company.
                obj = { company: company, contact: contact }
                // push the obj to the caller's array of stops.
                caller.view.controller.view.state.data.stops.push(obj)
                // set obj equal to the caller's array of stops.
                obj = caller.view.controller.view.state.data.stops
            } else {
                // I dont think this else statement makes any sense.
                // randomly filter out the stops with a contact_id when the caller does not have an array of stops??
                caller.view.controller.view.state.data.stops.filter((stop) => stop?.contact?.id !== undefined);
                // set object to an array with a stop object with inaccessible contact and company data??
                obj = [{ company: company, contact: contact }]
            }
            // console.log("OBJ2",obj)
            // set the caller's state at whatever object it is editing (pickup, dropoff, stop...).
            caller.view.controller.setState({key: "data", param: caller.view.type, value: obj})
        }
        // call the object who owns the modal's toggle_modal method.
        caller.close_modal()
    }
    handle_inject_payor({contact, company}) {
        // NEED TO HIDE THE SIMILAR CONTACTS
        company = JSON.parse(JSON.stringify(company))
        if(typeof company?.email === "object") {
            company.email = company.email.email
        }
        if(typeof company?.phone === "object") {
            company.phone = company.phone.area_code + company.phone.phone_number
        }
        let payor_obj = company
        payor_obj.contact = contact
        payor_obj.name = contact?.name ?? company?.name
        payor_obj.address = contact?.address ?? company?.address
        this.view.controller.setState({key: "data", param: "company", value: payor_obj})
        this.view.parent.state.new_contact = true
        this.reset_data()
        this.close_modal()
    }
    handle_inject_reform({contact, company}) {
        // Has to format it to stop data, so NEEDS to grab existing stop id if the stop exists already.
        contact = JSON.parse(JSON.stringify(contact))
        let stop_obj = {
            address: contact?.address ?? company?.address,
            name: contact?.name ?? company?.name
        }
        stop_obj.company = JSON.parse(JSON.stringify(company))
        contact.company = company
        stop_obj.contact = contact
        stop_obj.id = "reform"
        let type = this.view.parent?.type
        this.view.controller.setState({key: "data", param: type, value: stop_obj})
        this.reset_data()
        this.close_modal()
    }
    cancel_action() {
        this.close_modal()
        this.reset_data()
    }
    close_modal() {
        if(typeof this.view.state.parent.reset_modal === "function") {
            this.view.state.parent.reset_modal()
        } else {
            this.view.state.parent.toggle_modal({toggle_state: false})
        }
    }
    reset_data() {
        this.view.setState(prevState => ({reset: !prevState.reset}))
        delete this.view.state.data?.company
        delete this.view.state.data?.contact
        delete this.view.state.search
    }
    set_search(obj) {
        this.view.state.search = {
            address: {selected_value: obj?.address},
            zip: {search_value: obj?.address?.location?.zip},
            company: {search_value: obj?.name}
        }
    }
    handle_reform_address(address) {
        if(!this?._notnull(address)) { return }
        if(!Object.keys(address)?.length) { return }
        if(address.address?.includes(",")) {
            let _split = JSON.parse(JSON.stringify(address.address)).split(",")
            address.address = _split[0]
            address.address_2 = _split[1].trim()
        }
        return address
    }
}