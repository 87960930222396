// GENERAL REACT MODULES
import { Container, Row, Col, Card, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES

import Map from "module/pages/Map/map.view";
import Controller from "./ViewLoadPanel.controller";
import "./ViewLoadPanel.css";
import MultiPanel from "components/MultiPanels/MultiPanel";
import PickupStopModal from "../AddPickupStop/PickupStop.modal";
import DeliveryStopModal from "../DeliveryStop/DeliveryStop.modal.jsx"

import LoadDetails from "./Partials/Load_Details/Load_Details.partial.jsx";
import CotaUserBar from "components/CotaUserBar/CotaUserBar.partial.jsx"
import CompanyCard from "./Partials/CompanyCard/CompanyCard.partial";
import PaymentDetails from "./Partials/PaymentDetails/PaymentDetails.partial";
import PayorCard from "./Partials/CompanyCard/PayorCard/PayorCard.partial";
import DocumentsPanel from "./Partials/Documents/Documents.partial";
import NotesPanel from "./Partials/Notes/NotesPanel.partial";
import AddStop from "./Partials/AddStop/AddStop.partial.jsx"
import StatusButtonsPartial from "./Partials/StatusButtons/StatusButtons.partial";
import config_data from "./ViewLoadPanel.json"
import Modal from "./Partials/PendingModal/PendingModal.jsx"
import CancelLoadButton from "./Partials/CancelLoadButton/CancelLoadButton.partial.jsx";
import DeleteLoadButton from "./Partials/DeleteLoadButton/DeleteLoadButton.partial.jsx";

export default class ViewLoadPanel extends MultiPanel {
    constructor(props) {
        super(props)
        this._modal = Modal
        this.custom_canvas = true
        this.state.screen = 0
        this.state.form         = config_data.form_objects
        // this.state.panel_trees = [{ key: 0, name: "map", base: 0 }]
        // this.state.panels = [{ key: 0, obj: Map, params: { panel_params: { is_closeable: true, close_title: "Back Home", close_func: "resetScreen" } } }]
        this.handleProcessEvent = this.handleProcessEvent.bind(this)
        this.controller = new Controller({ params: { view: this } })
        this.state.states = []
        this.state.trailer_types = []
        this.state.special_requirements = []
        this.state.statuses = []
        this.state.line_items = []
        this.state.data = { 
            line_item: {},
            line_items: [],
            commodities: [],
            notes: [],
            stops: [],
            shipment: {
                rate_items: []
            },
            equipment: [] 
        }
        this.state.component = []
        this.state.stops = {
            pickup: [],
            dropoff: []
        }
        this.state.updated = false
        this.state.edit = false
        this.state.show = false
    }
    get stops() {
        // remove any duplicated (from rendering issue) and sort by stop index
        let log_stops = JSON.parse(JSON.stringify(this.state.data.stops))
        let _stops = log_stops.filter(el => el !== null && el !== undefined)
        _stops = Array.from(new Set(log_stops.map(JSON.stringify))).map(el => {return JSON.parse(el)})
        return _stops.sort((a, b) => a?.index - b?.index)
    }
    toggleModalFunction = () => {
        this.toggle_modal({ toggle_state: true });
    };

    toggleDeliveryModal = () => {
        this.toggle_modal({ toggle_state: true })
    };

    togglePendingModal = () => {
        if(this.state.data.shipment.status === "Pending" && !this.state.ran){
            this.toggle_modal({ toggle_state: true })
            this.state.ran = true
        }
            };

    renderDelivery = () => {
        const deliveryCards = this.state.delivery_data;
        const deliveryComponent = deliveryCards.map((deliveryCard, index) => (
            <DeliveryStopModal
            params={{ parent: this, details: deliveryCard }}
            key={deliveryCard.id}
            deliveryCard={deliveryCard?.id}
            onClick={() => { }}
            />
        ));
        return deliveryComponent;
    }

    handleDelivery = () => {
        this.toggleDeliveryModal();
        this.renderDelivery();
    }

    renderCard = () => {
        const cards = this.state.pickup_data;
        const component = cards.map((card, index) => (
            <PickupStopModal
            params={{ parent: this, details: card }}
            key={card.id}
            card={card?.id}
            onClick={() => {
                // handle click event here
            }}
            />
        ));
        return component;
    };

    handleCardRender = () => {
        this.toggleModalFunction();
        this.renderCard();
    };
    render_stop_line() {
        return (
            <Row className="add">
                <Col className="stops_line" sm={2}/>
            </Row>
        )
    }
    render_add_stops() {
        if(this?.controller?.load.created_on == "carrierpro") {
            return (<>
                {
                <AddStop params={{ parent: this, controller: this.controller, data: { label: "Add Pickup Stop", type: "pickup" } }} />
                }
                {
                <AddStop params={{ parent: this, controller: this.controller, data: { label: "Add Dropoff Stop", type: "dropoff" } }} />
                }
            </>)
        }
        return (<></>)
    }
    render_delete_load() {
        if(this.controller.editable) {
            return <DeleteLoadButton params={{parent: this, controller: this.controller}}/>
        }
    }
    render_cancel_load() {
        if(this.controller.editable) {
            return <CancelLoadButton params = {{parent: this, controller: this.controller}}/>
        }
    }
    
    renderInnerFrame() {
    console.log(this.controller)
    this.togglePendingModal()
    return (
        <Container id={this.name} className="generic_100 g-0">
            <Row className="max_height">
                <Col className="flex_wrapper_rows">
                    <CotaUserBar params={{ parent: this, controller: this.controller, title: "loads", return: true }} />
                    <Row className="generic_100 margin_auto">
                        <Col className="pad_right">
                            <Row>
                                <Col>
                                    <LoadDetails params={{ parent: this, controller: this.controller }}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    &nbsp;
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <CompanyCard params={{ parent: this, controller: this.controller, role: "shipper" }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                {/* {typeof this.state.stops  === "object" ? this.state.stops = [this.state.stops]: this.state.stops = this.state.stops} */}
                                    {/* {this.state.data.stops.map((stop, index) => { */}
                                    {this.stops.map((stop, index) => {
                                        if(stop?.contact?.id === undefined || stop?.contact?.id === null) { return }
                                        return (<>
                                            {this.render_stop_line()}
                                            <CompanyCard params={{ parent: this, controller: this.controller, role: "stops", index: index, stop_type: stop?.stop_type?.toLowerCase() }} />
                                        </>)
                                    })}
                                </Col>
                            </Row>
                            <Row className="add">
                                <Row>
                                    <Col>
                                        <Row>
                                        <Col>
                                            &nbsp;
                                        </Col>
                                        <Col className="stop_line" sm={2}>
                                            {/* {this.state.stops.pickup.map((stop, index) => {
                                                return ( <></> )
                                            })}
                                            {this.state.stops.dropoff.map((stop, index) => {
                                                return ( <></> )
                                            })} */}
                                            {this.render_add_stops()}
                                            {/* {
                                            <AddStop params={{ parent: this, controller: this.controller, data: { label: "Add Pickup Stop", type: "pickup" } }} />
                                            }
                                            {
                                            <AddStop params={{ parent: this, controller: this.controller, data: { label: "Add Dropoff Stop", type: "dropoff" } }} />
                                            } */}
                                        </Col>
                                        <Col>
                                            &nbsp;
                                        </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Row>
                            <Row>
                                <Col>
                                    <CompanyCard params={{ parent: this, controller: this.controller, role: "consignee" }} />
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={3} className="pad_left left_column">
                            <StatusButtonsPartial params={{ parent: this, controller: this.controller }}/>
                            <Row>
                                <Col>
                                <Row>
                                    <Col>
                                        <PaymentDetails params={{ parent: this, controller: this.controller }} />
                                    </Col>
                                </Row>
                                </Col>
                            </Row>
                            <Row>
                                &nbsp;
                            </Row>
                            <Row>
                                <Col>
                                <Row>
                                    <Col>
                                        <PayorCard params={{ parent: this, controller: this.controller }} />
                                    </Col>
                                </Row>
                                </Col>
                            </Row>
                            <Row>
                                &nbsp;
                            </Row>
                            <Row>
                                <Col>
                                <Row>
                                    <Col>
                                        <DocumentsPanel params={{parent: this, controller: this.controller, editable: true}}/>
                                    </Col>
                                </Row>
                                </Col>
                            </Row>
                            <Row>
                                &nbsp;
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <NotesPanel params={{ parent: this, controller: this.controller }} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="pad_top_1rem">
                                <Col className="g-0 pad_top_1rem">
                                    {this.render_cancel_load()}
                                </Col>
                            </Row>
                            <Row>
                                <Col>&nbsp;</Col>
                            </Row>
                            {this.render_delete_load()}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
        );
    }
}
