import React from "react";
import { Container, Row, Col, ToggleButton, ToggleButtonGroup, Card, Button } from 'react-bootstrap'
import "./Contacts.css"

// import "./Contacts.css"
import _basePanelView from "components/General/_basePanelView.jsx"
import CotaUserBar from "components/CotaUserBar/CotaUserBar.partial.jsx"
import ViewContactPanel from "./ViewContact/ViewContactPanel.jsx";
import NewContactPanel from "./NewContact/NewContactPanel.jsx";
import GrafanaFrame from "components/GrafanaFrame/GrafanaFrame.view.jsx";
import CotaListPanel from "components/CotaListPanel/CotaListPanel.partial.jsx"
import config_data from "./ViewContact/ContactPanel.json"
import Controller from "./Contacts.controller"
import Modal from "./NewContact/NewContactModal/NewContact.modal.jsx"
import AlphaBar from "components/AlphaBar/AlphaBar.partial.jsx"
import grafana_config from "../../controllers/GrafanaConfig/GrafanaConfig.js";
import list_config from "./Contacts.json"

export default class ContactsPanel extends _basePanelView {
    constructor(props) {
        super(props)
        this.controller             = new Controller({ params: { view: this } })
        this._modal                 = Modal
        this.state.form             = config_data.form_objects
        this.state.config_data      = config_data
        this.state.screen           = 0
        // this.state.panelId          = 2
        this.state.panel_name       = "contacts"
        this.state.channel_name     = "grafana_row"
        this.state.panel_configs    = grafana_config()["pages"]["contacts"]
        this.state.user_options     = [{ value: "", label: "All" }]
        this.state.panel_trees      = [{ key: 0, name: "view_contact", base: 0},
                                       { key: 1, name: "new_contact", base: 1 }]
        this.state.panels           = [{ key: 0, obj: ViewContactPanel, params: { panel_params: { is_closeable: false, close_title: "Back Home", close_func: "resetScreen", controller: this.controller } } },
                                       { key: 1, obj: NewContactPanel, params: { panel_params: { is_closeable: true, close_title: "Back Home", close_func: "resetScreen", controller: this.controller } } }]
        this.state.role_filter      = ["payor", "shipper", "consignee"]
        this.state.contact_filter   = ""
        this.state.contacts         = []
        this.state.contries         = []
        this.state.state            = []
        this.state.shipper          = "shipper"
        this.state.consignee        = "consignee"
        this.state.payor            = "payor"
        this.handleProcessEvent = this.handleProcessEvent.bind(this)
    }
    followDidMount() {
        this.mount_listener()
    }
    render_filter_by_user() {
        return (
            <Col>
                {this.formObject({  data: this.state.form.contacts_filter, 
                                    options: this.state.user_options, 
                                    owner: this})}
            </Col>
        )
    }
    renderMain() {
        return (
            <Container id={this.name} className="generic_100 g-0">
                <Row className="max_height text_color">
                    <Col className="flex_wrapper_rows">
                        <Row>
                            <Col className="flex_wrapper_rows">
                                <CotaUserBar params={{parent: this, controller: this.controller, title: "Contacts"}} />
                            </Col>
                        </Row>
                        <Row className="generic_100 g-0 cota_panel_object add_shadow ">
                            <Col className="generic_100">
                                <Row className="toolbar">
                                    <Col sm={2} className="panel_title">
                                        Contacts
                                    </Col>
                                    <Col>&nbsp;</Col>
                                    <Col sm={3}>
                                        {
                                            this.render_filter_by_user()
                                        }
                                    </Col>
                                    <Col sm={2} className="max_width_11">
                                        <Button className={this._theme} variant="primary" onClick={() => this.controller.newContactButton()}>
                                            New contact
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className="tools_row_2">
                                    <Col sm={4} className="tools_col_1">
                                        <Row>
                                            <Col className="contact_roles muted_text">
                                                Filter roles:
                                            </Col>
                                        </Row>
                                        <Row className="badge_group">
                                            <Col className="badge_col">
                                                <div className={`btn_role ${this.state.shipper}`} onClick={this.controller.handleRoleClick.bind(this)}>
                                                    SHIPPER
                                                </div>
                                            </Col>
                                            <Col className="badge_col">
                                                <div className={`btn_role ${this.state.consignee}`} onClick={this.controller.handleRoleClick.bind(this)}>
                                                    CONSIGNEE
                                                </div>
                                            </Col>
                                            <Col className="badge_col">
                                                <div className={`btn_role ${this.state.payor}`} onClick={this.controller.handleRoleClick.bind(this)}>
                                                    PAYOR
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>&nbsp;</Col>
                                    <Col className="alpha_center">
                                        <Row className="alpha">
                                            <Col>
                                                <AlphaBar params={{parent: this.view, controller: this.controller}}/>
                                            </Col>
                                        </Row>
                                    </Col>
                        </Row>
                        <Row className="grafana">
                            <Col>
                                {/* <GrafanaFrame params={{ parent: this, 
                                                        panel_configs: this.state.panel_configs, 
                                                        panel_name: this.state.panel_name,
                                                        filters: {
                                                            roles: JSON.stringify(this.state.role_filter),
                                                            name: this.controller.user_filter
                                                        }}}
                                /> */}
                                <CotaListPanel params={{parent: this, 
                                                        panel_name: this.state.panel_name,
                                                        configs: list_config.panels,
                                                        endpoint: "contacts",
                                                        req_name: "flist",
                                                        query: "fcontacts",
                                                        filters: {
                                                            name: this.controller.user_filter, 
                                                            roles: this.state.role_filter, 
                                                            time: this.controller.process_date(this.view?.state?.page?.time_frame)
                                                        }}}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                </Col>
                </Row>
            </Container>
        )
    }
}
