// MODULE SPECIFIC IMPORTS
import NoteModelController from "controllers/ModelControllers/Shipping/NoteModelController.jsx"
import _viewController from "controllers/ViewController/_viewController.jsx"
import create_note from "assets/graphql/Notes/Note.create.graphql.json"
import delete_note from "assets/graphql/Notes/Note.delete.graphql.json"

export default class NotesPanelController extends _viewController {
    constructor(props) {
        super(props)
        this._configs = {
            local: "note",
            array: "notes"
        }
        this.note           = new NoteModelController({params: {parent: this,
                                                        controller: this,
                                                        index: 0,
                                                        _param: "notes",
                                                        is_view: true,
                                                        view: this.view}})
    }
    add_note() {
        // two guard clauses rather than nested if statements.
        if(this.note._sum <= 0) { return }
        if(this.note.note_text.trim() === "") { return }
        let body = this.format_mutation()
        this.api.users.ask({caller: this, params: {body: body}, callback: {f: this.process_note}})
        // extract data from the model controller.
        let data = JSON.parse(JSON.stringify(this.note?.data))
        // push the new note data to the existing array of notes.
        // this.controller.view.state.data.notes.push(data)
        // works to reset the note model controller's values. 
        this.setState({key: "data", param: "notes", value: [{note_text:"", viewable:false}]})
        // this set state forces the textarea to clear.
        this.view.setState(prevState => ({
            index: prevState.index + 1
        }))
        // re-render the view.
        // this.controller.view.forceUpdate()
    }
    process_note({caller, results, params}) {
        if(results?.errors !== undefined) {
            console.log("Error processing notes", results.errors)
            return
        }
        // console.log("Note created")
        caller.controller.view.state.data.notes.push(results?.data?.setNote)
        caller.controller.view.forceUpdate()
    }
    format_mutation() {
        let driver_id = this.controller.driver._id
        let data = JSON.parse(JSON.stringify(this.note?.data))
        data.user_id = driver_id
        data = this.toUnquotedJSON(data)
        let body = JSON.parse(JSON.stringify(create_note))
        body.query = body.query.replace("input:", "input:"+data)
        return body
    }
    remove_note(index) {
        let note = this.controller.notes[index]
        if(note?.id !== undefined && note?.id !== null) { 
            let body = JSON.parse(JSON.stringify(delete_note))
            body.query = body.query.replace("id:", 'id: "'+note.id+'"')
            this.api.notes.ask({caller: this, params: {body: body}})
        }
        this.view.controller._delete({name: this?._configs?.array, param: "driver", index: index})
        this.controller.view.forceUpdate()
    }
}