import _viewController from "controllers/ViewController/_viewController.jsx"

export default class TripLogsController extends _viewController{
    constructor(props) {
        super(props)
    }
    switch_tabs() {
        // this.view.setState({showMap: true})
        // this.view.setState(prevState => ({
        //     showMap: !prevState.showMap
        // }))
    }
    handleMap() {
        this.view.setState({
            btn_left: "",
            btn_right: "btn_logs_active",
            show_map: true
        })
        // this.view.setState({show_map: true})
    }
    handleLogs() {
        this.view.setState({
            btn_left: "btn_logs_active",
            btn_right: "",
            show_map: false
        })
        // this.view.setState({show_map: false})
    }
}