// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button } from "react-bootstrap";

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx";
import Controller from "./AssignDriverTruck.partial.controller.jsx"
import form_configs from "./AssignDriver.json"
import "./AssignDriverTruck.css"

export default class AssignDriverTruck extends _basePartial {
    constructor(props) {
        super (props)
        this.partial_controller     = new Controller ({params: {view: this, controller: this.controller}})
        this.state.form             = form_configs.form_objects
    }
    renderView() {
        // console.log(this)
        return (
            <Row id={this.name}>
                <div className="cota_panel_border add_shadow g-0">
                    <Row className="g-0 padding">
                        <Col>
                            <Row>
                                <Col  className="info text_wrap">
                                    Choose a truck
                                </Col>
                            </Row>
                            <Row>
                                <Col className="instructions">
                                    Search for an idle truck and hook up it to this trailer
                                </Col>
                            </Row>
                            <Row className="dropdown">
                                <Col>
                                    {this.formObject({data: this.state.form.asset, controller: this.partial_controller, owner: this})}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="btn_row">
                        <Col sm={7} ></Col>
                        <Col className=' g-0  d-flex justify-content-end' sm={4}>
                            <Button onClick={() => this.partial_controller.link_truck()} className="btn_cancel">
                                Assign Truck
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Row>
        )
    }
}