import _viewController from "controllers/ViewController/_viewController.jsx"

export default class TripLoadCardController extends _viewController{
    constructor(props) {
        super(props)
    }
    get driver_name() {
        let name = this.controller.driver.full_name
        return (name.trim().length === 0) ? "N/A" : name
    }
    edit_trip() {
        let trip = this?.controller?.view?.state?.data?.trip
        trip = JSON.parse(JSON.stringify(trip))
        this.view.panel_controller?.selectPanel({panel_name: "new_trip", cache_data: trip})
    }
}