// GENERAL REACT
import React from "react";
import { Row, Col, Button } from 'react-bootstrap'
import { LiaDollarSignSolid } from "react-icons/lia";

// MODULE SPECIFIC
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./LoadDetails.partial.controller.jsx"
import "./LoadDetails.css"

export default class LoadDetailsPartial extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this, controller: this?.controller}})
    }
    get commidity() {
        return this.state?.data?.shipment?.commodites?.[0]
    }
    get index() {
        return this.state.index
    }
    renderView() {
        // for some reason data kept getting overwritten so I had put the following line:
        this.state.data = this.props?.params?.data
        return (
            <Row key={"load_detail_"+this.state.index} id={this.name} className="max_height">
                <Col className="g-0 add_shadow cota_panel_border">
                    <Row className="pad_top_3 center">
                        <Col className="center">
                            <Row className="ta_center">
                                <Col>
                                    <Row>
                                        <Col>
                                            <div className="icon_bg">
                                                <LiaDollarSignSolid size={36} className="icon"/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="pad_top_half">
                                        <Button className="btn_load cursor center_text" onClick={() => this.controller.view_load({index: this.index})}>
                                            Load {this.partial_controller.load?.local_id}
                                        </Button>
                                    </Row>
                                    <Row className="pad_top_half">
                                        <Col className="widgets center_text">
                                            {this.commidity?.name}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="billed center_text">
                                            {this.partial_controller.load?.status}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="pad_top_2 pad_bot">
                        <Col />
                        <Col className="ta_center">
                            <Row>
                                <Col className="info center_text">
                                    Shipper
                                </Col>
                            </Row>
                            <Row>
                                <Col className="details center_text">
                                    {this.partial_controller.pickup?.contact?.name}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="location no_wrap center_text">
                                    {this.partial_controller.pickup?.contact?.city}, {this.partial_controller.pickup?.contact?.state}
                                </Col>
                            </Row>
                        </Col>
                        <Col />
                        <Col className="ta_center">
                            <Row>
                                <Col className="info center_text">
                                    Consignee
                                </Col>
                            </Row>
                            <Row>
                                <Col className="details no_wrap center_text">
                                    {this.partial_controller.dropoff?.contact?.name}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="location center_text">
                                    {this.partial_controller.dropoff?.contact?.city}, {this.partial_controller.dropoff?.contact?.state}
                                </Col>
                            </Row>
                        </Col>
                        <Col />
                    </Row>
                </Col>
            </Row>
        )
    }
}