// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx"

export default class CancelLoadModal extends _baseModal {
    constructor(props) {
        super(props)
        this.state.modal_params = {
            positioned: "middle_of_panel",   
            title: "",                         
            title_size: "",                     
            closeable: true,                    
            has_overlay: true,                  
            close_on_outside_click: true,      
            x_adjustment: "0px",                
            y_adjustment: "-100px",                
            draggable: false,                              
        }
        this._message = ""
        this._action = null
        this._ok_button_only = false
    }

    get status() {
        return this.controller.load?.status?.toLowerCase()
    }

    get message() {
        return this._message
    }

    set message(message){
        this._message = message
    }

    get action() {
        return this._action
    }

    set action(action){
        this._action = action
    }

    make_load_available(){
        this.parent.controller.load.status = "Available"
        this.parent.toggle_modal({toggle_state: false})
        // this.parent.toggle_modal.bind(this.parent)
        this.parent?.controller.save_load("status")
    }

    cancel_load(){
        this.parent.controller.load.status = "Canceled"
        this.parent.toggle_modal({toggle_state: false})
        // this.parent.toggle_modal.bind(this.parent)
        this.parent?.controller.save_load("status")
    }

    determine_modal() {
        switch (this.status) {
            case "canceled":
                this._ok_button_only = false;
                this.action = this.make_load_available
                this.message = "Would you like to make this load available?"
                break;
            case "available":
                this._ok_button_only = false;
                this.action = this.cancel_load
                this.message = "Would you like to cancel this load?"
                break;
            case "delivered":
            case ("billed"):
                    this._ok_button_only = true;
                    this.message = "You cannot cancel a load that has already been delivered or billed."
                    break;
            default:
                this._ok_button_only = true;
                this.message = <div>You cannot cancel a load that has already been dispatched to a trip.<br/>Please remove this load from its trip before canceling.</div>

        }
    }

    renderButtons(){
        if(this._ok_button_only){
            return (
                <Row className="pt-4">
                    <Col className="flex_centered">
                        <Button className="primary_button_coloring cota" 
                                onClick={this.parent.toggle_modal.bind(this.parent)}
                        >
                            OK
                        </Button>
                    </Col> 
                </Row>
            ) 
        }
        return (
            <Row className="pt-4">
                <Col>
                    <Button className="clear_button cota"  onClick={this.parent.toggle_modal.bind(this.parent)}>
                        No
                    </Button>
                </Col>
                <Col>
                    <Button className="primary_button_coloring cota" 
                            onClick={this.action.bind(this)}
                    >
                        Yes
                    </Button>
                </Col>
            </Row>
        )
    }

    renderView() {
        this.determine_modal()
        return (
            <Row className="flex_centered p-3">
               <Col>
                    <Row>
                        <Col className="flex_centered description">
                            {this.message}
                        </Col>
                    </Row>
                    {this.renderButtons()}
               </Col>
            </Row>
        )
    }
}
