import _viewController from "controllers/ViewController/_viewController.jsx";

export default class NewContactModalController extends _viewController {
    constructor(props) {
        super(props)
    }
    processEventReaction(decoded) {
        this.handleScreen(2, decoded.message.data)
    }
    handleCloseScreen() {
        this.resetScreen()
    }
}