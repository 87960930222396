// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button, Container, Card } from "react-bootstrap";

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx";
import Controller from "./AssignAsset.partial.controller.jsx";
import AssetStatus from "../AssetStatus/AssetStatus.partial.view.jsx";
import config_data from "./AssignAsset.json"
import AssetAttached from "../AssetAttached/AssetAttached.partial.view.jsx"

import "./AssignAsset.css";

export default class AssignAsset extends _basePartial {
    constructor(props){
        super(props);
        this.state.form                     = config_data.form_objects
        this.partial_controller             = new Controller ({params: { view: this, controller: this.controller}})
        this.state.showAssetStatus          = false
        this.state.redirectToAssetAttached  = false
    }
    asset_attached(){
        return (
         <AssetAttached params={{parent: this.view,
                                controller: this.controller,
                                asset_id: this.state?.search?.assets?.selected_value?.id}} />
        )
    }
   asset_status(){
      return (
        <AssetStatus params={{parent: this.view,
                                controller: this.controller}} />
      )
    }
    get asset() {
        return this.controller?.asset
    }
    renderView() {
        if (this.state.redirectToAssetAttached) {
            return this.asset_attached()
        }
        if (this.state.showAssetStatus) {
            return this.asset_status()
        }
        if (this.state?.form?.assets !== undefined ) {
            this.state.form.assets.values = { asset_type: this.asset.inverse_type.toUpperCase() }
        }
        this.state.form.assets.placeholder = `${this.controller?.asset?.inverse_type.Capitalize()}`
        this.state.form.assets.values = {"asset_type": this.controller?.asset?.inverse_type.toUpperCase()}
        return (
            <div>
                <Row id={this.name}>
                    <Card className="g-0 add_shadow">
                        <Row className="g-0 padding">
                            <Col>
                                <Row>
                                    <Col className="info">
                                        Choose a {this.asset.inverse_type}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="instructions">
                                        Search for an idle {this.asset.inverse_type} and hook up it to this {this.asset.base_type}
                                    </Col>
                                </Row>
                                <Row className="search_asset">
                                    <Col>
                                        {this.formObject({data: this.state.form.assets, owner: this, controller: this.partial_controller, })}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col className="contents">
                                                <Button className="btn_cancel btn_align" onClick={this.partial_controller.redirect_asset_status.bind(this)}>
                                                    Cancel
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col className="contents">
                                                <Button className="btn_asset btn_align" onClick={this.partial_controller.attach_asset.bind(this)}>
                                                    Hook up a {this.asset.inverse_type}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Row>
            </div>
        )
    }
}
