// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"

// MODEL CONTROLLERS
import TripModelController from "controllers/ModelControllers/Trips/TripModelController.jsx"
import PersonModelController from "controllers/ModelControllers/PersonModelController.jsx"
import StopModelController from "controllers/ModelControllers/StopModelController.jsx"
import ShipmentModelController from "controllers/ModelControllers/Shipping/ShipmentModelController.jsx"
import AssetModelController from "controllers/ModelControllers/AssetModelController.jsx"

// API REQUESTS
import trip_request from "assets/graphql/Trips/trips.graphql.json"
import trip_expanded_loads_request from "assets/graphql/Trips/trips.expanded_loads.graphql.json"
import trip_mutation from "assets/graphql/Trips/trips.mutation.graphql.json"
import delete_trip_mutation from "assets/graphql/Trips/trips.delete.graphql.json"

export default class DispatchedTripController extends _viewController {
    constructor(props) {
        super(props)
        this.trip           = new TripModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true}})
        this.driver         = new PersonModelController({params: {parent: this,
                                                                    controller: this,
                                                                    is_view: true,
                                                                    _param: "driver"}})
        this.load           = new ShipmentModelController({params: {parent: this,
                                                                    controller: this, 
                                                                    is_view: true}})
        this.truck          = new AssetModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true,
                                                                _param: "truck"}})
        this.trailer        = new AssetModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true,
                                                                _param: "trailer"}})
        this.pickup         = new StopModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true,
                                                                _param: "pickup"}})
        this.consignee      = new StopModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true,
                                                                _param: "consignee"}})
        this.requests   = [{callback: {f: this.state_results, p: {name: "areas", var: "states"}}, 
                                endpoint: "areas", replace: {o: "params:", r:'params: {country: "US"}'}},
                           {callback: {f: this.status_results, p: {name: "status", var: "status"}}, endpoint: "status", 
                                replace: {o: "status", r: 'status(types:"trip")'}}]
    }
    get notes() {
        return this.view?.state?.data?.notes
    }
    get loads() {
        return this.view?.state?.data?.loads
    }
    get logs() {
        return this.view?.state?.data?.logs
    }
    get event_types() {
        return this.view.state.event_types
    }
    get states() {
        return this.view.state.states
    }
    get statuses() {
        return this.view.state.statuses
    }
    get internal_user() {
        return this.check_role("INTERNAL_COTA_USER")
    }
    get admin_user() {
        return this.check_admin()
    }
    get owner() {
        return this.check_role("OWNER")
    }
    get editable() {
        if(this.internal_user || this.admin_user || this.owner) {
            return true
        }
        return false
    }
    status_results({caller, params, results}) {
        if (results?.errors === undefined && results?.data?.status !== undefined) {
            results?.data?.status?.map((stat, index) => {
                return caller.view.state.statuses.push({value: stat?.value, label: stat?.label})
            })
        }
    }
    state_results({caller, params, results}) {
        if (results?.errors === undefined && results?.data?.areas !== undefined) {
            results.data?.areas?.map((area, index) => {
                caller.view.state.states.push({ value: area.state, label: area.state })
            })
        }
    }
    load_cache() {
        let key = this.view.state.screen
        let data = (this.panel_controller?.getCache(key))?.data
        if(data === undefined) {
            data = (this.panel_controller?.getCache(4))?.data
        }
        if(data !== undefined) {
            this.setState({key: "data", param: "id", value: data?.id})
        }
    }
    load_harness() {
        let trip_id = this.view.state?.data?.id
        // let body = JSON.parse(JSON.stringify(trip_request))
        let body = JSON.parse(JSON.stringify(trip_expanded_loads_request))
        body.query = body.query.replace("trips", 'trip(id: "'+trip_id+'")')
        this.api.trips.ask({caller: this, params: {body: body}, callback: {f: this.process_trip, fin: true}})
    }
    process_trip({caller, params, results}) {
        if(results?.errors !== undefined) {
            console.log("Error processing trip", results.errors)
        }
        let trip = results?.data?.trip
        if (trip == undefined) { console.log("No trip available"); return }
        caller.view.state.data.trip = trip
        caller.inject_data({data: trip})
        caller.view.forceUpdate()
    }
    inject_data({data, params}) {
        if(typeof data === "object") { 
            return Object.keys(data).map((key, index) => {
                if(typeof data[key] === "object" || Array.isArray(data[key])) {
                    this.view.state.data[key] = data[key]
                }
            })
        }
    }
    update_trip_status(status) {
        if(status === undefined) { status = this.trip.status.toUpperCase() }
        let trip_id = this.trip._id
        let body = JSON.parse(JSON.stringify(trip_mutation))
        let data = {id: trip_id, status: status, driver_id: this?.driver?.data?.id}
        data = this.toUnquotedJSON(data)
        body.query = body.query.replace("input", 'input: '+data)
        this.api.trips.ask({caller: this, params: {body: body}, callback: {f: this.process_completed_trip}})
    }
    process_completed_trip({caller, params, results}) {
        if(results?.errors !== undefined) {
            console.log("Error completing trip", results.errors)
            return
        }
        caller.trip.status = results?.data?.setTrip?.status?.toUpperCase()
        caller.view.forceUpdate()
    }
    saveAction(page_key) {
        this.state?.parent?.selectPanel({panel_name: page_key, cache_data: {}})
    }
    _delete({name, index}) {
        this.view.state.data[name] = this.view?.state?.data?.[name].filter((value, i) => i !== index)
        this.view.forceUpdate()
    }
    view_load({index}) {
        // console.log("INDEX: ", index)
        let id = this.loads[index]?.id
        // console.log("INDEX ID: ", id)
        this.view.panel_controller.selectPanel({panel_name: "view_load", cache_data: {id: id}})
    }
    delete_trip() {
        let body = JSON.parse(JSON.stringify(delete_trip_mutation)) 
        let data = {id: this.trip._id}
        data = this.toUnquotedJSON(data)
        body.query = body.query.replace("input", 'input: '+data)
        this.api.trips.ask({caller: this, params: {body: body}, callback: {f: this.process_deleted_trip}})
    }
    process_deleted_trip({caller, params, results}) {
        if(results?.errors !== undefined) {
            console.log("Error deleting trip", results.errors)
            return
        }
        // return to trips view
        caller.controller?.view?.resetScreen()
    }
}