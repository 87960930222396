// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import StopModelController from "controllers/ModelControllers/StopModelController.jsx"
import CompanyModelController from "controllers/ModelControllers/CompanyModelController.jsx"
import ShipmentModelController from "controllers/ModelControllers/Shipping/ShipmentModelController.jsx"
import EquipmentModelController from "controllers/ModelControllers/Shipping/EquipmentModelController.jsx"
import CommodityModelController from "controllers/ModelControllers/Shipping/CommodityModelController.jsx"
import LineItemModelController from "controllers/ModelControllers/Quotes/LineItemModelController"
import InfoModelController from "controllers/ModelControllers/Shipping/InformationModelController.jsx"

import existing_quoteload from "assets/graphql/Loads/load.query.graphql.json"
import edit_load from "assets/graphql/Loads/loads.mutation.graphql.json"
import edit_load_rate_items from "assets/graphql/Loads/load.mutation.rate_items.graphql.json"
import edit_load_notes from "assets/graphql/Loads/loads.mutation.notes.graphql.json"
import edit_load_stops from "assets/graphql/Loads/load.mutation.stops.graphql.json"
import edit_load_payor from "assets/graphql/Loads/loads_mutations/load.mutation.payor.graphql.json"
import load_documents from "assets/graphql/Loads/load.documents.graphql.json"
import distance_request from "assets/graphql/Distance/distance.graphql.json"
import delete_load_mutation from "assets/graphql/Loads/loads_mutations/load.delete.graphql.json"

export default class ViewLoadController extends _viewController {
    constructor(props) {
        super(props)
        this.state = {}
        this.state.stops_length         = 0
        this.state.old_stops_length     = 0    
        this.load           = new ShipmentModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true}})
        this.commodity      = new CommodityModelController ({params: {parent: this,
                                                                controller: this,
                                                                is_view: true}})
        this.pickup         = new StopModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true,
                                                            _param: "pickup"}})
        this.consignee      = new StopModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true,
                                                            _param: "consignee"}})
        this.company        = new CompanyModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true}})
        this.equipment      = new EquipmentModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true}})
        this.line_item      = new LineItemModelController({params: {parent: this, 
                                                            controller: this,
                                                            is_view: true,
                                                            //  _param: "line_items"
                                                            }})
        this.information    = new InfoModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true}})
        this.requests  = [{callback: {fin:false, f: this.state_results, p: {name: "areas", var: ["areas", "states"]}}, 
                                    endpoint: "areas", replace: {o: "params:", r:'params: {country: "US"}'}},
                            {callback: { fin:false, p: {name: "trailer_types", var: "trailer_types"}}, endpoint: "trailers"},
                            {callback: {fin:false, p: {name: "special_requirements", var: "special_requirements"}},
                                endpoint: "requirements", req_name: "special"},
                            {callback: {fin:false, f: this.process_status}, endpoint: "status", 
                                    replace: {o: "status", r: 'status(types:"load")'}}
                            // {callback: {p: {name: "status", var: "statuses", disp: "label"}}, endpoint: "status", 
                            //             replace: {o: "status", r: 'status(types:"load")'}}
                                    ]
    }
    process_status({caller, params, results}) {
        results?.data?.status?.map((obj) => {
            // if(obj?.value === "DISPATCHED") { return }
            if(obj?.value === "DISPATCHED_CARRIER" 
                || obj?.value === "BOOKED" 
                || obj?.value === "BILLED_FREIGHT" 
                || obj?.value === "CANCELED" 
                || obj?.value === "PENDING" 
                || obj?.value === "PROCESSING"
                || obj?.value === "POSTED") { return }
            caller.view.state.statuses.push({value: obj?.id, label: obj?.label.Capitalize()})
        })
    }
    async get_distance() {
        console.log("GETTING THE DISTANCE",this)
        let fetch = false
        // if we add or subtract a stop set fetch to true to recaculate the distance
        if (this.calc_stop_length() !== this.state.old_stops_length) { fetch = true }
        // if we dont have a distance fetch one
        if (this.load?.data?.distance === null || this.load?.data?.distance === undefined) { fetch = true }
// begin the calculating process
        if (fetch) {
            // store the stops in state to check against to see of we need to recalculate distance later
            this.state.old_stops_length = this.calc_stop_length()
            // we calculate the distance based of the zips
            let zips = []
            zips = this.push_zip({stop: this.pickup, zips: zips})
            // check to see if we have an array to loop over 
            if (Array.isArray(this.load.stops)) {
                // grab every zip code to give to the api to calculate the distance
                this.load.stops.forEach((stop) => {
                    zips = this.push_zip({stop: stop, zips: zips})
                    return
                })
            }
            // if we dont have an array push the new zip into the array of zips
            zips = this.push_zip({stop: this.consignee, zips: zips})
                 // make sure we have at least two stops 
            if (zips.length > 1) {
                /*  distance req looks like this
                  "query": 
                  "query{ distance(zips:)
                { total_distance, total_time, complexity, ave_complexity } }" */
                let body    = JSON.parse(JSON.stringify(distance_request))
                body.query  = body.query.replace("(zips:)", '(zips: '+JSON.stringify(zips)+')')
                await this.api.distance.ask({caller: this, params: {body: body}, callback: {f: this.process_distance}})
            }
        }
    }
    calc_stop_length() {
        let len = 0
        if (this.pickup?.contact?.id !== undefined) {
            len = len + 1
        }
        if (this.consignee?.contact?.id !== undefined) {
            len = len + 1
        }
        if (Array.isArray(this.load?.stops)) {
            len = len + this.load?.stops.length
        }
        return len
    }
    push_zip({stop, zips}) {
        if (stop.contact?.zip !== undefined && stop.contact?.zip !== null) {
            if (stop?.contact?.zip !== "") {
                zips.push(stop?.contact?.zip)
            }
        } else {
            if (stop?.contact?.company?.zip !== undefined && stop?.contact?.company?.zip !== null && stop?.contact?.company?.zip !== "") { zips.push(stop?.contact?.company?.zip) }
        }
        return zips
    } 
    process_distance({caller, params, results}) {
        caller.state.distance = results?.data?.distance?.total_distance
        // console.log("DISTANCE RESULTS", results?.data?.distance?.total_distance, caller.load.distance)
        caller.view.forceUpdate()
    }
    state_results({caller, params, results}) {
        if (results?.errors === undefined && results?.data?.areas !== undefined) {
            results.data.areas.map((area, index) => {
                caller.view.state.states.push({ value: area.state, label: area.state })
            })
        }
    }
    get controller() {
        return this.view.state?.controller
    }
    get states() {
        return this.view.state.states
    }
    get trailer_types() {
        return this.view.state.trailer_types
    }
    get trailer_type_objects(){
        let objs = this?.equipment?.trailer_types?.map((ttId)=> {
            return this.trailer_types.find(ttObj => ttObj.value == ttId)
        })
        if(objs == undefined) return []
        return objs;
    }
    get statuses() {
        return this.view.state.statuses
    }
    get requirements() {
        return this.view.state.special_requirements
    }
    get special_requirements_objects(){
        let objs = this?.equipment?.requirements?.map((rId)=> {
            return this.requirements.find(rObj => rObj.value == rId)
        })
        if(objs == undefined) return []
        return objs;
    }
    get notes() {
        return this.view?.state?.data?.shipment?.notes
    }
    get line_items() {
        return this.view.state.data.shipment?.rate_items
    }
    get commodities() {
        let commodities = this.view?.state?.data?.commodity
        if(JSON.stringify(commodities) === '{}' || commodities === undefined) {
            return []
        }
        return commodities
    }
    get info() {
        return this.view.state.data.information
    }
    get special_requirements() {
        return this.equipment?.requirements
    }
    get commodities_dims() {
        return this.calculate_commodity_totals()
    }
    get internal_user() {
        return this.check_role("INTERNAL_COTA_USER")
    }
    get admin_user() {
        return this.check_admin()
    }
    get owner() {
        return this.check_role("OWNER")
    }
    get editable() {
        if(this.internal_user || this.admin_user || this.owner) {
            return true
        }
        return false
    }
    get labeled_dims() {
        let dims = this.calculate_commodity_totals()
        dims.height = this.reduce_size({value: dims.height, metric: false, label: true})
        dims.width  = this.reduce_size({value: dims.width, metric: false, label: true})
        dims.length = this.reduce_size({value: dims.length, metric: false, label: true})
        return dims
    }
    upload_callback({caller, params, results}) {
        // console.log("Refreshing docs")
        // console.log(this)
        let body    = JSON.parse(JSON.stringify(load_documents))
        let data = {id: this.load?._id}
        body.query  = body.query.replace("load", 'load(id: "'+data?.id+'")')
        this.api.loads.ask({caller: this, params: {body: body}, callback: {f: this.process_load_documents, fin: false}})
    }
    process_load_documents({caller, params, results}) { 
        caller.load.documents = results?.data?.load?.documents
        caller.view.forceUpdate()
    } 
    calculate_commodity_totals() {
        let totals = { 
            height: 0, length: 0,
            width: 0, weight: 0,
            value: 0
        }
        this.commodities?.map((com, index) => {
            totals.height += com?.height ?? 0
            totals.length += com?.length ?? 0
            totals.width += com?.width ?? 0
            totals.weight += com?.weight ?? 0
            totals.value += com?.value ?? 0
        })
        return totals
    }
    reduce_size({value, metric, label}) {
        if (value !== undefined) {
            if (metric) {
                // Finish later
                return value + ((label) ? " mm": "")
            } else {
                if (value < 12) {
                    return value + ((label) ? " in": "")
                } else {
                    return (Math.round((value / 12 * 10)) / 10) + ((label) ? " ft": "")
                }
            }
        }
    }
    follow_on_selection({event, obj, data}) {
        // follow on selection runs only if the user needs to 'update' the load,
        // so set in state the variable update to true
        this.view.setState({update: true})
	    this.get_contact({id: obj?.id, name: data?.name})
    }
    _delete({name, index}) {
        this.view.state.data[name] = this.view?.state?.data?.[name].filter((value, i) => i !== index)
        this.view.forceUpdate()
    }
    update(param) {
        console.log("WE ARE UPDATING")
        // need to get rid of redundancy here. Almost no point to update function.
        this.save_load(param)
        if(this.view.state?.updated === false) {
            // this.view.setState({updated: true})
            this.view.state.updated = true
        }
        // After the load is saved set update state back to false.
        this.view.setState({update: false})
    }
    saveAction(page_key) {
        this.state?.parent?.selectPanel({panel_name: page_key, cache_data: {}})
    }
    bill_action() {
        let data = {id: this.load?._id, updated: this.view.state.updated}
        this.view.state?.parent?.selectPanel({panel_name: "billing", cache_data: data})
        // this.view.state?.parent?.selectPanel({panel_num: 2, cache_data: data})
    }
    dispatch_action() {
        let data = this.view.state?.data?.shipment
        data.dropoff = this.view.state?.data?.consignee
        data.pickup = this.view.state?.data?.pickup
        data = JSON.parse(JSON.stringify(data))
        this.view.state?.parent?.selectPanel({panel_num: 3, cache_data: data})
    }
    handleCloseScreen() {
        // Needs a refactor
        this.view.state.panel_params.controller.view.resetScreen()
    }
    load_cache() {
        let key = this.view.state.screen
        let data = (this.panel_controller?.getCache(key))?.data
        if(data === undefined) {
            data = (this.panel_controller?.getCache(2))?.data
        }
        if(data !== undefined) {
            this.setState({key: "data", param: "trip_load", value: data})
        }
    }
    load_harness() {
        let data = this.view.state.panel_params.data
        // This means the data in panel_params is a trip
        if(data?.loads?.length > 0) { 
            data = this.view.state?.data?.trip_load
        }
        this.load_existing_quote(data)
    }
    resolveCache() {
        if (this.view.state?.cache !== undefined) {
            this.view.state.context.user    = this.getCopy(this.view?.state?.cache)
            this.setState({key: "data", param: "user", value: this.getCopy(this.view?.state?.cache)})
            this.view.forceUpdate()
        }
    }
    delete_load() {
        let body = JSON.parse(JSON.stringify(delete_load_mutation)) 
        let data = {id: this.load._id}
        data = this.toUnquotedJSON(data)
        body.query = body.query.replace("input", 'input: '+data)
        this.api.trips.ask({caller: this, params: {body: body}, callback: {f: this.process_delete_load}})
    }
    process_delete_load({caller, params, results}) {
        if(results?.errors !== undefined) {
            console.log("Error deleting load", results.errors)
            return
        }
        // return to loads view
        caller?.view?.state?.controller?.view?.resetScreen()
    }
    async load_existing_quote(data) {
        // console.log("QUERYING")
        this.view.setState({_is_loading: true})
        if (data !== undefined) {
	        let body    = JSON.parse(JSON.stringify(existing_quoteload))
            let query_param = (data?.id !== undefined) ? 'load(id: "'+data?.id+'")' : 'load(load_number: "'+data?.load_number+'")'
            body.query  = body.query.replace("load", query_param)
            // console.log("STRING UNDEFINED",data.load_number,data.id)
            // if(data.load_number !== undefined, && data.id!== undefined){
            this.api.loads.ask({caller: this, params: {body: body}, callback: {f: this.process_loaded_quote, fin:false}})
            // }
        }
    }
    process_loaded_quote({caller, params, results}) {
        if(results?.errors !== undefined) {
            console.log("process_loaded_quote error:", results.errors)
        }
        let quoteload = results?.data?.load
        let reform_data = undefined
        // console.log("loaded load*", quoteload)
        // NEED TO CHECK FOR LOAD STATUS PENDING AS WELL
        if(caller?._notnull(quoteload?.raw_reform)) {
            reform_data = caller.remap_reform(quoteload?.raw_reform)
            if(reform_data !== undefined) {
                caller.set_reform_data(reform_data)
            }
        }
        if(quoteload?.info !== undefined) {
            if(quoteload?.dispatcher?.id !== undefined && quoteload?.dispatcher?.id !== null) {
                quoteload.info.po_number = quoteload?.cota_id
            } 
            if(quoteload?.info !== undefined && quoteload?.info !== null && Object.keys(quoteload?.info?.scrub())?.length) {
                caller.view.state.data.information = quoteload.info
            }
        }
        if (quoteload?.carrier !== undefined) {
            caller.setState({key: "data", param: "saved.times", value: true})
            if (quoteload?.carrier?.id !== undefined) {
                caller.view.state.data.saved.dispatched = true
            }
        }
        if (quoteload?.loads?.length > 0) {
            caller.company.index = 0
            caller.view.state.data.company = quoteload?.loads.map(a => a.payor)
        }
        let quote = quoteload?.quote
        if (quoteload?._type === "quote") {
            quote = JSON.parse(JSON.stringify(quoteload))
            delete quoteload.id
        }
        if (quoteload !== undefined) {
            if (quoteload.commodities === undefined) {
                // caller.commodity?._sync({data: quote?.raw_quote?.request?.items, remapper:"quote"})
            } else if(quoteload?.commodities?.length) {
                caller.commodity?._sync({data:quoteload.commodities})
            }
            if(quoteload?.rate_items !== undefined) {
                let linehaul = quoteload?.linehaul
                caller.view.state.line_items = caller.add_basic_items({items: quoteload?.rate_items, linehaul: quoteload?.linehaul})
                if((linehaul !== undefined && linehaul !== null)) {
                    // quoteload.rate_items = quoteload.rate_items.filter(el => el?.rate_type !== "flat_rate")
                    quoteload.rate_items?.push({id: "linehaul", rate_type: "flat_rate", number: 1, price: linehaul, rate: linehaul})
                }
            }
	        caller.setState({key: "data", param: "equipment", value: quoteload?.equipment})
            caller.load?._sync({data: quoteload, remapper:"quote"})
            caller.handle_reform_totals({load: quoteload, reform: reform_data})
	        caller.quote?._sync({data: quote})
            if (quote?.raw_quote !== undefined) {
                let raw_request = JSON.parse(JSON.stringify(quote?.raw_quote))
                let date = undefined
                if (quote?.pickup_date !== null && quote?.pickup_date !== undefined) {
                    let convert_date = new Date(parseInt(quote?.pickup_date, 10))
                    if (!isNaN(convert_date)) {
                        date = convert_date
                    }
                }
                caller.quote?._sync({data: quote})
                caller.company?._sync({data: quote?.customer})
            }
            caller.load_payor_or_dispatcher({load: quoteload})
            if (quoteload?.pickup?.id !== undefined && quoteload?.pickup?.id !== null) {
                caller.get_stop({id: quoteload?.pickup?.id, name: "pickup"})
            }
            if (quoteload?.dropoff?.id !== undefined && quoteload?.dropoff?.id !== null) {
                caller.get_stop({id: quoteload?.dropoff?.id, name: "consignee"})
            }
            if (quoteload?.stops !== undefined) {
                if (Array.isArray(quoteload.stops)) {
                    quoteload.stops.map((stop, index) => {
                        caller.get_stop({id: stop.id, name: "stops", index: index})
                    })
                }
            }
        }
	    caller.view.setState({_is_loading: false})
        caller.view.forceUpdate()
    }
    load_payor_or_dispatcher({load}) {
        // NEED TO REVISIT (ensure dispatched from FP to CP payor cant be changed)
        if(load?.dispatcher?.id !== undefined && load?.dispatcher?.id !== null) {
            // if(load.dispatcher?.roles?.find(({ name }) => name.toLowerCase() === "dispatcher") !== undefined) {
            this.view.state.edit = false
            return this.get_company({id: load.dispatcher?.id, name: "company"})
            // }
        }
        if(load?.payor !== undefined) {
            this.view.state.edit = true
            return this.get_company({id: load.payor?.id, name: "company"})
        }
        console.log("Error loading payor/dispatcher info")
    }
    get_stop({id, name, index}) {
        this.api?.stops?.gid({caller: this, value: id, callback: {f: this.process_loaded_stop, p: {name: name, index: index}, fin: true}})
    }
    get_contact({id, name}) {
        this.api?.contacts?.gid({caller: this, value: id, callback: {f: this.process_loaded_contact, p: {name: name}, fin :false}})
    }
    get_company({id, name}) {
        this.api?.companies?.get({caller: this, id: id, callback: {f: this.process_loaded_company, p: {name: name}, fin: false}})
    }
    process_loaded_stop({caller, params, results}) {
        if (params.index === undefined) {
            caller?.[params?.name]?._sync({data: results?.data?.stop})
        } else {
            let stops = caller.view.state.data[params?.name]
            stops[params?.index] = results?.data?.stop
            let stop_type = results?.data?.stop?.stop_type?.toLowerCase()
            stop_type = (stop_type === "consignee") ? "dropoff" : stop_type 
            let other_stops = caller?.view?.state?.stops?.[stop_type]
            if (other_stops !== undefined && other_stops !== null) {
                other_stops[params?.index] = results?.data?.stop
            }
            caller.setState({key: "data", param: params?.name, value: stops})
        }
    }
    process_loaded_contact({caller, params, results}) {
        let contact = {
            contact: results?.data?.contact,
            company: results?.data?.contact?.company,
            insurance: results?.data?.contact?.insurance
        }
        caller.setState({key: "data", param: params?.name, value: contact})
        // guard update function in an if statement to run only 
        // if variable in state called update is true.
        // prevents update function from being called when page is first loaded.
        if(caller.view.state.update) {
            caller.update()
        }
    }
    process_loaded_company({caller, params, results}) {
        caller?.[params?.name]?._sync({data: results.data.company})
    }
    // LOAD CREATION:
    async save_load(param) {
        // console.log("NOW SAVING",param,this)
        if(this.load.summary?.notes?.length) {
            if(!this.load.summary?.notes[this.load.summary?.notes?.length -1].viewable ){
                this.load.summary.notes[this.load.summary.notes.length -1].viewable = true
            }
        }
        await this.get_distance()
        let mutation = edit_load
        this.view.state.data.commodities = this.commodity.data
        let summary = JSON.parse(JSON.stringify(this.load.summary))
        if(this.state.distance){
            summary.distance = this.state.distance
        }
        // console.log(summary)
        let _stops = summary?.stops
        if(Array.isArray(_stops)) {
            _stops.map((stop, index) => {
                stop.index = index
                return
            })
            summary.stops = _stops
        }
        // MEANT TO LOWER TAX ON API, NEED TO SWITCH TO SWITCH CASE NOW
        if(param === "notes") {
            // NEED TO potentially have another call to load as a query after the mutation to grab the notes.
            mutation = edit_load_notes
            summary = {id: summary.id, notes: summary.notes, status: summary.status}
        }
        if(param === "status") {
            summary = {id: summary.id, status: summary.status}
        }
        if(param === "stops") {
            mutation = edit_load_stops
            summary = {id: summary.id, stops: summary.stops, status: summary.status}
        }
        // May need to change due to dispatcher field.
        if(param === "payor") {
            mutation = edit_load_payor
            summary = {id: summary.id, payor_id: summary.payor_id, status: summary.status}
        }
        if(param === "rate_items") {
            mutation = edit_load_rate_items
            let information = this?.view?.state?.data?.information?.scrub()
            let rate_items = this.view.state?.line_items
            // console.log("rate_items", rate_items)
            rate_items = rate_items?.filter((item) => item?.rate !== 0 && item?.id !== "linehaul").map((item) => { return item.scrub() })
            // console.log("rate_items", rate_items)
            summary = {id: summary.id, rate_items: rate_items, information: information, status: summary.status}
        }
        console.log("save load summary sls*", param)
        if(this?._notnull(param?.company?.id)){
            summary.payor_id = param.company.id
        }
        if(this?._notnull(param?.company?.company?.id)) {
            summary.payor_id = param.company.company.id
        }
        if(param === "approve") {
            summary = this.reform_load_summary(summary)
        }
        let data    = this.toUnquotedJSON(summary)
        let body    = JSON.parse(JSON.stringify(mutation))
        body.query  = body.query.replace("load", 'load(input: '+data+')')
        // reset the data, may need to remove when multi commodities gets implemented
        delete this.view.state.data.commodities
        await this.api.loads.ask({caller: this, params: {body: body}, callback: {f: this.process_load, fin : false}})
        // await this.api.loads.update({caller: this, params: {body: body}, callback: {f: this.process_load}})
    }
    reform_load_summary(summary) {
        if(summary?.payor_id === "reform") {
            delete summary.payor_id
            let _data = JSON.parse(JSON.stringify(this.company.summary))
            delete _data.contact; delete _data.company; delete _data.id; delete _data.number_of_trucks
            summary.payor = { contact: _data, company: _data }
        }
        if(summary?.pickup?.contact_id === "reform") {
           summary.pickup = this.format_reform_stop_summary("pickup")
        }
        if(summary?.dropoff?.contact_id === "reform") {
            summary.dropoff = this.format_reform_stop_summary("consignee")
        }
        return summary
    }
    format_reform_stop_summary(type) {
        let _data = JSON.parse(JSON.stringify(this?.[type]?.summary))
        delete _data.id; delete _data.contact_id
        let _address = undefined
        if(this?._notnull(this.consignee.data.address)) {  
            _address = JSON.parse(JSON.stringify(this?.[type]?.data.address))
            if(_address.address?.includes(",")) {
                let _split = JSON.parse(JSON.stringify(_address.address)).split(",")
                _address.address = _split[0]
                _address.address_2 = _split[1].trim()
            }
        }
        let _contact = JSON.parse(JSON.stringify(this?.[type].contact.summary.contact))
        _contact.address = _address
        delete _contact.id
        _data.contact = { contact: _contact, company: _contact }
        return _data
    } 
    process_load({caller, params, results}) {
        if(results?.errors !== undefined) {
            console.log("Load update error:", results.errors)
        }
        let load_result = results?.data?.load
        if(load_result?.payment_rate !== undefined) {
            caller.view.state.data.shipment.payment_rate = load_result?.payment_rate
        }
        if(load_result?.rate_items !== undefined) {
            let linehaul = load_result?.linehaul
            if((linehaul !== undefined && linehaul !== null)) {
                load_result?.rate_items?.push({id: "linehaul", rate_type: "flat_rate", number: 1, price: linehaul, rate: linehaul})
            }
            caller.view.state.data.shipment.rate_items = load_result?.rate_items
            caller.view.setState({line_items: caller.add_basic_items({items: load_result?.rate_items, linehaul: load_result?.linehaul})})
        }
        if(load_result?.commodities !== undefined) {
            caller.commodity?._sync({data:load_result.commodities})
        }
        // if(load_result?.information !== undefined) {
        //     caller.information?._sync({data:load_result.information})
        // }
        if(load_result?.notes !== undefined) {
            // console.log("Reloading notes...")
            caller.view.state.data.shipment.notes = results.data.load.notes
        }
        if(results.data?.load?.payor !== undefined) {
            caller.load_payor_or_dispatcher({load: results?.data?.load})
        }
        if(load_result?.stops !== undefined) {
            if (Array.isArray(load_result?.stops)) {
                results.data.load.stops.map((stop, index) => {
                    caller.get_stop({id: stop.id, name: "stops", index: index})
                })
            }
        }
        // console.log("Load updated",results?.data?.load?.id)
        if(caller?.view?.state?.rerender === true){
            caller.view.forceUpdate()
        }
        if(results?.data?.load?.id !== "undefined"  && results?.data?.load?.id !== undefined){
            // console.log("LOKKING FOR UNDEFINED",results.data.load.id)
            caller.load_existing_quote(results.data.load)
        }
    }
    add_basic_items({items, linehaul}) {
        let line_items = JSON.parse(JSON.stringify(items))
        let base_items = [{rate_type: "flat_rate"}, {rate_type: "fuel_surcharge"}, {rate_type: "accessorial_fee"}]
        // YES this check needs to be in this function to work as well
        if(line_items?.find((item) => item?.id === "linehaul") === undefined) {
            if((linehaul !== undefined && linehaul !== null)) {
                line_items?.push({id: "linehaul", rate_type: "flat_rate", number: 1, price: linehaul, rate: linehaul})
            }
        }
        base_items.map((bi, index) => {
            let item_count = line_items.filter((item) => { return item.rate_type === bi.rate_type })?.length
            if (item_count === 0) { line_items.push(bi) }
        })
        return line_items
    }
    handle_reform_totals({load, reform}) {
        if(!this?._notnull(reform)) { return }
        if(!Object.keys(reform)?.length) { return }
        // NEED TO ADD PENDING STATUS CHECK HERE AS WELL
        let keys = ["total_weight", "total_length", "total_value"]
        keys.map((k, i) => {
            if(load?.[k] === undefined || load?.[k] === null) {
                this.view.state.data.shipment[k] = reform?.[k]
            }
        })
    }
    set_reform_data(data) {
        if(typeof data === "object" && data !== null) {
            if(this?._notnull(data?.commodities)) {
                this.view.state.data.commodity = [{ name: data?.commodities }]
            }
            if(data?.po_number !== undefined) {
                if(this.view.state.data?.information === undefined) {
                    this.view.state.data.information = { po_number: data?.po_number}
                }
                this.view.state.data.information.po_number = data?.po_number
            }
            Object.keys(data).map((key, index) => {
                if(typeof data[key] === "object" && data[key] !== null) {
                    // console.log(key, this[key])
                    this[key]?._sync({data: data[key]})
                }
            }) 
            if(data?.company === undefined || data?.company === null) {
                let temp_payor = { id: "reform", address: {}, contact: {id: "reform", company: {id: "reform"}}, company: {id: "reform"} }
                this.company?._sync({data: temp_payor})
            }
        }
    }

    // Terrible mapper
    remap_reform(data) {
        if(typeof data === "object" && data !== null) {
            let reform_data = JSON.parse(JSON.stringify(data)).scrub()
            let obj = {}
            Object.keys(reform_data).map((key, index) => {
                if(typeof reform_data[key] === "object" && reform_data[key] !== null && !Array.isArray(reform_data[key])) {
                    let new_key;
                    switch (key) {
                        case "from_details":
                            new_key = "pickup"
                            break;
                        case "to_details":
                            new_key = "consignee"
                            break;
                        case "payor_details":
                            new_key = "company"
                            break;
                    }
                    obj[new_key] = reform_data[key]
                    obj[new_key].id = "reform"
                    delete reform_data[key]
                    Object.keys(obj[new_key]).map((k, i) => {
                        if(typeof obj[new_key][k] === "string" && !obj[new_key][k]?.length) { delete obj[new_key][k] }
                        if (k === "address") { obj[new_key].address = { address: obj[new_key][k], location: {} } }
                        if((k === "city" || k === "state" || k === "zip" || k === "country") && obj[new_key]?.address !== undefined) {
                            obj[new_key].address.location[k] = obj[new_key][k]
                            delete obj[new_key].address.location.country // NEED to review this line 
                            delete obj[new_key][k]
                        }
                        if(k === "contact_name") {
                            obj[new_key].name = obj[new_key][k]
                            delete obj[new_key][k]
                        }
                    })
                    if(obj[new_key]?.contact === undefined) {
                        let company = JSON.parse(JSON.stringify(obj[new_key]))
                        let contact = JSON.parse(JSON.stringify(company))
                        contact.company = company
                        obj[new_key].contact = contact
                        obj[new_key].company = company
                    }
                }
                if(key === "shipment_id") {
                    obj.po_number = reform_data[key]
                    delete reform_data[key]
                }
                obj[key] = reform_data[key]
            })
            // console.log("--- r1 remapped reform ---", obj.scrub())
            return obj.scrub()
        }
        return data
    }
}
