import _viewController from "controllers/ViewController/_viewController"
import ContactModelController from "controllers/ModelControllers/ContactModelController.jsx"
import company_request from "assets/graphql/Companies/Company.graphql.json"
import mutation_request from "assets/graphql/Contacts/Contacts.mutation.graphql.json"
import update_request from "assets/graphql/Contacts/Contacts.update.graphql.json"

export default class NewLoadModalController extends _viewController{
    constructor(props) {
        super(props)
        this.contact = new ContactModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true}})
    }
    handleCloseScreen() {
        this.resetScreen()
    }
    handleRoleClick(event) {
        let text = event.target.textContent.toLowerCase()
        this.setState(prevState => ({
            [text]: prevState?.[text] === "" ? text : ""
        }))
        this.modal_controller.toggle_role(text)
    }
    toggle_role(text) {
        this.contact[text] = !this.contact?.[text]
        this.view.forceUpdate()
    }
    follow_on_selection({event, obj, data}) {
        if (data?.name === "zip") { 
            this.contact.zip        = obj.zip
            this.contact.city       = obj.city
            this.contact.state      = obj.state
            this.contact.country    = obj.country
        }
        if (data?.name === "address") {
            this.contact.address    = obj?.address
            this.contact.address_2  = obj?.address_2
            this.contact.zip        = obj?.location?.zip
            this.contact.city       = obj?.location?.city
            this.contact.state      = obj?.location?.state
            this.contact.country    = obj?.location?.country
            this.setState({key: "search",
                            param: "zip.selected_value",
                            value: {zip: obj?.location?.zip} })
        }
        if (obj?.id !== undefined && data?.name === "company") {
            let body    = JSON.parse(JSON.stringify(company_request))
            body.query  = body.query.replace("company", 'company(id: "'+obj?.id+'")')
	        this.api.companies.ask({caller: this, params: {body: body}, nocache: true, callback: {f: this.process_company}})
        }
    }
    process_company({caller, params, results}) {
        caller.setState({key: ["search",
                                "search",
                                "data"],
                            param: ["address.selected_value",
                                    "zip.selected_value",
                                    "company"],
                            value: [{address: results?.data?.company?.address?.address},
                                    {zip: results?.data?.company?.address?.location?.zip},
                                    results?.data?.company]})
    }
    async save_action() {
        let body = undefined
        if (this.state?.data?.contact?.id === undefined) {
            body    = JSON.parse(JSON.stringify(mutation_request))
        } else {
            body    = JSON.parse(JSON.stringify(update_request))
        }
        let summary = JSON.parse(JSON.stringify(this.contact.summary))
        // Need to figure out how to resolve these in the API.
        delete summary.company?.image
        delete summary.company?.website
        let data = this.toUnquotedJSON(summary)
        body.query = body.query.replace("input:", 'input: '+ data)
        await this.api.contacts.create({caller: this, params: {body: body}, callback: {f: this.inject_data}})
    }
    inject_data({caller, params, results}) {
        let contact = (results?.data?.setContact?.id !== undefined) ? results?.data?.setContact : results?.data?.updateContact
        if (contact?.id !== undefined && contact?.id !== null) {
            let company = contact?.company
            let obj = { company: company, contact: contact }
            caller.view.controller.setState({key: "data", param: caller.view.type, value: obj})
        }
        caller.view.state.parent.toggle_modal({toggle_state: false})
    }
    cancel_action() {
        this.view.state.parent.toggle_modal({toggle_state: false})
        this.reset_data()
    }
    reset_data() {
        this.view.setState(prevState => ({reset: !prevState.reset}))
        delete this.view.state.data?.company
        delete this.view.state.search
    }
}