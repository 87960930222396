//GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button, Card } from "react-bootstrap";

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx";
import Controller from "./PaymentDetails.controller.jsx"
import Modal from "../../../EditPayment/EditPayment.modal.jsx"

import "./PaymentDetails.css"

export default class PaymentDetails extends _basePartial {
    constructor(props){
        super(props)
        this._modal = Modal
        this.partial_controller = new Controller ({params: {view: this, controller: this.controller}})
        this.total = 0
    }
    get rate_items() {
        let _rate_items = this.state?.parent?.state?.data?.line_items
        return _rate_items.filter(item => JSON.stringify(item) !== "{}")
    }
    format_rate_type(rate_type) {
        let words = rate_type?.replaceAll("_", " ").split(" ")
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
        return words?.join(" ")
    }
    renderView() {
        // console.log(this)
        this.partial_controller.check_basic_items()
        return(
            <Row id={this.name} className="cota_panel_border">
                <Card className="add_shadow g-0 padding">
                    <Row >
                        <Col>
                            <Row className="payment_details">
                                Payment Details
                            </Row>
                        </Col>
                    </Row>
                    <Row className="pad_left">
                        <Col>
                            <Row>
                                <Col className="po_bol">
                                    PO #
                                </Col>
                            </Row>
                            <Row >
                                <Col className="dash">
                                    {this.controller.info?.po_number ?? ""}
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row >
                                <Col className="po_bol">
                                    BOL #
                                </Col>
                            </Row>
                            <Row>
                                <Col className="dash_two">
                                    {this.controller.info?.bol_number ?? ""}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            < hr className="hr"/>
                        </Col>
                    </Row>
                    <Col style={{width:"70%", marginLeft:"3vw"}}>
                         {this.controller?.line_items?.map((item, index) => 
                            <Row className="no_wrap" style={{paddingTop:"5%"}} key={"payement_details_line_"+index}>
                                <Col>
                                    <Row className="pad_top">
                                        <Col className="types">
                                            {this.format_rate_type(item?.rate_type)}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col className="line_item">
                                            {'$' + this.partial_controller.format_rate(item?.rate)}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>)
                        }              
                    <Row className="no_wrap" style={{paddingTop:"3%"}}>
                        <Col>
                            <Row >
                                <Col className="line_item total_rate">
                                    Total Rate
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row >
                                <Col className="line_item total_rate">
                                {'$' + this.partial_controller.total_rate}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    </Col>
                    <Row>
                        &nbsp;
                    </Row>
                    <Row>
                        <Col>
                        <Button  onClick={() => this.toggle_modal({toggle_state: true})} sm={2} className="clear_button" style={{minWidth:"180px", whiteSpace:"nowrap", cursor:"pointer"}}>
                            Edit Payment Details
                        </Button>
                        </Col>
                    </Row>
                </Card>
            </Row>
        )
    }
}