// GENERAL REACT MODULES
import React from "react"
import { Row, Col, Button } from "react-bootstrap"

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./CompanyClaim.partial.controller.jsx"
import "./CompanyClaim.partial.css"
import config_data from "../Overlay.json"
import PayorObject from "components/NewLoadPartials/PayorPartial/Payor.partial.jsx"

export default class CompanyClaimPartial extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this, controller: this?.controller}})
        this.state.form = config_data.form_objects
    }
    get parent() {
        return this.state.parent
    }
    get disabled() {
        if(this.state?.data?.company?.id !== undefined && this.state?.data?.company?.id !== null) {
            return false
        }
        return true
    }
    check_company() {
        let company = this.parent.state?.company
        if(company !== undefined && this.parent.state?.created !== undefined) {
            this.state.data.company = company
            // delete this.parent.state.created
            delete this.parent.state.company
        }
    }
    render_button() {
        if(this.parent.state?.created) {
            return (
                <Button disabled={this.disabled} className={"confirm_button "+this._theme} onClick={this?.partial_controller?.confirm.bind(this)}>
                    Confirm
                </Button>
            )
        }
        return (
            <Button disabled={this.disabled} className={"confirm_button "+this._theme} onClick={this?.partial_controller?.submit_action.bind(this)}>
                Claim
            </Button>
        )
    }
    renderView() {
        // console.log(this)
        this.check_company()
        return (
            <Row id={this.name} className="absolute_dims">
                <Col>
                    <Row className="margin_auto generic_panel_object generic_100 claim_row" id={this.name}>
                        <Col className="generic_block_ul">
                            <Row className="generic_center company_title_question">
                                <Col>
                                    Company Name
                                </Col>
                            </Row>
                            <Row className="company_field">
                                <Col>
                                    <PayorObject params={{parent: this, controller: this.partial_controller, center: true,
                                            data: {company: this.partial_controller.company}, closeable: true}}/>
                                </Col>
                            </Row>
                            <Row className="mt-4">&nbsp;</Row>
                            <Row className="generic_override_flex padding_row">
                                <Col />
                                <Col>
                                    {this.render_button()}
                                    {/* <Button disabled={this.disabled} className={"confirm_button "+this._theme} onClick={this?.partial_controller?.submit_action.bind(this)}>
                                        Claim
                                    </Button> */}
                                </Col>
                                <Col />
                            </Row>
                            <Row>&nbsp;</Row>
                            {/* <Row className="generic_center_content">
                                <Col className="generic_center">
                                    <p>Company Not Listed?</p>
                                </Col>
                            </Row>
                            <Row className="generic_center_content">
                                <Col className="generic_center">
                                    <Button variant="link" className={"reject_button generic_center "+this._theme} onClick={this?.partial_controller?.cancel_action.bind(this)}>
                                        Add Your Company To Carrier Pro
                                    </Button>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col>
                                    <Row className="generic_center_content">
                                        <Col className="generic_center">
                                            Company Not Listed?
                                        </Col>
                                    </Row>
                                    <Row className="generic_center_content">
                                        <Col className="generic_center">
                                            <Button variant="link" className={"reject_button generic_center "+this._theme} onClick={this?.partial_controller?.cancel_action.bind(this)}>
                                                Add Your Company To Carrier Pro
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

