//REACT SPECFIC 
import React from "react";
import { Row, Col } from "react-bootstrap";

//MODULE SPECIFIC 
import Controller from "./IncomeExpenses.partial.controller.jsx"
import _basePartial from "components/General/_basePartial.jsx"
import income_expenses from "./IncomeExpense.json"
import "./IncomeExpenses.css"

export default class IncomeExpenses extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller  = new Controller({params: {view: this, controller: this?.controller}})
        this.state.form_configs  = income_expenses.form_objects
    }
    get filtered_logs() {
        let logs = this.controller.logs?.filter((el) => el?.details?.type !== "STATUS")
        return logs
    }
    render_load_items() {
        return this.controller.loads?.map((load) => {
            return (
                <Row key={"load_item_"+load?.id} className="pad_top_half g-0">
                    <Col className="details pad_top_25 text-end">
                        Load {(load?.company_load_number ?? load?.cota_id)}
                    </Col>
                    <Col className="color ps-2">
                        {this.partial_controller.format_currency(this.partial_controller.configure_rate(load))}
                    </Col>
                </Row>
            )
        })
    }
    render_log_items() {
        // NEED to change this to this.filtered_logs
        return this.controller.logs?.map((log) => {
            if(log?.value === null || log?.value === undefined || log?.value === 0) { return }
            return (
                <Row key={"log_item_"+log?.id} className="pad_top_half g-0">
                    <Col className="details pad_top_25 text-end">
                        {/* log.type   log.value */}
                        {log?.details?.title}
                    </Col>
                    <Col className="color ps-2">
                        {"("+this.partial_controller.format_currency(log?.value)+")"}
                    </Col>
                </Row>
            )
        })
    }
    renderView() {
        // console.log(this)
        return (
            <Row id={this.name}>
                <Col className="add_shadow padding cota_panel_border">
                    <Row className="g-0">
                        <Col>
                            <Row className="g-0">
                                <Col className="header ps-3">
                                    Income and Expenses
                                </Col>
                            </Row>
                            <Row className="pad_top g-0">
                                <Col className="expense_info text-end">
                                    Income
                                </Col>
                                <Col className="ps-2 total">
                                    {this.partial_controller.income}
                                </Col>
                            </Row>
                            {this.render_load_items()}
                           <Row className="g-0">
                                <Col>
                                    <hr/>
                                </Col>
                           </Row>
                           <Row className="g-0">
                                <Col className="expense_info text-end">
                                    Expenses
                                </Col>
                                <Col className="ps-2 total">
                                    {this.partial_controller.expenses}
                                </Col>
                            </Row>
                            {this.render_log_items()}
                            <Row className="g-0">
                                <Col>
                                    <hr className="hr"/>
                                </Col>
                           </Row>
                           <Row className="pad_bot g-0">
                                <Col className="expense_info text-end">
                                    Profit/Loss
                                </Col>
                                <Col className="info ps-2" style={{color: this.partial_controller.color}}>
                                    {this.partial_controller.profit_and_loss()}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}