import _viewController from "controllers/ViewController/_viewController.jsx"
import self_request from "assets/graphql/Users/self.graphql.json"

export default class AutoLoadModalController extends _viewController {
    constructor (props) {
        super(props)
        this.state={}

    }

    changeModal(view){
        console.log(view)
        // view.parent.state.change = true
        // view.parent.changeModal("C-006969")
        view?.state?.controller?.uploader?.view?.partial_controller?.upload()
    }

    upload_callback({results, caller, params}){
console.log(results)

if(results?.data?.reform?.carrier_load_number){
    caller.parent.toggle_modal({toggle_state: false})
    caller.parent.state.change = true
    caller.parent.changeModal(results?.data?.reform?.carrier_load_number)
}
    }
}