// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import CompanyModelController from "controllers/ModelControllers/CompanyModelController.jsx"
import ContactModelController from "controllers/ModelControllers/ContactModelController.jsx"
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class ReformPayorController extends _viewController {
    constructor(props) {
        super(props)
        this.company = new CompanyModelController({params: {parent: this, 
                                                            controller: this,
                                                            is_view: true}})
        this.contact = new ContactModelController({params: {parent: this, 
                                                                controller: this,
                                                                is_view: true}})
    }
    load_harness() {
        if (this.check_role("INTERNAL_COTA_USER")) {
            this.view.state.closeable = false
        }
    }
    // close() {
    //     if (this.check_role("INTERNAL_COTA_USER")) {
    //         this.controller.setState({key: "search", param: "company", value: {}})
    //         this.partial_controller.setState({key: "data", param: "company", value: {id: null}})
    //         if (this.controller?.company !== undefined) {
    //             this.controller.company.update({key: "data", param: "company", value: {id: null}})
    //         } else {
    //             this.controller.setState({key: "data", param: "company", value: {id: null}})
    //         }
    //     }
    // }
    close() {
        // should not appear if role does not exist
        this.partial_controller.setState({key: "search", param: "company", value: {}})
        this.partial_controller.setState({key: "data", param: "company", value: {id: null}})
        this.controller.setState({key: "data", param: "company", value: {id: null}})
    }
    follow_on_selection({event, obj, data}) {
        console.log("FOLLOWING ON SELECTION",obj,data, this)
	    this.setState({key: "data", param: "contact", value: obj})
        // DOES THIS MESS UP PAYORS ON NEW LOADS?
	    this.view.controller.company.data = obj
        if (this.controller?.contact !== undefined) {
            this.controller.contact.update(obj)
        } else {
            this.view.controller.setState({key: "data", param: "contact", value: obj})
        }
    }
}
