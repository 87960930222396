// GENERAL REACT MODULES
import React from "react";
import { FaImage } from "react-icons/fa"

// PAGE SPECIFIC MODULES
import _basePanelView from "components/General/_basePanelView.jsx"
import "./SettingsPage.css"
import config_data from "./SettingsPage.json"
import Controller from "./SettingsPage.controller"
import UploadPartial from "components/FileUpload/Upload.partial.jsx"
import UploadPartialController from "components/FileUpload/Upload.partial.controller.jsx"

// ALL OTHER MODULES
import { Row, Col, Button } from 'react-bootstrap';
import { Measurements, Menu, Row_Length } from "./SettingsDropdowns.jsx"


export default class SettingsPage extends _basePanelView {
    constructor(props) {
        super(props)
        this.controller         = new Controller({params: {view: this}})
        this.uploader = new UploadPartialController()
        this.state.states       = []
    }
    get form() {
        return config_data.form_objects
    }
    render_labeled_input({label, info, data, options}) {
        return (
            <Row className="mb_label_input">
                <Col>
                    <Row className="label_row">
                        <Col className="label">
                            {label} {(info !== undefined) && "( "+info+" )"}
                        </Col>
                    </Row>
                    <Row>
                        {/* Adding this key allows for rerender of the inputs, removing sticky text */}
                        <Col key={this.state.key_num+label+this.controller.user._id}>
                            {this.formObject({data: data, controller: this.controller, owner: this, options: options})}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
    render_driver_form() {
        if(!this.controller.driver) { return }
        return (<>
            <Row className="padding_row">
                <Col>
                    {this.render_labeled_input({label: "License", data: this.form.drivers_license})}
                    {this.render_labeled_input({label: "License State", data: this.form.license_state, options: this.state.states})}
                    {this.render_labeled_input({label: "License Type", data: this.form.license_type})}
                </Col>
                <Col>
                    {this.render_labeled_input({label: "Safety Date", info: "Last safety certification date", data: this.form.safety_date})}
                </Col>
            </Row>
            <Row>
                <Col>
                    <hr />
                </Col>
            </Row>
        </>)
    }
    renderMain() {
        return (
            <div id={this.name}>
                <Row className="generic_100 add_shadow cota_panel_object g-0">
                    <Col>
                        <Row className="mt-4 mb-4 padding_row">
                            <Col className="title">
                                User Details
                            </Col>
                        </Row>
                        <Row className="padding_row">
                            <Col sm={2}>
                                {/* <div className="icon_row icon_col">
                                    <FaImage className="icon" size={25} />
                                </div> */}
                                <UploadPartial params={{
                                    parent: this.view, 
                                    controller: this.controller, 
                                    endpoint: "user", 
                                    single_link: true, 
                                    // css_rules: "icon_row icon_col", 
                                    // image_css:"SettingsUserImage", 
                                    id:this?.state?.context?.self?.id, 
                                    existing_image: this?.state?.context?.self?.image?.[0],
                                    new_image: this.state?.current_image,
                                    view: this,
                                    save: this.state.save,
                                    uploaded: this.state.uploaded,
                                    disabled : false,
                                    }} />

                            </Col>
                            <Col>
                                <Row>
                                    {this.render_labeled_input({label: "First Name", data: this.form.first_name})}
                                </Row>
                                <Row>
                                    {this.render_labeled_input({label: "Email Address", data: this.form.email_address})}
                                </Row>
                            </Col>
                            <Col>
                                {this.render_labeled_input({label: "Last Name", data: this.form.last_name})}
                            </Col>
                            <Col xs="auto"/>
                        </Row>
                        <Row>
                            <Col>
                                <hr />
                            </Col>
                        </Row>
                        <Row className="padding_row">
                            <Col>
                                {this.render_labeled_input({label: "Home Phone", data: this.form.home_phone})}
                                {this.render_labeled_input({label: "Mobile Phone", data: this.form.mobile_phone})}
                            </Col>
                            <Col>
                                {this.render_labeled_input({label: "Work Phone", data: this.form.work_phone})}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <hr />
                            </Col>
                        </Row>
                        {this.render_driver_form()}
                        <Row className="mt-4 mb-4 padding_row">
                            <Col className="title_2">
                                Socials
                            </Col>
                        </Row>
                        <Row className="padding_row">
                            <Col>
                                {this.render_labeled_input({label: "Facebook", data: this.form.facebook})}
                            </Col>
                            <Col>
                                {this.render_labeled_input({label: "X", data: this.form.twitter})}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <hr />
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-4 padding_row">
                            <Col className="title_2">
                                Preferences
                            </Col>
                        </Row>
                        <Row className="padding_row">
                            {/* <Col>
                                {this.render_labeled_input({label: "Measurement System", data: this.form.measurement_system, options: Measurements})}
                            </Col> */}
                            <Col>
                                {this.render_labeled_input({label: "Menu Open", data: this.form.menu_open, options: Menu})}
                            </Col>
                            <Col>
                                {this.render_labeled_input({label: "Default Row Length", data: this.form.row_length, options: Row_Length})}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <hr />
                            </Col>
                        </Row>
                        <Row className="padding_row g-0 mb-3">
                            <Col>
                                <Button onClick={() => this.controller.reset()} className="clear_button min_button_width">
                                    Discard Changes
                                </Button>
                            </Col>
                            <Col sm={1} className="save_button">
                                <Button onClick={() => this.controller.save()} className={"primary_button_coloring min_button_width "+this._theme}>
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}
