// GENERAL REACT MODULES
import {Row, Col, Button, Container, Card} from "react-bootstrap"

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import Contact from "controllers/ModelControllers/ContactModelController.jsx"
import Company from "controllers/ModelControllers/CompanyModelController.jsx"
import contact_mutation from "assets/graphql/Contacts/Contacts.mutation.graphql.json"
import company_request from "assets/graphql/Companies/Company.graphql.json"

export default class NewContactController extends _viewController {
    constructor(props) {
        super(props)
        this.contact    = new Contact({params: {parent: this,
                                                controller: this,
                                                is_view: true}})
  
        this.requests   = [{callback: {f: this.state_results, p: {name: "areas", var: ["areas", "states"]}}, 
                            endpoint: "areas", replace: {o: "params:", r:'params: {country: "US"}'}}]
    }
    state_results({caller, params, results}) {
        if (results?.errors === undefined && results?.data?.areas !== undefined) {
            results.data.areas.map((area, index) => {
                caller.view.state.states.push({ value: area.state, label: area.state })
            })
        }
    }
    close({save: save}) {
        if(save) {
            this.save()
        } else {
            this.cancel()
        }
        // this.view.state.panel_params.controller.view.resetScreen()
    }
    format_mutation() {
        let data = JSON.parse(JSON.stringify(this.contact.summary))
        delete data.company.number_of_trucks
        data = this.toUnquotedJSON(data)
        let body = JSON.parse(JSON.stringify(contact_mutation))
        body.query = body.query.replace("input:", "input: "+data)
        return body
    }
    save() {
        // console.log(this.contact)
        if(this.contact._sum <= 0) { return }
        let body = this.format_mutation()
        this.api.contacts.create({caller: this, params: {body: body}, callback: {f: this.process_save}})
    }
    process_save({caller, params, results}) {
        caller.view.setState({_is_mutating: false})
        caller.view.state.panel_params.controller.view.resetScreen()
    }
    cancel() {
        this.controller?.resolveCache()
        this.view.state.panel_params.controller.view.resetScreen()
    }
    resolveCache() {
        if (this.view.state?.cache !== undefined) {
            this.view.state.context.contact = this.getCopy(this.view?.state?.cache)
            this.setState({key: "data", param: "contact", value: this.getCopy(this.view?.state?.cache)})
            this.view.forceUpdate()
        }
    }
    handleRoleClick(event) {
        let text = event.target.textContent.toLowerCase()
        this.view.setState(prevState => ({
            [text]: prevState?.[text] === "" ? text : ""
        }))
        this.toggle_role(text)
        this.handleCredit(text)
    }
    toggle_role(text) {
        this.contact[text] = !this.contact?.[text]
        this.view.forceUpdate()
    }
    handleCredit(text) {
        if(text !== "payor") {
            return
        }
        this.view.setState(prevState => ({credit: !prevState.credit}))
    }
    editable_field({data, label, options}) {
        return (
            <Col>
                <Row>
                    <Col className='info'>
                        {label}
                    </Col>
                </Row>
                <Row className="g-0">
                    <Col>
                        {this.view?.formObject({data: data, owner: this.view, options: options})}
                    </Col>
                </Row>
            </Col>
        )
    }
    edit_button_row() {
        return (
            <>
                <Col></Col>
                <Col></Col>
                <Col className='no_wrap'
                    xs='auto'
                    onClick={this.close.bind(this)}
                    tabIndex={this.view.tab("cancel")}>
                        <Button size="sm" variant="secondary" className='btn_secondary'>
                            Cancel
                        </Button>
                </Col> 
                <Col className='edit_contact no_wrap' xs='auto'>
                    <Button size="sm" className={this._theme}
                        onClick={this.close.bind(this, {save: true})}
                        tabIndex={this.view.tab("save")}>
                        Save
                    </Button>
                </Col>
                <Col xs="auto"></Col> 
            </>
        )
    }
    async follow_on_selection({event, obj, data}) {
	    // console.log("--- FOLLOW ON SELECTION ---")
        if (data?.name === "zip") { 
            this.contact.zip        = obj.zip
            this.contact.city       = obj.city
            this.contact.state      = obj.state
            this.contact.country    = obj.country
        }
        if (data?.name === "address") {
            this.contact.address    = obj?.address
            this.contact.address_2  = obj?.address_2
            this.contact.zip        = obj?.location?.zip
            this.contact.city       = obj?.location?.city
            this.contact.state      = obj?.location?.state
            this.contact.country    = obj?.location?.country
            this.setState({key: "search",
                            param: "zip.selected_value",
                            value: {zip: obj?.location?.zip} })
        }
        if (obj?.id !== undefined && data?.name === "company") {
            let body    = JSON.parse(JSON.stringify(company_request))
            body.query  = body.query.replace("company", 'company(id: "'+obj?.id+'")')
            this.api.companies.ask({caller: this, params: {body: body}, nocache: true, callback: {f: this.process_company}})
        }
    }
    process_company({caller, params, results}) {
        caller.setState({key: ["search",
                                "search",
                                "data"],
                            param: ["address.selected_value",
                                    "zip.selected_value",
                                    "company"],
                            value: [{address: results?.data?.company?.address?.address},
                                    {zip: results?.data?.company?.address?.location?.zip},
                                    results?.data?.company]})
    }
}
