// GENERAL REACT MODULES
import React from "react";

// PAGE SPECIFIC MODULES
import "./Dashboard.css"
import Controller from "./Dashboard.controller.jsx"

// ALL OTHER MODULES
import Sidebar from "components/Sidebar/Sidebar.view"
import _baseViewComponent from "components/General/_baseViewComponent.jsx"
import Background from "components/Background/Background.view"
import { MainConsumer } from "controllers/MainContext.jsx"
import ModuleContext from "module/controllers/ModuleContext.jsx"
import ApiController from "controllers/ApiController/ApiController.jsx"

class Dashboard extends _baseViewComponent {
    constructor(props) {
        super(props)
        this.controller = new Controller({params: {view: this}})
        if (props.authClient) {
            this.state = {
                loading: true,
                authClient: props?.authClient,
                window: "main",
                api: new ApiController()
            }
        }
    }
    followDidMount() {
        this.load_self(this.state.context)
        this.setState({authenticated: this.state?.authClient?.authenticated,
                            token: this.state?.authClient?.token,
                            refreshToken: this.state?.authClient?.refreshToken,
                            realm: this.state?.authClient?.realm,
                            pages: this?.context?.getPages()})
    }
    render_side_bar() {
        if (process.env.REACT_APP_DISABLE_SIDEBAR) {
            return (<></>)
        }
        return (<Sidebar params={{parent: this, authClient: this.state.authClient, display: "none"}} ref={(sidebar) => { this.sidebar = sidebar; }} />)
    }
    get flex() {
        if (this.state?.context?.is_mobile()) {
            return "mobile_app"
        }
        return "app"
    }
    renderInnerView() {
        return(
            <div className={"fullscreen root_css dashboard_color "+this._theme+" "+this.flex}>
                {this.render_side_bar()}
                <div className={"dashboard MainScreen "+this._theme}>
                    {this.controller.renderMainWindow()}
                    {/* {this.controller.getChatMenu({chatToggle: true})} */}
                    {/* <ChatMenu params={{parent: this}}/> */}
                </div>
            </div>
        )
    }
    async load_self(context) {
        this.state.loading = true
        context.get_self({caller: this.controller, update: true})
    }
    renderView() {
        if (this.state.failed) {
            return ( 
                <Background params={{parent: this}}/>
            )
        }
        return (
            <MainConsumer>
                {(context) => {
                    context.authClient = this.state.authClient
                    context.app = this
                    context.api.parent = this
                    if (process.env.REACT_APP_NOLOGIN) {
                        {return this.renderInnerView()}
                    }
                    if (this.state.authClient && !this.state.loading) {
                        if (this.state.authClient.authenticated) {
                            {return this.renderInnerView()}
                        } else {
                            if (context.authClient !== undefined) {
                                context.authClient?.login()
                            }
                        }
                    }
                    return (<Background />)
                }}
            </MainConsumer>
        )
    }
}
Dashboard.contextType = ModuleContext

export default Dashboard;
