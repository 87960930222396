// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
// import LineItemModelController from "controllers/ModelControllers/Quotes/LineItemModelController"

export default class ListNavController extends _viewController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    selected(e) {
        this.view.controller.setState({key: "page_length", value: e})
    }
    nav(direction) {
        if (direction) {
            this.setState({page: this.state.page+1, max_pages: this.state.max_pages+1})
            this.controller.setState({key: "page", value: this.state.page+1})
        } else {
            if (this.state.page > 1) {
                this.setState({page: this.state.page-1})
                this.controller.setState({key: "page", value: this.state.page-1})
            }
        }
    }
    refresh_results() {
        this.controller.view.state.called = undefined
        this.controller?.view?.forceUpdate()
    }
    search_field() {
        if (!this.view?.state?.is_bottom) {
            let search_fields = this.view?.controller?.view?.state?.search_fields
            let search_fields_config = {
                "title": "search_fields",
                "name": "query",
                "type": "text",
                "placeholder": "Search "+search_fields
            }

            if (search_fields !== undefined) {
                return (
                    <>
                        {this.view?.formObject({data: search_fields_config, 
                                                        options: undefined,
                                                        controller: this,
                                                        owner: this})}
                    </>
                )
            }
        }
    }
}