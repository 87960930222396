// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import StopModelController from "controllers/ModelControllers/StopModelController.jsx"

export default class AddStopModalController extends _viewController {
    constructor(props) {
        super(props)
        this.pickup    = new StopModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true,
                                                            _param: "pickup"}})
        this.consignee = new StopModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true,
                                                            _param: "consignee"}})
    }
    get stop_index() {
        let type = (this.view.type === "consignee") ? "dropoff" : this.view.type
        return this.view.controller.view.state.stops[type]?.length
    }
    get contact() {
	    return this?.[this.view.type]?.contact
    }
    get people() {
        return this.view.state.people
    }
    handleCloseScreen() {
        this.resetScreen()
    } 
    follow_on_selection({event, obj, data}) {
        this.view.setState({key: data?.title, param: data?.name, value: {search_value: ""}})
        this.api?.contacts?.gid({caller: this, value: obj?.id, callback: {f: this.process_loaded_contact, p: {name: data?.name}}})
    }
    process_loaded_contact({caller, params, results}) {
        caller.view.state.data[caller.view.type].contact = results?.data?.contact
        if(results?.data?.contact?.company?.employees !== undefined) {
            caller.view.state.people = results?.data?.contact?.company?.employees
        }
        caller.view.forceUpdate()
    }
    select_person(index) {
        let person = this.modal_controller.people[index]
        this.modal_controller[this.modal_controller.view.type].rep = person
        // console.log(this.modal_controller[this.modal_controller.view.type])
        this.state.parent.toggle_modal({toggle_state: undefined})
    }
    save_action() {
        let note = this.view.state.note
        let summary = JSON.parse(JSON.stringify(this[this.view.type].summary))
        let data = JSON.parse(JSON.stringify(this[this.view.type].data))
        summary.contact = data.contact
        summary.representative = data.representative 
        summary.index = this.stop_index
        // still need to figure out how to incorporate notes
        this.view.controller.view.state.data.stops.push(summary)
        this.view.controller.update("stops")
        this.close()
    }
    close() {
        this.view.setState(prevState => ({reset: !prevState.reset}))
        delete this.view.state.search
        delete this.view.state.note
        this.view.state.people = []
        delete this.view.state.data?.[this.view.type]
        this.view.state.parent.toggle_modal({toggle_state: false})
    }
}