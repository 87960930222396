// GENERAL REACT MODULES
import { Row, Col, Button } from 'react-bootstrap'
import { FaImage } from "react-icons/fa";

//MODULE SPECIFIC
import _basePanelView from "components/General/_basePanelView.jsx"
import "../ContactPanel.css"
import config from "../ContactPanel.json"
import RoleButtonRow from '../Partials/RoleButtonRow/RoleButtonRow.partial';
import UploadPartial from "components/FileUpload/Upload.partial.jsx"


export default class EditContactPanel extends _basePanelView {
    constructor(props) {
        //fdsfdsfsefew
        super(props)
        this.controller             = props.params.controller
        this.state.form             = config.form_objects
    }
    renderMain() {
     //  console.log("RENDERING",this)
        return (
            <>
                <Row className='padding' id={this.name}>
                    <Col xs="auto">
                        {/* <Row className='icon_row icon_col' type='button'>
                            <FaImage size={25} className="icon"/>
                        </Row> */}

                                <UploadPartial params={{
                                    parent: this.view, 
                                    controller: this.controller, 
                                    endpoint: "contact", 
                                    single_link: true, 
                                    // css_rules: "image", 
                                    edit: true,
                                  //  image_css: { display: block, height: 10rem, width: 10rem border-radius: 3rem}, 
                                    id:this?.controller?.contact?.id, 
                                    existing_image: this?.controller?.contact?.image,
                                    new_image: this.controller?.view?.state?.current_image,
                                    view: this?.controller?.view,
                                    save: this.controller?.view?.state?.save,
                                    uploaded: this?.controller?.view?.state.uploaded
                                    }} />
                    </Col>
                    <Col xs="auto"></Col>
                    <Col sm={6}>
                        <Row className='pad_top'>
                            <Col className='name'>
                                {this.controller.editable_field({data: this.state.form.company, label: "Contact Name"})}
                            </Col>
                        </Row>
                        <RoleButtonRow params={{parent: this.parent, controller: this.controller}}/>
                    </Col>
                    <Col />
                </Row>
                <Row className='pad_top'>
                    <Col>
                        < hr/>
                    </Col>
                </Row>
                <Row className='padding'>
                    <Col sm={5}>
                        <Row>
                            {this.controller.editable_field({data: this.state.form.address, label: "Address"})}
                        </Row>
                        <Row className='pad_top_8'>
                            {this.controller.editable_field({data: this.state.form.address_2, label: "Address line 2"})}
                        </Row>
                        <Row className='pad_top_8'>
                            {this.controller.editable_field({data: this.state.form.city, label: "City"})}
                        </Row>
                        <Row className='pad_top_8'>
                            <Col>
                                {this.controller.editable_field({data: this.state.form.state, options: this.controller.getState({key: "states"}), label: "State/Province"})}
                            </Col>
                            <Col>
                                {this.controller.editable_field({data: this.state.form.zip, label: "Zip Code"})}
                            </Col>
                        </Row>
                        <Row className='pad_top_8'>
                            {this.controller.editable_field({data: this.state.form.country, options: this.controller.getState({key: "countries"}), label: "Country"})}
                        </Row>
                        <Row className='pad_top_8'>
                            {this.controller.editable_field({data: this.state.form.email, label: "Email Address"})}
                        </Row>
                        <Row className='pad_top_8'>
                            {this.controller.editable_field({data: this.state.form.phone, label: "Phone"})}
                        </Row>
                        <Row className='pad_top_8'>
                            {this.controller.editable_field({data: this.state.form.fax, label: "Fax"})}
                        </Row>
                        <Row className='pad_top_8' >
                            {this.controller.editable_field({data: this.state.form.website, label: "Website"})}
                        </Row>
                    </Col>
                    <Col sm={1} />
                    <Col sm={5}>
                        {this.controller.credit_form(this)}
                        <Row className={this.credit_fields ? "pad_top_8" : ""}>
                            {this.controller.editable_field({data: this.state.form.facebook, label: "Facebook"})}
                        </Row>
                        <Row className='pad_top_8'>
                            {this.controller.editable_field({data: this.state.form.twitter, label: "Twitter"})}
                        </Row>
                                <Row className='pad_top_8'>
                                    <Col>
                                        <Row>
                                            <Col className='info MuiInputLabel-shrink' >
                                                Hours of Operation
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='no_border' >
                                                {this.controller.editable_field({data: this.state.form.hours_open})}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={1}>
                                        <Row>
                                            <Col>
                                                &nbsp;
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='row_dash'>
                                                -
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                &nbsp;
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.controller.editable_field({data: this.state.form.hours_close})}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <hr />
                    </Col>
                </Row>
                <Row className='align-items-middle'>
                    {this.controller.edit_button_row()}
                </Row>
            </>
        )
    }
}
