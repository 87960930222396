// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
// import LineItemModelController from "controllers/ModelControllers/Quotes/LineItemModelController"
import ListLineController from "components/CotaListPanel/ListLineController.jsx"

export default class TriColumnController extends ListLineController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    get item_data() {
        return this.view.item_data
    }
    get _configs() {
        return this.view._configs
    }
    font_color() {
        if (this.view?.parent?.state?.hovered) {
            return "white"
        }
        return ""
    }
    get_col_color(row_num) {
        let color = this._configs?.columns?.[this?.view?.state?.item]?.c?.[row_num]
        return (color === undefined) ? "#000" : color
    }
}
