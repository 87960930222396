import { Button, Row, Col, Card, Container} from "react-bootstrap";
import { FaImage } from "react-icons/fa"

import _basePanelView from "components/General/_basePanelView.jsx"
import _baseViewComponent from "components/General/_baseViewComponent"
import Controller from "./EditTruckPanel.controller.jsx";
import form_configs from "./EditTruckPanel.json"
import "./EditTruckPanel.css";
import CotaUserBar from "components/CotaUserBar/CotaUserBar.partial.jsx"
import HelperPanel from "components/HelperPanel/HelperPanel.partial.jsx"

export default class EditTruckPanel extends _basePanelView {
    constructor(props) {
        super(props);
        this.state.form             = form_configs.form_objects
        this.state.form_configs     = form_configs
        this.controller             = new Controller({ params: { view: this }});
        this.state.panel_name       = "edit_truck"
        this.state.edit_mode        = false
        this.handleProcessEvent     = this.handleProcessEvent.bind(this)
        this.state.more_info        = true
        this.state.status_options   = []
        this.state.states           = []
    }
    toggleMoreInfo = () => {
        this.setState((prevState) => ({
            more_info: !prevState.more_info
        }))
    }
    render_length() {
        if(this.controller.asset.is_boxtruck) {
            return (<>
                <Row className="pad_top">
                    <Col className="info MuiInputLabel-shrink">
                        LENGTH, FT
                    </Col>
                </Row>
                <Row className="pad_quarter">
                    <Col>
                    {this.formObject({data: this.state.form.length, owner: this})}
                    </Col>
                </Row>
            </>)
        }
    }
    renderMain() {
        const { more_info } = this.state
        return (
            <Container id={this.name} className="generic_100">
                <Row className="max_height">
                    <Col className="flex_wrapper_rows">
                        <Row>
                            <CotaUserBar params={{parent: this, controller: this.controller, title: "assets", return: true}} />
                        </Row>
                        <Row className="generic_100 margin_auto">
                            <Col>
                                <div className="g-0 cota_panel_border add_shadow pb-4">
                                    <Row className="padding info">
                                        <Col>
                                            <Row className="pad_top no_wrap">
                                                <Col sm={1}>
                                                    <Row>
                                                        <Col>
                                                            <div className="icon_row icon_col" type="button">
                                                                <FaImage className="icon" size={25} onClick={this.controller.handleClick} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                {/* <Col xs="auto" onClick={this.controller.handleClick}> */}
                                                    {/* <Row>
                                                        <Col>
                                                            <div className="image_block">
                                                                {this.props.selectedImage ? (
                                                                <img src={this.props.selectedImage} className="selected_image" alt = "Selected Image"/>
                                                                ) : (
                                                                <IoMdImage className="immd_class" size={20} />
                                                                )}  
                                                        </div>
                                                        </Col>
                                                    </Row> */}
                                                {/* </Col> */}
                                                <Col sm={5} className="pad_top pad_left">
                                                    <Row className="no_wrap">
                                                        <Col className="info MuiInputLabel-shrink">
                                                            TRUCK NUMBER *
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            {this.formObject({ data: this.state?.form?.truck_number })}
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-3">
                                                        <Col>
                                                            {this.formObject({data: this.state.form?.is_boxtruck })}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <div style={{ display: more_info ? "none" : "block" }}>
                                            <Row className="pad_two">
                                                < hr className="hr"/>
                                            </Row>
                                                <Row>
                                                    <Col sm={5}>
                                                        <Row>
                                                            <Col className="gi">
                                                                General Info
                                                            </Col>
                                                        </Row>
                                                        <Row className="pad_top">
                                                            <Col  className="info MuiInputLabel-shrink">
                                                                MAKE
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                            {this.formObject({ data: this.state?.form?.make })}
                                                            </Col>
                                                        </Row>
                                                        <Row className="pad_top">
                                                            <Col className="info MuiInputLabel-shrink">
                                                                MODEL
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                            {/* {console.log(this.state,"STATE")} */}
                                                            {this.formObject({ data: this.state?.form?.model, owner:this})}
                                                            </Col>
                                                        </Row>
                                                        <Row className="pad_top">
                                                            <Col className="info MuiInputLabel-shrink">
                                                                YEAR
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                            {this.formObject({ data: this.state?.form?.year })}
                                                            </Col>
                                                        </Row>
                                                        <Row className="pad_top">
                                                            <Col className="info MuiInputLabel-shrink">
                                                                WEIGHT,LB
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                            {this.formObject({ data: this.state?.form?.weight })}
                                                            </Col>
                                                        </Row> 
                                                        {this.render_length()}
                                                        <Row className="pad_top">
                                                            <Col className="info MuiInputLabel-shrink">
                                                                COLOR
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                            {this.formObject({ data: this.state?.form?.color })}
                                                            </Col>
                                                        </Row> 
                                                        <Row className="pad_top">
                                                            <Col className="info MuiInputLabel-shrink">
                                                                LICENSE PLATE #
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                            {this.formObject({ data: this.state?.form?.license_plate })}
                                                            </Col>
                                                        </Row>
                                                        <Row className="pad_top">
                                                            <Col className="info MuiInputLabel-shrink">
                                                                VIN #
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                            {this.formObject({ data: this.state?.form?.vin })}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs="auto"></Col>
                                                    <Col sm={5}>
                                                        <Row className="pad_top">
                                                            <Col className="gi">
                                                                Current mileage and location
                                                            </Col>
                                                        </Row>
                                                        <Row className="pad_top">
                                                            <Col className="info MuiInputLabel-shrink">
                                                                CURRENT MILEAGE
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                            {this.formObject({ data: this.state?.form?.current_miles })}
                                                            </Col>
                                                        </Row>
                                                        <Row className="pad_top">
                                                            <Col className="info MuiInputLabel-shrink">
                                                                CURRENT LOCATION
                                                            </Col>
                                                        </Row>
                                                        <Row className="pad_quarter">
                                                            <Col>
                                                                {this.formObject({ data: this.state.form.city })}
                                                            </Col>
                                                        </Row>
                                                        <Row className="pt-3">
                                                            <Col>
                                                                {this.formObject({ data: this.state.form.state, owner: this, options: this.state.states })}
                                                            </Col>
                                                            <Col>
                                                                {this.formObject({ data: this.state.form.zip, owner: this })}
                                                            </Col>
                                                        </Row>
                                                        <Row className="pad_top">
                                                            <Col>
                                                                < hr className="hr" />
                                                            </Col>
                                                        </Row>
                                                        <Row className="pad_top">
                                                            <Col className="gi">
                                                                Starting mileage and location
                                                            </Col>
                                                        </Row>
                                                        <Row className="pad_top">
                                                            <Col className="info MuiInputLabel-shrink">
                                                                STARTING MILEAGE, MILES
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                            {this.formObject({ data: this.state?.form?.starting_miles })}
                                                            </Col>
                                                        </Row>
                                                        <Row className="pad_top">
                                                            <Col className="info MuiInputLabel-shrink">
                                                                STARTING ZIP CODE
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                            {this.formObject({ data: this.state?.form?.starting_zip })}
                                                            </Col>
                                                        </Row>
                                                        <Row className="pad_top">
                                                            <Col>
                                                                < hr className="hr" />
                                                            </Col>
                                                        </Row>
                                                        <Row className="pad_top">
                                                            <Col className="gi">
                                                                Registration Details
                                                            </Col>
                                                        </Row>
                                                        <Row className="pad_top">
                                                            <Col className="info MuiInputLabel-shrink">
                                                                REGISTRATION #
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                            {this.formObject({ data: this.state?.form?.registration_number })}
                                                            </Col>
                                                        </Row>
                                                        <Row className="pad_top">
                                                            <Col className="info MuiInputLabel-shrink">
                                                                REGISTRATION STATE
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                            {this.formObject({ data: this.state?.form?.registration_state, options: this.state?.states, })}
                                                            </Col>
                                                        </Row>
                                                        <Row className="pad_top">
                                                            <Col className="info MuiInputLabel-shrink">
                                                                REGISTRATION DATE
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                            {this.formObject({ data: this.state?.form?.registration_date })}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row className="pad_top">
                                            <hr className="hr"/>
                                            </Row>
                                            <Row className="pad_bot">
                                                <Col>
                                                    <Button onClick={this.toggleMoreInfo} className={"primary_button_coloring "+this._theme}>
                                                        {(this.state.more_info) ? "Show More Info" : "Close Info"}
                                                    </Button>
                                                </Col>
                                                <Col></Col>
                                                <Col>
                                                    <Button className={"center_button clear_button "+this._theme} onClick={() => this.controller.handleCloseScreen()}>                                             
                                                        Cancel
                                                    </Button> 
                                                </Col>
                                                <Col>
                                                    <Button className={"primary_button_coloring "+this._theme} onClick={this?.controller?.saveAction.bind(this)}>
                                                        Save
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <HelperPanel params={form_configs?.tooltips?.trucks}/>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}