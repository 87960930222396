//GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import {FaUser} from "react-icons/fa";

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx";
import Controller from "./ChangeDriver.partial.controller.jsx";
import "./ChangeDriverPartial.css";
import Modal from "./ChangeDriverModal/ChangeDriverModal.view.jsx"

export default class ChangeDriverPartial extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller     = new Controller({params: {view: this, controller: this?.controller}})
        this._modal                 = Modal 
        this.state.showChangeDriver = false
    }
    componentDidMount() {
        this._isMounted = true
        this.partial_controller.load_data()
    }
    renderView() {
        return (
            <Row id={this.name}>
                <div className="cota_panel_border g-0 add_shadow">
                    <Row className="pad_3">
                        <Col className="center">
                            <Row className="icon_row">
                                <Col className="center">
                                    <FaUser size={30} className="icon" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="ta_center pad_1">
                        <Col className="center">
                            <Button className="btn user" onClick={() => this.controller.view_driver({id: this.controller?.asset?.data?.driver?.id})}>
                                {/* NEED to put a model controller here for the driver */}
                                {this.controller?.asset?.data?.driver?.first_name} {this.controller?.asset?.data?.driver?.last_name}
                            </Button>
                        </Col>
                    </Row>
                    <Row className="ta_center pad_top_half">
                        <Col className="avai">
                            Assigned
                        </Col>
                    </Row>
                    <Row className="pad_1 pad_bot">
                        <Col className="center">
                            <Button className="btn" onClick={() => this.toggle_modal({toggle_state: true})}>
                                Unassign Driver
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Row>
        )
    }
}