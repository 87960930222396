//GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button } from "react-bootstrap";
//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx";
import Controller from "./CompanyCard.partial.controller.jsx";
import Modal from "../../../EditShipperModal/EditShipper.modal.jsx";
import ContactModal from "./NewLoadModal/NewContactLoad.modal.jsx"
import EditContactModal from "./EditContactModal/EditContact.modal.jsx";
import RoleObject from "components/NewLoadPartials/ShipperObject/Shipper.partial.jsx"

import "./CompanyCard.css";

export default class CompanyCard extends _basePartial {
    constructor(props) {
        super(props)
        this._modal = Modal
        this.partial_controller = new Controller({ params: { view: this, controller: this.controller } })
        this.state.type = this.partial_controller.type
        this.state.edit_contact = false
    }
    get index() {
        return this.props?.params?.index
    }
    get stop_type() {
        return this.props?.params?.stop_type
    }
    get no_edit() {
        // May need this getter for permission checks, so dont delete
        if(this.controller.load.created_on == "carrierpro"){
            return false
        }
        return true
        // if(this?.state?.role === "stops") { return true }
    }
    get no_close() {
        if(this?.state?.role === "stops") { return true }

        if(this.controller.load.created_on == "carrierpro"){
            return false
        }
        return true
    }
    get role_css() {
        if(this.state?.role === "stops") {
            return (this.stop_type === "pickup") ? "shipper" : this.stop_type
        }
        return this.state?.type
    }
    get role() {
        if(this.state?.role === "stops") {
            return (this.stop_type === "consignee") ? "dropoff" : this.stop_type
        }
        return this.state?.role
    }
    get type() {
        return this.state.type
    }
    get_contact_data() {
        if(this.controller?._notnull(this.controller?.[this.type]?.data)) {
            return JSON.parse(JSON.stringify(this.controller[this.type].data))
        }
    }
    changeModal() {
        this.state.edit_contact = true
        this._modal = EditContactModal
        this.toggle_modal({ toggle_state: true })
    }
    reset_modal(){
        this.state.edit_contact = false
        this._modal = Modal
        this.toggle_modal({ toggle_state: false })
    }
    render_delete() {
        if(this.state?.role !== "stops") { return <></> }
        return (
            <Row  className="pb-1">
                <Col className="d-flex justify-content-end pe-1">
                    <Button className="delete_btn" onClick={this.partial_controller.delete_stop.bind(this)}>
                        Delete stop
                    </Button>
                </Col>
            </Row>
        )
    }
    render_edit_contact() {
        if(this.controller?.[this.type]?._id === null || this.controller?.[this.type]?._id === undefined) { return }
        if(this.controller.load?._id && this.controller.load?.status === "Pending") {
            return (
                <Row  className="pb-1">
                    <Col className="d-flex justify-content-end pe-1">
                        <Button className="delete_btn" onClick={() => this.changeModal()}>
                            Edit Contact
                        </Button>
                    </Col>
                </Row>
            )
        }
    }
    renderView() {
        if(this.state.edit_contact) {
            this._modal = EditContactModal
        } else if (this.controller[this.partial_controller.type]?._id === undefined && this.type !== "stops") {
            this._modal = ContactModal
        } else {
            this._modal = Modal
        }
        return (
            <Row id={this.name} className="cota_panel_border">
                <Col className=" add_shadow">
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    &nbsp;
                                </Col>
                                <Col sm={2} className={"g-0 uc_text tag " +this.role_css}>
                                    {this.role}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <RoleObject params={{parent: this, controller: this.controller, 
                                            type: this.partial_controller.type, editable: true,
                                            borderless: true, title: false, padding: true,
                                            // modal: true, index: this?.state?.index,
                                            modal: true, index: this.index,
                                            changeModal: true,
                                            no_edit: this.no_edit, no_close: this.no_close }}/>
                    {this.controller.load.created_on == "carrierpro" && this.render_delete()}
                    {this.render_edit_contact()}
                </Col>
            </Row>
        )
    }
}
