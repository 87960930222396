import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import _basePartial from "components/General/_basePartial.jsx";
import Controller from "./DocumentRow.controller.jsx"
import "./DocumentRow.css"

export default class DocumentRow extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this, controller: this?.controller}})
        this.state.added = false
    }
    get document() {
        return this.state?.document
    }
    get css() {
        return "clear_button btn_width "+this?._theme
    }
    render_add_or_remove() {
        return (
            <input type="checkbox" value={this.state.added} onClick={() => this.partial_controller.handle_doc()}/>
        )
    }
    renderView() {
        // console.log(this)
        return (
            <Row id={this.name} key={this.document?.id+this.state.index} className="mt-2">
                <Col sm={6}>
                    {this.document?.name}
                </Col>
                <Col sm={2}>
                    {this.document?.document_type?.label}
                </Col>
                <Col sm={4} className="d-flex">
                   {this.render_add_or_remove()} 
                </Col>
            </Row>
        )
    }
}