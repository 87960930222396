// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import ShipmentModelController from "controllers/ModelControllers/Shipping/ShipmentModelController.jsx"

export default class StatusButtonsController extends _viewController {
    constructor(props) {
        super(props)
        this.shipment = new ShipmentModelController({params: {
                                                        parent: this,
                                                        controller: this,
                                                        is_view: true}})
    }
    get line_items() {
        return this.controller.view.state.data.line_items
    }
    get po_number() {
        return this.controller.view.state.data?.information?.po_number
    }
    get bol_number() {
        return this.controller.view.state.data?.information?.bol_number
    }
    save_action() {
        if (this.check_billed() === false) { return }
        this.controller.load.status = this.view.state.data.shipment.status
        // this.controller.view.forceUpdate()
        this.controller.update("status")
        this.close()
    }
    check_billed() {
        if (this.view.state.data.shipment.status?.toLowerCase() === "billed") {
            if (this.line_items?.length <= 1 && this.controller.view.state.data.shipment?.total_amount === undefined) {
                 alert("Please add a rate item"); return false 
            }
            if ((this.bol_number === undefined && this.bol_number === null) &&
                (this.po_number === undefined && this.po_number === null)) {
                alert("Please add PO# and BOL#"); return false
            }
            return true
        }
    }
    bill_action() {
        if (this.controller.view.state.data.shipment?.total_amount === undefined) { 
            alert("Please add a rate item"); return 
        }
        if ((this.bol_number === undefined && this.bol_number === null) &&
            (this.po_number === undefined && this.po_number === null)) {
            alert("Please add PO# and BOL#"); return
        }
        if (this.controller.load.status !== "Billed") {
            this.controller.load.status = "Billed"
            this.controller.update("status")
        }
        this.controller.bill_action()
    }
    close() {
        this.view.toggle_modal({ toggle_state: false })
    }
}