// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import test_data from "./test.json"
import schema from "./schema.json"

// ALL OTHER MODULES
import _baseApiObject from "components/General/_baseApiObject";
import creator_request from "assets/graphql/Quotes/Quote.creator.graphql.json"
import customer_request from "assets/graphql/Quotes/Quote.customer.graphql.json"
import quote_list from "assets/graphql/Quotes/Quotes.chart.graphql.json"


export default class Quotes extends _baseApiObject {
    constructor(props) {
        super(props)
        this.schema     = schema
        this.test_data  = test_data
        this.requests   = {
            creators: creator_request,
            customers: customer_request,
            list: quote_list
        }
    }
    async list({caller, value, callback, params}) {
        let body    = JSON.parse(JSON.stringify(quote_list))
        body.query  = body.query.replace("quotes", 'quotes("'+value+'")')
        params      = (params === undefined) ? {} : params
        params.body = body
        let results     = await this.ask({caller: caller, callback: callback, params: params, nocache: true})
        return results
    }
}