// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "../_ModelController";

export default class InformationModelController extends _ModelController {
    constructor(props) {
        super(props)
        this._state = undefined
    }
    get po_number() {
        return this.data?.po_number
    }
    set po_number(value) {
        this.data.po_number = value
    }

    get container_number() {
        return this.data?.container_number
    }
    set container_number(value) {
        this.data.container_number = value
    }

    get bol() {
        return this.data?.bol_number
    }
    set bol(value) {
        this.data.bol_number = value
    }

    get bol_number() {
        return this.data?.bol_number
    }
    set bol_number(value) {
        this.data.bol_number = value
    }

    get house_bol() {
        return this.data?.hbol_number
    }
    set house_bol(value) {
        this.data.hbol_number = value
    }
    get hbol_number() {
        return this.data?.hbol_number
    }
    set hbol_number(value) {
        this.data.hbol_number = value
    }

    get vessel_name() {
        return this.data?.vessel_name
    }
    set vessel_name(value) {
        this.data.vessel_name = value
    }

    get container_size() {
        return this.data?.container_size
    }
    set container_size(value) {
        this.data.container_size = value
    }

    get customers_brokers() {
        return this.data?.customers_brokers
    }
    set customers_brokers(value) {
        this.data.customers_brokers = value
    }

    get destination_port() {
        return this.data?.destination_port
    }
    set destination_port(value) {
        this.data.destination_port = value
    }

    get importer() {
        return this.data?.importer
    }
    set importer(value) {
        this.data.importer = value
    }

    get origin_port() {
        return this.data?.origin_port
    }
    set origin_port(value) {
        this.data.origin_port = value
    }

    get customs_entry() {
        return this.data?.customs_entry
    }
    set customs_entry(value) {
        this.data.customs_entry = value
    }

    get origin_country() {
        return this.data?.origin_country
    }
    set origin_country(value) {
        this.data.origin_country = value
    }

    get fulfillment_number() {
        return this.data?.fulfillment_number
    }
    set fulfillment_number(value) {
        this.data.fulfillment_number = value
    }

    get additional_reference() {
        return this.data?.additional_reference
    }
    set additional_reference(value) {
        this.data.additional_reference = value
    }

    get import_value() {
        return this.data?.import_value
    }
    set import_value(value) {
        this.data.import_value = value
    }

    get voyage_flight() {
        return this.data?.voyage_flight
    }
    set voyage_flight(value) {
        this.data.voyage_flight = value
    }

    get shipping_line() {
        return this.data?.shipping_line
    }
    set shipping_line(value) {
        this.data.shipping_line = value
    }

    get in_bond() {
        return this.data?.in_bond
    }
    set in_bond(value) {
        this.data.in_bond = value
    }
    get summary() {
        let _summary = {}
        _summary.po_number              = this.po_number
        _summary.bol_number             = this.bol_number
        _summary.hbol_number            = this.hbol_number
        _summary.vessel_name            = this.vessel_name
        _summary.container_number       = this.container_number
        _summary.container_size         = this.container_size
        _summary.customers_brokers      = this.customers_brokers
        _summary.destination_port       = this.destination_port
        _summary.origin_port            = this.origin_port
        _summary.importer               = this.importer
        _summary.customs_entry          = this.customs_entry
        _summary.origin_country         = this.origin_country
        _summary.fulfillment_number     = this.fulfillment_number
        _summary.additional_reference   = this.additional_reference
        _summary.import_value   = this.import_value
        _summary.voyage_flight  = this.voyage_flight
        // why is this equal to this._summary? maybe just a typo? - Lindhagen
        // _summary.shipping_line  = this._summary
        _summary.shipping_line  = this.shipping_line
        _summary.in_bond        = this.in_bond
        return _summary
    }
}
