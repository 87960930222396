//GENERAL REACT MODULES
import React from "react"
import { Button, Row, Col } from "react-bootstrap"

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import DeleteTripModal from "./DeleteTrip.modal.jsx"

export default class DeleteTripButton extends _basePartial {
    constructor(props) {
        super(props)
        this.modal_controller   = this.controller
        this._modal = DeleteTripModal
    }
    get status() {
        return this.controller.trip?.status?.toLowerCase()
    }
    renderView() {
        if(this.status === "deadheading") {
            return (<>
                <Row>
                    <Col>
                        <Row>
                            <Button variant="danger" className="w-100 cota" onClick={this.toggle_modal.bind(this)}>Delete Trip</Button>
                        </Row>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
            </>)
        }
    }
}