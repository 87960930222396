// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx"
import "./ProcessingModal.css"
// import UploadPartial from "components/FileUpload/Upload.partial.jsx";
import Controller from "./ProcessingModal.Controller.jsx"
// import DownloadPartial from "components/FileDownload/Download.partial";
// import DeleteFile from "components/DeleteFile/DeleteFile.button.jsx"
// import documents from "./AutoLoadModal.json"

export default class ProcessingModal extends _baseModal {
    constructor(props) {
        super(props)
        this.modal_controller   = new Controller({params: {view: this, controller: this.controller }})
        this.state.modal_params = {
            positioned: "middle_of_panel",
            title: "",
            title_size: "",
            closeable: false,
            has_overlay: true,
            close_on_outside_click: true,
            x_adjustment: "0px",
            y_adjustment: "-100px",
            draggable: false,
        }
        // this.state.doc_types = documents.doc_types
    }
    // render_delete(doc) {
    //     if(doc?.created_on === "freightpro" || doc?.name === "RATE_CONFIRMATION") {
    //         return <></>
    //     } 
    //     return <DeleteFile params={{button: false, file: doc, controller: this.controller, parent: this}} />
    // }
    renderView() {
        return (
            <>
                  <Row className="autoscroll_list font pad_bottom">
                    <center>
                    Thanks for checking in!
                    </center>
                    </Row>
                    <Row className="autoscroll_list font margin pad_hoz">
                    
                    We're still working on this.
            
                    </Row>
                    <Row className="autoscroll_list font pad_bottom margin" > Please check back in a minute or two.</Row>
                    <center>
                          
                          <Button size="sm"  onClick={() => this.modal_controller.changeModal(this)} className={"primary_button_coloring " + this._theme}>
                              OK
                          </Button>
                          </center>
                    {/* <Row className="autoscroll_list">
                    (supported file types - jpg, pdf,png)
                    </Row> */}
                {/* <Row className="pad_bottom">
                    <UploadPartial params={{parent: this.view, controller: this.controller, endpoint: "load", doc_type_override: this.state.doc_types}} />
                </Row>
                {this.formObject({ data: this.state.doc_types, owner: this, options: this.state.docTypes })} */}
          
                       
               
            </>
        )
    }
}
