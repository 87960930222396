//GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button, Container, Card } from "react-bootstrap";
import { BsFillPlusSquareFill } from "react-icons/bs";

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx";
import Controller from "./AddStop.partial.controller.jsx";
import Modal from "./AddStopModal/AddStopModal.modal.jsx";
// import Modal from "../EditPayorModal/EditPayor.modal.jsx";

import "./AddStop.css"

export default class AddStop extends _basePartial {
    constructor(props) {
        super(props)
        this._modal = Modal
        this.partial_controller = new Controller ({params: {view: this}})
    }
    renderView() {
        return (
            <Row className="prevent_wrap" id={this.name}>
                <Col>
                    <Button className={"clear_button thin_button "+this._theme} onClick={() => this.toggle_modal({toggle_state: true})}>
                        <Row>
                            <Col className="plus_sign" sm={1}>
                                <BsFillPlusSquareFill />
                            </Col>
                            <Col className="label">
                                {this.state.data.label}
                            </Col>
                        </Row>
                    </Button>
                </Col>
            </Row>
        )
    }
}
