// GENERAL REACT MODULES
import React from "react";
import {Container, Row, Col, ToggleButton, ToggleButtonGroup, Card, Button } from "react-bootstrap";

//PAGE SPECIFIC MODULES
import { dirverOptions } from "./driver_options";
import "./Driver.css";
import { MultiSelectDropdown } from "components/Dropdowns/Dropdowns.jsx";
import Controller from "./Driver.controller";
import Modal from "./DriverModal/DriverModal.modal.jsx";
import _basePanelView from "components/General/_basePanelView.jsx";
import ViewDriverPanel from "./ViewDriver/ViewDriver.view.jsx";
import GrafanaFrame from "components/GrafanaFrame/GrafanaFrame.view.jsx";
import CotaListPanel from "components/CotaListPanel/CotaListPanel.partial.jsx"
import CotaUserBar from "components/CotaUserBar/CotaUserBar.partial.jsx"
import config_data from "./DriverSearch.json"
import DispatchedTrip from "../Trips/DispatchedTrip/DispatchedTrip.view.jsx";
import grafana_config from "../../controllers/GrafanaConfig/GrafanaConfig.js";
import driver_panel_data from "./driver.json"
import ViewAssetsPanel from "../Assets/ViewAssetsPanel/ViewAssetsPanel.view.jsx";

export default class DriverPanel extends _basePanelView {
  constructor(props) {
    super(props)
    this.controller               = new Controller({ params: {view: this}})
    this._modal                   = Modal
    this.state.form               = config_data.form_objects
    this.state.config_data        = config_data
    this.state.driver_panel_data  = driver_panel_data
    this.state.screen             = 0
    this.state.panelId            = 2
    this.state.panel_name         = "drivers"
    this.state.channel_name       = "grafana_row"
    this.state.panel_configs      = grafana_config()["pages"]["drivers"]
    this.state.modal_pos          = { x: -400, y: -175 }
    this.state.panel_trees      = [{key: 0, name: "driver_flow", base: 0,
                                      paths: [{"view_driver": 0, "view_trip": 1, "view_asset": 2}]}]
    this.state.panels             = [{key: 0, obj: ViewDriverPanel, params: {panel_params: {is_closeable: false, close_title: "Back Home", close_func: "resetScreen", controller: this.controller}}},
                                     {key: 1, obj: DispatchedTrip, params: {panel_params: {is_closeable: false, close_title: "Back Home", close_func: "resetScreen", controller: this.controller}}},
                                     { key: 2, obj: ViewAssetsPanel, params: { panel_params: { is_closeable: false, close_title: "Back to Driver", close_func: "backPanel", controller: this.controller } } }]
    this.handleProcessEvent       = this.handleProcessEvent.bind(this)
    this.state.status_options = []
  }
  renderGrafanaPanel() {
    if(this.state?.company_id === undefined) {return <></>}
    return (
                    <CotaListPanel params={{
                                                parent: this,
                                                panel_configs: this.state.panel_configs,
                                                panel_name: this.state.panel_name,
                                                 configs: this.state.driver_panel_data.panels,
                                                endpoint: "drivers",
                                                // req_name: "general",
                                                filters: {
                                                  statuses: this.state?.page?.status_filter,
                                                    // company_id: this.state?.company_id,
                                                }
                                            }} />
    )
  }
  renderMain() {
    return (
      <Container id={this.name} className="generic_100 g-0">
        <Row className="max_height text_color">
          <Col className="flex_wrapper_rows">
            <Row>
              <Col className="flex_wrapper_rows">
                  <CotaUserBar params={{parent: this, controller: this.controller, title:"Drivers"}} />
              </Col>
            </Row>
            <Row className="generic_100 g-0 cota_panel_object add_shadow">
              <Col className="generic_100">
                <Row className="toolbar">
                      <Col sm={1} className="drivers">
                        Drivers
                      </Col>
                      <Col sm={3}>
                          {/* <MultiSelectDropdown params={{parent: this,
                                                        name: "optionSelectedTrips",
                                                        title: "selection",
                                                        controller: this.controller,
                                                        options: dirverOptions,
                                                        handleChange: this.controller?.handleChange,
                                                        optionSelected: this.state.selection,
                                                        styled: true
                                                    }} 
                              /> */}
                              {this.formObject({ data: this.state.form.status_filter, options: this.state.status_options, owner: this })}
                        </Col>
                        <Col sm={3}></Col>
                        <Col sm={3}className="search">
                          {this.formObject({data: this.state.form.drivers, options: undefined, owner: this})}
                        </Col>
                        <Col sm={2}>
                          <Button className={this._theme} variant="primary" onClick={() => this.toggle_modal({toggle_state: true})}>
                              New Driver
                          </Button>          
                        </Col>
                </Row>
                <Row className="grafana">
                  <Col>

                      {this.renderGrafanaPanel()}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
