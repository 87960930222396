// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class TripsController extends _viewController {
    constructor(props) {
        super(props)
        this.requests = [{callback: {f: this.process_status}, endpoint: "status",
                          replace: {o:"status", r:'status(types:"trip")'}}]
    }
    get trip_id() {
        return this.view.state?.data?.id
    }
    process_status({caller, params, results}) {
        results?.data?.status?.map((item) => {
            caller.view.state.status_options.push({value: item?.value, label: item?.label})
        })
    }
    new_trip() {
        this.view.handleScreen({path_num: 0, screen_num: 0, data: {prepopulation_delete: true}})
    }
    processEventReaction(decoded) {
        this.setState({key: "data", value: decoded.message.data})
        this.view.handleScreen({path_num: 1, screen_num: 1, data: decoded.message.data})
    }
}