// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import AssetModelController from "controllers/ModelControllers/AssetModelController.jsx"
import asset_request from "assets/graphql/Assets/Assets.graphql.json"
import { Row, Col } from "react-bootstrap";
import {FaTrailer} from "react-icons/fa"
import { FiTruck } from "react-icons/fi"

export default class AssetAttachedController extends _viewController {
    constructor(props) {
        super(props)
        this.data       = {config_data: {},status: "map_idle"}
        this.asset     = new AssetModelController ({params: {parent: this,
                                                        controller: this,
                                                        is_view: true}})
    }
    get main_asset() {
        return this.view?.controller?.asset
    }
    get trailer_number () {
        return this?.asset?.trailer_number
    }
    get truck_number() {
        return this?.asset?.truck_number
    }
    // load_harness() {
    //     console.log("LOAD_HARNESS", this)
    //     this.attached_asset_info()
    // }
    attached_asset_info() {
        let id = this.view.props.params.asset_id
        let body = JSON.parse(JSON.stringify(asset_request))
        body.query = body.query.replace("assets", 'asset(id: "'+id+'")')
        this.view.controller.api.assets.ask({caller: this, params: {body: body}, callback: {f: this.process_asset}})
    }
    process_asset({caller, params, results}) {
        if (results.data?.asset !== undefined) {
            caller.view.state.data.asset = caller.getCopy(results.data?.asset)
            caller.view.forceUpdate()
        }
    }
    unattach_asset() {
        this.setState({redirectToAssignAsset: true})
    }
    asset_icon() {
        if (this.main_asset.asset_type === "TRUCK") {
            return (
                <Row className="icon_row">
                    <Col className="icon_col">
                        <FaTrailer size={30} className="icon" />
                    </Col>
                </Row>
            )
        } else {
            return (
                <Row className="icon_row">
                    <Col className="icon_col">
                        <FiTruck size={30} className="icon"/>
                    </Col>
                </Row>
            )
        }
    }
    asset_name() {
        if(this.asset.trailer_number !== undefined && this.asset.trailer_number !==null) {
          return (  
            this.asset.trailer_number
          )
        } else {
            return (
                this.asset.truck_number
            )
        }
    }
}