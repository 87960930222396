// MODULE SPECIFIC IMPORTS
import NoteModelController from "controllers/ModelControllers/Shipping/NoteModelController.jsx"
import _viewController from "controllers/ViewController/_viewController.jsx"
import update_trip_notes from "assets/graphql/Trips/trips.notes.mutation.graphql.json"
import delete_note from "assets/graphql/Notes/Note.delete.graphql.json"

export default class ContactNotesController extends _viewController {
    constructor(props) {
        super(props)
        this._configs = {
            local: "note",
            array: "notes"
        }
        this.note           = new NoteModelController({params: {parent: this,
                                                        controller: this,
                                                        index: 0,
                                                        _param: "notes",
                                                        is_view: true,
                                                        view: this.view}})
    }
    add_note() {
        // two guard clauses rather than nested if statements.
        if(this.note._sum <= 0) { return }
        if(this.note.note_text.trim() === "") { return }
        let body = this.format_mutation()
        this.api.trips.ask({caller: this, params: {body: body}, callback: {f: this.process_notes}})
        // extract data from the model controller.
        let data = JSON.parse(JSON.stringify(this.note?.data))
        // push the new note data to the existing array of notes.
        this.controller.view.state.data.notes.push(data)
        // works to reset the note model controller's values. 
        this.setState({key: "data", param: "notes", value: [{note_text:"", viewable:false}]})
        // this set state forces the textarea to clear.
        this.view.setState(prevState => ({
            index: prevState.index + 1
        }))
        // re-render the view.
        this.controller.view.forceUpdate()
    }
    format_mutation() {
        let trip_id = this.controller.trip._id
        let note = JSON.parse(JSON.stringify(this.note?.data))
        let data = { id: trip_id, notes: [note] }
        data = this.toUnquotedJSON(data)
        let body = JSON.parse(JSON.stringify(update_trip_notes))
        body.query = body.query.replace("input", "input:"+data)
        return body
    }
    process_notes({params, results, caller}) {
        if(results?.errors !== undefined) {
            console.log("Error adding note", results.errors)
            return
        }
        // console.log("Note added")
    }
    remove_note(index) {
        let note = this.controller.notes[index]
        // potentially add an alert or modal here to say refresh and try again.
        let alert_message = "There was an error trying to delete the note, please refresh and try again."
        if(note?.id === undefined || note?.id === null) { return alert(alert_message) }
        let body = JSON.parse(JSON.stringify(delete_note))
        body.query = body.query.replace("id:", 'id: "'+note.id+'"')
        this.api.notes.ask({caller: this, params: {body: body}})
        this.view.controller._delete({name: this?._configs?.array, index: index})
        this.controller.view.forceUpdate()
    }
}