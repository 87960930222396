import _viewController from "controllers/ViewController/_viewController.jsx"
// import self_request from "assets/graphql/Users/self.graphql.json"

export default class ConfomationModalController extends _viewController {
    constructor (props) {
        super(props)

    }

    changeModal(view){
        console.log(view)
        view.parent.toggle_modal({toggle_state: false})
        view.parent.state.change = true
        view.parent.resetModal()
        view.parent.state.reload =true
    }
}