
// Module specific imports:
import _viewController from "controllers/ViewController/_viewController.jsx"
import self_request from "assets/graphql/Users/self.graphql.json"

export default class CotaUserBarController extends _viewController {
    constructor(props) {
        super(props)
        this.requests   = [{request: self_request,
                                callback: {f: this.load_user, p: {name: "self"}}, endpoint: "users"}]
    }
    load_user({caller, params, results}) {
        if(results?.errors === undefined && results?.data?.self !== undefined) {
            caller.view.state.data.user = caller.getCopy(results.data.self)
            caller.view.state.cache = caller.getCopy(caller.view.state.data.user)
            caller.view.forceUpdate()
        }
    }
    handle_reset_screen() {
        this?.controller?.view?.state?.panel_params?.controller?.view?.resetScreen()
    }
    handle_nav() {
        if (this.view?.state?.parent?.state?.panel_params?.close_func === "backPanel") {
            this.view?.state?.parent?.call_close()
        } else {
            this.handle_reset_screen()
        }
    }
}