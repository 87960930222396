//GENERAL REACT MODULES
import React from "react"
import { Button, Row, Col } from "react-bootstrap"

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import DeleteAssetModal from "./DeleteAsset.modal.jsx"

export default class DeleteAssetButton extends _basePartial {
    constructor(props) {
        super(props)
        this.modal_controller   = this.controller
        this._modal = DeleteAssetModal
    }
    get status() {
        return this.controller.asset?.status?.toLowerCase()
    }
    renderView() {
        if(this.status === "available" || this.status === "out-of-service") {
            return <Button variant="danger" className="w-100 cota" onClick={this.toggle_modal.bind(this)}>Delete Asset</Button>
        }
    }
}