export const TrailerOptions = [{label: "Van", value: "Van"}, 
                            {label: "Reefer", value: "Reefer"}, 
                            {label: "Flatbed", value: "Flatbed"}, 
                            {label: "Stepdeck", value: "Stepdeck"}, 
                            {label: "Double Drop", value: "Double_Drop"}, 
                            {label: "RGN", value: "RGN"},
                            {label: "Chassis", value: "Chassis"},
                            {label: "Stretch", value: "Stretch"},
                            {label: "Tanker", value: "Tanker"},
                            {label: "Livestock", value: "Livestock"},
                            {label: "Hotshot", value: "Hotshot"},
                            {label: "Bulk", value: "Bulk"}]