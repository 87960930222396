// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "controllers/ModelControllers/_ModelController";

export default class PaymentDetailsModelController extends _ModelController {
    constructor(props) {
        super(props)
    }
    get po_num() {
        return this.data?.po_num
    }
    set po_num(value) {
        this.data.po_num = value
    }
    get bol_num() {
        return this.data?.bol_num
    }
    set bol_num(value) {
        this.data.bol_num = value
    }

    get items() {
        return (this.data.line_items !== undefined) ? this.data.line_items : this.data.items
    }
    get summary() {
        let _summary = {
            po_number: this.po_num,
            bol_number: this.bol_num,
            items: this.items
        }
        return _summary
    }
    get data() {
        return this.controller?.getState({key: "data"})
    }
}