// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button } from "react-bootstrap";

//PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx";
import Controller from "./UnassignTruck.modal.controller.jsx"
import "./UnassignTruckModal.css"

export default class UnassignTruckModal extends _baseModal {
    constructor(props) {
        super (props)
        this.modal_controller       = new Controller({params:{view: this, contoller: this?.controller}})
        this.state.dims             = {width: 636, height: 980}
    }
    renderView() {
        const styles = { maxWidth: "30vw" }
        const newStyles = { ...styles, ...this._inner_modal_location}
        // console.log(this)
        return (
            <>
                <Col className={"g-0 inner_modal_window cota_panel_border add_shadow modal_style"} ref={(divElement) => { this.divElement = divElement; }} style={newStyles} id={this.name}>
                    <Row>
                        <Col className="unassign">
                            Unassign Truck
                        </Col>
                    </Row>
                    <Row className="pad_top">
                        <Col className="info text-center">
                            Are you sure you want to unassign this truck?
                        </Col>
                    </Row>
                    <Row className="pad_top no_wrap">
                        <Col className="button_min_w">
                            <Button className={"clear_button "+this._theme} onClick={this.toggle_modal?.bind(this.state.parent, {toggle_state: false})} >
                                Cancel
                            </Button>
                        </Col>
                        <Col />
                        <Col className="button_min_w">
                            <Button className="btn_unassign" onClick={() => this.modal_controller.detach_truck()} >
                                Unassign
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </>
        )
    }
}