// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";

// ALL OTHER MODULES
import _viewController from "controllers/ViewController/_viewController";

export default class BackgroundController extends _viewController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    check_failed() {
        if (this.view?.parent?.state?.failed) {
            return (
                <>
                    <Row>
                        <Col className="login_text">
                            Failed to Login. Please Try again.
                        </Col>
                    </Row>
                    <Row>
                        <Col className="center_button">
                            <Button className={"close_button primary_button_coloring "+this.getTheme()}>
                                Try Again
                            </Button>
                        </Col>
                    </Row>
                </>
            )
        }
        return (
            <>
                <div className="loader"/>
                <div className="loaderText">
                    Loading
                </div>
            </>
        )
    }
    renderLogout() {
        if (this.state?.supportLogout) {
            return (
                <div>
                    <Button variant="primary" className="RoundedButton">Logout</Button>
                </div>
            )
        }
        return (
            <span />
        )
    }
}