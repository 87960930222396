import _viewController from "controllers/ViewController/_viewController.jsx"
import EventModelController from "controllers/ModelControllers/Trips/EventModelController.jsx"

export default class TripLogItemController extends _viewController {
    constructor(props) {
        super(props)
        this.event            = new EventModelController({params: {parent: this,
                                                                    controller: this,
                                                                    is_view: true}})
    }
    format_currency(num) {
        if(num === undefined || num === null) { return "" }
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
}