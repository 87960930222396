//GENERAL REACT MODULES
import React from "react";
import {Row, Col, Container, Button } from "react-bootstrap";

//PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx";
import ModalController from "./EditPayment.modal.controller.jsx";
import "./EditPayment.modal.css"
import config_data from "./EditPayment.json"
import LineItem from "./LineItem/LineItem.partial.jsx";
// import form_configs from "./EditPayment.modal.json"

export default class EditPaymentModal extends _baseModal {
    constructor(props) {
        super(props)
        this.state.form = config_data.form_objects
        this.modal_controller = new ModalController ({params: {view: this, controller: this.controller}})
        this.state.dims = {width: 636, height: 980}
        this.state.style = ""
        this.state.style_two = ""
        this.state.style_three = ""
        this.state.modal_params = {
            positioned: "middle_of_panel",
            title: "Edit Payment Details",
            title_size: "",
            y_adjustment: "-100px",
            x_adjustment: "0px",
            has_overlay: false,
            close_on_outside_click: true,
            closeable: true,
            draggable: true,
        }
        this.state.data.line_items = this.modal_controller.line_items
    }
    render_line_items() {
        if(this.controller?.view?.state?._is_loading && this.state.data.line_items?.length === 0) { return }
        return this.controller.view.state.line_items?.map((line_item, index) => {
            let key = line_item?.id
            if(key === undefined || key === null) {
                // This will be used as the key for id's that are undefined. Forcing React to retain the line items that are not changed,
                // but also forcing rerenders on those that are changed in order to avoid duplication / sticky data.
                key = Math.random() * this.controller.view.state.line_items?.length
            }
            const isEven = (index%2 == 1)
            return this.generate_line_item({isEven: isEven, line_item: line_item, index: index, key: key})
        })
    }
    generate_line_item({isEven, line_item, index, key}) {
        return (
            <div className={isEven ? "even item" : "odd item"}>
                <LineItem params={{ parent: this, controller: this.controller, line_index: index, data: line_item}} key={"line_item_"+index+key} />
            </div>
        )
    }
    renderView() {
        this.modal_controller.retrieve_data()
        // console.log(this)
        return (
            <div style={{width: "625px"}}>
                <Row >
                    <Col>
                        <Row>
                            <Col className="titles_font">
                                PO #
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.formObject({ data: this.state.form.po_num, options: undefined, controller: this.modal_controller, disabled: this.controller.load?.created_on === "freightpro", owner: this})}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col className="titles_font">
                                BOL #
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            {this.formObject({ data: this.state.form.bol_num, options: undefined, controller: this.modal_controller, owner: this})}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="item_row_titles">
                    <Col xs={4} className="d-flex flex-nowrap titles_font">
                        Payment Detail
                    </Col>
                    <Col xs={2} className="d-flex flex-nowrap titles_font">
                        QTY
                    </Col>
                    <Col xs={2} className="d-flex flex-nowrap titles_font">
                        Price
                    </Col>
                    <Col xs={2} className="d-flex flex-nowrap titles_font">
                        Cost
                    </Col>
                    <Col xs={2} className="d-flex flex-nowrap titles_font"></Col>
                </Row>
                <hr className="hr_margin"/>
                {this.render_line_items()}
                <Row>
                    <Col xs={8}></Col>
                </Row>
                <Row>
                    <Col>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button onClick={this.modal_controller.add_item.bind(this)} className={"clear_button add_item "+this._theme}>
                            + Add Item
                        </Button>
                    </Col>
                </Row>
                <hr />
                <Row className="flex-nowrap">
                    <Col>
                        <Button className={"clear_button "+this._theme} onClick={() => this.modal_controller.close()} >
                            Cancel
                        </Button>
                    </Col>
                    <Col>
                        <Button className={"primary_button_coloring "+this._theme} onClick={this.modal_controller.save_action.bind(this)}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }
}