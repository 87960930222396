// GENERAL REACT MODULES

//OBJECT SPECIFIC MODULES
import Modal from "../LoadBoostModal/LoadBoost.modal.view.jsx";
import ColumnController from "components/Column/Column.controller.jsx";
import ContactInfoPanel from "../ContactInfoPanel/ContactInfo.partial.jsx"

export default class ColumnThreeController extends ColumnController {
    constructor(props) {
        super(props)
        this.state.data = {
            shipper: {},
            consignee: {},
            stop: undefined
        }
        this._modal = Modal;
        this.state.rows = this.generate_rows()
    }
    generate_rows() {
        let rows = Array(1).fill(undefined)
        //attempted to close modal with setting toggle_state inside of controller
        // {this.toggle_modal?.bind(this, { toggle_state: true })}
          // manages state of ContactInfoPanel component. Intermediary between the view (components) and the model (data) of the application
        rows[0] = {obj: ContactInfoPanel, params: { params: {parent: this, controller: this.view.controller, editable: true, index: 0}}}
        return rows
    }
}