// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx";
import "./LoadBoost.modal.css";
import Seperator from "components/Seperator/Seperator.view.jsx";
import ColumnOneController from "../LoadBoostColumns/ColumnOne.controller";
import ColumnTwoController from "../LoadBoostColumns/ColumnTwo.controller.jsx"
import ColumnThreeController from "../LoadBoostColumns/ColumnThree.controller";
import Column from "components/Column/Column.view.jsx";
import ModalController from "./LoadBoost.modal.controller.jsx"

export default class LoadboardModal extends _baseModal {
    constructor(props) {
        super(props);
        this.state.modal_params   = {
            positioned: "middle_of_panel",   
            title: "Load Information",                
            closeable: true,                    
            has_overlay: true,                 
            close_on_outside_click: true,       
            x_adjustment: "0px",                
            y_adjustment: "0px",
        }
        let get_column_controller = this.get_column_controller.bind(this);
        this.modal_controller = new ModalController({params: {view: this }})
        this.column = {
            controllers: {
                get one() {
                    return get_column_controller({name: "column_one_controller", class_name: ColumnOneController});
                },
                get two() {
                    return get_column_controller({name: "column_two_controller", class_name: ColumnTwoController})
                },
                get three(){
                    return get_column_controller({name: "column_three_controller", class_name: ColumnThreeController})
                }
            },
        };
    }
    get_column_controller({ name, class_name }) {
        if (this.state[name] === undefined) {
            this.state[name] = new class_name({ params: { view: this } });
        }
        return this.state[name];
    }
    cri_modal() {
        return (
            <>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                Thank you for your interest in this Load.
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                We shall get back to your shortly.
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Your Ticket Number is {this.state.parent.state.cri}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
    follow_modal_toggle({toggle_state}) {
        if(!toggle_state) {
            delete this.state.parent.state.selected
        }
    }
    renderView() {
        if (this.state.parent.state.cri !== undefined) {
            return this.cri_modal()
        }
        if (this.state.selected !== this.state?.parent?.state?.selected?.id) {
            this.state.data = {}
            this.state.selected = this.state?.parent?.state?.selected?.id
            this.modal_controller.get_load_info()
        }
        return (
            <>
                <Row>
                    <Column params={{parent: this, controller: this.modal_controller, view_controller: this.column.controllers.one }} />
                    <Column params={{parent: this, controller: this.modal_controller, view_controller: this.column.controllers.two }} />
                    <Column params={{parent: this, controller: this.modal_controller, view_controller: this.column.controllers.three}} />
                </Row>
            </>
        );
    }
}