import _baseController from "components/General/_baseController"
import * as moment from 'moment';
import validator from 'validator'
import Snackbar from "node-snackbar";

export default class _viewController extends _baseController {
    constructor(props) {
        super(props)
        this.paths = this.updatePaths()
        this._harness = false
        if (props !== undefined) {
            if (props?.params !== undefined) {
                Object.keys(props?.params).map((param, _) => {
                    this[param] = props?.params[param]
                    return undefined
                })
            }
        }
        this.controllerDidMount()
        this.changeViewState.bind(this)
        return this
    }
    decompose_el(el) {
        if(el.includes("=")) {
            let del = el.split("=")
            return { [del[0]] : del[1] }
        }
        return el
    }
    reset_overrides() {
        // if(this.overrides === undefined) { return }
        this.overrides.forEach(el => {
            if(this.view.state?.[el] !== undefined) {
                delete this.view.state[el]
            }
        })
    }
    direct_overrides() {
        if (this.overrides === undefined) { return }
        this.reset_overrides()

        let pathname = window?.location.pathname
        let paths = pathname.split('/').filter(el => el !== "")
        // turn string into json object?
        // separate by & 
        if(pathname.includes("&")) {
            paths = paths.map((el, index) => (el.includes("&")) ? el.split("&") : el)
        }
        // make strings where '=' exists into key value pairs
        if(pathname.includes("=")) {
            paths = (paths.map((el, index) => (Array.isArray(el)) ? el.map(el => {el = el.split("="); return {[el[0]]:el[1]}}) : this.decompose_el(el))).flat(Infinity)
        }
        paths.forEach(el => { 
            if (typeof el === "object") {
                if (this.overrides.includes(Object.keys(el)?.[0])) {
                    let value = (Object.values(el)?.[0] === undefined) ? true : Object.values(el)?.[0]
                    if (typeof JSON.parse(value) == "boolean") {
                        value = JSON.parse(value);
                    }
                    this.view.state[Object.keys(el)?.[0]] = value
                    this.scrub_paths(el)
                }
            }
            if (typeof el === "string") { 
                if (this.overrides.includes(el)) {
                    this.view.state[el] = true
                    this.scrub_paths(el)
                }
            }
        })
    }
    scrub_paths(path) {
        let index = (this.context.path)?.indexOf(path)
        if (index !== -1) {
            (this.context.path).splice(index, 1);
        }
    }
    direct_load() {
        this.direct_overrides()
        let path_num    = (this.routing?.default?.path !== undefined) ? this.routing.default.path : 0
        let screen_num  = (this.routing?.default?.screen !== undefined) ? this.routing.default.screen : 0
        let configs     = this.routing?.[this.context?.path?.[2]]
        let key         = "id"
        if (configs !== undefined) {
            path_num = configs.path
            screen_num = configs.screen
            key = (this.routing?.alt !== undefined) ? this.routing?.alt : this.context?.path?.[2]
        } else {
            if (this.context?.path?.length > 2) {
                if (validator.isInt(this.context?.path?.[2])) {
                    path_num    = parseInt(this.context?.path?.[2])
                } else {
                    key = (this.routing?.alt !== undefined) ? this.routing?.alt : this.context?.path?.[2]
                }
            }
	    }
        if (this.context?.path?.length > 3) {
            if (validator.isInt(this.context?.path?.[3])) {
                screen_num  = parseInt(this.context?.path?.[3])
            }
        }
        let number    = this.context?.path?.[this.context?.path.length-1]
        if (this.context?.path?.length > 2 && number !== "") {
            if (number !== undefined) {
                if (validator.isUUID(number)) {
                    this.view.handleScreen({path_num: path_num, screen_num: screen_num, data: {id: number}})
                    return true
                } else {
                    this.view.handleScreen({path_num: path_num, screen_num: screen_num, data: {[key]: number}})
                    return true
                }
            }
        }
        return false
    }
    get context() {
        return this.view.state.context
    }
    set context(value) {
        this.view.state.context = value
    }
    get _isMounted() {
        if (this.isMounted === undefined) {
            return this.view?._isMounted
        }
        return this.isMounted
    }
    get _theme() {
        return this.getTheme()
    }
    get api() {
        return (this.view?.api !== undefined) ? this.view.api : (this.view?.state?.api !== undefined) ? this.view.state.api : this.controller?.api
    }
    get panel_controller() {
        return (this.view?.panel_controller !== undefined) ? this.view.panel_controller : this.view?.state?.panel_controller
    }
    get sidebar() {
        return this.view?.state?.context?.sidebar
    }
    controllerDidMount() { }
    changeViewState(props) {
        if (props !== undefined && this.view !== undefined) {
            Object.keys(props).map((key, index) => {
                this.view.state[key] = props[key]
                return undefined
            })
        }
    }
    load_cache() {
    }
    load_objects() {
        
    }
    updatePaths() {
        let paths = window?.location.pathname.split('/').filter(function (el) { return el !== ""; })
        paths.map((key, index) => {
            paths[index] = "/"+key
            return undefined
        })
        if (paths.length === 0) {
            paths.push("/")
        }
        return paths
    }
    viewUpdate() {
        this.view?.state?.context?.app.forceUpdate()
    }
    follow_on_dropdown() { }
    async load_harness() { }
    async follow_on_empty({event, obj, data}) { }
    new_object(type) { }
    async follow_on_selection({event, obj, data}) { }
    follow_event(decoded) { console.log("VC DECODED") }
    updateValue({message, data, index}) {
        let obj_title = (message.target?.title === undefined || message.target?.title === "") ? message.target?.placeholder : message.target?.title
        let obj_value = (message.target?.type === "checkbox") ? message.target?.checked : message.target?.value
        if (Array.isArray(this[obj_title])) {
            let obj = this.new_object(obj_title)
            if (index === undefined) {
                if (this?.[obj_title]?.length !== undefined) {
                    index = this[obj_title].length
                }
            }
            if (obj !== undefined) {
                obj._find(index)
                obj.update({key: obj_title,
                                  value: obj_value,
                                param: message.target?.name,
                                index: index})
            } else {
                let objs = this.getState({key: obj_title})
                let obj = objs?.[index]
                if (message?.target?.name !== undefined) {
                    obj[message.target.name] = obj_value
                }
                objs[index] = obj
                this.setState({key: obj_title, value: objs})
            }
        } else {
            if (this[obj_title] === undefined) {
                this.setState({key: obj_title,
                                    value: obj_value,
                                    param: message.target?.name})
                this.view.forceUpdate()
            } else {
                this[obj_title]?.update({key: obj_title,
                                            value: obj_value,
                                            param: message.target?.name})
            }
        }
    }
    getValue({key, param, index}) {
        if (Array.isArray(this[key])) {
            return this[key][index][param]
        }
        return this[key][param]
    }
    setParam({key, param, value: v}) {
        this.view[key] = param
    }
    getParam({key, param, value: v}) {
        if (key !== undefined) {
            if (this.view?.[key] === undefined) {
                this.view[key] = {}
            }
            return this.view?.[key]
        }
        return this.view
    }
    getSearch({key, selected, search, param}) {
        if (key !== undefined) {
            let value = this.view?.state?.search?.[key]
            if (selected !== undefined) {
                value = this.view?.state?.search?.[key]?.selected_value
                if (param !== undefined) {
                    value = this.view?.state?.search?.[key]?.selected_value?.[param]
                }
            }
            if (search !== undefined) {
                value = this.view?.state?.search?.[key]?.search_value
            }
            return value
        }
    }
    get_object_param({key, param}) {
        if (key !== undefined) {
            if (param !== undefined) {
                return Object.get_by_string({obj: this?.[key], 
                                            obj_string: param});
            }
        }
        return undefined
    }
    getState({key, param, value}) {
        if (key !== undefined) {
            if (param !== undefined) {
                return Object.byString({obj: this.view?.state?.[key], 
                                            obj_string: param});
            }
            if (this.view?.state?.[key] === undefined) {
                this.view.state[key] = {}
            }
            return this.view?.state?.[key]
        }
        return this.view?.state
    }
    setState({key, param, value}) {
        var changed = false
        if (key !== undefined) {
            let state_obj = {}
            if (Array.isArray(key)) {
                key.map((k, _) => {
                    this.create_key(k)
                    return undefined
                })
            } else {
                this.create_key(key)
            }
            if (param !== undefined) {
                if (Array.isArray(param)) {
                    key.map((k, index) => {
                        this.update_by_string({key: k, param: param[index], value: value[index]})
                        return undefined
                    })
		            this.view?.setState({[key]: this.view.state[key]})
                } else {
                    this.update_by_string({key: key, param: param, value: value})
		            this.view?.setState({[key]: this.view.state[key]})
                }
            } else {
                if (Array.isArray(key)) {
                    key.map((k, index) => {
                        if (this.view.state[k] !== value[index]) {
                            this.view.state[k] = value[index]
                            changed = true
                        }
                        return undefined
                    })
                } else {
                    if (this.view.state[key] !== value) {
                        this.view.state[key] = value
                        changed = true
                    }
                }
            }
            
            if (Array.isArray(key)) {
                key.map((k, index) => {
                    state_obj[k] = this.view?.state?.[k]
                    return undefined
                })
            } else {
                state_obj[key] = this.view?.state[key]
            }
            if (changed) {
                this.view?.setState(state_obj)
	        }
        }
    }
    update_by_string({key, param, value}) {
        return Object.byString({obj: this.view?.state?.[key], 
                            obj_string: param, 
                            obj_value: value});
    }
    create_key(key) {
        if (this.view?.state?.[key] === undefined) {
            this.view.state[key] = {}
        }
    }
    getCopy(value) {
        return (value === undefined) ? undefined : JSON.parse(JSON.stringify(value))
    }
    getTheme() {
        let theme = (Object.keys(this.view?.context).length === 0 || this.view?.context === undefined) ? this.getState({key: "context", param: "user.preferences.theme"}) : this.view?.context?.user?.preferences?.theme
        if (theme === undefined || theme === null) {
            theme = "cota"
        } else {
            if (Object.keys(theme).length === 0) {
                theme = "cota"
            }
        }
        return theme
    }
    diff_date({then}) {
        let now = new Date().getTime();
        let diff = {
            time: 0,
            frame: ""
        }
        let month = moment.utc(moment(now).diff(moment(then))).format("MM")
        let days = moment.utc(moment(now).diff(moment(then))).format("DD")
        let hours = moment.utc(moment(now).diff(moment(then))).format("HH")
        let minutes = moment.utc(moment(now).diff(moment(then))).format("mm")
        if (month > 0) {
            diff.time = month
            diff.frame = "MM"
            diff.desc = (month > 1) ? "Months" : "Month"
        } else {
            if (days > 0) {
                diff.time = days
                diff.frame = "DD"
                diff.desc = (days > 1) ? "Days" : "Day"
            } else {
                if (hours > 0) {
                    diff.time = hours
                    diff.frame = "HH"
                    diff.desc = (hours > 1) ? "Hours" : "Hour"
                } else {
                    diff.time = minutes
                    diff.frame = "mm"
                    diff.desc = (minutes > 1) ? "Minutes" : "Minute"
                }
            }
        }
        diff.time = (diff.time < 10) ? diff.time.substring(1) : diff.time
        return diff
    }
    toUnquotedJSON(param){ // Implemented by Frostbolt Games 2022
        if (param !== undefined && param !== null) {
            if(Array.isArray(param)){ // In case of an array, recursively call our function on each element.
                let results = [];
                for(let elem of param){
                    results.push(this.toUnquotedJSON(elem));
                }
                return "[" + results.join(",") + "]";
            } else if(typeof param === "object"){ // In case of an object, loop over its keys and only add quotes around keys that aren't valid JavaScript variable names. Recursively call our function on each value.
                let props = Object.keys(param).map((key) => {
                    if (key.match(/^[a-zA-Z_$][a-zA-Z\d_$]*$/) === null) {
                        return `"${key}":${this.toUnquotedJSON(param[key])}`;
                    } else {
                        return `${key}:${this.toUnquotedJSON(param[key])}`;
                    }
                })
                return `{${props}}`;
            } else { // For every other value, simply use the native JSON.stringify() function.
                return JSON.stringify(param);
            }
        }
        return '""'
    }
    check_admin() {
        if(this.check_role("COTA_SUPER_USER")) {
            return true
        }
        if(this.check_role("ADMIN")) {
            if(this.check_admin_company()) {
                return true
            }
        }
        return false
    }
    check_admin_company() {
        let context = this.view?.state?.context
        if(context === undefined) {
            context = this.view?.state?.controller?.context
        }
        let data = JSON.parse(JSON.stringify(this.view?.state?.data))
        let id = data.company?.id
        let bool = false
        if(context?.self?.roles !== undefined) {
            if(Array.isArray(context?.self?.roles)) {
                context?.self?.roles?.find((role, index) => {
                    if(role?.company?.id === id) {
                        bool = true
                        return bool
                    }
                })
            }
        }
        return bool
    }
    flatten_self_roles() {
        let roles = []
        let context = this.view?.state?.context
        if(context === undefined) {
            context = this.view?.controller?.view?.state?.context
        }
        if(context?.self?.roles !== undefined) {
            if(Array.isArray(context?.self?.roles)) {
                context?.self?.roles?.forEach((role, index) => {
                    if(role?.name !== undefined) {
                        roles.push(role.name)
                    }
                })
            }
        }
        return roles
    }
    check_role(role) {
        let roles = this.flatten_self_roles()
        let context = this.view?.state?.context
        if(context === undefined) {
            context = this.view?.controller?.view?.state?.context
        }
        if (context?.authClient?.realmAccess?.roles !== undefined) {
            if (Array.isArray(context?.authClient?.realmAccess?.roles)) {
                roles = roles.concat(context?.authClient?.realmAccess?.roles)
                if (roles.includes(role)) {
                    return true
                }
            }
        }
	    return false
    }
    // Was just messing with some JSDoc, can remove later
    /**
     * @param {Object} params
     * @param {string} params.msg - The message for the snackbar.
     * @param {string} params.status - can be "error" or "success".
     * @param {string} [params.pos] - combination of top/bottom and left/right/center, ex: "top-center"
     * @param {boolean} [params.btn=true] - toggle action button (dismisses the snackbar)
     */
    show_snackbar({msg, status, pos, btn = true}) {
        // look into customizing the action and adding a callback function.
        let bg_color = "#22AA7D"
        let badge = "green-badge"
        if(status === "success") { bg_color = "#22AA7D"; badge = "green-badge" }
        if(status === "error") { 
            bg_color = "#DE1112"
            badge = "red-badge"
            pos = (pos === undefined) ? "top-center" : pos
        }
        Snackbar.show({
            pos: pos ?? "bottom-right", 
            showAction: btn, 
            actionTextColor: "#FFF",
            text: msg ?? status,
            backgroundColor: bg_color,
            customClass: "snackbar_border "+badge        
        }) 
    }
    async perform_search({title, name, value, values, data}) {
        this.api?.[data?.endpoint]?.search({caller: this, key: name, value: value, values: values, callback: {f: this.process_search, p: {title: title, name: name, data: data}}, params: {title: title, name: name, data: data}})
    }
    process_search({caller, params, results}) {
        if (results?.data?.[params?.data?.endpoint] !== undefined && results?.data?.[params?.data?.endpoint] !== null) {
            if(results?.data?.[params?.data?.endpoint].length > 0) {
                caller.setState({key: params?.title, param: params?.name+".return_value", value: results?.data?.[params?.data?.endpoint]})
            }
        }
        caller.follow_on_search({results: results})
    }
    async follow_on_search() {}
    async load_data() {
        // console.log("HARNESS",this.requests )
        if (!this._harness) {
            this._harness = true
            if (this.requests !== undefined) {
                if (Array.isArray(this.requests)) {
                 
                    if (this.view !== undefined) {
                        this.view.setState({_is_loading: true})
                        this.view.state._is_loading = true
                        // console.log("SETTING",this.view)
                    }
                    for (const request of this.requests) {
                    // this.requests.map((request, index) => {
                        await this.request_data({req: request})
                    }
                }
            }else{ this.view?.setState({_is_loading: true}) }
            this.load_harness()
            this.view?.forceUpdate()
        }
    }
    async request_data({req}) {
        if (req.endpoint !== undefined) {
            let req_name = (req?.req_name === undefined) ? "general" : req.req_name
            let request = (req?.request !== undefined) ? req.request : this.api[req.endpoint]?.requests?.[req_name]
            if(process.env.REACT_APP_DEVMODE || process.env?.REACT_APP_DOMAIN === "cotasure") {
                console.log(request)
            }
            // if (req?.callback?.fin) { this.view.state._final_req = req.endpoint }
            if (request !== undefined) {
                let body    = JSON.parse(JSON.stringify(request))
                if (Array.isArray(req?.replace)) {
                    for (const rep of req?.replace) {
                        body.query = this.replace_request({query: body.query, replace: rep})
                    }
                } else {
                    body.query = this.replace_request({query: body.query, replace: req?.replace})
                }
                if (this.api?.[req?.endpoint] !== undefined) {
                    await this.api[req.endpoint].ask({caller: this, params: {body: body}, callback: req?.callback})
                }
            }
        }
    }
    replace_request({query, replace}) {
        if (replace?.o !== undefined && replace?.r !== undefined) {
            return query.replace(replace.o, replace.r)
        }
        return query
    }
    follow_handle_results({params, results}) { }
    handle_results({params, results}) {
        if (results?.errors === undefined && results?.data?.[params?.name] !== undefined) {
            if (Array.isArray(this?.view?.state?.[params?.var])) {
                results?.data?.[params?.name]?.map((obj, index) => {
                    let label = (params?.disp !== undefined) ? params.disp : "name"
                    this.view.state[params.var].push({ value: obj?.id, label: obj?.[label]})
                })
            } else {
                let key     = (params?.var !== undefined) ? params.var : "data"
                let param   = (params?.params !== undefined) ? params.params : ((key === "data") ? params?.name : undefined)
                let name    = (params?.name !== undefined) ? params?.name : (Object.keys(results?.data))[0]
                this.setState({key: key, param: param, value: results?.data?.[name]})
            }
        }
        this.follow_handle_results({params: params, results: results})
    }
}
