// GENERAL REACT MODULES

// ALL OTHER MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import Users from "./Endpoints/Users/Users";
import Areas from "./Endpoints/Places/Areas/Areas"
import Companies from "./Endpoints/Companies/Companies"
import Roles from "./Endpoints/Roles/Roles";
import Quotes from "./Endpoints/Quotes/Quotes";
import Contacts from "./Endpoints/Contacts/Contacts";
import Carriers from "./Endpoints/Carriers/Carriers";
import Persons from "./Endpoints/Persons/Persons";
import Loads from "./Endpoints/Loads/Loads"
import Location from "./Endpoints/Places/Location/Location"
import Address from "./Endpoints/Places/Address/Address"
import Trailers from "./Endpoints/Trailers/Trailers"
import Languages from "./Endpoints/Languages/Languages";
import Requirements from "./Endpoints/Requirements/Requirements"
import Stops from "./Endpoints/Stops/Stops"
import Commodities from "./Endpoints/Commodities/Commodities";
import Assets from "./Endpoints/Assets/Assets";
import Upload from "./Endpoints/Upload/Upload"
import DocumentTypes from "./Endpoints/Document_types/Document_types"
import Status from "./Endpoints/Status/Status"
import Notes from "./Endpoints/Notes/Notes"
import Distance from "./Endpoints/Distance/Distance"
import Class from "./Endpoints/Class/Class"
import Summary from "./Endpoints/Summary/Summary"
import Email from "./Endpoints/Email/Email.jsx"
import Trips from "./Endpoints/Trips/Trips.jsx"
import Invoice_pdf from "./Endpoints/Invoice/invoice.jsx"
import Documents from "./Endpoints/Documents/Documents.jsx"
import Event_types from "./Endpoints/Event_types/Event_types.jsx"
import Events from "./Endpoints/Events/Events.jsx"
import Invite from "./Endpoints/Invite/invite.jsx"
import Employees from "./Endpoints/Employees/Employees";
import Drivers from "./Endpoints/Driver/Drivers"
import Rate_item from "./Endpoints/Rate_items/Rate_items.jsx"
import Request_info from "./Endpoints/Request_info/Request_info.jsx"
import Feedback_info from "./Endpoints/Feedback/Feedback";

// ASSIGNMENTS
const domain = process.env.REACT_APP_DOMAIN

export default class ApiController extends _viewController {
    constructor(props) {
        super(props)
        this.uri    = {
            "cota": {
                proto: "https",
                domain: "api."+domain+"/graphql",
                port: undefined
            },
            "dev": {
                proto: "http",
                domain: "localhost:9000/graphql",
                port: undefined
            },
            "upload": {
                proto: "https",
                domain: "upload."+domain,
                port: undefined
            },
            "dev_upload": {
                proto: "https",
                domain: "upload."+domain,
                port: undefined
            },
            "reform": {
                proto: "https",
                domain: "nifi.reform."+domain,
                port: undefined
            }
        
        }
        this.defaults   = {
            server: (process.env.REACT_APP_DEVMODE) ? "dev" : "cota"
        }
        this.dev_mode       = true
        this.upload         = new Upload({params:{parent:this}})
        this.class          = new Class({params: {parent: this}})
        this.trailers       = new Trailers({params: {parent: this}})
        this.areas          = new Areas({params: {parent:this}})
        this.contacts       = new Contacts({params: {parent:this}})
        this.carriers       = new Carriers({params: {parent:this}})
        this.persons        = new Persons({params: {parent:this}})
        this.users          = new Users({params: {parent:this}})
        this.companies      = new Companies({params: {parent:this}})
        this.roles          = new Roles({params: {parent: this}})
        this.quotes         = new Quotes({params: {parent: this}})
        this.loads          = new Loads({params: {parent: this}})
        this.locations      = new Location({params: {parent: this}})
        this.addresses      = new Address({params: {parent: this}})
        this.languages      = new Languages({params: {parent: this}})
        this.commodities    = new Commodities({params: {parent: this}})
        this.assets         = new Assets ({params: {parent: this}})
        this.requirements   = new Requirements({params: {parent: this}})
        this.stops          = new Stops({params: {parent: this}})
        this.document_types = new DocumentTypes({params: {parent: this}})
        this.event_types    = new Event_types({params: {parent: this}})
        this.events         = new Events({params: {parent: this}})
        this.status         = new Status({params: {parent: this}})
        this.notes          = new Notes({params: {parent: this}})
        this.distance       = new Distance({params: {parent: this}})
        this.summary        = new Summary({params: {parent: this}})
        this.email          = new Email({params: {parent: this}})
        this.invoice_pdf    = new Invoice_pdf({params:{parent: this}})
        this.trips          = new Trips({params: {parent: this}})
        this.documents      = new Documents({params: {parent: this}})
        this.invite         = new Invite({params: {parent: this}})
        this.employees      = new Employees({params: {parent: this}})
        this.drivers        = new Drivers({params: {parent:this}})
        this.rate_item      = new Rate_item({params: {parent:this}})
        this.request_info   = new Request_info({params: {parent:this}})
        this.feedback       = new Feedback_info({params: {parent:this}}) 
        // this.invoice_pdf    = new Invoice_pdf({parmas:{parent: this} })
        this.verbs      = ["GET", "POST", "PUT", "DELETE", "COPY", "HEAD", "OPTIONS"]
        if (!ApiController._instance) {
            ApiController._instance = this;
        }
        return ApiController._instance;
    }

    getInstance() {
        return this._instance;
    }
    mode() {
        return this.dev_mode
    }
    schema(name) {
        return this[name].get_schema()
    }
    get({headers, body}) {
        let options = this.get_options({type: "GET", headers: headers, body: body})
        return options
    }
    async post({uri, server, graph, path, params, headers, body, caller, callback}) {
        let options = this.get_options({type: "POST", headers: headers, body: body})
        if(process.env.REACT_APP_DEVMODE || process.env?.REACT_APP_DOMAIN === "cotasure.com") {
            console.log(caller, body)
        }
        return await this.perform_request({uri: this.get_uri({uri: uri, server: server}), options: options, caller: caller, callback: callback, params})
    }
    generate_url({server}) {
        let server_name = (server === undefined) ? this.defaults.server : server 
        if (this.uri[server_name].port !== undefined) {
            return this.uri[server_name].domain+":"+this.uri[server_name].port
        }
        return this.uri[server_name].domain
    }
    get_uri({uri, server: s}) {
        let server = (s === undefined) ? this.defaults.server : s
        return (uri === undefined) ? (this.uri[server].proto+"://"+this.generate_url({server: s})) : uri
    }
    get_options({type, headers, body}) {
        let body_data = (body === undefined) ? "" : JSON.stringify(body)
        if (this.verbs.includes(type.toUpperCase())) {
            let token = this.parent?.state?.context?.authClient?.token
            let content_type = (headers?.content_type === undefined) ? 'application/json' : headers.content_type
            let new_headers = { 'Content-Type': content_type, 
                            // "authorization": "Bearer "+this.parent?.state?.context?.authClient?.token,
                            "key": "2K4M5N6Q8R9SBUCVDXFYGZJ3K4M6P7Q8SATBUDWEXFZH2J3K5N6P7R9SAT" }
            if (token !== undefined) {
                new_headers.authorization = "Bearer "+token
            }
            const requestOptions = {
                method: type,
                headers: new_headers,
                body: body_data
            };
            return requestOptions
        }
        return undefined
    }
    async file_post({uri, server, graph, path, params, headers, body, caller, callback,type}) {
        type = (type === undefined) ? "POST" : type 
      if(body?.reform){
        type = "PUT"
        server = "reform"
      }
        let options = await this.get_file_options({type: type, body: body})
        if(params?.request_type === "file") {
            return await this.perform_file_request({uri: this.get_uri({uri: uri, server: server}), options: options, caller: caller, callback: callback, params: params})
        }
        return await this.perform_request({uri: this.get_uri({uri: uri, server: server}), options: options, caller: caller, callback: callback, params: params})
    }
    async get_file_options({type, body}) {
        if (this.verbs.includes(type.toUpperCase()) && body !== undefined) {
            let token = this.parent?.state?.context?.authClient?.token
            // let token = "somecooltokenthatistotallyencryptedandsuperlong"
            // console.log(body.file.size, body.file.type, body.file.name)
            body.doc_type = (body?.doc_type === undefined) ? '58e46053-6ece-4794-a441-bbbfce34e49e' : body.doc_type
       
            let custom_headers = {
                "Content-Type":body.file?.type,
                "Content-Length":`${body.file?.size}`,
                "x-filename": body?.document_name ?? `${body.file?.name}`,
                "x-doc-type": body.doc_type,
                "x-put": `${body.put}`,
                "x-private":`${body.private}`,
                "x-load":`${body.delete}`, // could update headers to have x-delete
                "x-resource":`${body.id}`, // could update headers to have x-resource
                "x-type":`${body.endpoint}`, // the type of resource (user, contact, carrier, load)
//                "x-contact":`${body?.resource_id}`, // could update headers to have x-resource
                "x-auth":token,
                "x-user-info":body.endpoint+":"+body.id,
                "x-to-pdf": body.pdf,
                // needs to be populated by body.filetype or something similar:
                "x-cota-filetype": body.filetype,
                // NEW STUFF
                "x-delete": body?.delete ?? false,
                "x-is-profile-pic": body?.is_profile_pic ?? false,
                "x-document-name": body?.document_name ?? `${body.file?.name}`
            }
            if(body.reform){
                custom_headers = {
                    "x-document-name": body?.document_name ?? `${body.file?.name}`,
                    "key": "2K4M5N6Q8R9SBUCVDXFYGZJ3K4M6P7Q8SATBUDWEXFZH2J3K5N6P7R9SAT",
                    "Content-Type":body.file?.type,
                    "Content-Length":`${body.file?.size}`,
                    "x-file-type": body.doc_type,
                   "authorization": "Bearer "+token,
                }
            }
            let requestOptions = {
                method: type,
                body: body.file,
                headers: custom_headers
            }
            // if(body.reform){ 
            //     requestOptions.body = {
            //         // document: body.file
            //     }
            // }
            return requestOptions
        }
        return undefined
    }
    async perform_file_request({uri, options, caller, callback, params}) {
        if (options !== undefined) {
            if (options.method === "POST" && options.body === "") { return undefined }
            let data = await fetch(uri, options)
                .then(async response => {
                        if (!response.ok) {
                                console.error("HTTP Error "+response.status)
                        }
                        let text = await response.text()
                        return text
                })
                .then(data => data)
                .catch(rejected => {
                    console.error(rejected);
                });
            if(callback !== undefined) {
                this.handle_callback({caller: caller, callback: callback, params: params, data: data})
            }
            return data
        }
    }
    async perform_request({uri, options, caller, callback, params}) {
        if (options !== undefined) {
            if (options.method === "POST" && options.body === "") { return undefined }
            let data = await fetch(uri, options)
                .then(async response => {
                    if (!response.ok) {
                        console.error("HTTP Error "+response.status)
                    }
                    let res = await response.json()
                    res.status = response.status
                    res.statusText = response.statusText
                    return res
                })
                .then(data => data)
                .catch(rejected => {
                    console.error(rejected);
                });
            this.handle_callback({caller: caller, callback: callback, params: params, data: data})
            return data
        }
    }
    isJsonString(str) {
        try {
            JSON.parse(str);
            // var obj = JSON.parse(str);
             // More strict checking
             // if (obj && typeof obj === "object") {
             //    return true;
             // }
    
        } catch (e) {
            return false;
        }
        return true;
    }
    handle_callback({caller, callback, params, data}) {
        console.log("HANDLING CALLBACK",data)
        if (data?.errors !== undefined || data === undefined) {
            this.handle_errors({caller: caller, params: callback, results: data})
        } else if (callback?.p?.sb?.success !== undefined && callback?.p?.sb?.success?.show) {
            this.show_snackbar({msg: callback?.p?.sb?.success?.msg, status: "success", pos: "bottom-right"})
        }
        if (caller?.view?.state !== undefined) {
            // console.log("FIN",caller?.view,callback)
            let finish = (callback?.fin !== undefined) ? callback?.fin : true 
            if (finish) {
                if (caller?.view?.state?._final_req !== undefined && caller?.view?.state?._final_req !== null) {
                    if (data?.data !== null && data?.data !== undefined) {
                        if (caller.view.state._final_req === Object.keys(data?.data)?.[0]) {
                            caller.view.setState({_is_loading: false})
                        }
                    }
                } else {
                    caller.view.setState({_is_loading: false})
                }
            }
        }
        if (callback?.f !== undefined) {
            if (typeof callback.f === "function") {
                callback.f({caller: caller, params: callback?.p, results: data})
            }
        } else {
            if (caller !== undefined) {
                if(typeof caller.handle_results === "function") {
                    caller.handle_results({params: callback?.p, results: data})
                }
            }
        }
    }
    handle_errors({caller, params, results}) {
        let msg = params?.p?.sb?.error?.msg ?? "Oops! Something went wrong!"
        // results should now include the error status and text for better error handling later.
        this.show_snackbar({msg: msg, status: "error", pos: "top-center"})
    }
}
