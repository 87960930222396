import React from "react";
import { Container, Row, Col, Button } from 'react-bootstrap'
import MultiPanel from "components/MultiPanels/MultiPanel"
import "./DispatchedTrip.css"
import TripLoadCard from "./Partials/TripLoadCard/TripLoadCard.partial.jsx";
import CotaUserBar from "components/CotaUserBar/CotaUserBar.partial.jsx"
import LoadWidget from "./Partials/LoadWidget/LoadWidget.partial.jsx";
import NotesPanel from "./Partials/TripNotes/TripNotes.view.jsx";
import IncomeExpenses from "./Partials/IncomeExpenses/IncomeExpenses.partial.view";
import TripLogs from "./Partials/TripLogs/TripLogs.partial";
import Controller from "./DispatchedTrip.controller.jsx"
import StatusButtonsPartial from "./Partials/StatusButtons/StatusButtons.partial.jsx";
import DeleteTripButton from "./Partials/DeleteTripButton/DeleteTripButton.partial.jsx";

export default class DispatchedTrip extends MultiPanel {
    constructor(props) {
        super(props)
        this.custom_canvas      = true
        this.state.screen       = 1
        this.controller         = new Controller({params: {view: this, controller: this.state?.controller}})
        this.state.select_map   = true
        this.state.select_logs  = false
        this.handleProcessEvent = this.handleProcessEvent.bind(this)
        this.state.reset        = false
        this.state.event_types  = []
        this.state.states       = []
        this.state.statuses     = []
        this.state.data.logs    = []
        this.state.data.notes   = []
        this.state.data.loads   = [{}]
    }
    render_complete_trip() {
        if(this.controller.trip?.status !== "empty") { return }
        return (
            <>
                <Row>
                    <Col>
                        <Row>
                            <Button className="dispatch_sheet" onClick={() => this.controller.update_trip_status("COMPLETED")}>
                                Complete Trip
                            </Button>
                        </Row>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
            </>
        )
    }
    render_change_status() {
        if(this.controller.editable && this.controller.trip?.status?.toLowerCase() !== "completed") {
            return <StatusButtonsPartial params={{parent: this, controller: this.controller}}/>
        }
        return this.render_complete_trip()
    }
    render_delete_button() {
        if(this.controller.editable) {
            return <DeleteTripButton params={{parent: this, controller: this.controller}}/>
        }
    }
    renderInnerFrame() {
        // console.log(this)
        return (
            <Container id={this.name} className="generic_100 g-0">
                <Row>
                    <Col>
                        <CotaUserBar params={{ parent: this, controller: this.controller, title: "trips", return: true }} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <TripLoadCard params={{ parent: this, controller: this.controller }} />
                            </Col>
                        </Row>
                        <Row>
                            &nbsp;
                        </Row>
                        <LoadWidget params={{ parent: this, controller: this.controller }} />
                        <Row>
                            &nbsp;
                        </Row>
                        <Row>
                            <Col>
                                <TripLogs params={{ parent: this, controller: this.controller }} />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="auto" />
                    <Col sm={4}>
                        {this.render_change_status()}
                        <Row>
                            <Col>
                                <IncomeExpenses params={{ parent: this, controller: this.controller }} />
                            </Col>
                        </Row>
                        <Row>&nbsp;</Row>
                        <Row>
                            <Col>
                                <NotesPanel params={{ parent: this, controller: this.controller }} />
                            </Col>
                        </Row>
                        <Row>&nbsp;</Row>
                        {this.render_delete_button()}
                    </Col>
                </Row>
            </Container>
        )
    }
}