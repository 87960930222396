//GENERAL REACT MODULES
import React from "react"
import { Row, Col, Button } from "react-bootstrap"

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./StatusButtons.partial.controller.jsx"
import Modal from "./StatusButtonsModal/StatusButtons.modal.jsx"
import "./StatusButtons.partial.css"

export default class StatusButtonsPartial extends _basePartial {
    constructor(props) {
        super(props)
        this.modal_controller   = this.state.parent?.partial_controller
        this.partial_controller = new Controller({ params: { view: this, controller: this.controller } })
        this._modal = Modal
    }
    get status() {
        return this.controller.load?.status?.toLowerCase()
    }
    render_change_status() {
        return (<>
            <Row>
                <Col>{ this.parent.state.data.shipment.status !== "Pending" ?
                    <Button className={"dispatch_button primary_button_coloring " + this._theme}
                        onClick={() => this.toggle_modal({toggle_state: true})}>
                        Change load status
                    </Button> :   <Button className={"dispatch_button primary_button_coloring " + this._theme} onClick={() => this.approve_action()}>
                        Approve
                    </Button>}
                </Col>
            </Row>
            <Row>&nbsp;</Row>
        </>)
    }
    approve_action(){
        this.parent.controller.load.status = "Available"
        this.parent?.controller.save_load("approve")
    }
    render_bill() {
        return (<>
            <Row>
                <Col>
                    <Button className={"dispatch_button primary_button_coloring " + this._theme}
                        onClick={() => this.partial_controller.bill_action()}>
                        Bill
                    </Button>
                </Col>
            </Row>  
            <Row>&nbsp;</Row>
        </>)
    }
    render_dispatch() {
        return (<>
            <Row>
                <Col>
                    <Button className={"dispatch_button primary_button_coloring " + this._theme}
                        onClick={() => this.controller.dispatch_action()}>
                        Dispatch
                    </Button>
                </Col>
            </Row>
            <Row>&nbsp;</Row>
        </>)
    }
    determine_render() {
        switch (this.status) {
            case "available":
                return this.render_dispatch()
            case "billed":
                return this.render_bill()
            case "delivered":
                return (<>
                    {this.render_change_status()}
                    {this.render_bill()}
                </>)
            default:
                return this.render_change_status()
        }
    }
    renderView() {
        return (
            <Row id={this.name}>
                <Col className="g-0">
                    {this.determine_render()}
                </Col>
            </Row>
        )
    }
}

