// GENERAL REACT MODULES
import { Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx"
import configs from "./Shipper.json"
import "./Shipper.css"

export default class ShipperObjectModal extends _baseModal {
    constructor(props) {
        super(props)
        this.state.modal_params = {
            positioned: "relative_to_parent",
            title: "People",
            title_size: "extra_small",
            relative_position: "below",
            relative_alignment: "start",
            x_adjustment: "20px",
            close_on_outside_click: false,
            closeable: true,
        }
        this.state.form = configs.form_objects
        this.modal_controller = this.state.parent?.partial_controller
    }
    renderView() {
        // this.parent_dims = this.state.parent?.divElement?.getBoundingClientRect()
        // this.state.base = {y: this.parent_dims?.top, x: 20}
        // TEMPORARY fix for modal position on carrierPro New Load Page, 
        // but for this modal, position sticky is weird 

        return (
            <>
                <Row>
                    <Col>
                        {
                            this.modal_controller?.contact?.people?.map((person, index) => {
                                return (
                                    <Row className="g-0 people_row pointer" onClick={this.modal_controller.select_person.bind(this.state.parent, index)} key={"person_"+index}>
                                        <Col>
                                            {person?.first_name} {person?.last_name}
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </Col>
                </Row>
            </>
        )
    }
}
