// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "../_ModelController";
import StopModelController from "../StopModelController"

export default class ShipmentModelController extends _ModelController {
    constructor(props) {
        super(props)
        this.stop = new StopModelController({params: {parent: this, 
                                                        controller: this.controller,
                                                        is_view: true}})
    }
    get created_on() {
        return this.data?.created_on
    }
    get cota_id() {
        return this.data?.cota_id
    }
    get dispatcher() {
        return this.data?.dispatcher
    }
    get payor() {
        return this.data?.payor
    }
    get documents() {
        return this.data?.documents
    }
    set documents(value) {
        this.controller.setState({key: "data", param: this._data_param+".documents", value: value})
    }
    get document_name() {
	    return this.data?.document_name
    }
    set document_name(value) {
	    this.data.document_name = value
    }
    get notes() {
	    return this.data?.notes
    }
    set notes(value) {
	    this.data.notes = value
    }
    get co2() {
        if (this.data?.co2 === undefined) {
            return this.controller?.quote?.co2?.amount
        }
        return this.data?.co2
    }
    get rate_items() {
        if (this.data?.rate_items === undefined || this.data?.rate_items === null) {
            this.data.rate_items = []
        }
        return this.data?.rate_items
    }
    set rate_items(value) {
        this.data.rate_items = value
    }
    get rate_mile() {
        if(this._notnull(this.data?.rate_mile)) { return this.data?.rate_mile }
        if(this.distance === 0) { return 0 }
        let rate_mile =  Math.round((this.cost / this.distance) * 100) / 100
        return (isNaN(rate_mile)) ? 0 : rate_mile
    }
    set rate_mile(value) {
        this.data.rate_mile = value
    }
    get linehaul_rate_mile() {
        let rate_mile = Math.round((this.linehaul / this.distance) * 100) / 100
        if(this.distance === 0) { return 0 }
        rate_mile = (isNaN(rate_mile)) ? 0 : rate_mile
        return rate_mile
    }
    get calculated_rate_mile() {
        let calc_rate_mile = Math.round((this.calculated_rate / this.distance) * 100) / 100
        if(this.distance === 0) { return 0 }
        calc_rate_mile = (isNaN(calc_rate_mile)) ? 0 : calc_rate_mile
        return calc_rate_mile
    }
    get linehaul() {
        return this.data?.linehaul
    }
    set linehaul(value) {
        this.data.linehaul = value
    }
    get days() {
        if (this.data?.days === undefined) {
            return this.controller?.quote?.days
        }
        return this.data?.days
    }
    get cost() {
        if (this.data?.cost === undefined) {
            return this.controller?.quote?.cost
        }
        return this.data?.cost
    }
    set cost(value) {
        this.data.cost = value
    }
    get rate() {
        if (this.rate_items !== undefined) {
            if (Array.isArray(this.rate_items)) {
                if (this.rate_items.length > 0) {
                    let sums = this.rate_items.reduce(function (sum, v) {
                        if(JSON.stringify(v) === '{}') {
                            v.rate = 0
                        }
                        return sum + parseFloat(v.rate);
                    }, 0);
                    return sums
                }
            }
        }
	    let rate = (this.data?.rate === undefined || this.data?.rate === null) ? this.cost : this.data?.rate
        return (rate === undefined || rate === null) ? 0 : rate
    }
    get calculated_rate() {
        if (this?.data?.calculated_rate !== undefined && this?.data?.calculated_rate !== null) {
            return this.data?.calculated_rate
        }
        if(this?._notnull(this.data?.linehaul) && this.data?.linehaul > 0) {
            return parseFloat(this.data?.linehaul)
        }
        return parseFloat(this.rate) * this.margin
    }
    get payment_rate() {
        if (this.rate_items !== undefined) {
            let rate_items = JSON.parse(JSON.stringify(this?.data?.rate_items))
            if (Array.isArray(rate_items)) {
                if (rate_items.length > 0) {
                    let sums = rate_items.reduce(function (sum, v) {
                        if(JSON.stringify(v) === '{}' || v?.id === "linehaul") {
                            v.rate = 0
                        }
                        return sum + parseFloat(v.rate);
                    }, 0);
                    if(this._notnull(this.data?.linehaul)) {
                        sums = sums + this.data?.linehaul
                    }
                    sums = Math.round((sums) * 100) / 100
                    return sums
                }
            }
        }
        if(this._notnull(this.data?.payment_rate)) {
            return this.data?.payment_rate
        }
    }
    get margin() {
        if(this._notnull(this.data?.rate_margin)) {
            return this.data?.rate_margin
        }
        if(this._notnull(this.data?.margin)) {
            return this.data?.margin
        }
        let margin = Math.round(100 - ((this.linehaul / this.controller?.load.rate) * 100))
        return (isNaN(margin) || !isFinite(margin)) ? 0 : margin
    }
    set margin(value) {
        this.data.margin = value
    }
    get local_id() {
        if(this._notnull(this.data?.local_id)) {
            return this.data.local_id
        }
        return this.data?.cota_id
    }
    get number() {
        if(this._notnull(this.data?.company_load_number)) {
            return this.data?.company_load_number
        }
        if (this._notnull(this.data?.cota_id)) {
            return this.data.cota_id
        }
        if (this.data?.load_number === undefined || this.data?.load_number === null) {
            return (this.controller.quote?.number !== undefined) ? this.controller.quote?.number : ""
        }
        return (this.data?.load_number === undefined) ? "" : this.data?.load_number
    }
    get carrier() {
        if (this.data?.carrier !== undefined) {
            return this.data.carrier
        }
        return this.controller.getState({key: "data", param: "carrier"})
    }
    get document_name() {
	    return this.data?.document_name
    }
    set document_name(value) {
	    this.data.document_name = value
    }
    set carrier(value) {
        this.data.carrier = value
    //	this.data.carrier = null
        this.controller.setState({key: "data", param: "carrier", value: value})
    }    
    get status() {
        if (this.data?.status !== undefined && this.data?.status !== null) {
            return this.data?.status.replace('_', ' ')
        }
	    return "Pending"
    }
    set status(value) {
        this.data.status = value
        this.status_details = {value: value, name: value}
    }
    get resolved_status() {
        if (this.data?.status_details?.value !== null && this.data?.status_details?.value !== undefined) {
            return this.data?.status_details?.value
        }
        return this.data?.status
    }
    set status_details(value) {
        this.data.status_details = value
    }

    get raw_stops() {
        return this.controller.getState({key: "data", param: "stops"})
    }
    get stops_ids() {
        return this.data.stops
    }
    get pickup() {
        let pickup = this.controller.getState({key: "data", param: "pickup"})
        if (pickup !== undefined) {
            this.stop._load({data: pickup})
            return this.stop
        }
        if (Array.isArray(this.raw_stops)) {
            return this.raw_stops?.[0]
        }
    }

    get pickup_id() {
        if (Array.isArray(this.raw_stops)) {
            return this.stops_ids?.[0]?.id
        }
        return undefined
    }
    get consignee() {
        let consignee = this.controller.getState({key: "data", param: "dropoff"})
        if(consignee !== undefined) {
            this.stop._load({data: consignee})
            return this.stop
        }
        if (Array.isArray(this.raw_stops)) {
            return this.raw_stops?.[this.raw_stops.length-1]
        }
    }

    get consignee_id() {
        if (Array.isArray(this.raw_stops)) {
            return this.stops_ids?.[this.raw_stops.length-1]?.id
        }
        return undefined
    }
    get stops() {
        let stops = JSON.parse(JSON.stringify(this.raw_stops))
        // if (Array.isArray(stops)) {
        //     let self = this
        //     stops = stops?.filter(function(stop) {
        //         return stop.id !== self.pickup_id
        //     })
        //     stops = stops?.filter(function(stop) {
        //         return stop.id !== self.consignee_id
        //     })
        // }
        return stops
    }
    get trips(){
        return this.data?.trips
    }
    get url(){
        return this.data?.url
    }
    get raw_reform() {
        return this.data?.raw_reform
    }
    get_stops() {
        let stops_data = this.controller.getState({key: "data", param: "stops"})
        let stops = []
        if (stops_data !== undefined) {
            if(Array.isArray(stops_data)) {
                stops_data.map((stop, index) => {
                    this.stop._load({data: stop})
                    // this.stop.stop_index = index
                    this.stop.stop_index = (stop?.index === undefined) ? index : stop?.index
                    let _summary = this.stop.summary
                    stops.push(_summary)
                })
            }
        }
        if (stops.length > 0) {
            return stops
        }
        return undefined
    }
    get_loads() {
        let loads_data = this.controller.getState({key: "data", param: "loads"})
        if (loads_data !== undefined) {
            if (Array.isArray(loads_data)) {
                let loads = []
                loads_data.map((load, index) => {
                    loads.push({id: load.id})
                })
                return loads_data
            }
        }
        return undefined
    }
    get bol_number() {
        return this.data?.bol_number
    }
    get po_number() {
        return this.data?.po_number
    }
    get is_hazardous() {
        return this.data?.is_hazardous
    }
    set is_hazardous(value) {
        this.data.is_hazardous = value
    }
    get total_length() {
        return this.data?.total_length
    }
    set total_length(value) {
        this.data.total_length = value
    }
    get total_weight() {
        return this.data?.total_weight
    }
    get is_oversize() {
        return this.data?.is_oversize
    }
    set is_oversize(value) {
        this.data.is_oversize = value
    }
    get pallet_number() {
        return this.data?.pallet_number
    }
    set pallet_number(value) {
        this.data.pallet_number = value
    }
    get pallet_exchange() {
        return this.data?.pallet_exchange
    }
    set pallet_exchange(value) {
        this.data.pallet_exchange = value
    }
    get temperature_from() {
        return this.data?.temperature_from
    }
    set temperature_from(value) {
        this.data.temperature_from = value
    }
    get temperature_to() {
        return this.data?.temperature_to
    }
    set temperature_to(value) {
        this.data.temperature_to = value
    }
    get cases() {
        return this.data?.cases
    }
    set cases(value) {
        this.data.cases = value
    }
    get distance() {
        if (this._notnull(this.data?.distance)) {
            return this.data?.distance
        }
        return 0
    }
    set distance(value) {
        this.data.distance = value
    }
    get profit() {
        if(this._notnull(this.data?.profit)) {
            return this.data?.profit
        }
        let linehaul = (this.linehaul !== undefined) ? this.linehaul : 0
        let profit = Math.round((this.controller?.load?.cost - linehaul) * 100) / 100
        return (isNaN(profit)) ? 0 : profit
    }
    set profit(value) {
        this.data.profit = value
    }
    get_rate_items() {
        if (this.rate_items !== undefined) {
            if (Array.isArray(this.rate_items)) {
                if (this.rate_items.length > 0) {
                    let items = this.rate_items.filter((item, index) => (item.rate !== 0 && item?.id === undefined))
                    items.map((rate_item, index) => {
                        rate_item.rate = this.try_parse(rate_item.rate);
                        return rate_item
                    });
                    return items
                }
            }
        }
        return []
    }
    try_parse(value) {
        if (isNaN(value)) {
            return value;
        } else {
            return parseFloat(value);
        }
    };

    get summary() {
        let load = {
	        id: this._id,
            distance: this.distance,
            payor_id: this.controller?.company?._id,
            // pickup: this.controller?.pickup?.summary,
            // dropoff: this.controller?.consignee?.summary,
            quote_id: this.controller?.quote?._id,
            // status: this.status?.toUpperCase(),
            status: this.resolved_status?.toUpperCase(),
            carrier_id: (this.carrier?.id !== undefined && this.carrier?.id !== null) ? this.carrier?.id : "",
            trailer_type: this.controller?.quote?.trailer_type_id,
            pallet_exchange: this.pallet_exchange,
            is_oversize: this.is_oversize,
            is_hazardous: this.is_hazardous,
            pallet_number: Number(this.pallet_number),
            cases: Number(this.cases),
            temperature_to: Number(this.temperature_to),
            temperature_from: Number(this.temperature_from),

            // special_requirements: this.data?.equipment?.requirements,
            po_number: this.data?.information?.po,
            // container_number: this.data?.information?.container,
            container_number: this.controller?.information?.summary?.container,
            bol_number: this.data?.information?.bol,
            hbol_number: this.data?.information?.house_bol,
            vessel_name: this.data?.information?.vessel_name,
            stops: this.get_stops(),
            loads: this.get_loads(),
            rate: this.rate,
            days: this.days,
            co2: this.co2,
            commodities: this.controller.getState({key: "data", param: "commodities"}),
            notes: this.data.notes,
            rates: this.data.rates,
            rate_items: this.get_rate_items(),
	        // notes: this.controller.getState({key: "data", param: "notes"}),
            information: this.controller?.information?.summary,
            equipment: this.controller?.equipment?.summary,
            total_volume: this.controller?.quote?.total_volume,
            total_weight: this.controller?.quote?.total_weight,
            shipper_company_id: this.data?.pickup?.contact?.id,
            consignee_company_id: this.data?.dropoff?.contact?.id,
            tracking_number: this.controller?.quote?.number,
            rate_mile: Number(this.rate_mile),
            trips: this.trips
        }
        if(this._notnull(this.linehaul)) {
            load.linehaul = Number(this.linehaul)
        }
        if(load.rate === 0 || !load.rate) {
            load.rate = this.cost
        }
        if (this.controller?.pickup?.contact?.id !== undefined) {
            load.pickup = this.controller?.pickup?.summary
        }
        if (this.controller?.consignee?.contact?.id !== undefined) {
            load.dropoff = this.controller?.consignee?.summary
        }
        if(isNaN(load.linehaul)) {
            delete load.linehaul
        }
        if(isNaN(load.rate_mile)) {
            delete load.rate_mile
        }
        if(isNaN(load.pallet_number)) {
            delete load.pallet_number
        }
        if(isNaN(load.cases)) {
            delete load.cases
        }
        if(isNaN(load.temperature_to)) {
            delete load.temperature_to
        }
        if(isNaN(load.temperature_from)) {
            delete load.temperature_from
        }
    	// if (this.data?.load_number === undefined || this.data?.load_number === null) {
    	//     load.load_number = this.controller?.quote?.number
    	// } else {
    	//     load.load_number = this.data?.load_number
    	// }
	    load.scrub()
        return load
    }
}
