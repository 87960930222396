// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class CommodityPartialController extends _viewController {
    constructor(props) {
        super(props)
    }
    handleHazmat() {
        this.view.setState((prevState) => ({
            hazmatColor: prevState.hazmatColor === "#FEF400" ? "#F0F1F7" : "#FEF400"
        }))
    }
    handleOversized() {
        this.view.setState((prevState) => ({
            oversizedColor: prevState.oversizedColor === "#BDE0EB" ? "#F0F1F7" : "#BDE0EB"
        }))
    }
    handlePallets() {
        this.view.setState((prevState) => ({
            palletsColor: prevState.palletsColor === "#043B64" ? "#F0F1F7" : "#043B64",
            iconColor: prevState.iconColor === "black" ? "white" : "black"
        }))
    }
}