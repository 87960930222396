// GENERAL REACT MODULES
import _basePartial from "components/General/_basePartial.jsx"
import { Row, Col } from 'react-bootstrap'

// ALL OTHER MODULES
import "./MiniBadgeColumn.css"
import Controller from "./MiniBadgeColumn.controller.jsx"

export default class MiniBadgeColumn extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this}})
    }
    get items() {
        return this.controller.getState({key: "line_items"})
    }
    get item_data() {
        return this.props?.params?.item_data
        // return this.items?.[this.state?.index]
    }
    get line_item() {
        return this.partial_controller.line_item
    }
    get line_height() {
        return 40
    }
    get _configs() {
        return this.controller.getState({key: "configs"})
    }
    render() {
        return (
            <Col id={this.name} style={{"minWidth": this.state.width}}>
                <Row>
                    {this.render_icons(this.partial_controller.configure_data(this?.props?.params?.data))}
                </Row>
            </Col>
        )
    }
    render_icons(data) {
        if (Array.isArray(data)) {
            return data.map((el, index) => {
                return this.render_icon({data: data, index: index})
            })
        }
        return this.render_icon({data: data})
    }
    render_icon({data, index}) {
        return (
            <Col key={"icon_mini_"+index}>
                <div className={"badge "+this.partial_controller.get_badge_color(index)}>
                    {this.partial_controller.get_icon(index)}
                </div>
            </Col>
        )
    }
}
