// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx";

export default class OverlayController extends _viewController {
    constructor(props) {
        super(props)
    }
    get states() {
        return this.controller.states
    }
    set_company({data}) {
        this.view.setState({company: data})
    }
}