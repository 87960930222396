//GENERAL REACT MODULES
import React from "react"
import { Button, Row, Col } from "react-bootstrap"

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import DeleteDriverModal from "./DeleteDriverButton.modal"

export default class DeleteDriverButton extends _basePartial {
    constructor(props) {
        super(props)
        this.modal_controller   = this.controller
        this._modal = DeleteDriverModal
    }
    get status() {
        return this.controller.driver?.status?.toLowerCase()
    }
    renderView() {
            return <Button variant="danger" className="w-100 cota" onClick={this.toggle_modal.bind(this)}>Delete Driver</Button>
        }
}