// GENERAL REACT MODULES
import { Col } from 'react-bootstrap'
import ListLineController from "components/CotaListPanel/ListLineController.jsx"

export default class FilterLineController extends ListLineController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    get item_data() {
        return this.view.item_data
    }
    get _configs() {
        return this.view._configs
    }
    map_columns() {
        if (this.item_data !== undefined) {
            return Object.keys(this.item_data).map((item, index) => {
                if (this._configs?.hide?.indexOf(item) > -1) { return }
                if (this.is_string(this.item_data?.[item]) || this.is_number(this.item_data?.[item]) || null || this.is_obj(this.item_data?.[item])) {
                    return (
                        <Col className="flex" key={"filter_col_"+index}>
                            <input type="text" id="fname" name={item} className="filter_input" onChange={(e) => this.filter_change(this, e)}/>
                        </Col>
                    )
                }
                return(<></>)
            })
        }
    }
    filter_change(e) {
        if (this.controller?.view?.state?.param_filters !== undefined) {
            this.controller.view.state.param_filters[e.target.name] = e.target.value
            if (e.target.value.length > 2) {
                this.controller.view.forceUpdate()
            }
        }
    }
    add_button() {
        if (this._configs?.button !== undefined) {
            return (
                <Col className="cota_table_button button_column_width">
                </Col>
            )
        }
        return
    }
    self_filter() {
        if (this.view.state?.line_filter) {
            return (
                <Col className="filter_btn">
                </Col>
            )
        }
    }
    add_actions() {
        if (this.view.state?.actions !== undefined) {
            return (
                <Col className="action_btn" key={"add_actions_"+this.view.state?.index}>
  
                </Col>
            )
        }
    }
}
