import _viewController from "controllers/ViewController/_viewController.jsx";
import asset_request from "assets/graphql/Assets/Assets.unlink.graphql.json"

export default class UnhookAssetModalController extends _viewController {
    constructor(props) {
        super(props)
    }
    follow_handle_results({params, results}) {

    }
    unattach_asset() {
        let body = JSON.parse(JSON.stringify(asset_request)) 
        let _summary = {
            id: this?.controller?.asset?.id
        }
        this.controller.view.state.data.asset.linked_asset = undefined
        this.view.state.parent.toggle_modal({toggle_state: false})
        this.view.state.parent.setState({redirectToAssignAsset: true})
        this.controller.view.forceUpdate()
        if(_summary.id !== undefined) {
            let data = this.controller.toUnquotedJSON(_summary)
            body.query = body.query.replace("input:", 'input: '+data)
            this?.controller?.api?.assets?.create({caller: this, params: {body: body}, callback: {f: this.process_assignment}})
        }
    }
    process_assignment({caller, params, results}) {
        if(results?.errors !== undefined) {
            console.log("Error unlinking asset", results.errors)
            return
        }
        // console.log("Asset unlinked")
        caller.controller.view.state.data.asset.linked_asset = undefined
        caller.controller.view.forceUpdate()
    }
}