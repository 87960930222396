import _viewController from "controllers/ViewController/_viewController.jsx"

export default class IncomeExpensesController extends _viewController {
    constructor(props) {
        super(props)
    }
    get color() {
        if(this.sum > 0) {
            return "green"
        }
        if (this.sum < 0) {
            return "red"
        }
        return "black"
    }
    get margin() {
        return 0.8
    }
    get income() {
        return this.format_currency(this.load_totals)
    }
    get expenses() {
        return this.format_currency(this.log_totals)
    }
    get sum() {
        return this.load_totals - this.log_totals
    }
    profit_and_loss() {
        return this.format_currency(this.sum)
    }
    format_currency(num) {
        num = (this.not_null(num)) ? num : 0
        num = (isNaN(num)) ? 0 : num
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    get loads() {
        let loads = JSON.parse(JSON.stringify(this.controller.loads))
        return loads
    }
    get load_totals() {
        let total = this.loads?.reduce((prev, current) => {
            let rate = this.configure_rate(current)
            // current.linehaul = (this.not_null(current?.linehaul)) ? current.linehaul : 0
            return prev + Number.parseFloat(rate)
        }, 0)
        return total
    }
    configure_rate(load) {
        let rate = 0
        // if(this.not_null(load?.calculated_rate)) { rate = load?.calculated_rate }
        // if(this.not_null(load?.linehaul) && load?.linehaul > 0) { rate = load?.linehaul }
        if(this.not_null(load?.payment_rate)) { rate = load?.payment_rate }
        return rate
    }
    get log_totals() {
        let total = this.controller.logs?.reduce((prev, current) => {
            current.value = (this.not_null(current.value)) ? current.value : 0
            return prev +  Number.parseFloat(current.value)
        }, 0)
        return total
    }
    not_null(value) {
        if(value !== undefined && value !== null) {
            return true
        }
        return false
    }
}