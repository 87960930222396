// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "../_ModelController";

export default class EventModelController extends _ModelController {
    constructor(props) {
        super(props)
    }
    get document_name() {
        return this.data?.document_name
    }
    set document_name(value) {
        this.data.document_name = value
    }
    get created_date() {
        return this.data?.created_date 
    }

    get date() {
        if (this._notnull(this.data?.created_date)) {
            return this.format_time(this.data?.created_date, {
                year: "numeric",
                month: "long",
                day: "numeric",
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            })
        }
        else{
            return this.format_time(Date.now(), {
                year: "numeric",
                month: "long",
                day: "numeric",
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            })
        }
    }

    get time() {
        if (this._notnull(this.data?.created_date)) {
            return this.format_time(this.data?.created_date, {
                hour: "numeric",
                minute: "numeric",
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                timeZoneName: "short",
            })
        }
        else{
            return this.format_time(Date.now(), {
                hour: "numeric",
                minute: "numeric",
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                timeZoneName: "short",
            })
        }
        // return this.data?.time
    }

    format_time(data, options) {
       // console.log("DATA :", data)
        //     var strDateTime = `${data} GMT`;
        //     var myDate = new Date(strDateTime);
        //  console.log(myDate)
        // console.log("DATA :",data)
        //    console.log("LOCAL TIME :",Intl.DateTimeFormat().resolvedOptions().timeZone)
        // const options = {
        //     hour: "numeric",
        //     minute: "numeric",
        //     timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        //     timeZoneName: "short",
        // }
        const timeFormat = new Intl.DateTimeFormat('en-US', options)
        let time = new Date(Number(data))
      ///  console.log("TIME :", timeFormat.format(time))
        return timeFormat.format(time)
        // const dt = new Date.now(0);
        // const hr = dt % 86400 / 3600 ;
        // const m = dt % 3600 / 60;
        // console.log("HR",hr)

        // return hr.substring(-2) + ':' + m.substring(-2)
    }
    get value() {
        if (this.data?.value !== undefined && this.data?.value !== null) {
            return parseFloat(this.data?.value)
        }
        return this.data?.value
    }
    set value(value) {
        this.data.value = value
    }
    // get address() {
    //     // Must return Address in order for the rest of locations to work
    //     return (this._notnull(this.data?.address?.address)) ? this.data?.address?.address : this.company?.address
    // }
    // set address(value) {
    //     if (this.company?._id === undefined) {
    //         this.company.address = value
    //     } else {
    //         this.setState({param: "address.address", value: value})
    //     }
    // }
    get address() {
        let _address = this.data?.address?.address
        if(!this._notnull(_address)) {
            let search_address = this.controller.getState({key: "search", param: "address.search_value"})
            if(this._notnull(search_address) && typeof search_address !== "object") {
                _address = search_address
            }
        }
        return _address
    }
    set address(value) {
        this.setState({ param: "address.address", value: value })
    }
    get address_2() {
        return this.data?.address_2
    }
    set address_2(value) {
        this.setState({ param: "address.address_2", value: value })
    }
    get raw_location() {
        return this.data?.address?.location
    }
    get city() {
        let _city = this.raw_location?.city
        if(!this._notnull(_city)) {
            _city = this.data?.city
        }
        return _city
    }
    set city(value) {
        this.setState({ param: "address.location.city", value: value })
    }
    get state() {
        let _state = this.raw_location?.state
        if(!this._notnull(_state)) {
            _state = this.data?.state
        }
        return _state
    }
    set state(value) {
        this.setState({ param: "address.location.state", value: value })
    }
    get country() {
        return this.raw_location?.country
    }
    set country(value) {
        this.setState({ param: "address.location.country", value: value })
    }
    get zip() {
        let _zip = this.raw_location?.zip
        if(!this._notnull(_zip)) {
            let search_zip = this.controller.getState({key: "search", param: "zip.search_value"})
            if(this._notnull(search_zip) && typeof search_zip !== "object") {
                _zip = search_zip
            }
        }
        return _zip
    }
    set zip(value) {
        this.setState({ param: "address.location.zip", value: value })
    }
    get location() {
        let location = [this.city, this.state, this.zip]
        location = (location.filter((el) => this._notnull(el) && el !== ""))?.join(", ")
        if (this._notnull(this.country)) { location += " " + this.country }
        return location
    }
    get street_address() {
        let address = (this._notnull(this.address)) ? this.address : ""
        let addrress_2 = (this._notnull(this.address_2)) ? this.address_2 : ""
        if (addrress_2 !== "") { address += " " + addrress_2 }
        return address
    }
    get full_address() {
        let address = (this.street_address !== "") ? this.street_address : ""
        address = ([address, this.location].filter((el) => this._notnull(el) && el !== ""))?.join(", ")
        return address
    }
    get details() {
        return this.data?.details
    }
    get type() {
        let type = this.data?.details?.title
        if (this._notnull(type)) { return type }
        return this.data?.type
    }
    set type(value) {
        this.data.type = value
    }
    get inference() {
        return this.data?.inference
    }
    get note() {
        return this.data?.note
    }
    get load_id() {
        return this.data?.load_id
    }
    set load_id(value) {
        this.data.load_id = value
    }
    get trip_id() {
        return this.data?.trip_id
    }
    set trip_id(value) {
        this.data.trip_id = value
    }
    get documents() {
        return this.data?.documents
    }
    set documents(value) {
        this.data.documents = value
    }
    resolve_address() {
      //  console.log("STATE CHECK :", this.controller)
        let address = this.controller.getState({ key: "search", param: "address.selected_value" })
        if (address?.address !== undefined) {
            return address?.address
        }
        address = this.controller.getState({ key: "search", param: "address.search_value" })
        return address
    }
    resolve_zip() {
        let _zip = this.controller.getState({ key: "search", param: "zip.selected_value" })
        if (_zip !== undefined) {
            return _zip.zip
        }
        _zip = this.controller.getState({ key: "search", param: "zip.search_value" })
        return _zip
    }
    get summary() {
        let _summary = {
            date: this.date,
            value: parseFloat(this.value),
            type: this.type,
            load_id: this.load_id,
            trip_id: this.trip_id,
            note: this.controller?.note?.summary
        }
        if (isNaN(_summary.value)) {
            delete _summary.value
        }
        if (this.address !== null && this.address !== undefined) {
            _summary.address = {
                address: this.resolve_address(),
                address_2: this.address_2,
                location: {
                    city: this.city,
                    state: this.state,
                    zip: this.zip
                }
            }
        }
        _summary.scrub()
        return _summary
    }
}