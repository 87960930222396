import React from "react";
import { Row, Col } from "react-bootstrap"

import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./Commodity.partial.controller.jsx"

export default class CommodityPartial extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller ({params: {view: this, controller: this.controller}})
    }
    get commodity() {
        /// SUPER IMPORTANT FOR PROPS PARAMS!!!
        return this.props.params.data
    }
    renderView() {
        // console.log(this)
        return (
            <Row id={this.name}>
                <Col>
                    {this.commodity?.name}
                </Col>
                <Col>
                    {this.commodity?.type}
                </Col>
                <Col>
                    {this.commodity?.quantity}
                </Col>
                {/* <Col>
                    {this.commodity?.piece_count}
                </Col> */}
                {/* <Col>
                    {this.commodity?.length}
                </Col>
                <Col>
                    {this.commodity?.weight}
                </Col> */}
                <Col sm={6}/>
            </Row>
        )
    }
}