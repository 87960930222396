// GENERAL REACT MODULES
import { Row, Col, Button, Form, Container } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal";
import "./ConformationModal.css";
// import config_data from "./AutoLoadModal.json"
// import UploadPartial from "components/FileUpload/Upload.partial.jsx";
import controller from  "./ConformationModal.controller.jsx"


export default class ConformationModal extends _baseModal {
    constructor(props) {
        super(props)
        // this.state.form = config_data.form_objects
        this.modal_controller = new controller
        // this.controller.setState({ key: "private_file", value: true })
        // this.state.docTypes = test_data.docTypes
        // this.state.got_doc_types = false
        // this.state.docTypes = []
        this.state.modal_params = {
            positioned: "middle_of_panel",
            ignore_sidebar: true,
            title: "",
            title_size: "",
            closeable: false,
            has_overlay: true,
            close_on_outside_click: false,
            draggable: false,
        }
    }

    renderView() {
        // this.get_doc_types()
        console.log(this)
        return (
            <Container id={this.name}>
                <Row  className="g-0 col_padding remove_top_padding">
                    <Col className="g-0 col_padding remove_padding">

                        <Row className="g-0 description font pad_bottom">
                            <center>
                                {/* {console.log("CHECKING FORM OBJ :",this.controller?.view)} */}
                                {/* {console.log("CHECKING FORM PARENT :",this)} */}
                               Got it!
                            </center>
                        </Row>
                        <Row className="pad_bottom font">

                            {/* {console.log("CHECKING FORM OBJ :",this.controller?.view)} */}
                            {/* {console.log("CHECKING FORM PARENT :",this)} */}
                            <center>
                            We need some time to look this over. This usually takes a minute or two. Please check back soon!
                            </center>
                        </Row>
                        <Row className="font pad_bottom">
                            <center>
                          {/* Load Number: C-XXXXX */}
                          Load Number: {this?.state?.parent?.state?.processing_id}
                          </center>
                        </Row>
                  
                        <Col />
                        <Row className="pe-3">
                          
                       
                            <Col className={"edit_contact button_col "}>
                                <center>
                          
                                <Button size="sm"  onClick={() => this.modal_controller.changeModal(this)} className={"primary_button_coloring " + this._theme}>
                                    OK
                                </Button>
                                </center>
                            </Col>
                            {/* <Col sm={1}/> */}
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}
