//REACT SPECIFIC 
import { Button, Row, Col, Card, Container} from "react-bootstrap";

//MODULE SPECIFIC
import Controller from "./EditTrailerPanel.controller.jsx";
import _basePanelView from "components/General/_basePanelView.jsx"
import _basePartial from "components/General/_basePartial.jsx";
import form_configs from "../EditTrailer/EditTrailerPanel.json"
import { FaImage } from "react-icons/fa"
import "./EditTrailer.css";
import HelperPanel from "components/HelperPanel/HelperPanel.partial.jsx"
import CotaUserBar from "components/CotaUserBar/CotaUserBar.partial.jsx"

export default class EditTrailerPanel extends _basePanelView {
    constructor(props) {
        super(props);
        this.state.form             = form_configs.form_objects
        this.state.form_configs     = form_configs
        this.controller             = new Controller({ params: { view: this } })
        this.state.trailer_types    = []
        this.state.more_info        = true
        this.state.states           = []
    }
    toggleMoreInfo = () => {
        this.setState((prevState) => ({
            more_info: !prevState.more_info
        }))
    }
    renderMain() {
        const { more_info } =this.state
        return (
            <Container id={this.name} className="generic_100">
                <Row className="max_height">
                    <Col className="flex_wrapper_rows">
                        <Row>
                            <CotaUserBar params={{parent: this, controller: this.controller, title: "assets", return: true}} />
                        </Row>
                        <Row className="generic_100 margin_auto">
                            <Col>
                                <div className="g-0 cota_panel_border add_shadow pb-4">
                                    <Row className="padding info">
                                        <Col>
                                            <Row>
                                                <Col sm={3}>
                                                    <Row className="pad_top">
                                                        <Col>
                                                            <div className="icon_row icon_col" type="button">
                                                                <FaImage className="icon" size={25} onClick={this.controller.handleClick} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className="pad_top ">
                                                    <Row className="no_wrap">
                                                        <Col className="info MuiInputLabel-shrink">
                                                            TRAILER NUMBER* 
                                                        </Col>
                                                    </Row>
                                                    <Row className="pad_half">
                                                        <Col>
                                                            {this.formObject({ data: this.state.form.trailer_number })}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={4}>
                                            <Row className="pad_top ">
                                                <Col className="info MuiInputLabel-shrink">
                                                    TRAILER TYPE
                                                </Col>
                                            </Row>
                                            <Row className="pad_half">
                                                <Col>
                                                {this.formObject({data: this.state.form.trailer_type,
                                                                        owner: this,
                                                                        options: this.state.trailer_types
                                                                    })}
                                                </Col>
                                            </Row>
                                            <Row style={{paddingTop:"2rem"}}>
                                                <Col sm={1} className="no_wrap">
                                                    {this.formObject({data: this.state.form.support_air_ride, owner: this})} 
                                                </Col>
                                                <Col >
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs="auto"></Col>
                                        <Col xs="auto"></Col>
                                    </Row>
                                    <div style={{ display: more_info ? "none" : "block" }}>
                                        <Row className="padding info">
                                            <Row>
                                                <Col>
                                                    < hr />
                                                </Col>
                                            </Row>
                                            <Col sm={5}>
                                                <Row>
                                                    <Col className="gi">
                                                        General Info
                                                    </Col>
                                                </Row>
                                                <Row className="pad_top">
                                                    <Col className="info MuiInputLabel-shrink">
                                                        MAKE
                                                    </Col>
                                                </Row>
                                                <Row className="pad_quarter">
                                                    <Col>
                                                        {this.formObject({ data: this.state.form.make })}
                                                    </Col>
                                                </Row>
                                                <Row className="pad_top">
                                                    <Col className="info MuiInputLabel-shrink">
                                                        MODEL
                                                    </Col>
                                                </Row>
                                                <Row className="pad_quarter">
                                                    <Col>
                                                        {this.formObject({ data: this.state.form.model })}
                                                    </Col>
                                                </Row>
                                                <Row className="pad_top">
                                                    <Col className="info MuiInputLabel-shrink">
                                                        YEAR
                                                    </Col>
                                                </Row>
                                                <Row className="pad_quarter">
                                                    <Col>
                                                        {this.formObject({ data: this.state.form.year })}
                                                    </Col>
                                                </Row>
                                                <Row className="pad_top">
                                                    <Col className="info MuiInputLabel-shrink">
                                                        WEIGHT, LB
                                                    </Col>
                                                </Row>
                                                <Row className="pad_quarter">
                                                    <Col>
                                                        {this.formObject({ data: this.state.form.weight })}
                                                    </Col>
                                                </Row>
                                                <Row className="pad_top">
                                                    <Col className="info MuiInputLabel-shrink">
                                                        LENGTH, FT
                                                    </Col>
                                                </Row>
                                                <Row className="pad_quarter">
                                                    <Col>
                                                    {this.formObject({data: this.state.form.length,
                                                                    owner: this,
                                                                    options: form_configs.length_options})}
                                                        {/* <MultiSelectDropdown params={{ parent: this,
                                                                                    name: "optionSelectedLength",
                                                                                    title: "selection",
                                                                                    controller: this.controller,
                                                                                    options: form_configs.length_options,
                                                                                    handleChange: this.controller?.handleChange,
                                                                                    optionSelected: this.state.selection}}
                                                            /> */}
                                                    </Col>
                                                </Row>
                                                <Row className="pad_top">
                                                    <Col className="info MuiInputLabel-shrink">
                                                        WEIGHT CAPACITY, LB
                                                    </Col>
                                                </Row>
                                                <Row className="pad_quarter">
                                                    <Col>
                                                        {this.formObject({ data: this.state.form.weight_capacity })}
                                                    </Col>
                                                </Row>
                                                <Row className="pad_top">
                                                    <Col className="info MuiInputLabel-shrink">
                                                        LICENSE PLATE #
                                                    </Col>
                                                </Row>
                                                <Row className="pad_quarter">
                                                    <Col>
                                                        {this.formObject({ data: this.state.form.license_plate })}
                                                    </Col>
                                                </Row>
                                                <Row className="pad_top">
                                                    <Col className="info MuiInputLabel-shrink">
                                                        VIN
                                                    </Col>
                                                </Row>
                                                <Row className="pad_quarter">
                                                    <Col>
                                                        {this.formObject({ data: this.state.form.vin })}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="auto"></Col>
                                            <Col sm={5}>
                                                    <Row className="pad_top">
                                                        <Col className="gi">
                                                            Current mileage and location
                                                        </Col>
                                                    </Row>
                                                    <Row className="pad_top">
                                                        <Col className="info MuiInputLabel-shrink">
                                                            CURRENT LOCATION
                                                        </Col>
                                                    </Row>
                                                    <Row className="pad_quarter">
                                                        <Col>
                                                            {this.formObject({ data: this.state.form.city })}
                                                        </Col>
                                                    </Row>
                                                    <Row className="pt-3">
                                                        <Col>
                                                            {this.formObject({ data: this.state.form.state, owner: this, options: this.state.states })}
                                                        </Col>
                                                        <Col>
                                                            {this.formObject({ data: this.state.form.zip, owner: this })}
                                                        </Col>
                                                    </Row>
                                                    <Row className="pad_top">
                                                        <Col className="info MuiInputLabel-shrink">
                                                            CURRENT MILEAGE
                                                        </Col>
                                                    </Row>
                                                    <Row className="pad_quarter">
                                                        <Col>
                                                            {this.formObject({ data: this.state.form.current_miles })}
                                                        </Col>
                                                    </Row>
                                                    <Row className="pad_top">
                                                        <Col>
                                                            < hr />
                                                        </Col>
                                                    </Row>
                                                    <Row className="pad_top">
                                                        <Col className="gi">
                                                            Starting mileage and location
                                                        </Col>
                                                    </Row>
                                                    <Row className="pad_top">
                                                        <Col className="info MuiInputLabel-shrink">
                                                            STARTING MILEAGE, MILES
                                                        </Col>
                                                    </Row>
                                                    <Row className="pad_quarter">
                                                        <Col>
                                                            {this.formObject({ data: this.state.form.starting_miles })}
                                                        </Col>
                                                    </Row>
                                                    <Row className="pad_top">
                                                        <Col className="info MuiInputLabel-shrink">
                                                            STARTING ZIP CODE
                                                        </Col>
                                                    </Row>
                                                    <Row className="pad_quarter">
                                                        <Col>
                                                            {this.formObject({ data: this.state.form.starting_zip })}
                                                        </Col>
                                                    </Row>
                                                    <Row className="pad_top">
                                                        <Col>
                                                            < hr />
                                                        </Col>
                                                    </Row>
                                                    <Row className="pad_top">
                                                        <Col className="gi">
                                                            Registration Details
                                                        </Col>
                                                    </Row>
                                                    <Row className="pad_top">
                                                        <Col className="info MuiInputLabel-shrink">
                                                            REGISTRATION #
                                                        </Col>
                                                    </Row>
                                                    <Row className="pad_quarter">
                                                        <Col>
                                                            {this.formObject({ data: this.state.form.registration_number })}
                                                        </Col>
                                                    </Row>
                                                    <Row className="pad_top">
                                                        <Col className="info MuiInputLabel-shrink">
                                                            REGISTRATION STATE
                                                        </Col>
                                                    </Row>
                                                    <Row className="pad_quarter">
                                                        <Col>
                                                            {this.formObject({ data: this.state.form.registration_state,options: this.state.states })}
                                                        </Col>
                                                    </Row>
                                                    <Row className="pad_top">
                                                        <Col className="info MuiInputLabel-shrink">
                                                            REGISTRATION DATE
                                                        </Col>
                                                    </Row>
                                                    <Row className="pad_quarter">
                                                        <Col>
                                                            {this.formObject({ data: this.state.form.registration_date })}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row className="pad_top">
                                            <Col>
                                                <hr/>
                                            </Col>
                                        </Row>
                                        <Row className="padding">
                                            <Row className="pad_bot">
                                                <Col>
                                                    <Button onClick={this.toggleMoreInfo} className={"primary_button_coloring "+this._theme}>
                                                        {(this.state.more_info) ? "Show More Info" : "Close Info"}
                                                    </Button>
                                                </Col>
                                                <Col></Col>
                                                <Col>
                                                    <Button onClick={() => this.controller.handleCloseScreen()} className={"center_button clear_button "+this._theme} >
                                                        Cancel
                                                    </Button> 
                                                </Col>
                                                <Col>
                                                    <Button className={"primary_button_coloring "+this._theme} onClick={this?.controller?.saveAction.bind(this)}>
                                                        Save
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Row>
                                </div>
                            </Col>
                            <HelperPanel params={form_configs?.tooltips?.trailers}/>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}
