// GENERAL REACT MODULES
import { Row, Col, Container } from 'react-bootstrap'

//MODULE SPECIFIC
import MultiPanel from "components/MultiPanels/MultiPanel";
import Controller from "./UserPanel.controller.jsx"
import "./UserPanel.css"
import CotaUserBar from "components/CotaUserBar/CotaUserBar.partial.jsx"

export default class UserPanel extends MultiPanel {
    constructor(props) {
        super(props)
        this.custom_canvas = true
        this.controller = new Controller({params: {view: this}})
    }
    get user() {
        return this.controller.user
    }
    // TODO: Need to get other person information like last login and role
    // TODO: Need to create edit and remove / delete functionality
    renderInnerFrame() {
        console.log(this)
        return (
            <Container id={this.name} className="generic_100 g-0">
                <Row className="max_height">
                    <Col className="flex_wrapper_rows">
                        <CotaUserBar params={{parent: this, controller: this.controller, title: "Manage Users", return: true}}/>
                        <Row className="generic_100 margin_auto">

                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}