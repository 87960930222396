//GENERAL REACT MODULES
import React from "react"
import { Row, Col } from 'react-bootstrap'

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx";
import Controller from "./DriverTrips.controller.jsx"
import "./DriverTrips.css"
import TripRow from "./TripRowPartial/TripRow.partial.jsx";

export default class DriverTripsPartial extends _basePartial {
    constructor (props) {
        super(props)
        this.partial_controller      = new Controller ({params: {view: this, controller: this?.controller}})
    }
    render_trips() {
        if(this.controller.trips?.length > 0) {
            return this.controller.trips.map((trip, index) => {
                return <TripRow key={"trip_row_"+index} params={{parent: this, controller: this.controller, data: {trip: trip}, index: index}}/>
            })
        }
        return (
            <Row className="g-0">
                <Col className="center">
                    Driver is not a part of any trips
                </Col>
            </Row>
        )
    }
    renderView() {
        return (
            <Row id={this.name} className="g-0 cota_panel_border add_shadow">
                <Col>
                    <Row className="g-0">
                        <Col className="header padding">
                            Trips by driver
                        </Col>
                    </Row>
                    <Row className="details ms-3 mt-3 g-0">
                        <Col sm={2}>
                            Trip number
                        </Col>
                        <Col>
                            Truck and trailer
                        </Col>
                        <Col sm={3}>
                            Last Event
                        </Col>
                        <Col sm={1} className="status_col">
                            Status
                        </Col>
                        <Col>
                            Actions
                        </Col>
                    </Row>
                    <Row className="g-0">
                        <Col>
                            <hr />
                        </Col>
                    </Row>
                    {this.render_trips()}
                    <Row className="g-0">
                        <Col>
                            <hr />
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}