// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import test_data from "./test.json"
import schema from "./schema.json"

// ALL OTHER MODULES
import _baseApiObject from "components/General/_baseApiObject";
import load_request from "assets/graphql/Loads/Loads.graphql.json"
import payor_request from "assets/graphql/Loads/loads.payor.graphql.json"
import carrier_request from "assets/graphql/Loads/loads.carrier.graphql.json"
import load_search   from "assets/graphql/Loads/loads.search.graphql.json"
import load_list from "assets/graphql/Loads/loads.chart.graphql.json"
import loadboost from "assets/graphql/Loads/loadboost.query.graphql.json"
import delete_request from "assets/graphql/Rate_items/rate_item.delete.graphql.json"

export default class Loads extends _baseApiObject {
    constructor(props) {
        super(props)
        this.schema     = schema
        this.test_data  = test_data
        // this.rules_file = rules_file
        this.requests   = {
            general: load_request,
            customers: carrier_request,
            carriers: carrier_request,
            creators: carrier_request,
            payors: payor_request,
            list: load_list,
            loadboost: loadboost
        }
    }
    async search({caller, value, callback, params}) {
        let results = value
        if (value?.length >= 2) {
            // TODO: Make sure the loads searched are filtered by user's company's loads as well.
            let body = JSON.parse(JSON.stringify(load_search))
            body.query = body.query.replace("loads", 'loads(name: "'+value+'")')
            params = (params === undefined) ? {} : params
            params.body = body
            results = await this.ask({caller: caller, callback: callback, params: params, nocache: true})
        }
        // if (callback !== undefined) {
        //     callback({caller: caller, params: params, results: results})
        // }
        return results
    }
    async delete({caller, value, callback, params}) {
        let body = undefined
        if(params?.type === "rate_item") {
            body = JSON.parse(JSON.stringify(delete_request))
            body.query = body.query.replace("id:", 'id: "'+value+'"')
            params = (params === undefined) ? {} : params
            params.body = body
            let results = await this.ask({caller: caller, callback: callback, params: params, nocache: true})
            return results
        }
    }
}
