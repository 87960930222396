// MODULE SPECIFIC IMPORTS
import _viewController from "controllers/ViewController/_viewController";

export default class DocumentModalController extends _viewController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    truncateString(string, maxLength) {
        if(typeof string !== "string") { return "" }
        if (string.length <= maxLength) {
            return string;
        } else {
            const truncatedString = string.substring(0, maxLength) + "..."
            return truncatedString;
        }
    }
}