// MODULE SPECIFIC IMPORTS
import _viewController from "controllers/ViewController/_viewController.jsx"
import driver_link from "assets/graphql/Assets/Driver.link.graphql.json"

export default class AssignDriverTruckController extends _viewController{
    constructor(props) {
        super(props)
    }
    follow_on_search({results}) {
        let asset = results?.data?.assets?.[0]
        if(asset?.id !== undefined && asset?.id !== null) {
            this.view.setState({asset: asset})
        }
    }
    link_truck() {
        let asset = this.view.state?.asset
        if(asset?.id !== undefined && asset?.id !== null) {
            let data = {id: asset.id, user_id: this.controller.driver._id}
            data = this.toUnquotedJSON(data)
            let body = JSON.parse(JSON.stringify(driver_link))
            body.query = body.query.replace("input:", "input:"+data)
            this.api.assets.ask({caller: this, params: {body: body}, callback: {f: this.process_truck}})
        }
    }
    process_truck({caller, results, params}) {
        if(results?.errors !== undefined) {
            console.log("Error linking truck", results.errors)
            return
        }
        caller.controller.setState({key: "data", param: "asset", value: results?.data?.linkDriver})
    }
}