//REACT SPECIFIC
import React from "react";
import { Row, Col, Container, Button, Card } from "react-bootstrap";

//MODULE SPECIFIC
import _baseModal from "components/General/_baseModal.jsx";

import "./FeedBackModal.css"

export default class FeedBackModal extends _baseModal {
    constructor(props) {
        super(props)

        this.state.modal_params = {
            positioned: "middle_of_panel",
            has_overlay: true,
            close_on_outside_click: true,

        }
    }
    renderView() {
        return (
            <>
                <div className="padding" id={this.name}>
                    <Col >
                        <Row>
                            <Col className="font-size padding" >
                                <center>
                                    Thanks For Your Feedback!
                                </center>
                            </Col>
                        </Row>

                    </Col>
                </div>
            </>
        )
    }
}