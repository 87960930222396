// PAGE SPECIFIC MODULES
import LoadDetailsPanel from "../LoadDetailsPanel/LoadDetails.partial.jsx";
import ColumnController from "components/Column/Column.controller.jsx"

export default class ColumnTwoController extends ColumnController {
    constructor(props) {
        super(props)
        this.state.data = {
            shipper: {},
            consignee: {},
            stop: undefined
        }
        this.state.rows = this.generate_rows()
    }
    generate_rows(){
        let rows = Array(1).fill(undefined)
          // manages state of LoadDetailsPanel component. Intermediary between the view (components) and the model (data) of the application
        rows[0] = {obj: LoadDetailsPanel, params: {params: {parent: this, controller: this.view.controller, editable: true, index: 0}}}
        return rows
    }
}