//MODULE SPECIFIC
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class PersonItemController extends _viewController {
    constructor(props) {
        super(props)
    }
    getLastPerson() {
        let newIndex = this.state.index - 1
        if(newIndex < 1) {
            newIndex = this.controller.personModels?.length - 1
        }    
        this.setState({
            index: newIndex,
            person: this.controller.personModels[newIndex]
        })
        this.forceUpdate()
    }
    getNextPerson() {
        let newIndex = this.state.index + 1
        if(newIndex >= this.controller.personModels?.length) {
            newIndex = 1
        }
        this.setState({
            index: newIndex,
            person: this.controller.personModels[newIndex]
        })
        this.forceUpdate()
    }
    toggle_btn_overlay(event) {
        if(this.state.target === undefined) {
            this.setState({target: event.target})
        } else {
            this.setState({target: undefined})
        }
        this.setState(prevState => ({
            overlay: !prevState.overlay
        }))
        this.forceUpdate()
    }
    delete_person() {
        let person = JSON.parse(JSON.stringify(this.view.state.person.summary))
        // console.log("delete person")
    }
    edit_person() {
        let person = JSON.parse(JSON.stringify(this.view.state.person.summary))
        this.view.toggle_modal({toggle_state: true})
    }
}