// GENERAL REACT MODULES
import { createContext } from 'react'

// ALL OTHER MODULES
import MobileDetect from 'mobile-detect'
import ApiController from "./ApiController/ApiController.jsx"
import CacheController from './CacheController/CacheController.jsx'
import grafana_config from "assets/grafana_config.json"
import grafana_test from "assets/grafana_test.json"
import graphql_user from "assets/graphql/Users/self.graphql.json"

const mContext = {
                    settings: {},
                    api: new ApiController(),
                    user: {
                        companies: [],
                        company_ids: [],
                        roles: [],
                        role_ids: [],
                        contacts: [],
                        preferences: {}
                    }
                }
export const MainContext = createContext(mContext)

export const MainConsumer = MainContext.Consumer
export const MainProvider = MainContext.Provider

export const ContextConstructor = function(context) {
    context.grafana_config = (process.env.REACT_APP_DEVMODE) ? grafana_test : grafana_config
    context.api         = new ApiController({params: {parent: this}})
    context.cache       = new CacheController({params: {parent: this}})
    context.get_self    = async function({caller, update}) {
        if (this.self !== undefined && (update === undefined || !update)) {
                return this.self
        }
        if (process.env.REACT_APP_DEVMODE) {
            // return {
            //         "iss": "https://auth.cotafreight.com/auth/realms/cota",
            //         "aud": "cotafreight",
            //         "sub": "4df09522-6ca5-4ee5-b507-9278ec835af9",
            //         "typ": "ID",
            //         "azp": "cotafreight",
            //         "email_verified": true,
            //         "roles": [
            //             "FREIGHT_00",
            //             "default-roles-cota",
            //             "GrafanaAdmin",
            //             "offline_access",
            //             "admin",
            //             "uma_authorization",
            //             "INTERNAL_COTA_USER",
            //             "COTA_SUPER_USER"
            //         ],
            //         "name": "Nicholas Damuth",
            //         "preferred_username": "cotadev",
            //         "given_name": "Nicholas",
            //         "family_name": "Damuth",
            //         "email": "nick@cotasystem.com",
            //         "picture": "https://test"
            // }
        }
        let body = JSON.parse(JSON.stringify(graphql_user))
        let sb_config = {error: {show: false}}
        let response = await this.api?.users.ask({caller: caller, params: {body: body}, nocache: true, callback: {f: caller.process_user_load, p: {sb: sb_config}}})
        if (response === undefined) {
            caller.process_user_load({caller: caller, results: {"status": "failed"} })
        }
        if (response?.data?.self !== null) {
            this.self = response?.data?.self
            return this.self
        } else {
            caller.process_user_load({caller: caller})
        }
    }
    context.is_mobile = function(type) {
        type = new MobileDetect(window.navigator.userAgent)
        if (type.os() === "iOS") {
            return true
          } else if (type.os() === "AndroidOS") {
            return true
          } else if (type.os() === "BlackBerryOS") {
            return false
          } else if (type.os() === "WindowsOS") {
            return false
          } else if (type.os() === "MacOS") {
            return false
          } else {
            return false
          }
    }
    context.get_token   = function() {
        if (!this.user?.loaded && this.authClient !== undefined) {
            return this.authClient?.idTokenParsed
        }
        return ""
    }
    return context
}

export default MainContext
// export function MainProvider(props) {
//     const [state, dispatch] = useReducer(swap_theme, initialState);
//     return (
//         <Context.Provider value={[state, dispatch]}>
//             {props.children}
//         </Context.Provider>
//     );
// }

// function swap_theme(state, action) {
//     console.log(state)
//     console.log(action)
// }

// export default MainContext
