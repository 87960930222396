// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Container, Card } from "react-bootstrap"
import { FaImage } from "react-icons/fa";

// OBJECT SPECIFIC MODULES
import _basePanelView from "components/General/_basePanelView.jsx";
import CotaUserBar from "components/CotaUserBar/CotaUserBar.partial.jsx"
import HelperPanel from "components/HelperPanel/HelperPanel.partial.jsx"
import Controller from "./NewContactPanel.controller.jsx"
import configs from "./NewContactPanel.json"
import "./NewContactPanel.css"
import contact from "controllers/ApiController/Endpoints/Contacts/schema.json"

export default class NewContactPanel extends _basePanelView {
  constructor(props) {
    super(props)
    this.state.data = JSON.parse(JSON.stringify(contact))
    this.state.form         = configs.form_objects
    this.state.configs      = configs
    this.controller         = new Controller({ params: { view: this } })
    this.state.countries    = this.countries ?? []
    this.state.states       = this.states ?? []
    this.state.credit       = false
    this.roles              = this.controller.contact.roles
    this.state.payor        = (this.roles?.payor)     ? "payor" : ""
    this.state.shipper      = (this.roles?.shipper)   ? "shipper" : ""
    this.state.consignee    = (this.roles?.consignee) ? "consignee" : ""
  }
  get countries() {
    return this.state.panel_params.controller.getState({key: "countries"})
  }
//   get states() {
//     return this.state.panel_params.controller.getState({key: "states"})
//   }
  render_credit() {
    if(this.state.credit === false) {
        return <></>
    }
    return (<>
        <Row>
            {this.controller.editable_field({data: this.state.form.credit_limit, label: "Credit Limit"})}
        </Row>
        <Row className='pad_top_8'>
            {this.controller.editable_field({data: this.state.form.credit_terms, label: "Credit Terms"})}
        </Row>
        <Row className='mt-5 align-items-middle'>
            <Col>
                <hr />
            </Col>
        </Row>
    </>)
    }
    render() {
        return (
            <Container id={this.name} className="generic_100">
                <Row className="max_height">
                    <Col className="flex_wrapper_rows">
                        <Row>
                            <CotaUserBar params={{parent: this, controller: this.controller, title: "New Contact", return: true}}/>
                        </Row>
                        <Row className="generic_100 margin_auto">
                            <Col>
                                <Row>
                                    <Col>
                                        <div className="g-0 cota_panel_border add_shadow pb-4">
                                            <Row className="padding">
                                                <Col xs="auto">
                                                    <Row className="icon_row icon_col" type="button">
                                                    <FaImage size={25} className="icon"/>
                                                    </Row>
                                                </Col>
                                                <Col xs="auto"/>
                                                <Col sm={6}>
                                                    <Row className="pad_top">
                                                        <Col className="name">
                                                            {this.controller.editable_field({data: this.state.form.company, label: "Contact Name"})}
                                                        </Col>
                                                    </Row>
                                                    <Row id="RoleButtonRow" className="m-0 p-0 pad_top">
                                                    <Col className="pad_top_8 pb-1">
                                                        <Row>
                                                            <Col className="info">
                                                                Role
                                                            </Col>
                                                        </Row>
                                                        <Row className="g-2">
                                                            <Col className={"btn mx-2 "+this.state.payor}
                                                                type="button"
                                                                onClick={(e) => this.controller.handleRoleClick(e)}>
                                                                payor
                                                            </Col>
                                                            <Col className={"btn mx-2 "+this.state.shipper}
                                                                type="button"
                                                                onClick={(e) => this.controller.handleRoleClick(e)}>
                                                                shipper
                                                            </Col>
                                                            <Col className={"btn mx-2 "+this.state.consignee}
                                                                type="button"
                                                                onClick={(e) => this.controller.handleRoleClick(e)}>
                                                                consignee
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    </Row>
                                                    {/* End Role Row */}
                                                </Col>
                                                <Col />
                                            </Row>
                                            <Row className="pad_top">
                                                <Col>
                                                    <hr />
                                                </Col>
                                            </Row>
                                            <Row className="padding g-0">
                                                <Col>
                                                    <Row>
                                                        {this.controller.editable_field({data: this.state.form.address, label: "Address"})}
                                                    </Row>
                                                    <Row className='pad_top_8'>
                                                        {this.controller.editable_field({data: this.state.form.address_2, label: "Address line 2"})}
                                                    </Row>
                                                    <Row className='pad_top_8'>
                                                        {this.controller.editable_field({data: this.state.form.city, label: "City"})}
                                                    </Row>
                                                    <Row className='pad_top_8'>
                                                        <Col>
                                                            {this.controller.editable_field({data: this.state.form.state, options: this.state.states, label: "State/Province"})}
                                                        </Col>
                                                        <Col>
                                                            {this.controller.editable_field({data: this.state.form.zip, label: "Zip Code"})}
                                                        </Col>
                                                    </Row>
                                                    <Row className='pad_top_8'>
                                                        {this.controller.editable_field({data: this.state.form.country, options: this.controller.getState({key: "countries"}), label: "Country"})}
                                                    </Row>
                                                    <Row className='pad_top_8'>
                                                        {this.controller.editable_field({data: this.state.form.email, label: "Email Address"})}
                                                    </Row>
                                                    <Row className='pad_top_8'>
                                                        {this.controller.editable_field({data: this.state.form.phone, label: "Phone"})}
                                                    </Row>
                                                    <Row className='pad_top_8'>
                                                        {this.controller.editable_field({data: this.state.form.fax, label: "Fax"})}
                                                    </Row>
                                                    <Row className='pad_top_8'>
                                                        {this.controller.editable_field({data: this.state.form.website, label: "Website"})}
                                                    </Row>
                                                </Col>
                                                <Col sm={1} className="seperator"/>
                                                <Col>
                                                    {this.render_credit()}
                                                    {/* {this.controller.credit_form(this)} */}
                                                    <Row className={this.credit_fields ? "pad_top_8" : ""}>
                                                        {this.controller.editable_field({data: this.state.form.facebook, label: "Facebook"})}
                                                    </Row>
                                                    <Row className='pad_top_8'>
                                                        {this.controller.editable_field({data: this.state.form.twitter, label: "Twitter"})}
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col>
                                                    <hr />
                                                </Col>
                                            </Row>
                                            <Row className='align-items-middle'>
                                                {this.controller.edit_button_row()}
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <HelperPanel params={configs?.tooltips?.contacts}/>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}
