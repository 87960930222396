// GENERAL REACT MODULES
import React from "react";
import { Row, Col } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import "./Column.css"

export default class Column extends _basePartial {
    constructor(props) {
        super(props)
        if (this.state.view_controller !== undefined) {
            this.state.data = this.state?.view_controller?._data
            this.state.rows = this.state?.view_controller?._rows
            this.state.view_controller.column = this
        }
	    this.onDragEnd = this.onDragEnd.bind(this)
    }
    get spacing() {
        if (this.state.full_spacing !== undefined) {
            return this.state.full_spacing
        }
        return false
    }
    eval_full_height() {
        if (this.spacing) {
            let style_props = {display: "block", height: (100/this.state.rows.length)+"%"}
            return style_props
        }
        return {}
    }
    return_rows() {
        let dnd_index = -1
        return this.state?.view_controller?._rows?.map((row, index) => {
            if (row !== undefined) {
                if (row?.params?.params?.dnd) {
		            dnd_index = dnd_index+1 
                    return (
                        <Draggable key={"row_wrapper_"+index} draggableId={"stop_"+dnd_index} index={dnd_index} style={this.eval_full_height()}>
                            {(provided, snapshot) => (
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    {
                                        React.createElement(row?.obj, row?.params, undefined)
                                    }
                                </div>
                            )}
                        </Draggable>
                    )
                } else {
                    return(
                        <span key={"row_wrapper_"+index} style={this.eval_full_height()}>
                            {
                                React.createElement(row?.obj, row?.params, undefined)
                            }
                        </span>
                    )
                }
            }
        })
    }
    onDragEnd(result) {
        if (!result.destination) {
            return
        }
        this.controller.view.state.data.stops = this.reorder(this.controller.view.state.data.stops,
                                result.source.index,
                                result.destination.index)
    }
    reorder(list, startIndex, endIndex) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        result.map((stop, index) => {
            stop.index = index
            return stop
        })
        return result;
    };
    render() {
        if (this.state.view_controller !== undefined) {
            this.state.view_controller.controller = this.controller
        }
        return (
            <>
                <Col id={this.name}>
                    <Row className="g-0 max_height">
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <Col {...provided.droppableProps} ref={provided.innerRef}>
                                {
                                    this.return_rows()
                                }
                                </Col>  
                            )}
                            </Droppable>
                        </DragDropContext>
                    </Row>
                </Col>
            </>
        )
    }
}
