import _viewController from "controllers/ViewController/_viewController.jsx"

export default class TripOptionsPartialController extends _viewController {
    constructor(props) {
        super(props)
    }
    follow_on_selection({event, obj, data}) {
        delete this.view.state.search.company
        delete this.view.state.data.companies
        this.controller.option_requests({company_id: obj?.id})
        // this.view.setState({company_id: obj?.id})
    }
    display_tab_total(tab) {
        let total = this.controller[tab]?.length
        total = (total === null || total === undefined) ? 0 : total
        let color = (total === 0) ? "red" : "green"
        return (
            <span style={{color: color}}>
                {" ("+total+")"}
            </span>
        )
    }
}