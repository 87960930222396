// GENERAL REACT MODULES
import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';

export default class CacheController {
    constructor(props) {
        this.cache = []
        this.preloader()
    }
    get_hash({key, id}) {
        let secret = (key === undefined) ? "" : key
        let hash = sha256(id)
        return Base64.stringify(hmacSHA512(hash, secret))
    }
    get_from_cache({key, id}) {
        let obj = this.get_hash({key, id})
        if (this.cache?.[key]?.[obj] !== undefined) {
            return this.cache[key][obj]
        }
        return undefined
    }
    put_in_cache({key, id, value}) {
        let obj = this.get_hash({key, id})
        if (this.cache[key] === undefined) {
            this.cache[key] = {}
        }
        this.cache[key][obj] = value
    }
    preloader() {

    }
}