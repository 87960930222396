// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import self_request from "assets/graphql/Users/self.graphql.json"
import UserModelController from "controllers/ModelControllers/UserModelController.jsx"
import PersonModelController from "controllers/ModelControllers/PersonModelController.jsx"

export default class DriverController extends _viewController {
    constructor(props) {
        super(props)
        this.person = new PersonModelController({
            params: {
                parent: this,
                controller: this,
                is_view: true,
                index: null
            }
        })
        this.user = new UserModelController({
            params: {
                parent: this,
                controller: this,
                is_view: true
            }
        })


        this.requests = [{
            request: self_request,
            callback: { f: this.load_user, p: { name: "self" } }, endpoint: "users"
        },
        {
            callback: { f: this.process_status }, endpoint: "status",
            replace: { o: "status", r: 'status(types:"driver")' }
        }]
    }
    get company_id() {
        return this.view.state.company_id
    }
    process_status({caller, params, results}) {
        results?.data?.status?.map((obj) => {
            caller.view.state.status_options.push({value: obj?.value, label: obj?.label.Capitalize()})
        })
    }
    load_user({ caller, params, results }) {
        if (results?.errors === undefined && results?.data?.self !== undefined) {
            // caller.view.state.data.user = caller.getCopy(results.data.self)
            // caller.view.state.cache = caller.getCopy(caller.view.state.data.user)
            caller.view.setState({
                company_id: results.data.self.companies[0].id
            })
        }
    }
    follow_on_selection({event, obj, data}) {
        this.setState({key: "data", value: obj})
        this.view.handleScreen({ path_num: 0, screen_num: 0, data: obj })
    }
    processEventReaction(decoded) {
        this.setState({ key: "data", value: decoded.message.data })
        this.view.handleScreen({ path_num: 0, screen_num: 0, data: decoded.message.data })
    }
    get _data() {
        return this.getState({ key: "data" });
    }
    handleCloseScreen() {
        this.resetScreen()
    }
    handleChange() {
        
    }
}

