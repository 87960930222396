// GENERAL REACT MODULES
import React from "react";
import { Row, Col } from 'react-bootstrap';
import { BiEdit } from "react-icons/bi";

// PAGE SPECIFIC MODULES
import _baseViewComponent from "components/General/_baseViewComponent.jsx"

export default class DetailsView extends _baseViewComponent {
    constructor(props) {
        super(props)
        this.controller = props.params?.controller
        this.state.tabview = "settings"
        this.data = this.controller.getState({key: "details"})
    }
    get_selected_class({name}) {
        if (this.state.tabview === name) {
            return "selected_tab"
        }
        return "unselected_tab"
    }
    toggle_tab({name}) {
        this.setState({tabview: name})
    }
    render_tab_view() {
        if (this.state.tabview === "settings") {
            return this.render_settings_view()
        } else {
            return this.render_mapping_view()
        }
    }
    render_settings_view() {
        return (
            <Row className="g-0">
                <Col className="g-0">
                    <Row className="g-0 common_row view_title">
                        Account Configuration
                    </Row>
                    <Row className="g-0 common_row">
                        <Col sm={2} className="view_subtitle">
                            Company Name
                        </Col>
                        <Col className="view_subtitle">
                            {this.data?.name}
                        </Col>
                    </Row>
                    <Row className="g-0 common_row">
                        Auto sync
                    </Row>
                    <Row className="g-0 common_row">

                    </Row>
                    <Row className="g-0 common_row">
                        Bill and Invoice approval
                    </Row>
                    <Row className="g-0 common_row">

                    </Row>
                </Col>
            </Row>
        )
    }
    render_mapping_view() {
        return (
            <Row className="g-0">
                <Col className="g-0">
                    <Row className="g-0 common_row view_title">
                        Account Mappings
                    </Row>
                    <Row className="g-0 common_row">
                        Cota transactions in the categories on the left will be posted to the NAME accounts on the right.
                    </Row>
                    <Row className="g-0 common_row">
                        <Col>
                        </Col>
                        <Col sm={2} className="edit clickable">
                            <BiEdit /> Edit
                        </Col>
                    </Row>
                    <Row className="g-0 common_row cat_row">
                        <Col className="view_title blue cat_col">
                            Category
                        </Col>
                        <Col className="view_title blue cat_col">
                            Account
                        </Col>
                    </Row>
                    {this.render_transactions()}
                </Col>
            </Row>
        )
    }
    render_transactions() {
        console.log(this.data?.actioms)
        return this.data?.actions.map((action, index) => {
            return (
                <Row className="g-0 common_row transaction_row" key={"transactions_"+index}>
                    <Col>
                        {action?.cat}
                    </Col>
                    <Col>
                        {action?.acc}
                    </Col>
                </Row>
            )
        })
    }
    render() {
        return(
            <Col id={this.name}>
                <Row className="g-0">
                    <Col sm={2} className={"clickable "+this.get_selected_class({name: "settings"})}
                            onClick={this.toggle_tab.bind(this, {name: "settings"})}>
                        Settings
                    </Col>
                    <Col sm={2} className={"clickable "+this.get_selected_class({name: "mappping"})}
                            onClick={this.toggle_tab.bind(this, {name: "mappping"})}>
                        Mapping
                    </Col>
                    <Col className="row_seperator">
                    </Col>
                </Row>
                {this.render_tab_view()}
            </Col>
        )
    }
}