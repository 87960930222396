import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";

import _baseModal from "components/General/_baseModal.jsx";
import ModalController from "./NewPerson.modal.controller.jsx"
import config_data from "./NewPerson.modal.json"
import "./NewPerson.modal.css"


export default class NewPersonModal extends _baseModal {
    constructor(props) {
        super(props)
        this.modal_controller   = new ModalController ({params: {view: this, controller: this.controller}})
        this.state.form         = JSON.parse(JSON.stringify(config_data.form_objects))
        this.state.modal_params   = {
          positioned: "middle_of_panel",   
          title: "New Contact Person",                
          title_size: "",                     
          closeable: true,                    
          has_overlay: true,                 
          close_on_outside_click: true,       
          relative_position: "",
          relative_alignment: "",
          x_adjustment: "0px",                
          y_adjustment: "0px",
        }
        this.state.dims         = {width: 636, height: 980}
        this.state.key          = false
        this.state.index        = this.controller.personModels.length
        this.state.data = {
          person: {}
        }
    }
    renderView() {
        return (
              <>
                <Row>
                  <Col>
                    <Row className="info">
                      <Col>
                        FIRST NAME*
                      </Col>
                    </Row>
                    <Row >
                      <Col>
                        {this.controller.view?.formObject({data: this.state.form.first_name, owner: this, index: 0, controller: this.modal_controller})}
                      </Col>
                    </Row>
                    <Row className="info">
                      <Col>
                        LAST NAME*
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {this.controller.view?.formObject({data: this.state.form.last_name, owner: this, index: 0, controller: this.modal_controller})}
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row className="info" >
                      <Col>
                        EMAIL*
                      </Col>
                    </Row>
                    <Row >
                      <Col>
                        {this.controller.view?.formObject({data: this.state.form.email, owner: this, index: 0, controller: this.modal_controller})}
                      </Col>
                    </Row>
                    <Row className="info">
                      <Col>
                        PHONE*
                      </Col>
                    </Row>
                    <Row >
                      <Col>
                        {this.controller.view?.formObject({data: this.state.form.phone, owner: this, index: 0, controller: this.modal_controller})}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                      <hr className="hr"/>
                  </Col>
                </Row>
                <Row className="pad_no_wrap">
                  <Col></Col>
                  <Col>
                        <Button className={"clear_button " + this._theme} onClick={this.toggle_modal?.bind(this.state.parent, {toggle_state: false})}>
                          Cancel
                        </Button>

                  </Col>
                  <Col>
                        <Button className={"primary_button_coloring " + this._theme} onClick={this.modal_controller.action_new_person.bind(this)}>
                          Save
                        </Button>
                  </Col>
                </Row>
              </>
        )
    }
}