// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class DeleteFileController extends _viewController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    get file() {
        return this.view?.props?.params?.file
    }
    get is_private() {
        return this.view.state.is_private ?? true
    }
    delete_file() {
        if (this.file?.id !== undefined) {
            let caller  = (this.view?.state?.parent?.modal_controller !== undefined) ? this.view?.state?.parent?.modal_controller : this.view?.state?.parent?.controller
            caller      = (caller !== undefined) ? caller : this
            this.api.upload.file_delete({caller: caller, id: this.file?.id, callback: {f: caller.process_deleted_file}})
        }
        let callback = this.view.state?.callback
        let fn = this.view.state?.parent?.[callback]?.bind(this.view.state?.parent);
        if(fn !== undefined) {
            if(typeof fn === "function") {
                fn()
            }
        }
    }
    process_deleted_file({caller, results, params}) {
        // console.log("FIlE DELETED")
        if(typeof caller?.upload_callback === "function") {
            caller?.upload_callback({caller: caller, results: results, params: params})
        }
        if(typeof caller.view.controller?.upload_callback === "function") {
            caller.view.controller?.upload_callback({caller: caller, results: results, params: params})
        }
    }
}