// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "./_ModelController.jsx";

export default class InvoiceModelController extends _ModelController {
    constructor(props) {
        super(props)
        this._state = undefined
    }
    get load_id() {
        if(this.data?.load_id === undefined) {
            return this.controller?.load?._id
        }
        return this.data?.load_id
    }
    get id() {
        return this.data?.id
    }
    get web_resource() {
        return this.data?.web_resource
    }
    get generated_date() {
        return this.data?.generated_date
    }
    get sent_at() {
        return this.data?.sent_at
    }
    get sent() {
        if(this.data?.sent === undefined) {
            return false
        }
        return this.data?.sent
    }
    set sent(value) {
        this.data.sent = value
    }
    generate_sent_date() {
        // IF sent_at needs to have time finish this function
    }
    generate_invoice_date() {
        let invoice_date = new Date()
        let offset = invoice_date.getTimezoneOffset()
        invoice_date = new Date(invoice_date.getTime() - (offset * 60 * 1000))
        return invoice_date.toISOString().split('T')[0]
    }
    get summary() {
        let invoice = {
            generated_date: this.generate_invoice_date(),
            sent: this.sent,
            load_id: this.load_id,
            sent_at: this.sent_at
        }
        if(invoice.sent) {
            invoice.sent_at = this.generate_invoice_date()
        }
       // console.log("SUMMARY",summary)
        invoice.scrub()
        return invoice
    }



    
}
