// GENERAL REACT MODULES
import React from "react";
import { Button } from 'react-bootstrap'

// PAGE SPECIFIC MODULES
import Controller from "./Background.controller.jsx"
import  "./Background.css"

// ALL OTHER MODULES
import BackgroundImage from "img/cotabackground.jpg"
import _baseViewComponent from "components/General/_baseViewComponent.jsx";

class Background extends _baseViewComponent {
    constructor(props) {
        super(props)
        this.controller = new Controller({params: {view: this}})
        this.setState({supportLogout: props?.supportLogout})
    }
    renderView() {
        return (
            <div className="login-pf-page" id={this.name}>
                <img className="demo-bg" src={BackgroundImage} alt="" />
                <div className="card-pf">
                    <div className="bannerImage">

                    </div>
                    {this.controller.check_failed()}
                    {
                        this.controller.renderLogout()
                    }
                </div>
            </div>
        )
    }
}
export default Background;
