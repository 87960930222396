//REACT SPECIFC 
import React from "react";
import { Row, Col } from "react-bootstrap";
import { BsCalendarCheck, BsCheck2 } from "react-icons/bs"

//MODULE SPECIFIC
import Controller from "./RoleObject.partial.controller"
import _basePartial from "components/General/_basePartial.jsx";
import Modal from "../../NewLoadModal/NewContactLoad.modal.jsx"
import "./RoleObjectPartial.css"
import RoleObject from "components/NewLoadPartials/ShipperObject/Shipper.partial.jsx"
import PayorObject from "components/NewLoadPartials/PayorPartial/Payor.partial.jsx"

export default class RoleObjectPartial extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller       = new Controller({params: {view: this, 
                                                        controller: this.controller}})
        this._modal                   = Modal 
        this.state.iconColorAppt      = "black"
        this.state.apptRequired       = "#F0F1F7"
        this.state.iconColorDelivery  = "black"
        this.state.deliveryColor      = "#F0F1F7"
    }
    renderRoleTag() {
        return (
            <Row className="d-flex justify-content-end g-0">
                <Col className={"neg_12_margin role_tag "+this.state.role.toLowerCase()}>
                    {this.state.role}
                </Col>
            </Row>
        )
    }
    renderDeliveryCheck() {
        if(this.state.role.toLowerCase() !== "consignee") {return <></>}
        return (
            <Col>
                {this.formObject({ data: this.state.form?.delivery_check, disabled: this.partial_controller.check_disabled() })}
            </Col>
        )
    }
    renderPersonInput() {
        if(this.state.role.toLowerCase() !== "payor") {return <></>}
        return (
            <Row className="generic_form_label padding">
                <Col>
                    <Row>
                        <Col>
                            {this.state.role} contact person
                        </Col>
                    </Row>
                    <Row>
                        <Col >
                            {this.formObject({ data: this.state?.form?.contact_person })}   
                        </Col>
                    </Row>
                </Col>
                <Col sm={3}></Col>
            </Row>
        )
    }
    renderRoleForm() {
        if (this.state.role.toLowerCase() === "payor") {return <></>} 
        return (<>
            <Row className="generic_form_label padding no_wrap">
                <Col>
                    <Row>
                        <Col>
                            {(this.state.role === "shipper") ? "pickup" : "dropoff"} date
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    {this.formObject({ data: this.state.form.delivery_date, disabled: this.partial_controller.check_disabled() })} 
                                </Col>
                            </Row> 
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            {(this.state.role === "shipper") ? "pickup" : "dropoff"} time
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.formObject({ data: this.state.form.start_time, disabled: this.partial_controller.check_disabled() })} 
                        </Col>
                        <Col xs="auto" className="flex_centered"> - </Col>
                        <Col>
                            {this.formObject({ data: this.state.form.end_time, disabled: this.partial_controller.check_disabled() })} 
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="generic_form_label padding">
                <Col>
                    <Row>
                        <Col>
                            {(this.state.role === "shipper") ? "pickup" : "dropoff"} # 
                        </Col>
                    </Row>
                    <Row>
                        <Col key={this.partial_controller.check_disabled()}>
                            {this.formObject({ data: this.state.form.pickup_number, disabled: this.partial_controller.check_disabled() })}
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col className="flex_col">
                            {this.formObject({ data: this.state.form.appointment_required, disabled: this.partial_controller.check_disabled() })}
                        </Col>
                    </Row>
                    <Row>
                        {this.renderDeliveryCheck()}
                    </Row>
                </Col>
            </Row>
        </>)
    }
    renderRoleComponent() {
        if(this.state.role.toLowerCase() === "payor") {
            return <PayorObject params={{parent: this, controller: this.controller,
                                         borderless: true, half_width: true, 
                                         closeable: true, sandwich: true}} />
        }
        return <RoleObject params={{parent: this, controller: this.controller, 
                                    type: this.partial_controller.type, editable: true,
                                    borderless: true, title: false,
                                    padding: false, modal: true, no_edit: true}} />
    }
    renderView() {
        this.partial_controller.check_disabled()
        return (<>
            <Row id={this.name}>
                <Col>
                    <Row>
                        <Col className="title_row">
                            <Row className="title_row_hr g-0">
                                <hr/>
                            </Row>
                            <Row className="title_row_badge">
                                <Col>
                                    {this.renderRoleTag()}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="generic_form_label">
                        <Col>
                            <Row>
                                <Col>
                                    {this.state.role}
                                </Col>
                            </Row> 
                            {this.renderRoleComponent()}
                        </Col>
                    </Row>
                    {this.renderPersonInput()}
                    {this.renderRoleForm()}
                </Col>
            </Row>
            <Row>
                &nbsp;
            </Row>
        </>)
    }
}
