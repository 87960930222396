// GENERAL REACT MODULES
// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class RoleButtonRowController extends _viewController {
    constructor(props) {
        super(props)
    }
    handleButtonClick(event) {
        let text = event.target.textContent.toLowerCase()
        this.setState(prevState => ({
            [text]: prevState?.[text] === "" ? text : ""
        }))
        // this.controller.toggleCredit(text)
    }
    toggleCredit(text) {
        if(text !== "payor") {
            return
        }
        // console.log(this.view.state.controller)
        // this.view.parent.setState(prevState => ({credit_fields: !prevState.credit_fields}))
    }
}