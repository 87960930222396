//GENERAL REACT MODULES 
import React from "react";
import { Form, Row, Col, Card, Button, Container } from 'react-bootstrap'

//PAGE SPECIFIC MODULES
import Controller from "./BlockDriver.partial.controller.jsx"
import _basePartial from "components/General/_basePartial.jsx";
import Modal from "./BlockDriverModal/BlockDriver.modal.view.jsx"
import "./BlockDriverPartial.css"

export default class BlockDriverPartial extends _basePartial {
    constructor(props) {
        super(props)
        this._modal                 = Modal
        this.partial_controller     = new Controller ({params: { view: this }}) 
    }
    renderView() {
        return (
            <Row id={this.name} className="padding">
                <Col xs="auto">
                    <Row>
                        <Col>
                            <Button className={"primary_button_coloring "+this._theme} onClick={() => this.toggle_modal({toggle_state: true})} >
                                Block Driver
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col xs="auto"></Col>
                <Col>
                    <Row className="info">
                        <Col>
                            Driver won't be able to see any data of your company.
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}