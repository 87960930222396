// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES

import schema from "./schema.json"
import test_data from "./test.json"

// ALL OTHER MODULES
import _baseApiObject from "components/General/_baseApiObject"
import email_request from "assets/graphql/Email/Email.graphql.json"

export default class Email extends _baseApiObject {
    constructor(props) {
        super(props)
        this.schema     = schema
        this.test_data  = test_data
        this.requests   = {
            general: email_request
        }
    }
}
