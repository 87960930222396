// GENERAL REACT MODULES
import { Row, Col } from 'react-bootstrap'
// ALL OTHER MODULES
import MultiPanel from "components/MultiPanels/MultiPanel"
import _basePanelView from "components/General/_basePanelView.jsx";
import "./ViewDriver.css"
import Controller from "./ViewDriver.controller.jsx"
import DriverDetails from './Partials/DriverDetails/DriverDetails.partial.jsx';
import NotesPanel from './Partials/NotesPanel/NotesPanel.partial';
import DriverTripsPanel from './DriverTrips/DriverTrips.view';
import CotaUserBar from "components/CotaUserBar/CotaUserBar.partial.jsx"
import DriverLinkPartial from './Partials/DriverLink/DriverLink.partial';
import BlockDriverButton from './BlockDriver/BlockDriverButton.partial.jsx';
import DeleteDriverButton from '../DeleteDriver/DeleteDriverButton.partial.jsx';

export default class ViewDriverPanel extends MultiPanel {
    constructor(props) {
        super(props)
        this.custom_canvas              = true
        this.state.screen               = 0
        this.state.change               = false
        this.controller                 = new Controller({params: {view: this}})
        this.handleProcessEvent         = this.handleProcessEvent.bind(this)
        this.state.data.asset           = {}
        this.state.data.driver = {
            trips: [],
            notes: []
        }
    }
    render_block_driver(){
        if(this.controller.editable) {
            return <BlockDriverButton params={{parent: this, controller: this.controller}}/>
        }
    }

    render_delete_driver(){
        if(this.controller.editable) {
            return <DeleteDriverButton params={{parent: this, controller: this.controller}}/>
        }
    }

    renderInnerFrame() {
        // console.log(this)
        return (
            <Row id={this.name}>
                <Col>
                    <Row>
                        <Col>
                            <CotaUserBar params={{ parent: this, controller: this.controller, title: "drivers", return: true}}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <DriverDetails params={{parent: this, controller: this.controller}}/>
                                </Col>
                            </Row>
                            <Row>
                                &nbsp;
                            </Row>
                            <Row>
                                <Col>
                                    <DriverTripsPanel params={{parent: this, controller: this.controller}} />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="auto"/>
                        <Col sm={3}>
                            <Row>
                                <Col>
                                    <DriverLinkPartial params={{parent: this, controller: this.controller}} />
                                </Col>
                            </Row>
                            <Row>
                                &nbsp;
                            </Row>
                            <Row>
                                <Col className='g-0'>
                                    {this.render_block_driver()}
                                </Col>
                            </Row>
                            <Row>
                                &nbsp;
                            </Row>
                            <Row>
                                <Col className='g-0'>
                                    {this.render_delete_driver()}
                                </Col>
                            </Row>
                            {/* <Row >
                                <Col>
                                    <NotesPanel params={{parent: this, controller: this.controller}} />
                                </Col>
                            </Row> */}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

