import _viewController from "controllers/ViewController/_viewController.jsx"
import self_request from "assets/graphql/Users/self.graphql.json"
import truck_number_request from "controllers/ApiController/Endpoints/Assets/Assets.search.graphql.json"

export default class assetsController extends _viewController {
    constructor (props) {
        super(props)
        this.requests   = [{request: self_request,
                           callback: {f: this.load_user, p: {name: "self"}}, endpoint: "users"},
                           {callback: {f: this.process_status}, endpoint: "status",
                                    replace: {o: "status", r: 'status(types:"asset")'}}]
    }
    load_user({caller, params, results}) {
        if(results?.errors === undefined && results?.data?.self !== undefined) {
            caller.view.setState({
                company_id: results.data.self.companies[0].id
            })
        }
    }
    get company_id() {
        return this.view.state?.company_id
    }
    async get_truck_number(truck_number) {
        truck_number    = (truck_number === undefined)
        let body        = JSON.parse(JSON.stringify(truck_number_request))
        let results     = await this.api.assets.ask({caller: this, params: {body: body}})
        results?.data?.assets?.map((cnt, index) => {
            this.view.state.user_options.push({value: cnt.truck_number, label: cnt.truck_number?.Capitalized() })
        })
    }
    process_status({caller, params, results}) {
        results?.data?.status?.map((obj) => {
            caller.view.state.status_options.push({value: obj?.value, label: obj?.label.Capitalize()})
        })
    }
    processEventReaction(decoded) {
        this.setState({key: "data", value: decoded.message.data})
        this.view.handleScreen({path_num: 0, screen_num: 0, data:decoded.message.data})
    }
    get data() {
        return this.getState({key: "data"});
    }
    handleCloseScreen() {
        // this.resetScreen()
    }
    follow_on_selection({event, obj, data}) {
        this.view.empty_results()
        this.setState({key: "data", value: obj})
        this.view.handleScreen({path_num: 0, screen_num: 0, data: obj})
    }
    // get user_filter() {
    //     if (this.view?.state?.alpha !== undefined) {
    //         return this.view.state.alpha
    //     }
    //     return ""
    // }
}