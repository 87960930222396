//GENERAL REACT MODULES
import React from "react"
import { Row, Col } from "react-bootstrap"

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./NotesPanel.partial.controller.jsx"
import configs from "./NotesPanel.json"
import "./NotesPanel.css"
import NoteItem from "./NoteItem/NoteItem.partial.jsx"

export default class DriverNotesPanel extends _basePartial {
    constructor(props){
        super(props)
        this.partial_controller = new Controller({params: {view: this, controller: this.controller}})
        this.state.data.note    = [{note_text:"", viewable:null}]
        this.state.form         = configs.form_objects
        this.state.index        = this.controller.notes.length
    }
    renderView() {
        // console.log(this)
        return (
            <Row id={this.name}>
                <div className="cota_panel_border add_shadow g-0">
                    <Row className="g-0">
                        <Col>
                            <Row className="header_main g-0">
                                <Col>
                                    Notes
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="g-0 row_padding">
                        <Col>
                            <Row className="note_header g-0">
                                <Col>
                                    notes
                                </Col>
                            </Row>
                            <Row key={"note_input"+this.state.index} className="pad_top g-0">
                                <Col>
                                    {this.controller.view.formObject({data: this.state.form.text, index: 0, owner: this, controller: this.partial_controller})}
                                </Col>
                            </Row>
                            <Row className="g-0">
                                &nbsp;
                            </Row>
                            <Row className="g-0">
                                <Col />
                                <Col sm={4} 
                                    type="button" className={"clear_button add_btn "+this._theme}
                                    onClick={() => this.partial_controller.add_note()}>
                                    Add Note
                                </Col>
                            </Row>
                            <Row className="g-0" >
                                <Col>
                                    {this.controller?.notes?.map((note, index) => {
                                        return <NoteItem key={"note_"+index+note.note_text} 
                                                params={{parent: this.parent, controller: this.partial_controller, index: index, note: note}}/>
                                    })}
                                </Col>
                            </Row>
                            <Row className="g-0">
                                &nbsp;
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Row>
        )
    }
}