// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import test_data from "./test.json"
import schema from "./schema.json"
import graph_request from "./Address.search.graphql.json"

// ALL OTHER MODULES
import _baseApiObject from "components/General/_baseApiObject";

export default class Address extends _baseApiObject {
    constructor(props) {
        super(props)
        this.schema     = schema
        this.test_data  = test_data
    }
    async search({caller, key, value, callback, params}) {
        let results = value
        if (value.length >= 2) {
            key = (key !== undefined) ? key : "address"
            let body = JSON.parse(JSON.stringify(graph_request))
            body.query = body.query.replace("addresses", 'addresses('+params?.data?.label+': "'+value+'")')
            params = (params === undefined) ? {} : params
            params.body = body
            results = await this.ask({caller: caller, callback: callback, params: params})
        }
        // if (callback !== undefined) {
        //     callback({caller: caller, params: params, results: results})
        // }
        return results
    }
}
