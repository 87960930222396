import _viewController from "controllers/ViewController/_viewController.jsx"

export default class TripBuilderPartialController extends _viewController {
    constructor(props) {
        super(props)
    }
    get disabled_dispatch() {
        // Jeremy wants dispatch to work with at least a load and a truck.
        // So the check will only check if sel_loads is greater than 0, and sel_trucks is greater than 0.
        if ((this.controller.trip.loads.length > 0) && (this.controller.trip.trucks.length > 0)) { 
            return false
        }
        if ((this.controller.trip.loads.length > 0) && (this.controller.trip.drivers.length > 0)) { 
            if(this.notnull(this.controller.trip.drivers[0]?.asset?.id)) {
                return false
            }
        }
        if ((this.controller.trip.loads.length > 0) && (this.controller.trip.trailers.length > 0)) { 
            if(this.notnull(this.controller.trip.trailers[0]?.linked_asset?.id)) {
                return false
            }
        }
        return true
    }
    notnull(obj) {
        if (obj !== undefined && obj !== null) {
            return true
        }
        return false
    }
    // NEED TO MAKE THIS WORK ON EDIT SO IT REFILLS THE SELECTIONS
    reset() {
        this.reset_seletions("loads")
        this.reset_seletions("drivers")
        this.reset_seletions("trucks")
        this.reset_seletions("trailers")
        this.view.parent.forceUpdate()
    }
    reset_seletions(option) {
        if(this.controller.trip[option].length > 0) {
            this.controller.trip[option] = []
        }
    }
    // reset_asset_type(option) {
    //     if(this.controller.trip[option].length <= 0) { return }
    //     let data = JSON.parse(JSON.stringify(this.controller.trip[option][0]))
    //     let drag_index = data.drag_index
    //     delete data.drag_index
    //     this.view.parent.state[option][drag_index] = data
    //     this.controller.trip[option] = []
    // }
}