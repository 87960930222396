import React from 'react'
import { MdOutlinePieChart, MdOutlinePeople } from "react-icons/md";
import { BiMapAlt } from "react-icons/bi";
import { RiDirectionLine, RiTruckLine } from "react-icons/ri";
import { HiCurrencyDollar } from "react-icons/hi";
import { TiContacts } from "react-icons/ti";
import { FaTruck } from "react-icons/fa";

import Trips from "module/pages/Trips/Trips.view.jsx";
import Driver from 'module/pages/Driver/Driver.view';
import Contacts from 'module/pages/Contacts/Contacts.view';
import Assets from 'module/pages/Assets/Assets.view.jsx';
import Loads from "module/pages/Loads/Loads.view"
import Map from 'module/pages/Map/map.view';
import LoadBoost from "module/pages/LoadBoost/LoadBoost.view.jsx"
import { BsArrowReturnRight } from "react-icons/bs";

const mContext = {
                menus: [[{name: "Dashboard", icon: <MdOutlinePieChart className="menuListIcon"/>, internal: true, link: "/", 
                                    requirements: {roles: ["INTERNAL_COTA_USER"]}, display: false}]
                    [{name: "Map", icon: <BiMapAlt className="menuListIcon"/>, internal: true, link: "/map",
                    requirements: {roles: ["INTERNAL_COTA_USER"]}}],
                    [{name: "LoadBoost", icon: <FaTruck className="menuListIcon" />, internal: true, link: "/loadboost"}],
                    [{name: "Trips", icon: <RiDirectionLine className="menuListIcon"/>, internal: true, link: "/trips"}],
                    [{name: "Loads", icon: <HiCurrencyDollar className="menuListIcon"/>, internal: true, link: "/loads", binding: "loads_expanded"},
                        {name: "Dispatched", icon: <BsArrowReturnRight className="menuListIcon"/>, internal: true, bound: "loads_expanded", link: "/loads/dispatched"},
                        {name: "Available", icon: <BsArrowReturnRight className="menuListIcon"/>, internal: true, bound: "loads_expanded", link: "/loads/available"},
                        {name: "Delivered", icon: <BsArrowReturnRight className="menuListIcon"/>, internal: true, bound: "loads_expanded", link: "/loads/delivered"}],
                    [{name: "Drivers", icon: <MdOutlinePeople className="menuListIcon"/>, internal: true, link: "/drivers"}],
                    [{name: "Contacts", icon: <TiContacts className="menuListIcon"/>, internal: true, link: "/contacts"}],
                    [{name: "Assets", icon: <RiTruckLine className="menuListIcon"/>, internal: true, link: "/assets"}]],
                pages: {"/": {link: "/", page: <LoadBoost />},
                        "/map": {link: "/map", page: <Map />},
                        "/loadboost": {link: "/loadboost", page: <LoadBoost />},
                        "/trips": {link: "/trips", page: <Trips />},
                        "/drivers": {link: "/drivers", page: <Driver />},
                        "/contacts": {link: "/contacts", page: <Contacts />},
                        "/assets": {link: "/assets", page: <Assets />},
                        "/loads": {link: "/loads", page: <Loads />}},  

                default: "/loadboost"              
}

mContext.getMenu = function() {
    return mContext.menus
}

mContext.getPages = function() {
    return mContext.pages
}
mContext.getPage = function({link, params}) {
    return mContext.pages[link].page
}
const ModuleContext = React.createContext(mContext)

export const ModuleProvider = ModuleContext.Provider
export const ModuleConsumer = ModuleContext.Consumer

export default ModuleContext