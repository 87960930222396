// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class AlphaBarController extends _viewController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    select_letter(index) {
        if (this.state.selected === index) { 
            this.setState({selected: -1})
            this.controller.setState({key: "alpha", value: ""})
        } else {
            this.setState({selected: index})
            this.controller.setState({key: "alpha", value: this.alphabet?.[index]})
        }
    }
    hover_letter(index) {
        this.setState({hover: index})
    }
    unhover_letter(index) {
        this.setState({hover: -1})
    }
}
