import _viewController from "controllers/ViewController/_viewController.jsx"

export default class CompanyCardController extends _viewController {
    constructor (props) {
        super(props)
    }
    // get role_object() {
    //     if(Array.isArray(this.controller.view.state.data[this.view.type])) {
    //         return this.controller.view.state.data[this.view.type]?.[this.view.index]
    //     }
    //     return this.controller.view.state.data[this.view.type]
    // }
    get type() {
        return (this.view.state.role === "shipper") ? "pickup" : this.view.state.role 
    }
    delete_stop() {
        let objs = this.controller.getState({key: "data", param: this.state?.type})
        let index = this.props?.params?.index
        objs[index] = {}
        // objs.splice(this.props?.params?.index, 1)
        this.controller.setState({key: "data", param: this.state?.type, value: objs})
        this.controller.update()
    }
}