import _viewController from "controllers/ViewController/_viewController.jsx"
import AssetModelController from "controllers/ModelControllers/AssetModelController.jsx"
import mutation_request from  "assets/graphql/Assets/Assets.mutation.graphql.json"
import { Button, Row, Col, Card} from "react-bootstrap";

export default class EditTruckController extends _viewController {
    constructor (props) {
        super(props)
        this.asset = new AssetModelController({params: {parent: this,
                                                            controller: this,
                                                            is_view: true,
                                                            view: this.view}})
        // this.asset.status = "2de9b983-b216-495e-b3b4-849e1e605165"
        // this.requests   = [{callback: {f: this.process_status}, endpoint: "status"}]
        this.requests   = [{callback: {f: this.process_status}, endpoint: "status"},
                            {callback: {f: this.state_results, p: {name: "areas", var: ["areas", "states"]}}, 
                                endpoint: "areas", replace: {o: "params:", r:'params: {country: "US"}'}}]
    }
    state_results({caller, params, results}) {
        if (results?.errors === undefined && results?.data?.areas !== undefined) {
            results.data.areas.map((area, index) => {
                caller.view.state.states.push({ value: area.state, label: area.state })
            })
        }
    }
    handleChange() {
        // console.log("change")
    }

    load_harness() {
        // this.saveAction()
    }
    process_status({caller, params, results}) {
        let status_options = [results.data?.status[10], results.data?.status[12], results.data?.status[9], results.data?.status[0], results.data?.status[8], results.data?.status[4]]
        status_options.map((obj, index) => {
            caller.view.state.status_options.push({value: obj?.value, label: obj?.label, id: obj?.id})
        })
    }
    handleClick = () => {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.onchange = (event) => {
            const file = event.target.files[0]
            const reader = new FileReader()
            reader.onload = () => {
                const imageDataUrl = reader.result
                const imageElement = document.createElement("img")
                imageElement.src = imageDataUrl
                imageElement.className = "selected_image"
                const imageBlock = document.querySelector(".image_block")
                imageBlock.innerHTML = ""
                imageBlock.appendChild(imageElement)
                this.props?.onFileChange(file)
            };
        reader.readAsDataURL(file);
        };
        fileInput.click();
    }

    async saveAction(page_key) {
        if (this.controller?.asset?.truck_number === undefined || this.controller?.asset?.truck_number === null) {
            alert("Please fill in the Truck Number field before saving")
        } else {
            // this.controller.asset.trailer_truck = true
            // this.controller.asset.status_toggle = true

            this.controller.asset.asset_type = "TRUCK"
            // this.controller.asset.status = this.state.status_options[0]
            this.controller.asset.company_id = this.state.context.self.companies[0]?.id
            let summary = this.controller?.asset?.summary
            // summary.company_id = this.state.context.self.companies[0].id
            let data = this.controller.toUnquotedJSON(summary)
            let body = JSON.parse(JSON.stringify(mutation_request))
            body.query = body.query.replace("input:", "input: "+data)
            if (this.controller?.asset?._sum < 0) { return }
            let response = await this.api.assets.create({caller: this, params: {body:body}, callback: {f: this.controller.process_save, p: {page_key: page_key}}})
        }
    }
    async process_save ({caller, params, results }) {
        caller.controller.handleCloseScreen()
    }
    get _data(){
        return this.getState({key: "data"})
    }
    handleCloseScreen() {
        this.view.state.panel_params.controller.view.resetScreen()
    }
    save_button_row() {
    if (this.view.state.edit_mode) {
        return (
            <>
            <Row>
                <Col>
                    <hr/>
                </Col>
            </Row>
            <Col>&nbsp;</Col>
                <Col sm={3} className="button_col">
                    <Button className={"completeButton primary_button_coloring "+this._theme}
                        onClick={this.close.bind(this, {save: false})} 
                        variant="secondary" 
                        size="sm" 
                        tabIndex={this.view.tab("cancel")}>
                            Cancel
                    </Button>
                </Col>
                <Col sm={3} className="button_col">
                    <Button className={"completeButton primary_button_coloring "+this._theme}
                        onClick={() => {
                            this.saveAction({ save: true });
                            this.close({ save: false });
                            }}
                            
                        variant="primary" 
                        size="sm">
                            Save
                    </Button>
                </Col>
                <Col xs="auto"></Col>
                <Row>
                    &nbsp;
                </Row>
            </>
        )
    }
    return ""
    }
}
