// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button } from 'react-bootstrap'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { FaUser, FaTrailer } from 'react-icons/fa'
import { BsTruck } from 'react-icons/bs'
import { AiFillDollarCircle } from "react-icons/ai"

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./TripBuilder.partial.controller.jsx"
import "./TripBuilder.partial.css"
import TripOption from "../TripOptionObject/TripOption.partial.jsx"
import config_data from "./TripBuilder.json"

export default class TripBuilderPartial extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this, controller: this?.controller}})
        this.state.form = config_data.form_objects
    }
    get parent() {
        return this.state.parent
    }
    data_key(option) {
        switch (option) {
            case "loads":
                return "shipment"
            case "drivers":
                return "person"
            case "trucks":
                return "truck"
            case "trailers": 
                return "trailer"       
            default:
                return "shipment"
        }
    }
    get_dropable_height() {
        if (this.controller.trip.loads.length > 0) {
            return""
        }
        return "dropable_height"
    }
    get_dispatched_css() {
        if (this.partial_controller.disabled_dispatch) {
            return "disabled_button"
        }
        return ""
    }
    getStyle(style, snapshot) {
        if (!snapshot?.isDragging) return {}
        if (!snapshot?.isDropAnimating) {
            return style
        }
        return {
            ...style,
            // cannot be 0, but make it super tiny
            transitionDuration: `0.001s`
        }
    }
    render_icon(icon) {
        switch (icon) {
            case "Driver":
                return  <FaUser className="avatar_spacing" size={30} /> 
            case "Truck":
                return <BsTruck className="avatar_spacing" size={30} /> 
            case "Trailer":
                return <FaTrailer className="avatar_spacing" size={30} />     
            default:
                return <></>
        }
    }
    format_option(option) {
        option = (option.charAt(0).toUpperCase() + option.slice(1)).substring(0, option.length-1)
        return option
    }
    default_view(option, provided) {
        return (
            <Row style={this.getStyle(provided?.draggableProps?.style, provided)}
                className="draggable_border">
                <Col className="mini_plug border_add dashed_border">
                    {this.determine_render(option)}
                </Col>
            </Row>
        )
    }
    render_drop_zone(option) {
        return (<>
            <Row>
                <Col>
                    <Row>
                        <span className="avatar_position relative">
                            {this.render_icon(this.format_option(option))}
                        </span>
                    </Row>
                    <div className="add_title g-0">
                        + Add {this.format_option(option)}
                    </div>
                </Col>
            </Row>
        </>)
    }
    render_selection(option) {
        return this.controller.trip[option]?.map((obj, index) => {
            return (
                <Row>
                    <TripOption params={{formatting: true, parent: this, controller: this.controller, data: {[this.data_key(option)]: obj}, index: index, option: option, data_key: this.data_key(option), remove: true}} />
                </Row>
            )
        })
    }
    determine_render(option) {
        if(this.controller.trip[option]?.length > 0) {
            return this.render_selection(option)
        }
        return this.render_drop_zone(option)
    }
    render_assigned_loads() {
        if (this.controller.trip.loads.length > 0) {
            return this.controller.trip.loads.map((load_detail, index) => {
                return (
                    <Row className="load_row">
                        <TripOption params={{parent: this, controller: this.controller, data: {shipment: load_detail}, index: index, option: "loads", data_key: "shipment", remove: true}} />
                    </Row>
                )
            })
        }
    }
    render_reset() {
        if(this.partial_controller.notnull(this.controller.view?.state?.trip_id)) { return <></> }
        return (
            <Button className="small_button">
                <p className="button_text reset" onClick={() => this.partial_controller.reset()}>
                    Reset
                </p>
            </Button>
        )
    }
    renderView() {
        // console.log(this)
        return (
            <Row id={this.name}>
                <Col className="cota_panel_border add_shadow colored_panel" >
                    <Row className="heading_2" >
                        <Col className="title" >
                            New Trip
                        </Col>
                        <Col className="autocomplete_col">
                            {this.parent.formObject({data: this.state.form.autocomplete, owner: this.parent, controller: this.controller})}     
                        </Col>
                        <Col xs={2}>
                            {this.render_reset()}
                        </Col>
                        <Col xs="auto">
                            <Button onClick={() => this.controller.dispatch_trip()} className={"btn_col "+this.get_dispatched_css()+" "+this._theme} disabled={this.partial_controller.disabled_dispatch}>
                                Dispatch
                            </Button>
                        </Col>
                    </Row>
                    <Droppable key={"assigned_details"} direction="horizontal" droppableId={"assigned_details"}>
                        {(provided) => (
                            <Row ref={provided.innerRef} {...provided.droppableProps} {...provided.dragHandleProps} className="assigned_details relative">
                                <Col>
                                    <Row className="plug_row">
                                        <Col>
                                            {this.default_view("drivers")}
                                        </Col>
                                        <Col>
                                            {this.default_view("trucks")}
                                        </Col>
                                        <Col>
                                            {this.default_view("trailers")}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </Droppable>
                    <Row>
                        <Droppable droppableId="assigned_loads" className="dropable_position">
                        {(provided) => (
                            <Col className="many_loads relative dashed_border" ref={provided.innerRef} {...provided.droppableProps} {...provided.dragHandleProps} style={this.getStyle(provided.draggableProps?.style, provided)}>
                                <Row className={this.get_dropable_height()}>
                                    <Col className="center">
                                        <AiFillDollarCircle className="green" size={50} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="center">
                                        + Add one or many loads
                                    </Col>
                                </Row>
                                {/* {provided.placeholder} */}
                            </Col>
                        )}
                        </Droppable>
                    </Row>
                    <Row>
                        <Col>
                            {this.render_assigned_loads()}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}
