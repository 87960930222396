// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
// import LineItemModelController from "controllers/ModelControllers/Quotes/LineItemModelController"
import ListLineController from "components/CotaListPanel/ListLineController.jsx"

export default class BadgeColumnController extends ListLineController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    get item_data() {
        return this.view.item_data
    }
    get _configs() {
        return this.view?.controller?.configs
    }
    get_data() {
        if (this.view.props.params.data === undefined || this.view.props.params.data === null) {
            if (this._configs?.columns?.default_badge !== undefined) {
                return this._configs?.columns?.default_badge
            }
            return "pending"
        }
        return this.view.props.params.data
    }
    get_badge_color() {
        if (this.view.state.color !== undefined) {
            return this.view.state.color+"_badge"
        }
        let design  = (this.view.state.design?.b?.filter(type => this.lc(type.v) === this.lc(this.get_data())))?.[0]
        if (design !== undefined) {
            return design?.c+"_badge"
        }
        return ""
    }

}