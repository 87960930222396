// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import StopModelController from "controllers/ModelControllers/StopModelController.jsx"

export default class DriverModalController extends _viewController {
    constructor(props) {
        super(props)
        this.pickup    = new StopModelController({params: {parent: this,
                                                        controller: this,
                                                        is_view: true,
                                                        _param: "pickup"}})
        this.consignee = new StopModelController({params: {parent: this,
                                                        controller: this,
                                                        is_view: true,
                                                        _param: "consignee"}})
        this.stops     = new StopModelController({params: {parent: this,
                                                        controller: this,
                                                        is_view: true,
                                                        _param: "stops"}})
    }
    handleCloseScreen() {
        this.resetScreen()
    }
    get role_object() {
        if(Array.isArray(this.controller.view.state.data[this.view.type])) {
            return this.controller.view.state.data[this.view.type]?.[this.view.index]
        }
        return this.controller.view.state.data[this.view.type]
    }
    save_action() {
        let object = this.role_object
        let data = JSON.parse(JSON.stringify(this[this.view.type].summary))
        data = {...object, ...data}
        if(this.view.type === "stops") {
            this.controller.view.state.stops[this.view.index] = data
        } else {
            this.controller.setState({key: "data", param: this.view.type, value: data})
        }
        this.controller.update()
        this.close()
    }
    cancel() {
        // delete state
        delete this.view.state.data?.[this.view.type]
        this.close()
    }
    close() {
        this.view.state.parent.toggle_modal({toggle_state: false})
    }
}