// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Container, Button, Card } from "react-bootstrap";
import { FaUser, FaTrailer } from 'react-icons/fa'
import { BsTruck, } from 'react-icons/bs'
// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx";
import BlockDriverModalController from "./BlockDriver.modal.controller";

import "./BlockDriverModal.css"

export default class BlockDriverModal extends _baseModal {
    constructor(props) {
        super(props);
        this.state.modal_params = {
            positioned: "middle_of_panel",   
            title: "Block Driver",                
            title_size: "",                     
            closeable: true,                    
            has_overlay: true,                 
            close_on_outside_click: true,       
            relative_position: "",
            relative_alignment: "",
            x_adjustment: "0px",                
            y_adjustment: "0px",
        }
        this.modal_controller   = new BlockDriverModalController({ params: { view: this } })
        // this.state.dims         = {width: 636, height: 980}         
    }

    renderView() {

        return (
            <>
                    <Row>
                        <Col className="modal_text">
                            Are you sure you want to block this driver?
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button className={"clear_button "+this._theme} onClick={this.toggle_modal?.bind(this.state.parent, {toggle_state: false})} >
                                Cancel
                            </Button>
                        </Col>
                        <Col></Col>
                        <Col>
                            <Button className={"primary_button_coloring "+this._theme} onClick={this.toggle_modal?.bind(this.state.parent, {toggle_state: false})} >
                                Block Driver
                            </Button>
                        </Col>
                    </Row>
            </>
        );
    }
}

