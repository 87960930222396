//GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FiDownload } from "react-icons/fi";

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx";

export default class InvoiceRateItem extends _basePartial {
    constructor(props) {
        super(props)
    }
    format_rate_type(rate_type) {
        let words = rate_type?.replaceAll("_", " ").split(" ")
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
        return words?.join(" ")
    }
    renderView() {
        console.log(this)
        return (
            <>
                <Col>
                    <Row className="info MuiInputLabel-shrink">
                        <Col>
                            Order
                        </Col>
                        <Col>
                            Description
                        </Col>
                        <Col>
                            QTY
                        </Col>
                        <Col>
                            Rate
                        </Col>
                        <Col>
                            Amount
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={2}>
                            {this.props.params.index + 1}
                        </Col>
                        <Col xs={2}>
                            {this.format_rate_type(this.props.params.data.rate_type)}
                        </Col>
                        <Col xs={2}>
                            {this.props.params.data.number}
                        </Col>
                        <Col xs={2}>
                            {this.props.params.data?.price}
                        </Col>
                        <Col xs={2}>
                            {this.props.params.data.rate}
                        </Col>
                    </Row>
                </Col>
            </>
        )
    }
}
