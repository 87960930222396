//GENERAL REACT MODULES
import React from "react";
import { Container, Row, Col } from 'react-bootstrap'

//MODULE SPECIFIC
import GrafanaFrame from "components/GrafanaFrame/GrafanaFrame.view.jsx";
import _basePanelView from "components/General/_basePanelView.jsx"
import Controller from "./LoadBoost.controller.jsx"
import Modal from "./LoadBoostModal/LoadBoost.modal.view"
import "./LoadBoost.css"
import Overlay from "./Overlay/Overlay.partial";
import grafana_config from "../../controllers/GrafanaConfig/GrafanaConfig.js";
import CotaListPanel from "components/CotaListPanel/CotaListPanel.partial.jsx"
import config_data from "./Loadboost.json"

export default class LoadBoostPanel extends _basePanelView {
    constructor(props) {
        super(props)
        this._modal = Modal
        this.controller = new Controller({ params: { view: this } })
        this.state.screen = 0
        this.state.panelId = 1
        this.state.panel_name = "loadboard-2"
        this.state.selected = undefined
        this.state.channel_name = ["grafana_row", "grafana_button"]
        this.state.panel_configs = grafana_config()["pages"]["loadboard-2"]
        this.handleProcessEvent = this.handleProcessEvent.bind(this)
        this.state.states = []
        // this.state.test = false
    }
    get self() {
        return this.state.context.self
    }
    async load_user() {
        if (this.state.data.user === undefined) {
            this.state.data.user = this.controller.getCopy(await this.state?.context?.get_self({ caller: this.controller, update: true }))
            this.forceUpdate()
        }
    }
    determine_modal() {
        if (this.state?.data?.user?.roles?.length === 0) {
            return (
                <Overlay params={{ parent: this, controller: this.controller }} />
            )
        }
    }
    render_grafana_panel() {
        if (this.state.data.user === undefined || this.state.data.user === null) { return <></> }
        return (
            // <GrafanaFrame className="grafana-wrapper"
            //     params={{ parent: this, panel_configs: this.state.panel_configs, 
            //                       panel_name: this.state.panel_name, subdomain: "boards",
            //                       filters: {name: this.state?.page?.user_filter}}}
            // />
            <CotaListPanel params={{
                parent: this,
                panel_name: this.state.panel_name, subdomain: "boards",
                configs: config_data?.panels,
                endpoint: "loads",
                req_name: "loadboost",
                query: "loadboost",
                configs: config_data.panels,
                //query_params: this.controller.generate_query_params(this.state?.page?.attributes),

                // args: this.state?.page?.status_filter[0] === undefined ? `carrier:"${this?.state?.carrier_id}"` : `carrier:"${this?.state?.carrier_id}",statuses:["${this.state?.page?.status_filter}"]` ,
                filters: {
                    name: this.state?.page?.user_filter,
                    // statuses: JSON.stringify(this.state?.page?.status_filter),
                    // ltl_truckload: this.controller.resolve_ltl_toggle(),
                    // nots: ((this.state?.page?.status_filter?.length > 0) ? "" : "status_id"),
                    // carrier_id: this.state?.carrier_id,
                    // time: this.controller.process_date(this.state?.page?.time_frame)


                }
            }} />
        )
    }
    get panel_height() {
        if (this.expandElement?.state?.is_toggled) {
            return 260
        }
        return 80
    }
    renderMain() {
        // console.log(this)
        // console.log(this.self)
        this.load_user()
        return (
            <Container className="generic_100 g-0">
                <Row className="max_height">
                    <Col className="flex_wrapper_rows" id={this.name}>
                        {/* <Row className="cota_panel_object generic_100 quotes_panel_one add_shadow"> */}
                        <Row className="cota_panel_object quotes_panel_one add_shadow" style={{ "height": "calc(100% - " + this.panel_height + "px)", "width": "100%" }}>
                            <Col className="generic_100 wrap_cotapanel g-0">
                                {this.render_grafana_panel()}
                            </Col>
                        </Row>
                                {/* </Row> */}
                            </Col>
                        </Row>
                        {this.determine_modal()}
                    </Container>
                    )
    }
}

