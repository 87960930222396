// GENERAL REACT MODULES
import React from "react";
import { Row } from 'react-bootstrap';

// PAGE SPECIFIC MODULES
import _baseViewComponent from "components/General/_baseViewComponent.jsx"
import Column from "components/Column/Column.view.jsx"
import Seperator from "components/Seperator/Seperator.view.jsx"
import ColumnOneController from "./DetailsColumns/ColumnOne.controller";
import DetailsView from "./Partials/DetailsView.partial";
import "./Details.css"

export default class Details extends _baseViewComponent {
    constructor(props) {
        super(props)
        this.controller = props.params?.controller
        let get_column_controller = this.get_column_controller.bind(this)
        this.column = {
            controllers: {
                get one() {
                    return get_column_controller({name: "column_one_controller", class_name: ColumnOneController})
                }
            }
        }
    }
    get_column_controller({name, class_name}) {
        if (this.state[name] === undefined && class_name !== undefined) {
            this.state[name] = new class_name({params: {view: this}})
        }
        return this.state[name]
    }
    render() {
        return(
            <span className="admin_middle_wrapper" id={this.name}>
                <Row className="g-0 admin_max_height">
                    <span className="details_menu_column">
                        <Column params={{parent: this, controller: this.controller, view_controller: this.column.controllers.one }}/>
                    </span>
                    <Seperator params={{parent: this, controller: this.controller, view_controller: this.column.controllers.one, solid: true}}/>
                    <DetailsView params={{parent: this, controller: this.controller}}/>
                </Row>
            </span>
        )
    }
}