// GENERAL REACT MODULES
import { Row, Col, Form, Button } from "react-bootstrap";
import { FaPen, FaRegWindowClose } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import "./Shipper.css"
import config_data from "./Shipper.json"
import Controller from "./ShipperPartial.controller.jsx"
import Modal from "./Shipper.modal.jsx";
import Background from "components/Background/Background.view";
import ChangeContactModal from "./ChangeContactModal/ChangeContactModal.jsx";

export default class ShipperObject extends _basePartial {
    constructor(props) {
        super(props)
        this.state.form = config_data.form_objects
        this.configs = config_data.configs
        this.state.padding = (this.state?.padding) ? "ps-3" : ""
        this.state.border = (this.state?.borderless) ? "" : "cota_panel_object_unsized cota_panel_border"
        this.state.seperated = true
        this._draggable_modal = false
        this.partial_controller = new Controller({ params: { view: this, controller: this.controller } })
        this._modal = Modal
        this.state.pickup = {}
        this.state.consignee = {}
        this.state.stops = []
        this.state.stop = {}
        this.state.index = 0
        this.state.ran = []
        this.state.new_contact = false
        this.state.contact_change = false
        // this.state.loading = true
    }
    follow_on_selection({ event, obj, data }) {
        this.controller.setState({ key: data?.title, param: data?.name, value: { search_value: "" } })
        this.api?.contacts?.gid({ caller: this, value: obj?.id, callback: { f: this.process_loaded_contact, p: { name: data?.name } } })
    }
    process_loaded_contact({ caller, params, results }) {
        caller.controller.view.state.data.stops.push(results?.data?.contact)
        caller.controller.view.forceUpdate()
        caller.state.parent?.column?.forceUpdate()
    }
    get details() {
        return this.partial_controller.details
    }
    get contact() {
        return this.details?.contact
    }
    get company() {
        return this.contact?.company
    }
    get index() {
        return this?.props?.params?.index
    }
    followDidMount() {
        this.partial_controller?.load_cache()
    }
    changeModal() {
        this._modal = ChangeContactModal
        this.toggle_modal({ toggle_state: true })
    }
    reset_modal(){
        this._modal = Modal
        this.toggle_modal({ toggle_state: false })
    }
    determine_edit_contact() {
        if (this.state.modal) {
            return this.state.parent.toggle_modal({ toggle_state: true })
        }
        const editContact = this.partial_controller?.editContact.bind(this)
        return editContact()
    }
    selectRender() {
        // console.log("select render ----", this)
        let display = true
        if (this.state.visible !== undefined) {
            let obj = this.controller.getState({ key: "data", param: this.state.visible })
            if (obj?.id === undefined) { display = false }
        }
        if (!display) { return (<></>) }
        if (this.contact?.id !== undefined) {
            return (<>{this.renderDetails(this.details)}</>)
        }
        return this.renderInput()
    }
    renderTitle() {
        if (!this.state.title) { return <></> }
        return (
            <Row className={"font_size_25 "}>
                <Col className={(this.state.type === "stop") ? "large_title" : ""}>
                    {(this.state.type === "stop") ? ("Add " + this.configs?.[this.state.type]?.title + "s ?") : this.configs?.[this.state.type]?.title}
                </Col>
            </Row>
        )
    }
    renderSubPanel() {
        if (!this.state.title) { return <></> }
        return (
            <Col sm={1} className="sub_subpanel center_text">
                {this.configs?.[this.state.type]?.title}
            </Col>
        )
    }
    renderInput() {
        // console.log("FINDING NAME",this.props.params.type,this.details.contact.name)
        // this.partial_controller.find_contacts( this.props.params.type)
        return (
            <Row className={this.state.padding + " shipper_panel g-0"} id={this.name} ref={(divElement) => { this.divElement = divElement }}>
                <Col className={this.state.border + " adj_padding"}>
                    {this.renderTitle()}
                    <Row>
                        <Col>
                            {this.controller.view.formObject({
                                data: this.state.form[this.state.type],
                                options: undefined,
                                owner: this
                            })}
                        </Col>
                        <Col className="create_contact_col">
                            <Button className={"toggle_button_style " + this.controller.getTheme()}
                                onClick={() => this.determine_edit_contact()}>
                                Create Contact
                            </Button>
                        </Col>
                    </Row>
                </Col>
                {this.renderSubPanel()}
            </Row>
        )
    }
    render_reset_rep() {
        if (this.state.no_edit || this.state.no_close) { return <></> }
        return (
            <Col className="g-0">
                <FaRegWindowClose onClick={this.partial_controller.reset_rep.bind(this)} className="pointer"/>
            </Col>
        )
    }
    render_contact(contact) {
        if (contact?.id === undefined || contact?.id === null) {
            return this.renderContactInput()
        }
        return (
            <Row>
                <Col>
                    {contact?.first_name}
                </Col>
                <Col>
                    {contact?.last_name}
                </Col>
                <Col>
                    {contact?.email}
                </Col>
                <Col>
                    {contact?.phone}
                </Col>
                <Col sm={1}>
                    <Row>
                        <Col>
                            &nbsp;
                        </Col>
                        {this.render_reset_rep()}
                        {/* <Col>
                            <FaRegWindowClose onClick={this.partial_controller.reset_rep.bind(this)} className="pointer"/>
                        </Col> */}
                    </Row>
                </Col>
            </Row>
        )
    }
    renderContactInput() {
        if (this.state.no_rep) { return <></> }
        return (
            <Row className="pad_top_1rem">
                <Col>
                    <Button className={"toggle_button_style " + this.controller.getTheme()} onClick={this.toggle_modal?.bind(this, { toggle_state: true })}>
                        Add Representative?
                    </Button>
                </Col>
            </Row>
        )
    }
    renderContactDetails(data) {
        return (
            <>
                <Row>
                    <Col>
                        {data?.first_name} {data?.last_name}
                    </Col>
                    <Col>
                        {data?.email}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {data?.phone}
                    </Col>
                    <Col>
                    </Col>
                </Row>
            </>
        )
    }
    processDate({ date, word }) {
        if (date !== undefined) {
            var splitStr = ("" + date + "").split(word);
            return splitStr[0].substring(4)
        }
        return ""
    }
    check_id_change() {
        // DARN HACKS!
        if(!this.state.contact_change && this.contact?._id !== "reform") {
            this.state.ran = []
            this.state.contact_change = true
        }
    }
    render_hours_open() {
        if(this?.contact?.hours?.open) {
            return (
                <Row>
                    <Col className="generic_label_md" xs="auto">
                        Business Hours:
                    </Col>
                    <Col className="generic_text_md" xs="auto">
                        {this?.contact?.hours.open}
                    </Col>
                    <Col className="generic_text_md" xs="auto">
                        -
                    </Col>
                    <Col className="generic_text_md" xs="auto">
                        {this?.contact?.hours.close}
                    </Col>
                    <Col />
                </Row> 
            )
        }
    }
    render_date() {
        if(this.details?.date) {
            return (
                <Row>
                    <Col className="generic_label_md" xs="auto">
                        Date:
                    </Col>
                    <Col className="generic_text_md" xs="auto">
                        {this.details?.formattedDate}
                    </Col>
                    <Col />
                </Row> 
            )
        }
    }
    render_time() {
        if(this.details?.time || this.details?.end_time) {
            return (
                <Row>
                    <Col className="generic_label_md" xs="auto">
                        Time:
                    </Col>
                    <Col className="generic_text_md" xs="auto">
                        {this.details?.time}
                    </Col>
                        {this.details?.end_time ? <Col xs="auto">-</Col> : null}
                    <Col className="generic_text_md" xs="auto">
                        {this.details?.end_time}
                    </Col>
                    <Col />
                </Row> 
            )
        }
    }
    renderDetails(data) {
        this.check_id_change()
        if (!this.state.pickup.ran || !this.state.consignee.ran) {
            this.partial_controller.find_contacts(this.contact.name)
        }
        return (
            <Row className={this.state.padding + " subpanel g-0 partial_text"} id="ShipperObject" ref={ (divElement) => { this.divElement = divElement } }>
                <Col className={this.state.border + " max_height  relative bg" }>
                    <Row>
                        <Col>
                            <Row>
                                <Col >
                                    <Row>
                                        <Col className="generic_text_md">
                                            {this.contact?.name}
                                        </Col>
                                    </Row>
                                    <Row className="generic_text_md"  >
                                        <Col>
                                            {this?.contact?.address}
                                        </Col>
                                    </Row>
                                    <Row className="generic_text_md"  >
                                        <Col>
                                            {this?.contact?.address_2}
                                        </Col>
                                    </Row>
                                    <Row className="generic_text_md"  >
                                        <Col>
                                            {this.contact?.location}
                                        </Col>
                                    </Row>
                                    <Row className="generic_text_md"  >
                                        <Col>
                                            {this?.company?.phone}
                                        </Col>
                                    </Row>
                                    <Row className="generic_text_md"  >
                                        <Col>
                                            {this?.company?.email}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    {this.render_hours_open()}
                                    <Row>
                                        <Col className="generic_label_md">
                                            Appointment Required: {((this.details?.appointment_required) ? "Yes" : "No")} 
                                        </Col>
                                    </Row>
                                    {this.render_date()}
                                    {this.render_time()}                    
                                    <Row>
                                        <Col className="generic_label_md" xs="auto">
                                            {this.parent?.role?.toLowerCase() == "shipper" ? "Pickup #:" : "Delivery #:"} 
                                        </Col>
                                        <Col className="generic_text_md" xs="auto">
                                            {this.details?.number}
                                        </Col> 
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    
                                </Col>
                            </Row>
                        </Col>
                        {this.render_similar_contacts()}
                        <Col sm={1}>
                            <Row className="pad_top_point5rem">
                                <Col className="flex" >
                                    {this.render_close()}
                                    {this.render_edit()}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {this.render_contact(this.details?.rep)}
                </Col>
                {this.renderSubPanel()}
            </Row>
        )
    }
    render_similar_contacts() {
        // console.log("---- render_similar_contactz ----", this.state?.type, this.index)
        if(this.partial_controller.should_render()) {
            return (<>
                <Col sm={1}>{this.render_vert_line()} </Col><Col sm={4}>
                    <Row sm={6}>
                        <Col sm={2}>
                            <IoIosWarning color="#ffc800" size={70} />
                        </Col>
                        <Col sm={1}></Col>
                        <Col sm={7}>
                            <Row className="warn_text">{`We found ${this.state[this?.state?.type]?.similar_number ?? this.state[this?.state?.type][0]?.similar_number} existing contacts that closely match this name`}</Row>
                            <Row>
                                <Button className={"primary_button_coloring " + this._theme} onClick={() => this.changeModal()}>
                                    Show Me
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </>)
        }
    }
    render_vert_line() {
        return (<>
            <Col className="vert_line" sm={1} />
        </>)
    }
    render_close() {
        if (this.state.no_close) { return <></> }
        return (
            <Col className="g-0">
                <FaRegWindowClose onClick={() => this.partial_controller.reset_contact()} className="pointer" />
            </Col>
        )
    }
    render_edit() {
        if (this.state.no_edit) { return <></> }
        return (
            <Col className="flex_end">
                <FaPen size={20} onClick={() => this.state.parent.toggle_modal({toggle_state: true})} className="pointer"/>
            </Col>
        )
    }
    renderView() {
        this.partial_controller.load_details()
        this.state.visible = this.props.params?.visible
        this.state.type = this.props.params?.type
        if (this.details !== undefined) {
            this.details._param = this.state?.type
        }
        this.state._completed = this.details?.contact?._any
        return (
            <>
                {
                    this.selectRender()
                }
            </>
        )
    }
}
