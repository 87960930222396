import Keycloak from "keycloak-js";
// import { createContext }from 'react'

// const KeycloakContext = createContext()

const keycloak = new Keycloak({
    url: "https://auth."+process.env.REACT_APP_DOMAIN+"/auth",
    //url: "https://auth.cotasystems.com/auth",
    realm: "cota",
    clientId: "cotafreight",
    onLoad: "check-sso",
});

// const KeycloakContextProvider = (props) => {
//     const [ keycloakValue, setKeycloakValue ] = useState(null)
//     const [ authenticated, setAuthenticated ] = useState(false)

//     const setKeycloak = () => {
//         // const keycloak = Keycloak({
//         //     realm: process.env.REACT_APP_WULING_KEYCLOAK_REALM,
//         //     url: process.env.REACT_APP_WULING_KEYCLOAK_URL,
//         //     clientId: process.env.REACT_APP_WULING_KEYCLOAK_CLIENTID,
//         // })
//         const keycloak = Keycloak({
//             realm: "cota",
//             url: "http://auth."+process.env.REACT_APP_DOMAIN+"/auth",
//             clientId: "cotafreight",
//             onLoad: "check-sso"
//         })

//         keycloak.init({
//             onLoad: 'check-sso', 
//             // checkLoginIframe: false, // remove checkLoginIframe here
//         }).then(authenticated => {
//             setKeycloakValue(keycloak)
//             setAuthenticated(authenticated)
//         })
//     }

//     const logout = () => {
//         setKeycloak(null)
//         setAuthenticated(false)
//         keycloakValue.logout()
//     }

//     useEffect(() => {
//         setKeycloak()
//     }, [])

//     return (
//         <KeycloakContext.Provider
//             value={{
//                 keycloakValue,
//                 authenticated,
//                 logout
//             }}
//         >
//             {props['children']}
//         </KeycloakContext.Provider>
//     )
// }

// export { KeycloakContextProvider, KeycloakContext }
export default keycloak
