// GENERAL REACT MODULES
import { Row } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx"
import "./DocUpload.css"
import UploadPartial from "components/FileUpload/Upload.partial.jsx";
import Controller from "./DocUpload.modal.controller"

export default class DocUploadModal extends _baseModal {
    constructor(props) {
        super(props)
        this.modal_controller   = new Controller({params: {view: this }})
        this.state.modal_params = {
            positioned: "middle_of_panel",   
            title: "Documents",                         
            title_size: "",                     
            closeable: true,                    
            has_overlay: true,                  
            close_on_outside_click: true,      
            x_adjustment: "0px",                
            y_adjustment: "-100px",                
            draggable: false,                              
        }
    }
    renderView() {
        return (
            <Row className="pad_bottom">
                <UploadPartial params={{parent: this.view, controller: this.controller, endpoint: "company"}} />
            </Row>
        )
    }
}
