//REACT SPECFIC 
import React from "react";
import { Row, Col } from "react-bootstrap";

//MODULE SPECIFIC
import Controller from "./LoadWidget.partial.controller.jsx"
import _basePartial from "components/General/_basePartial.jsx"
import "./LoadWidget.css"
import LoadDetailsPartial from "./LoadDetails/LoadDetails.partial.view.jsx"

export default class LoadWidget extends _basePartial {
    constructor (props) {
        super (props)
        this.partial_controller     = new Controller({params: {view: this, controller: this?.controller}})
    }
    render_details({index, data}) {
        return <LoadDetailsPartial key={index} params={{parent: this, controller: this.controller, 
                data: this.partial_controller.create_data_obj({data: data, params: {name: "shipment"}}), index: index}} /> 
    }
    render_cols({index, array}) {
        return (<>
            <Col className="me-1">
                {this.render_details({index: index, data: array[index]})}
            </Col>
            {this.render_next({index: index, array: array})}
        </>)
    }
    // render next
    render_next({index, array}) {
        if(array[index+1] !== undefined) {
            return (
                <Col className="ms-1">
                    {this.render_details({index: index+1, data: array[index+1]})}
                </Col>
            )
        }
        return (
            <Col className="ms-1"/>
        )
    }
    map_loads() {
        let rows = []
        let loads = this.controller?.loads
        for (let index = 0; index < loads?.length; index+=2) {
            if(index === 0) {
                rows.push(<Row key={"row_"+index}>{this.render_cols({index: index, array: loads})}</Row>)
            } else {
                rows.push(<Row key={"row_"+index} className="pt-1">{this.render_cols({index: index, array: loads})}</Row>)
            }
        }
        return rows
    }
    renderView() {
        return (
            <Row id={this.name}>
                <Col>
                    {this.map_loads()}
                </Col>
            </Row>
        )
    }
}