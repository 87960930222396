// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "./_ModelController";

export default class RoleModelController extends _ModelController {
    constructor(props) {
        super(props)
        this._state = undefined
    }
    get status() {
        return this.data?.status
    }
    get name() {
        return this.data?.name
    }
    get label() {
        return this.data?.label
    }
    get data() {
        let data = this?._state?.data
        if (data === undefined && this.controller !== undefined) {
            data = this.controller.getState({key: this._data_key, param: "role"})
        }
        return data
    }
}
