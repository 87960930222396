// GENERAL REACT MODULES
import React from "react";
import { Row, Col } from "react-bootstrap";
// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx";
import ModalController from "./CotaUserMenu.modal.controller";
import "./CotaUserMenu.modal.css";

export default class CotaUserBarModal extends _baseModal {
    constructor(props) {
        super(props)
        // this.modal_controller = new ModalController({ params: { view: this, controller: this.controller } })
        this.state.modal_params = {
            positioned: "relative_to_parent",
            title: "",
            title_size: "",
            relative_position: "below",
            relative_alignment: "start",
            y_adjustment: "0px",
            x_adjustment: "0px",
            has_overlay: false,
            close_on_outside_click: true,
        }
        this.controller  = new ModalController({params: {view: this, controller: this?.controller}})
    }

    renderView() {
        const menuItems = [
            {
                title: "User Details",
                action: ()=> this.controller.handle_user_details()
            },
            {
                title: "About COTA",
                action: ()=> this.controller.handle_about_cota()
            },
            {
                title: "Privacy Policy",
                action: ()=> this.controller.handle_privacy_policy()
            },
            {
                title: "Terms of Use",
                action: ()=> this.controller.handle_terms_of_use()
            },
            {
                title: "Sign Out",
                action: ()=> this.controller.handle_sign_out()
            }
        ]
        return (
            <div>
                {menuItems.map((item)=>{
                    return (
                        <Row onClick={item.action} className=" pointer item">
                            <Col>
                                {item.title}
                            </Col>
                        </Row>
                    )
                })}
            </div>
        )
    }
}
