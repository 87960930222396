// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import EventModelController from "controllers/ModelControllers/Trips/EventModelController.jsx"
import NoteModelController from "controllers/ModelControllers/Shipping/NoteModelController.jsx"
import event_mutation from "assets/graphql/Events/event.full.mutation.graphql.json"

export default class AddLogModalController extends _viewController {
    constructor(props) {
        super(props)
        this.event          = new EventModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true}})
        this.note           = new NoteModelController({params: {parent: this,
                                                                controller: this,
                                                                is_view: true }})
    }
    async follow_on_selection({event, obj, data}) {

        if (data?.name === "zip") { 
            this.event.zip        = obj.zip
            this.event.city       = obj.city
            this.event.state      = obj.state
            this.event.country    = obj.country
        }
        if (data?.name === "address") {
            this.event.address    = obj?.address
            this.event.address_2  = obj?.address_2
            this.event.zip        = obj?.location?.zip
            this.event.city       = obj?.location?.city
            this.event.state      = obj?.location?.state
            this.event.country    = obj?.location?.country
            this.setState({key: "search",
                            param: "zip.selected_value",
                            value: {zip: obj?.location?.zip} })
        }
    }
    delete_data(obj) {
        if(typeof obj === "object") {
            Object.keys(obj).map((key) => { delete obj[key] })
        }
    }
    upload_callback(caller,params,results){
        caller.caller.controller.view.state.data.logs[caller.caller.controller.view.state.data.logs.length - 1].documents.push(caller.results.data.createWeb_resource)
        // caller.caller.reset_state()
        caller.caller.controller.view.forceUpdate()
    }
    // NEED to actually reset the modal properly
    reset_state() {
        this.delete_data(this.view.state.data.event)
        delete this.view.state.search
        this.view.state.parent.setState(prevState => ({reset: !prevState.reset}))
    }
    cancel() {
        this.reset_state()
        this.view.state.parent.toggle_modal({toggle_state: false})
    }
    submit_action() {
        let _summary = this.event.summary
        _summary.trip_id = this.controller.trip?._id
        // NEEDS to actually update the DB through API for date
        delete _summary.date
        let data = this.toUnquotedJSON(_summary)
        // console.log("--- Data ---", data)
        let body = JSON.parse(JSON.stringify(event_mutation))
        body.query = body.query.replace("input:", "input:"+data)
        this.api.events.ask({caller: this, params: {body: body}, callback: {f: this.process_event}})
    }
   async process_event({caller, params, results}) {
        // console.log("CALLER",caller)
        if(results?.errors !== undefined) {

            console.log("Error creating event", results.errors)
            return
        }
    
        // console.log("Event created")
        if(results?.data?.event?.id !== undefined && results?.data?.event?.id !== null) {
            if (caller?.uploader !== undefined) {
                // console.log("ABOUT TO CALL")
                await caller.uploader?.process_queue(results?.data?.event?.id)
            }
            caller.controller.view.state.data.logs.push(results.data.event)
            caller.reset_state()
            caller.view.state.parent.toggle_modal({toggle_state: false})
            caller.controller.view.forceUpdate()
        }
    }
}