// GENERAL REACT MODULES
import { Container, Row, Col, Card } from 'react-bootstrap'
// ALL OTHER MODULES
import Controller from "./ViewContactPanel.controller.jsx"
import _basePanelView from "components/General/_basePanelView.jsx";
import MultiPanel from "components/MultiPanels/MultiPanel"
import "./ViewContactsPanel.css"
import "./ContactPanel.css"
import CotaUserBar from "components/CotaUserBar/CotaUserBar.partial.jsx"
import ContactNotes from './ContactNotes/ContactNotes.view.jsx';
import Modal from "./ContactPerson/NewPerson/NewPerson.modal.jsx"

export default class ViewContactPanel extends MultiPanel {
    constructor(props) {
        super(props)
        this.custom_canvas              = true
        this.controller                 = new Controller ({params: {view: this}})
        this._modal                     = Modal
        this.state.parent               = props.params.parent
        this.state.edit_mode            = false
        this.state.credit_fields        = false
        this.state.data.notes           = []
        this.state.countries            = []
        this.state.states               = []
    }
    renderInnerFrame() {
        return (
            <Container id={this.name} className="generic_100">
                <Row className='max_height'>
                    <Col className='flex_wrapper_rows'>
                        <CotaUserBar params={{parent: this, controller: this.controller, title: "Contacts", return: true}} />
                        <Row className='generic_100 margin_auto'>
                            <Col className="pad_right">
                                <Row>
                                    <Col id='ContactPanel'>
                                        <div className="g-0 cota_panel_border add_shadow pb-4">
                                            {this.controller.switchContactPanel()}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="auto"/>
                            <Col sm={4}>
                                {/* <Row>
                                    <Col>
                                        {this.controller.contactPersonsDisplay()}
                                    </Col>
                                </Row>
                                <Row>
                                    &nbsp;
                                </Row> */}
                                <Row>
                                    <Col>
                                        <ContactNotes params={{parent: this, controller: this.controller}} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}