// GENERAL REACT MODULES
import _basePartial from "components/General/_basePartial.jsx"
import { Row, Col } from 'react-bootstrap'

// ALL OTHER MODULES
import "./TriColumn.css"
import Controller from "./TriColumn.controller.jsx"

export default class TriColumn extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this}})
    }
    get items() {
        return this.controller.getState({key: "line_items"})
    }
    get item_data() {
        return this.props?.params?.item_data
        // return this.items?.[this.state?.index]
    }
    get line_item() {
        return this.partial_controller.line_item
    }
    get line_height() {
        return 40
    }
    get _configs() {
        return this.controller.getState({key: "configs"})
    }
    render() {
        return (
            <Col id={this.name} style={{"minWidth": this.state.width}}>
                <Row className="tri_row">
                    <Col  className="line_one" style={{  color: this.partial_controller?.get_col_color(0)}}>
                        {this.partial_controller.unwrap_obj({obj: this.item_data?.[this.state.data?.[0]]})}
                    </Col>
                </Row>
                <Row className="tri_row">
                    <Col  className="line_two" style={{color: this.partial_controller?.get_col_color(0)}}>
                        {this.partial_controller.unwrap_obj({obj: this.item_data?.[this.state.data?.[1]]})}
                    </Col>
                </Row>
                <Row className="tri_row">
                    <Col  className={"line_three "+this.partial_controller.font_color()} style={{color: this.partial_controller?.get_col_color(0)}}>
                        {this.partial_controller.unwrap_obj({obj: this.item_data?.[this.state.data?.[2]]})}
                    </Col>
                </Row>
            </Col>
        )
    }
}
