import _viewController from "controllers/ViewController/_viewController.jsx"

export default class PayorCardController extends _viewController {
    constructor (props) {
        super(props)
    }
    get full_address() {
        let address = this.controller?.company?.full_address?.replaceAll(' ','')
        if(address === ",,") {
            return ""
        }
        return this.controller?.company?.full_address
    }
    find_contacts(type) {
        if (type === null || type === undefined) {
            this.view.state.hide = true
            this.view.forceUpdate()
            return
        }
        if(type?.length === 0){
            return
        }
        // this.view._isMounted = true
        if (this.view.state.ran.length) {
            return
        } else {
            //---- Contacts.jsx Search ----
            // console.log("________SENDING PAYOR-------", type, this, this?._isMounted)
            this.api.contacts.search({ caller: this, value: `${type}`, callback: { f: this.process_contacts } })
        }
        this.view.state.ran.push(type)
    }
    process_contacts({ caller, params, results }) {
        // console.log("---- process_contacts PAYOR ----",results)
        caller.view.state.loading = false
        if (results !== undefined) {
            caller.view.state.similar_payor = results
            caller.view.state.similar_payor_number = results.data.contacts.length
            caller.view.forceUpdate()
        }
    }
    should_render() {
        let show = false
        if (this?.controller?.load?.status === "Pending" && this?.view?.state?.new_contact !== true && this?.controller?.load?._id) {
            if (this?.view?.state?.similar_payor_number) {
                if (this?.view?.state?.similar_payor_number > 1) {
                    show = true
                }
            }
        }
        return show
    }
}