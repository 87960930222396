// rate_confirmation.create.graphql.json

// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES

import schema from "./schema.json"
import test_data from "./test.json"
import rate_confirm_request from "../../../../assets/graphql/Documents/rate_confirmation.create.graphql.json"

// ALL OTHER MODULES
import _baseApiObject from "components/General/_baseApiObject"

export default class Email extends _baseApiObject {
    constructor(props) {
        super(props)
        this.schema     = schema
        this.test_data  = test_data
        this.requests   = {
            general: undefined,
            rate_confirm: rate_confirm_request
        }
    }
    async rate_confirmation({caller, value, type, callback, params, id, file_type, receipient, generate_only}) {
        if (value === undefined) { return }
        if (Array.isArray(value)) {
            if (value.length === 0) { return }
            let body = JSON.parse(JSON.stringify(rate_confirm_request))
            body.query = body.query.replace("create_document(input: {datas:", 'create_document(input: {request_type: "'+type+'",  carrier: "'+receipient+'" datas: '+JSON.stringify(value).replace(/"([^"]+)":/g, '$1:')+' ')
            if (generate_only !== undefined) {
                body.query = body.query.replace("{request_type: ", "{ generate_only: "+generate_only+", request_type: ")
            }
            return await this.state.parent.post({body: body, callback: callback, caller: caller})
        }
    }
}