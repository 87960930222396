import _viewController from "controllers/ViewController/_viewController.jsx"
import AssetModelController from "controllers/ModelControllers/AssetModelController.jsx"
import mutation_request from  "assets/graphql/Assets/Assets.mutation.graphql.json"
import { Button, Row, Col, Card} from "react-bootstrap";

export default class EditTrailerController extends _viewController {
    constructor (props) {
        super(props)
        this.asset      = new AssetModelController({params: {parent: this,
                                                    controller: this,
                                                    is_view: true,
                                                    view: this.view}})
        this.asset.support_air_ride = false
        this.asset.trailer_type_id  = { name: "" }
        this.requests   = [{callback: {f: this.process_trailer_types, p: {name: "trailer_types", var: "trailer_types"}},
                                endpoint: "trailers"},
                            {callback: {f: this.state_results, p: {name: "areas", var: ["areas", "states"]}}, 
                                endpoint: "areas", replace: {o: "params:", r:'params: {country: "US"}'}}]
    }
    state_results({caller, params, results}) {
        if (results?.errors === undefined && results?.data?.areas !== undefined) {
            results.data.areas.map((area, index) => {
                caller.view.state.states.push({ value: area.state, label: area.state })
            })
        }
    }
    process_trailer_types({caller, params, results}) {
        if(results.data?.trailer_types === undefined) { return }
        if(results.data?.trailer_types.length <= 0) { return }
        results.data.trailer_types.map((asset, index) => {
            asset.label = asset.name
            asset.value = asset.name
            caller.view.state.trailer_types.push(asset)
        })
    }
    handleClick = () => {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.onchange = (event) => {
            this.props.onFileChange(event.target.files[0]);
        };
        fileInput.click();
    };
    async saveAction(page_key) {
        if 
            (this.controller?.asset?.data?.trailer_type === undefined || this.controller?.asset?.trailer_number === undefined)
            {
            alert("Please fill in both the Trailer Number and Trailer Type fields before saving");
        }
         else {
            this.controller.asset.asset_type = "TRAILER"
            // this.controller.asset.trailer_truck = false
            // this.controller.asset.status_toggle = true
            this.controller.asset.company_id = this.state.context.self.companies[0].id
            let summary = this.controller?.asset?.summary
            summary.company_id = this.state.context.self.companies[0].id
            let data = this.controller.toUnquotedJSON(summary)
            let body = JSON.parse(JSON.stringify(mutation_request))
            body.query = body.query.replace("input:", "input: "+data)
            if (this.controller?.asset?._sum < 0) { return }
            let sb_config = {success: {show: false}}
            let response = await this.api.assets.ask({caller: this, params: {body:body}, callback: {f: this.process_save, p: {page_key: page_key, sb: sb_config}}})
            this.controller.handleCloseScreen()
        }
    }

    async process_save ({caller, params, results }) {
        let id = (results?.data?.setAsset?.id !== undefined ? results?.data?.setAsset?.id : results?.data?.setAsset.id)
        if (id != undefined) {
            caller.panel_controller.selectPanel({panel_name: params?.page_key, cache_data: {id: id, type: this.controller?.asset}})
        }
    }
    get _data(){
        return this.getState({key: "data"})
    }
    handleCloseScreen() {
        this.view.state.panel_params.controller.view.resetScreen()
    }
    toggleMoreInfo = () => {
        this.setState((prevState) => ({
            more_info: !prevState.more_info
        }))
    }
}
