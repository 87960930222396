// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button, Container, Card } from "react-bootstrap";

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx";
import Controller  from "./AssetAttached.partial.controller.jsx"
import "./AssetAttached.css"
import AssignAsset from "../AssignAsset/AssignAsset.partial.jsx";
import Modal from "./UnhookAssetModal/UnhookAssetModal.view.jsx"

export default class AssetAttached extends _basePartial {
    constructor(props) {
        super(props);
        this.partial_controller             = new Controller ({params: {view: this, controller: this?.controller}})
        this.state.redirectToAssignAsset    = false
        this._modal                         = Modal
    }
    get linked_asset() {
        return this.controller.view.state?.data?.asset?.linked_asset
    }
    format_unhook(str) {
        str = str.toLowerCase()
        return str[0].toUpperCase() + str.slice(1)
    }
    assign_asset() {
        return (
            <AssignAsset params={{parent: this.controller.view,
                                    controller: this.controller}} />
        )
    }
    componentDidMount() {
        this._isMounted = true
        this.partial_controller.load_data()
    }
    renderView() {
        // console.log(this)
        if(this.state.redirectToAssignAsset) {
            return this.assign_asset()
        }
        return (
            <div>
                <Row id={this.name}>
                    <div className="cota_panel_border g-0 add_shadow">
                        <Row className="pad_3">
                            <Col className="icon_col">
                                {this.partial_controller.asset_icon()}
                            </Col>
                        </Row>
                        <Row>
                            &nbsp;
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col className="font icon_col">
                                        {this.controller.asset.inverse_type} 
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                        </Row>
                        <Row>
                            <Col className="icon_col hooked">
                                Hooked {this.linked_asset?.asset_number}
                            </Col>
                        </Row>
                        <Row>
                            &nbsp;
                        </Row>
                        <Row className="pad_bot">
                            <Col className="icon_col">
                                <Row>
                                    <Col style={{whiteSpace:"nowrap"}}>
                                        <Button className={"light_button " + this._theme} onClick={() => this.toggle_modal({toggle_state: true})}>
                                            Unhook from a {this.format_unhook(this.controller.asset.inverse_type)}
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button className={"light_button " + this._theme} onClick={() => this.partial_controller.unattach_asset()} >
                                            Change {this.controller.asset.inverse_type}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Row>
            </div>
        )
    }
}