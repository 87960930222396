// GENERAL REACT MODULES
import React from 'react'
import { Row, Col, Card, Button } from 'react-bootstrap'

//MODULE SPECIFIC
import _basePartial from "components/General/_basePartial.jsx"
import Modal from "./NewPerson/NewPerson.modal.jsx"
import PersonItem from './PersonItem/PersonItem.jsx'
import "./ContactPersonPanel.css"

export default class ContactPersonPanel extends _basePartial {
    constructor(props) {
        super(props)
        this.state.data.people = [{email: "",
                                    phone: "",
                                    first_name: "",
                                    last_name: ""}]
        this._modal     = Modal
    }
    renderView() {
        return (
            <Row id={this.name}>
                <Card className="add_shadow g-0">
                    <Row className='g-0'>
                        <Col className='ps-3'>
                            <Row className='py-4 g-0'>
                                <Col className='title'>Contact Persons</Col>
                            </Row>
                            <PersonItem params={{parent: this, controller: this.controller, index: 1}}/>
                        </Col>
                    </Row>
                    <Row className='g-0'>
                        <Col>
                            <hr/>
                        </Col>
                    </Row>
                    <Row className='pb-2 mb-1 d-flex flex-row justify-content-between align-items-center g-0'>
                        <Col/>
                        <Col>
                            <Button variant="outline-primary" onClick={() => this.toggle_modal({toggle_state: true})} className='no_wrap'>
                                Add contact person
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </Row>
        )
    }
}