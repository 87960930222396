import _viewController from "controllers/ViewController/_viewController.jsx";
import unlink_driver from "assets/graphql/Assets/Driver.unlink.graphql.json"
import AssetModelController from "controllers/ModelControllers/AssetModelController.jsx"


export default class ChangeDriverModalController extends _viewController {
    constructor(props) {
        super(props)
        this.asset     = new AssetModelController ({params: {parent: this,
                                                                controller: this,
                                                                view: this.view,
                                                                is_view: true}})
    }
    follow_handle_results({params, results}) {

    }
    async unassign_driver(link) {
        let driver = this.controller.drivers?.filter(driver => driver?.id === this.driver_id)?.[0].value
        let body = undefined
        if(unlink_driver !== undefined) {
            body = JSON.parse(JSON.stringify(unlink_driver)) 
            let _summary = {
                id: driver
            }
            if (link === true) {
                body.query.replace("unlinkDriver", "linkDriver")
            }
            if(_summary.id !== undefined) {
                let data = this.controller.toUnquotedJSON(_summary)
                body.query = body.query.replace("input:", 'input: '+data)
                this?.controller?.api?.assets?.create({caller: this, params: {body: body}, callback: {f: this.modal_controller.process_assignment}})
            }
        }
    }
    process_assignment({caller, params, results}) {
        caller.controller.view.state.data.asset.driver = undefined
        caller.controller.view.forceUpdate()
        caller.state.parent.forceUpdate() 
    }
    assign_asset_redirect() {
        this.setState({assignAsset: true})
    }
}