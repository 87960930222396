// GENERAL REACT MODULES
import React from "react";
import { Row, Col } from "react-bootstrap";

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx";
import Controller from "./DriverLink.partial.controller.jsx"
import TruckStatusPartial from "./TruckStatusPartial/TruckStatus.partial.view";
import AssignDriverTruck from "./AssignDriverTruck/AssignDriverTruck.partial";

export default class DriverLinkPartial extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params:{view: this, controller: this?.controller}})
    }
    get asset() {
        return this.controller.asset
    }
    determine_render() {
        if(this.asset?._id !== undefined && this.asset?._id !== null) {
            return <TruckStatusPartial params={{parent: this, controller: this?.controller, data: {asset: this.asset}}}/>
        }
        return <AssignDriverTruck params={{parent: this, controller: this?.controller}}/>
    }
    renderView() {
        // console.log(this)
        return (
            <Row id={this.name}>
                <Col>
                    {this.determine_render()}
                </Col>
            </Row>
        )
    }
}