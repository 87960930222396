// GENERAL REACT MODULES
import "react-datepicker/dist/react-datepicker.css";

// ALL OTHER MODULES
import _basePanel from "components/General/_basePanel";

export default class MultiPanel extends _basePanel {
    constructor(props) {
        super(props)
        if (props?.params?.panel_params !== undefined) {
            Object.keys(props?.params?.panel_params).map((param, index) => {
                this.state[param] = props?.params?.panel_params[param]
                return undefined
            })
        }
    }
    loadInnerFrame() {
        this.controller?.load_cache()
        return this.renderInnerFrame()
    }
    renderInnerFrame() {

    }
    submit_action() {
        this?.buttonAction(true)
    }
    handle_change(event) {
        let data = this.state.data
        data[event.target.name] = event.target.value
        this.setState({data: data})
        // this.setState({data: {[event.target.name]: event.target.value}})
    }
    buttonAction(direction) {
        if (direction) {
            this.state?.parent?.iteratePanels(this.state.data)
        } else {
            this.state?.parent?.backPanel()
        }
    }
    get_data(data) {
        if (data !== undefined) {
            if (data.data !== undefined) {
                if (data.data.length > 0) {
                    return data.data[0]
                }
            }
        }
        this.name = this.constructor.name
    }
    getPanelData() {
        return this.state?.data
    }
    saveData() {
        this.renderForm()
    }
    renderForm() {
        Object.keys(this.state.form).map((key, index) => {
            return undefined
        })
    }
}
