// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx";
import Controller from "./TripLogItem.modal.controller.jsx"
import "./TripLogItem.modal.css"
import DownloadPartial from "components/FileDownload/Download.partial.jsx"

export default class TripLogModal extends _baseModal {
    constructor(props) {
        super(props)
        this.modal_controller   = new Controller ({params: {view: this, controller: this?.controller}})
        this.state.dims         = {width: 636, height: 980}
        this.state.modal_params = {
            positioned: "middle_of_panel",
            title: "Trip Log",
            title_size: "",
            has_overlay: true,
            close_on_outside_click: true,
            relative_position: "",
            relative_alignment: "",
            x_adjustment: "0px",
            y_adjustment: "0px",
        }
    }
    get log() {
        return this.state.parent.log
    }
    render_value() {
        // really dumb fix for ensuring $0.00 is not displayed where it should not be
        let check = ["CHECK_CALL", "FUEL_RECEIPT", "TOLL_RECEIPT"]
        if(this.log?.value !== undefined && this.log?.value !== null) {
            if(this.log.value === 0 && !check.includes(this.log?.details?.type)) { return }
            return (<>
                <Row>
                    <Col>Value</Col>
                </Row>
                <Row className="font_row">
                    <Col className="font_bg">{this.modal_controller.format_currency(this?.log?.value)}</Col>
                </Row>
            </>)
        } 
    }
    render_note() {
        if(this.log?.note?.id !== undefined && this.log?.note?.id !== undefined) {
            return (
                <Row className="mt-4">
                    <Col>
                        <Row>
                            <Col>Note</Col>
                        </Row>
                        <Row className="font_row">
                            <Col className="font_bg">{this.log?.note?.note_text}</Col>
                        </Row>
                    </Col>
                </Row>
            )
        }
    }
    render_documents() {
        if(this.log?.documents?.length > 0) {
            return (
                <Row className="mt-4">
                    <Col>
                        <Row>
                            <Col>Documents</Col>
                        </Row>
                        {/* {console.log("LOOKING FOR API",this)} */}
                        {this.log?.documents?.map((file, index) => {
                            return (
                                <Row key={"file_"+index}>
                                    <Col className="font_bg">
                                        <DownloadPartial params={{text: true, file: file, css: "text_btn",controller:this.controller}}/>
                                    </Col>
                                </Row>
                            )
                        })}
                    </Col>
                </Row>
            )
        }
    }
    renderView() {
        // console.log(this)
        return (
            <Row id={this.name} className="dimensions">
                <Col className="mt-4">
                    <Row>
                        <Col>
                            <Row>
                                <Col>Type</Col>
                            </Row>
                            <Row className="font_row">
                                <Col className="font_bg">{this.log?.details?.title}</Col>
                            </Row>
                        </Col>
                        <Col>
                            {this.render_value()}
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <Row>
                                <Col>Location</Col>
                            </Row>
                            <Row className="font_row">
                                <Col className="font_bg">{this.log?.full_address}</Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col>Date</Col>
                            </Row>
                            <Row className="font_row">
                                <Col className="font_bg">{this.log?.date}</Col>
                            </Row>
                            <Row className="g-0">
                                <Col className="font_bg">{this.log?.time}</Col>
                            </Row>
                        </Col>
                    </Row>
                    {this.render_note()}
                    {this.render_documents()}
                    {/* NEED to add these features (edit / delete) */}
                    {/* <Row>
                        <hr />
                    </Row>
                    <Row>
                        <Col>
                            <Button>Delete</Button>
                        </Col>
                        <Col>
                            <Button onClick={this.toggle_modal.bind(this.state.parent)}>Cancel</Button>
                        </Col>
                        <Col>
                            <Button>Edit</Button>
                        </Col>
                    </Row> */}
                </Col>
            </Row>  
        )
    }
}