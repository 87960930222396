// GENERAL REACT MODULES
import React from "react";
import "react-datepicker/dist/react-datepicker.css";

// ALL OTHER MODULES
import _baseForm from "./_baseForm.jsx";
import { MainConsumer as Consumer } from "controllers/MainContext.jsx"
import ApiController from "controllers/ApiController/ApiController";

var attached = false;

export default class _baseViewComponent extends _baseForm {
    constructor(props) {
        super(props)
        this.preViewAssignments = this.preViewAssignments.bind(this)
        this.stage = 0;
        this.toggled_views	= []
        this.searchElement	= {}
        if (this.api === undefined) {
            // This should be inherited from the parent via _baseComponent. If not, load here.
            // Has to be performed here, because ApiController is a form of _baseComponent, thus can not be loaded there
            this.api = new ApiController()
        }
    }
    get modal_parent() {
        if(this?.state?.parent_override !== undefined) {
            return this.state.parent_override
        }
        return this
    }
    render() {
        return(
            <>
                <Consumer className="consumer-wrapper">
                    {(context) => {
                            this.state.context = context
                            this.preViewAssignments()
                            return(
                                <>
				                    {this.renderNamedView()}
                                    {this.render_modal({panel_params: {parent: this, modal: this.state.modal, modal_pos: this.state.modal_pos, 
                                                                        centered: this._center_modal,  closeable: this.modal?._closeable}} )}
                                </>
                            )
                        }
                    }
                </Consumer>
            </>
        )
    }
    componentDidMount() {
        if (!attached) {
            window.addEventListener('message', this.handleProcessEvent);
            attached = true
        }
        this._isMounted = true
        if (this.controller !== undefined) {
            if (typeof this.controller.load_data === "function") { 
                this.controller.load_data()
            }
        }
        this.followDidMount()
    }
    get _theme() {
        return this.controller?._theme
    }
    loadViewSettings(settings) {
        this.state.settings = settings
        Object.keys(settings?.values).map((value, _) => {
            this[value] = settings.values[value]
            return undefined
        })
    }
    renderNamedView() {
        if (this.named) {
            return (
                <div id={this.name}>
                    {this.renderView()}
                </div>
            )
        } else {
            return this.renderView()
        }
    }
    renderView() {
        return <div></div>
    }
    preViewAssignments() { }
    updateValue({message: e}) {
        if (this.controller !== undefined) {
            this.controller.updateValue({message: e})
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        if (this.state.cache !== undefined) {
            this.controller?.resolveCache()
        }
        this.followUnDidMount()
    }
    display_modal() {
        return (this.state._toggled_modal) ? "visible" : "hide"
    }
    render_modal({panel_params}) {
        if (this._modal !== undefined) {
            return (
                <span className={this.display_modal()}>
                    {
                        React.createElement(this._modal, {params: {parent: this, 
                                                                    panel_controller: this.panel_controller, 
                                                                    controller: this.controller,
                                                                    panel_params: panel_params,
								    draggable: this._draggable_modal}}, undefined)
                    }
                </span>
            )
        }
    }
    toggle_modal({toggle_state}) {
        if (toggle_state === undefined) {
            this.setState({_toggled_modal: !this.state._toggled_modal})
        } else {
            this.setState({_toggled_modal: toggle_state})
        }
        this.forceUpdate()
    }
    validStr(message) {
        if (typeof message === 'string' && message.length !== 0) {
            return message
        }
        if (message === undefined || message === null) {
                return ""
        }
        return message.toString()
    }
    grafana(page) {
        if (page === undefined) {
            return this._grafana_configs?.[this.state.panel_name]
        }
        return this._grafana_configs?.[page]
    }
    get _grafana_configs() {
        return this.state?.context?.grafana_config?.["pages"]
    }
}
