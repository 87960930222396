//GENERAL REACT MODULES
import React from "react"
import {Button } from "react-bootstrap"

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import BlockDriverModal from "./BlockDriverButton.modal"

export default class BlockDriverButton extends _basePartial {
    constructor(props) {
        super(props)
        this.modal_controller   = this.controller
        this._modal = BlockDriverModal
    }

    get status() {
        return this.controller.driver?.status?.toLowerCase()
    }

    render_unblock_driver() {
        return (
            <Button className="primary_button_coloring w-100 cota" onClick={this.toggle_modal.bind(this)}>Unblock Driver</Button>
        )
    }

    render_block_driver() {
        return (
            <Button variant="danger" className="w-100 cota" onClick={this.toggle_modal.bind(this)}>Block Driver</Button>
        )
    }

    renderView() {
        console.log("blockdriverbutton", this)
        switch (this.status) {
            case "blocked":
                return this.render_unblock_driver()
            default:
                return this.render_block_driver()
        }
    }
}