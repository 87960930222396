//GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button } from "react-bootstrap";

//PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx";
import configs from "./StatusButtonsModal.json"
import StatusDropdownPartial from "../StatusDropdown/StatusDropdown.partial.jsx"
import "../StatusButtons.partial.css"

export default class StatusButtonsModal extends _baseModal {
    constructor(props) {
        super(props)
        this.state.form         = configs.form_objects
        this.modal_controller   = this.state.parent?.partial_controller
        this.state.modal_params = {
            positioned: "middle_of_panel",
            title: "Change load status",
            title_size: "",
            y_adjustment: "0px",
            x_adjustment: "0px",
            has_overlay: true,
            close_on_outside_click: true,
        }
        this.state.status = this.controller?.load?.status
    }
    renderView() {
        return (
            <Row 
            // style={{height: "350px", width: "550px"}}
            // style={{height: "245px", width: "550px"}}
            style={{height: "100px", width: "550px"}}
            >
                <Col className="d-flex flex-column justify-content-between">
                    <Row>
                        <Col style={{position: "absolute"}}>
                            {/* {this.formObject({data: this.state.form.statuses, options: this.controller.statuses, controller: this.modal_controller})} */}
                            <StatusDropdownPartial params={{parent: this, controller: this.controller, partial_controller: this.modal_controller}}/>
                        </Col>
                    </Row>
                    <Row className="flex-nowrap">
                        <Col>
                            <Button className={"clear_button "+this._theme} onClick={() => this.modal_controller.close()} >
                                Cancel
                            </Button>
                        </Col>
                        <Col>
                            <Button className={"primary_button_coloring "+this._theme} onClick={() => this.modal_controller.save_action()}>
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}