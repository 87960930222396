// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Container, Button, Card } from "react-bootstrap";

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx";
import ModalController from "./AddStopModal.modal.controller.jsx";
import config_data from "./AddStopModal.json"
import AddStopPeoplePartial from "./AddStopPeoplePartial/AddStopPeople.partial.jsx";
import "./AddStopModal.modal.css"

export default class AddStopModal extends _baseModal {
    constructor(props) {
        super(props)
        this.state.form = config_data.form_objects
        this.state.config_data = config_data
        this.modal_controller = new ModalController ({params: {view: this}})
        this.state.dims = {width: 636, height: 980}
        this.state.modal_params = {
            positioned: "middle_of_panel",
            title: this.title,
            title_size: "",
            relative_position: "",
            relative_alignment: "",
            y_adjustment: "0px",
            x_adjustment: "0px",
            has_overlay: false,
            close_on_outside_click: true,
            closeable: true,
            draggable: true
        }
        this.state.reset = false
        this.state.people = []
    }
    get title() {
        return this.state.parent.state.data.label
    }
    get type() {
        return (this.state.parent.state.data.type === "dropoff") ? "consignee" : this.state.parent.state.data.type
    }
    renderView() {
        // console.log("AddStopModal::", this)
        return (
            <>
                <Row>
                    <Col>
                        <Row>
                            <Col className="std_font">
                                SHIPPER*
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.formObject({data: this.state.form?.[this.type], options: undefined, owner: this, controller: this.modal_controller})}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col >
                        <Row >
                            <Col className="std_font">
                                SHIPPER CONTACT PERSON
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{position: "absolute"}}>
                                <AddStopPeoplePartial params={{parent: this, controller: this.controller, partial_controller: this.modal_controller}} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col >
                        <Row style={{paddingTop: "50px"}}>
                            {/* <Col className="std_font">
                                NOTES
                            </Col> */}
                        </Row>
                        <Row style={{paddingBottom: "20px"}}>
                            {/* <Col key={this.state.reset}>
                                {this.formObject({data: this.state.form.note, controller: this.modal_controller})}
                            </Col> */}
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button onClick={() => this.modal_controller.close()} className="clear_button">
                            Cancel
                        </Button>
                    </Col>
                    <Col>
                        {/* onClick={() => this.renderCard()} */}
                        <Button onClick={() => this.modal_controller.save_action()} className={this._theme}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </>
        )
    }
}