// GENERAL REACT MODULES
import React from 'react'
import { Row, Col, Card, Overlay } from 'react-bootstrap'
import { FaEllipsisV, FaChevronLeft, FaChevronRight } from "react-icons/fa"

//MODULE SPECIFIC
import Controller from "./PersonItem.controller.jsx"
import _basePartial from "components/General/_basePartial.jsx"
import "./PersonItem.css"
import Modal from "./EditPersonModal/EditPerson.modal.jsx"

export default class PersonItem extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this, controller: this.controller}})
        this._modal             = Modal
        this.state.index        = props.params.index
        this.state.person       = this.controller.personModels[this.state.index]
        this.state.select       = this.controller.personModels.length <= 2 ? false : true
        this.state.overlay      = false
        this.state.target       = undefined
    }
    buttonOverlay() {
        return (<>
            <Overlay 
                target={this.state.target} 
                show={this.state.overlay}
                rootClose
                onHide={this.partial_controller.toggle_btn_overlay.bind(this)}
                placement='left'
                transition 
                >
                {({placement: _placement,
                    arrowProps: _arrowProps,
                    popper: _popper,
                    show: _show,
                    hasDoneInitialMeasure: _hasDoneInitialMeasure,
                    ...props}) => 
                    <Card {...props} id={this.name} style={{
                        position: 'absolute',
                        backgroundColor: '#fff',
                        padding: '4px 10px',
                        borderRadius: 5,
                        fontFamily: "Muli,Roboto,sans-serif",
                        ...props.style}} className="add_shadow">
                        <Col>
                            <Row className='g-0'>
                                <Col type='button' onClick={() => this.partial_controller.edit_person()} className='btn'>Edit</Col>
                            </Row>
                            <Row className='g-0'>
                                <Col type='button' onClick={() => this.partial_controller.delete_person()} className='btn'>Delete</Col>
                            </Row>
                        </Col>
                    </Card>} 
                </Overlay>
            </>)
    }
    renderContactSelect() {
        if(!this.state.select) { 
            return <></>
        }
        return (
            <Row className='d-flex flex-row g-0'>
                <Col sm={2} className='align-self-start'>
                    <FaChevronLeft type='button' onClick={this.partial_controller.getLastPerson.bind(this)}/>
                </Col>
                <Col sm={2} className='align-self-end'>
                    <FaChevronRight type='button' onClick={this.partial_controller.getNextPerson.bind(this)}/>
                </Col>
            </Row>
        )
    }
    renderView() {
        return (<>
            {this.renderContactSelect()}
            <Row key={"person_item "+this.state.index} className='g-0'>
                <Col>
                    <Row className='g-0'>
                        <Col sm={10} className="person_title">
                            Contact Person {this.state.index}
                        </Col>
                        <Col className='person_kebab'>
                            <FaEllipsisV type='button' onClick={this.partial_controller.toggle_btn_overlay.bind(this)}/>
                            {this.buttonOverlay()}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Col className='pb-4 person_details'>
                <Row className='g-0'><Col>{this.state?.person?.name?.first+" "+this.state?.person?.name?.last}</Col></Row>
                <Row className='g-0'><Col>{this.state?.person?.email}</Col></Row>
                <Row className='g-0'><Col>{this.state?.person?.phone}</Col></Row>
            </Col>
        </>)
    }
}