//OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx";
import load_request from "assets/graphql/Loads/Loads.graphql.json"
import StopModelController from "controllers/ModelControllers/StopModelController.jsx"
import LoadModelController from "controllers/ModelControllers/Shipping/ShipmentModelController.jsx"
import CommodityModelController from "controllers/ModelControllers/Shipping/CommodityModelController.jsx"
import EquipmentModelController from "controllers/ModelControllers/Shipping/EquipmentModelController.jsx"

export default class LoadBoostModalController extends _viewController {
    constructor(props) {
        super(props)
        this.commodity  = new CommodityModelController({params: {parent: this,
                                                        controller: this,
                                                        is_view: true,
                                                        _param: "commodities",
                                                        index: 0}})
        this.equipment  = new EquipmentModelController({params: {parent: this,
                                                        controller: this,
                                                        is_view: true}})
        this.pickup     = new StopModelController({params: {parent: this,
                                                    controller: this,
                                                    is_view: true, _param: "pickup"}})
        this.consignee  = new StopModelController({params: {parent: this,
                                                    controller: this,
                                                    is_view: true, _param: "consignee"}})
        this.load       = new LoadModelController ({params: { parent: this,
                                                    controller: this,
                                                    is_view: true}})

    }
    processEventReaction(decoded) {
        this.handleScreen(2, decoded.message.data)
    }
    handleCloseScreen() {
        this.resetScreen()
    }
    get_load_info() {
        let id = this.view.state.selected
        if (id !== undefined) {
            let body = JSON.parse(JSON.stringify(load_request))
            body.query = body.query.replace("load", 'load(id: "'+id+'")')
            this.api.loads.ask({caller: this, params: {body:body}, callback: {f: this.process_load}})
        }
    }

    process_load({caller, params, results}) {
        let quoteload = results?.data?.load
        if (quoteload !== undefined) {
            if (quoteload.commodities === undefined) {
                caller.commodity?._sync({data: quoteload?.raw_quote?.request?.items, remapper:"quote"})
            } else {
                caller.commodity?._sync({data:quoteload.commodities})
            }
            caller.load?._sync({data: quoteload, remapper:"quote"})
            if (quoteload?.pickup !== undefined) {
                caller.get_stop({id: quoteload?.pickup?.id, name: "pickup"})
            }
            if (quoteload?.dropoff !== undefined) {
                caller.get_stop({id: quoteload?.dropoff?.id, name: "consignee"})
            }
            caller.view.forceUpdate()
        }
    }
    get_stop({id, name, index}) {
        this.api?.stops?.gid({caller: this, value: id, callback: {f: this.process_loaded_stop, p: {name: name, index: index}}})
    }
    process_loaded_stop({caller, params, results}) {
        if (params.index === undefined) {
            caller?.[params?.name]?._sync({data: results?.data?.stop})
        } else {
            let stops = caller.view.state.data[params?.name]
            stops[params?.index] = results?.data?.stop
            caller.setState({key: "data", param: params?.name, value: stops, fin:true})
        }
    }
}