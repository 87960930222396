// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "../_ModelController";

export default class TripModelController extends _ModelController {
    constructor(props) {
        super(props)
        this._keys = {
            drivers: "sel_drivers",
            loads: "sel_loads",
            trucks: "sel_trucks",
            trailers: "sel_trailers"
        }
    }
    get trip_id() {
        if (this.data?.trip_id !== null && this.data?.trip_id !== undefined) {
            return this.data?.trip_id
        }
        return ""
    }
    get cota_id() {
        return this.data?.cota_id
    }
    get loads() {
        return this.data?.[this._keys.loads]
    }
    set loads(value) {
        this.data[this._keys.loads] = value
    }
    get drivers() {
        return this.data?.[this._keys.drivers]
    }
    set drivers(value) {
        this.data[this._keys.drivers] = value
    }
    get trucks() {
        return this.data?.[this._keys.trucks]
    }
    set trucks(value) {
        this.data[this._keys.trucks] = value
    }
    get trailers() {
        return this.data?.[this._keys.trailers]
    }
    set trailers(value) {
        this.data[this._keys.trailers] = value
    }
    get status() {
        return this.data?.status?.toLowerCase()
    }
    set status(value) {
        this.data.status = value
    }
    get trip_number() {
        return this.data?.cota_id
    }
    get autocomplete() {
        if(this._notnull(this.data?.autocomplete)) {
            return this.data?.autocomplete
        }
        return false
    }
    set autocomplete(value) {
        this.data.autocomplete = value
    }
    resolve_ids(value) {
        if(Array.isArray(value)) {
            let ids = value.map((item) => {
                if(typeof item === "object") {
                    return item.id
                }
                return item
            })
            return ids
        }
    }
    get summary() {
        let _summary = {
            loads: this.resolve_ids(this.loads),
            driver_id: (this.resolve_ids(this.drivers))?.[0],
            truck_id: (this.resolve_ids(this.trucks))?.[0],
        }
        if (this.trailers !== undefined) {
            if (Array.isArray(this.trailers)) {
                if (this.trailers.length > 0) {
                    _summary.trailer_id = (this.resolve_ids(this.trailers))?.[0]
                }
            }
        }
        _summary.autocomplete = this.autocomplete
        if(this._notnull(this.drivers?.[0]?.asset?.id)) {
            _summary.truck_id = this.drivers?.[0]?.asset?.id
        }
        if(this._notnull(this?.trailers?.[0]?.linked_asset?.id)) {
            _summary.truck_id = this?.trailers?.[0]?.linked_asset?.id
        }
        if(this._notnull(this?.trucks?.[0]?.driver?.id)) {
            _summary.driver_id = this.trucks?.[0]?.driver?.id
        } else if(this._notnull(this?.trailers?.[0]?.linked_asset?.driver?.id)) {
            _summary.driver_id = this?.trailers?.[0]?.linked_asset?.driver?.id
        }
        if(this._notnull(this?.drivers?.[0]?.asset?.linked_asset?.id)) {
            _summary.trailer_id = this?.drivers?.[0]?.asset?.linked_asset?.id
        } else if(this._notnull(this?.trucks?.[0]?.linked_asset?.id)) {
            _summary.trailer_id = this?.trucks?.[0]?.linked_asset?.id
        }
        _summary.scrub()
        return _summary
    }
}