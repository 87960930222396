// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"
import AssetModelController from "controllers/ModelControllers/AssetModelController.jsx"
import asset_request from "assets/graphql/Assets/Assets.graphql.json"
import ViewAssetDetails from "./Partials/ViewAssetDetails/ViewAssetDetails.partial.view"
import { Row } from 'react-bootstrap'
import update_request from "assets/graphql/Assets/Assets.update.graphql.json"
import company_request from "assets/graphql/Companies/Company.drivers.graphql.json"
import delete_asset_mutation from "assets/graphql/Assets/Assets.delete.graphql.json"

export default class ViewAssetsPanelController extends _viewController {
    constructor(props) { 
        super(props)
        this.data       = {config_data: {},status: "map_idle"}
        this.asset     = new AssetModelController({params: {parent: this,
                                                                controller: this,
                                                                view: this.view,
                                                                is_view: true}})
    }
    get company_id() {
        return this.view.state.panel_params?.controller?.company_id
    }
    get drivers() {
        return this.view.state.data.drivers
    }
    get notes() {
        return this.view?.state?.data?.notes
    }
    get internal_user() {
        return this.check_role("INTERNAL_COTA_USER")
    }
    get admin_user() {
        return this.check_admin()
    }
    get owner() {
        return this.check_role("OWNER")
    }
    get editable() {
        if(this.internal_user || this.admin_user || this.owner) {
            return true
        }
        return false
    }
    load_cache() {
        let key = this.view.state.screen
        let data = (this.panel_controller?.getCache(key))?.data
        if (data === undefined) {
            data = (this.panel_controller?.getCache(2))?.data
        }
        if (data !== undefined) {
            this.setState({key: "panel_params", param: "data", value: data})
        }
    }
    load_harness() {
        this.get_asset_info()
        this.load_company()
    }
    async load_company() {
        let body = JSON.parse(JSON.stringify(company_request))
        body.query  = body.query.replace("company", 'company(id: "'+this.company_id+'")')
        this.api.companies.ask({caller: this, params: {body: body}, callback: {f: this.process_company}})
    }
    process_company({caller, params, results}) {
        if(results?.data?.company !== undefined) {
            caller.view.state.data.company = results?.data?.company
            caller.format_drivers(results?.data?.company?.drivers)
            caller.view.forceUpdate()
        }
    }
    format_drivers(drivers) {
        // format driver results to conform to dropdown/select data conventions
        if(drivers !== undefined) {
            drivers.map((driver, index) => {
                driver.name = driver?.first_name + " " + driver?.last_name
                this.view.state.data.drivers.push({value: driver?.id, label: driver?.name})
            })
        }
    }
    _delete({name, index}) {
        this.view.state.data[name] = this.view?.state?.data?.[name].filter((value, i) => i !== index)
        this.view.forceUpdate()
    }
    updateData(newData) {
        this.data = {...this.data, ...newData}
        const event = new CustomEvent("data-updated", { detail: this.data });
        document.dispatchEvent(event);
    }
    handleCloseScreen() {
        // Needs a refactor
        this.view.state.panel_params.controller.view.resetScreen()
    }
    get_asset_info() {
        let id = this.view.state.panel_params.data.id
        let body = JSON.parse(JSON.stringify(asset_request))
        body.query = body.query.replace("assets", 'asset(id: "'+id+'")')
        this.api.assets.ask({caller: this, params: {body: body}, callback: {f: this.process_asset}})
    }
    process_asset({caller, params, results}) {
        if (results.data?.asset !== undefined) {
            caller.view.state.data.asset = caller.getCopy(results.data?.asset)
            caller.view.forceUpdate()
        }
    }
    async saveAction(page_key) {
        let body = JSON.parse(JSON.stringify(update_request))
        let summary = this.asset?.summary
        let data = this.toUnquotedJSON(summary)
        body.query = body.query.replace("input:", 'input: '+data)
        // console.log(body.query)
        this.api.assets.create({caller: this, params: {body: body}, callback:{f: this.process_save, p: {page_key: page_key}}})       
    }
    async change_service() {
        if (this.asset.status.toLowerCase() === 'out-of-service') {
            this.asset.status = 'Available'
        } else {
            this.asset.status = 'out-of-service'
        }
        this.saveAction()
    }
    async process_save({caller, params, results}) {
        if(results?.errors !== undefined) {
            console.log("Error updating asset", results.errors)
        }
        // console.log("Asset Updated")
        // let id = (results?.data?.setAsset?.id !== undefined ? results?.data?.setAsset?.id : results?.data?.updateAsset?.id)
        // if (id !== undefined && id !== null) {
        //     if(caller?.controller?.uploader !== undefined) {
        //         await caller.controller.uploaded?.process_queue(id)
        //     }
        //     caller.panel_controller.selectPanel({panel_name: params?.page_key, cache_data: {id: id, type: caller.controller?.asset_type}})
        // }
    }
    resolveCache() {
        if (this.view.state?.cache !== undefined) {
            this.view.state.context.user    = this.getCopy(this.view?.state?.cache)
            this.setState({key: "data", param: "user", value: this.getCopy(this.view?.state?.cache)})
            this.view.forceUpdate()
        }
    }
    load_page(page_num) {
        switch (page_num) {
            default: 
                return <ViewAssetDetails params={{parent: this.view,
                                                    controller: this}} />
        }
    }
    delete_asset() {
        let body = JSON.parse(JSON.stringify(delete_asset_mutation))
        let data = {id: this.asset._id}
        data = this.toUnquotedJSON(data)
        body.query = body.query.replace("input", 'input: '+data)
        this.api.assets.ask({caller: this, params: {body: body}, callback: {f: this.process_delete_asset}})
    }
    process_delete_asset({caller, params, results}) {
        if(results?.errors !== undefined) {
            console.log("Error deleting asset", results.errors)
            return
        }
        // return to assets view
        caller.view?.state?.controller?.view?.resetScreen()
    }
    status() {
        if (this.asset?.status?.name === "IDLE") {
            return (
                <>
                    <Row className='idle'>
                        {this.asset?.status.label}
                    </Row>
                </>
            )
        }
        if (this.asset?.status?.name === "ASSIGNED") {
            return (
                <>
                    <Row className="assigned">
                        {this.asset?.status?.label}
                    </Row>
                </>
            )
        }
        if (this.asset?.status?.name === "DISPATCHED") {
            return (
                <>
                    <Row className="dispatched">
                        {this.asset?.status?.label}
                    </Row>
                </>
            )
        }
        if (this.asset?.status?.name === "OUT_OF_SERVICE") {
            return (
                <>
                    <Row className="out_of_service">
                        {this.asset?.status?.label}
                    </Row>
                </>
            )
        }
        if (this.asset?.status?.name === "HOOKED") {
            return (
                <>
                    <Row className="hooked">
                        {this.asset?.status?.label}
                    </Row>
                </>
            )
        }
        if (this.asset?.status?.name === "AVAILABLE") {
            return (
                <>
                    <Row className="available">
                        {this.asset?.status?.label}
                    </Row>
                </>
            )
        }
    }
    view_driver({id}) {
        this.view.panel_controller.selectPanel({panel_name: "view_driver", cache_data: {id: id}})
    }
}
  
