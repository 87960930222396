// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import _viewController from "controllers/ViewController/_viewController.jsx"

export default class DocUploadModalController extends _viewController {
    constructor(props) {
        super(props)
        this._ = undefined
    }
    get_row_color(index) {
        if (index === this.view?.state?.hover) {
            return "hover_line"
        }
        return ""
    }
    hover_line(index) {
        if (this.state.hover !== index) {
            return this.setState({hover: index})
        }
        return this.setState({hover: undefined})
    }
    create_document(type) {
        console.log("CREATE")
        console.log(this)
        this.state.panel_controller?.selectPanel({panel_name: "create_documents", data: type})
    }
    download_file() {
        
    }
    truncateString(string, maxLength){
        if (string.length <= maxLength) {
            return string;
        } else {
            const truncatedString = string.substring(0, maxLength) + "..."
            return truncatedString;
        }
    }
}
