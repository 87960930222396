//REACT SPECFIC 
import { Button, Row, Col } from "react-bootstrap";
import { BsTruck } from "react-icons/bs"

//MODULE SPECIFIC 
import Controller from "./TruckStatus.partial.controller.jsx"
import _basePartial from "components/General/_basePartial.jsx";
import Modal from "../UnassignTruckModal/UnassignTruck.modal.view.jsx"
import "./TruckStatusPartial.css"

export default class TruckStatusPartial extends _basePartial {
    constructor(props) {
        super(props)
        this._modal                         = Modal
        this.partial_controller             = new Controller({params:{view: this, controller: this?.controller}})
    }
    get asset() {
        return this.controller.asset
    }
    renderView() {
        // console.log(this)
        return (
            <Row id={this.name}>
                <div className="cota_panel_border add_shadow g-0">
                    <Row className="pad_3 g-0">
                        <Col className="icon_col">
                            <Row className="icon_row">
                                <Col className="icon_col">
                                    <BsTruck size={40}  className="icon"/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="pad_2 g-0">
                        <Col className="icon_col">
                            <Row>
                                <Col className="center">
                                    <Button className="info un_truck" onClick={() => this.controller.view_asset({id: this.asset?.id})}>
                                        {this.asset?.asset_number}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="g-0">
                        <Col className="icon_col">
                            <Row>
                                <Col className="status">
                                    {this.asset?.status}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="pad_1 pad_bot center_text g-0">
                        <Col>
                            <Row>
                                <Col>
                                    <Button onClick={() => this.toggle_modal({toggle_state: true})} className="un_truck">
                                        Unassign
                                    </Button> 
                                </Col>
                            </Row>
                        </Col>
                        {/* <Col>
                            <Row>
                                <Col>
                                    <Button className="un_truck" onClick={() => {}}>
                                        Change truck
                                    </Button>
                                </Col>
                            </Row>
                        </Col> */}
                    </Row>
                </div>
            </Row>
        )
    }
}