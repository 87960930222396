// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button } from 'react-bootstrap'
import { Droppable, Draggable } from 'react-beautiful-dnd'

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./TripOptions.partial.controller.jsx"
import configs from "./TripOptions.json"
import TripOption from "../TripOptionObject/TripOption.partial.jsx"
import "./TripOptions.partial.css"

export default class TripOptionsPartial extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this, controller: this?.controller}})
        this.state.form = configs.form_objects
    }
    get parent() {
        return this.state.parent
    }
    get data_key() {
        return this.controller.data_key()
    }
    get tab_selection() {
        return this.parent?.state?.tab_selection
    }
    tab_click(tab) {
        delete this.parent.state.search
        this.parent.setState({tab_selection: tab})
    }
    rendered_fitted_content(option) {
        // console.log(option)
        if (option !== "loads") {
            return "fit_content max_width"
        }
        return ""
    }
    render_options(option) {
        return (
            <Droppable droppableId={option} className="adjusted_max_height">
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps} {...provided.dragHandleProps} className="max_height max_width">
                        {/* TODO: make this array filtered based on search, then on each tab click, reset search */}
                        {(this.controller?.[option]?.map((obj, index) => {
                            return (
                                <Draggable id={option+"_"+obj?.id} key={obj?.id+index} index={index} draggableId={String(obj?.id)}>
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={this.rendered_fitted_content(option)}>
                                            {/* for data, need to dynamically assign the key based on option argument */}
                                            <TripOption params={{parent: this, controller: this.controller, data: {[this.data_key]: obj}, index: index, option: option, data_key: this.data_key}} />
                                            {provided.placeholder}
                                        </div>)}
                                </Draggable>
                            )
                        }))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        )
    }
    render_company_search() {
        if(this.controller.internal_user) {
            return this.formObject({data: this.state.form.company, controller: this.partial_controller, owner: this})
        }
        return <></>
    }
    renderView() {
        // console.log(this)
        if(this.tab_selection === "drivers") {
            this.state.form.drivers.values = {"company_id": this.controller?.company_id, "role": "DRIVER"}
        }
        return (
            <Row id={this.name}>
                <Col className='cota_panel_border add_shadow'>
                    <Row className="heading">
                        <Col className="title">
                            Available for a Trip
                        </Col>
                        <Col>
                            {this.render_company_search()}
                        </Col>
                    </Row>
                    <Row className="button_row">
                        <Col>
                            <Button onClick={() => {this.tab_click("loads"); this.controller.handleColorChange(1)}} className={`${this.controller.colors.background1} text_default`}>
                                <span className={`${this.controller.colors.textColor1}`}>Loads -
                                    {this.partial_controller.display_tab_total("loads")}
                                </span>
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => {this.tab_click("drivers"); this.controller.handleColorChange(2)}} className={`${this.controller.colors.background2} text_default`}>
                                <span className={`${this.controller.colors.textColor2}`}>Drivers -
                                    {this.partial_controller.display_tab_total("drivers")}
                                </span>
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => {this.tab_click("trucks"); this.controller.handleColorChange(3)}} className={`${this.controller.colors.background3} text_default`}>
                                <span className={`${this.controller.colors.textColor3}`}>Trucks -
                                    {this.partial_controller.display_tab_total("trucks")}
                                </span>
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => {this.tab_click("trailers"); this.controller.handleColorChange(4)}} className={`${this.controller.colors.background4} text_default`}>
                                <span className={`${this.controller.colors.textColor4}`}>Trailers -
                                    {this.partial_controller.display_tab_total("trailers")}
                                </span>
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="search_bar">
                            {/* TODO: MAKE this.state.form.loads be something like this.state.form[tab_selection]
                                so it can dynamically search the correct tab
                                Ensure there are search functions in the endpoints for each tab
                                Ensure API can search for each endpoint
                            */}
                            {/* {this.formObject({data: this.state.form.loads, owner: this, controller: this.controller})} */}
                            {this.parent.formObject({data: this.state.form[this.tab_selection], owner: this, controller: this.controller})}
                        </Col>
                    </Row>
                    <Row className="g-0 max_height">
                        <Col className="g-0 object_contrainer max_height">
                            {/* input the selection based on which tab was clicked */}
                            {this.render_options(this.parent.state.tab_selection)}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}