// GENERAL REACT MODULES
import React from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloak";

// OBJECT SPECIFIC MODULES
import Dashboard from "./applications/Dashboard/Dashboard.view.jsx"
import Background from "./components/Background/Background.view";
import { MainProvider, ContextConstructor } from "./controllers/MainContext"
import context from "./assets/context.json"

// STYLE MODULES
import "styles/themes/blue_theme/blue_theme.css"
import "styles/themes/cota_theme/cota_theme.css"
import "styles/themes/dark_theme/dark_theme.css"
import "styles/themes/light_theme/light_theme.css"
import "styles/generic.css"
import "styles/cota.css"
import "styles/menus.css"
import "styles/snackbar.css"
import 'bootstrap/dist/css/bootstrap.css';


function App() {
    const keycloakProviderInitConfig = {
        onLoad: "check-sso",
        checkLoginIframe: false,
    };
    if (process.env.REACT_APP_DEVMODE) {
        console.log(process.env.REACT_APP_DEVMODE)
        keycloak.authenticated = true
        keycloak.idTokenParsed = {
            "exp": 1683122121,
            "iat": 1683118525,
            "auth_time": 1683118521,
            "jti": "82cbaf62-d02b-4889-b9a0-4f3c5cd8fbcf",
            "iss": "https://auth.cotafreight.com/auth/realms/cota",
            "aud": "cotafreight",
            "sub": "4df09522-6ca5-4ee5-b507-9278ec835af9",
            "typ": "ID",
            "azp": "cotafreight",
            "nonce": "72f0b3c2-8a36-4d36-8b90-304c9e26ca91",
            "session_state": "a7f590f2-39cb-49cb-8022-cf5cd655476d",
            "at_hash": "fUBvqdENUuBa3IKUxl7kAg",
            "acr": "0",
            "sid": "a7f590f2-39cb-49cb-8022-cf5cd655476d",
            "email_verified": true,
            "roles": [
                "FREIGHT_00",
                "default-roles-cota",
                "GrafanaAdmin",
                "offline_access",
                "admin",
                "uma_authorization",
                "INTERNAL_COTA_USER",
                "COTA_SUPER_USER"
            ],
            "name": "Nicholas Damuth",
            "preferred_username": "cotadev",
            "given_name": "Nicholas",
            "family_name": "Damuth",
            "email": "nick@cotasystem.com",
            "picture": "https://test"
        }
        keycloak.realmAccess = {
            "roles": [
                "FREIGHT_00",
                "default-roles-cota",
                "GrafanaAdmin",
                "offline_access",
                "admin",
                "uma_authorization",
                "INTERNAL_COTA_USER",
                "COTA_SUPER_USER"
            ]
        }
        keycloak.idTokenParsed.sub = "4286317f-87e9-479c-b5b4-df48e8cdd4c7"
        return (
            <div className="fullscreen">
                <MainProvider value={ContextConstructor(context)}>
                    <Dashboard authClient={keycloak} params={{parent: this, keycloak: keycloak}} />
                </MainProvider>
            </div>
        )
    }
    if (process.env.REACT_APP_NOLOGIN) {
        <div className="fullscreen">
            <MainProvider value={ContextConstructor(context)}>
                <Dashboard authClient={keycloak} params={{parent: this, keycloak: keycloak}} />
            </MainProvider>
        </div>
    }
    return (
        <div className="fullscreen">
            <MainProvider value={ContextConstructor(context)}>
                <ReactKeycloakProvider authClient={keycloak}
                                        initOptions={keycloakProviderInitConfig}
                                        LoadingComponent={<Background />}>
                    <Dashboard authClient={keycloak} params={{parent: this, keycloak: keycloak}} />
                </ReactKeycloakProvider>
            </MainProvider>
        </div>
    );
}

export default App;

