//GENERAL REACT MODULES
import React from "react"
import { Row, Col, Button } from "react-bootstrap"

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"
import Controller from "./StatusButtons.partial.controller.jsx"
import Modal from "./StatusButtonsModal/StatusButtons.modal.jsx"
import "./StatusButtons.partial.css"

export default class StatusButtonsPartial extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({ params: { view: this, controller: this.controller } })
        this._modal = Modal
    }
    get status() {
        return this.controller.trip?.status?.toLowerCase()
    }
    render_change_status() {
        return (<>
            <Row>
                <Col>
                    <Row>
                        <Button className={"dispatch_sheet primary_button_coloring " + this._theme}
                            onClick={() => this.toggle_modal({toggle_state: true})}>
                            Change Trip Status
                        </Button>
                    </Row>
                </Col>
            </Row>
            <Row>&nbsp;</Row>
        </>)
    }
    render_complete_trip() {
        return (
            <>
                <Row>
                    <Col>
                        <Row>
                            <Button className="dispatch_sheet" onClick={() => this.controller.update_trip_status("COMPLETED")}>
                                Complete Trip
                            </Button>
                        </Row>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
            </>
        )
    }
    determine_render() {
        switch (this.status) {
            case "empty":
                return (<>
                    {this.render_complete_trip()}
                    {this.render_change_status()}
                </>)
            default:
                return this.render_change_status()
        }
    }
    renderView() {
        return (
            <Row id={this.name}>
                <Col>
                    {this.determine_render()}
                </Col>
            </Row>
        )
    }
}

