// GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button } from "react-bootstrap";
// import { CheckCircle } from "react-icons/bi"

// PAGE SPECIFIC MODULES
import _baseModal from "components/General/_baseModal.jsx";
import ModalController from "./InvoiceModal.controller.jsx";
import config_data from "./InvoiceModal.json"
import "./InvoiceModal.css"

export default class InvoiceModal extends _baseModal {
    constructor(props) {
        super(props)
        this.state.form             = config_data.form_objects
        this.modal_controller       = new ModalController ({params: {view: this, controller: this.controller}})
        this.state.modal_params     = { positioned: "middle_of_panel",   
                                        title: "Thunder Funding Invoice Details",                
                                        title_size: "",                     
                                        closeable: true,                    
                                        has_overlay: true,                 
                                        close_on_outside_click: true,       
                                        relative_position: "",
                                        relative_alignment: "",
                                        x_adjustment: "0px",                
                                        y_adjustment: "0px" }
    }
    renderView() {
        const invoice = this.controller.view.state.invoice_list
        return (
            <Row>
                <Col>
                    <Row className="bottom_border">
                        <Col>
                            <Row>
                                <Col className="info MuiInputLabel-shrink">
                                    Funding Preferences *
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.formObject({data: this.state.form.funding, options: undefined, owner: this})} 
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            Bank wire - funds in your bank account today
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            Fuel card - funds on your Thunder card today
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            ACH - funds in your bank next business day
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            </Col>
                    </Row>
                    {/* <Row className="bottom_border">
                        <Col>
                            <Row>
                                <Col className="info MuiInputLabel-shrink">
                                    Notes
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.formObject({data: this.state.form.notes, options: undefined, owner: this})} 
                                </Col>
                            </Row>
                        </Col>
                    </Row> */}
                    <Row className="bottom_border">
                        <Col>
                            <Row>
                                <Col className="info ">
                                    Files
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    Choose Invoice, Signed Bill of Lading, Lumper Receipts (optional), Freight Images (optional).
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.modal_controller.render_invoices()}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        &nbsp;
                    </Row>
                    <Row className="btn_row">
                        <Col>
                        </Col>
                        <Col className="btn_col">
                            <Button className={"clear_button "+this._theme} onClick={this.toggle_modal?.bind(this.state.parent, {toggle_state: false})} >
                                Cancel
                            </Button>
                        </Col>
                        <Col className="btn_col">
                            <Button className={"primary_button_coloring "+this._theme} onClick={this.toggle_modal?.bind(this.state.parent, {toggle_state: false})} >
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}