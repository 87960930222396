// GENERAL REACT MODULES

// ALL OTHER MODULES
import _ModelController from "./_ModelController";

export default class FeedbackModelController extends _ModelController {
    constructor(props) {
        super(props)  }

        get _id () {
            return this.data?.id
        }
        set _id (value) {
            return this.data.id = value
        }
    get description() {
        return this.data?.description
    }
    set description(data) {
        this.data.description = data
    }
    get is_bug() {
        return this.data?.is_bug
    }
    set is_bug(data) {
        this.data.is_bug = data
    }
    get document_name() {
        return this.data?.document_name
    }
    set document_name(data) {
        this.data.document_name = data
    }
    get user_id() {
        return this.controller.context.self.id
    }

   
    get summary() {
        let _summary = {
            description: this.description,
            is_bug:      this.is_bug,
            user_id:    this.user_id
        }
        return _summary
    }
}
