// OBJECT SPECIFIC MODULES
import test_data from "./test.json"
import schema from "./schema.json"

// ALL OTHER MODULES
import _baseApiObject from "../../../../components/General/_baseApiObject";
import event_types_request from "assets/graphql/Event_types/event_types.graphql.json"

export default class Event_types extends _baseApiObject {
    constructor(props) {
        super(props)
        this.schema     = schema
        this.test_data  = test_data
        this.requests   = {
            general: event_types_request
        }
    }
}
