//REACT SPECIFC 
import React from "react";
import { Row, Col, Container } from "react-bootstrap";

//MODULE SPECIFIC
import Controller from "./NewLoadPanel.contoller.jsx";
import "./NewLoadPanel.css";
import CotaUserBar from "components/CotaUserBar/CotaUserBar.partial.jsx"
import MultiPanel from "components/MultiPanels/MultiPanel";
import RoleObjectPartial from "./Partials/RoleObjectPartial/RoleObject.partial.jsx";
import CommodityPartial from "./Partials/Commodity/Commodity_Partial.jsx";
import CommodityDetailsPartial from "./Partials/CommodityDetails/CommodityDetails.partial.jsx";
import SaveCancel from "./Partials/Save_Cancel/Save_Cancel.jsx";
import configs from "./NewLoad.json"
import HelperPanel from "components/HelperPanel/HelperPanel.partial.jsx"

export default class NewLoadPanel extends MultiPanel {
    constructor(props) {
        super(props)
        this.state.form         = configs.form_objects
        this.state.configs      = configs
        this.controller = new Controller({ params: { view: this } })
        this.state.more_info = false
        this.state.data = { commodities: [] }
        this.state.states = []
        this.state.trailer_types = []
        this.state.special_requirements = []
        this.handleProcessEvent = this.handleProcessEvent.bind(this)
    }
    renderMoreInfo() {
        if (this.state.more_info) {
            return (<>
                <Row>
                    <Col>
                        <CommodityDetailsPartial params={{ parent: this, controller: this.controller, form: this.state.form }} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <RoleObjectPartial params={{ parent: this, controller: this.controller, role: "shipper", form: this.state.form.pickup }} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <RoleObjectPartial params={{ parent: this, controller: this.controller, role: "consignee", form: this.state.form.consignee }} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <RoleObjectPartial params={{parent: this, controller: this.controller, role: "payor"}}/>
                    </Col>
                </Row>
            </>)
        }
    }
    render() {
        // console.log(this)
         // seriously need to find a fix for why the state is not going into data
        this.state.data.shipment = this.state?.shipment
        return (
            <Container id={this.name} className="generic_100">
                <Row className="max_height text_color">
                    <Col className="flex_wrapper_rows">
                        <Row>
                            <CotaUserBar params={{ parent: this, controller: this.controller, title: "Loads", return: true}}/>
                        </Row>
                        <Row className="generic_100 margin_auto">
                            <Col>
                                <Row>
                                    <Col>
                                        <div className="g-0 cota_panel_border add_shadow pb-4 partial_padding">
                                            <Row>
                                                <Col>
                                                    <CommodityPartial params={{ parent: this, controller: this.controller, form: this.state.form.commodity }} />
                                                </Col>
                                            </Row>
                                            {this.renderMoreInfo()}
                                            <Row>
                                                <Col>
                                                    <SaveCancel params={{ parent: this, controller: this.controller }} />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <HelperPanel params={configs?.tooltips?.loads}/>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}