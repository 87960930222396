//GENERAL REACT MODULES
import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { IoIosWarning } from "react-icons/io";

//PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx";
import Controller from "./PayorCard.partial.controller.jsx";
import Modal from "../EditPayorModal/EditPayor.modal.jsx";
import NewPayorModal from "../NewLoadModal/NewContactLoad.modal.jsx";
import EditPayorModal from "../EditContactModal/EditContact.modal.jsx"
import  ChangeContactModal from "components/NewLoadPartials/ShipperObject/ChangeContactModal/ChangeContactModal.jsx"

import "./PayorCard.css"

export default class PayorCard extends _basePartial {
    constructor(props) {
        super(props)
        this._modal = Modal
        this.partial_controller = new Controller({ params: { view: this, controller: this.controller } })
        this.state.pickup = {}
        this.state.consignee = {}
        this.state.stops = []
        this.state.stop = {}
        this.state.index = 0
        this.state.ran = []
        this.state.new_contact = false
        this.state.contact_change = false
    }
    get reform_mode() {
        if(this.controller?.load?.status === "Pending") {
            return true
        }
        return false
    }
    changeContactModal() {
        // console.log("CHANGED")
        this._modal = ChangeContactModal
        this.toggle_modal({ toggle_state: true })
    }
    changeModal() {
        if(this.controller.load?.status === "Pending") { 
            this._modal = EditPayorModal
        }
        this.toggle_modal({ toggle_state: true })
    }
    changePayor(param) {
        // where the modal change magic happens
        this.toggle_modal({ toggle_state: false })
        if(param === "new") {
           // this._modal = NewPayorModal
        } else {
            this._modal = EditPayorModal
        }
        this.toggle_modal({ toggle_state: true })
    }
    reset_modal(){
        this._modal = Modal
        this.toggle_modal({ toggle_state: false })
    }
    render_edit_btn() {
        if(this?.controller?.editable && this?.controller?.load.created_on == "carrierpro") {
            return (
                <Button onClick={() => this.changeModal()} sm={2} className="clear_button">
                    Edit Payor
                </Button>
            )
        }
    }
    render_similar_contacts() {
        // console.log("---- render_similar_contactz ----", this.state?.type, this.index, this)
        if(this.partial_controller.should_render()) {
            return (<>
                <Col className="padd" sm={10}>
                    <Row className="warn_text">
                        <center> {`We found ${this.state?.similar_payor_number} contacts that match`} </center>
                    </Row>
                    <Row className="space" >
                        <Col sm={1} >
                        <Button className={"primary_button_coloring " + this._theme} onClick={() => this.changeContactModal()}>
                            Show Me
                        </Button>
                        </Col>
                    </Row>
                </Col>
            </>)
        }
    }
    check_id_change() {
        // DARN HACKS!
        if(!this.state.contact_change && this.controller.company?._id !== "reform") {
            this.state.ran = []
            this.state.contact_change = true
        }
    }
    renderView() {
        this.check_id_change()
        // if(this.controller?.company?.name){
        if(!this.state.ran.length){
            this.partial_controller.find_contacts(this.controller?.company?.name)
        // this.partial_controller.find_contacts(this.controller?.load?.payor.name)
        }
        return (
            <Row id={this.name}>
                <Col className="g-0 add_shadow cota_panel_border">
                    <Row className="g-0">
                        <Col className="end g-0">
                            <Row className="g-0">
                                <Col className="g-0">
                                    &nbsp;
                                </Col>
                                <Col sm={2} className={"g-0 uc_text payor tag"}>
                                    Payor
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        {this.render_similar_contacts()}
                    </Row>
                    <Row className="buffer_row no_wrap">
                        <Col>
                            <Row className="no_wrap width">
                                {/* Cursor poiner on number will redirect to corresponding contact information page in contacts */}
                                <Col className="number">
                                    {this?.controller?.company?.name}
                                    {/* {this?.controller?.company?.name === "" ? this?.controller?.load?.payor?.name : this?.controller?.company?.name} */}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    &nbsp;
                                </Col>
                            </Row>
                            <Row>
                                <Col className="address">
                                    Address
                                </Col>
                            </Row>
                            <Row>
                                <Col className="location">
                                    {this.partial_controller.full_address}
                                </Col>
                            </Row>
                            <Col>
                                &nbsp;
                            </Col>
                            <Row>
                                <Col className="address">
                                    Contact Details
                                </Col>
                            </Row>
                            <Row>
                                <Col className="location">
                                    {this.controller?.company?.phone ?? ""}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="location">
                                    {this.controller?.company?.email ?? ""}
                                </Col>
                            </Row>
                        </Col>
                        {/* <Col className="width_50">
                            <Row>
                                <Row>
                                </Row>
                                <Col>
                                </Col>
                                <Col sm={2} className="pad_top">
                                    <Row >
                                        <Col >
                                            <Row >
                                                <Button onClick={() => this.toggle_modal({ toggle_state: true })} sm={2} className="clear_button">
                                                    Edit Payor
                                                </Button>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            &nbsp;
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col> */}
                    </Row>
                    <Row>
                        <Col>
                        </Col>
                        <Col>
                            {this.render_edit_btn()}
                            {/* <Button onClick={() => this.toggle_modal({ toggle_state: true })} sm={2} className="clear_button">
                                Edit Payor
                            </Button> */}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}