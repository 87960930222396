// GENERAL REACT MODULES
import { Container, Row, Col, Button } from "react-bootstrap";
import { ColorRing } from  'react-loader-spinner'

// PAGE SPECIFIC MODULES
import Controller from "./BillingPage.controller";
import "./BillingPage.css";
import MultiPanel from "components/MultiPanels/MultiPanel";
import CotaUserBar from "components/CotaUserBar/CotaUserBar.partial.jsx"
import InvoicePanel from "./Partials/InvoicePanel/InvoicePanel.partial.jsx"
import BillingDetails from "./Partials/BillingDetails/BillingDetails.partial.jsx"
import DownloadPartial from "components/FileDownload/Download.partial.jsx"
import Modal from "./Partials/EmailModal/EmailModal.view.jsx"

export default class BillingPage extends MultiPanel {
    constructor(props) {
        super(props)
        this.custom_canvas  = true
        this.state.screen   = 2
        this._modal         = Modal
        this.controller     = new Controller({ params: { view: this } })
        this.state.states               = []
        this.state.trailer_types        = []
        this.state.special_requirements = []
        this.state.emails               = []
        this.state.data     = {
            line_item: {},
            line_items: [],
            commodities: [],
            notes: [] 
        }
        this.state.documents        = []
        this.state.selected_docs    = []
        this.state.component        = []
        this.state.stops = {
            pickup: [],
            dropoff: []
        }
        this.state.invoice_list     = []
    }
    loader() {
        return (
            <Row className="add_shadow invoice_spinner">
                <Col className="mt-3">
                    <Row>
                        <Col className="invoice_spinner_text_col">
                            Loading Invoice...
                        </Col>
                    </Row>
                    <Row>
                        <ColorRing visible={true}
                            height="110"
                            width="80"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={['#fff', '#fff', '#fff', '#fff', '#fff']}/>
                    </Row>
                </Col>
            </Row>
        )
    }
    render_invoice_actions() {
        if(this.controller.invoice_pdf?.id === null || this.controller?.invoice_pdf?.id === undefined) { 
            return this.loader()
        }
        return (<>
            <Row>
                <Col>
                    <Row>
                        <Button onClick={() => this.toggle_modal({toggle_state: true})} className={"dispatch_button primary_button_coloring " + this._theme}>
                            Email Invoice
                        </Button>
                    </Row>
                </Col>
            </Row>
            <Row>&nbsp;</Row>
            <Row>
                <Col className="g-0">
                    <Row>
                        <DownloadPartial params={{parent: this, controller: this.controller, is_private: true, file: this.state.invoice_pdf, button: true, title: "Print Invoice", new_tab: true, css: "dispatch_button primary_button_coloring w-100"}} />
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <InvoicePanel params={{ parent: this, controller: this.controller }}/>
                </Col>
            </Row>
        </>)
    }
    renderInnerFrame() {
        // console.log(this)
        return(
            <Container id={this.name} className="generic_100 g-0">
                <Row className="max_height">
                    <Col id={this.name} className="flex_wrapper_rows">
                        <CotaUserBar params={{ parent: this, controller: this.controller, title: "loads", return: true }} />
                        <Row className="generic_100 margin_auto">
                            <Col className="pad_right">
                                <BillingDetails params={{ parent: this, controller: this.controller }}/>
                            </Col>
                            <Col sm={3} className="pad_left col_width">
                                {this.render_invoice_actions()}
                                {/* <Row>
                                    <Col>
                                    <Row>
                                        <Button onClick={() => this.toggle_modal({toggle_state: true})} className={"dispatch_button primary_button_coloring " + this._theme}>
                                            Email Invoice
                                        </Button>
                                    </Row>
                                    </Col>
                                </Row>
                                <Row>&nbsp;</Row>
                                <Row>
                                    <Col className="g-0">
                                        <Row>
                                            <DownloadPartial params={{parent: this, controller: this.controller, is_private: true, file: this.state.invoice_pdf, button: true, title: "Print Invoice", new_tab: true, css: "dispatch_button primary_button_coloring w-100"}} />
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <InvoicePanel params={{ parent: this, controller: this.controller }}/>
                                    </Col>
                                </Row> */}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}