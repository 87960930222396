//REACT SPECIFIC
import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";

//MODULE SPECIFIC
import _baseModal from "components/General/_baseModal.jsx";
import Controller from "./ChangeDriverModal.controller.jsx";
import "./ChangeDriverModal.css";
import AssignAsset from "../../AssignAsset/AssignAsset.partial.jsx";

export default class ChangeDriverModal extends _baseModal {
    constructor(props) {
        super(props) 
        this.modal_controller       = new Controller ({params: {view: this}})
        this.state.modal_params     = {positioned: "middle_of_panel", 
                                        has_overlay: true, 
                                        title: "Unassign Driver", 
                                        close_on_outside_click: true}
        this.state.assignAsset      = false        
    }
    assign_asset() {
        return (
            <AssignAsset params={{parent: this.view,
                                    controller: this.controller}} />
        )
    }
    renderView() {
        if(this.state.assignAsset) {
            return this.assign_asset()
        }
        return (
            <>
                <div>
                    <Col className="pad"> 
                        <Row>
                            <Col className="font">
                                Are you sure you want to unassign this driver?
                            </Col>
                        </Row>
                        <Row>
                            &nbsp;
                        </Row>
                        <Row>
                            <Col>
                                <Button className={"clear_button "+this._theme} onClick={this.toggle_modal?.bind(this.state.parent, {toggle_state: false})} >
                                    Cancel
                                </Button>
                            </Col>
                            <Col>
                                <div onClick={this.modal_controller.unassign_driver.bind(this)}>
                                    <Button className={"primary_button_coloring "+this._theme} onClick={this.toggle_modal?.bind(this.state.parent, {toggle_state: false})} >
                                        Unassign 
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </div>
            </>
        );
    }
}