// GENERAL REACT MODULES
import _basePartial from "components/General/_basePartial.jsx"
import { Row, Col } from 'react-bootstrap'

// ALL OTHER MODULES
import "./BadgeColumn.css"
import Controller from "./BadgeColumn.controller.jsx"

export default class BadgeColumn extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this}})
        this.state.has_icon     = false
        this.state.data         = (this.state.data === undefined) ? "pending" : this.state.data
    }
    get items() {
        return this.controller.getState({key: "line_items"})
    }
    get item_data() {
        return this.props?.params?.item_data
        // return this.items?.[this.state?.index]
    }
    get line_item() {
        return this.partial_controller.line_item
    }
    get line_height() {
        return 40
    }
    get _configs() {
        return this.controller.getState({key: "configs"})
    }
    render() {
        return (
            <Col id={this.name} style={{"minWidth": this.state.width}} className="line_col">
                <Row>
                    <Col  className="generic_override_flex">
                        <div className={"badge "+this.partial_controller.get_badge_color()}>
                            {this.partial_controller.unwrap_obj({obj: this.partial_controller.get_data()})}
                        </div>
                    </Col>
                </Row>
            </Col>
        )
    }
}
