// GENERAL REACT MODULES
import moment from 'moment'

// ALL OTHER MODULES
import _ModelController from "./_ModelController";
import ContactModelController from "controllers/ModelControllers/ContactModelController";

export default class StopModelController extends _ModelController {
    constructor(props) {
        super(props)
        this._state = undefined
//        this.contact = new ContactModelController({params: {parent: this, controller: this.controller, index: this.index, _nested: this._param, param: "contact"}})
        this.contact = new ContactModelController({params: {parent: this, controller: this.controller, _binded: this.is_bound(true), 
                                                        index: this.index, _nested: this._param, _param: "contact"}})
    }
    get stop_index() {
        if (this.data?.stop_index !== undefined) {
            return this.data?.stop_index
        }
        return this._stop_index
    }
    set stop_index(value) {
        if (this.data?.stop_index !== undefined) {
            this.setState({param: "stop_index", value: value})
        } else {
            this._stop_index = value
        }
    }
    get all() {
        let data = this.data
        if (this.contact?.all !== undefined) {
            data.contact = this.contact.all
        }
        return data
    }
    get number() {
        return this.data?.number
    }
    set number(value) {
        this.data.number = value
    }
    get date() {
        if (this.data?.date !== undefined && this.data?.date !== null) {
            return this.data?.date
        }
        return ""
    }
    get formattedDate(){
        // let d = new Date(this.date).toISOString().split('T')[0]
        // let formattedDate = moment(d).format('MMM Do, YYYY')
        let formattedDate = moment(this.date).format('MMM Do, YYYY')
        return formattedDate;
    }
    set date(value) {
        this.setState({param: "date", value: value})
    }
    get time() {
        return this.data?.time
    }
    get end_time(){
        return this.data?.end_time
    }
    get appointment_required() {
	return this.data?.appointment_required
    }
    set appointment_required(value) {
	    this.data.appointment_required = value
    }
    set time(value) {
        this.setState({param: "time", value: value})
    }
    set end_time(value) {
        this.setState({param: "end_time", value: value})
    }
    get rep() {
        if (typeof this.data?.representative === "object") {
            return this.data?.representative
        }
        return this.contact?.people?.[this.data?.representative]
    }
    set rep(value) {
        this.data.representative = value
    }
    get type() {
        // return (this._type === undefined) ? this._data_param : this._type
        return (this.data?.stop_type === undefined) ? this._data_param : this.data?.stop_type
    }
    get unwrapped_date() {
        return new Date(this.date)
    }
    get rendered_date() {
	if (typeof this.date === 'object' && this.date !== null && 'toISOString' in this.date) {
            return this.date.toISOString().replace(/T.*/,'').split('-').join('-')
        }
        if (this.date !== undefined && this.date !== null) {
            return this.date.replace(/T.*/,'').split('-').join('-')
        }
        return ""
    }
    get summary() {
        let data = {
            id: this._id,
            contact_person_id: this.rep?.id,
            time: this.time,
            end_time: this.end_time,
            date: this.rendered_date,
            contact_id: (this.contact?._id !== undefined) ? this.contact?._id : this.data?.contact?.id,
            stop_type: this.type?.toUpperCase(),
            number: this.number,
            appointment_required: this.appointment_required
        }
        if(!isNaN(this.stop_index)) {
            data.index = Number(this.stop_index)
        }
        return data
    }
}
