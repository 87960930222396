// GENERAL REACT MODULES
import React from "react";
import { Row, Col } from 'react-bootstrap';
import { RiCheckboxCircleFill } from "react-icons/ri";
import { AiOutlineHome, AiOutlineCloseCircle, AiOutlineFile } from "react-icons/ai";
import { BiComment } from "react-icons/bi";
// AiOutlineHome
// BiComment

// AiOutlineCloseCircle

// PAGE SPECIFIC MODULES
import _basePartial from "components/General/_basePartial.jsx"

export default class DetailsMenu extends _basePartial {
    constructor(props) {
        super(props)
        this.data = this.controller.getState({key: "details"})
    }
    render() {
        return(
            <Row className="g-0" id={this.name}>
                <Col className="menu_block">
                    <Row className="g-0 row_height">
                        <Col sm={2} className="center check_col">
                            <RiCheckboxCircleFill size={30}/>
                        </Col>
                        <Col>
                            Connected
                        </Col>
                    </Row>
                    <Row className="g-0 row_height clickable teal_row">
                        <Col sm={2} className="center">
                            <AiOutlineHome size={30}/>
                        </Col>
                        <Col>
                            {this.data?.name}
                        </Col>
                    </Row>
                    <Row className="g-0 row_height clickable teal_row">
                        <Col sm={2} className="center">
                            <BiComment size={30}/>
                        </Col>
                        <Col>
                            Need help?
                        </Col>
                    </Row>
                    <Row className="g-0 row_height clickable teal_row">
                        <Col sm={2} className="center">
                            <AiOutlineFile size={30}/>
                        </Col>
                        <Col>
                            Terms of service
                        </Col>
                    </Row>
                    <Row className="g-0 row_height clickable disconnect_row">
                        <Col sm={2} className="center">
                            <AiOutlineCloseCircle size={30}/>
                        </Col>
                        <Col>
                            Disconnect app
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}